import regexSplitterPatternPart, { RegexSplitterPatternPart } from './regexSplitterPatternPart';

export interface RegexSplitterPart {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  regexSplittersId: string; // System.Guid
  targetModel: string; // string
  targetId: string; // string
  pattern: string; // string
  partSortOrder: number; // int
  targetSortOrder: number; // int
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  regexSplitterPatternParts: RegexSplitterPatternPart[];
}

export interface Value {
  value: RegexSplitterPart[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<RegexSplitterPart>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    RegexSplittersId: data?.regexSplittersId || undefined,
    TargetModel: data?.targetModel || '',
    TargetId: data?.targetId || undefined,
    Pattern: data?.pattern || '',
    PartSortOrder: data?.partSortOrder || 0,
    TargetSortOrder: data?.targetSortOrder || 0,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    RegexSplitterPatternParts: data?.regexSplitterPatternParts?.map((item) => regexSplitterPatternPart.toAPI(item)),
  };
}

function parse(data?: Partial<RegexSplitterPart>): RegexSplitterPart {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    regexSplittersId: data?.regexSplittersId || '',
    targetModel: data?.targetModel || '',
    targetId: data?.targetId || '',
    pattern: data?.pattern || '',
    partSortOrder: data?.partSortOrder || 0,
    targetSortOrder: data?.targetSortOrder || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    regexSplitterPatternParts: data?.regexSplitterPatternParts || [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
