import { GetterTree } from 'vuex';
import { DocumentPagesTextState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentPagesTextState, RootState> = {
  ['getDocumentPagesTexts']: (state) => state.documentPagesTexts,
  ['getDocumentPagesTextsIsLoading']: (state) => state.documentPagesTexts?.isLoading,
  ['getDocumentPagesTextsTotal']: (state) => state.documentPagesTexts?.total,
  ['getDocumentPagesTextsSearchParams']: (state) => state.documentPagesTexts?.searchParams,
};
