import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { OdataItems } from '@/shared/model/OdataItems';
import { Document } from '@/shared/model/document';
import MenuDocumentAssignFolderView from '../menu-document-assign-folder/menu-document-assign-folder.vue';
import MenuDocumentFilesView from './menu-document-files/menu-document-files.vue';
import MenuDocumentInformationView from '../menu-document-information/menu-document-information.vue';
import MenuDocumentTemplateView from '../menu-document-template/menu-document-template.vue';
import MenuDocumentNotesView from './menu-document-notes/menu-document-notes.vue';
import MenuDocumentGobdView from './menu-document-gobd/menu-document-gobd.vue';
import MenuDocumentAccountView from './menu-document-account/menu-document-account.vue';
import MenuDocumentQaView from './menu-document-qa/menu-document-qa.vue';
import MenuDocumentLogsView from './menu-document-logs/menu-document-logs.vue';
import MenuDocumentSplitpdfView from './menu-document-splitpdf/menu-document-splitpdf.vue';

import MenuMaintenanceBtnView from './menu-maintenance-btn/menu-maintenance-btn.vue';
import DocumentSwitchesView from './document-switches/document-switches.vue';
import DocumentFavoriteInfoFieldsView from './document-favorite-info-fields/document-favorite-info-fields.vue';

import DocumentAutoGobdDialog from './document-auto-gobd-dialog/document-auto-gobd-dialog.vue';
import DocumentGroupRightsDialog from './document-group-rights-dialog/document-group-rights-dialog.vue';
import DocumentRestoreDialog from '@/views/menu/rightMainMenu/menu-document-list/documents-restore-dialog/documents-restore-dialog.vue';
import DocumentsDeleteDialog from '@/views/menu/rightMainMenu/menu-document-list/documents-delete-dialog/documents-delete-dialog.vue';
import GobdHelper from './menu-document-gobd/gobdHelper';
import FileUtils from '@/shared/utils/fileUtils';
import GeneralUtils, { isBlank } from '@/shared/utils/generalUtils';

import DocumentsUnMergeDialog from '@/views/menu/rightMainMenu/menu-document-list/documents-unmerge-dialog/documents-unmerge-dialog.vue';
import { DocumentProperty } from '@/shared/model/documentProperty';
import ActionLogsHelper from '@/shared/utils/actionLogHelper';
import DateUtils from '@/shared/utils/DateUtils';
import DocumentDeleteDialog from '@/components/dialogs/documentDeleteDialog/documentDeleteDialog.vue';

import file, { File, Value } from '@/shared/model/file';
import moment from 'moment';

const logger = new Logger('menu-document-preview');

const documentModule = namespace('document');
const documentPropertyModule = namespace('documentProperty');
const authModule = namespace('auth');
const fileModule = namespace('file');
const allocationModule = namespace('allocation');

@Component({
  name: 'menu-document-preview',
  components: {
    MenuDocumentAssignFolderView,
    MenuDocumentFilesView,
    MenuDocumentInformationView,
    MenuDocumentTemplateView,
    MenuDocumentNotesView,
    MenuDocumentGobdView,
    DocumentAutoGobdDialog,
    MenuDocumentAccountView,
    MenuDocumentQaView,
    MenuDocumentLogsView,
    MenuDocumentSplitpdfView,

    MenuMaintenanceBtnView,
    DocumentSwitchesView,
    DocumentFavoriteInfoFieldsView,

    DocumentGroupRightsDialog,
    DocumentRestoreDialog,
    DocumentsDeleteDialog,
    DocumentsUnMergeDialog,

    DocumentDeleteDialog,
  },
})
export default class MenuDocumentPreviewView extends Vue {
  @documentPropertyModule.Getter('getDocumentPropertys')
  private documentProperties?: OdataItems<DocumentProperty>;
  @documentPropertyModule.Action('getDocumentPropertys')
  private getDocumentPropertys!: any;

  @allocationModule.Action('getMainAllocationsByDocumentId')
  private actionGetMainAllocationsByDocumentId?: any;
  @allocationModule.Getter('getAllocations')
  private getterAllocations?: any;
  @allocationModule.Action('copyAllocation')
  private actionCopyAllocation?: any;

  get allocations() {
    return this.getterAllocations;
  }

  @authModule.Getter('getIsLeftDrawerShown')
  private getterIsLeftDrawerShown: any;
  @authModule.Getter('DeleteDocumentsAllowed')
  private DeleteDocumentsAllowed: any;
  @authModule.Getter('CheckDocumentGobdAllowed')
  private checkDocumentGobdAllowed: any;
  @authModule.Getter('EditDocumentAllocationsAllowed')
  private editDocumentAllocationsAllowed: any;
  @authModule.Getter('ViewDocumentCommentsAllowed')
  private viewDocumentCommentsAllowed: any;

  drawerSubRight = false;

  @Watch('getterIsLeftDrawerShown')
  private onLeftDrawerChange(newValue: any, o: any) {
    if (!newValue) {
      this.resetSubMenus();
    }
  }

  get documentHasAddress() {
    return this.getterGetDocument?.address?.id;
  }

  get documentHadAddress() {
    return this.getterGetDocument?.adresseKundennummer?.length > 0;
  }

  get documentAddress() {
    return this.getterGetDocument.address;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  get document() {
    return this.getterGetDocument;
  }

  get isDocumentPdf() {
    return this.getterGetDocument && FileUtils.isPdf(this.getterGetDocument.name);
  }

  get documentNumber() {
    return this.document?.documentNumber ?? 0;
  }

  private accountId = '';

  get isDocumentHasDeletedStatus() {
    return !!this.document?.deleted;
  }

  formatDateUtcDateTime(date: string) {
    return this.$i18n.locale.toLowerCase() === 'de'
      ? moment(date).format('DD.MM.YYYY HH:mm:ss')
      : moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  formatDate(date: string) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(date, this.$i18n.locale.toLowerCase());
  }

  formatDateOnly(date: string) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(date, this.$i18n.locale.toLowerCase(), false);
  }

  get documentDeleteAfter() {
    return this.formatDateOnly(this.document?.deleteAfter);
  }

  get showFutureFeature() {
    return GeneralUtils.showFutureFeature;
  }
  async mounted() {
    await Promise.all([
      //this.actionGetDocument(this.documentId),
      this.getDocumentPropertys({ documentId: this.documentId }),
    ]);
    if (this.$route.params && this.$route.params['threadId']) {
      await this.actionGetDocument(this.documentId).then(() => {
        this.openQaSubmenu();
      });
      this.actionGetMainAllocationsByDocumentId(this.documentId);
    }
  }
  async created() {
    // (ED-615) open `Notes` when click on link with `Notes` from `document-list`
    if (this.$route.query && this.$route.query['openNotes']) {
      this.openNotesSubmenu();
    }
    if (this.$route.query && this.$route.query['threadId']) {
      await this.actionGetDocument(this.documentId).then(() => {
        this.openQaSubmenu();
      });
    }
    // await this.actionGetDocument(this.documentId); // (ED-967) comment extra request, because will use data from state or from `document-preview` request (same request)
    this.actionGetMainAllocationsByDocumentId(this.documentId);
  }

  //#region Move to `trash` folder logic
  @documentModule.Action('updateDeletedStatus')
  private actionUpdateDeletedStatus!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;
  @fileModule.Action('getFilesForDocumentId')
  private actionGetFilesForDocumentId!: any;
  @fileModule.Getter('getFiles')
  private getterFile!: any;
  @fileModule.Action('getFilesForUnmergeForDocumentId')
  private actionGetFilesForUnmergeForDocumentId!: any;

  @documentModule.Action('getDocumentFoldersCounts')
  private actionGetDocumentFoldersCounts!: any;

  @documentModule.Action('removeDeleteAfter')
  private actionRemoveDeleteAfter!: any;

  //#region : DeleteDialog
  private isSaveBtnLoading = false;

  onClickDelete() {
    this.deleteDialog.model = {};
    this.deleteDialog.show = true;
  }

  deleteDialog = {
    show: false,
    model: {},
  };

  async deleteDialogOnClose() {
    this.deleteDialog.show = false;
  }

  async deleteDialogOnDelete(date: string) {
    this.deleteDialog.show = false;

    await this.actionUpdateDeletedStatus({ documentIds: [this.document?.id], isRecover: false, deleteAfter: date })
      .then(() => {
        this.actionGetDocuments().then(() => {
          this.actionGetDocumentFoldersCounts(); // (ED-1196) Update `left-menu` count after moving documents to `trash` folder
        }); // update document-list after moving data to `trash` folder
        this.$router.push('/dashboard/organization');
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  //#endregion

  private async moveToBin() {
    logger.debug('document_moveToBin');
    // TODO: (ED-167) query getDocument by ID if `getterGetDocuments` is empty (when navigate to `document-preview` via browser at once, without entering the `document-LIST` component)
    if (!this.document?.id) {
      logger.error('something went wrong to get Document');
      logger.debug('getterGetDocument', this.getterGetDocument);
      return;
    }

    this.onClickDelete();
  }
  //#endregion

  private deleteDocument() {
    logger.debug('deleteDocument');
  }

  private isFolderMenu = false;
  private isFilesMenu = false;
  private isInformationMenu = false;
  private isTemplateMenu = false;
  private isNotesMenu = false;
  private isGobdMenu = false;
  private isAccountMenu = false;
  private isQaMenu = false;
  private isLogMenu = false;
  private isSplitPdfMenu = false;

  private openFolderSubmenu() {
    this.isFolderMenu = true;
    this.drawerSubRight = true;
  }

  private openFilesSubmenu() {
    this.isFilesMenu = true;
    this.drawerSubRight = true;
  }

  private openInformationSubmenu() {
    this.isInformationMenu = true;
    this.drawerSubRight = true;
  }

  private openTemplateSubmenu() {
    this.isTemplateMenu = true;
    this.drawerSubRight = true;
  }

  private openNotesSubmenu() {
    this.isNotesMenu = true;
    this.drawerSubRight = true;
  }

  private openGobdSubmenu() {
    this.isGobdMenu = true;
    this.drawerSubRight = true;
  }

  private openAccountSubmenu() {
    this.isAccountMenu = true;
    this.drawerSubRight = true;
  }

  private editAccount(id: string) {
    this.accountId = id;
    this.openAccountSubmenu();
  }

  private copyAccount(id: string) {
    this.actionCopyAllocation(id).then(() => {
      this.actionGetMainAllocationsByDocumentId(this.documentId);
    });
  }

  private openQaSubmenu() {
    this.isQaMenu = true;
    this.drawerSubRight = true;
  }

  private openLogSubMenu() {
    this.isLogMenu = true;
    this.drawerSubRight = true;
  }

  private openPdfSplitSubMenu() {
    this.isSplitPdfMenu = true;
    this.drawerSubRight = true;
  }

  private returnToMain() {
    this.isFolderMenu = false;
    this.isFilesMenu = false;
    this.isInformationMenu = false;
    this.isTemplateMenu = false;
    this.isNotesMenu = false;
    this.isGobdMenu = false;
    this.isAccountMenu = false;
    this.isQaMenu = false;
    this.isLogMenu = false;
    this.isSplitPdfMenu = false;

    this.drawerSubRight = false;
  }

  private key = 0;
  private returnToMainFromTemplates() {
    this.actionGetDocument(this.documentId).then(() => {
      this.key = Math.floor(Math.random() * 6) + 1;
      this.returnToMain();
    });
  }

  private closeAccountDialog() {
    this.returnToMain();
    this.accountId = '';
    this.actionGetMainAllocationsByDocumentId(this.documentId);
  }

  get isSeveralFiles() {
    const result = this.getterGetDocument?.documentFiles!.length > 1;
    logger.debug(`isDokumenteTrennen:${result}`);
    return result;
  }

  // TODO: (EGRUP-172) optimize logic when more submenus
  private resetSubMenus() {
    this.returnToMain();
  }

  //#region Gobd btn classes logic
  get gobdBtnClassObject() {
    return {
      'yellow-bg': this.documentGobdYellow,
      'green-bg': this.documentGobdGreen,
      'blue-bg': this.documentGobdBlue,
    };
  }

  get documentHasGobd() {
    return !!this.getterGetDocument?.documentGobdDatum; // if no Gobd for document then `documentGobdDatum` will be NULL, i.e. return false
  }

  get documentGobdYellow() {
    return GobdHelper.checkIfGobdYellow(this.getterGetDocument);
  }

  get documentGobdGreen() {
    return GobdHelper.checkIfGobdGreen(this.getterGetDocument);
  }

  get documentGobdBlue() {
    return GobdHelper.checkIfGobdBlue(this.getterGetDocument);
  }

  get showGobCheckeddInfo() {
    return GobdHelper.checkIfAllGobdBackendFieldsVerifiedForGreen(this.getterGetDocument);
  }
  //#endregion

  //#region TemplateMarkups
  get documentHasTemplateMarkups() {
    return this.getterGetDocument.haveTemplateMarkups;
  }

  get templatemarkupsBtnClassObject() {
    return {
      'yellow-bg': this.documentHasTemplateMarkups,
    };
  }

  //#endregion

  //#region Logic connected with `document-favorite-info-fields` component and `documentPropertys`
  get documentPropertys() {
    return this.documentProperties?.items;
  }

  get documentHasPropertys() {
    return !!this.documentProperties?.items; // if no DocumentPropertys for document then `documentPropertys` will be NULL, i.e. return false
  }

  get hasInforamtion() {
    const values = this.documentProperties?.items.map((x) => x.value).join('') + '';
    return (
      values.length > 0 ||
      !isBlank(this.document.vorkontiert) ||
      !isBlank(this.document.addressId) ||
      !isBlank(this.document.iban) ||
      !isBlank(this.document.kundennummer) ||
      !isBlank(this.document.steuernummer)
    );
  }

  get informationBtnClassObject() {
    return {
      'yellow-bg': this.hasInforamtion,
    };
  }

  //#endregion

  //#region Notes btn classes logic
  get notesBtnClassObject() {
    return {
      'yellow-bg': this.documentHasNotes,
    };
  }

  get documentHasNotes() {
    return (
      !!this.getterGetDocument?.documentDetailComments && this.getterGetDocument?.documentDetailComments.length > 0
    ); // if no Notes for document then `documentGobdDatum` will be NULL, i.e. return false
  }
  //#endregion

  //#region (ED-944) QA (Frage & Antworten) btn classes logic
  get qaBtnClassObject() {
    return {
      'yellow-green-bg': this.isAllQuestionClosed || this.isAllTaskClosed || this.isAllCircularClosed,
      //'yellow-bg': this.documentHasQa,
      'yellow-bg': this.documentHasOpenQuestion || this.documentHasOpenTask || this.documentHasOpenCircular,
      'info-bg':
        (this.hasQuestionDate && !this.isQuestionDateExpired) ||
        (this.hasTaskDate && !this.isTaskDateExpired) ||
        (this.hasCircularDate && !this.isCircularDateExpired),
      'alert-bg':
        (this.hasQuestionDate && this.isQuestionDateExpired) ||
        (this.hasTaskDate && this.isTaskDateExpired) ||
        (this.hasCircularDate && this.isCircularDateExpired),
    };
  }

  // get documentHasQa() {
  //   return !!this.getterGetDocument?.actionLogs && this.getterGetDocument?.actionLogs.length > 0; // if no QA for document then `actionLogs` will be NULL, i.e. return false
  // }

  //question
  @documentModule.Getter('isAllQuestionClosed')
  isAllQuestionClosed!: boolean;

  @documentModule.Getter('hasOpenQuestion')
  documentHasOpenQuestion!: boolean;

  get hasQuestionDate(): boolean {
    return this.OpenQuestionDate ? true : false;
  }

  get isQuestionDateExpired() {
    return this.OpenQuestionDate && this.isDateExpired(this.OpenQuestionDate);
  }

  get OpenQuestionDate() {
    return ActionLogsHelper.OpenQuestionDate(this.getterGetDocument?.actionLogs);
  }

  // task
  @documentModule.Getter('isAllTaskClosed')
  isAllTaskClosed!: boolean;
  @documentModule.Getter('hasOpenTask')
  documentHasOpenTask!: boolean;

  get hasTaskDate(): boolean {
    return this.OpenTaskDate ? true : false;
  }

  get isTaskDateExpired() {
    return this.OpenTaskDate && this.isDateExpired(this.OpenTaskDate);
  }

  get OpenTaskDate() {
    return ActionLogsHelper.OpenTaskDate(this.getterGetDocument?.actionLogs);
  }

  // circular
  @documentModule.Getter('isAllCircularClosed')
  isAllCircularClosed!: boolean;

  @documentModule.Getter('hasOpenCircular')
  documentHasOpenCircular!: boolean;

  get hasCircularDate(): boolean {
    return this.OpenCircularDate ? true : false;
  }

  get isCircularDateExpired() {
    return this.OpenCircularDate && this.isDateExpired(this.OpenCircularDate);
  }

  get OpenCircularDate() {
    return ActionLogsHelper.OpenCircularDate(this.getterGetDocument?.actionLogs);
  }

  // utils
  private todayDateIso = new Date().toISOString().slice(0, 10);
  private isDateExpired(date: any) {
    if (date.includes('.')) {
      date = DateUtils.dotDateToIsoDate(date)!;
    }
    return new Date(date) < new Date(this.todayDateIso);
  }

  //#endregion

  private async splitDocuments() {
    logger.debug('splitDocuments');
    this.$confirm
      .open(`${this.$t('move_to_bin_dialog_title')}`, `${this.$t('move_to_bin_dialog_text')}`)
      .then(async (response: any) => {});
  }

  //#region Document AutoGoBd logic
  private showDocumentAutoGobdDialog() {
    this.dialogDocumentAutoGobd.show = true;
  }

  dialogDocumentAutoGobd = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAutoGobd.show = false;
    },
  };
  //#endregion

  //#region Document Rights Dialog logic
  private showDocumentRightsDialog() {
    this.dialogDocumentRights.model = this.document.id;
    this.dialogDocumentRights.show = true;
  }

  dialogDocumentRights = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentRights.show = false;
    },
  };
  //#endregion

  //#region Document UnMerge Dialog logic
  private showDocumentsUnMergeDialog() {
    var filesForUnmerge: File[] = [];
    this.actionGetFilesForUnmergeForDocumentId(this.documentId).then((res: any) => {
      filesForUnmerge = res.value;
      this.dialogDocumentsUnMerge.show = true;
      this.dialogDocumentsUnMerge.model = Object.assign({}, filesForUnmerge);
    });
  }

  dialogDocumentsUnMerge = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentsUnMerge.show = false;
    },
    OnUnmerge: () => {
      this.actionGetDocuments();
      this.dialogDocumentsUnMerge.show = false;
    },
  };
  //#endregion

  //#region Restore Document dialog logic
  openRestoreDialog() {
    this.dialogDocumentRestore.show = true;
  }

  dialogDocumentRestore = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentRestore.show = false;
    },
  };
  //#endregion

  //#region (ED-424) Document Dates dialog logic
  removeDocument() {
    this.dialogDocumentsDelete.show = true;
    this.dialogDocumentsDelete.deleteAll = false;
    this.dialogDocumentsDelete.model = [this.document.id];
  }

  dialogDocumentsDelete = {
    show: false,
    deleteAll: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentsDelete.show = false;
    },
  };
  //#endregion

  removeDeleteAfter() {
    this.document.deleteAfter = '';
    this.actionRemoveDeleteAfter({ id: this.document.id });
  }
  getUserEmail(documentGobdDatum: { user: { email: string } }) {
    return documentGobdDatum.user?.email;
  }

  printDocument() {
    window.print();
  }
}
