import { GetterTree } from 'vuex';
import { AllocationTaxSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationTaxSettingState, RootState> = {
  ['getAllocationTaxSettings']: (state) => state.allocationTaxSettings,
  ['getAllocationTaxSettingsIsLoading']: (state) => state.allocationTaxSettings?.isLoading,
  ['getAllocationTaxSettingsTotal']: (state) => state.allocationTaxSettings?.total,
  ['getAllocationTaxSettingsSearchParams']: (state) => state.allocationTaxSettings?.searchParams,
  ['getAllocationTaxSettingsAllForCombo']: (state) => state.allocationTaxSettingsAllForCombo,
};
