import Vue from 'vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { RequestType, ThreadType } from '../thread-request-qa-types';
import { ActionLog } from '@/shared/model/actionLog';
import DocumentQaDialog from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-qa/document-qa-dialog/document-qa-dialog.vue';
import { OdataItems } from '@/shared/model/OdataItems';
import updatedDiff from '@/shared/utils/deep-object-utils';

const logger = new Logger('qa-overview-dialog');
const actionLogModule = namespace('actionLog');
const authModule = namespace('auth');
@Component({
  name: 'qa-overview-dialog',
  components: { DocumentQaDialog },
})
export default class QaOverviewDialog extends Vue {
  @actionLogModule.Action('getActionLogsQaOverview')
  private getActionLogsQaOverview!: any;
  @actionLogModule.Getter('getActionLogsQaOverview')
  private actionLogsQaOverview!: OdataItems<ActionLog>;

  @authModule.Getter('getAccount')
  private account!: any;
  toggle_close = 'open';
  @Watch('toggle_close')
  onToggleClose(nValue?: 'open' | 'close' | 'all', oValue?: 'open' | 'close' | 'all') {
    this.getActionLogsQaOverview({
      searchData: { type: RequestType.start, closed: nValue, page: oValue == nValue ? undefined : 1 },
    });
  }
  @Prop({ default: false })
  private dialog!: boolean;

  private documentQaArray: Array<any> = [];
  get documentQAs() {
    return this.actionLogsQaOverview.items;
  }

  async created() {
    const searchData: any = { type: RequestType.start, closed: this.toggle_close };

    await this.getActionLogsQaOverview({ searchData })
      .then((result: any) => {
        this.documentQaArray = result.value;

        this.formatActionLogDataField();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  formatActionLogDataField() {
    if (this.documentQaArray.length > 0) {
      this.documentQaArray.forEach((element: any) => {
        if (element.data && element.data.notificationType) return; // skip parsing because this ActionLog was already parsed
        // element.data = JSON.parse(element.data);
      });
    }
  }

  members(item: any) {
    return item?.data?.members?.length > 0 ? item.data.members.join(', ') : '';
  }
  roles(item: any) {
    return item?.data?.roles?.length > 0 ? item.data.roles.map((x: any) => `Gruppe ${x}`).join(', ') : '';
  }

  formatMessage(item: any) {
    const members = this.members(item);
    const roles = this.roles(item);
    const memberRolesText = `${members ? members + ', ' : ''} ${roles}`;
    const formattedMessage = this.getMessageBasedOnType(item.threadType, memberRolesText);
    return formattedMessage;
  }

  getMessageBasedOnType(threadType: ThreadType, memberRolesText: string) {
    let result = memberRolesText;
    memberRolesText = memberRolesText.trim() ? memberRolesText : `${this.$t('all_employees')}`;
    switch (threadType) {
      case ThreadType.question:
        result = `${this.$t('question_asked_of_x_employees', { memberRolesText })}`;
        break;
      case ThreadType.task:
        result = `${this.$t('task_given_to_x_employees', { memberRolesText })}`;
        break;
      case ThreadType.circular:
        result = `${this.$t('circulars_provided_to_x_employees', { memberRolesText })}`;
        break;

      default:
        break;
    }
    return result;
  }

  openQa(item: any) {
    // logger.info('openQA', item);
    this.$emit('open:qa', item);
    this.$router.push(`/document/${item?.documentId}/qa-task/${item.actionLogId}`);
  }

  clickClose() {
    this.$emit('click:close');
  }

  /// --------------

  get documentId() {
    return this.$route.params['documentId'];
  }

  private createNewTopic() {
    logger.debug('createNewTopic');
    this.showDocumentQaDialog();
  }

  @actionLogModule.Action('getActionLogsQa')
  private getActionLogsQa!: any;
  @actionLogModule.Getter('getActionLogsQa')
  private getterActionLogsQa!: any;

  //#region Document QA Dialog logic
  private showDocumentQaDialog(qaId?: string) {
    this.dialogDocumentQa.show = true;
    this.dialogDocumentQa.startMessageId = qaId || '';
    this.dialogDocumentQa.model = { id: this.documentId, organisationId: this.account.profile.current_organization_id };
  }

  dialogDocumentQa = {
    show: false,
    model: {},
    startMessageId: '',
    OnClose: () => {
      this.useActionLogGetterData();
      this.dialogDocumentQa.show = false;
    },
    showQaOverviewDialog: () => {
      this.getActionLogsQaOverview({ searchData: { type: RequestType.start, closed: this.toggle_close } });
      this.showQaOverviewDialog();
    },
  };

  private useActionLogGetterData() {
    if (this.getterActionLogsQa && this.getterActionLogsQa.items?.length > 0) {
      this.documentQaArray = this.getterActionLogsQa.items.filter((x: any) => x.type === RequestType.start);

      this.formatActionLogDataField();
    }
  }

  private checkIfOpenFromEmail() {
    if (this.$route.query && this.$route.query['threadId']) {
      this.showDocumentQaDialog(this.$route.query['threadId'] + '');
    }
  }
  //#endregion

  //#region QA Overview Dialog logic
  private showQaOverviewDialog() {
    this.dialogDocumentQa.show = false;
    this.dialogQaOverview.show = true;
  }

  dialogQaOverview = {
    show: false,
    model: {},

    openQaFromOverview: (qa: ActionLog) => {
      this.showDocumentQaDialogFromOverview(qa);
    },
    OnClose: () => {
      this.dialogQaOverview.show = false;
    },
  };

  private showDocumentQaDialogFromOverview(qa: ActionLog) {
    this.dialogDocumentQa.show = true;
    this.dialogDocumentQa.startMessageId = qa.id;
    this.dialogDocumentQa.model = { id: qa.documentId, organisationId: qa.organisationId };
  }

  //#endregion
  @Watch('actionLogsQaOverview.searchParams.dataOption', { deep: true })
  public async onOptionsPageChanged(newVal: any, oldVal: any) {
    const diff = updatedDiff(newVal, oldVal);
    if (Object.keys(diff).length !== 0) {
      await this.getActionLogsQaOverview({ searchData: { type: RequestType.start, closed: this.toggle_close } });
    }
  }
  @Watch('actionLogsQaOverview.searchParams.dataOption.page')
  public async onOptionsPageChange(newVal: any, oldVal: any) {
    if (newVal !== oldVal) {
      await this.getActionLogsQaOverview({ searchData: { type: RequestType.start, closed: this.toggle_close } });
    }
  }
}
