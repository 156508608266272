import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { defaultBackendAccount } from '../../../backend/account';
import { Credetials } from '../../../model/credentials';
import store from '../../index';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import { DefaultAuthService } from '@/shared/utils/AuthService';
import i18n from '@/i18n';
import Vue from 'vue';

const logger = new Logger('actions');
export const actions: ActionTree<AuthState, RootState> = {
  /**
   * Login to the backend.
   */
  async login({ commit, dispatch }, payload: { organizationId?: string; impersonateId?: string }) {
    commit('document/resetDocumentsSearchPayload', true, { root: true });
    return DefaultAuthService.login(payload.organizationId, payload.impersonateId);
  },
  async signinRedirectCallback({ commit, dispatch }) {
    return DefaultAuthService.signinRedirectCallback();
  },
  async signinRedirect({ commit, dispatch }, payload?: any) {
    return DefaultAuthService.mgr.signinRedirect(payload).then((result: any) => {
      commit('setAccount', result);
      commit('setToken', result);
    });
  },
  async signinSilent({ commit, dispatch }, payload?: any) {
    return DefaultAuthService.mgr
      .signinSilent(payload)
      .then((result: any) => {
        commit('setAccount', result);
        commit('setToken', result);
      })
      .catch((err) => {
        logger.error(err);
        commit('setAccount', undefined);
        commit('clearTokens');
        commit('document/resetDocumentsSearchPayload', null, { root: true }); // (ED-1254)
        store.dispatch('resetAllState');
        DefaultAuthService.logout();
        Vue.prototype.stopSignalR();
      });
  },
  async signinSilentCallback({ commit, dispatch }, payload?: any) {
    return DefaultAuthService.mgr.signinSilentCallback(payload);
  },

  async getUser({ commit }) {
    return DefaultAuthService.getUser()

      .then((result) => {
        commit('setAccount', result);
        commit('setToken', result);
        return result;
      })
      .catch((e) => {
        commit('setAccount', undefined);
        module_utils.error(i18n.t('error'), commit, e, logger);
        throw new Error(i18n.t('error').toString());
      });
  },
  /**
   * Clears the access token, effectively logging out the user.
   */
  logout({ commit }) {
    commit('setAccount', undefined);
    commit('clearTokens');
    commit('document/resetDocumentsSearchPayload', null, { root: true }); // (ED-1254)
    store.dispatch('resetAllState');
    DefaultAuthService.logout();
    Vue.prototype.stopSignalR();
  },

  updateHeaderNavData({ commit }, headerData: { translateId: string; to: string }) {
    commit('setHeaderNavData', headerData);
  },
  updateLeftMenuOpenState({ commit }, showLeftMenu: boolean) {
    commit('setIsLeftDrawerShown', showLeftMenu);
  },

  async impersonateUser({ commit }, userId: string) {
    return defaultBackendAccount
      .impersonateUser(userId)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        logger.error(e);
      });
  },
};
