import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import autoGobdCheckTerm, { AutoGobdCheckTerm, Value } from '../model/autoGobdCheckTerm';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AutoGobdCheckTerm');
export interface BackendAutoGobdCheckTerm {
  runAutoGobdCheckTerm: (payload: { autoGobdCheckTermId: string; organisationId: string }) => AxiosPromise<any>;
  getAutoGobdCheckTerm(id: string): AxiosPromise<AutoGobdCheckTerm>;
  getAutoGobdCheckTerms: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAutoGobdCheckTerm(id: string): AxiosPromise;
  updateAutoGobdCheckTerm(AutoGobdCheckTerm: AutoGobdCheckTerm): AxiosPromise<any>;
}

export const defaultBackendAutoGobdCheckTerm: BackendAutoGobdCheckTerm = {
  getAutoGobdCheckTerm(id: string): AxiosPromise<AutoGobdCheckTerm> {
    const url = `${URLS.autoGobdCheckTermOdata}/${id}`;
    return instance.get<AutoGobdCheckTerm>(url);
  },
  getAutoGobdCheckTerms(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.autoGobdCheckTermOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAutoGobdCheckTerms${url}`);
    return instance.get<Value>(url);
  },

  deleteAutoGobdCheckTerm(id: string): AxiosPromise {
    logger.debug('deleteAutoGobdCheckTerm');
    return instance.delete(`${URLS.autoGobdCheckTerm}/${id}`);
  },
  updateAutoGobdCheckTerm(AutoGobdCheckTerm: AutoGobdCheckTerm): AxiosPromise<any> {
    logger.debug('updateAutoGobdCheckTerm');
    return instance.put<AutoGobdCheckTerm>(
      `${URLS.autoGobdCheckTerm}/update`,
      autoGobdCheckTerm.toAPI(AutoGobdCheckTerm)
    );
  },
  runAutoGobdCheckTerm(payload: { autoGobdCheckTermId: string; organisationId: string }): AxiosPromise<any> {
    logger.debug('runAutoGobdCheckTerm');
    return instance.put<AutoGobdCheckTerm>(`${URLS.autoGobdCheckTerm}/run/${payload.autoGobdCheckTermId}`);
  },
};
