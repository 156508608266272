import { GetterTree } from 'vuex';
import { SuppliersImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SuppliersImportSettingState, RootState> = {
  ['getSuppliersImportSettings']: (state) => state.suppliersImportSettings,
  ['getSuppliersImportSettingsIsLoading']: (state) => state.suppliersImportSettings?.isLoading,
  ['getSuppliersImportSettingsTotal']: (state) => state.suppliersImportSettings?.total,
  ['getSuppliersImportSettingsSearchParams']: (state) => state.suppliersImportSettings?.searchParams,
  ['getSuppliersImportSetting']: (state) => state.suppliersImportSetting,
  ['getInProgressUpdateSuppliersImportSetting']: (state) => state.inProgressUpdateSuppliersImportSetting,
};
