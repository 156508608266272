export interface AllocationTemplateProperty {
  id: string; // System.Guid
  allocationTemplateId: string; // System.Guid
  allocationFieldId: string; // System.Guid
  value: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationId: string; // System.Guid
}

export interface Value {
  value: AllocationTemplateProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationTemplateProperty>): any {
  return {
    Id: data?.id || undefined,
    AllocationTemplateId: data?.allocationTemplateId || undefined,
    AllocationFieldId: data?.allocationFieldId || undefined,
    Value: data?.value || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    OrganisationId: data?.organisationId || undefined,
  };
}

function parse(data?: Partial<AllocationTemplateProperty>): AllocationTemplateProperty {
  return {
    id: data?.id || '',
    allocationTemplateId: data?.allocationTemplateId || '',
    allocationFieldId: data?.allocationFieldId || '',
    value: data?.value || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
