import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import suppliersImportSetting, { SuppliersImportSetting, Value } from '../model/suppliersImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.SuppliersImportSetting');
export interface BackendSuppliersImportSetting {
  getSuppliersImportSetting(id: string): AxiosPromise<SuppliersImportSetting>;
  getSuppliersImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteSuppliersImportSetting(id: string): AxiosPromise;
  updateSuppliersImportSetting(SuppliersImportSetting: SuppliersImportSetting): AxiosPromise<any>;
}

export const defaultBackendSuppliersImportSetting: BackendSuppliersImportSetting = {
  getSuppliersImportSetting(id: string): AxiosPromise<SuppliersImportSetting> {
    const url = `${URLS.suppliersImportSettingOdata}/${id}`;
    return instance.get<SuppliersImportSetting>(url);
  },
  getSuppliersImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.suppliersImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getSuppliersImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteSuppliersImportSetting(id: string): AxiosPromise {
    logger.debug('deleteSuppliersImportSetting');
    return instance.delete(`${URLS.suppliersImportSetting}/${id}`);
  },
  updateSuppliersImportSetting(SuppliersImportSetting: SuppliersImportSetting): AxiosPromise<any> {
    logger.debug('updateSuppliersImportSetting');
    return instance.put<SuppliersImportSetting>(
      `${URLS.suppliersImportSetting}/update`,
      suppliersImportSetting.toAPI(SuppliersImportSetting)
    );
  },
};
