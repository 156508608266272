import { MutationTree } from 'vuex';
import { RegexSplitterPatternPartState } from './types';
import Vue from 'vue';
import regexSplitterPatternPart, { RegexSplitterPatternPart, Value } from '@/shared/model/regexSplitterPatternPart';

export const mutations: MutationTree<RegexSplitterPatternPartState> = {
  setRegexSplitterPatternParts(state, payload: Value) {
    state.regexSplitterPatternParts.items = payload.value.map((x) => regexSplitterPatternPart.parse(x));
    state.regexSplitterPatternParts.total = payload['@odata.count'] || 0;
    state.regexSplitterPatternParts.isLoading = false;
  },
  setRegexSplitterPatternPartsTotal(state, payload: number) {
    state.regexSplitterPatternParts.total = payload;
  },
  setRegexSplitterPatternPartsIsLoading(state, payload: boolean) {
    state.regexSplitterPatternParts.isLoading = payload;
  },
};
