import updatedDiff from '../utils/deep-object-utils';

export interface AllocationTemplateEx {
  id: string; // System.Guid
  index: number; // System.Guid
  allocationTemplateId: string; // System.Guid
  organisationId: string; // System.Guid
  name: string; // string
  deleted: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  mainAllocationTemplateId: string; // System.Guid
  sparkle: string; //Belegkreis
  booking_type: string; //Buchungsart
  document_text_1: string; //Belegstext 1
  document_text_2: string; //Belegstext 2
  staff_number: string; //Belegstext 2
  origin: string; //Herkunft
  exp_distribution_channel: string; //Erw. Vertriebsweg
  dealer_own_cost_unit: string; //Händler eigener Kostenträger
  project: string; //Projekt
  discount_rate: string; //Skontosatz
  exchange_rate: string; //Währung
  drive_type: string; //Antriebsart
  account: string; //Antriebsart
  account_group: string;
  contra_account: string;
  amount: string;
  invoice_date: string;
  invoice_number: string;
  tax_key: string;
  tax_key_amount: string;
  posting_text: string;
  branch: string;
  brand: string;
  cost_center: string;
  cost_unit: string;
  serial_number: string;
  sales_channel: string;
  vat_code: string;
  contra_account_group: string | number;
  discount_in: string | number;
  days_clean: string | number;
  days_discount: string | number;
  foreign_currency: string | number;
  denate_date: string;
  compensation_date: string;
  delivery_date: string;
}

// init api object
function toAPI(data: Partial<AllocationTemplateEx>): any {
  return {
    Id: data?.id || undefined,
    Index: data?.index || 0,
    AllocationTemplateId: data?.allocationTemplateId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    Deleted: data?.deleted || false,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    MainAllocationTemplateId: data?.mainAllocationTemplateId || undefined,
    Sparkle: data?.sparkle || '',
    Booking_type: data?.booking_type || '',
    Document_text_1: data?.document_text_1 || '',
    Document_text_2: data?.document_text_2 || '',
    Staff_number: data?.staff_number || '',
    Origin: data?.origin || '',
    Exp_distribution_channel: data?.exp_distribution_channel || '',
    Dealer_own_cost_unit: data?.dealer_own_cost_unit || '',
    Project: data?.project || '',
    Discount_rate: data?.discount_rate || '',
    Exchange_rate: data?.exchange_rate || '',
    Drive_type: data?.drive_type || '',
    Account: data?.account || '',
    Account_group: data?.account_group || '',
    Contra_account: data?.contra_account || '',
    Amount: data?.amount || '',
    Invoice_date: data?.invoice_date || '',
    Invoice_number: data?.invoice_number || '',
    Tax_key: data?.tax_key || '',
    Tax_key_amount: data?.tax_key_amount || '',
    Posting_text: data?.posting_text || '',
    Branch: data?.branch || '',
    Brand: data?.brand || '',
    Cost_center: data?.cost_center || '',
    Cost_unit: data?.cost_unit || '',
    Serial_number: data?.serial_number || '',
    Sales_channel: data?.sales_channel || '',
    Vat_code: data?.vat_code || '',
    Contra_account_group: data?.contra_account_group || '',
    Discount_in: data?.discount_in || '',
    Days_clean: data?.days_clean || '',
    Days_discount: data?.days_discount || '',
    Foreign_currency: data?.foreign_currency || '',
    Denate_date: data?.denate_date || '',
    Compensation_date: data?.compensation_date || '',
    Delivery_date: data?.delivery_date || '',
  };
}

function toObject(data: AllocationTemplateEx): any {
  return {
    '5fc8be6f-64b1-c407-9f49-6e7100000000': { name: 'Antriebsart', value: data.drive_type },
    '53204fe8-b606-aab2-3c55-ecd500000000': { name: 'Marke', value: data.brand },
    '53204fe8-b606-aab2-3c55-ecd300000000': { name: 'Filiale', value: data.branch },
    '53204fe8-b606-aab2-3c55-ecd100000000': { name: 'Persornalnummer', value: data?.staff_number },
    '53204fe8-b606-aab2-3c55-eccf00000000': { name: 'Buchungsart', value: data?.booking_type },
    '53204fe8-b606-aab2-3c55-eccd00000000': { name: 'Belegstext 2', value: data?.document_text_2 },
    '53204fe8-b606-aab2-3c55-eccb00000000': { name: 'Belegstext 1', value: data?.document_text_1 },
    '53204fe8-b606-aab2-3c55-ecd700000000': { name: 'Kostenstelle', value: data.cost_center },
    '53204fe8-b606-aab2-3c55-ecc900000000': { name: 'Buchungstext', value: data.posting_text },
    '53204fe8-b606-aab2-3c55-ecc500000000': { name: 'Betrag', value: data.amount },
    '53204fe8-b606-aab2-3c55-ecc300000000': { name: 'Gegenkontenkreis', value: data.contra_account_group },
    '53204fe8-b606-aab2-3c55-ecc100000000': { name: 'Kontenkreis', value: data.account_group },
    '53204fe8-b606-aab2-3c55-ecbf00000000': { name: 'Gegenkonto', value: data.contra_account },
    '53204fe8-b606-aab2-3c55-ecbd00000000': { name: 'Konto', value: data.account },
    '53204fe8-b606-aab2-3c55-ecbb00000000': { name: 'Belegkreis', value: data.sparkle },
    '53204fe8-b606-aab2-3c55-ecc700000000': { name: 'Rechnungsnummer', value: data.invoice_number },
    '53204fe8-b606-aab2-3c55-ecd900000000': { name: 'Kostenträger', value: data.cost_unit },
    '53204fe8-b606-aab2-3c55-ecdb00000000': { name: 'Herkunft', value: data?.origin },
    '53204fe8-b606-aab2-3c55-ecdd00000000': { name: 'Absatzkanal', value: data.sales_channel },
    '59a7cbe9-80dc-8475-077b-23c600000000': { name: 'Fremdwährung', value: data.foreign_currency },
    '59a3f424-5746-d9f7-687e-6b8300000000': { name: 'Währung', value: data.exchange_rate },
    '537b2cb2-09bb-e01a-1529-3c9e00000000': { name: 'Skontosatz', value: data.discount_rate },
    '537b2cb2-09bb-e01a-1529-3c9c00000000': { name: 'Tage skonto', value: data.days_discount },
    '537b2cb2-09bb-e01a-1529-3c9a00000000': { name: 'Tage netto', value: data.days_clean },
    '5368eb93-09bb-e067-0911-69e200000000': { name: 'Skonto in &euro;', value: data.discount_in },
    '53204fe8-b606-aab2-3c55-ecef00000000': { name: 'Lieferdatum', value: data.delivery_date },
    '53204fe8-b606-aab2-3c55-eced00000000': { name: 'Ausgleichsdatum', value: data.compensation_date },
    '53204fe8-b606-aab2-3c55-eceb00000000': { name: 'Seriennummer', value: data.serial_number },
    '53204fe8-b606-aab2-3c55-ece900000000': { name: 'USt Code', value: data.vat_code },
    '53204fe8-b606-aab2-3c55-ece700000000': { name: 'Steuer-Schlüssel', value: data.tax_key },
    '53204fe8-b606-aab2-3c55-ece500000000': { name: 'Kontierungsdatum', value: data.denate_date },
    '53204fe8-b606-aab2-3c55-ece300000000': { name: 'Projekt', value: data.project },
    '53204fe8-b606-aab2-3c55-ece100000000': { name: 'Händler eigener Kostenträger', value: data?.dealer_own_cost_unit },
    '53204fe8-b606-aab2-3c55-ecdf00000000': { name: 'Erw. Vertriebsweg', value: data?.exp_distribution_channel },
    '53204fe8-b606-aab2-3c55-ecb900000000': { name: 'Rechnungsdatum', value: data.invoice_date },
    'a98a9b59-8786-405d-bcfa-80bf4aea97c8': { name: 'Steuerbetrag', value: data.tax_key_amount },
  };
}

function parse(data?: Partial<AllocationTemplateEx>): AllocationTemplateEx {
  return {
    id: data?.id || '',
    index: data?.index || 0,
    allocationTemplateId: data?.allocationTemplateId || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    deleted: data?.deleted || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    mainAllocationTemplateId: data?.mainAllocationTemplateId || '',
    sparkle: data?.sparkle || '',
    booking_type: data?.booking_type || '',
    document_text_1: data?.document_text_1 || '',
    document_text_2: data?.document_text_2 || '',
    staff_number: data?.staff_number || '',
    origin: data?.origin || '',
    exp_distribution_channel: data?.exp_distribution_channel || '',
    dealer_own_cost_unit: data?.dealer_own_cost_unit || '',
    project: data?.project || '',
    discount_rate: data?.discount_rate || '',
    exchange_rate: data?.exchange_rate || '',
    drive_type: data?.drive_type || '',
    account: data?.account || '',
    account_group: data?.account_group || '',
    contra_account: data?.contra_account || '',
    amount: data?.amount || '',
    invoice_date: data?.invoice_date || '',
    invoice_number: data?.invoice_number || '',
    tax_key: data?.tax_key || '',
    tax_key_amount: data?.tax_key_amount || '',
    posting_text: data?.posting_text || '',
    branch: data?.branch || '',
    brand: data?.brand || '',
    cost_center: data?.cost_center || '',
    cost_unit: data?.cost_unit || '',
    serial_number: data?.serial_number || '',
    sales_channel: data?.sales_channel || '',
    vat_code: data?.vat_code || '',
    contra_account_group: data?.contra_account_group || '',
    discount_in: data?.discount_in || '',
    days_clean: data?.days_clean || '',
    days_discount: data?.days_discount || '',
    foreign_currency: data?.foreign_currency || '',
    denate_date: data?.denate_date || '',
    compensation_date: data?.compensation_date || '',
    delivery_date: data?.delivery_date || '',
  };
}

function compare(obj1: AllocationTemplateEx, obj2: AllocationTemplateEx) {
  const diff = updatedDiff(parse(obj1), parse(obj2));
  return Object.keys(diff).length == 0;
}

export default {
  parse,
  toAPI: toAPI,
  toObject,
  compare,
};
