import { MutationTree } from 'vuex';
import { DocumentPagesTextState } from './types';
import Vue from 'vue';
import documentPagesText, { DocumentPagesText, Value } from '@/shared/model/documentPagesText';

export const mutations: MutationTree<DocumentPagesTextState> = {
  setDocumentPagesTexts(state, payload: Value) {
    state.documentPagesTexts.items = payload.value.map((x) => documentPagesText.parse(x));
    state.documentPagesTexts.total = payload['@odata.count'] || 0;
    state.documentPagesTexts.isLoading = false;
  },
  setDocumentPagesTextsTotal(state, payload: number) {
    state.documentPagesTexts.total = payload;
  },
  setDocumentPagesTextsIsLoading(state, payload: boolean) {
    state.documentPagesTexts.isLoading = payload;
  },
};
