import { ActionTree } from 'vuex';
import { RoleRightState } from './types';
import { RootState } from '../../types';
import { defaultBackendRoleRight } from '@/shared/backend/roleRight';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import roleRight, { Value, RoleRight } from '@/shared/model/roleRight';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.roleRights');
export const actions: ActionTree<RoleRightState, RootState> = {
  getRoleRights({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; roleId: string }) {
    commit('setRoleRightsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRoleRightsSearchParams;
    return defaultBackendRoleRight
      .getRoleRights(searchParams, payload?.roleId)
      .then((response: AxiosResponse<Value>) => {
        commit('setRoleRights', response.data);
        commit('setRoleRightsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRoleRightsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRoleRight({ commit, dispatch }, id: string) {
    return defaultBackendRoleRight
      .getRoleRight(id)
      .then((response: AxiosResponse<RoleRight>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRoleRight({ commit, dispatch }, file: RoleRight) {
    commit('setInProgressUpdateRoleRights', true);
    return defaultBackendRoleRight
      .updateRoleRight(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoleRight;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.role_right_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateRoleRights', false);
      });
  },
};
