import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationTaxGroup, { AllocationTaxGroup, Value } from '../model/allocationTaxGroup';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationTaxGroup');
export interface BackendAllocationTaxGroup {
  getAllocationTaxGroup(id: string): AxiosPromise<AllocationTaxGroup>;
  getAllocationTaxGroups: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationTaxGroup(id: string): AxiosPromise;
  updateAllocationTaxGroup(AllocationTaxGroup: AllocationTaxGroup): AxiosPromise<any>;
  getAllocationTaxSettings: (
    searchParams: SearchParams,
    organizationId: string,
    allocationTaxGroupsId: string
  ) => AxiosPromise<Value>;
}

export const defaultBackendAllocationTaxGroup: BackendAllocationTaxGroup = {
  getAllocationTaxGroup(id: string): AxiosPromise<AllocationTaxGroup> {
    const url = `${URLS.allocationTaxGroupOdata}/${id}`;
    return instance.get<AllocationTaxGroup>(url);
  },
  getAllocationTaxGroups(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxGroupOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=AllocationTaxSettings($count=true;$top=10),AllocationTaxGroupsImportSetting']
    );
    logger.log(`getAllocationTaxGroups${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationTaxGroup(id: string): AxiosPromise {
    logger.debug('deleteAllocationTaxGroup');
    return instance.delete(`${URLS.allocationTaxGroup}/${id}`);
  },
  updateAllocationTaxGroup(AllocationTaxGroup: AllocationTaxGroup): AxiosPromise<any> {
    logger.debug('updateAllocationTaxGroup');
    return instance.put<AllocationTaxGroup>(
      `${URLS.allocationTaxGroup}/update`,
      allocationTaxGroup.toAPI(AllocationTaxGroup)
    );
  },
  getAllocationTaxSettings(
    searchParams: SearchParams,
    organizationId: string,
    allocationTaxGroupsId: string
  ): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false).eq('AllocationTaxGroupsId', allocationTaxGroupsId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );

    return instance.get<Value>(url);
  },
};
