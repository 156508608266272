import store from '@/shared/store';
import { RouteConfig } from 'vue-router';
import { Logger } from 'fsts';
const logger = new Logger('routes');
//TODO move route.name & route.path? to enum ROUTES
export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Home.vue'),
  },
  {
    path: '/password_reset',
    name: 'Password reset',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/forget-password/forget-password.vue'),
  },
  {
    path: '/registrieren', // path from `https://neu.easy-docs.de/`
    name: 'Registration',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/registration-via-email/registration-via-email.vue'),
  },
  {
    path: '/impressum', // path from `https://neu.easy-docs.de/`
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/impressum/impressum.vue'),
  },
  {
    path: `/setNewPassword`,
    name: 'set-new-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/new-password/new-password.vue'),
    meta: {
      title: 'Set new password',
      requiresAuth: false,
    },
  },
  {
    path: '/datenschutz', // path from `https://neu.easy-docs.de/`
    name: 'Dataprotection',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/dataprotection/dataprotection.vue'),
  },
  {
    path: '/agb', // path from `https://neu.easy-docs.de/`
    name: 'Dataterms',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/dataterms/dataterms.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
  },
  {
    path: '/dashboard/organization/:organizationId?/:folderId?/:documentId?',
    alias: '/trash',
    name: 'document-list',
    component: () => import(/* webpackChunkName: "document-list" */ '../views/documentList/document-list.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: () => import('../views/addresses/addresses.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/ViewAddressesAllowed'] || store.getters['auth/isOwner']) {
        next();
      } else {
        logger.log('Attempting to access a secure url:' + to.path);
        next(false);
      }
    },
  },
  {
    path: '/export',
    name: 'export',
    component: () => import('../views/exports/exports.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      { path: 'allocation', component: () => import('../views/exports/allocation/allocation.vue') },
      { path: 'pdf', component: () => import('../views/exports/pdf-export/pdf-export.vue') },
      { path: 'process', component: () => import('../views/exports/process-export/process-export.vue') },
    ],
  },
  {
    path: '/superAdmin',
    name: 'superAdmin',
    component: () => import('../views/superAdmin/superAdmin.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isSiteOwner']) {
        next();
      } else {
        console.error('Attempting to access a secure url:' + to.path);
        next();
      }
    },
    children: [
      { path: 'admin', component: () => import('../views/superAdmin/admin/admin.vue') },
      { path: 'data', component: () => import('../views/superAdmin/data/data.vue') },
      { path: 'customer', component: () => import('../views/superAdmin/customer/customer.vue') },
      { path: 'healthcheck', component: () => import('../views/superAdmin/healthcheck/healthcheck.vue') },
      {
        path: 'organizationExport',
        component: () => import('../views/superAdmin/organizationExport/organizationExport.vue'),
      },
      { path: 'reports', component: () => import('../views/superAdmin/reports/reports.vue') },
    ],
  },
  {
    path: '/processes',
    name: 'processes',
    component: () => import('../views/processes/processes.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        store.getters['auth/ViewAllProcessListsAllowed'] ||
        store.getters['auth/ViewProcessListsAllowed'] ||
        store.getters['auth/EditProcessListsAllowed'] ||
        store.getters['auth/isOwner']
      ) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings/settings.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      { path: 'my-data', component: () => import('../views/settings/my-data/my-data.vue') },
      {
        path: 'company',
        component: () => import('../views/settings/company/company.vue'),
        children: [
          { path: '', component: () => import('../views/settings/company/organization/organization.vue') },
          { path: 'organization', component: () => import('../views/settings/company/organization/organization.vue') },
          {
            path: 'employee',
            component: () => import('../views/settings/company/employees-roles/employees-roles.vue'),
          },
          {
            path: 'folder',
            component: () => import('../views/settings/company/folder/folder.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters['auth/SettingsFoldersAllowed'] || store.getters['auth/isOwner']) {
                next();
              } else {
                logger.log('Attempting to access a secure url:' + to.path);
                next(false);
              }
            },
          },
          {
            path: 'documents',
            component: () => import('../views/settings/company/documents/documents.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters['auth/SettingsDocumentsAllowed'] || store.getters['auth/isOwner']) {
                next();
              } else {
                logger.log('Attempting to access a secure url:' + to.path);
                next(false);
              }
            },
          },
          {
            beforeEnter: (to, from, next) => {
              if (store.getters['auth/SettingsMembersAllowed'] || store.getters['auth/isOwner']) {
                next();
              } else {
                logger.log('Attempting to access a secure url:' + to.path);
                next(false);
              }
            },
            path: 'account-assignment',
            component: () => import('../views/settings/company/account-assignment/account-assignment.vue'),
            children: [
              {
                path: '',
                component: () =>
                  import('../views/settings/company/account-assignment/order-and-export/order-and-export.vue'),
              },
              {
                path: 'order-and-export',
                component: () =>
                  import('../views/settings/company/account-assignment/order-and-export/order-and-export.vue'),
              },
              {
                path: 'brands',
                component: () => import('../views/settings/company/account-assignment/brands/brands.vue'),
              },
              {
                path: 'offices',
                component: () => import('../views/settings/company/account-assignment/offices/offices.vue'),
              },
              {
                path: 'tax-groups',
                component: () => import('../views/settings/company/account-assignment/tax-groups/tax-groups.vue'),
              },
              {
                path: 'cost-objectives',
                component: () =>
                  import('../views/settings/company/account-assignment/cost-objectives/cost-objectives.vue'),
              },
              {
                path: 'cost-centers',
                component: () => import('../views/settings/company/account-assignment/cost-centers/cost-centers.vue'),
              },
              {
                path: 'trade-channels',
                component: () =>
                  import('../views/settings/company/account-assignment/trade-channels/trade-channels.vue'),
              },
              {
                path: 'drive-types',
                component: () => import('../views/settings/company/account-assignment/drive-types/drive-types.vue'),
              },
              {
                path: 'suppliers',
                component: () => import('../views/settings/company/account-assignment/suppliers/suppliers.vue'),
              },
              {
                path: 'addresses',
                component: () => import('../views/settings/company/account-assignment/addresses/addresses.vue'),
              },
            ],
          },
          {
            path: 'operations',
            component: () => import('../views/settings/company/operations/operations.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters['auth/SettingsProcessesAllowed'] || store.getters['auth/isOwner']) {
                next();
              } else {
                logger.log('Attempting to access a secure url:' + to.path);
                next(false);
              }
            },
          },
          {
            path: 'incoming-email',
            component: () => import('../views/settings/company/incoming-email/incoming-email.vue'),
          },
          {
            path: 'activities',
            component: () => import('../views/settings/company/activities/activities.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters['auth/SettingsActionLogsAllowed'] || store.getters['auth/isOwner']) {
                next();
              } else {
                logger.log('Attempting to access a secure url:' + to.path);
                next(false);
              }
            },
          },
        ],
      },
      { path: 'subscriptions', component: () => import('../views/settings/subscriptions/subscriptions.vue') },
      { path: 'information', component: () => import('../views/settings/info/info.vue') },
    ],
  },
  {
    path: '/document/:documentId',
    name: 'document-preview',
    component: () =>
      import(/* webpackChunkName: "document-list" */ '../views/documentList/document-preview/document-preview.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'qa-task',
        path: 'qa-task/:threadId',
        component: () =>
          import(
            '../views/menu/rightMainMenu/menu-document-preview/menu-document-qa/document-qa-dialog/document-qa-dialog.vue'
          ),
      },
      {
        name: 'qa-question',
        path: 'qa-question/:threadId',
        component: () =>
          import(
            '../views/menu/rightMainMenu/menu-document-preview/menu-document-qa/document-qa-dialog/document-qa-dialog.vue'
          ),
      },
      {
        name: 'qa-circular',
        path: 'qa-circular/:threadId',
        component: () =>
          import(
            '../views/menu/rightMainMenu/menu-document-preview/menu-document-qa/document-qa-dialog/document-qa-dialog.vue'
          ),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (to.fullPath.indexOf('document/') > 0) {
        const documentId = to.params['documentId'];
        store.dispatch('document/documentExists', documentId).then((result: boolean) => {
          if (result == false) {
            next(false);
          } else {
            next();
          }
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/allocation-details/:accountExportDate/:exportId',
    name: 'allocation-details',
    component: () =>
      import(
        /* webpackChunkName: "document-list" */ '../views/exports/allocation/allocation-details/allocation-details.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/error-access-view',
    name: 'error-access-view',
    component: () => import('../views/errors/error-access.vue'),
  },
  { path: '/callback', name: 'callback', component: () => import('../views/callback/Callback.vue') },
  { path: '/silent-renew', name: 'silent-renew', component: () => import('../views/silent-renew/silent-renew.vue') },
];
