import { GetterTree } from 'vuex';
import { AllocationTemplatePropertyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationTemplatePropertyState, RootState> = {
  ['getAllocationTemplatePropertys']: (state) => state.allocationTemplatePropertys,
  ['getAllocationTemplatePropertysIsLoading']: (state) => state.allocationTemplatePropertys?.isLoading,
  ['getAllocationTemplatePropertysTotal']: (state) => state.allocationTemplatePropertys?.total,
  ['getAllocationTemplatePropertysSearchParams']: (state) => state.allocationTemplatePropertys?.searchParams,
};
