import costCentreImportSetting from '@/shared/model/costCentreImportSetting';
import { CostCentreImportSettingState } from './types';

export const state = (): CostCentreImportSettingState => initialState();

export const initialState = (): CostCentreImportSettingState => ({
  costCentreImportSettings: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['CreatedAt'],
    },
  },
  costCentreImportSetting: costCentreImportSetting.parse({}),
  inProgressUpdateCostCentreImportSetting: false,
});
