import { MutationTree } from 'vuex';
import { CostCentreState } from './types';
import Vue from 'vue';
import costCentre, { CostCentre, Value } from '@/shared/model/costCentre';

export const mutations: MutationTree<CostCentreState> = {
  setCostCentres(state, payload: Value) {
    state.costCentres.items = payload.value.map((x) => costCentre.parse(x));
    state.costCentres.total = payload['@odata.count'] || 0;
    state.costCentres.isLoading = false;
  },
  setCostCentresTotal(state, payload: number) {
    state.costCentres.total = payload;
  },
  setCostCentresIsLoading(state, payload: boolean) {
    state.costCentres.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
};
