import { MutationTree } from 'vuex';
import { UserState } from './types';
import Vue from 'vue';
import user, { User, Value } from '@/shared/model/user';
import userRight, { Value as UserValue } from '@/shared/model/userRight';

export const mutations: MutationTree<UserState> = {
  setUsers(state, payload: Value) {
    state.users.items = payload.value.map((x) => user.parse(x));
    state.users.total = payload['@odata.count'] || 0;
    state.users.isLoading = false;
  },
  setUsersTotal(state, payload: number) {
    state.users.total = payload;
  },
  setUsersIsLoading(state, payload: boolean) {
    state.users.isLoading = payload;
  },

  setQaUsers(state, payload: Value) {
    state.qaUsers.items = payload.value.map((x) => user.parse(x));
    state.qaUsers.total = payload['@odata.count'] || 0;
    state.qaUsers.isLoading = false;
  },
  setQaUsersTotal(state, payload: number) {
    state.qaUsers.total = payload;
  },
  setQaUsersIsLoading(state, payload: boolean) {
    state.qaUsers.isLoading = payload;
  },
  setUserRights(state, payload: UserValue) {
    state.userRights.items = payload.value.map((x) => userRight.parse(x));
    state.userRights.total = payload['@odata.count'] || 0;
    state.userRights.isLoading = false;
  },
  setUserRightsTotal(state, payload: number) {
    state.userRights.total = payload;
  },
  setUserRightsIsLoading(state, payload: boolean) {
    state.userRights.isLoading = payload;
  },
  setRegisterUserInProcess(state, payload: boolean) {
    state.registerUserInProcess = payload;
  },
  setUpdateUserRightsIsLoading(state, payload: boolean) {
    state.updateUserRightsIsLoading = payload;
  },
  setIsUserEmailExistInProgress(state, payload: boolean) {
    state.isUserEmailExistInProgress = payload;
  },
};
