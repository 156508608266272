import { GetterTree } from 'vuex';
import { AllocationBrandsImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationBrandsImportSettingState, RootState> = {
  ['getAllocationBrandsImportSettings']: (state) => state.allocationBrandsImportSettings,
  ['getAllocationBrandsImportSettingsIsLoading']: (state) => state.allocationBrandsImportSettings?.isLoading,
  ['getAllocationBrandsImportSettingsTotal']: (state) => state.allocationBrandsImportSettings?.total,
  ['getAllocationBrandsImportSettingsSearchParams']: (state) => state.allocationBrandsImportSettings?.searchParams,
  ['getAllocationBrandsImportSetting']: (state) => state.allocationBrandsImportSetting,
  ['getInProgressUpdateAllocationBrandsImportSetting']: (state) => state.inProgressUpdateAllocationBrandsImportSetting,
};
