import { AllocationBrandState } from './types';

export const state = (): AllocationBrandState => initialState();

export const initialState = (): AllocationBrandState => ({
  allocationBrands: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sortOrder'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Name', 'BrandNumber'],
      orClauseFieldsIdsIgnoreCase: ['Name', 'BrandNumber'],
      orClauseFieldsIdsConvertToString: [],
    },
  },
  isImporting: false,
  editOfficesPermittedDialog_AllAllocationBrandsIsImporting: false,
  editOfficesPermittedDialog_AllAllocationBrands: [],
});
