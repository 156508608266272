import { GetterTree } from 'vuex';
import { UserPackageState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UserPackageState, RootState> = {
  ['getUserPackages']: (state) => state.userPackages,
  ['getUserPackagesIsLoading']: (state) => state.userPackages?.isLoading,
  ['getUserPackagesTotal']: (state) => state.userPackages?.total,
  ['getUserPackagesSearchParams']: (state) => state.userPackages?.searchParams,
};
