import { GetterTree } from 'vuex';
import { OrganisationDocumentPropertyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationDocumentPropertyState, RootState> = {
  ['getOrganisationDocumentPropertys']: (state) => state.organisationDocumentPropertys,
  ['getOrganisationDocumentPropertysIsLoading']: (state) => state.organisationDocumentPropertys?.isLoading,
  ['getOrganisationDocumentPropertysTotal']: (state) => state.organisationDocumentPropertys?.total,
  ['getOrganisationDocumentPropertysSearchParams']: (state) => state.organisationDocumentPropertys?.searchParams,
};
