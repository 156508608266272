import { ActionTree } from 'vuex';
import { AllocationSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationSetting } from '@/shared/backend/allocationSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationSetting, {
  Value,
  AllocationSetting,
  AllocationSettingExportTypes,
} from '@/shared/model/allocationSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { Response } from '@/shared/model/Response';

const logger = new Logger('actions.allocationSettings');
export const actions: ActionTree<AllocationSettingState, RootState> = {
  getAllocationSettings(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; folderId?: string }
  ) {
    commit('setAllocationSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationSettingsSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendAllocationSetting
      .getAllocationSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationSettings', response.data);
        commit('setAllocationSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationSetting
      .getAllocationSetting(id)
      .then((response: AxiosResponse<AllocationSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationSettingCurrent({ commit, dispatch, getters, rootGetters }, id: string) {
    const searchParams = getters.getAllocationSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationSetting
      .getAllocationSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationSetting', allocationSetting.parse(response.data.value[0]));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationSetting({ commit, dispatch }, file: AllocationSetting) {
    return defaultBackendAllocationSetting
      .updateAllocationSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocation_setting_update`), commit);
          commit('setAllocationSetting', allocationSetting.parse(response.data.result));

          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  setExportType({ commit, dispatch }, exportType: AllocationSettingExportTypes) {
    return defaultBackendAllocationSetting
      .setExportType(exportType)
      .then((response: AxiosResponse<Response<AllocationSetting>>) => {
        if (response.data?.isValid) {
          module_utils.ok(i18n.tc(`success.allocation_setting_update`), commit);
          commit('setAllocationSetting', allocationSetting.parse(response.data.result));
        } else module_utils.error('error', commit, response.data.errors, logger);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationSetting
      .deleteAllocationSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
