import { GetterTree } from 'vuex';
import { OrganisationDocumentSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationDocumentSettingState, RootState> = {
  ['getOrganisationDocumentSetting']: (state) => state.current,
  ['getOrganisationDocumentSettings']: (state) => state.organisationDocumentSettings,
  ['getOrganisationDocumentSettingsIsLoading']: (state) => state.organisationDocumentSettings?.isLoading,
  ['getOrganisationDocumentSettingsTotal']: (state) => state.organisationDocumentSettings?.total,
  ['getOrganisationDocumentSettingsSearchParams']: (state) => state.organisationDocumentSettings?.searchParams,
  ['getIsUpdating']: (state) => state.isUpdating,
};
