import datevSetting from '@/shared/model/datevSetting';
import { DatevSettingState } from './types';

export const state = (): DatevSettingState => initialState();

export const initialState = (): DatevSettingState => ({
  datevSettings: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['CreatedAt'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  isUpdating: false,
  current: datevSetting.parse({}),
});
