import { ActionTree } from 'vuex';
import { DatevSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendDatevSetting } from '@/shared/backend/datevSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import datevSetting, { Value, DatevSetting } from '@/shared/model/datevSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.datevSettings');
export const actions: ActionTree<DatevSettingState, RootState> = {
  getDatevSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDatevSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDatevSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDatevSetting
      .getDatevSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDatevSettings', response.data);
        commit('setDatevSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDatevSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDatevSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setDatevSettingsIsLoading', true);
    const searchParams = getters.getDatevSettingsSearchParams;
    searchParams.dataOption.itemsPerPage = 1;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDatevSetting
      .getDatevSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDatevSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDatevSettingsIsLoading', false);
      });
  },
  getDatevSettingById({ commit, dispatch }, id: string) {
    return defaultBackendDatevSetting
      .getDatevSetting(id)
      .then((response: AxiosResponse<DatevSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDatevSetting({ commit, dispatch }, file: DatevSetting) {
    commit('setIsUpdating', true);
    return defaultBackendDatevSetting
      .updateDatevSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DatevSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.datev_setting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
  deleteDatevSetting({ commit, dispatch }, id: string) {
    return defaultBackendDatevSetting
      .deleteDatevSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DatevSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
