import { MutationTree } from 'vuex';
import { AllocationOfficeState } from './types';

import allocationOffice, {
  AllocationOffice,
  AllocationOfficeGroupByAllowedBrands,
  Value,
} from '@/shared/model/allocationOffice';

export const mutations: MutationTree<AllocationOfficeState> = {
  setAllocationOffices(state, payload: Value) {
    state.allocationOffices.items = payload.value.map((x) => allocationOffice.parse(x));
    state.allocationOffices.total = payload['@odata.count'] || 0;
    state.allocationOffices.isLoading = false;
  },
  setAllocationOfficesEx(state, payload: AllocationOffice[]) {
    state.allocationOffices.items = payload.map((x) => allocationOffice.parse(x));
    state.allocationOffices.total = payload.length || 0;
    state.allocationOffices.isLoading = false;
  },
  setAllocationOfficesTotal(state, payload: number) {
    state.allocationOffices.total = payload;
  },
  setAllocationOfficesIsLoading(state, payload: boolean) {
    state.allocationOffices.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
  setAllocationOfficeGroupByAllowedBrands(state, payload: AllocationOfficeGroupByAllowedBrands[]) {
    state.editOfficesPermittedDialog_AllocationOfficesGroupByAllowedBrands = payload.map((x) =>
      allocationOffice.parseAllocationOfficeGroupByAllowedBrands(x)
    );
  },
  setAllAllocationOffices(state, payload: AllocationOffice[]) {
    state.editOfficesPermittedDialog_AllAllocationOffices = payload.map((x) => allocationOffice.parse(x));
  },
  setAllAllocationOfficesIsImporting(state, payload: boolean) {
    state.editOfficesPermittedDialog_AllAllocationOfficesIsImporting = payload;
  },
};
