import { MutationTree } from 'vuex';
import { DriveTypeState } from './types';
import Vue from 'vue';
import driveType, { DriveType, Value } from '@/shared/model/driveType';

export const mutations: MutationTree<DriveTypeState> = {
  setDriveTypes(state, payload: Value) {
    state.driveTypes.items = payload.value.map((x) => driveType.parse(x));
    state.driveTypes.total = payload['@odata.count'] || 0;
    state.driveTypes.isLoading = false;
  },
  setDriveTypesTotal(state, payload: number) {
    state.driveTypes.total = payload;
  },
  setDriveTypesIsLoading(state, payload: boolean) {
    state.driveTypes.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
};
