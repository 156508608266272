import { MutationTree } from 'vuex';
import { TradeChannelImportSettingState } from './types';
import Vue from 'vue';
import tradeChannelImportSetting, { TradeChannelImportSetting, Value } from '@/shared/model/tradeChannelImportSetting';

export const mutations: MutationTree<TradeChannelImportSettingState> = {
  setTradeChannelImportSettings(state, payload: Value) {
    state.tradeChannelImportSettings.items = payload.value.map((x) => tradeChannelImportSetting.parse(x));
    state.tradeChannelImportSettings.total = payload['@odata.count'] || 0;
    state.tradeChannelImportSettings.isLoading = false;
  },
  setTradeChannelImportSettingsTotal(state, payload: number) {
    state.tradeChannelImportSettings.total = payload;
  },
  setTradeChannelImportSettingsIsLoading(state, payload: boolean) {
    state.tradeChannelImportSettings.isLoading = payload;
  },
  setTradeChannelImportSetting(state, payload: Value) {
    state.tradeChannelImportSetting = Object.assign({}, payload.value[0]);
  },
  setInProgressUpdateTradeChannelImportSetting(state, payload: boolean) {
    state.inProgressUpdateTradeChannelImportSetting = payload;
  },
};
