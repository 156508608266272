import { GetterTree } from 'vuex';
import { DriveTypeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DriveTypeState, RootState> = {
  ['getDriveTypes']: (state) => state.driveTypes,
  ['getDriveTypesIsLoading']: (state) => state.driveTypes?.isLoading,
  ['getDriveTypesTotal']: (state) => state.driveTypes?.total,
  ['getDriveTypesSearchParams']: (state) => state.driveTypes?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
};
