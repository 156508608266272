import { MutationTree } from 'vuex';
import { DocumentReminderState } from './types';
import Vue from 'vue';
import documentReminder, { DocumentReminder, Value } from '@/shared/model/documentReminder';

export const mutations: MutationTree<DocumentReminderState> = {
  setDocumentReminders(state, payload: Value) {
    state.documentReminders.items = payload.value.map((x) => documentReminder.parse(x));
    state.documentReminders.total = payload['@odata.count'] || 0;
    state.documentReminders.isLoading = false;
  },
  setDocumentRemindersTotal(state, payload: number) {
    state.documentReminders.total = payload;
  },
  setDocumentRemindersIsLoading(state, payload: boolean) {
    state.documentReminders.isLoading = payload;
  },
};
