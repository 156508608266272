import { GetterTree } from 'vuex';
import { OfficeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OfficeState, RootState> = {
  ['getOffices']: (state) => state.offices,
  ['getOfficesIsLoading']: (state) => state.offices?.isLoading,
  ['getOfficesTotal']: (state) => state.offices?.total,
  ['getOfficesSearchParams']: (state) => state.offices?.searchParams,
};
