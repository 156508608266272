import { ActionTree } from 'vuex';
import { AllocationTemplatePropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationTemplateProperty } from '@/shared/backend/allocationTemplateProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationTemplateProperty, {
  Value,
  AllocationTemplateProperty,
} from '@/shared/model/allocationTemplateProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationTemplatePropertys');
export const actions: ActionTree<AllocationTemplatePropertyState, RootState> = {
  getAllocationTemplatePropertys({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationTemplatePropertysIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationTemplatePropertysSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTemplateProperty
      .getAllocationTemplatePropertys(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTemplatePropertys', response.data);
        commit('setAllocationTemplatePropertysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationTemplatePropertysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTemplateProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTemplateProperty
      .getAllocationTemplateProperty(id)
      .then((response: AxiosResponse<AllocationTemplateProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationTemplateProperty({ commit, dispatch }, file: AllocationTemplateProperty) {
    return defaultBackendAllocationTemplateProperty
      .updateAllocationTemplateProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTemplateProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationTemplateProperty_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationTemplateProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTemplateProperty
      .deleteAllocationTemplateProperty(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTemplateProperty;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
