import { GetterTree } from 'vuex';
import { AllocationFramePropertyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationFramePropertyState, RootState> = {
  ['getAllocationFramePropertys']: (state) => state.allocationFramePropertys,
  ['getAllocationFramePropertysIsLoading']: (state) => state.allocationFramePropertys?.isLoading,
  ['getAllocationFramePropertysTotal']: (state) => state.allocationFramePropertys?.total,
  ['getAllocationFramePropertysSearchParams']: (state) => state.allocationFramePropertys?.searchParams,
};
