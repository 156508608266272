import { ActionTree } from 'vuex';
import { OrganisationAllocationPropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationAllocationProperty } from '@/shared/backend/organisationAllocationProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationAllocationProperty, {
  Value,
  OrganisationAllocationProperty,
} from '@/shared/model/organisationAllocationProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationAllocationPropertys');
export const actions: ActionTree<OrganisationAllocationPropertyState, RootState> = {
  getOrganisationAllocationProperties({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setOrganisationAllocationPropertiesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationAllocationPropertiesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationAllocationProperty
      .getOrganisationAllocationPropertys(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationAllocationProperties', response.data);
        commit('setOrganisationAllocationPropertiesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationAllocationPropertiesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationAllocationProperty({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationAllocationProperty
      .getOrganisationAllocationProperty(id)
      .then((response: AxiosResponse<OrganisationAllocationProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationAllocationProperty({ commit, dispatch }, file: OrganisationAllocationProperty) {
    commit('setIsUpdating', true);
    return defaultBackendOrganisationAllocationProperty
      .updateOrganisationAllocationProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAllocationProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_allocation_property_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
  moveOrganisationAllocationProperty(
    { commit, dispatch },
    payload: { element: OrganisationAllocationProperty; newIndex: number; oldIndex: number }
  ) {
    commit('setIsUpdating', true);
    return defaultBackendOrganisationAllocationProperty
      .moveOrganisationAllocationProperty(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAllocationProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_allocation_property_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
};
