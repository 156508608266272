import { MutationTree } from 'vuex';
import { FileUploadState } from './types';
import Vue from 'vue';
import { FileUpload } from '@/shared/model/fileUpload';

export const mutations: MutationTree<FileUploadState> = {
  setFileUpload(state, payload: FileUpload) {
    state.fileUpload.inProgress = payload.inProgress;
    state.fileUpload.total = payload.total;
    state.fileUpload.uploaded = payload.uploaded;
    state.fileUpload.errors = payload.errors;
  },
};
