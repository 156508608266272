import { ActionTree } from 'vuex';
import { DriveTypesImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendDriveTypesImportSetting } from '@/shared/backend/driveTypesImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import driveTypesImportSetting, { Value, DriveTypesImportSetting } from '@/shared/model/driveTypesImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.driveTypesImportSettings');
export const actions: ActionTree<DriveTypesImportSettingState, RootState> = {
  getDriveTypesImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDriveTypesImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDriveTypesImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDriveTypesImportSetting
      .getDriveTypesImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDriveTypesImportSettings', response.data);
        commit('setDriveTypesImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDriveTypesImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDriveTypesImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setDriveTypesImportSettingsIsLoading', true);
    const searchParams = getters.getDriveTypesImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDriveTypesImportSetting
      .getDriveTypesImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDriveTypesImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDriveTypesImportSettingsIsLoading', false);
      });
  },
  getDriveTypesImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendDriveTypesImportSetting
      .getDriveTypesImportSetting(id)
      .then((response: AxiosResponse<DriveTypesImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDriveTypesImportSetting({ commit, dispatch, rootGetters }, file: DriveTypesImportSetting) {
    commit('setInProgressUpdateDriveTypesImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDriveTypesImportSetting
      .updateDriveTypesImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DriveTypesImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.driveTypesImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateDriveTypesImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getDriveTypesImportSetting.clearBeforeImport = payload.value;
    dispatch('updateDriveTypesImportSetting', getters.getDriveTypesImportSetting)
      .then((result: any) => {
        getters.getDriveTypesImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getDriveTypesImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteDriveTypesImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendDriveTypesImportSetting
      .deleteDriveTypesImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DriveTypesImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
