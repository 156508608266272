import { MutationTree } from 'vuex';
import { CostCentreImportSettingState } from './types';
import Vue from 'vue';
import costCentreImportSetting, { Value } from '@/shared/model/costCentreImportSetting';

export const mutations: MutationTree<CostCentreImportSettingState> = {
  setCostCentreImportSettings(state, payload: Value) {
    state.costCentreImportSettings.items = payload.value.map((x) => costCentreImportSetting.parse(x));
    state.costCentreImportSettings.total = payload['@odata.count'] || 0;
    state.costCentreImportSettings.isLoading = false;
  },
  setCostCentreImportSettingsTotal(state, payload: number) {
    state.costCentreImportSettings.total = payload;
  },
  setCostCentreImportSettingsIsLoading(state, payload: boolean) {
    state.costCentreImportSettings.isLoading = payload;
  },
  setCostCentreImportSetting(state, payload: Value) {
    state.costCentreImportSetting = Object.assign({}, costCentreImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateCostCentreImportSetting(state, payload: boolean) {
    state.inProgressUpdateCostCentreImportSetting = payload;
  },
};
