import { ActionTree } from 'vuex';
import { ExportState } from './types';
import { RootState } from '../../types';
import { defaultBackendExport } from '@/shared/backend/export';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import export_, { Value, Export } from '@/shared/model/export';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.Exports');
export const actions: ActionTree<ExportState, RootState> = {
  getExports({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; folderId?: string }) {
    commit('setExportsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getExportsSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendExport
      .getExports(searchParams, organizationId, payload?.folderId || '')
      .then((response: AxiosResponse<Value>) => {
        commit('setExports', response.data);
        commit('setExportsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setExportsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getExport({ commit, dispatch }, id: string) {
    return defaultBackendExport
      .getExport(id)
      .then((response: AxiosResponse<Export>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateExport({ commit, dispatch }, file: Export) {
    return defaultBackendExport
      .updateExport(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Export;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.export_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteExport({ commit, dispatch }, id: string) {
    return defaultBackendExport
      .deleteExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Export;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
