import { render, staticRenderFns } from "./document-switches.html?vue&type=template&id=b0dfa670&scoped=true&external"
import script from "./document-switches.ts?vue&type=script&lang=ts&external"
export * from "./document-switches.ts?vue&type=script&lang=ts&external"
import style0 from "./document-switches.scss?vue&type=style&index=0&id=b0dfa670&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0dfa670",
  null
  
)

/* custom blocks */
import block0 from "./document-switches.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fdocument-switches%2Fdocument-switches.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VCol,VContainer,VRow,VSwitch})
