import { ActionTree } from 'vuex';
import { RegexSplitterPatternPartState } from './types';
import { RootState } from '../../types';
import { defaultBackendRegexSplitterPatternPart } from '@/shared/backend/regexSplitterPatternPart';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import regexSplitterPatternPart, { Value, RegexSplitterPatternPart } from '@/shared/model/regexSplitterPatternPart';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.regexSplitterPatternParts');
export const actions: ActionTree<RegexSplitterPatternPartState, RootState> = {
  getRegexSplitterPatternParts({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setRegexSplitterPatternPartsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRegexSplitterPatternPartsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendRegexSplitterPatternPart
      .getRegexSplitterPatternParts(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setRegexSplitterPatternParts', response.data);
        commit('setRegexSplitterPatternPartsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRegexSplitterPatternPartsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRegexSplitterPatternPart({ commit, dispatch }, id: string) {
    return defaultBackendRegexSplitterPatternPart
      .getRegexSplitterPatternPart(id)
      .then((response: AxiosResponse<RegexSplitterPatternPart>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRegexSplitterPatternPart({ commit, dispatch }, file: RegexSplitterPatternPart) {
    return defaultBackendRegexSplitterPatternPart
      .updateRegexSplitterPatternPart(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RegexSplitterPatternPart;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.regexSplitterPatternPart_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteRegexSplitterPatternPart({ commit, dispatch }, id: string) {
    return defaultBackendRegexSplitterPatternPart
      .deleteRegexSplitterPatternPart(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RegexSplitterPatternPart;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
