import { MutationTree } from 'vuex';
import { OrganisationDocumentPropertyState } from './types';
import Vue from 'vue';
import organisationDocumentProperty, {
  OrganisationDocumentProperty,
  Value,
} from '@/shared/model/organisationDocumentProperty';

export const mutations: MutationTree<OrganisationDocumentPropertyState> = {
  setOrganisationDocumentPropertys(state, payload: Value) {
    state.organisationDocumentPropertys.items = payload.value.map((x) => organisationDocumentProperty.parse(x));
    state.organisationDocumentPropertys.total = payload['@odata.count'] || 0;
    state.organisationDocumentPropertys.isLoading = false;
  },
  setOrganisationDocumentPropertysTotal(state, payload: number) {
    state.organisationDocumentPropertys.total = payload;
  },
  setOrganisationDocumentPropertysIsLoading(state, payload: boolean) {
    state.organisationDocumentPropertys.isLoading = payload;
  },
};
