export interface CostCentre {
  id: string; // ulong
  organisationId: string; // string
  name: string; // string
  value: number; // long
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: CostCentre[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<CostCentre>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    Value: data?.value || 0,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<CostCentre>): CostCentre {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    value: data?.value || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
