import { GetterTree } from 'vuex';
import { DriveTypesImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DriveTypesImportSettingState, RootState> = {
  ['getDriveTypesImportSettings']: (state) => state.driveTypesImportSettings,
  ['getDriveTypesImportSettingsIsLoading']: (state) => state.driveTypesImportSettings?.isLoading,
  ['getDriveTypesImportSettingsTotal']: (state) => state.driveTypesImportSettings?.total,
  ['getDriveTypesImportSettingsSearchParams']: (state) => state.driveTypesImportSettings?.searchParams,
  ['getDriveTypesImportSetting']: (state) => state.driveTypesImportSetting,
  ['getInProgressUpdateDriveTypesImportSetting']: (state) => state.inProgressUpdateDriveTypesImportSetting,
};
