export interface FilePagesText {
  id: string; // System.Guid
  fileId: string; // System.Guid
  pagesText: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationId: string; // System.Guid
}

export interface Value {
  value: FilePagesText[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<FilePagesText>): any {
  return {
    Id: data?.id || undefined,
    FileId: data?.fileId || undefined,
    PagesText: data?.pagesText || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    OrganisationId: data?.organisationId || undefined,
  };
}

function parse(data?: Partial<FilePagesText>): FilePagesText {
  return {
    id: data?.id || '',
    fileId: data?.fileId || '',
    pagesText: data?.pagesText || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
