import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import userPackage, { UserPackage, Value } from '../model/userPackage';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.UserPackage');
export interface BackendUserPackage {
  getUserPackage(id: string): AxiosPromise<UserPackage>;
  getUserPackages: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteUserPackage(id: string): AxiosPromise;
  updateUserPackage(UserPackage: UserPackage): AxiosPromise<any>;
}

export const defaultBackendUserPackage: BackendUserPackage = {
  getUserPackage(id: string): AxiosPromise<UserPackage> {
    const url = `${URLS.userPackageOdata}/${id}`;
    return instance.get<UserPackage>(url);
  },
  getUserPackages(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userPackageOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getUserPackages${url}`);
    return instance.get<Value>(url);
  },

  deleteUserPackage(id: string): AxiosPromise {
    logger.debug('deleteUserPackage');
    return instance.delete(`${URLS.userPackage}/${id}`);
  },
  updateUserPackage(UserPackage: UserPackage): AxiosPromise<any> {
    logger.debug('updateUserPackage');
    return instance.put<UserPackage>(`${URLS.userPackage}/update`, userPackage.toAPI(UserPackage));
  },
};
