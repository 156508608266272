import Vue from 'vue';

const LocalStorageService = (function () {
  let _service: any;
  function _getService() {
    if (!_service) {
      _service = LocalStorageService;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj: any) {
    localStorage.setItem(`access_token`, tokenObj.access_token);
    localStorage.setItem(`refresh_token`, tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return localStorage.getItem(`access_token`);
  }
  function _getRefreshToken() {
    return localStorage.getItem(`refresh_token`);
  }
  function _clearToken() {
    localStorage.removeItem(`access_token`);
    localStorage.removeItem(`refresh_token`);
  }
  function _getAccount() {
    const base_path = process.env.VUE_APP_BASE_PATH;
    const STS_DOMAIN: string = base_path + ':8005/';
    const client = process.env.VUE_APP_CLIENT;
    try {
      const accaunt: x = JSON.parse(localStorage.getItem(`oidc.user:${STS_DOMAIN}:${client}`) || '');
      // accaunt.profile = JSON.parse(accaunt.profile,x);
      return accaunt;
    } catch (error) {
      return { access_token: undefined };
    }
  }
  interface x {
    access_token: string;
    expires_at: number;
    id_token: string;
    profile: {
      [key: string]: any;
      amr: string[];
      auth_time: number;
      authorized_organization_id: string[];
      family_name: string;
      given_name: string;
      idp: string;
      is_owner: string;
      is_seller: 'True';
      main_organization_id: string;
      current_organization_id: string;
      name: string;
      nickname: string;
      ownerid: string;
      preferred_username: string;
      s_hash: string;
      sid: string;
      sub: string;
    };
    scope: string;
    session_state: string;
    token_type: string;
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    getAccount: _getAccount,
  };
})();
export default LocalStorageService;
