import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { Folder } from '@/shared/model/folder';
import { WorkflowRule } from '@/shared/model/workflowRule';
import folderRule, { FolderRule } from '@/shared/model/folderRule';
import TreeUtils from '@/shared/utils/treeUtils';

const name = 'action-move-to-folder';
const logger = new Logger(name);
const folderModule = namespace('folder');

@Component({
  name: name,
  components: {},
})
export default class ActionMoveToFolder extends Vue {
  @PropSync('selected', {
    default: () => {
      return [];
    },
  })
  private selectedModel!: any[];
  @PropSync('value', { default: () => folderRule.parse({}) })
  private model!: FolderRule;
  @Watch('value', { deep: true })
  private onValueUpdated(nVal: FolderRule, oVal: FolderRule) {
    if (nVal.folderId !== oVal.folderId) {
      const parent = TreeUtils.getFoldersFromFlat(oVal.folderId, this.foldersFlat);
      if (parent) {
        TreeUtils.setDisableById(parent[0].fullPath, oVal.folderId, this.folders, false);
      }
    }
    if (nVal.folderId) {
      const parent = TreeUtils.getFoldersFromFlat(nVal.folderId, this.foldersFlat);
      if (parent) {
        TreeUtils.setDisableById(parent[0].fullPath, nVal.folderId, this.folders, true);
      }
    }
  }
  @Watch('selectedModel', { deep: true })
  private onSelected(nVal: any, oVal: any) {
    if (nVal.length != oVal.length || nVal[0].id != oVal[0].id) this.menu = false;
  }

  getFolderPath() {
    if (this.model.actionMoveToFolderId == '')
      return `${this.$t('pattern', { 0: this.model.actionMoveToFolderPattern })}`;
    return TreeUtils.getFolderPath(this.model.actionMoveToFolderId, this.foldersFlat);
  }
  @Watch('menu')
  onMenu(nVal: any, oVal: any) {
    this.actualiseSelectedModel();

    this.setPattern();
  }
  setPattern() {
    if (!this.menu) {
      if (this.selectedModel.length > 0) {
        this.model.actionMoveToFolderId = this.selectedModel[0].id;
        this.model.actionMoveToFolder = this.selectedModel[0];
      }
      if (!this.model.actionMoveToFolderPattern) this.model.actionMoveToFolderPattern = '[Y]/[m]';
      if (!this.model.actionMoveToFolderPatternType && this.patternTypes[0])
        this.model.actionMoveToFolderPatternType = this.patternTypes[0].id;
    }
  }
  @folderModule.Getter('getMenuFoldersTreeViewFlat')
  private foldersFlat!: Folder[];
  @folderModule.Getter('getMenuFoldersTreeView')
  private folders!: {
    folder: Folder;
    id: string;
    name: string;
    parent: { name: string; id: string };
    children: any[];
  }[];

  items: {
    folder?: Folder;
    id: string;
    name: string;
    parent?: { name: string; id: string };
    children?: any[];
  }[] = [];
  private actualiseSelectedModel() {
    const properties = Object.keys(this.selectedModel[0]);
    // on change pattern type pass only 2 properties and should be selected actual folder
    if (properties.length == 2) {
      this.selectedModel = TreeUtils.getFoldersFromFlat(
        this.model.actionMoveToFolderId,
        this.model.actionMoveToFolderId == this.pattern.id ? (this.items as Folder[]) : this.foldersFlat
      );
      this.initFolders(); //actualise folders if was added subfolder
    }
  }

  get pattern() {
    return {
      id: '',
      name: `${this.$t('pattern')}`,
      parent: undefined,
      folder: undefined,
      children: undefined,
      fullPath: '',
    };
  }
  menu = false;

  get patternTypes(): {
    id: 'document_upload' | 'latest_status' | 'latest_allocation' | 'invoice_date_of_latest_allocation';
    name: string;
  }[] {
    return [
      { id: 'document_upload', name: this.$t('pattern_types.document_upload').toString() },
      { id: 'latest_status', name: this.$t('pattern_types.latest_status').toString() },
      { id: 'latest_allocation', name: this.$t('pattern_types.latest_allocation').toString() },
      {
        id: 'invoice_date_of_latest_allocation',
        name: this.$t('pattern_types.invoice_date_of_latest_allocation').toString(),
      },
    ];
  }
  mounted() {
    if (this.model !== undefined) {
      const parent = TreeUtils.getFoldersFromFlat(this.model.folderId, this.foldersFlat);
      if (parent) {
        TreeUtils.setDisableById(parent[0].fullPath, this.model.folderId, this.folders, true);
      }
    }
    this.initFolders();
    this.setPattern();
  }

  private initFolders() {
    this.items.length = 0;
    this.items.push(this.pattern);
    this.folders.forEach((element) => {
      this.items.push(element);
    });
  }
  get patternRules() {
    return [(v: string) => (v && !!v.trim()) || this.$i18n.t('pattern_required_message')];
  }
}
