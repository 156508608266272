import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import MenuDocumentListView from './menu-document-list/menu-document-list.vue';
import MenuDocumentPreviewView from './menu-document-preview/menu-document-preview.vue';
import MenuAddressView from './menu-address/menu-address.vue';
import MenuExportView from './menu-export/menu-export.vue';
import MenuAllocationExportDownloadView from './menu-allocation-export-download/menu-allocation-export-download.vue';
import MenuProcessView from './menu-process/menu-process.vue';
import { Route } from 'vue-router';

const logger = new Logger('right-main-menu');

const authModule = namespace('auth');

@Component({
  name: 'right-main-menu',
  components: {
    MenuDocumentListView,
    MenuDocumentPreviewView,
    MenuAddressView,
    MenuExportView,
    MenuAllocationExportDownloadView,
    MenuProcessView,
  },
})
export default class RightMainMenuView extends Vue {
  @Prop({ default: true, type: Boolean })
  private drawerLeft!: boolean;

  @authModule.Getter('isLoggedIn')
  private getterIsLoggedIn!: boolean;

  private routeInfo?: Route;

  get isLoggedIn() {
    return this.getterIsLoggedIn;
  }

  get mobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get drawerRight() {
    return !this.drawerLeft;
  }

  get isSettingsRoute() {
    return this.$route?.path.startsWith('/settings');
  }

  get isTrashRoute() {
    return this.$route?.path === '/trash';
  }

  get isDocumentListView() {
    return this.$route?.name === 'document-list';
  }

  get isDocumentPreviewView() {
    return (
      this.$route.name == 'document-preview' ||
      this.$route.name == 'qa-task' ||
      this.$route.name == 'qa-question' ||
      this.$route.name == 'qa-circular'
    );
  }

  get isAddressView() {
    return this.$route?.name === 'addresses';
  }

  get isDocumentExportView() {
    return this.$route.path.includes('export');
  }

  get isAllocationExportDownloadView() {
    return this.$route.path.includes('allocation-details');
  }

  get isDocumentProcessView() {
    return this.$route?.name === 'processes';
  }

  // TODO: (ED-159) temp soulution for test (redo as prop from App component or another way )
  @Watch('$route.path', { immediate: true, deep: true })
  onRouteChange(newVal: any, oldVal: any) {
    this.routeInfo = this.$route;
  }
}
