import { GetterTree } from 'vuex';
import { DocumentFilesExportState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentFilesExportState, RootState> = {
  ['getDocumentFilesExports']: (state) => state.documentFilesExports,
  ['getDocumentFilesExportsIsLoading']: (state) => state.documentFilesExports?.isLoading,
  ['getDocumentFilesExportsTotal']: (state) => state.documentFilesExports?.total,
  ['getDocumentFilesExportsSearchParams']: (state) => state.documentFilesExports?.searchParams,
};
