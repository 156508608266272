import { ActionTree } from 'vuex';
import { DocumentStateState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentState } from '@/shared/backend/documentState';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentState, { Value, DocumentState } from '@/shared/model/documentState';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { DocumentStateDto } from '@/shared/model/smallPayloadModels/documentStateDto';

const logger = new Logger('actions.documentStates');
export const actions: ActionTree<DocumentStateState, RootState> = {
  getDocumentStates({ commit, dispatch, getters, rootGetters }, payload?: { documentId: string; searchParams?: any }) {
    commit('setDocumentStatesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentStatesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocumentState
      .getDocumentStates(searchParams, payload!.documentId, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentStates', response.data);
        commit('setDocumentStatesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentStatesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentState({ commit, dispatch }, id: string) {
    return defaultBackendDocumentState
      .getDocumentState(id)
      .then((response: AxiosResponse<DocumentState>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentState({ commit, dispatch, rootGetters }, file: DocumentState) {
    const organisationId = rootGetters[CONST.getOrganisation].id;
    file.organisationId = organisationId;
    commit('setInProgressUpdateDocumentState', true);
    return defaultBackendDocumentState
      .updateDocumentState(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentState;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentState_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateDocumentState', false);
      });
  },

  addDeleteDocumentStateMultiple({ commit, dispatch }, payload: DocumentStateDto) {
    if (payload.values.length == 0) {
      module_utils.error(i18n.tc(`errors.documentState_update_multiple`), commit);
      return;
    }

    return defaultBackendDocumentState
      .addDeleteDocumentStateMultiple(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentState;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentState_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentState({ commit, dispatch, rootGetters }, id: string) {
    const organisationId = rootGetters[CONST.getOrganisation].id;
    commit('setInProgressUpdateDocumentState', true);
    return defaultBackendDocumentState
      .deleteDocumentState(id, organisationId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentState;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateDocumentState', false);
      });
  },
};
