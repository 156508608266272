import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import autoGobdCheckTerm, { AutoGobdCheckTerm } from '@/shared/model/autoGobdCheckTerm';
import MenuDocumentAccountTemplateTable from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-account/menu-document-account-template-table/menu-document-account-template-table.vue';
import MenuDocumentAccountTable from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-account/menu-document-account-table/menu-document-account-table.vue';

import { AllocationTemplate } from '@/shared/model/allocationTemplate';
import { Supplier } from '@/shared/model/supplier';
import ActionMoveToFolder from '@/views/settings/company/folder/workflow-rules/edit-folder-rule/action-move-to-folder/action-move-to-folder.vue';
import folderRule, { ActionTypes, ConditionTypes, FolderRule } from '@/shared/model/folderRule';
import folder from '@/shared/model/folder';

const name = 'document-auto-gobd-edit';
const logger = new Logger(name);
const allocationTemplateModule = namespace('allocationTemplate');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const autoGobdCheckTermModule = namespace('autoGobdCheckTerm');
const folderModule = namespace('folder');

@Component({
  name: 'document-auto-gobd-edit',
  components: { MenuDocumentAccountTable, MenuDocumentAccountTemplateTable, ActionMoveToFolder },
})
export default class DocumentAutoGobdEdit extends Vue {
  @allocationTemplateModule.Getter('getAllocationTemplates')
  private allocationTemplates?: any;
  @allocationTemplateModule.Action('getAllocationTemplates')
  private actionGetAllocationTemplates?: any;

  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  @autoGobdCheckTermModule.Action('getAutoGobdCheckTerms')
  private actionGetAutoGobdCheckTerms?: any;
  @autoGobdCheckTermModule.Action('deleteAutoGobdCheckTerm')
  private actionDeleteAutoGobdCheckTerm?: any;

  @Ref('auto-gobd-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => autoGobdCheckTerm.parse({}) })
  private value!: AutoGobdCheckTerm;

  @Watch('value')
  onUpdateValue(newV: AutoGobdCheckTerm, oldV: AutoGobdCheckTerm) {
    this.editedItem = Object.assign(this.editedItem, newV);

    this.selectedFolders = Object.assign(this.selectedFolders, [{ name: '', id: this.editedItem.moveToFolderId }]);

    this.value1 = Object.assign(
      this.value1,
      folderRule.parse({
        id: '',
        folderId: '37e8dc7d-7e3b-4367-9e9c-0490def5c8ec',
        //conditionType: ConditionTypes.has_status,
        //conditionStatus: '',
        //actionType: ActionTypes.move_to_folder,
        //actionMoveToFolderId: this.editedItem.moveToFolderId,
        //actionMoveToFolderPattern: this.editedItem.moveToFolderPattern || '[Y]/[m]',
        //actionMoveToFolderPatternType: 'document_upload',
        //actionEmailSubject: '',
        //actionEmailMessage: '',
        //sortOrder: 0,
        //createdAt: '',
        //updatedAt: '',
        //conditionAmountMin: 0,
        //conditionAmountMax: 0,
        //conditionAmountType: 'day',
        //conditionDays: 0,
        //lastExecution: '',
        organisationId: '18c2e103-037f-4bfa-953a-269cedc84a31',
        //actionRoles: [],
        //actionUsers: [],
        //copyToOrganisationId: '',
        //actionOptions: { copyType: 'copy_and_delete' },
      })
    );

    this.value1.actionMoveToFolder = Object.assign(this.value1.actionMoveToFolder, [
      { name: '', id: this.editedItem.moveToFolderId },
    ]);

    this.resetValidation();
  }

  private value1: FolderRule = folderRule.parse({});
  selectedFolders: any[] = [];

  private editedItem: AutoGobdCheckTerm = autoGobdCheckTerm.parse({});

  get getPrioritats() {
    return [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
    ];
  }

  //#region rules
  private isFormValid = true;
  get searchTermRules() {
    return [(v: any) => !!v.toString().trim() || this.$i18n.t('messages.search_term_required')];
  }

  get searchTerm1Rules() {
    const other =
      this.editedItem.searchTerm2.trim() != '' ||
      this.editedItem.searchTerm3.trim() != '' ||
      this.editedItem.searchTerm4.trim() != '' ||
      this.editedItem.searchTerm5.trim() != '';
    if (other) this.resetValidation();
    return [(v: any) => !!v.toString().trim() || other || this.$i18n.t('messages.search_term_required')];
  }

  get searchTerm2Rules() {
    const other =
      this.editedItem.searchTerm1.trim() != '' ||
      this.editedItem.searchTerm3.trim() != '' ||
      this.editedItem.searchTerm4.trim() != '' ||
      this.editedItem.searchTerm5.trim() != '';
    if (other) this.resetValidation();

    return [(v: any) => !!v.toString().trim() || other || this.$i18n.t('messages.search_term_required')];
  }

  get searchTerm3Rules() {
    const other =
      this.editedItem.searchTerm1.trim() != '' ||
      this.editedItem.searchTerm2.trim() != '' ||
      this.editedItem.searchTerm4.trim() != '' ||
      this.editedItem.searchTerm5.trim() != '';
    if (other) this.resetValidation();

    return [(v: any) => !!v.toString().trim() || other || this.$i18n.t('messages.search_term_required')];
  }

  get searchTerm4Rules() {
    const other =
      this.editedItem.searchTerm1.trim() != '' ||
      this.editedItem.searchTerm2.trim() != '' ||
      this.editedItem.searchTerm3.trim() != '' ||
      this.editedItem.searchTerm5.trim() != '';
    if (other) this.resetValidation();

    return [(v: any) => !!v.toString().trim() || other || this.$i18n.t('messages.search_term_required')];
  }

  get searchTerm5Rules() {
    const other =
      this.editedItem.searchTerm1.trim() != '' ||
      this.editedItem.searchTerm2.trim() != '' ||
      this.editedItem.searchTerm3.trim() != '' ||
      this.editedItem.searchTerm4.trim() != '';
    if (other) this.resetValidation();

    return [(v: any) => !!v.toString().trim() || other || this.$i18n.t('messages.search_term_required')];
  }

  get descriptionRules() {
    return [(v: string) => !!v.trim() || this.$i18n.t('messages.description_required')];
  }
  //#endregion

  async created() {
    this.actionGetAllocationTemplates().then(() => {});
  }

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    this.editedItem.moveToFolderId = this.value1.actionMoveToFolderId;
    this.editedItem.moveToFolderPattern = this.value1.actionMoveToFolderPattern;
    this.editedItem.moveToFolderPatternType = this.value1.actionMoveToFolderPatternType;

    if (isFormValidResult) {
      this.$emit('click:update', this.editedItem);
    }
  }

  private openTemplateDialog() {
    this.showDocumentAccountTemplateDialog();
  }

  //#region Document Template Dialog logic
  private showDocumentAccountTemplateDialog() {
    this.dialogDocumentAccountTemplate.show = true;
  }

  dialogDocumentAccountTemplate = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAccountTemplate.show = false;
    },
    OnSelect: (account: AllocationTemplate) => {
      this.dialogDocumentAccountTemplate.show = false;
    },
  };
  //#endregion

  //#region Lieferanten Dialog logic
  private showDocumentAccountDialog(field: string) {
    this.dialogDocumentAccount.show = true;
    this.dialogDocumentAccount.insertIntoField = field;
  }

  dialogDocumentAccount = {
    show: false,
    insertIntoField: '',
    model: {},
    OnClose: () => {
      this.dialogDocumentAccount.show = false;
    },
    OnSelect: (value: Supplier) => {
      switch (this.dialogDocumentAccount.insertIntoField) {
        case 'account': {
          //this.allocationsProperties.account = value.konto;
          this.editedItem.supplierAccount = value.id;
          this.editedItem.supplierAccountName = value.konto;
          break;
        }
        case 'contra_account': {
          //this.allocationsProperties.contra_account = value.konto;
          break;
        }
      }
      this.dialogDocumentAccount.show = false;
    },
  };
  //#endregion

  async onClickDelete(account: AllocationTemplate) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: account.name,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteAutoGobdCheckTerm(account.id)
            .then(() => {
              this.actionGetAutoGobdCheckTerms();
              this.onClickClose();
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }
}
