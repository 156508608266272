import { GetterTree } from 'vuex';
import { CostObjectiveState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CostObjectiveState, RootState> = {
  ['getCostObjectives']: (state) => state.costObjectives,
  ['getCostObjectivesIsLoading']: (state) => state.costObjectives?.isLoading,
  ['getCostObjectivesTotal']: (state) => state.costObjectives?.total,
  ['getCostObjectivesSearchParams']: (state) => state.costObjectives?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
};
