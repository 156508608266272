import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { Document } from '@/shared/model/document';

const logger = new Logger('backend.document');
const documentModule = namespace('document');

@Component
export default class DocumentsRestoreDialog extends Vue {
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @documentModule.Action('updateDeletedStatus')
  private actionUpdateDeletedStatus!: any;
  @documentModule.Action('getDocumentFoldersCounts')
  private actionGetDocumentFoldersCounts!: any;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;
  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocs!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: [] })
  private model!: any;

  get isRestoreSingleDocument() {
    return !!this.$route.params['documentId'];
  }

  get isRestoreMultipleDocs() {
    return this.getterSelectedDocs.length > 0;
  }

  get document() {
    return this.getterGetDocument;
  }

  clickClose() {
    this.$emit('click:close');
  }

  async recover() {
    let docIds: Array<string> = [];
    if (this.isRestoreSingleDocument) {
      docIds = [this.document?.id];
    } else if (this.isRestoreMultipleDocs) {
      docIds = this.getterSelectedDocs;
    } else if (docIds.length === 0) {
      return;
    }

    await this.actionUpdateDeletedStatus({ documentIds: docIds, isRecover: true })
      .then(() => {
        if (this.isRestoreSingleDocument) {
          this.$router.push('/trash');
        }
        this.actionGetDocuments({ showDeleted: true })
          .then(async () => {
            await this.actionGetDocumentFoldersCounts(); // (ED-1196) Update `left-menu` folders count after `recover` documents
            this.clickClose();
          })
          .catch((err: any) => {
            logger.error(err);
          });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
}
