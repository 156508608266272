import { MutationTree } from 'vuex';
import { AllocationTaxGroupState } from './types';
import Vue from 'vue';
import allocationTaxGroup, { AllocationTaxGroup, Value } from '@/shared/model/allocationTaxGroup';
import allocationTaxSetting, {
  AllocationTaxSetting,
  Value as AllocationTaxSettingValue,
} from '@/shared/model/allocationTaxSetting';

export const mutations: MutationTree<AllocationTaxGroupState> = {
  setAllocationTaxGroups(state, payload: Value) {
    state.allocationTaxGroups.items = payload.value.map((x) => allocationTaxGroup.parse(x));
    state.allocationTaxGroups.total = payload['@odata.count'] || 0;
    state.allocationTaxGroups.isLoading = false;
  },
  setAllocationTaxGroupsTotal(state, payload: number) {
    state.allocationTaxGroups.total = payload;
  },
  setAllocationTaxGroupsIsLoading(state, payload: boolean) {
    state.allocationTaxGroups.isLoading = payload;
  },
  resetSorting(state, payload?: boolean) {
    state.allocationTaxGroups.searchParams.dataOption.sortBy = ['createdAt'];
    state.allocationTaxGroups.searchParams.dataOption.sortDesc = [false];
  },
  setAllocationTaxSettings(state, payload: { allocationTaxSettingId: string; value: AllocationTaxSettingValue }) {
    const index = state.allocationTaxGroups.items.findIndex(
      (x: AllocationTaxGroup) => x.id === payload.allocationTaxSettingId
    );
    if (index >= 0) {
      state.allocationTaxGroups.items[index].taxSettings!.items = payload.value.value.map((x: AllocationTaxSetting) =>
        allocationTaxSetting.parse(x)
      );
      state.allocationTaxGroups.items[index].taxSettings!.total = payload.value['@odata.count'] || 0;
      state.allocationTaxGroups.items[index].taxSettings!.isLoading = false;
    }
  },
  setAllocationTaxSettingsIsLoading(state, payload: { isLoading: boolean; allocationTaxSettingId: string }) {
    const index = state.allocationTaxGroups.items.findIndex(
      (x: AllocationTaxGroup) => x.id === payload.allocationTaxSettingId
    );
    if (index >= 0) state.allocationTaxGroups.items[index].taxSettings!.isLoading = payload.isLoading;
  },
};
