const getNextInput = (currentIndex: number, currentForm: HTMLFormElement): HTMLInputElement | null => {
  if (currentIndex == currentForm.elements.length - 1) {
    return null;
  }
  const nextIndex = currentIndex + 1;
  const nextElement = currentForm.elements[nextIndex];

  if (
    nextElement.tagName == 'INPUT' &&
    nextElement.getAttribute('type') != 'hidden' &&
    nextElement.getAttribute('disabled') != 'disabled'
  ) {
    return nextElement as HTMLInputElement;
  } else {
    return getNextInput(nextIndex, currentForm);
  }
};

export const buildOnEnter = (lastElement: HTMLElement) => {
  return (elem: HTMLInputElement) => {
    if (elem.form != null) {
      const currentIndex = Array.from(elem.form!.elements).indexOf(elem);
      const nextElement = getNextInput(currentIndex, elem.form!);
      if (nextElement != null) {
        nextElement.focus();
        nextElement.setSelectionRange(0, nextElement.value.length);
      } else {
        lastElement.focus();
      }
    }
  };
};
