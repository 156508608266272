import { CONST } from '@/shared/utils/Constants';
import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { Credetials } from '@/shared/model/credentials';
import LSService from '../../../utils/LocalStorageService';
import AuthService from '@/shared/utils/AuthService';
const authService = new AuthService();
const lsService = LSService.getService();
export const getters: GetterTree<AuthState, RootState> = {
  /**
   * Answers whether the user is authenticated (an access token is provided).
   */
  // return a function so it is no cached
  ['token']: (state: AuthState) => lsService.getAccessToken(),
  ['getIsLeftDrawerShown']: (state) => state.isLeftDrawerShown,
  ['getHeaderNavData']: (state) => state.headerNavData,
  isLoggedIn(state): boolean {
    const user = lsService.getAccount().expires_at;
    const nowx = +(Date.now() + '').substring(0, 10);
    return user - nowx > 0;
  },
  ['roleId']: (state: AuthState) => state.account?.profile?.role_id,
  ['isOwner']: (state: AuthState) => state.account?.profile?.is_owner == 'True',
  ['isSeller']: (state: AuthState) => state.account?.profile?.is_seller == 'True',
  ['isSiteOwner']: (state: AuthState) => state.account?.profile?.is_site_owner == 'True',
  ['isImpersonate']: (state: AuthState) => state.account?.profile?.impersonate == 'true',
  ['SettingsOrganisationsAllowed']: (state: AuthState) => state.account?.profile?.SettingsOrganisations == 'allowed',
  ['SettingsMembersAllowed']: (state: AuthState) => state.account?.profile?.SettingsMembers == 'allowed',
  ['SettingsRolesAllowed']: (state: AuthState) => state.account?.profile.SettingsRoles == 'allowed',
  ['SettingsAllocationsAllowed']: (state: AuthState) => state.account?.profile?.SettingsAllocations == 'allowed',
  ['SettingsFoldersAllowed']: (state: AuthState) => state.account?.profile?.SettingsFolders == 'allowed',
  ['SettingsDocumentsAllowed']: (state: AuthState) => state.account?.profile?.SettingsDocuments == 'allowed',
  ['SettingsActionLogsAllowed']: (state: AuthState) => state.account?.profile?.SettingsActionLogs == 'allowed',
  ['ViewAddressesAllowed']: (state: AuthState) => state.account?.profile?.ViewAddresses == 'allowed',
  ['EditAddressesAllowed']: (state: AuthState) => state.account?.profile?.EditAddresses == 'allowed',
  ['SettingsProcessesAllowed']: (state: AuthState) => state.account?.profile?.SettingsProcesses == 'allowed',
  ['ViewAllProcessListsAllowed']: (state: AuthState) => state.account?.profile?.ViewAllProcessLists == 'allowed',
  ['ViewProcessListsAllowed']: (state: AuthState) => state.account?.profile?.ViewProcessLists == 'allowed',
  ['EditProcessListsAllowed']: (state: AuthState) => state.account?.profile?.EditProcessLists == 'allowed',
  ['DeleteProcessListsAllowed']: (state: AuthState) => state.account?.profile?.DeleteProcessLists == 'allowed',
  ['OwnerRightPayedView']: (state: AuthState) => state.account?.profile?.OwnerRight_payed_View == 'allowed',
  ['OwnerRightAcceptedView']: (state: AuthState) => state.account?.profile?.OwnerRight_accepted_View == 'allowed',
  ['OwnerRightFinalCheckView']: (state: AuthState) => state.account?.profile?.OwnerRight_final_check_View == 'allowed',
  ['OwnerRightExtra1View']: (state: AuthState) => state.account?.profile?.OwnerRight_extra1_View == 'allowed',
  ['OwnerRightExtra2View']: (state: AuthState) => state.account?.profile?.OwnerRight_extra2_View == 'allowed',
  ['OwnerRightExtra3View']: (state: AuthState) => state.account?.profile?.OwnerRight_extra3_View == 'allowed',
  ['OwnerRightExtra4View']: (state: AuthState) => state.account?.profile?.OwnerRight_extra4_View == 'allowed',
  ['OwnerRightExtra5View']: (state: AuthState) => state.account?.profile?.OwnerRight_extra5_View == 'allowed',
  [`OwnerRightExtra${6}View`]: (state: AuthState) => state.account?.profile[`OwnerRight_extra${6}_View`] == 'allowed',
  [`OwnerRightExtraNView`]: (state: AuthState) => (n: number) =>
    state.account?.profile[`OwnerRight_extra${n}_View`] == 'allowed',
  ['UploadDocumentsAllowed']: (state: AuthState) => state.account?.profile?.UploadDocuments == 'allowed',
  ['DeleteDocumentsAllowed']: (state: AuthState) => state.account?.profile?.DeleteDocuments == 'allowed',
  ['DeleteDocumentsFromTrashAllowed']: (state: AuthState) =>
    state.account?.profile?.DeleteDocumentsFromTrash == 'allowed',
  ['DocumentShowOnlyIdSearchAllowed']: (state: AuthState) =>
    state.account?.profile?.DocumentShowOnlyIdSearch == 'allowed',
  ['EditDocumentInformationsAllowed']: (state: AuthState) =>
    state.account?.profile?.EditDocumentInformations == 'allowed',
  ['MergeDocumentsAllowed']: (state: AuthState) => state.account?.profile?.MergeDocuments == 'allowed',
  ['CheckDocumentGobdAllowed']: (state: AuthState) => state.account?.profile?.CheckDocumentGobd == 'allowed',
  ['EditDocumentAllocationsAllowed']: (state: AuthState) =>
    state.account?.profile?.EditDocumentAllocations == 'allowed',
  ['ExportDocumentAllocationsAllowed']: (state: AuthState) =>
    state.account?.profile?.ExportDocumentAllocations == 'allowed',
  ['ViewDocumentCommentsAllowed']: (state: AuthState) => state.account?.profile?.ViewDocumentComments == 'allowed',
  ['CloseAllDocumentThreadsAllowed']: (state: AuthState) =>
    state?.account?.profile?.CloseAllDocumentThreads == 'allowed',
  ['PayedViewAllowed']: (state: AuthState) => state.account?.profile?.payed_View == 'allowed',
  ['AcceptedViewAllowed']: (state: AuthState) => state.account?.profile?.accepted_View == 'allowed',
  ['FinalCheckViewAllowed']: (state: AuthState) => state.account?.profile?.final_check_View == 'allowed',
  ['Extra1ViewAllowed']: (state: AuthState) => state.account?.profile?.extra1_View == 'allowed',
  ['Extra2ViewAllowed']: (state: AuthState) => state.account?.profile?.extra2_View == 'allowed',
  ['Extra3ViewAllowed']: (state: AuthState) => state.account?.profile?.extra3_View == 'allowed',
  ['Extra4ViewAllowed']: (state: AuthState) => state.account?.profile?.extra4_View == 'allowed',
  ['Extra5ViewAllowed']: (state: AuthState) => state.account?.profile?.extra5_View == 'allowed',
  ['Extra6ViewAllowed']: (state: AuthState) => state.account?.profile?.extra6_View == 'allowed',
  ['ExtraNViewAllowed']: (state: AuthState) => (n: number) => state.account?.profile[`extra${n}_View`] == 'allowed',
  isRightWriteAllowed: (state) => (statusValue: string) => {
    if (state.account?.profile?.is_owner == 'True')
      if (state.account?.profile?.is_owner == 'True') {
        switch (statusValue) {
          case 'payed':
            return state.account?.profile.OwnerRight_payed_View == 'allowed';
          case 'accepted':
            return state.account?.profile.OwnerRight_accepted_View == 'allowed';
          case 'final_check':
            return state.account?.profile.OwnerRight_final_check_View == 'allowed';
          case 'extra1':
            return state.account?.profile.OwnerRight_extra1_View == 'allowed';
          case 'extra2':
            return state.account?.profile.OwnerRight_extra2_View == 'allowed';
          case 'extra3':
            return state.account?.profile.OwnerRight_extra3_View == 'allowed';
          case 'extra4':
            return state.account?.profile.OwnerRight_extra4_View == 'allowed';
          case 'extra5':
            return state.account?.profile.OwnerRight_extra5_View == 'allowed';
          case 'extra6':
            return state.account?.profile.OwnerRight_extra6_View == 'allowed';
          case 'extra7':
          case 'extra8':
          case 'extra9':
          case 'extra10':
          case 'extra11':
          case 'extra12':
            return state.account?.profile[`OwnerRight_${statusValue}_View`] == 'allowed';

          default:
            return true;
        }
      }
    switch (statusValue) {
      case 'payed':
        return state.account?.profile.payed_Write == 'allowed';
      case 'accepted':
        return state.account?.profile.accepted_Write == 'allowed';
      case 'final_check':
        return state.account?.profile.final_check_Write == 'allowed';
      case 'extra1':
        return state.account?.profile.extra1_Write == 'allowed';
      case 'extra2':
        return state.account?.profile.extra2_Write == 'allowed';
      case 'extra3':
        return state.account?.profile.extra3_Write == 'allowed';
      case 'extra4':
        return state.account?.profile.extra4_Write == 'allowed';
      case 'extra5':
        return state.account?.profile.extra5_Write == 'allowed';
      case 'extra6':
        return state.account?.profile.extra6_Write == 'allowed';
      case 'extra7':
      case 'extra8':
      case 'extra9':
      case 'extra10':
      case 'extra11':
      case 'extra12':
        return state.account?.profile[`${statusValue}_Write`] == 'allowed';

      default:
        return true;
    }
  },
  isRightViewAllowed: (state) => (statusValue: string) => {
    if (state.account?.profile?.is_owner == 'True') {
      switch (statusValue) {
        case 'payed':
        case 'accepted':
        case 'final_check':
        case 'extra1':
        case 'extra2':
        case 'extra3':
        case 'extra4':
        case 'extra5':
        case 'extra6':
        case 'extra7':
        case 'extra8':
        case 'extra9':
        case 'extra10':
        case 'extra11':
        case 'extra12':
          return state.account?.profile[`OwnerRight_${statusValue}_View`] == 'allowed';

        default:
          return true;
      }
    }
    switch (statusValue) {
      case 'payed':
        return state.account?.profile.payed_View == 'allowed';
      case 'accepted':
        return state.account?.profile.accepted_View == 'allowed';
      case 'final_check':
        return state.account?.profile.final_check_View == 'allowed';
      case 'extra1':
        return state.account?.profile.extra1_View == 'allowed';
      case 'extra2':
        return state.account?.profile.extra2_View == 'allowed';
      case 'extra3':
        return state.account?.profile.extra3_View == 'allowed';
      case 'extra4':
        return state.account?.profile.extra4_View == 'allowed';
      case 'extra5':
        return state.account?.profile.extra5_View == 'allowed';
      case 'extra6':
        return state.account?.profile.extra6_View == 'allowed';
      case 'extra7':
      case 'extra8':
      case 'extra9':
      case 'extra10':
      case 'extra11':
      case 'extra12':
        return state.account?.profile[`${statusValue}_View`] == 'allowed';

      default:
        return true;
    }
  },
  getAccount(state): any {
    return state.account;
  },
  getCurrentOrganizationId(state: AuthState): any {
    return state.account?.profile?.current_organization_id;
  },
  getAccountFullName(state: AuthState): any {
    if (state.account?.profile?.impersonate == 'true') return state.account?.profile?.impersonate_name;

    if (state.account?.profile?.given_name.length > 0 && state.account?.profile?.family_name.length > 0)
      return state.account?.profile?.given_name + ' ' + state.account?.profile?.family_name;
    else if (state.account?.profile?.nickname.length > 0) return state.account?.profile?.nickname;
    else return state.account?.profile?.preferred_username;
  },
};
