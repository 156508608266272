import { ActionTree } from 'vuex';
import { FolderRuleState } from './types';
import { RootState } from '../../types';
import { defaultBackendFolderRule } from '@/shared/backend/folderRule';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import folderRule, { Value, FolderRule } from '@/shared/model/folderRule';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.folderRules');
export const actions: ActionTree<FolderRuleState, RootState> = {
  getFolderRules({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; folderId?: string }) {
    commit('setFolderRulesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getFolderRulesSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendFolderRule
      .getFolderRules(searchParams, organizationId, payload?.folderId || '')
      .then((response: AxiosResponse<Value>) => {
        commit('setFolderRules', response.data);
        commit('setFolderRulesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setFolderRulesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getFolderRule({ commit, dispatch }, id: string) {
    return defaultBackendFolderRule
      .getFolderRule(id)
      .then((response: AxiosResponse<FolderRule>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateFolderRule({ commit, dispatch }, file: FolderRule) {
    return defaultBackendFolderRule
      .updateFolderRule(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: FolderRule;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.folderRule_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteFolderRule({ commit, dispatch }, id: string) {
    return defaultBackendFolderRule
      .deleteFolderRule(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: FolderRule;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
