import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import user, { User, Value } from '../model/user';
import userRight, { UserRight, Value as UserRightValue } from '../model/userRight';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
import JsObjectUtils from '../utils/jsObjectUtils';
import { Role } from '../model/role';
import { ConfirmEmailDto, EmailData, PasswordData } from '@/shared/model/smallPayloadModels/emailData';

const logger = new Logger('backend.User');
export interface BackendUser {
  getUser(id: string): AxiosPromise<User>;
  getUsers: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  getQaUsers: (searchParams: SearchParams, organisationId: string, searchData?: any) => AxiosPromise<Value>;
  getUserRights: (userId: string, searchParams: SearchParams) => AxiosPromise<UserRightValue>;
  deleteUser(id: string): AxiosPromise;
  updateUser(User: Partial<User>): AxiosPromise<any>;
  registerUser(User: Partial<User>): AxiosPromise<any>;
  updateInfoAndPassword(
    id: string,
    info: {
      email: string;
      firstName: string;
      lastName: string;
      kurzform: string;
      password: string;
      pdfZoom: string;
      phoneNumber: string;
    }
  ): AxiosPromise<any>;
  lockAccess(id: string): AxiosPromise<any>;
  unlockAccess(id: string): AxiosPromise<any>;
  moveToRecycle(id: string): AxiosPromise<string>;
  restoreFromRecycle(id: string): AxiosPromise;
  getUserRole(id: string): AxiosPromise<Role>;
  updateUserRole(userId: string, userRoleId: string, roleId: string): AxiosPromise<any>;
  updateUserRights(userId: string, rights: UserRight[]): AxiosPromise<any>;

  sendEmail(emailData: EmailData): AxiosPromise;
  setNewPasswordViaEmail(passwordData: PasswordData): AxiosPromise;
  confirmEmail(data: ConfirmEmailDto): AxiosPromise;
  IsUserEmailExist(email: string): AxiosPromise<any>;
}

export const defaultBackendUser: BackendUser = {
  getUser(id: string): AxiosPromise<User> {
    const url = `${URLS.userOdata}/${id}`;
    return instance.get<User>(url);
  },
  getUserRole(id: string): AxiosPromise<Role> {
    const url = `${URLS.userOdata}/(${id})/Role`;
    return instance.get<Role>(url);
  },
  getUsers(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq('MainOrganizationId', organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      // TODO need separate request for userRoles , too many data from backend
      ['$expand=UserRoles', '$select=id,Email,Kurzform,FirstName,LastName,name,personalNumber,isSeller,userType'],
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getUsers${url}`);
    return instance.get<Value>(url);
  },
  getQaUsers(searchParams: SearchParams, organisationId: string, searchData?: any): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('or');
    if (searchData?.userIds?.length > 0) {
      odfb.or(queryForUserIds(searchData.userIds)); // `odfb.in` converted in multiple `id eq ...` statements in URL
      // if use just `odfb.in` then get the next ERROR because of single quotes: `A binary operator with incompatible types was detected. Found operand types 'Edm.Guid' and 'Edm.String' for operator kind 'Equal'.`
    }
    if (searchData?.roleIds?.length > 0) {
      odfb.or(queryForRoleIds(searchData.roleIds));
    }

    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      // TODO need separate request for userRoles , too many data from backend
      ['$expand=UserRoles', '$select=id,Email,Kurzform,FirstName,LastName,name,personalNumber,isSeller,userType']
    );
    logger.log(`getUsers${url}`);
    return instance.get<Value>(url);
  },

  getUserRights(userId: string, searchParams: SearchParams): AxiosPromise<UserRightValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userOdata}(${userId})/Rights`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined
    );
    logger.log(`getUserRights${url}`);
    return instance.get<UserRightValue>(url);
  },

  deleteUser(id: string): AxiosPromise {
    logger.debug('deleteUser');
    return instance.delete(`${URLS.userOdata}(${id})`);
  },
  updateUser(item: Partial<User>): AxiosPromise<any> {
    logger.debug('updateUser');

    if (item.id) {
      return instance.patch<User>(`${URLS.userOdata}(${item.id})`, user.toAPI(item));
    } else {
      const formData = JsObjectUtils.getFormData(user.toAPI(item));
      return instance.post<User>(`${URLS.userOdata}`, formData);
    }
  },
  registerUser(item: Partial<User>): AxiosPromise<any> {
    logger.debug('registerUser');

    const formData = JsObjectUtils.getFormData(user.toAPI(item));
    return instance.post<User>(`${URLS.userOdata}/register`, formData);
  },
  updateInfoAndPassword(
    id: string,
    info: {
      email: string;
      firstName: string;
      lastName: string;
      kurzform: string;
      password: string;
      pdfZoom: string;
      phoneNumber: string;
    }
  ): AxiosPromise<any> {
    logger.debug('updateInfoAndPassword');
    return instance.post(`${URLS.userOdata}(${id})/UpdateInfoAndPassword`, info);
  },
  lockAccess(id: string): AxiosPromise<any> {
    return instance.post(`${URLS.userOdata}(${id})/LockAccess`);
  },
  unlockAccess(id: string): AxiosPromise<any> {
    return instance.post(`${URLS.userOdata}(${id})/UnlockAccess`);
  },
  moveToRecycle(id: string): AxiosPromise {
    logger.debug('moveToRecycleOrganisationUser');
    return instance.post(`${URLS.userOdata}(${id})/MoveToRecycle`);
  },
  restoreFromRecycle(id: string): AxiosPromise {
    logger.debug('restoreToRecycle');
    return instance.post(`${URLS.userOdata}(${id})/RestoreFromRecycle`);
  },
  updateUserRole(userId: string, userRoleId: string, roleId: string): AxiosPromise<any> {
    logger.debug('updateUserRole');

    return instance.patch<User>(`${URLS.userOdata}(${userId})/UserRoles`, {
      id: userRoleId,
      userId: userId,
      roleId: roleId,
    });
  },
  updateUserRights(userId: string, items: UserRight[]): AxiosPromise<any> {
    logger.debug('updateUserRights');
    return instance.post<User>(`${URLS.userOdata}(${userId})/MultipleUpdateRights`, {
      rights: items.map((item) => userRight.toAPI(item)),
    });
  },
  sendEmail(emailData: EmailData): AxiosPromise<any> {
    return instance.put<Value>(`${URLS.userOdata}/sendEmail`, emailData);
  },
  setNewPasswordViaEmail(passwordData: PasswordData): AxiosPromise<any> {
    return instance.put<Value>(`${URLS.userOdata}/setNewPassword`, passwordData);
  },
  confirmEmail(data: ConfirmEmailDto): AxiosPromise<any> {
    return instance.put<Value>(`${URLS.userOdata}/confirmEmail`, data);
  },
  IsUserEmailExist(email: string): AxiosPromise<any> {
    return instance.post<any>(`${URLS.user}/IsUserEmailExist`, {
      email,
    });
  },
};

//#region ED-896 Format selected `roleIds` and `userIds` for Odata URL

function queryForRoleIds(roleIds: any) {
  let result = '';
  roleIds.forEach((el: string, i: number, array: Array<string>) => {
    result += ` a/roleId eq ${el} ${i !== array.length - 1 ? ' or' : ''}`; // Add `or` for multiple values except last one
  });
  return `UserRoles/any(a:${result})`;
}

function queryForUserIds(userIds: any) {
  let result = '';
  userIds.forEach((el: string, i: number, array: Array<string>) => {
    result += ` id eq ${el} ${i !== array.length - 1 ? ' or' : ''}`; // Add `or` for multiple values except last one
  });
  return result;
}
//#endregion
