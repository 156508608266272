import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import costCentreImportSetting, { CostCentreImportSetting, Value } from '../model/costCentreImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.CostCentreImportSetting');
export interface BackendCostCentreImportSetting {
  getCostCentreImportSetting(id: string): AxiosPromise<CostCentreImportSetting>;
  getCostCentreImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteCostCentreImportSetting(id: string): AxiosPromise;
  updateCostCentreImportSetting(CostCentreImportSetting: CostCentreImportSetting): AxiosPromise<any>;
}

export const defaultBackendCostCentreImportSetting: BackendCostCentreImportSetting = {
  getCostCentreImportSetting(id: string): AxiosPromise<CostCentreImportSetting> {
    const url = `${URLS.costCentreImportSettingOdata}/${id}`;
    return instance.get<CostCentreImportSetting>(url);
  },
  getCostCentreImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.costCentreImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getCostCentreImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteCostCentreImportSetting(id: string): AxiosPromise {
    logger.debug('deleteCostCentreImportSetting');
    return instance.delete(`${URLS.costCentreImportSetting}/${id}`);
  },
  updateCostCentreImportSetting(CostCentreImportSetting: CostCentreImportSetting): AxiosPromise<any> {
    logger.debug('updateCostCentreImportSetting');
    return instance.put<CostCentreImportSetting>(
      `${URLS.costCentreImportSetting}/update`,
      costCentreImportSetting.toAPI(CostCentreImportSetting)
    );
  },
};
