import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { Document } from '@/shared/model/document';
import { th } from 'date-fns/locale';
import { UserFile } from '@/shared/model/userFile';

const logger = new Logger('menu-document-splitpdf-view');

const userFileModule = namespace('userFile');
const documentModule = namespace('document');
const authModule = namespace('auth');

@Component({ name: 'menu-document-splitpdf-view', components: {} })
export default class MenuDocumentSplitpdfView extends Vue {
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;

  @documentModule.Action('split')
  private actionSplit!: any;

  @userFileModule.Getter('getUserFile')
  private getFile!: UserFile;

  mounted() {
    this.createInitialSplits();
  }

  get documentPagesCount() {
    return this.getterGetDocument.pageCount;
  }

  private splitsArray: {
    from: number;
    to: number;
    toLabel: string;
  }[] = [];

  get splits() {
    return this.splitsArray;
  }

  private createInitialSplits() {
    this.splitsArray.push({ from: 1, to: this.documentPagesCount, toLabel: 'bis ' + this.documentPagesCount });
  }

  private returnBack() {
    this.$emit('click:return');
  }

  private addSplit() {
    if (this.documentPagesCount == 1) {
      return;
    }

    if (this.splitsArray.length == 0) {
      this.createInitialSplits();
      return;
    }

    const arrStarts: number[] = [];
    for (let i = 1; i < this.documentPagesCount + 1; i++) {
      const result1 = this.splitsArray.find((obj) => {
        return obj.from === i && obj.to > i;
      });

      if (result1) {
        const result = this.splitsArray.find((obj) => {
          return obj.from === i && obj.to === i;
        });

        if (!result) {
          const indexOf = this.splitsArray.findIndex((obj) => {
            return obj.from === i;
          });

          this.splitsArray.splice(indexOf, 1);

          this.splitsArray.push({ from: i, to: i, toLabel: 'bis ' + i });
          this.splitsArray.push({
            from: i + 1,
            to: this.documentPagesCount,
            toLabel: 'bis ' + this.documentPagesCount,
          });

          this.orderSplits();

          return;
        }
      }
    }

    //let maxItem = Math.max(...this.splitsArray.map((o) => o.to));
    //let newItem = maxItem + 1;
    //this.splitsArray.push({ from: maxItem, to: newItem, toLabel: 'bis ' + newItem });
  }

  private orderSplits() {
    this.splitsArray.sort(function (a, b) {
      const keyA = new Number(a.from),
        keyB = new Number(b.from);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  private removeSplit(item: any, index: any) {
    if (this.splitsArray.length >= 1) {
      if (index - 1 < 0) {
        this.splitsArray.splice(index, 1);
        return;
      }

      this.splitsArray[index - 1].to = parseInt(this.splitsArray[index].from.toString());
      this.splitsArray[index - 1].toLabel = 'bis ' + this.splitsArray[index].from;

      this.splitsArray.splice(index, 1);

      if (this.splitsArray.length == 1) {
        this.splitsArray[0].from = 1;
        this.splitsArray[0].to = this.documentPagesCount;
        this.splitsArray[0].toLabel = 'bis ' + this.documentPagesCount;
      }

      this.setLastRow();
      this.orderSplits();
    }
  }

  private changedSplit(item: any, index: any) {
    this.setFirstRow();

    this.splitsArray[index].from = parseInt(this.splitsArray[index].from.toString());

    const fromMoreTenMax = this.splitsArray[index].from > this.documentPagesCount;
    const changedFrom = this.splitsArray[index].from;
    const changedTo = this.splitsArray[index].to;

    if (changedFrom < this.splitsArray[index - 1].to) this.splitsArray[index].from = this.splitsArray[index - 1].to + 1;

    if (changedTo < changedFrom) {
      this.splitsArray[index].to = parseInt(this.splitsArray[index].from.toString());
      this.splitsArray[index].toLabel = 'bis ' + this.splitsArray[index].from;
    }

    for (let i = index + 1; i < this.splitsArray.length; i++) {
      if (this.splitsArray[i].from == this.splitsArray[i].to) {
        this.splitsArray[i].from = parseInt(this.splitsArray[i - 1].to.toString()) + 1;
        this.splitsArray[i].to = parseInt(this.splitsArray[i - 1].to.toString()) + 1;
        this.splitsArray[i].toLabel = 'bis ' + (parseInt(this.splitsArray[i - 1].to.toString()) + 1);
      } else {
        this.splitsArray[i].from = parseInt(this.splitsArray[i - 1].to.toString()) + 1;
      }
    }

    for (let i = index - 1; i >= 0; i--) {
      //console.error(this.splitsArray[i].to, this.splitsArray[i + 1].from);
      if (this.splitsArray[i].to > this.splitsArray[i + 1].from) {
        //this.splitsArray[i].from = parseInt(this.splitsArray[i + 1].to.toString()) - 1;
        this.splitsArray[i].to = parseInt(this.splitsArray[i + 1].to.toString()) - 1;
        this.splitsArray[i].toLabel = 'bis ' + (parseInt(this.splitsArray[i + 1].to.toString()) - 1);
      }
    }

    this.setLastRow();
  }

  private setFirstRow() {
    if (this.splitsArray.length > 0) this.splitsArray[0].from = 1;
  }

  private isExeed = false;
  private setLastRow() {
    this.isExeed = false;
    const lastIndex = this.splitsArray.length - 1;
    if (this.splitsArray[lastIndex].from < this.documentPagesCount) {
      this.splitsArray[lastIndex].to = this.documentPagesCount;
      this.splitsArray[lastIndex].toLabel = 'bis ' + this.documentPagesCount;
    }

    if (this.splitsArray[lastIndex].to < this.splitsArray[lastIndex].from)
      this.splitsArray[lastIndex].to = parseInt(this.splitsArray[lastIndex].from.toString());
    if (this.splitsArray[lastIndex].to > this.documentPagesCount) {
      this.splitsArray[lastIndex].toLabel = 'bis max';
      this.isExeed = true;
    }
  }

  private keepOriginal: boolean = false;

  private onlyCreateDublicate() {
    return (
      (this.splitsArray.length == 1 &&
        this.splitsArray[0].from == 1 &&
        this.splitsArray[0].to == this.documentPagesCount) ||
      this.splitsArray.length == 0
    );
  }

  private splitPdf() {
    const pages: any = [];
    this.splitsArray.forEach((element: any) => {
      pages.push(element.from + '-' + element.to);
    });

    if (pages.length == 0) {
      pages.push('1-' + this.documentPagesCount);
    }

    this.actionSplit({
      pages: pages,
      deleteSource: !this.keepOriginal,
      onlyCreateDublicate: this.onlyCreateDublicate(),
      documentNumber: this.getterGetDocument.documentNumber,
      fileId: this.getFile.id,
      folderId: this.getterGetDocument.folderId,
    }).then(() => {
      this.$emit('click:split');
    });
  }
}
