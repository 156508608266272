import { render, staticRenderFns } from "./account-template-edit-component.html?vue&type=template&id=51056472&scoped=true&external"
import script from "./account-template-edit-component.ts?vue&type=script&lang=ts&external"
export * from "./account-template-edit-component.ts?vue&type=script&lang=ts&external"
import style0 from "./account-template-edit-component.scss?vue&type=style&index=0&id=51056472&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51056472",
  null
  
)

/* custom blocks */
import block0 from "./account-template-edit-component.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-account%2Fmenu-document-account-template-edit%2Faccount-template-edit-component%2Faccount-template-edit-component.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCol,VCombobox,VIcon,VListItem,VListItemTitle,VRow,VTextField})
