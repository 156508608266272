import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationBrandsImportSetting, {
  AllocationBrandsImportSetting,
  Value,
} from '../model/allocationBrandsImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationBrandsImportSetting');
export interface BackendAllocationBrandsImportSetting {
  getAllocationBrandsImportSetting(id: string): AxiosPromise<AllocationBrandsImportSetting>;
  getAllocationBrandsImportSettings: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationBrandsImportSetting(id: string): AxiosPromise;
  updateAllocationBrandsImportSetting(AllocationBrandsImportSetting: AllocationBrandsImportSetting): AxiosPromise<any>;
}

export const defaultBackendAllocationBrandsImportSetting: BackendAllocationBrandsImportSetting = {
  getAllocationBrandsImportSetting(id: string): AxiosPromise<AllocationBrandsImportSetting> {
    const url = `${URLS.allocationBrandsImportSettingOdata}/${id}`;
    return instance.get<AllocationBrandsImportSetting>(url);
  },
  getAllocationBrandsImportSettings(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationBrandsImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationBrandsImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationBrandsImportSetting(id: string): AxiosPromise {
    logger.debug('deleteAllocationBrandsImportSetting');
    return instance.delete(`${URLS.allocationBrandsImportSetting}/${id}`);
  },
  updateAllocationBrandsImportSetting(AllocationBrandsImportSetting: AllocationBrandsImportSetting): AxiosPromise<any> {
    logger.debug('updateAllocationBrandsImportSetting');
    return instance.put<AllocationBrandsImportSetting>(
      `${URLS.allocationBrandsImportSetting}/update`,
      allocationBrandsImportSetting.toAPI(AllocationBrandsImportSetting)
    );
  },
};
