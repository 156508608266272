export default class RouterUtils {
  /**
   * (string) Empty GUID with '00000000-0000-0000-0000-000000000000' value
   */
  public static emptyGuid: string = '00000000-0000-0000-0000-000000000000'; // emptyGuid is used in Routes when create entity like Customer (localhost:3001/customer/00000000-0000-0000-0000-000000000000)

  /**
   * Check if passed GUID is empty, i.e. equals to '00000000-0000-0000-0000-000000000000'
   *
   * @param (string) GUID Id of entity (Customer, Employee, Group, etc)
   */
  public static IsEmptyGuid(guid: string) {
    return guid === this.emptyGuid;
  }

  /**
   * Return null if empty Guid
   *
   * @param (string) GUID Id of entity (Customer, Employee, Group, etc)
   */
  public static NullIfEmptyGuid(guid: string | undefined) {
    return RouterUtils.IsEmptyGuid(guid || '') ? null : guid;
  }
  /**
   * Return '' if undefined
   *
   * @param (string)
   */
  public static EmptyIfUndefined(str: string | undefined) {
    return str || '';
  }

  /**
   * Very basic check if specified GUID is valid, checks :
   * 1) not NULL or undefined
   * 2) has proper length, GUID always is 36 chars
   * 3) not equal to empty GUID ('00000000-0000-0000-0000-000000000000')
   *
   * @param (string) GUID Id of entity (Customer, Employee, Group, etc)
   */
  public static IsGuidId(guid: string) {
    return guid && guid.length === 36 && guid !== this.emptyGuid;
  }
}
