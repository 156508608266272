import { Component, Prop, Vue } from 'vue-property-decorator';
import HomeHelpDialog from '@/views/home/home-help-dialog/home-help-dialog.vue';

@Component({
  components: { HomeHelpDialog },
})
export default class LoginHeaderNav extends Vue {
  //#region showHelpDialog logic
  private showHelpDialog() {
    this.dialogHelp.show = true;
  }

  dialogHelp = {
    show: false,
    OnClose: () => {
      this.dialogHelp.show = false;
    },
  };

  //#endregion

  private navigateToPasswordResetPage() {
    this.$router.push({ path: '/password_reset' });
  }

  private navigateToRegistrationPage() {
    this.$router.push({ path: '/registrieren' });
  }
}
