import { CONST } from './../../../utils/Constants';
import { ActionTree } from 'vuex';
import { AllocationBrandsImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationBrandsImportSetting } from '@/shared/backend/allocationBrandsImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationBrandsImportSetting, {
  Value,
  AllocationBrandsImportSetting,
} from '@/shared/model/allocationBrandsImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.allocationBrandsImportSettings');
export const actions: ActionTree<AllocationBrandsImportSettingState, RootState> = {
  getAllocationBrandsImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationBrandsImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationBrandsImportSettingsSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendAllocationBrandsImportSetting
      .getAllocationBrandsImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationBrandsImportSettings', response.data);
        commit('setAllocationBrandsImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationBrandsImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationBrandsImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setAllocationBrandsImportSettingsIsLoading', true);
    const searchParams = getters.getAllocationBrandsImportSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationBrandsImportSetting
      .getAllocationBrandsImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationBrandsImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllocationBrandsImportSettingsIsLoading', false);
      });
  },
  getAllocationBrandsImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendAllocationBrandsImportSetting
      .getAllocationBrandsImportSetting(id)
      .then((response: AxiosResponse<AllocationBrandsImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationBrandsImportSetting({ commit, dispatch, rootGetters }, file: AllocationBrandsImportSetting) {
    commit('setInProgressUpdateAllocationBrandsImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationBrandsImportSetting
      .updateAllocationBrandsImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationBrandsImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationBrandsImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateAllocationBrandsImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getAllocationBrandsImportSetting.clearBeforeImport = payload.value;
    dispatch('updateAllocationBrandsImportSetting', getters.getAllocationBrandsImportSetting)
      .then((result: any) => {
        getters.getAllocationBrandsImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getAllocationBrandsImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteAllocationBrandsImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationBrandsImportSetting
      .deleteAllocationBrandsImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationBrandsImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
