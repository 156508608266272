import { MutationTree } from 'vuex';
import { CostObjectiveState } from './types';
import Vue from 'vue';
import costObjective, { CostObjective, Value } from '@/shared/model/costObjective';

export const mutations: MutationTree<CostObjectiveState> = {
  setCostObjectives(state, payload: Value) {
    state.costObjectives.items = payload.value.map((x) => costObjective.parse(x));
    state.costObjectives.total = payload['@odata.count'] || 0;
    state.costObjectives.isLoading = false;
  },
  setCostObjectivesTotal(state, payload: number) {
    state.costObjectives.total = payload;
  },
  setCostObjectivesIsLoading(state, payload: boolean) {
    state.costObjectives.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
};
