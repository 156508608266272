import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocation, { Allocation, AllocationEx, Value } from '../model/allocation';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Allocation');
export interface BackendAllocation {
  getAllocation(id: string): AxiosPromise<Allocation>;
  getAllocations: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  getAllByDocumentId: (id: string, allocationId: string) => AxiosPromise<AllocationEx>;
  getListByDocumentId: (id: string, allocationId: string) => AxiosPromise<Allocation[]>;
  getMainAllocationsByDocumentId: (id: string) => AxiosPromise<Allocation[]>;
  deleteAllocation(id: string): AxiosPromise;
  copyAllocation(id: string): AxiosPromise;
  updateAllocation(Allocation: Allocation): AxiosPromise<any>;
}

export const defaultBackendAllocation: BackendAllocation = {
  getAllocation(id: string): AxiosPromise<Allocation> {
    const url = `${URLS.allocationOdata}/${id}`;
    return instance.get<Allocation>(url);
  },
  getAllocations(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocations${url}`);
    return instance.get<Value>(url);
  },

  getAllByDocumentId(id: string, allocationId: string): AxiosPromise<AllocationEx> {
    return instance.get<AllocationEx>(`${URLS.allocation}/GetAllByDocumentId/${id}/${allocationId}`);
  },

  getListByDocumentId(id: string, allocationId: string): AxiosPromise<Allocation[]> {
    return instance.get<Allocation[]>(`${URLS.allocation}/GetListByDocumentId/${id}/${allocationId}`);
  },

  getMainAllocationsByDocumentId(id: string): AxiosPromise<Allocation[]> {
    return instance.get<Allocation[]>(`${URLS.allocation}/GetMainAllocationsByDocumentId/${id}`);
  },

  deleteAllocation(id: string): AxiosPromise {
    logger.debug('deleteAllocation');
    return instance.delete(`${URLS.allocation}/${id}`);
  },
  copyAllocation(id: string): AxiosPromise {
    logger.debug('copyAllocation');
    return instance.put(`${URLS.allocation}/Copy/${id}`);
  },
  updateAllocation(Allocation: Allocation): AxiosPromise<any> {
    logger.debug('updateAllocation');
    return instance.put<Allocation>(`${URLS.allocation}/update`, allocation.toAPI(Allocation));
  },
};
