import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationTemplateProperty, { AllocationTemplateProperty, Value } from '../model/allocationTemplateProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationTemplateProperty');
export interface BackendAllocationTemplateProperty {
  getAllocationTemplateProperty(id: string): AxiosPromise<AllocationTemplateProperty>;
  getAllocationTemplatePropertys: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationTemplateProperty(id: string): AxiosPromise;
  updateAllocationTemplateProperty(AllocationTemplateProperty: AllocationTemplateProperty): AxiosPromise<any>;
}

export const defaultBackendAllocationTemplateProperty: BackendAllocationTemplateProperty = {
  getAllocationTemplateProperty(id: string): AxiosPromise<AllocationTemplateProperty> {
    const url = `${URLS.allocationTemplatePropertyOdata}/${id}`;
    return instance.get<AllocationTemplateProperty>(url);
  },
  getAllocationTemplatePropertys(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTemplatePropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationTemplatePropertys${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationTemplateProperty(id: string): AxiosPromise {
    logger.debug('deleteAllocationTemplateProperty');
    return instance.delete(`${URLS.allocationTemplateProperty}/${id}`);
  },
  updateAllocationTemplateProperty(AllocationTemplateProperty: AllocationTemplateProperty): AxiosPromise<any> {
    logger.debug('updateAllocationTemplateProperty');
    return instance.put<AllocationTemplateProperty>(
      `${URLS.allocationTemplateProperty}/update`,
      allocationTemplateProperty.toAPI(AllocationTemplateProperty)
    );
  },
};
