import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentPagesText, { DocumentPagesText, Value } from '../model/documentPagesText';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentPagesText');
export interface BackendDocumentPagesText {
  getDocumentPagesText(id: string): AxiosPromise<DocumentPagesText>;
  getDocumentPagesTexts: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentPagesText(id: string): AxiosPromise;
  updateDocumentPagesText(DocumentPagesText: DocumentPagesText): AxiosPromise<any>;
}

export const defaultBackendDocumentPagesText: BackendDocumentPagesText = {
  getDocumentPagesText(id: string): AxiosPromise<DocumentPagesText> {
    const url = `${URLS.documentPagesTextOdata}/${id}`;
    return instance.get<DocumentPagesText>(url);
  },
  getDocumentPagesTexts(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentPagesTextOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentPagesTexts${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentPagesText(id: string): AxiosPromise {
    logger.debug('deleteDocumentPagesText');
    return instance.delete(`${URLS.documentPagesText}/${id}`);
  },
  updateDocumentPagesText(DocumentPagesText: DocumentPagesText): AxiosPromise<any> {
    logger.debug('updateDocumentPagesText');
    return instance.put<DocumentPagesText>(
      `${URLS.documentPagesText}/update`,
      documentPagesText.toAPI(DocumentPagesText)
    );
  },
};
