import { GetterTree } from 'vuex';
import { RegexSplitterPatternPartState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RegexSplitterPatternPartState, RootState> = {
  ['getRegexSplitterPatternParts']: (state) => state.regexSplitterPatternParts,
  ['getRegexSplitterPatternPartsIsLoading']: (state) => state.regexSplitterPatternParts?.isLoading,
  ['getRegexSplitterPatternPartsTotal']: (state) => state.regexSplitterPatternParts?.total,
  ['getRegexSplitterPatternPartsSearchParams']: (state) => state.regexSplitterPatternParts?.searchParams,
};
