export interface DocumentRemindersReceiverUser {
  id: string; // System.Guid
  documentRemindersId: string; // System.Guid
  documentId: string; // System.Guid
  organisationId: string; // System.Guid
  userId: string; // System.Guid
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: DocumentRemindersReceiverUser[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentRemindersReceiverUser>): any {
  return {
    Id: data?.id || undefined,
    DocumentRemindersId: data?.documentRemindersId || undefined,
    DocumentId: data?.documentId || undefined,
    OrganisationId: data?.organisationId || undefined,
    UserId: data?.userId || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
  };
}

function parse(data?: Partial<DocumentRemindersReceiverUser>): DocumentRemindersReceiverUser {
  return {
    id: data?.id || '',
    documentRemindersId: data?.documentRemindersId || '',
    documentId: data?.documentId || '',
    organisationId: data?.organisationId || '',
    userId: data?.userId || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
