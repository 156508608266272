import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import ds, { DocumentState } from '@/shared/model/documentState';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';

const logger = new Logger('document-switches');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const documentModule = namespace('document');
const authModule = namespace('auth');

const documentStateModule = namespace('documentState');

@Component({ name: 'document-switches', components: {} })
export default class DocumentSwitchesView extends Vue {
  @authModule.Getter('isRightWriteAllowed')
  private isRightWriteAllowed!: (name: string) => boolean;
  @authModule.Getter('isRightViewAllowed')
  private isRightViewAllowed!: (name: string) => boolean;

  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocumentIds!: any;

  @documentModule.Action('updateDocumentInDocumentListState')
  private actionUpdateDocumentInDocumentListState!: any;
  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Getter('getDocument')
  private getterDocument!: any;

  @documentStateModule.Action('updateDocumentState')
  private actionUpdateDocumentState!: any;
  @documentStateModule.Action('deleteDocumentState')
  private actionDeleteDocumentState!: any;
  @documentStateModule.Getter('inProgressUpdateDocumentState')
  private inProgressUpdateDocumentState!: boolean;

  @Prop({ default: false })
  private isMultipleMode!: boolean; // (ED-392) Create/Delete `documentState` for multiple documents from `document-list`

  @Watch('getterDocument')
  odDocumentChanged(newV: any, oldV: any) {
    this.documentEntity = newV;
    this.setActiveStatusesFromBackend();
  }

  get isChangeSingleDocument() {
    return this.getterSelectedDocumentIds.length === 0;
  }

  get documentExtraStatuses() {
    return this.getOrganisationDocumentExtraStatusSettings.items;
  }

  get isDocumentInTrash() {
    return !!this.getterDocument?.deleted;
  }

  documentExtraStatusesOld: any[] = [];

  onChange(event: any, item: any) {
    const payload = { event, item };
    this.$emit('update:checkbox', payload);
  }

  changeValue(value: boolean, item: any) {
    if (this.isMultipleMode) {
      this.$emit('update:switch', item);
      return;
    }

    // take implementation from https://neu.easy-docs.de/ (if switch is ON create record if then OFF delete created record)
    if (value) {
      this.createDocumentState(item);
    } else {
      this.deleteDocumentState(item);
    }
  }

  deleteDocumentState(item: any) {
    const id = this.documentEntity.documentStates.find((x: any) => x.name === item.statusValue)?.id;
    if (id) {
      this.actionDeleteDocumentState(id).then((result: any) => {
        this.actionGetDocument(this.documentEntity.id).then((result: any) => {
          this.documentEntity = result;
          this.actionUpdateDocumentInDocumentListState(result);
        });
      });
    }
  }

  private createDocumentState(item: any) {
    const documentState: DocumentState = ds.parse();
    documentState.documentId = this.documentEntity.id; // if use `DocumentId` from `Document` entity then get the `23503: insert or update on table violates foreign key constraint` exception
    // also checked migration and for DocumentState DocumentId FK settings as `principalTable: "documents",principalColumn: "id",`
    documentState.name = item.statusValue;
    documentState.organisationDocumentExtraStatusSettingId = item.id;
    this.actionUpdateDocumentState(documentState)
      .then((result: any) => {
        // console.log('result :>> ', result);
        this.actionGetDocument(this.documentEntity.id).then((result: any) => {
          console.log('result :>> ', result);
          this.documentEntity = result;
          this.actionUpdateDocumentInDocumentListState(result);
        });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  @authModule.Getter('getAccount')
  private getAccount!: any;

  // (ED-969) Fix wrong `document-switches` values when click on the header `previous/next` buttons (they were always the same)
  @Watch('$route.path')
  async onRouteChange(newVal: any, oldVal: any) {
    await this.loadInitData();
    await this.loadDocument();
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  getChangeDate(item: OrganisationDocumentExtraStatusSetting) {
    return DateUtils.formatDate(item.createdAt);
  }

  get userEmail() {
    return this.getAccount?.profile?.name || 'user';
  }

  async created() {
    await this.loadInitData();
    await this.loadDocument();
  }
  // TODO: (ED-216) check `document` value for each `documentStatus` and reflect value from backend (true/false)
  private async loadInitData() {
    logger.log('start loading document statuses for switches');
    await this.actionGetOrganisationDocumentExtraStatusSettings();

    this.documentExtraStatusesOld = this.getOrganisationDocumentExtraStatusSettings.items
      //hide the status based on user rights
      .filter((x: OrganisationDocumentExtraStatusSetting) =>
        this.isChangeSingleDocument
          ? // for document(single) preview status without write right
            this.isRightViewAllowed(x.statusValue) || this.isRightWriteAllowed(x.statusValue)
          : // for multiple documents show only with write right
            this.isRightWriteAllowed(x.statusValue)
      )
      .map((obj: any) => ({
        ...obj,
        active: false,
      })); // temporal solution to track changes for many checkboxes with different `v-models`
  }

  documentEntity: any = {}; // `document` (NOT userFile)
  private async loadDocument() {
    if (this.isMultipleMode) return; // edit from `document-list` so do NOT need to load Document data

    const doc = this.getterDocument;
    // reuse the `document`(NOT userFile) from the Vuex State if it is currently opened document, otherwise load correct `document` from the backend
    if (doc?.id && doc.id === this.documentId) {
      this.documentEntity = doc;
    } else {
      await this.actionGetDocument(this.documentId)
        .then((result: any) => {
          this.documentEntity = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
    this.setActiveStatusesFromBackend();
  }

  setActiveStatusesFromBackend() {
    const activeStatuses = this.documentEntity?.documentStates?.map((x: { name: string; createdAt: string }) => ({
      name: x.name,
      createdAt: x.createdAt,
    }));
    logger.log('activeStatuses :>> ', activeStatuses);

    activeStatuses?.forEach((statusVal: { name: string; createdAt: string }) => {
      const status = this.documentExtraStatusesOld.find((x: any) => x.statusValue === statusVal.name);
      if (status) {
        status.active = true;
        status.createdAt = statusVal.createdAt;
      }
    });
  }
}
