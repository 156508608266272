import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationTaxSetting, { AllocationTaxSetting, Value } from '../model/allocationTaxSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationTaxSetting');
export interface BackendAllocationTaxSetting {
  getAllocationTaxSetting(id: string): AxiosPromise<AllocationTaxSetting>;
  getAllocationTaxSettings: (
    searchParams: SearchParams,
    organizationId: string,
    allocationTaxGroupsId?: string
  ) => AxiosPromise<Value>;
  getAllocationTaxSettingByGroupDate(searchParams: SearchParams, date: string): AxiosPromise<Value>;
  deleteAllocationTaxSetting(id: string): AxiosPromise;
  updateAllocationTaxSetting(AllocationTaxSetting: AllocationTaxSetting): AxiosPromise<any>;
  uploadAllocationTaxSetting(uploadInfo: {
    fileForImport: any;
    allocationTaxGroupsId: string;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendAllocationTaxSetting: BackendAllocationTaxSetting = {
  getAllocationTaxSetting(id: string): AxiosPromise<AllocationTaxSetting> {
    const url = `${URLS.allocationTaxSettingOdata}/${id}`;
    return instance.get<AllocationTaxSetting>(url);
  },
  getAllocationTaxSettings(
    searchParams: SearchParams,
    organizationId: string,
    allocationTaxGroupsId?: string
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    if (allocationTaxGroupsId) odfb = odfb.eq('AllocationTaxGroupsId', allocationTaxGroupsId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAllocationTaxSettings${url}`);
    return instance.get<Value>(url);
  },
  getAllocationTaxSettingByGroupDate(searchParams: SearchParams, date: string): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxSettingOdata}/GetAllocationTaxSettingByGroupDate(date='${date}')`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    return instance.get<Value>(url);
  },
  deleteAllocationTaxSetting(id: string): AxiosPromise {
    logger.debug('deleteAllocationTaxSetting');
    return instance.delete(`${URLS.allocationTaxSetting}/${id}`);
  },
  updateAllocationTaxSetting(AllocationTaxSetting: AllocationTaxSetting): AxiosPromise<any> {
    logger.debug('updateAllocationTaxSetting');
    return instance.put<AllocationTaxSetting>(
      `${URLS.allocationTaxSetting}/update`,
      allocationTaxSetting.toAPI(AllocationTaxSetting)
    );
  },
  uploadAllocationTaxSetting(uploadInfo: {
    fileForImport: any;
    allocationTaxGroupsId: string;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);
    formData.append('AllocationTaxGroupsId', uploadInfo.allocationTaxGroupsId);

    return instance.post<any>(`${URLS.allocationTaxSetting}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
