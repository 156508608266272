import { GetterTree } from 'vuex';
import { AllocationSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationSettingState, RootState> = {
  ['getAllocationSettings']: (state) => state.allocationSettings,
  ['getAllocationSettingsIsLoading']: (state) => state.allocationSettings?.isLoading,
  ['getAllocationSettingsTotal']: (state) => state.allocationSettings?.total,
  ['getAllocationSettingsSearchParams']: (state) => state.allocationSettings?.searchParams,
  ['getAllocationSetting']: (state) => state.allocationSetting,
};
