import DateUtils from '@/shared/utils/DateUtils';
import { MutationTree } from 'vuex';
import { AddressState } from './types';
import Vue from 'vue';
import address, { Address, Value } from '@/shared/model/address';
const du = DateUtils;
export const mutations: MutationTree<AddressState> = {
  setAddresses(state, payload: Value) {
    state.addresses.items = payload.value.map((x) => address.parse(x));
    state.addresses.total = payload['@odata.count'] || 0;
    state.addresses.isLoading = false;
  },
  setAddressesTotal(state, payload: number) {
    state.addresses.total = payload;
  },
  setAddressesIsLoading(state, payload: boolean) {
    state.addresses.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
  updateIsImporting(state, payload: Date) {
    state.isImporting = state.isImporting == false;
  },
  setInProgressGetAddress(state, payload: boolean) {
    state.inProgressGetAddress = payload;
  },
  setImportingFile(state, payload: any) {
    state.importingFile = payload;
    state.isImporting = false;
  },
};
