import { render, staticRenderFns } from "./menu-document-notes.html?vue&type=template&id=7b8ea0f6&scoped=true&external"
import script from "./menu-document-notes.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-notes.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-notes.scss?vue&type=style&index=0&id=7b8ea0f6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8ea0f6",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-notes.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-notes%2Fmenu-document-notes.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VTextarea})
