import { GetterTree } from 'vuex';
import { ProcessListsExportState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ProcessListsExportState, RootState> = {
  ['getProcessListsExports']: (state) => state.processListsExports,
  ['getProcessListsExportsIsLoading']: (state) => state.processListsExports?.isLoading,
  ['getProcessListsExportsTotal']: (state) => state.processListsExports?.total,
  ['getProcessListsExportsSearchParams']: (state) => state.processListsExports?.searchParams,
};
