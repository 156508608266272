import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentFilesExport, { DocumentFilesExport, Value } from '../model/documentFilesExport';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentFilesExport');
export interface BackendDocumentFilesExport {
  getDocumentFilesExport(id: string): AxiosPromise<DocumentFilesExport>;
  getDocumentFilesExports: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentFilesExport(id: string): AxiosPromise;
  updateDocumentFilesExport(DocumentFilesExport: DocumentFilesExport): AxiosPromise<any>;
  markAsDownloadedPdfExport(id: string): AxiosPromise;
  createPdfExport(value: string, timezoneOffet: number): AxiosPromise;
}

export const defaultBackendDocumentFilesExport: BackendDocumentFilesExport = {
  getDocumentFilesExport(id: string): AxiosPromise<DocumentFilesExport> {
    const url = `${URLS.documentFilesExportOdata}/${id}`;
    return instance.get<DocumentFilesExport>(url);
  },
  getDocumentFilesExports(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentFilesExportOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentFilesExports${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentFilesExport(id: string): AxiosPromise {
    logger.debug('deleteDocumentFilesExport');
    return instance.delete(`${URLS.documentFilesExport}/${id}`);
  },
  updateDocumentFilesExport(DocumentFilesExport: DocumentFilesExport): AxiosPromise<any> {
    logger.debug('updateDocumentFilesExport');
    return instance.put<DocumentFilesExport>(
      `${URLS.documentFilesExport}/update`,
      documentFilesExport.toAPI(DocumentFilesExport)
    );
  },

  markAsDownloadedPdfExport(id: string): AxiosPromise {
    return instance.put<DocumentFilesExport>(`${URLS.documentFilesExport}/markAsDownloaded/${id}`);
  },

  createPdfExport(value: string, timezoneOffet: number): AxiosPromise {
    return instance.put(`${URLS.documentFilesExport}/create/${value}/${timezoneOffet}`);
  },
};
