import { GetterTree } from 'vuex';
import { AllocationTemplateState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationTemplateState, RootState> = {
  ['getAllocationTemplates']: (state) => state.allocationTemplates,
  ['getAllocationTemplatesIsLoading']: (state) => state.allocationTemplates?.isLoading,
  ['getAllocationTemplatesTotal']: (state) => state.allocationTemplates?.total,
  ['getAllocationTemplatesSearchParams']: (state) => state.allocationTemplates?.searchParams,
};
