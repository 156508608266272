export enum CONST {
  IsDeleted = 'Deleted',
  IsMerged = 'Merged',
  ParentFolderId = 'ParentFolderId',
  FolderId = 'FolderId',
  DocumentId = 'DocumentId',
  DocumentNumber = 'DocumentNumber',
  // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  OrganisationId = 'OrganisationId',
  getOrganisation = 'organization/getOrganization',
  AddressId = 'AddressId',

  //cache name
  cachePreviews = 'previews',

  //for how many first documents get UserFilePreview
  DocumentInfoInitialLoadCount = 20,
}

// for `BackendDocument` search via `getDocuments` + OData
export const documentChildEntities = [
  'Name',
  'Iban',
  'Kundennummer',
  'Steuernummer',
  'AdresseKundennummer',
  'AdresseKennzeichen',
  'AdresseSeriennummer',
];
export const odataStrings: any = {
  Notes: 'DocumentDetailComments/any(a:contains(tolower(a/Message)',
  Note: 'DocumentDetailComments/any(a:contains(tolower(a/Message)',
  //Text: '(contains(tolower(DocumentPagesText)',
  Text: '(contains((DocumentPagesText)',
  AddressLastName: '(contains(tolower(Address/Nachname)',
  AddressFirstName: '(contains(tolower(Address/Vorname)',
  InvoiceNumber: '(contains(tolower(DocumentGobdDatum/InvoiceNumber)',
  SupplierAccount: '(contains(tolower(DocumentGobdDatum/SupplierAccount)',
  DocumentProperty: 'DocumentPropertys/any(a:contains(tolower(a/Value)',
};
