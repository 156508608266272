import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import office, { Office, Value } from '../model/office';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.Office');
export interface BackendOffice {
  getOffice(id: string): AxiosPromise<Office>;
  getOffices: (searchParams: SearchParams, organizationId: string, folderId: string) => AxiosPromise<Value>;
  deleteOffice(id: string): AxiosPromise;
  updateOffice(Office: Office): AxiosPromise<any>;
}

export const defaultBackendOffice: BackendOffice = {
  getOffice(id: string): AxiosPromise<Office> {
    const url = `${URLS.officeOdata}/${id}`;
    return instance.get<Office>(url);
  },
  getOffices(searchParams: SearchParams, organizationId: string, folderId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false).eq(CONST.FolderId, folderId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.officeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOffices${url}`);
    return instance.get<Value>(url);
  },

  deleteOffice(id: string): AxiosPromise {
    logger.debug('deleteOffice');
    return instance.delete(`${URLS.office}/${id}`);
  },
  updateOffice(Office: Office): AxiosPromise<any> {
    logger.debug('updateOffice');
    return instance.put<Office>(`${URLS.office}/update`, office.toAPI(Office));
  },
};
