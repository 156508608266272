import { ActionTree } from 'vuex';
import { DocumentFileState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentFile } from '@/shared/backend/documentFile';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentFile, { Value, DocumentFile } from '@/shared/model/documentFile';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentFiles');
export const actions: ActionTree<DocumentFileState, RootState> = {
  getDocumentFiles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDocumentFilesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentFilesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocumentFile
      .getDocumentFiles(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentFiles', response.data);
        commit('setDocumentFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentFile({ commit, dispatch }, id: string) {
    commit('setDocumentFileIsLoading', true);
    return defaultBackendDocumentFile
      .getDocumentFile(id)
      .then((response: AxiosResponse<DocumentFile>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDocumentFileIsLoading', false);
      });
  },
  updateDocumentFile({ commit, dispatch }, file: DocumentFile) {
    return defaultBackendDocumentFile
      .updateDocumentFile(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFile;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentFile_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentFile({ commit, dispatch }, id: string) {
    return defaultBackendDocumentFile
      .deleteDocumentFile(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFile;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
