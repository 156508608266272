import userBillingInfo, { UserBillingInfo } from './../model/userBillingInfo';
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organization, { Organisation, Value } from '../model/organisation';
import folder, { Folder, Value as FolderValue } from '../model/folder';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';
import organisationUser, { OrganisationUser, Value as OrganisationUserValue } from '../model/organisationUser';
import JsObjectUtils from '../utils/jsObjectUtils';
import organisationProcessSettings, { OrganisationProcessSettings } from '../model/organisationProcessSettings';

const logger = new Logger('backend.organization');
export interface BackendOrganisation {
  updateFolderOpenType(payload: { organisationId: string; folderOpenType: number }): AxiosPromise<Organisation>;
  updateToMain(id: string, ubi: UserBillingInfo): AxiosPromise<string>;
  getOrganization(id: string): AxiosPromise<Organisation>;
  getOrganizationProcessSettings(): AxiosPromise<OrganisationProcessSettings>;
  getFoldersByUrl(url: string): AxiosPromise<Value>;
  getOrganizations: (searchParams: SearchParams, parentOrganizationId?: string) => AxiosPromise<Value>;
  deleteOrganization(id: string): AxiosPromise;
  deleteAndCleanAllTables(id: string): AxiosPromise;
  updateOrganization(document: Organisation): AxiosPromise<Organisation>;
  updateOrganizationProcessSettings(data: OrganisationProcessSettings): AxiosPromise<OrganisationProcessSettings>;
  resetOrganization(): AxiosPromise<Organisation>;
  getOrganizationFolders(
    organizationId: string,
    searchParams: SearchParams,
    parentFolderId?: string
  ): AxiosPromise<FolderValue>;
  getOrganisationsForOwner(searchParams: SearchParams, mainOrgId: string | undefined): AxiosPromise<Value>;
  getOrganizationFoldersByUrl(url: string): AxiosPromise<FolderValue>;
  getOrganisationUsers(organizationId: string, searchParams: SearchParams): AxiosPromise<OrganisationUserValue>;
  getOrganisationUsersAll(searchParams: SearchParams): AxiosPromise<OrganisationUserValue>;
  updateOrganisationUser(organizationUser: OrganisationUser): AxiosPromise<OrganisationUser>;
  deleteOrganisationUser(orgUserId: string): AxiosPromise<OrganisationUser>;

  updateOrganizationSettings(organisationId: string, name: string): AxiosPromise;
  export(organisationId: string): AxiosPromise;
  treatGobdEnable(organisationId: string): AxiosPromise;
  addDemoDays(organisationId: string, days: number): AxiosPromise;
  updateOrganizationForCustomerSettings(organisationId: string, name: string): AxiosPromise;
  updateUserSettings(userId: string, name: string): AxiosPromise;
}

export const defaultBackendOrganisation: BackendOrganisation = {
  getOrganization(id: string): AxiosPromise<Organisation> {
    const url = `${URLS.organisationOdata}/${id}`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<Organisation>(url);
  },
  getFoldersByUrl(url: string): AxiosPromise<Value> {
    return instance.get<Value>(DefaultBackendHelper.removeUrlAppendix(url));
  },

  getOrganizations(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      ['$expand=Owner,UserPackages'],
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase,
      searchParams.orClauseFieldsIdsGrateThan
    );
    logger.log(`getOrganizations${url}`);
    // url = '/odata/organisations';
    return instance.get<Value>(url);
  },

  getOrganizationProcessSettings(): AxiosPromise<OrganisationProcessSettings> {
    const url = `${URLS.organisationOdata}/GetProcessSettings`;
    return instance.get<OrganisationProcessSettings>(url);
  },

  deleteOrganization(id: string): AxiosPromise {
    logger.debug('deleteOrganisation');
    return instance.delete(`${URLS.organisation}/${id}`);
  },

  deleteAndCleanAllTables(id: string): AxiosPromise {
    logger.debug('deleteOrganisation');
    return instance.post(`${URLS.organisation}/DeleteAndCleanAllTables/${id}`);
  },

  updateToMain(orgId: string, ubi: UserBillingInfo): AxiosPromise<any> {
    logger.debug('updateRoleRights');
    return instance.post<string>(`${URLS.organisationOdata}(${orgId})/UpdateToMain`, {
      UserBillingInfo: userBillingInfo.toAPI(ubi),
    });
  },
  updateOrganization(item: Organisation): AxiosPromise<Organisation> {
    return instance.patch<Organisation>(`${URLS.organisationOdata}(${item.id})`, organization.toAPI(item));
  },

  updateOrganizationProcessSettings(data: OrganisationProcessSettings): AxiosPromise<OrganisationProcessSettings> {
    return instance.put<OrganisationProcessSettings>(
      `${URLS.organisationOdata}/UpdateProcessSettings`,
      organisationProcessSettings.toAPI(data)
    );
  },

  resetOrganization(): AxiosPromise<Organisation> {
    return instance.put<Organisation>(`${URLS.organisation}/reset`);
  },
  getOrganizationFolders(
    organizationId: string,
    searchParams: SearchParams,
    parentFolderId?: string
  ): AxiosPromise<FolderValue> {
    const odfb = ODataFilterBuilder('and');
    if (parentFolderId) odfb.eq(CONST.ParentFolderId, parentFolderId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationOdata}(${organizationId})/folders`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      parentFolderId ? odfb : undefined
    );
    logger.log(`getFolders${url}`);
    return instance.get<FolderValue>(url);
  },

  getOrganisationsForOwner(searchParams: SearchParams, mainOrgId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    if (mainOrgId === undefined) odfb.eq('owner/mainOrganisationId', 'id', false);
    else odfb.eq('owner/mainOrganisationId', mainOrgId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=Owner,UserPackages'],
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase

      //['$expand=Owner', '$filter=organisationId eq owner/mainOrganisationId']
    );
    return instance.get<Value>(url);
  },

  getOrganizationFoldersByUrl(url: string): AxiosPromise<FolderValue> {
    url = DefaultBackendHelper.removeUrlAppendix(url);
    return instance.get<FolderValue>(url);
  },
  getOrganisationUsers(organizationId: string, searchParams: SearchParams): AxiosPromise<OrganisationUserValue> {
    const odfb = new ODataFilterBuilder().ne('disabled', true).ne('deleted', true);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationOdata}(${organizationId})/OrganisationUsers`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getOrganisationUsers${url}`);
    return instance.get<any>(url);
  },
  getOrganisationUsersAll(searchParams: SearchParams): AxiosPromise<OrganisationUserValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationUserOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getOrganisationUsersAll${url}`);
    return instance.get<any>(url);
  },

  updateOrganisationUser(item: OrganisationUser): AxiosPromise<OrganisationUser> {
    logger.debug('updateRole');
    if (item.id) {
      const formData = organisationUser.toAPI(item);
      return instance.put<OrganisationUser>(`${URLS.organisationUserOdata}(${item.id})`, formData);
    } else {
      const formData = JsObjectUtils.getFormData(organisationUser.toAPI(item));
      return instance.post<OrganisationUser>(`${URLS.organisationUserOdata}`, formData);
    }
  },
  deleteOrganisationUser(id: string): AxiosPromise {
    logger.debug('deleteOrganisationUser');
    return instance.delete(`${URLS.organisationUserOdata}(${id})`);
  },

  updateOrganizationSettings(organisationId: string, name: string): AxiosPromise {
    return instance.put(`${URLS.organisation}/UpdateSettings/${organisationId}/${name}`);
  },

  export(organisationId: string): AxiosPromise {
    return instance.put(`${URLS.organisation}/Export/${organisationId}`);
  },

  treatGobdEnable(organisationId: string): AxiosPromise {
    return instance.put(`${URLS.organisation}/TreatGobdEnable/${organisationId}`);
  },

  addDemoDays(organisationId: string, days: number): AxiosPromise {
    return instance.put(`${URLS.organisation}/addDemoDays/${organisationId}/${days}`);
  },

  updateOrganizationForCustomerSettings(organisationId: string, name: string): AxiosPromise {
    return instance.put(`${URLS.organisation}/UpdateSettingsForCustomer/${organisationId}/${name}`);
  },

  updateUserSettings(userId: string, name: string): AxiosPromise {
    return instance.put(`${URLS.organisationUsers}/UpdateSettings/${userId}/${name}`);
  },
  updateFolderOpenType(payload: { organisationId: string; folderOpenType: number }): AxiosPromise<Organisation> {
    return instance.patch<Organisation>(`${URLS.organisationOdata}(${payload.organisationId})`, {
      folderOpenType: payload.folderOpenType,
    });
  },
};
