import { render, staticRenderFns } from "./menu-allocation-export-download.html?vue&type=template&id=ade2067e&scoped=true&external"
import script from "./menu-allocation-export-download.ts?vue&type=script&lang=ts&external"
export * from "./menu-allocation-export-download.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-allocation-export-download.scss?vue&type=style&index=0&id=ade2067e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade2067e",
  null
  
)

/* custom blocks */
import block0 from "./menu-allocation-export-download.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-allocation-export-download%2Fmenu-allocation-export-download.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
