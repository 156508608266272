import { MutationTree } from 'vuex';
import { ActionLogState } from './types';
import Vue from 'vue';
import actionLog, { ActionLog, Value } from '@/shared/model/actionLog';
import actionLogUser, { ActionLogUser, Value as ActionLogUserValue } from '@/shared/model/actionLogUser';

export const mutations: MutationTree<ActionLogState> = {
  setActionLogs(state, payload: Value) {
    state.actionLogs.items = payload.value.map((x) => actionLog.parse(x));
    state.actionLogs.total = payload['@odata.count'] || 0;
    state.actionLogs.isLoading = false;
  },
  setActionLogsTotal(state, payload: number) {
    state.actionLogs.total = payload;
  },
  setActionLogsIsLoading(state, payload: boolean) {
    state.actionLogs.isLoading = payload;
  },

  //(ED-892) separate mutations for QA(Questions and answers)[DE= Fragen & Antworten ]
  setActionLogsQa(state, payload: Value) {
    state.actionLogsQa.items = payload.value.map((x) => x); // (ED-932) return backend model with `data` field where almost all info located (NOT frontend model, which is too different)
    state.actionLogsQa.total = payload['@odata.count'] || 0;
    state.actionLogsQa.isLoading = false;
  },
  setActionLogsQaTotal(state, payload: number) {
    state.actionLogsQa.total = payload;
  },
  setActionLogsQaIsLoading(state, payload: boolean) {
    state.actionLogsQa.isLoading = payload;
  },
  setThreadMessages(state, payload: Value) {
    state.threadMessages.items = payload.value.map((x) => x);
    state.threadMessages.total = payload['@odata.count'] || 0;
    state.threadMessages.isLoading = false;
  },
  setThreadMessagesTotal(state, payload: number) {
    state.threadMessages.total = payload;
  },
  setThreadMessagesIsLoading(state, payload: boolean) {
    state.threadMessages.isLoading = payload;
  },
  //(ED-363) separate mutations for QA(Questions and answers) overview dialog to prevent conflict with separate `document` QA
  setActionLogsQaOverview(state, payload: Value) {
    state.actionLogsQaOverview.items = payload.value.map((x) => x); // (ED-932) return backend model with `data` field where almost all info located (NOT frontend model, which is too different)
    state.actionLogsQaOverview.total = payload['@odata.count'] || 0;
    state.actionLogsQaOverview.isLoading = false;
  },
  setActionLogsQaOverviewTotal(state, payload: number) {
    state.actionLogsQaOverview.total = payload;
  },
  setActionLogsQaOverviewIsLoading(state, payload: boolean) {
    state.actionLogsQaOverview.isLoading = payload;
  },
  setActionLogQaUsers(state, payload: ActionLogUserValue) {
    state.actionLogQaUsers.items = payload.value.map((x) => actionLogUser.parse(x));
    state.actionLogQaUsers.total = payload['@odata.count'] || 0;
    state.actionLogQaUsers.isLoading = false;
  },
  setActionLogQaUsersTotal(state, payload: number) {
    state.actionLogQaUsers.total = payload;
  },
  setActionLogQaUsersIsLoading(state, payload: boolean) {
    state.actionLogQaUsers.isLoading = payload;
  },
  markAsReadNotificationById(state, id: string) {
    const index = state.actionLogs?.items?.findIndex((x) => x.actionLogId == id);
    if (index > 0) state.actionLogQaUsers.items[index].read = 'true';
  },
  closeThread(state, id: string) {
    const index = state.actionLogs?.items?.findIndex((x) => x.actionLogId == id);
    if (index > 0) state.actionLogs.items[index].closed = true;
  },
};
