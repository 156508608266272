import { render, staticRenderFns } from "./documents-restore-dialog.html?vue&type=template&id=45913290&scoped=true&external"
import script from "./documents-restore-dialog.ts?vue&type=script&lang=ts&external"
export * from "./documents-restore-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./documents-restore-dialog.scss?vue&type=style&index=0&id=45913290&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45913290",
  null
  
)

/* custom blocks */
import block0 from "./documents-restore-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-list%2Fdocuments-restore-dialog%2Fdocuments-restore-dialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})
