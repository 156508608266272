import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Route } from 'vue-router';
import AddressEditDialog from '@/views/addresses/address-edit-dialog/address-edit-dialog.vue';

import { namespace } from 'vuex-class';
import address, { Address } from '@/shared/model/address';

const logger = new Logger('addresses');
const addressModule = namespace('address');
const authModule = namespace('auth');
const documentModule = namespace('document');

@Component({
  components: { AddressEditDialog },
})
export default class MenuDocumentAccountAddresses extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: '' })
  private search!: string;
  @Watch('search', { deep: true })
  onUpdateSearch(nwal: any, owal: any) {
    this.getAddresses.searchParams.filter = this.search;
  }

  @PropSync('value', { default: '' })
  private model!: any;

  @authModule.Getter('EditAddressesAllowed')
  private EditAddressesAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;

  @addressModule.Getter('getAddresses')
  private getAddresses?: any;
  @addressModule.Action('getAddresses')
  private actionGetAddresses?: any;
  @addressModule.Getter('getAddressesSearchParams')
  private getAddressesSearchParams?: any;
  @addressModule.Action('updateAddress')
  private actionUpdateAddress?: any;
  @Watch('getAddresses.searchParams', { deep: true })
  onUpdateSearchParams(nwal: any, owal: any) {
    this.actionGetAddresses({ searchParams: this.getAddresses.searchParams });
  }

  get addressesItems() {
    return this.getAddresses.items;
  }

  private isSaveBtnLoading = false;

  private itemsPerPageOptions: number[] = [50, 100, 250, 500];

  private addresses: any = {
    searchParams: {},
  };

  clickClose() {
    this.getAddresses.searchParams.filter = this.search;
    this.$emit('click:close');
  }

  additionalFields: any[] = [];

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('customer_number'), value: 'konto' },
      { text: this.$t('serial_number'), value: 'seriennummer' },
      { text: this.$t('last_name'), value: 'nachname' },
      { text: this.$t('first_name'), value: 'vorname' },
      { text: this.$t('mark'), value: 'kennzeichen' },
    ];
    if (this.EditAddressesAllowed || this.isOwner)
      headers.push({
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      });
    return headers;
  }

  mounted() {
    this.actionGetAddresses().then(() => {
      this.getAddresses.searchParams.filter = this.search;
    });
  }

  addressEditDialog = {
    show: false,
    model: { ...address.parse({}) },
  };

  async addressEditDialogOnClose(address: Address) {
    this.addressEditDialog.show = false;
  }

  async addressEditDialogOnUpdate(address: Address) {
    this.isSaveBtnLoading = true;
    this.actionUpdateAddress(address)
      .then((result: any) => {})
      .catch((err: any) => {})
      .finally(() => {
        this.addressEditDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetAddresses();
      });
  }

  onClickAddressAdd() {
    this.addressEditDialog.model = { ...address.parse({}) };
    this.addressEditDialog.show = true;
  }
  onClickAddressEdit(address: Address) {
    this.addressEditDialog.model = { ...address };
    this.addressEditDialog.show = true;
  }

  seriennummerClick(item: any) {
    this.$emit('click:select', item);
  }
}
