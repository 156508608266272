import { ActionTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../../types';
import { defaultBackendUser } from '@/shared/backend/user';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import user, { Value, User } from '@/shared/model/user';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import userRight, { UserRight, Value as UserRightValue } from '@/shared/model/userRight';
import { ConfirmEmailDto, EmailData, PasswordData } from '@/shared/model/smallPayloadModels/emailData';

const logger = new Logger('actions.users');
export const actions: ActionTree<UserState, RootState> = {
  getUsers({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setUsersIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getUsersSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendUser
      .getUsers(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setUsers', response.data);
        commit('setUsersIsLoading', false);
        return response.data.value.map((x) => user.parse(x));
      })
      .catch((e: any) => {
        commit('setUsersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getQaUsers({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData?: any }) {
    commit('setQaUsersIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getUsersSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendUser
      .getQaUsers(searchParams, organisationId, payload?.searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setQaUsers', response.data);
        commit('setQaUsersIsLoading', false);
        return response.data.value.map((x) => user.parse(x));
      })
      .catch((e: any) => {
        commit('setQaUsersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getUserRights({ commit, dispatch, getters }, payload: { userId: string; searchParams?: any }) {
    commit('setUserRightsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getUserRightsSearchParams;
    return defaultBackendUser
      .getUserRights(payload.userId, searchParams)
      .then((response: AxiosResponse<UserRightValue>) => {
        commit('setUserRights', response.data);
        commit('setUserRightsIsLoading', false);
        return response.data.value.map((x) => userRight.parse(x));
      })
      .catch((e: any) => {
        commit('setUserRightsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getUser({ commit, dispatch }, id: string) {
    return defaultBackendUser
      .getUser(id)
      .then((response: AxiosResponse<User>) => {
        return user.parse(response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateUser({ commit, dispatch }, file: Partial<User>) {
    return defaultBackendUser
      .updateUser(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        let messageId = 'error';
        //show first error from responce if exits translate
        if (Array.isArray(e.response.data.errors)) {
          if (i18n.te('errors.' + e.response.data.errors[0].code))
            messageId = 'errors.' + e.response.data.errors[0].code;
        }
        module_utils.error(i18n.tc(messageId), commit, e, logger);
        throw e;
      });
  },
  registerUser({ commit, dispatch }, file: Partial<User>) {
    commit('setRegisterUserInProcess', true);
    return defaultBackendUser
      .registerUser(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        if (e.response.status !== 409) {
          // 409 is Conflict error
          module_utils.error('error', commit, e, logger);
        }
        throw e;
      })
      .finally(() => {
        commit('setRegisterUserInProcess', false);
      });
  },
  updateInfoAndPassword(
    { commit, dispatch },
    payload: {
      id: string;
      info: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        kurzform: string;
        password: string;
        pdfZoom: string;
        phoneNumber: string;
      };
    }
  ) {
    return defaultBackendUser
      .updateInfoAndPassword(payload.id, payload.info)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  lockAccess(
    { commit, dispatch },
    payload: {
      id: string;
    }
  ) {
    return defaultBackendUser
      .lockAccess(payload.id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  unlockAccess(
    { commit, dispatch },
    payload: {
      id: string;
    }
  ) {
    return defaultBackendUser
      .unlockAccess(payload.id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateUserRole({ commit, dispatch }, userRole: { userId: string; userRoleId: string; roleId: string }) {
    return defaultBackendUser
      .updateUserRole(userRole.userId, userRole.userRoleId, userRole.roleId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateUserRights({ commit, dispatch }, item: { userId: string; rights: UserRight[] }) {
    commit('setUpdateUserRightsIsLoading', true);
    return defaultBackendUser
      .updateUserRights(item.userId, item.rights)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.user_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUpdateUserRightsIsLoading', false);
      });
  },

  moveToRecycle({ commit, dispatch }, id: string) {
    // commit('setInProgressUpdateUser', true);
    return defaultBackendUser
      .moveToRecycle(id)
      .then((response) => {
        module_utils.ok(i18n.t('success.user_update'), commit, logger);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        // commit('setInProgressUpdateUser', false);
      });
  },
  restoreFromRecycle({ commit, dispatch }, id: string) {
    // commit('setInProgressUpdateUser', true);
    return defaultBackendUser
      .restoreFromRecycle(id)
      .then((response) => {
        module_utils.ok(i18n.t('success.user_update'), commit, logger);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        // commit('setInProgressUpdateUser', false);
      });
  },
  deleteUser({ commit, dispatch }, id: string) {
    return defaultBackendUser
      .deleteUser(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: User;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  sendEmail({ commit, dispatch }, emailData: EmailData) {
    return defaultBackendUser
      .sendEmail(emailData)
      .then((response: any) => {
        return response.data;
      })
      .catch((e: any) => {
        if (e.response.status !== 404) {
          module_utils.error('error', commit, e, logger);
        }
        throw e;
      });
  },
  setNewPasswordViaEmail({ commit, dispatch }, passwordData: PasswordData) {
    return defaultBackendUser
      .setNewPasswordViaEmail(passwordData)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.user_password_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  confirmEmail({ commit, dispatch }, data: ConfirmEmailDto) {
    return defaultBackendUser
      .confirmEmail(data)
      .then((response: any) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  isUserEmailExist({ commit }, email: string) {
    commit('setIsUserEmailExistInProgress', true);
    return defaultBackendUser
      .IsUserEmailExist(email)
      .then((result: any) => {
        return result.data;
      })
      .catch((e: any) => {
        logger.error(e);
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUserEmailExistInProgress', false);
      });
  },
};
