import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationAllocationExportProperty, {
  OrganisationAllocationExportProperty,
  Value,
} from '../model/organisationAllocationExportProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationAllocationExportProperty');
export interface BackendOrganisationAllocationExportProperty {
  getOrganisationAllocationExportProperty(id: string): AxiosPromise<OrganisationAllocationExportProperty>;
  getOrganisationAllocationExportProperties: (
    searchParams: SearchParams,
    organisationId: string
  ) => AxiosPromise<Value>;
  updateOrganisationAllocationExportProperty(
    OrganisationAllocationExportProperty: OrganisationAllocationExportProperty
  ): AxiosPromise<any>;
  moveOrganisationAllocationExportProperty(payload: {
    element: OrganisationAllocationExportProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any>;
}

export const defaultBackendOrganisationAllocationExportProperty: BackendOrganisationAllocationExportProperty = {
  getOrganisationAllocationExportProperty(id: string): AxiosPromise<OrganisationAllocationExportProperty> {
    const url = `${URLS.organisationAllocationExportPropertyOdata}/${id}`;
    return instance.get<OrganisationAllocationExportProperty>(url);
  },
  getOrganisationAllocationExportProperties(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationAllocationExportPropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOrganisationAllocationExportPropertys${url}`);
    return instance.get<Value>(url);
  },

  updateOrganisationAllocationExportProperty(
    OrganisationAllocationExportProperty: OrganisationAllocationExportProperty
  ): AxiosPromise<any> {
    logger.debug('updateOrganisationAllocationExportProperty');
    return instance.put<OrganisationAllocationExportProperty>(
      `${URLS.organisationAllocationExportProperty}/update`,
      organisationAllocationExportProperty.toAPI(OrganisationAllocationExportProperty)
    );
  },
  moveOrganisationAllocationExportProperty(payload: {
    element: OrganisationAllocationExportProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any> {
    logger.debug('moveOrganisationAllocationExportProperty');
    const item = organisationAllocationExportProperty.toAPI(payload.element);
    item.NewIndex = payload.newIndex;
    item.OldIndex = payload.oldIndex;
    return instance.put<OrganisationAllocationExportProperty>(
      `${URLS.organisationAllocationExportProperty}/move`,
      item
    );
  },
};
