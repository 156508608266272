import RouterUtils from '@/shared/utils/RouterUtils';
import { Logger } from 'fsts';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const name = 'simulation';
const logger = new Logger(name);
const authModule = namespace('auth');
@Component({ name: name })
export default class SimulationView extends Vue {
  @authModule.Getter('isImpersonate')
  private impersonate: any;
  @authModule.Action('login')
  private login: any;
  endImpersonate() {
    this.login({
      organizationId: RouterUtils.emptyGuid,
      impersonateId: RouterUtils.emptyGuid,
    });
  }
}
