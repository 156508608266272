import { GetterTree } from 'vuex';
import { DocumentFileState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentFileState, RootState> = {
  ['getDocumentFiles']: (state) => state.documentFiles,
  ['getDocumentFilesIsLoading']: (state) => state.documentFiles?.isLoading,
  ['getDocumentFilesTotal']: (state) => state.documentFiles?.total,
  ['getDocumentFilesSearchParams']: (state) => state.documentFiles?.searchParams,
  ['getDocumentFileIsLoading']: (state) => state.documentFileIsLoading,
};
