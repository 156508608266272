export interface Credetials {
  id?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  userName?: string;
  authorities: string;
  rememberMe?: boolean;
  emailConfirmed: boolean;
  inactivityMinutes: number;
  timeSheetFormat: number;
}

function parse(data?: Partial<Credetials>): Credetials {
  return {
    id: data?.id || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    password: data?.password || '',
    userName: data?.userName || '',
    authorities: data?.authorities || '',
    rememberMe: data?.rememberMe || false,
    emailConfirmed: data?.emailConfirmed || false,
    inactivityMinutes: data?.inactivityMinutes || 0,
    timeSheetFormat: data?.timeSheetFormat || 0,
  };
}

// init api object
function toAPI(data: Partial<Credetials>): any {
  return {
    Id: data!.id || '',
    FirstName: data?.firstName || '',
    LastName: data?.lastName || '',
    Password: data?.password || '',
    UserName: data?.userName || '',
    Authorities: data?.authorities || '',
    RememberMe: data?.rememberMe || false,
    EmailConfirmed: data?.emailConfirmed || false,
    InactivityMinutes: data?.inactivityMinutes || 0,
    TimeSheetFormat: data?.timeSheetFormat || 0,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
