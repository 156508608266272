import { ActionTree } from 'vuex';
import { CostCentreState } from './types';
import { RootState } from '../../types';
import { defaultBackendCostCentre } from '@/shared/backend/costCentre';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import costCentre, { Value, CostCentre } from '@/shared/model/costCentre';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.costCentres');
export const actions: ActionTree<CostCentreState, RootState> = {
  getCostCentres({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setCostCentresIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getCostCentresSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostCentre
      .getCostCentres(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setCostCentres', response.data);
        commit('setCostCentresIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCostCentresIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostCentre({ commit, dispatch }, id: string) {
    return defaultBackendCostCentre
      .getCostCentre(id)
      .then((response: AxiosResponse<CostCentre>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCostCentre({ commit, dispatch }, file: CostCentre) {
    return defaultBackendCostCentre
      .updateCostCentre(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostCentre;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.costCentre_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadCostCentre(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    commit('setIsImporting', true);
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostCentre
      .uploadCostCentre(fileInfo)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.cost_centre_uploaded`), commit);
        return response.data;
      })
      .catch((e: any) => {
        //show first error from responce if exits translate
        let error = [{ code: 'error', description: i18n.tc('error') }];
        if (Array.isArray(e.response.data.errors?.$values)) error = e.response.data.errors?.$values;
        if (Array.isArray(e.response.data.errors)) error = e.response.data.errors;
        if (i18n.te('errors.' + error[0].code)) error[0].description = i18n.tc('errors.' + error[0].code);

        module_utils.error(error[0].description, commit, e, logger);
        throw e.response.data;
      })
      .finally(() => {
        commit('setIsImporting', false);
      });
  },
  deleteCostCentre({ commit, dispatch }, id: string) {
    return defaultBackendCostCentre
      .deleteCostCentre(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostCentre;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
