import { GetterTree } from 'vuex';
import { AutoGobdCheckTermState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AutoGobdCheckTermState, RootState> = {
  ['getAutoGobdCheckTerms']: (state) => state.autoGobdCheckTerms,
  ['getAutoGobdCheckTermsIsLoading']: (state) => state.autoGobdCheckTerms?.isLoading,
  ['getAutoGobdCheckTermsTotal']: (state) => state.autoGobdCheckTerms?.total,
  ['getAutoGobdCheckTermsSearchParams']: (state) => state.autoGobdCheckTerms?.searchParams,
  ['isRunAutoGobdCheckTerm']: (state) => (id: string) => state.isRunAutoGobdCheckTerms.includes(id),
};
