import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import file, { File, Value } from '../model/file';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.File');
export interface BackendFile {
  getFile(id: string): AxiosPromise<File>;
  getFiles: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  getFilesForDocumentId: (documentId: string) => AxiosPromise<Value>;
  getFilesForUnmergeForDocumentId: (documentId: string) => AxiosPromise<Value>;
  deleteFile(id: string): AxiosPromise;
  updateFile(File: File): AxiosPromise<any>;
}

export const defaultBackendFile: BackendFile = {
  getFile(id: string): AxiosPromise<File> {
    const url = `${URLS.fileOdata}/${id}`;
    return instance.get<File>(url);
  },
  getFiles(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.fileOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getFiles${url}`);
    return instance.get<Value>(url);
  },

  getFilesForDocumentId(documentId: string): AxiosPromise<Value> {
    return instance.get<Value>(
      //`${URLS.fileOdata}/GetAllForDocumentId(documentId='${documentId}')?$orderby=DocumentNumber+asc,SortOrder+asc`
      `${URLS.fileOdata}/GetAllForDocumentId(documentId='${documentId}')?$orderby=DocumentNumber+asc`
    );
  },

  getFilesForUnmergeForDocumentId(documentId: string): AxiosPromise<Value> {
    return instance.get<Value>(
      `${URLS.fileOdata}/GetFilesForUnmergeForDocumentId(documentId='${documentId}')?$orderby=DocumentNumber+asc,SortOrder+asc`
    );
  },

  deleteFile(id: string): AxiosPromise {
    logger.debug('deleteFile');
    return instance.delete(`${URLS.file}/${id}`);
  },
  updateFile(File: File): AxiosPromise<any> {
    logger.debug('updateFile');
    return instance.put<File>(`${URLS.file}/update`, file.toAPI(File));
  },
};
