import { ActionTree } from 'vuex';
import { CommentState } from './types';
import { RootState } from '../../types';
import { defaultBackendComment } from '@/shared/backend/comment';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import comment, { Value, Comment } from '@/shared/model/comment';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.comments');
export const actions: ActionTree<CommentState, RootState> = {
  getComments({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; documentId: string }) {
    commit('setCommentsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getCommentsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendComment
      .getComments(searchParams, organisationId, payload!.documentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setComments', response.data);
        commit('setCommentsIsLoading', false);
        commit(
          'document/setDocumentsComments',
          {
            id: payload!.documentId,
            documentDetailComments: response.data.value.map((x) => comment.parse(x)),
            total: response.data['@odata.count'] || 0,
          },
          { root: true }
        );

        return response.data;
      })
      .catch((e: any) => {
        commit('setCommentsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getComment({ commit, dispatch }, id: string) {
    return defaultBackendComment
      .getComment(id)
      .then((response: AxiosResponse<Comment>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateComment({ commit, dispatch, rootGetters }, file: Comment) {
    const organisationId = rootGetters[CONST.getOrganisation].id;
    file.organisationId = organisationId;
    return defaultBackendComment
      .updateComment(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Comment;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.comment_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteComment({ commit, dispatch }, id: string) {
    return defaultBackendComment
      .deleteComment(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Comment;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
