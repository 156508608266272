import { ActionTree } from 'vuex';
import { UserBillingInfoState } from './types';
import { RootState } from '../../types';
import { defaultBackendUserBillingInfo } from '@/shared/backend/userBillingInfo';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import userBillingInfo, { Value, UserBillingInfo } from '@/shared/model/userBillingInfo';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.userBillingInfos');
export const actions: ActionTree<UserBillingInfoState, RootState> = {
  getUserBillingInfo({ commit, dispatch }) {
    commit('setIsloading', true);
    return defaultBackendUserBillingInfo
      .getUserBillingInfo()
      .then((response: AxiosResponse<UserBillingInfo>) => {
        const data = userBillingInfo.parse(response.data);
        commit('setUserBillingInfos', data);
        return data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsloading', false);
      });
  },
  updateUserBillingInfo({ commit, dispatch }, file: UserBillingInfo) {
    commit('setInProgressUpdateUserBillingInfo', true);
    return defaultBackendUserBillingInfo
      .updateUserBillingInfo(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: UserBillingInfo;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.user_billing_info_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateUserBillingInfo', false);
      });
  },
};
