import { render, staticRenderFns } from "./activities.html?vue&type=template&id=1b4f5914&scoped=true&external"
import script from "./activities.ts?vue&type=script&lang=ts&external"
export * from "./activities.ts?vue&type=script&lang=ts&external"
import style0 from "./activities.scss?vue&type=style&index=0&id=1b4f5914&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4f5914",
  null
  
)

/* custom blocks */
import block0 from "./activities.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Factivities%2Factivities.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataIterator,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer,VSubheader,VTextField})
