import TimeSpanUtils from '../utils/TimeSpanUtil';
import roleLoginTime, { RoleLoginTime } from './roleLoginTime';
import roleRight, { RoleRight } from './roleRight';

export interface Role {
  id: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  name: string;
  roleId: string;

  created_At: string;
  updated_At: string;
  //old
  roleIdOld: string;

  members?: string[];

  roleRights: RoleRight[];
  roleLoginTimes: RoleLoginTime[];
}

export interface Value {
  value: Role[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<Role>): any {
  const x: any = {
    'Id@odata.type': '#Guid',
    Id: data?.id || undefined,
    // OrganisationId: data?.organisationId || undefined,
    Name: data?.name || undefined,
    // RoleId: data?.roleId || undefined,
    // RoleIdOld: data?.roleIdOld || undefined,

    CreatedAt: data?.created_At || undefined,
    UpdatedAt: data?.updated_At || undefined,

    // IsSunAllowed: data?.isSunAllowed || false,
    // SunFromTime: data?.sunFromTime || '',
    // SunToTime: data?.sunToTime || '',
    // IsMonAllowed: data?.isMonAllowed || false,
    // MonFromTime: data?.monFromTime || '',
    // monToTime: data?.monToTime || '',
    // IsTueAllowed: data?.isTueAllowed || false,
    // TueFromTime: data?.tueFromTime || '',
    // TueToTime: data?.tueToTime || '',
    // IsWedAllowed: data?.isWedAllowed || false,
    // WedFromTime: data?.wedFromTime || '',
    // WedToTime: data?.wedToTime || '',
    // IsThuAllowed: data?.isThuAllowed || false,
    // ThuFromTime: data?.thuFromTime || '',
    // ThuToTime: data?.thuToTime || '',
    // IsFriAllowed: data?.isFriAllowed || false,
    // FriFromTime: data?.friFromTime || '',
    // FriToTime: data?.friToTime || '',
    // IsSatAllowed: data?.isSatAllowed || false,
    // SatFromTime: data?.satFromTime || '',
    // SatToTime: data?.satToTime || '',
  };
  if (data?.roleId) {
    x['RoleId@odata.type'] = '#Guid';
    x.RoleId = data?.roleId;
  }
  if (data?.organisationId) {
    x['OrganizationId@odata.type'] = '#Guid';
    x.OrganizationId = data?.organisationId;
  }
  return x;
}
function parse(data?: Partial<Role>): Role {
  return {
    id: data!.id || '',
    organisationId: (data as any).organizationId || '',
    name: data?.name || '',
    roleId: data?.roleId || '',
    roleIdOld: data?.roleIdOld || '',

    created_At: data?.created_At || '',
    updated_At: data?.updated_At || '',

    roleRights: data?.roleRights?.map((x) => roleRight.parse(x)) || [],
    roleLoginTimes: convertToFullWeek(data?.roleLoginTimes, (data as any).organizationId, data?.id),
  };
  function convertToFullWeek(
    roleLoginTimes?: RoleLoginTime[],
    organisationId?: string,
    roleId?: string
  ): RoleLoginTime[] {
    const result: RoleLoginTime[] = [];
    //TODO implement english version of the order
    const weekDays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    weekDays.forEach((weekDay) => {
      let dayRole: any = roleLoginTimes?.find((x) => x.weekDay == weekDay);
      if (dayRole === undefined)
        dayRole = {
          allowed: true,
          organisationId: organisationId,
          roleId: roleId,
          weekDay: weekDay,
          fromTime: TimeSpanUtils.toAPIString('00:00:00'),
          toTime: TimeSpanUtils.toAPIString('23:59:59'),
        };
      result.push(roleLoginTime.parse(dayRole));
    });
    return result;
  }
}
export default {
  parse,
  toAPI: toAPI,
};
