import { MutationTree } from 'vuex';
import { AllocationTaxSettingState } from './types';
import Vue from 'vue';
import allocationTaxSetting, { AllocationTaxSetting, Value } from '@/shared/model/allocationTaxSetting';

export const mutations: MutationTree<AllocationTaxSettingState> = {
  setAllocationTaxSettings(state, payload: Value) {
    state.allocationTaxSettings.items = payload.value.map((x) => allocationTaxSetting.parse(x));
    state.allocationTaxSettings.total = payload['@odata.count'] || 0;
    state.allocationTaxSettings.isLoading = false;
  },
  setAllocationTaxSettingsTotal(state, payload: number) {
    state.allocationTaxSettings.total = payload;
  },
  setAllocationTaxSettingsIsLoading(state, payload: boolean) {
    state.allocationTaxSettings.isLoading = payload;
  },
  setAllocationTaxSettingsAllForCombo(state, payload: Value) {
    state.allocationTaxSettingsAllForCombo.items = payload.value?.map((x) => allocationTaxSetting.parse(x));
    state.allocationTaxSettingsAllForCombo.total = payload['@odata.count'] || 0;
    state.allocationTaxSettingsAllForCombo.isLoading = false;
  },
  setAllocationTaxSettingsAllForComboTotal(state, payload: number) {
    state.allocationTaxSettingsAllForCombo.total = payload;
  },
  setAllocationTaxSettingsAllForComboIsLoading(state, payload: boolean) {
    state.allocationTaxSettingsAllForCombo.isLoading = payload;
  },
};
