import { allocation } from './../../../../shared/store/modules/allocation/index';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { Document } from '@/shared/model/document';
import { Organisation } from '@/shared/model/organisation';

const logger = new Logger('document-breadcrumb');
const authModule = namespace('auth');
const documentModule = namespace('document');
const folderModule = namespace('folder');
const organizationModule = namespace('organization');

@Component({ name: 'document-breadcrumb', components: {} })
export default class DocumentBreadcrumb extends Vue {
  @authModule.Getter('getIsLeftDrawerShown') private getterIsLeftDrawerShown: any;
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;
  @documentModule.Getter('getDocument')
  private document!: Document;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  get navbarTitle() {
    return this.getHeaderNavData.translateId;
  }

  get drawerLeft() {
    return this.getterIsLeftDrawerShown;
  }
  get accountExportDate() {
    return this.$route.params['accountExportDate'];
  }
  get documentNumber() {
    return this.document?.documentNumber ?? 0;
  }
  get isPreviewRoute() {
    return (
      this.$route.name == 'document-preview' ||
      this.$route.name == 'qa-task' ||
      this.$route.name == 'qa-question' ||
      this.$route.name == 'qa-circular'
    );
  }
  get isAllocationDetails() {
    return this.$route.name == 'allocation-details';
  }
  get isAccountExportRoute() {
    return !!this.$route.params['accountExportDate'];
  }
  get exportId() {
    return this.$route.params['exportId'];
  }
  private navigate() {
    if (this.isPreviewRoute && this.isAccountExportRoute) {
      this.$router.push({
        name: 'allocation-details',
        params: { accountExportDate: this.accountExportDate, exportId: this.exportId },
      });
      return;
    }
    if (this.isPreviewRoute) {
      this.getHeaderNavData.to = `/dashboard/organization/${this.organization.id}`;
      if (this.document?.id) this.getHeaderNavData.to += `/${this.document?.folderId}/${this.document?.id}`;
    }

    if (this.isAccountExportRoute || this.getHeaderNavData.to == '/export') {
      this.$router.push(this.getHeaderNavData.to + '/allocation').catch(() => {});
      return;
    }
    if (this.getHeaderNavData.to.indexOf('onlyCurrentFolderDocs') == -1)
      this.$router.push(this.getHeaderNavData.to + '?onlyCurrentFolderDocs=false').catch(() => {});
    this.$router.push(this.getHeaderNavData.to).catch(() => {});
  }
  get folderNam() {
    if (this.$route.fullPath.indexOf('export/allocation') != -1) return this.$t('export');
    if (this.$route.fullPath.indexOf('export/pdf') != -1) return this.$t('export');
    if (this.$route.fullPath.indexOf('export/process') != -1) return this.$t('export');
    switch (this.navbarTitle) {
      case 'all_documents':
      case 'addresses':
      case 'export':
      case 'processes':
      case 'trash':
      case 'settings':
      case 'my_questions_answers':
      case 'questions_answers':
        return this.$t(this.navbarTitle);

      default:
        return this.navbarTitle == 'all_documents' ? this.$t(this.navbarTitle) : this.navbarTitle;
    }
  }
  get folderRoute() {
    if (this.$route.fullPath.indexOf('export/allocation') != -1) return '/export/allocation';
    if (this.$route.fullPath.indexOf('allocation-details') != -1) return '/export/allocation';
    if (this.$route.fullPath.indexOf('export/pdf') != -1) return '/export/pdf';
    if (this.$route.fullPath.indexOf('export/process') != -1) return '/export/pdf';
    if (this.$route.fullPath.indexOf('addresses') != -1) return '/addresses';
    if (this.$route.fullPath.indexOf('trash') != -1) return '/trash';
    if (this.$route.fullPath.indexOf('processes') != -1) return '/processes';
    if (this.$route.fullPath.indexOf('settings') != -1) return '/settings';
    if (this.getHeaderNavData.to) return this.getHeaderNavData.to;
    return '/dashboard';
  }
  get exportDate() {
    return this.$route.params['accountExportDate'];
  }
  get items() {
    const items = [
      {
        text: `${this.folderNam}`,
        disabled: false,
        href: this.folderRoute,
        font: 'text-subtitle-1',
      },
    ];
    if (this.isAllocationDetails || (this.isPreviewRoute && this.isAccountExportRoute))
      items.push({
        text: `${this.exportDate}`,
        disabled: false,
        href: `/export/allocation`,
        font: 'text-h6',
      });
    if (this.isPreviewRoute)
      items.push({
        text: `${this.documentNumber}`,
        disabled: false,
        href: `/dashboard/organization/${this.organization.id}/${this.document.folderId}/${this.document?.id}?onlyCurrentFolderDocs=false`,
        font: 'text-h6',
      });
    items.push({
      text: '',
      disabled: true,
      href: 'breadcrumbs_link_2',
      font: 'text-subtitle-1',
    });
    return items;
  }
}
