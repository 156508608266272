import { MutationTree } from 'vuex';
import { AllocationFrameState } from './types';
import Vue from 'vue';
import allocationFrame, { AllocationFrame, Value } from '@/shared/model/allocationFrame';

export const mutations: MutationTree<AllocationFrameState> = {
  setAllocationFrames(state, payload: Value) {
    state.allocationFrames.items = payload.value.map((x) => allocationFrame.parse(x));
    state.allocationFrames.total = payload['@odata.count'] || 0;
    state.allocationFrames.isLoading = false;
  },
  setAllocationFramesTotal(state, payload: number) {
    state.allocationFrames.total = payload;
  },
  setAllocationFramesIsLoading(state, payload: boolean) {
    state.allocationFrames.isLoading = payload;
  },
};
