import { ActionTree } from 'vuex';
import { AllocationOfficesImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationOfficesImportSetting } from '@/shared/backend/allocationOfficesImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationOfficesImportSetting, {
  Value,
  AllocationOfficesImportSetting,
} from '@/shared/model/allocationOfficesImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationOfficesImportSettings');
export const actions: ActionTree<AllocationOfficesImportSettingState, RootState> = {
  getAllocationOfficesImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationOfficesImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationOfficesImportSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationOfficesImportSetting
      .getAllocationOfficesImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationOfficesImportSettings', response.data);
        commit('setAllocationOfficesImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationOfficesImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationOfficesImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setAllocationOfficesImportSettingsIsLoading', true);
    const searchParams = getters.getAllocationOfficesImportSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationOfficesImportSetting
      .getAllocationOfficesImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationOfficesImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllocationOfficesImportSettingsIsLoading', false);
      });
  },
  getAllocationOfficesImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendAllocationOfficesImportSetting
      .getAllocationOfficesImportSetting(id)
      .then((response: AxiosResponse<AllocationOfficesImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationOfficesImportSetting({ commit, dispatch, rootGetters }, file: AllocationOfficesImportSetting) {
    commit('setInProgressUpdateAllocationOfficesImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationOfficesImportSetting
      .updateAllocationOfficesImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationOfficesImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationOfficesImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateAllocationOfficesImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getAllocationOfficesImportSetting.clearBeforeImport = payload.value;
    dispatch('updateAllocationOfficesImportSetting', getters.getAllocationOfficesImportSetting)
      .then((result: any) => {
        getters.getAllocationOfficesImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getAllocationOfficesImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteAllocationOfficesImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationOfficesImportSetting
      .deleteAllocationOfficesImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationOfficesImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
