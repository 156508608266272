import { FolderState } from './types';

export const state = (): FolderState => initialState();

export const initialState = (): FolderState => ({
  folder: undefined,
  foldersAll: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: -1,
        sortBy: ['isBuiltIn', 'name'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
    treeView: [],
    treeViewWithoutHidden: [],
    treeViewWrite: [],
    treeViewFlat: [],
  },

  folders: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isBuiltIn', 'fullPath'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  uploadDialogFolders: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['isBuiltIn', 'fullPath'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  menuFolders: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: -1,
        sortBy: ['isBuiltIn', 'name'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
      orClauseFieldsIdsConvertToString: [],
      orClauseFieldsIdsIgnoreCase: ['name'],
    },
    treeView: [],
    treeViewWithoutHidden: [],
    treeViewWithoutHiddenFlat: [],
    treeViewUploadFlat: [],
    treeViewWrite: [],
    treeViewWriteFlat: [],
    treeViewFlat: [],
  },
  inProgressUpdateFolder: false,
  inProgressUpdateFoldersRoleRights: 0,
});
