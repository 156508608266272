import allocationTaxGroupsImportSetting from '@/shared/model/allocationTaxGroupsImportSetting';
import { AllocationTaxGroupsImportSettingState } from './types';

export const state = (): AllocationTaxGroupsImportSettingState => initialState();

export const initialState = (): AllocationTaxGroupsImportSettingState => ({
  allocationTaxGroupsImportSettings: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['CreatedAt'],
    },
  },
  allocationTaxGroupsImportSetting: allocationTaxGroupsImportSetting.parse({}),
  inProgressUpdateAllocationTaxGroupsImportSetting: false,
});
