import { ActionTree } from 'vuex';
import { GobdDataState } from './types';
import { RootState } from '../../types';
import { defaultBackendGobdData } from '@/shared/backend/gobdData';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gobdData, { Value, GobdData } from '@/shared/model/gobdData';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.GobdDatas');
export const actions: ActionTree<GobdDataState, RootState> = {
  getGobdDatas({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; folderId?: string }) {
    commit('setGobdDatasIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getGobdDatasSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendGobdData
      .getGobdDatas(searchParams, organizationId, payload?.folderId || '')
      .then((response: AxiosResponse<Value>) => {
        commit('setGobdDatas', response.data);
        commit('setGobdDatasIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGobdDatasIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGobdData({ commit, dispatch }, id: string) {
    return defaultBackendGobdData
      .getGobdData(id)
      .then((response: AxiosResponse<GobdData>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  detectGobdData({ commit, dispatch }, id: string) {
    return defaultBackendGobdData
      .detectGobdData(id)
      .then((response: AxiosResponse<GobdData>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGobdData({ commit, dispatch, rootGetters }, file: GobdData) {
    const organisationId = rootGetters[CONST.getOrganisation].id;
    file.organisationId = organisationId;

    if (file.amount === 'string') {
      file.amount = file.amount?.replace(',', '.'); // (ED-782) JSON (and JS) does not support `number` types with comma
    }
    commit('setGobdDatasIsLoading', true);
    return defaultBackendGobdData
      .updateGobdData(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GobdData;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gobd_data_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setGobdDatasIsLoading', false);
      });
  },
  deleteGobdData({ commit, dispatch }, id: string) {
    return defaultBackendGobdData
      .deleteGobdData(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GobdData;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
