import { GetterTree } from 'vuex';
import { OrganisationAllocationExportPropertyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationAllocationExportPropertyState, RootState> = {
  ['getOrganisationAllocationExportProperties']: (state) => state.organisationAllocationExportProperties,
  ['getOrganisationAllocationExportPropertiesIsLoading']: (state) =>
    state.organisationAllocationExportProperties?.isLoading,
  ['getOrganisationAllocationExportPropertiesTotal']: (state) => state.organisationAllocationExportProperties?.total,
  ['getOrganisationAllocationExportPropertiesSearchParams']: (state) =>
    state.organisationAllocationExportProperties?.searchParams,
  ['getIsUpdating']: (state) => state.isUpdating,
};
