import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationOffice, {
  AllocationOffice,
  AllocationOfficeGroupByAllowedBrands,
  Value,
} from '../model/allocationOffice';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationOffice');
export interface BackendAllocationOffice {
  getAllocationOffice(id: string): AxiosPromise<AllocationOffice>;
  getAllocationOfficeGroupByAllowedBrands(): AxiosPromise<AllocationOfficeGroupByAllowedBrands[]>;
  updateAllocationOfficeGroupByAllowedBrands(item: AllocationOfficeGroupByAllowedBrands[]): AxiosPromise<any>;
  getAllocationOffices: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationOffice(id: string): AxiosPromise;
  updateAllocationOffice(AllocationOffice: AllocationOffice): AxiosPromise<any>;
  uploadAllocationOffice(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
  getByUrl(url: string): AxiosPromise<Value>;
}

export const defaultBackendAllocationOffice: BackendAllocationOffice = {
  getAllocationOffice(id: string): AxiosPromise<AllocationOffice> {
    const url = `${URLS.allocationOfficeOdata}/${id}`;
    return instance.get<AllocationOffice>(url);
  },
  getAllocationOffices(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationOfficeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAllocationOffices${url}`);
    return instance.get<Value>(url);
  },
  getByUrl(url: string): AxiosPromise<Value> {
    return instance.get<Value>(DefaultBackendHelper.removeUrlAppendix(url));
  },
  getAllocationOfficeGroupByAllowedBrands(): AxiosPromise<AllocationOfficeGroupByAllowedBrands[]> {
    const url = `${URLS.allocationOffice}/GetAllocationOfficeGroupByAllowedBrands`;
    logger.log(`getAllocationOffices${url}`);
    return instance.get<AllocationOfficeGroupByAllowedBrands[]>(url);
  },
  updateAllocationOfficeGroupByAllowedBrands(items: AllocationOfficeGroupByAllowedBrands[]): AxiosPromise<any> {
    logger.debug('updateAllocationOffice');
    return instance.put<AllocationOffice>(`${URLS.allocationOffice}/UpdateAllocationOfficeGroupByAllowedBrands`, {
      AllocationOfficeGroupByAllowedBrands: items.map((x) =>
        allocationOffice.toAPIAllocationOfficeGroupByAllowedBrands(x)
      ),
    });
  },
  deleteAllocationOffice(id: string): AxiosPromise {
    logger.debug('deleteAllocationOffice');
    return instance.delete(`${URLS.allocationOffice}/${id}`);
  },
  updateAllocationOffice(AllocationOffice: AllocationOffice): AxiosPromise<any> {
    logger.debug('updateAllocationOffice');
    return instance.put<AllocationOffice>(`${URLS.allocationOffice}/update`, allocationOffice.toAPI(AllocationOffice));
  },
  uploadAllocationOffice(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.allocationOffice}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
