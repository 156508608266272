import { AllocationTemplateState } from './types';

export const state = (): AllocationTemplateState => initialState();

export const initialState = (): AllocationTemplateState => ({
  allocationTemplates: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name', 'account', 'contra_account', 'branch'],
      orClauseFieldsIdsIgnoreCase: ['name', 'account', 'contra_account', 'branch'],
    },
  },
});
