import { OfficeState } from './types';

export const state = (): OfficeState => initialState();

export const initialState = (): OfficeState => ({
  offices: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['OfficeNumber'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Name', 'OfficeNumber'],
    },
  },
});
