import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';

const logger = new Logger('duplicate-document-dialog');
const documentModule = namespace('document');

@Component
export default class DuplicateDocumentDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: '' })
  private model!: any;

  @documentModule.Action('deleteDocuments')
  private actionDeleteDocuments!: any;

  dialogDuplicatesIds: Array<string> = [];

  get previousDuplicates() {
    const oddIndexes = this.model.filter((a: any, index: number) => index % 2 === 1);
    return oddIndexes;
  }

  get newUploadedDuplicates() {
    const oddIndexes = this.model.filter((a: any, index: number) => index % 2 === 0);
    return oddIndexes;
  }

  private populateDialogDuplicatesIds() {
    if (this.model && this.model.length > 0) {
      this.dialogDuplicatesIds = this.model.filter((a: any, index: number) => index % 2 === 0).map((a: any) => a.id);
    }
  }

  created() {
    this.populateDialogDuplicatesIds(); // populate `dialogDuplicatesIds` to make all `last uploaded duplicates` document's checkboxes are CHECKED by default
  }

  mounted() {}

  clickClose() {
    this.$emit('click:close');
  }

  uploadMarked() {
    this.clickClose(); // used the logic from https://neu.easy-docs.de/
    this.$emit('upload:marked', this.dialogDuplicatesIds);
  }

  deleteDuplicatesFromDialog() {
    this.clickClose(); // used the logic from https://neu.easy-docs.de/
    this.$emit('delete:duplicates');
  }
}
