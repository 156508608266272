import { MutationTree } from 'vuex';
import { AllocationBrandState } from './types';
import Vue from 'vue';
import allocationBrand, { AllocationBrand, Value } from '@/shared/model/allocationBrand';

export const mutations: MutationTree<AllocationBrandState> = {
  setAllocationBrands(state, payload: Value) {
    state.allocationBrands.items = payload.value.map((x) => allocationBrand.parse(x));
    state.allocationBrands.total = payload['@odata.count'] || 0;
    state.allocationBrands.isLoading = false;
  },
  setAllocationBrandsEx(state, payload: AllocationBrand[]) {
    state.allocationBrands.items = payload.map((x) => allocationBrand.parse(x));
    state.allocationBrands.total = payload.length || 0;
    state.allocationBrands.isLoading = false;
  },
  setAllocationBrandsTotal(state, payload: number) {
    state.allocationBrands.total = payload;
  },
  setAllocationBrandsIsLoading(state, payload: boolean) {
    state.allocationBrands.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
  setAllAllocationBrands(state, payload: AllocationBrand[]) {
    state.editOfficesPermittedDialog_AllAllocationBrands = payload.map((x) => allocationBrand.parse(x));
  },
  setAllAllocationBrandsIsImporting(state, payload: boolean) {
    state.editOfficesPermittedDialog_AllAllocationBrandsIsImporting = payload;
  },
};
