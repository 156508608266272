import { MutationTree } from 'vuex';
import { AllocationState } from './types';
import Vue from 'vue';
import allocation, { Allocation, AllocationPropertyEx, Value } from '@/shared/model/allocation';

export const mutations: MutationTree<AllocationState> = {
  setAllocations(state, payload: Value) {
    state.allocations.items = payload.value.map((x) => allocation.parse(x));
    state.allocations.total = payload['@odata.count'] || 0;
    state.allocations.isLoading = false;
  },
  setAllocationList(state, payload: Allocation[]) {
    state.allocationList = payload;
    state.allocations.isLoading = false;
  },
  setAllocationPropertyEx(state, payload: AllocationPropertyEx) {
    state.properties = payload;
    state.allocations.isLoading = false;
  },
  setAllocationAllocationsData(state, payload: Allocation[]) {
    state.allocations.items = payload;
    state.allocations.total = payload.length || 0;
    state.allocations.isLoading = false;
  },
  setAllocationsTotal(state, payload: number) {
    state.allocations.total = payload;
  },
  setAllocationsIsLoading(state, payload: boolean) {
    state.allocations.isLoading = payload;
  },
};
