import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationDocumentProperty, {
  OrganisationDocumentProperty,
  Value,
} from '../model/organisationDocumentProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationDocumentProperty');
export interface BackendOrganisationDocumentProperty {
  getOrganisationDocumentProperty(id: string): AxiosPromise<OrganisationDocumentProperty>;
  getOrganisationDocumentPropertys: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteOrganisationDocumentProperty(id: string): AxiosPromise;
  updateOrganisationDocumentProperty(OrganisationDocumentProperty: OrganisationDocumentProperty): AxiosPromise<any>;
  moveOrganisationDocumentProperty(payload: {
    element: OrganisationDocumentProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any>;
}

export const defaultBackendOrganisationDocumentProperty: BackendOrganisationDocumentProperty = {
  getOrganisationDocumentProperty(id: string): AxiosPromise<OrganisationDocumentProperty> {
    const url = `${URLS.organisationDocumentPropertyOdata}/${id}`;
    return instance.get<OrganisationDocumentProperty>(url);
  },
  getOrganisationDocumentPropertys(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationDocumentPropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOrganisationDocumentPropertys${url}`);
    return instance.get<Value>(url);
  },

  deleteOrganisationDocumentProperty(id: string): AxiosPromise {
    logger.debug('deleteOrganisationDocumentProperty');
    return instance.delete(`${URLS.organisationDocumentProperty}/${id}`);
  },
  updateOrganisationDocumentProperty(OrganisationDocumentProperty: OrganisationDocumentProperty): AxiosPromise<any> {
    logger.debug('updateOrganisationDocumentProperty');
    return instance.put<OrganisationDocumentProperty>(
      `${URLS.organisationDocumentProperty}/update`,
      organisationDocumentProperty.toAPI(OrganisationDocumentProperty)
    );
  },
  moveOrganisationDocumentProperty(payload: {
    element: OrganisationDocumentProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any> {
    logger.debug('moveOrganisationDocumentProperty');
    const item = organisationDocumentProperty.toAPI(payload.element);
    item.NewIndex = payload.newIndex;
    item.OldIndex = payload.oldIndex;
    return instance.put<OrganisationDocumentProperty>(`${URLS.organisationDocumentProperty}/move`, item);
  },
};
