import { ActionTree } from 'vuex';
import { DocumentReminderState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentReminder } from '@/shared/backend/documentReminder';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentReminder, { Value, DocumentReminder } from '@/shared/model/documentReminder';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentReminders');
export const actions: ActionTree<DocumentReminderState, RootState> = {
  getDocumentReminders(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; documentId?: string }
  ) {
    commit('setDocumentRemindersIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentRemindersSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    const documentId = payload?.documentId;
    return defaultBackendDocumentReminder
      .getDocumentReminders(searchParams, organisationId, documentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentReminders', response.data);
        commit('setDocumentRemindersIsLoading', false);
        commit(
          'document/updateDocumentRemindersOnCard',
          { documentReminders: response.data, documentId: documentId },
          { root: true }
        );
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentRemindersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentReminder({ commit, dispatch }, id: string) {
    return defaultBackendDocumentReminder
      .getDocumentReminder(id)
      .then((response: AxiosResponse<DocumentReminder>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentReminder({ commit, dispatch }, file: DocumentReminder) {
    return defaultBackendDocumentReminder
      .updateDocumentReminder(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentReminder;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentReminder_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentReminder({ commit, dispatch }, id: string) {
    return defaultBackendDocumentReminder
      .deleteDocumentReminder(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentReminder;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
