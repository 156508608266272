import { GetterTree } from 'vuex';
import { RoleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RoleState, RootState> = {
  ['getRoles']: (state) => state.roles,
  ['RolesIsLoading']: (state) => state.roles?.isLoading,
  ['getRolesTotal']: (state) => state.roles?.total,
  ['getRolesSearchParams']: (state) => state.roles?.searchParams,
  ['getRoleRights']: (state) => state.roleRights,
  ['RolesRightsIsLoading']: (state) => state.roleRights?.isLoading,
  ['getRoleRightsTotal']: (state) => state.roleRights?.total,
  ['getRoleRightsSearchParams']: (state) => state.roleRights?.searchParams,
  ['getInProgressUpdateRoleRights']: (state) => state.inProgressUpdateRoleRights,
  ['getInProgressUpdateRole']: (state) => state.inProgressUpdateRole,
};
