import { ActionTree } from 'vuex';
import { RegexSplitterState } from './types';
import { RootState } from '../../types';
import { defaultBackendRegexSplitter } from '@/shared/backend/regexSplitter';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import regexSplitter, { Value, RegexSplitter } from '@/shared/model/regexSplitter';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.regexSplitters');
export const actions: ActionTree<RegexSplitterState, RootState> = {
  getRegexSplitters({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setRegexSplittersIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRegexSplittersSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendRegexSplitter
      .getRegexSplitters(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setRegexSplitters', response.data);
        commit('setRegexSplittersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRegexSplittersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRegexSplitter({ commit, dispatch }, id: string) {
    return defaultBackendRegexSplitter
      .getRegexSplitter(id)
      .then((response: AxiosResponse<RegexSplitter>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRegexSplitter({ commit, dispatch }, file: RegexSplitter) {
    return defaultBackendRegexSplitter
      .updateRegexSplitter(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RegexSplitter;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.regexSplitter_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteRegexSplitter({ commit, dispatch }, id: string) {
    return defaultBackendRegexSplitter
      .deleteRegexSplitter(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RegexSplitter;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
