import { DataOptions, getDefault } from './DataOptions';

export interface SearchParams {
  dataOption: DataOptions;
  orClauseFieldsIds: string[];
  orClauseFieldsIdsConvertToString?: string[];
  orClauseFieldsIdsIgnoreCase?: string[];
  orClauseFieldsIdsGrateThan?: string[] | undefined;
  filter: string;
  view?: string;
}

export const SearchParamsEmpty: SearchParams = {
  dataOption: { ...getDefault },
  orClauseFieldsIds: [],
  orClauseFieldsIdsConvertToString: [],
  orClauseFieldsIdsIgnoreCase: [],
  orClauseFieldsIdsGrateThan: [],
  filter: '',
};
