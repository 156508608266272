import { GetterTree } from 'vuex';
import { AllocationTaxGroupState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationTaxGroupState, RootState> = {
  ['getAllocationTaxGroups']: (state) => state.allocationTaxGroups,
  ['getAllocationTaxGroupsIsLoading']: (state) => state.allocationTaxGroups?.isLoading,
  ['getAllocationTaxGroupsTotal']: (state) => state.allocationTaxGroups?.total,
  ['getAllocationTaxGroupsSearchParams']: (state) => state.allocationTaxGroups?.searchParams,
};
