import { MutationTree } from 'vuex';
import { DocumentStatusState } from './types';
import ds, { Value, DocumentStatus } from '@/shared/model/documentStatus';
import Vue from 'vue';

export const mutations: MutationTree<DocumentStatusState> = {
  setDocumentStatus(state, payload?: DocumentStatus) {
    state.documentStatus = payload;
  },
  resetDocumentStatus(state, payload?: DocumentStatus) {
    state.documentStatus = Object.assign({}, payload);
  },
  setDocumentStatuses(state, payload: Value) {
    state.documentStatuses.items = payload.value.map((x) => ds.parse(x));
    state.documentStatuses.total = payload['@odata.count'] || 0;
    state.documentStatuses.isLoading = false;
  },
  // Avoid extra request to backend after documentStatusUpdate to update `documentStatuses.items` state
  updateDocumentStatus(state, payload: DocumentStatus) {
    const arrayCopy = state.documentStatuses.items;
    const index = arrayCopy.findIndex((d) => d.id == payload!.id);
    arrayCopy[index] = payload;
    state.documentStatuses.items = arrayCopy.map((x) => ds.parse(x));
  },
  setDocumentStatusesTotal(state, payload: number) {
    state.documentStatuses.total = payload;
  },
  setDocumentStatusesIsLoading(state, payload: boolean) {
    state.documentStatuses.isLoading = payload;
  },
};
