import { ActionTree } from 'vuex';
import { RoleState } from './types';
import { RootState } from '../../types';
import { defaultBackendRole } from '@/shared/backend/role';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import ds, { Value, Role } from '@/shared/model/role';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { RoleRight, Value as RoleRightValue } from '@/shared/model/roleRight';

const logger = new Logger('actions.roles');
export const actions: ActionTree<RoleState, RootState> = {
  getRoles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; parentRolesId?: string }) {
    commit('setRolesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRolesSearchParams;
    return defaultBackendRole
      .getRoles(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setRoles', response.data);
        commit('setRolesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRolesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRoleRights({ commit, dispatch, getters }, payload: { searchParams?: any; roleId: string }) {
    commit('setRoleRightsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRoleRightsSearchParams;
    return defaultBackendRole
      .getRoleRigts(payload?.roleId, searchParams)
      .then((response: AxiosResponse<RoleRightValue>) => {
        commit('setRoleRights', response.data);
        commit('setRoleRightsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRoleRightsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRole({ commit, dispatch }, id: string) {
    return defaultBackendRole
      .getRole(id)
      .then((response: AxiosResponse<Role>) => {
        commit('setRole', ds.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRole({ commit, dispatch }, file: Role) {
    commit('setInProgressUpdateRole', true);
    return defaultBackendRole
      .updateRole(file)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.role_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        const t = i18n.tc(`errors.${e.response.data.errors.map((x: any) => x.description).join(', ')}`);
        module_utils.error(t, commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateRole', false);
      });
  },
  updateRoleRights({ commit, dispatch }, item: { roleId: string; rights: RoleRight[]; folderRights: RoleRight[] }) {
    commit('setInProgressUpdateRoleRights', true);
    return defaultBackendRole
      .updateRoleRights(item.roleId, item.rights, item.folderRights)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.role_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateRoleRights', false);
      });
  },
  deleteRole({ commit, dispatch }, id: string) {
    return defaultBackendRole
      .deleteRole(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error(
          i18n.t(
            'error' + (Array.isArray(e.response.data?.errors) ? 's.' + e.response.data?.errors[0].description : '')
          ),
          commit,
          e,
          logger
        );
        throw e;
      });
  },
};
