import { MutationTree } from 'vuex';
import { OrganisationAllocationPropertyState } from './types';
import Vue from 'vue';
import organisationAllocationProperty, {
  OrganisationAllocationProperty,
  Value,
} from '@/shared/model/organisationAllocationProperty';

export const mutations: MutationTree<OrganisationAllocationPropertyState> = {
  setOrganisationAllocationProperties(state, payload: Value) {
    state.organisationAllocationProperties.items = payload.value.map((x) => organisationAllocationProperty.parse(x));
    state.organisationAllocationProperties.total = payload['@odata.count'] || 0;
    state.organisationAllocationProperties.isLoading = false;
  },
  setOrganisationAllocationPropertiesTotal(state, payload: number) {
    state.organisationAllocationProperties.total = payload;
  },
  setOrganisationAllocationPropertiesIsLoading(state, payload: boolean) {
    state.organisationAllocationProperties.isLoading = payload;
  },
  setIsUpdating(state, payload: boolean) {
    state.isUpdating = payload;
  },
};
