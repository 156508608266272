import { ActionTree } from 'vuex';
import { UserPackageState } from './types';
import { RootState } from '../../types';
import { defaultBackendUserPackage } from '@/shared/backend/userPackage';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import userPackage, { Value, UserPackage } from '@/shared/model/userPackage';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.userPackages');
export const actions: ActionTree<UserPackageState, RootState> = {
  getUserPackages({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setUserPackagesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getUserPackagesSearchParams;
    return defaultBackendUserPackage
      .getUserPackages(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setUserPackages', response.data);
        commit('setUserPackagesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setUserPackagesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getUserPackage({ commit, dispatch }, id: string) {
    return defaultBackendUserPackage
      .getUserPackage(id)
      .then((response: AxiosResponse<UserPackage>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateUserPackage({ commit, dispatch }, file: UserPackage) {
    return defaultBackendUserPackage
      .updateUserPackage(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: UserPackage;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.userPackage_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteUserPackage({ commit, dispatch }, id: string) {
    return defaultBackendUserPackage
      .deleteUserPackage(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: UserPackage;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
