export interface Export {
  id: string;
  createDateText: string;
  createdToday: boolean;
  deleted: boolean;
  fromTimeStamp: number;
  monthTimeStamp: number;
  name: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  toTimeStamp: number;
  userId: string;

  createdAt: string;
  updatedAt: string;
}

export interface Value {
  value: Export[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Export>): any {
  return {
    Id: data?.id || undefined,
    CreateDateText: data?.createDateText || '',
    CreatedToday: data?.createdToday || false,
    Deleted: data?.deleted || false,
    FromTimeStamp: data?.fromTimeStamp || 0,
    MonthTimeStamp: data?.monthTimeStamp || 0,
    Name: data?.name || '',
    OrganisationId: data?.organisationId || undefined,
    ToTimeStamp: data?.toTimeStamp || 0,
    UserId: data?.userId || undefined,

    Created_At: data?.createdAt || undefined,
    Updated_At: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<Export>): Export {
  return {
    id: data?.id || '',
    createDateText: data?.createDateText || '',
    createdToday: data?.createdToday || false,
    deleted: data?.deleted || false,
    fromTimeStamp: data?.fromTimeStamp || 0,
    monthTimeStamp: data?.monthTimeStamp || 0,
    name: data?.name || '',
    organisationId: data?.organisationId || '',
    toTimeStamp: data?.toTimeStamp || 0,
    userId: data?.userId || '',

    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
