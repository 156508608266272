export default class FileUtils {
  public static getFileExtension(fileName: string) {
    const fileExtension = fileName.split('.').pop();
    return fileExtension?.toLowerCase() || 'no Filename';
  }

  public static isPdf(fileName: string) {
    return this.getFileExtension(fileName) === EXTENSIONS.pdf;
  }

  public static isMsDocXlsOrPpt(fileName: string) {
    return (
      this.getFileExtension(fileName) === EXTENSIONS.doc ||
      this.getFileExtension(fileName) === EXTENSIONS.docx ||
      this.getFileExtension(fileName) === EXTENSIONS.xls ||
      this.getFileExtension(fileName) === EXTENSIONS.xlsx ||
      this.getFileExtension(fileName) === EXTENSIONS.ppt ||
      this.getFileExtension(fileName) === EXTENSIONS.pptx ||
      this.getFileExtension(fileName) === EXTENSIONS.txt ||
      this.getFileExtension(fileName) === EXTENSIONS.odt
    );
  }

  // bmp, jpg, jpeg, png
  public static isPicture(fileName: string) {
    const imageExtensions: string[] = [EXTENSIONS.bmp, EXTENSIONS.jpg, EXTENSIONS.jpeg, EXTENSIONS.png, EXTENSIONS.svg];
    return imageExtensions.includes(this.getFileExtension(fileName));
  }

  public static isVideo(fileName: string) {
    const imageExtensions: string[] = [EXTENSIONS.mp4];
    return imageExtensions.includes(this.getFileExtension(fileName));
  }
}

export enum EXTENSIONS {
  pdf = 'pdf',
  doc = 'doc',
  docx = 'docx',
  xls = 'xls',
  xlsx = 'xlsx',
  ppt = 'ppt',
  pptx = 'pptx',
  bmp = 'bmp',
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  txt = 'txt',
  odt = 'odt',
  svg = 'svg',
  mp4 = 'mp4',
}
