import { render, staticRenderFns } from "./menu-document-information.html?vue&type=template&id=0a7cbcf5&scoped=true&external"
import script from "./menu-document-information.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-information.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-information.scss?vue&type=style&index=0&id=0a7cbcf5&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7cbcf5",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-information.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-information%2Fmenu-document-information.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VListItem,VListItemTitle,VTextField})
