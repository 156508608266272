import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ds, { DocumentStatus, Value } from '../model/documentStatus';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.documentStatus');
export interface BackendDocumentStatus {
  getDocumentStatus(id: string): AxiosPromise<DocumentStatus>;
  getDocumentStatuses: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentStatus(id: string): AxiosPromise;
  updateDocumentStatus(documentStatus: DocumentStatus): AxiosPromise<any>;
}

// TODO (ED-254): change `OrganizationId` (with `Z`) to `OrganisationId` (with `S`) here and `DocumentStatusDm` backend class,  `OrganisationId` is used in old `https://neu.easy-docs.de/` tables, so we also use should `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
export const defaultBackendDocumentStatus: BackendDocumentStatus = {
  getDocumentStatus(id: string): AxiosPromise<DocumentStatus> {
    const url = `${URLS.documentStatusOdata}/${id}`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<DocumentStatus>(url);
  },
  getDocumentStatuses(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentStatusOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getFolders${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentStatus(id: string): AxiosPromise {
    logger.debug('deleteDocumentStatus');
    return instance.delete(`${URLS.documentStatus}/${id}`);
  },
  updateDocumentStatus(documentStatus: DocumentStatus): AxiosPromise<any> {
    return instance.put<DocumentStatus>(`${URLS.documentStatus}/update`, ds.toAPI(documentStatus));
  },
};
