import { ActionTree } from 'vuex';
import { AllocationTaxGroupsImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationTaxGroupsImportSetting } from '@/shared/backend/allocationTaxGroupsImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationTaxGroupsImportSetting, {
  Value,
  AllocationTaxGroupsImportSetting,
} from '@/shared/model/allocationTaxGroupsImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationTaxGroupsImportSettings');
export const actions: ActionTree<AllocationTaxGroupsImportSettingState, RootState> = {
  getAllocationTaxGroupsImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationTaxGroupsImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationTaxGroupsImportSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTaxGroupsImportSetting
      .getAllocationTaxGroupsImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTaxGroupsImportSettings', response.data);
        commit('setAllocationTaxGroupsImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationTaxGroupsImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTaxGroupsImportSetting({ commit, dispatch }, id: string) {
    commit('setAllocationTaxGroupsImportSettingsIsLoading', true);
    return defaultBackendAllocationTaxGroupsImportSetting
      .getAllocationTaxGroupsImportSetting(id)
      .then((response: AxiosResponse<AllocationTaxGroupsImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllocationTaxGroupsImportSettingsIsLoading', false);
      });
  },
  getAllocationTaxGroupsImportSettingByGroupId({ commit, dispatch, getters }, idGroup: string) {
    const searchParams = getters.getAllocationTaxGroupsImportSettingsSearchParams;
    return defaultBackendAllocationTaxGroupsImportSetting
      .getAllocationTaxGroupsImportSettingByGroupId(searchParams, idGroup)
      .then((response: AxiosResponse<Value>) => {
        return response.data.value[0];
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationTaxGroupsImportSetting({ commit, dispatch, rootGetters }, file: AllocationTaxGroupsImportSetting) {
    commit('setInProgressUpdateAllocationTaxGroupsImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTaxGroupsImportSetting
      .updateAllocationTaxGroupsImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTaxGroupsImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationTaxGroupsImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateAllocationTaxGroupsImportSetting', false);
      });
  },
  updateChangeClearBeforeImport(
    { commit, dispatch, rootGetters, getters },
    payload: { value: boolean; old: boolean; groupId: string }
  ) {
    getters.getAllocationTaxGroupsImportSetting.clearBeforeImport = payload.value;
    dispatch('updateAllocationTaxGroupsImportSetting', getters.getAllocationTaxGroupsImportSetting)
      .then((result: any) => {
        getters.getAllocationTaxGroupsImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getAllocationTaxGroupsImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteAllocationTaxGroupsImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTaxGroupsImportSetting
      .deleteAllocationTaxGroupsImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTaxGroupsImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
