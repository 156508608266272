export interface Comment {
  id: string; // System.Guid
  actionLogId: string; // System.Guid
  documentId: string; // System.Guid
  userId: string; // System.Guid
  message: string; // string
  createTimeStamp: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationId: string; // System.Guid // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  email: string;
}

export interface Value {
  value: Comment[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Comment>): any {
  return {
    Id: data?.id || undefined,
    ActionLogId: data?.actionLogId || undefined,
    DocumentId: data?.documentId || undefined,
    UserId: data?.userId || undefined,
    Message: data?.message || '',
    CreateTimeStamp: data?.createTimeStamp || '',
    CreatedAt: data?.createdAt || undefined, // if `nullable` type on backend `DateTime?` the default value should be `undefined` otherwise JSON parse error
    UpdatedAt: data?.updatedAt || undefined,
    OrganisationId: data?.organisationId || undefined,
    Email: data?.email || '',
  };
}

function parse(data?: Partial<Comment>): Comment {
  return {
    id: data?.id || '',
    actionLogId: data?.actionLogId || '',
    documentId: data?.documentId || '',
    userId: data?.userId || '',
    message: data?.message || '',
    createTimeStamp: data?.createTimeStamp || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
    email: data?.email || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
