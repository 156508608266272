import { SearchParams, SearchParamsEmpty } from '@/shared/model/searchParams';
import { MutationTree } from 'vuex';
import { OrganizationState } from './types';
import organization, { Value, Organisation } from '@/shared/model/organisation';
import organisationUser, { Value as OrganisationUserValue } from '@/shared/model/organisationUser';
import folder, { Value as FolderValue } from '@/shared/model/folder';
import TreeUtils from '@/shared/utils/treeUtils';

export const mutations: MutationTree<OrganizationState> = {
  setOrganization(state, payload?: Organisation) {
    state.currentOrganization = payload;
  },
  setOrganizations(state, payload: Value) {
    state.organizations.items = payload.value.map((x) => organization.parse(x));
    state.organizations.total = payload['@odata.count'] || 0;
    state.organizations.isLoading = false;

    // (ED-339) if `currentOrganization` is empty then use 1st organization as Current, there is similar logic in `Callback.ts` file, but on 1st request for some reason 0 organizations returned, and with additional `await` to wait some time the `left-menu` v-select with Organizations throws error, so best option I find to avoid errors on 1st login is to set currentOrganization here
    if (!state.currentOrganization?.id) {
      state.currentOrganization = state.organizations.items[0];
    }
  },
  setOrganizationUsersAdmPage(state, payload: { orgId: string; val: OrganisationUserValue }) {
    state.organizationUsersAdmPage[payload.orgId].items = payload.val.value.map((x) => organisationUser.parse(x));
    state.organizationUsersAdmPage[payload.orgId].total = payload.val['@odata.count'] || 0;
    state.organizationUsersAdmPage[payload.orgId].isLoading = false;
  },
  setOrganizationsAllForMain(state, payload: { mainId: string; val: Value }) {
    state.organizationsAllForMain[payload.mainId].items = payload.val.value.map((x) => organization.parse(x));
    state.organizationsAllForMain[payload.mainId].total = payload.val['@odata.count'] || 0;
    state.organizationsAllForMain[payload.mainId].isLoading = false;
  },
  setMainOrganizations(state, payload: Value) {
    state.organizationsAll.items = payload.value.map((x) => {
      const y = organization.parse(x);
      (y as any).items = {};
      return y;
    });
    state.organizationsAll.total = payload['@odata.count'] || 0;
    state.organizationsAll.isLoading = false;
  },
  setMainOrganizationsIsLoading(state, payload: boolean) {
    state.organizationsAll.isLoading = payload;
  },

  setOrganizationsTotal(state, payload: number) {
    state.organizations.total = payload;
  },
  setOrganizationsIsLoading(state, payload: boolean) {
    state.organizations.isLoading = payload;
  },

  setOrganizationFoldersAll(state, payload: FolderValue) {
    state.organizationFoldersAll.items = payload.value.map((x) => folder.parse(x));
    state.organizationFoldersAll.total = payload['@odata.count'] || 0;
    state.organizationFoldersAll.isLoading = false;
    state.organizationFoldersAll.treeView = Object.assign(
      [],
      TreeUtils.FoldersToTreeView(state.organizationFoldersAll.items.map((i) => ({ ...i })))
    );
    state.organizationFoldersAll.treeViewFlat = Object.assign(
      [],
      TreeUtils.FoldersToTreeViewFlat(state.organizationFoldersAll.items.map((i) => ({ ...i })))
    );
  },
  setOrganizationFoldersAllTotal(state, payload: number) {
    state.organizationFoldersAll.total = payload;
  },
  setOrganizationFoldersAllIsLoading(state, payload: boolean) {
    state.organizationFoldersAll.isLoading = payload;
  },
  setResetInProgress(state, payload: boolean) {
    state.resetInProgress = payload;
  },
  setOrganisationUsers(state, payload: OrganisationUserValue) {
    state.organisationUsers.items = payload.value.map((x) => organisationUser.parse(x));
    state.organisationUsers.total = payload['@odata.count'] || 0;
    state.organisationUsers.isLoading = false;
  },

  setOrganisationUsersAll(state, payload: OrganisationUserValue) {
    state.organisationUsersAll.items = payload.value.map((x) => organisationUser.parse(x));
    state.organisationUsersAll.total = payload['@odata.count'] || 0;
    state.organisationUsersAll.isLoading = false;
  },

  setOrganisationUsersTotal(state, payload: number) {
    state.organisationUsers.total = payload;
  },
  setOrganisationUsersAllTotal(state, payload: number) {
    state.organisationUsersAll.total = payload;
  },
  setOrganisationUsersIsLoading(state, payload: boolean) {
    state.organisationUsers.isLoading = payload;
  },
  setOrganisationUsersAllIsLoading(state, payload: boolean) {
    state.organisationUsersAll.isLoading = payload;
  },
  setInProgressUpdateOrganisationUser(state, payload: boolean) {
    state.inProgressUpdateOrganisationUser = payload;
  },
  setInProgressUpdateToMain(state, payload: boolean) {
    state.inProgressUpdateToMain = payload;
  },
  setInProgressUpdateCurrentOrganization(state, payload: boolean) {
    state.inProgressUpdateCurrentOrganization = payload;
  },
};
