import { Component, Vue, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';

import { Logger } from 'fsts';
import folder, { Folder } from '@/shared/model/folder';

const logger = new Logger('edit-folder-dialog');

@Component({
  name: 'edit-folder-dialog',
  components: {},
})
export default class EditFolderDialog extends Vue {
  @Ref('edit-folder-form')
  private refEditFolderForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => folder.parse({}) })
  private value!: any;
  @Watch('value')
  onValue(nVal: any, oVal: any) {
    this.resetNameOnAddToAvoidValidationError();
  }

  resetNameOnAddToAvoidValidationError() {
    this.value.name = null;
  }

  @Prop({ default: () => [] })
  parents!: {
    folder: Folder;
    id: string;
    name: string;
    parent: { name: string; id: string };
    children: any[];
  }[];

  private isFormValid = true;
  get nameRules() {
    return [
      (v: string) => (v && !!v.trim()) || this.$i18n.t('name_required_message'),
      (v: string) => (v && v.trim().length > 1) || this.$i18n.t('name_min_length_message'),
      (v: string) =>
        (v && v.trim().length > 1 && !this.nameRepeatOnSameLevel(v.trim())) ||
        this.$i18n.t('name_repeated_on_same_level'),
    ];
  }
  nameRepeatOnSameLevel(newName: string) {
    let children: any = undefined;
    children = this.parents.find((x) => x.name == newName && x.id != this.value.id);

    return children !== undefined;
  }
  get title() {
    return this.$i18n.t('title_add');
  }

  resetValidation() {
    this.refEditFolderForm?.resetValidation();
  }

  private onClose() {
    this.resetValidation();
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    const isFormValidResult = await this.refEditFolderForm.validate();
    if (!isFormValidResult) return;
    this.$emit('click:add', this.value);
  }
}
