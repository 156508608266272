import { GetterTree } from 'vuex';
import { CostCentreState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CostCentreState, RootState> = {
  ['getCostCentres']: (state) => state.costCentres,
  ['getCostCentresIsLoading']: (state) => state.costCentres?.isLoading,
  ['getCostCentresTotal']: (state) => state.costCentres?.total,
  ['getCostCentresSearchParams']: (state) => state.costCentres?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
};
