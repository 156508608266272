import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { Employee } from '@/shared/model/employee';
import { OdataItems } from '@/shared/model/OdataItems';
import { debounceAsync } from '@/shared/utils/generalUtils';

const name = 'selected-employees-table';
const logger = new Logger(name);
const userModule = namespace('user');
const actionLogModule = namespace('actionLog');

@Component({
  name: name,
  components: {},
})
export default class SelectedEmployeesTable extends Vue {
  @actionLogModule.Action('getActionLogQaUsers')
  private getQaUsers!: any;
  @actionLogModule.Getter('getActionLogQaUsers')
  private employees!: OdataItems<Employee>;

  @Prop({
    default: () => {},
  })
  private message!: any;

  get employeesHeaders() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [];
    if (this.message.threadType == 'circular') {
      headers.push({ text: this.$t('read'), value: 'read_icon', sortable: false, width: '1%' });
      headers.push({ text: this.$t('read_date'), value: 'read', sortable: false });
    }
    headers.push({ text: this.$t('email'), value: 'email' });
    headers.push({ text: this.$t('first_name'), value: 'firstName' });
    headers.push({ text: this.$t('last_name'), value: 'lastName' });
    return headers;
  }

  async mounted() {}

  //#region logic for search in filtered `Users`(employees) table
  // need these 2 boolean variables to avoid extra queries for backend (ie if user press Enter 10 times for empty search input in table 0 requests will be send, before without these 2 boolean variables 10 requests would be send )
  private isLastUserRequestWithSearch = false;

  private getRegexSplittersDebounced = debounceAsync(async () => await this.getQaUsers(this.message.actionLogId));

  @Watch('employees.searchParams', { deep: true })
  onUpdateSearchParams() {
    // debounce
    this.getRegexSplittersDebounced();
  }
  //#endregion
}
