import { GetterTree } from 'vuex';
import { EmployeeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<EmployeeState, RootState> = {
  ['getEmployees']: (state) => state.employees,
  ['EmployeesIsLoading']: (state) => state.employees?.isLoading,
  ['getEmployeesTotal']: (state) => state.employees?.total,
  ['getEmployeesSearchParams']: (state) => state.employees?.searchParams,
};
