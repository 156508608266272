import { ActionTree } from 'vuex';
import { CostCentreImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendCostCentreImportSetting } from '@/shared/backend/costCentreImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import costCentreImportSetting, { Value, CostCentreImportSetting } from '@/shared/model/costCentreImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.costCentreImportSettings');
export const actions: ActionTree<CostCentreImportSettingState, RootState> = {
  getCostCentreImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setCostCentreImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getCostCentreImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostCentreImportSetting
      .getCostCentreImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setCostCentreImportSettings', response.data);
        commit('setCostCentreImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCostCentreImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostCentreImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setCostCentreImportSettingsIsLoading', true);
    const searchParams = getters.getCostCentreImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostCentreImportSetting
      .getCostCentreImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setCostCentreImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setCostCentreImportSettingsIsLoading', false);
      });
  },
  getCostCentreImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendCostCentreImportSetting
      .getCostCentreImportSetting(id)
      .then((response: AxiosResponse<CostCentreImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCostCentreImportSetting({ commit, dispatch, rootGetters }, file: CostCentreImportSetting) {
    commit('setInProgressUpdateCostCentreImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostCentreImportSetting
      .updateCostCentreImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostCentreImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.costCentreImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateCostCentreImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getCostCentreImportSetting.clearBeforeImport = payload.value;
    dispatch('updateCostCentreImportSetting', getters.getCostCentreImportSetting)
      .then((result: any) => {
        getters.getCostCentreImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getCostCentreImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteCostCentreImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendCostCentreImportSetting
      .deleteCostCentreImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostCentreImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
