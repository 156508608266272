import { MutationTree } from 'vuex';
import { CostObjectiveImportSettingState } from './types';
import Vue from 'vue';
import costObjectiveImportSetting, { Value } from '@/shared/model/costObjectiveImportSetting';

export const mutations: MutationTree<CostObjectiveImportSettingState> = {
  setCostObjectiveImportSettings(state, payload: Value) {
    state.costObjectiveImportSettings.items = payload.value.map((x) => costObjectiveImportSetting.parse(x));
    state.costObjectiveImportSettings.total = payload['@odata.count'] || 0;
    state.costObjectiveImportSettings.isLoading = false;
  },
  setCostObjectiveImportSettingsTotal(state, payload: number) {
    state.costObjectiveImportSettings.total = payload;
  },
  setCostObjectiveImportSettingsIsLoading(state, payload: boolean) {
    state.costObjectiveImportSettings.isLoading = payload;
  },
  setCostObjectiveImportSetting(state, payload: Value) {
    state.costObjectiveImportSetting = Object.assign({}, costObjectiveImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateCostObjectiveImportSetting(state, payload: boolean) {
    state.inProgressUpdateCostObjectiveImportSetting = payload;
  },
};
