export interface AllocationFrameProperty {
  id: string; // System.Guid
  allocationFrameId: string; // System.Guid
  kontoInteger: number; // int
  konto: string; // string
  bezeichnung: string; // string
  kostenstelle: string; // string
  kostentraeger: string; // string
  absatzkanal: string; // string
  herkunft: string; // string
  haendlerEigenerKostentraeger: string; // string
  erweiterterVertriebsweg: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  antriebsart: string; // string
}

export interface Value {
  value: AllocationFrameProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationFrameProperty>): any {
  return {
    Id: data?.id || undefined,
    AllocationFrameId: data?.allocationFrameId || undefined,
    KontoInteger: data?.kontoInteger || 0,
    Konto: data?.konto || '',
    Bezeichnung: data?.bezeichnung || '',
    Kostenstelle: data?.kostenstelle || '',
    Kostentraeger: data?.kostentraeger || '',
    Absatzkanal: data?.absatzkanal || '',
    Herkunft: data?.herkunft || '',
    HaendlerEigenerKostentraeger: data?.haendlerEigenerKostentraeger || '',
    ErweiterterVertriebsweg: data?.erweiterterVertriebsweg || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    Antriebsart: data?.antriebsart || '',
  };
}

function parse(data?: Partial<AllocationFrameProperty>): AllocationFrameProperty {
  return {
    id: data?.id || '',
    allocationFrameId: data?.allocationFrameId || '',
    kontoInteger: data?.kontoInteger || 0,
    konto: data?.konto || '',
    bezeichnung: data?.bezeichnung || '',
    kostenstelle: data?.kostenstelle || '',
    kostentraeger: data?.kostentraeger || '',
    absatzkanal: data?.absatzkanal || '',
    herkunft: data?.herkunft || '',
    haendlerEigenerKostentraeger: data?.haendlerEigenerKostentraeger || '',
    erweiterterVertriebsweg: data?.erweiterterVertriebsweg || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    antriebsart: data?.antriebsart || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
