import { Organisation } from '@/shared/model/organisation';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Snackbar } from '@/shared/model/snackbar';
import { State, Action, namespace, Getter } from 'vuex-class';
import Login from '@/views/login/login.vue'; // @ is an alias to /src
import FilteringButtons from '@/components/filtering-buttons/filtering-buttons.vue';
import RightMainMenuView from '../menu/rightMainMenu/right-main-menu.vue';
import LeftMenuView from './left-menu/left-menu.vue';
import { Logger } from 'fsts';
import FileUploadDialog from '@/components/dialogs/file-upload/file-upload.vue';
import DialogBoxComponent from '@/components/dialogs/dialogBox/dialogBox.vue';
import AllProcessesWaitingIndicator from './all-processes-waiting-indicator/all-processes-waiting-indicator.vue';
import DocumentBreadcrumb from './top-menu/document-breadcrumb/document-breadcrumb.vue';
import { Document } from '@/shared/model/document';
import { OdataItems } from '@/shared/model/OdataItems';
import { VueExtensions } from 'vue/types/vue';
import store from '@/shared/store';
import ModalIdle from '@/components/dialogs/modal-idle/modal-idle.vue';
import { FileUpload } from '@/shared/model/fileUpload';

const logger = new Logger('app');
const authModule = namespace('auth');
const organizationModule = namespace('organization');
const documentModule = namespace('document');
const folderModule = namespace('folder');
const signalR = require('@microsoft/signalr');
const fileUploadModule = namespace('fileUpload');
@Component({
  components: {
    Login,
    FilteringButtons,
    RightMainMenuView,
    LeftMenuView,
    FileUploadDialog,
    DialogBoxComponent,
    AllProcessesWaitingIndicator,
    ModalIdle,
    DocumentBreadcrumb,
  },
})
export default class AppView extends Vue {
  public $refs!: Vue['$refs'] & {
    dialogBox: any;
  };

  @State('snackbar') public stateSnackbar!: Snackbar;

  @Action('releaseSnackbar') private releaseSnackbar: any;

  @Action('setListViewMode') private setListViewMode: any;

  @Action('auth/updateLeftMenuOpenState') private actionUpdateLeftMenuOpenState: any;
  @Action('auth/updateHeaderNavData') private actionUpdateHeaderNavData: any;
  @Getter('auth/getIsLeftDrawerShown') private getterIsLeftDrawerShown: any;
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;

  @documentModule.Action('refreshDocument')
  private refreshDocument!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @documentModule.Mutation('resetLastLoadDate')
  private resetLastLoadDate!: any;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;
  @documentModule.Getter('getDocumentsAllCount')
  private getDocumentsAllCount!: Document;
  @documentModule.Getter('isDocumentsNeedUpdate')
  private isDocumentsNeedUpdate!: boolean;

  @fileUploadModule.Getter('getFileUpload')
  private getFileUpload!: FileUpload;

  calcDocumentsNeedUpdateColor(val: boolean) {
    if (val) return 'orange';
    return '';
  }
  @documentModule.Action('rescanDocumentMultiple')
  private actionRescanDocumentsMultiple!: any;
  @documentModule.Action('getDocumentsAllOrDeletedCount')
  private actionGetDocumentsAllOrDeletedCount!: any;
  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;

  @authModule.Getter('UploadDocumentsAllowed')
  private UploadDocumentsAllowed!: boolean;
  @authModule.Getter('isSiteOwner')
  private isSiteOwner!: boolean;

  @Action('document/setMetadataIsCreated') private actionSetMetadataIsCreated: any;
  @Action('file/getFilesForDocumentId') private actionGetFilesForDocumentId: any;
  @Action('userFile/getUserFilePreview') private getUserFilePreview: any;
  @Action('document/getDocumentIdByPreviewFileId') private getDocumentIdByPreviewFileId: any;

  get snackbar() {
    return this.stateSnackbar;
  }

  get isPreviewRoute() {
    return this.$route.name === 'document-preview';
  }

  get isAddressRoute() {
    return this.$route.name === 'addresses';
  }

  get isSettingsRoute() {
    return this.$route.path.includes('settings');
  }

  get isExportRoute() {
    return this.$route.path.includes('export');
  }

  get isProcessesRoute() {
    return this.$route.name === 'processes';
  }

  get documentNumber() {
    return this.getterGetDocument?.documentNumber ?? 0;
  }

  @authModule.Action('logout')
  private actionLogout!: any;

  @authModule.Getter('getAccount')
  private account!: any;
  @authModule.Getter('isLoggedIn')
  public isLoggedIn!: boolean;

  get navbarTitle() {
    return this.getHeaderNavData.translateId;
  }

  get drawerLeft() {
    return this.getterIsLeftDrawerShown;
  }

  private toggleLeftDrawer() {
    this.updateFoldersCountsIfNewUploads(this.drawerLeft);
    this.actionUpdateLeftMenuOpenState(!this.drawerLeft);
  }

  updateFoldersCountsIfNewUploads(isDrawerLeftOpen: boolean) {
    if (isDrawerLeftOpen) return;

    this.getMenuFolders();
  }

  private hideLeftDrawer() {
    this.actionUpdateLeftMenuOpenState(false);
  }

  private changeText(menuItem: { translateId: string; to: string }) {
    this.actionUpdateHeaderNavData({ translateId: menuItem.translateId, to: menuItem.to });
  }

  //#region (ED-399 + ED-400)
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: any;

  private getDocsWithAnswers() {
    this.actionUpdateHeaderNavData({ translateId: 'questions_answers', to: '' });
    this.removeFilter('my-QA');
    this.addFilterIfNotExist('QA');
  }

  private getMyDocsWithAnswers() {
    this.removeFilter('QA');
    this.actionUpdateHeaderNavData({ translateId: 'my_questions_answers', to: '' });
    const userId = this.account?.profile?.sub;
    this.addFilterIfNotExist('my-QA', userId);
  }
  private getDocumentsWithoutCashing() {
    this.resetLastLoadDate();
    this.getDocuments();
  }
  private addFilterIfNotExist(status: string, value?: string) {
    // if filter exist just does not add it
    if (this.documentsSearchPayload.filterData.find((item: any) => item.docStateStatus == status)) return;

    const payload = {
      docStateStatus: status,
      value: value,
    };

    this.documentsSearchPayload.filterData.push(payload);
    this.getDocuments(); // add call here to avoid extra queries if click on icon several times
  }

  removeFilter(status: string) {
    const filterIndex = this.documentsSearchPayload.filterData.findIndex((item: any) => item.docStateStatus == status);

    if (filterIndex >= 0) {
      this.documentsSearchPayload.filterData.splice(filterIndex, 1);
    }
  }
  //#endregion
  async created() {
    this.$root.$i18n.locale = this.account?.profile?.language ?? 'de';
    this.$vuetify.lang.current = this.$root.$i18n.locale;
    this.$vuetify.theme.dark = this.account?.profile?.theme == 'dark';
  }
  async mounted() {
    logger.log('mounted');
    this.initSignalR();

    // placing a global component in app.vue.html and adding ref options (e.g. <myComponent ref="mycomp"></myComponent>)
    // then make a ref global:
    this.$confirm.open = this.$refs.dialogBox.openConfirm;
    this.$message.open = this.$refs.dialogBox.openMessageBox;

    if (this.isLoggedIn) {
      await loadOrganizationFolderFilesMainData();
    }
    if (this.$route.params.documentId) {
    }
  }

  private updateDocumentsPreviewAndText() {
    logger.log('Update documents preview image and text recognition');
    this.actionRescanDocumentsMultiple({ textOrPreview: 0, documentsIds: this.getterDocuments.items.map((x) => x.id) });
  }

  private getDocuments() {
    logger.log('Update docs from header :>>  routeQuery :>>', this.$route.query);
    this.actionGetDocuments();
  }

  //#region EditDocumentStatusDialog logic
  dialogUpload = {
    show: false,
    model: {},
  };

  async dialogUploadOnClose() {
    this.dialogUpload.show = false;
  }

  async dialogUploadOnUpload(item: any) {}

  private async openDocumentUploadDialog(file: any) {
    this.dialogUpload.model = { ...file };
    this.dialogUpload.show = true;
  }
  //#endregion

  //#region Handle header navigation (next/previous) for `document-preview`
  // TODO: (ED-235) Populate `getterDocuments` state if empty + ???`block previous/next` header buttons if `getterDocuments` is empty
  // TODO: try to move header markup and logic to separate `app-header` component
  @documentModule.Getter('getDocuments')
  private getterDocuments!: OdataItems<Document>;

  get documentItemsLength() {
    return this.getterDocuments.items.length;
  }

  private getIndexByDocumentNumber() {
    const index =
      this.documentItemsLength > 0
        ? this.getterDocuments.items.findIndex((x: Document) => x.documentNumber === this.documentNumber)
        : -10; // return `-10` (not -1) to avoid extra logic in `openNextDocument` method
    return index;
  }

  // `openPreviousDocument` and `openNextDocument` behaviour copied from the `https://neu.easy-docs.de/`
  private openPreviousDocument() {
    const index = this.getIndexByDocumentNumber();
    let previousDocumentIndex = index - 1;
    if (index === 0) {
      previousDocumentIndex = this.documentItemsLength - 1; // if 1st index then open the last document as previous
    }

    this.navigateToDocument(previousDocumentIndex);
  }

  private openNextDocument() {
    const index = this.getIndexByDocumentNumber();

    let nextDocumentIndex = index + 1;
    if (index + 1 === this.documentItemsLength) {
      nextDocumentIndex = 0; // if the last documentIndex then open 1st document as next
    }

    this.navigateToDocument(nextDocumentIndex);
  }

  private navigateToDocument(index: number) {
    if (index < 0) {
      logger.error('getterDocuments is empty');
      return;
    }
    const documentId = this.getterDocuments.items[index].id;

    this.$router.push({
      name: 'document-preview',
      params: { documentId: documentId },
    });
  }
  //#endregion

  //#region : SignalR processing
  private connection: any;

  async onPreviewSaved(username: string, previewFileId: string) {
    await this.getUserFilePreview(previewFileId);

    console.error('onPreviewSaved:' + previewFileId + '------------' + username);
  }
  async onDocumentChanged(docId: string, date: any) {
    if (docId) {
      this.refreshDocument(docId);
    }
    console.error('onDocumentChanged:' + docId + '------------' + date);
  }

  onFromBackendTextExtracted(docId: string) {
    if (docId) {
      if (this.$route.name == 'document-preview') {
        const idfromRout = this.$route.params['documentId'];
        this.actionSetMetadataIsCreated().then(async () => {
          this.resetLastLoadDate();
          this.refreshDocument(idfromRout).then(() => {
            this.actionGetFilesForDocumentId(idfromRout);
          });
        });
      } else {
        this.resetLastLoadDate();
        this.refreshDocument(docId);
      }
    }
    console.error('onFromBackendTextExtracted:' + docId + '------------');
  }

  private async initSignalR() {
    if (this.isLoggedIn) {
      await this.$backendHub.startSignalR(this.account.access_token);
      this.$backendHub.addressOpened(this.account?.profile?.sub);
      this.$backendHub.OrgJoin(this.account?.profile?.current_organization_id);

      this.$backendHub.$on('document-changed', this.onDocumentChanged);
      this.$backendHub.$on('document-text-extracted', this.onFromBackendTextExtracted);
      this.$backendHub.$on('preview-saved', this.onPreviewSaved);
    }
  }

  send(name: string, message: string) {
    this.connection.send(name, message, 'tbMessage.value').then(() => {});
  }
  //#endregion

  setCardView() {
    this.setListViewMode(false);
  }

  setListView() {
    this.setListViewMode(true);
  }
}

//TODO possible add this method ti refresh action
async function loadOrganizationFolderFilesMainData() {
  // first silent sighin
  await store.dispatch('auth/signinSilent');

  const allOrganizationsAsync: Promise<any> = store.dispatch('organization/getOrganizationsAll');
  const allFoldersAsync: Promise<any> = store.dispatch('folder/getMenuFolders');
  const getDocumentsAllAsync: Promise<any> = store.dispatch('document/getDocumentsAllOrDeletedCount', {
    searchParams: { dataOption: { itemsPerPage: 0, page: 1 } },
  });
  const getDocumentsDeletedAsync: Promise<any> = store.dispatch('document/getDocumentsAllOrDeletedCount', {
    searchParams: { dataOption: { itemsPerPage: 0, page: 1 } },
    showDeleted: true,
  });
  //const loadDocumentExtraStatusSettingsData: Promise<any> = store.dispatch(
  //  'organisationDocumentExtraStatusSetting/getOrganisationDocumentExtraStatusSettings'
  //);
  const getOrganisationDocumentPropertys: Promise<any> = store.dispatch(
    'organisationDocumentProperty/getOrganisationDocumentPropertys'
  );
  const getOrganisationAllocationPropertys: Promise<any> = store.dispatch(
    'organisationAllocationProperty/getOrganisationAllocationProperties'
  );
  const getAllocationSetting: Promise<any> = store.dispatch('allocationSetting/getAllocationSettingCurrent');
  const getDatevSetting: Promise<any> = store.dispatch('datevSetting/getDatevSetting');
  const getAllocationBrands: Promise<any> = store.dispatch('allocationBrand/getAllAllocationBrands');
  const getAllocationOffices: Promise<any> = store.dispatch('allocationOffice/getAllAllocationOffices');

  await Promise.all([
    getAllocationBrands,
    getAllocationOffices,
    allOrganizationsAsync,
    allFoldersAsync,
    getDocumentsAllAsync,
    getDocumentsDeletedAsync,
    getAllocationSetting,
    //loadDocumentExtraStatusSettingsData,
    getOrganisationDocumentPropertys,
    getOrganisationAllocationPropertys,
    getDatevSetting,
  ]);
}
