import { render, staticRenderFns } from "./menu-address.html?vue&type=template&id=3b547add&scoped=true&external"
import script from "./menu-address.ts?vue&type=script&lang=ts&external"
export * from "./menu-address.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-address.scss?vue&type=style&index=0&id=3b547add&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b547add",
  null
  
)

/* custom blocks */
import block0 from "./menu-address.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-address%2Fmenu-address.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports