export interface FilterBtnData {
  value: FilterBntValues;
  docStateStatus: string;
}

export enum FilterBntValues {
  yes = 'yes',
  no = 'no',
  all = 'all',
  incomplete = 'incomplete',
  manual = 'manual',
  auto = 'auto',
}
