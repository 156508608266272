import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { format, parse } from 'date-fns';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';

//https://github.com/darrenfang/vuetify-datetime-picker/blob/master/src/components/DatetimePicker.vue
//https://github.com/darrenfang/vuetify-datetime-picker/blob/master/LICENSE
@Component({ name: 'date-time-picker', components: {} })
export default class DateTimePicker extends Vue {
  private display = false;
  private activeTab = 0;
  private date = DEFAULT_DATE;
  private time = DEFAULT_TIME;

  @Prop({ default: null, type: [Date, String] })
  private datetime!: [Date, string];

  @Prop({ default: DEFAULT_DATE_FORMAT, type: String })
  private dateFormat!: string;

  @Prop({ default: 'HH:mm', type: String })
  private timeFormat!: string;

  @Prop({ type: Object })
  private timePickerProps!: Object;

  @Ref('timer')
  private refTimer!: any;

  get dateTimeFormat() {
    return this.dateFormat + ' ' + this.timeFormat;
  }

  get defaultDateTimeFormat() {
    return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT;
  }

  formattedDatetime() {
    return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
  }

  get selectedDatetime() {
    if (!this.date) this.date = format(new Date(), DEFAULT_DATE_FORMAT);
    if (this.date && this.time) {
      let datetimeString = this.date + ' ' + this.time;
      if (this.time.length === 5) {
        datetimeString += ':00';
      }
      return parse(datetimeString, this.defaultDateTimeFormat, new Date());
    } else {
      return null;
    }
  }

  dateSelected() {
    return !this.date;
  }

  mounted() {
    this.init();
  }

  init() {
    if (!this.datetime) {
      return;
    }
    let initDateTime: number | Date = 0;
    if (this.datetime instanceof Date) {
      initDateTime = this.datetime;
    } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
      // see https://stackoverflow.com/a/9436948
      //initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
    }
    this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
    this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
  }

  okHandler() {
    this.resetPicker();
    this.$emit('input', this.selectedDatetime?.toISOString());
  }

  clearHandler() {
    this.resetPicker();
    this.date = format(new Date(), DEFAULT_DATE_FORMAT);
    this.time = DEFAULT_TIME;
    //this.$emit('input', null);
    this.$emit('input', this.selectedDatetime?.toISOString());
  }

  resetPicker() {
    this.display = false;
    this.activeTab = 0;
    if (this.$refs.timer) {
      this.refTimer.selectingHour = true;
    }
  }

  showTimePicker() {
    this.activeTab = 1;
  }

  get format() {
    if (this.$i18n.locale == 'de') return '24hr';
    return 'ampm';
  }
}
