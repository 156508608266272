export default class TimeSpanUtils {
  public static fromAPIString(timeSpan: string) {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (timeSpan != null && typeof timeSpan == 'string' && timeSpan.indexOf('PT') > -1) {
      timeSpan = timeSpan.split('PT')[1].toLowerCase();
      const hourIndex = timeSpan.indexOf('h');
      if (hourIndex > -1) {
        hours = parseInt(timeSpan.slice(0, hourIndex));
        timeSpan = timeSpan.substring(hourIndex + 1);
      }

      const minuteIndex = timeSpan.indexOf('m');
      if (minuteIndex > -1) {
        minutes = parseInt(timeSpan.slice(0, minuteIndex));
        timeSpan = timeSpan.substring(minuteIndex + 1);
      }

      const secondIndex = timeSpan.indexOf('s');
      if (secondIndex > -1) seconds = parseInt(timeSpan.slice(0, secondIndex));
    }
    return [`${hours}`.padStart(2, '0'), `${minutes}`.padStart(2, '0'), `${seconds}`.padStart(2, '0')].join(':');
  }
  public static toAPIString(timeSpan: string) {
    const [hours, minutes, seconds]: string[] = timeSpan.split(':');
    const result: string[] = ['PT'];
    if (+hours) result.push(`${hours}H`);
    if (+minutes) result.push(`${minutes}M`);
    if (+seconds) result.push(`${seconds}S`);
    if (result.length == 1) return undefined;
    return result.join('');
  }
  //min: 00:00:00 max: 23:59:59
  public static Validation(v: string) {
    return v && v.trim().length == 8 && /^([0-9]|0[0-9]|1[0-9]|2[0-3])\:[0-5][0-9]\:[0-5][0-9]$/.test(v);
  }
}
