import { GetterTree } from 'vuex';
import { GobdDataState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GobdDataState, RootState> = {
  ['getGobdDatas']: (state) => state.gobdDatas,
  ['getGobdDatasIsLoading']: (state) => state.gobdDatas?.isLoading,
  ['getGobdDatasTotal']: (state) => state.gobdDatas?.total,
  ['getGobdDatasSearchParams']: (state) => state.gobdDatas?.searchParams,
};
