import { GetterTree } from 'vuex';
import { TemplateMarkupState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TemplateMarkupState, RootState> = {
  ['getTemplateMarkups']: (state) => state.templateMarkups,
  ['getTemplateMarkupsIsLoading']: (state) => state.templateMarkups?.isLoading,
  ['getTemplateMarkupsTotal']: (state) => state.templateMarkups?.total,
  ['getTemplateMarkupsSearchParams']: (state) => state.templateMarkups?.searchParams,
  ['getIsTemplateMode']: (state) => state.isTemplateMode,
  ['getTemplateMarkupsScale']: (state) => state.scale,
  ['getLastRecognizedTextInBox']: (state) => state.lastRecognizedTextInBox,
};
