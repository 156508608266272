export default class JsObjectUtils {
  /**
   * Merges object values in keys in one object and return array of objects
   * @param {object} object
   * @returns {array} array of objects [{}, ..., {}]
   */
  public static mergeObjKeysAndValues(obj: any): any[] {
    const result: any[] = [];
    const objKeys = Object.keys(obj); //
    objKeys.forEach((key) => {
      const newObj = obj[key];
      newObj['key'] = key;
      result.push(newObj);
    });
    return result;
  }

  /**
   * Check if object is Empty (// fastest approach to check `EmptyObject` according to https://stackoverflow.com/a/59787784/2771704 (2020-01))
   * @param {object} object
   * @returns {boolean} is object Empty (returns TRUE) or NOT (return FALSE)
   */
  public static isEmptyObject(object: any) {
    for (const i in object) return false;
    return true;
  }
  public static getFormData = (object: any) =>
    Object.keys(object).reduce((formData, key) => {
      if (object[key] !== undefined) formData.append(key, object[key]);
      return formData;
    }, new FormData());
}
