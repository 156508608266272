import { Folder } from '@/shared/model/folder';
import { address } from './../address/index';
import { ActionTree } from 'vuex';
import { DocumentState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocument } from '@/shared/backend/document';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import ds, { Value, Document } from '@/shared/model/document';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { FilterBtnData } from '@/shared/model/smallPayloadModels/filterBtnData';
import { Comment } from '@/shared/model/comment';
import { CONST } from '@/shared/utils/Constants';
import { GobdData } from '@/shared/model/gobdData';
import doucmentProperty, { DocumentProperty, Value as DocumentPropertyValue } from '@/shared/model/documentProperty';
import documentRoleRight, {
  DocumentRoleRight,
  Value as DocumentRoleRightValue,
} from '@/shared/model/documentRoleRight';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';

const logger = new Logger('actions.document');
export const actions: ActionTree<DocumentState, RootState> = {
  getDocument({ commit, dispatch }, id: string) {
    commit('setDocumentIsLoading', true);
    return defaultBackendDocument
      .getDocument(id)
      .then((response: AxiosResponse<Document>) => {
        const parsedResponse = ds.parse(response.data);
        commit('setDocument', parsedResponse);
        // commit('setDocumentInDocumentsState', parsedResponse);
        // commit('setDocumentsGobd', parsedResponse.documentGobdDatum);
        // commit('setDocumentsComments', {
        //   id: parsedResponse.id,
        //   documentDetailComments: parsedResponse.documentDetailComments,
        // });
        // commit('setDocumentsActionLogs', {
        //   id: parsedResponse.id,
        //   actionLogs: parsedResponse.actionLogs,
        // });
        // commit('removeDocumentsNeedUpdate', parsedResponse.id);
        // commit('setDocumentsAllocation', parsedResponse);
        // commit('setDocumentsAddress', { address: parsedResponse.address, documentIds: [parsedResponse.id] });
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDocumentIsLoading', false);
      });
  },
  refreshDocument({ commit, dispatch, getters }, id: string) {
    commit('setDocumentIsLoading', true);
    return defaultBackendDocument
      .getDocument(id)
      .then((response: AxiosResponse<Document>) => {
        const parsedResponse = ds.parse(response.data);
        if (id == getters.getDocument.id) commit('setDocument', parsedResponse);
        commit('setDocumentTemp', parsedResponse);
        commit('setDocumentInDocumentsState', parsedResponse);
        commit('setDocumentsGobd', parsedResponse.documentGobdDatum);
        commit('setDocumentsComments', {
          id: parsedResponse.id,
          documentDetailComments: parsedResponse.documentDetailComments,
        });
        commit('setDocumentsActionLogs', {
          id: parsedResponse.id,
          actionLogs: parsedResponse.actionLogs,
        });
        commit('removeDocumentsNeedUpdate', parsedResponse.id);
        commit('setDocumentsAllocation', parsedResponse);
        commit('setDocumentsAddress', { address: parsedResponse.address, documentIds: [parsedResponse.id] });
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDocumentIsLoading', false);
      });
  },

  documentExists({ commit, dispatch }, id: string) {
    return defaultBackendDocument.documentExists(id).then((response: AxiosResponse<boolean>) => {
      return response.data;
    });
  },

  updateDocumentInState({ commit, dispatch }, document: Document) {
    commit('setDocument', document);
  },
  updateDocumentNotesInState({ commit, dispatch }, comments: Comment) {
    commit('setDocumentNotes', comments);
  },
  updateDocumentGobdInState({ commit, dispatch }, gobd: GobdData) {
    commit('setDocumentGobd', gobd);
  },

  updateSearchModes({ commit, dispatch }, searchModes: string) {
    commit('setSearchModes', searchModes);
  },

  getFacetSearchResult(
    { commit, dispatch },
    payload: {
      word: string;
      showDeleted: boolean;
      searchType: string;
      searchModes: string[];
      searchFolderIds?: string;
      chipData?: string;
    }
  ) {
    const showDeleted = payload?.showDeleted ?? false;
    return defaultBackendDocument
      .getFacetSearchResult(
        payload.word,
        showDeleted,
        payload.searchType,
        payload.searchModes,
        payload.searchFolderIds,
        payload.chipData
      )
      .then((response: AxiosResponse<any>) => {
        logger.log('response :>> ', response);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  // (ED-226) for `loadMenuFolders` action in `Folders` module to fix empty list bug
  getDocumentsCount(
    { commit, dispatch, getters },
    payload: { searchParams: any; showDeleted: boolean; folderId: string }
  ) {
    const showDeleted = payload?.showDeleted ?? false;
    commit('makeGetDocumentsSearchUrl', { showDeleted: showDeleted, searchParams: payload.searchParams });
    return defaultBackendDocument
      .getDocuments(getters.documentsSearchUrl)
      .then((response: AxiosResponse<Value>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  async getDocuments({ commit, dispatch, getters }): Promise<Value> {
    commit('setDocumentsIsLoading', true);
    try {
      const documentsSearchPayload = getters.documentsSearchPayload;
      documentsSearchPayload.searchParams.dataOption.sortBy.forEach((item: string, i: number) => {
        documentsSearchPayload.searchParams.dataOption.sortBy[
          i
        ] = documentsSearchPayload.searchParams.dataOption.sortBy[i]
          .replace('.', '/')
          .replace('documentGobdDatum_invoiceDate', 'documentGobdDatum/invoiceDate')
          .replace('documentStates', 'documentStates/Name')
          .replace('informations', 'documentPropertys/Value');
      });
      commit('makeGetDocumentsSearchUrl', documentsSearchPayload);
      //console.log(getters.documentsSearchUrl);

      if (
        isActualDocumentCash(getters.documentsSearchUrl, getters.documentsSearchUrlOld, getters.lastLoadDocumentsDate)
      ) {
        //console.log(`Frontend CACHE is load uri:${getters.documentsSearchUrl} time: ${getters.lastLoadDocumentsDate}`);
      } else {
        const response = await defaultBackendDocument.getDocuments(getters.documentsSearchUrl);
        const promiseAll: any[] = [];
        const countRecords: number = +(response.data['@odata.count'] ?? 0);
        const baaseGetCount =
          countRecords > CONST.DocumentInfoInitialLoadCount ? CONST.DocumentInfoInitialLoadCount : countRecords;
        response.data.value
          .map((x) => x.previewFileId)
          .slice(0, baaseGetCount)
          .forEach(async (item) => {
            promiseAll.push(dispatch('userFile/getUserFilePreview', item, { root: true }));
          });
        commit('setDocumentsTemp', response.data);
        commit('updateLastLoadDate', response.data);
        // console.log(`Frontend CACHE is load uri:${getters.documentsSearchUrl} time: ${getters.lastLoadDocumentsDate}`);
        //preload first 12 document imagePreview
        await Promise.all(promiseAll);
      }
      commit('setTempToReal', false);
      commit('clearDocumentsNeedUpdate', false);
      commit('setDocumentsIsLoading', false);
      return getters.documents;
    } catch (e) {
      commit('setDocumentsIsLoading', false);
      module_utils.error('error', commit, e, logger);
      throw e;
    }
  },
  getDocumentProperties({ commit, getters }, documentId: string) {
    commit('pushToDocumentIdsForWhichDocumentPropertiesIsLoading', documentId);
    const searchParams = getters.getDocumentPropertiesSearchParams?.searchParams;
    return defaultBackendDocument
      .getDocumentProperties(documentId, searchParams)
      .then((response: AxiosResponse<DocumentPropertyValue>) => {
        const data = response.data.value.map((value) => doucmentProperty.parse(value));
        commit('setDocumentProperties', data);
        return data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('popFromDocumentIdsForWhichDocumentPropertiesIsLoading', documentId);
      });
  },
  async getDocumentFoldersCounts({ commit, dispatch }, payload: any) {
    await dispatch('folder/getFoldersAll', {}, { root: true });
    await dispatch(
      'document/getDocumentsAllOrDeletedCount',
      { searchParams: { dataOption: { itemsPerPage: 0, page: 1 } }, showDeleted: true },
      { root: true }
    );
    await dispatch(
      'document/getDocumentsAllOrDeletedCount',
      { searchParams: { dataOption: { itemsPerPage: 0, page: 1 } }, showDeleted: false },
      { root: true }
    );
  },

  // (ED-1041) when use previous `getDocumentsAll/getDocumentsAllDeleted` actions in `loadOrganizationFolderFilesMainData` from `document-list` then `state.documentsAll.items` rewritten for a second with `EMPTY` array (which creates flashing/blinking effect), here we just update the `count` (`total`) for the `DocumentsAll/Deleted` as was intended in  `loadOrganizationFolderFilesMainData` function
  getDocumentsAllOrDeletedCount(
    { commit, dispatch, getters },
    payload?: {
      searchParams?: any;
      folderId?: string;
      showDeleted: boolean;
    }
  ) {
    const searchParams = payload?.searchParams ?? getters.getDocumentsDeletedSearchParams;
    const showDeleted = payload?.showDeleted ?? false;
    commit('makeGetDocumentsSearchUrl', { showDeleted: showDeleted, searchParams: searchParams });
    return defaultBackendDocument
      .getDocuments(getters.documentsSearchUrl)
      .then((response: AxiosResponse<Value>) => {
        logger.log('getDocumentsAllOrDeletedCount :>> ', response.data['@odata.count']);
        if (showDeleted) {
          commit('setDocumentsDeletedTotal', response.data['@odata.count']);
        } else {
          commit('setDocumentsAllCount', response.data['@odata.count']);
        }
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  // TODO: (ED-246) probably later redo to just query `Fullpath` instead of many children `folderId`
  getDocumentsWithChildren(
    { commit, dispatch, getters, rootGetters },
    payload: { searchParams?: any; folderId: string; showDeleted: boolean }
  ) {
    commit('setDocumentsIsLoading', true);

    return defaultBackendDocument
      .getDocumentsWithChildren(
        getters.documentsSearchPayload.searchParams,
        getters.documentsSearchPayload.folderId,
        getters.documentsSearchPayload.isOnlyCurrentFolderDocs
      )
      .then(async (response: AxiosResponse<Value>) => {
        commit('setDocuments', response.data);
        commit('setDocumentsIsLoading', false);
        await dispatch('document/getDocumentFoldersCounts', {}, { root: true });
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentIdByPreviewFileId({ getters }, previewFileId: string) {
    const index = getters.getDocuments.items.findIndex((x: Document) => x.previewFileId === previewFileId);
    if (index >= 0) {
      const id = getters.getDocuments.items[index].id;

      return id;
    }
    return '';
  },
  getDocumentsDeleted({ commit, dispatch, getters }, payload?: { searchParams?: any; folderId?: string }) {
    commit('setDocumentsDeletedIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentsDeletedSearchParams;
    const showDeleted = true;
    commit('makeGetDocumentsSearchUrl', {
      showDeleted: showDeleted,
      searchParams: searchParams,
      folderId: payload?.folderId,
    });
    return defaultBackendDocument
      .getDocuments(getters.documentsSearchUrl)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentsDeleted', response.data);
        commit('setDocumentsDeletedIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentsDeletedIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDocument({ commit, dispatch }, file: Document) {
    commit('setUpdateInProgress', true);
    return defaultBackendDocument
      .updateDocument(file)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.document_status_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUpdateInProgress', false);
      });
  },
  updateDocumentFolder({ commit, dispatch, getters }, payload: { file: Document; toFolder: Folder }) {
    commit('setUpdateInProgress', true);
    return defaultBackendDocument
      .updateDocument(payload.file)
      .then((response) => {
        commit(
          'auth/setHeaderNavData',
          {
            translateId: payload.toFolder.fullPath,
            to: `/dashboard/organization/${payload.toFolder.organisationId}/${payload.toFolder.id}`,
          },
          { root: true }
        );
        //update filter for get documents
        getters.documentsSearchPayload.folderId = payload.toFolder.id;
        module_utils.ok(i18n.tc(`success.document_folder_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUpdateInProgress', false);
      });
  },
  updateDocumentsFolder({ commit, dispatch, getters }, payload: { documentsIds: string[]; toFolder: Folder }) {
    commit('setUpdateInProgress', true);
    return defaultBackendDocument
      .documentsMoveToFolder(payload.documentsIds, payload.toFolder.id)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.document_folder_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUpdateInProgress', false);
      });
  },
  updateDocuments({ commit, dispatch }, payload: { documentIdsWithData: any; document: Document }) {
    return defaultBackendDocument
      .updateDocuments(payload.documentIdsWithData, payload.document)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.document_status_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentInformationMultiple(
    { commit, dispatch, rootGetters },
    payload: { documentIdsWithData: any; document: Document; documentPropertys: DocumentProperty[] }
  ) {
    const items = payload.documentPropertys;
    return defaultBackendDocument
      .updateDocumentInformationMultiple(payload.documentIdsWithData, payload.document, payload.documentPropertys)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.document_status_update`), commit);
        commit('setDocumentsProperties', {
          items,
          anabledFavoritePropertysIds: rootGetters[
            'organisationDocumentProperty/getOrganisationDocumentPropertys'
          ].items.filter((prop: OrganisationDocumentProperty) => prop.enabled == true && prop.favorite == true),
        });
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDeletedStatus(
    { commit, dispatch, rootGetters },
    payload: { documentIds: string[]; isRecover?: boolean; deleteAfter?: string }
  ) {
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendDocument
      .updateDocumentDeletedStatus(payload.documentIds, organizationId, payload.isRecover, payload.deleteAfter)
      .then((response) => {
        if (payload.isRecover) {
          module_utils.ok(i18n.tc(`success.restore_document_from_bin`), commit);
        } else {
          if (payload.deleteAfter != '') module_utils.ok(i18n.tc(`success.document_move_to_bin_flagged`), commit);
          else module_utils.ok(i18n.tc(`success.document_move_to_bin`), commit);
        }
        commit('resetLastLoadDate');
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  deleteDocument({ commit, dispatch }, id: string) {
    return defaultBackendDocument
      .deleteDocument(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocuments({ commit, dispatch }, documentIds: Array<string>) {
    return defaultBackendDocument
      .deleteDocuments(documentIds)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.documents_deleted`), commit);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  rescanDocument({ commit, dispatch }, payload: { id: string; textOrPreview: number }) {
    return defaultBackendDocument
      .rescanDocument(payload.id, payload.textOrPreview)
      .then((response: any) => {
        if (payload.textOrPreview === 1) {
          module_utils.ok(i18n.tc(`success.document_rescan`), commit);
        }
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  removeDeleteAfter({ commit, dispatch }, payload: { id: string }) {
    return defaultBackendDocument
      .removeDeleteAfter(payload.id)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.document_remove_delete_after`), commit);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  rescanDocumentMultiple(
    { commit, dispatch, rootGetters },
    payload: { textOrPreview: number; documentsIds: string[] }
  ) {
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocument
      .rescanDocumentMultiple(organizationId, payload.textOrPreview, payload.documentsIds)
      .then((response: any) => {
        if (payload.textOrPreview === 1) {
          module_utils.ok(i18n.tc(`success.document_rescan`), commit);
        }
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  checkDocumentDuplicates({ commit, dispatch }, documentIds: any) {
    return defaultBackendDocument
      .checkDocumentDuplicates(documentIds)
      .then((response: any) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDocumentInDocumentListState({ commit, dispatch }, document: Document) {
    commit('setDocumentInDocumentsState', document);
  },

  createMetadata({ commit, dispatch }, payload: { id: string; timezoneOffet: number }) {
    commit('setIsMetadataProcessing', true);
    return defaultBackendDocument
      .createMetadata(payload.id, payload.timezoneOffet)
      .then((response: any) => {})
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  setMetadataIsCreated({ commit, dispatch }) {
    commit('setIsMetadataProcessing', false);
  },

  merge({ commit, dispatch }, payload: { sourceIds: string[]; destinationId: string; asNewDocument: boolean }) {
    return defaultBackendDocument
      .merge(payload.sourceIds, payload.destinationId, payload.asNewDocument)
      .then((response: any) => {})
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  split(
    { commit, dispatch },
    payload: {
      pages: string[];
      deleteSource: boolean;
      onlyCreateDublicate: boolean;
      documentNumber: string;
      fileId: string;
      folderId: string;
    }
  ) {
    return defaultBackendDocument
      .split(
        payload.pages,
        payload.deleteSource,
        payload.onlyCreateDublicate,
        payload.documentNumber,
        payload.fileId,
        payload.folderId
      )
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.document_split`), commit);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  unmerge({ commit, dispatch }, payload: { documentIds: string[]; sourceId: string }) {
    return defaultBackendDocument
      .unmerge(payload.documentIds, payload.sourceId)
      .then((response: any) => {})
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  async getDocumentRoleRights(
    { commit, dispatch, getters },
    payload: { documentId: string }
  ): Promise<DocumentRoleRightValue> {
    commit('setDocumentRoleRightsIsLoading', true);
    return await defaultBackendDocument
      .getDocumentRoleRights(payload.documentId, getters.getDocumentRoleRights.searchParams)
      .then((response: AxiosResponse<DocumentRoleRightValue>) => {
        commit('setDocumentRoleRights', response.data);
        commit('setDocumentRoleRightsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDocumentRoleRightsIsLoading', false);
      });
  },
};
function isActualDocumentCash(
  documentsSearchUrl: string,
  documentsSearchUrlOld: string,
  lastLoadDocumentsDate: Date
): boolean {
  const cachingTime30minutes = 30 * 60 * 1000;
  const nowDate = new Date();
  if (documentsSearchUrl != documentsSearchUrlOld) return false;
  if (lastLoadDocumentsDate.getTime() + cachingTime30minutes < nowDate.getTime()) {
    console.log(`== ${lastLoadDocumentsDate.getTime()} + ${cachingTime30minutes} < ${nowDate.getTime()}`);
    return false;
  }
  console.log(`== ${lastLoadDocumentsDate.getTime()} + ${cachingTime30minutes} > ${nowDate.getTime()}`);
  return true;
}
