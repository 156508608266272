import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import costObjectiveImportSetting, { CostObjectiveImportSetting, Value } from '../model/costObjectiveImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.CostObjectiveImportSetting');
export interface BackendCostObjectiveImportSetting {
  getCostObjectiveImportSetting(id: string): AxiosPromise<CostObjectiveImportSetting>;
  getCostObjectiveImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteCostObjectiveImportSetting(id: string): AxiosPromise;
  updateCostObjectiveImportSetting(CostObjectiveImportSetting: CostObjectiveImportSetting): AxiosPromise<any>;
}

export const defaultBackendCostObjectiveImportSetting: BackendCostObjectiveImportSetting = {
  getCostObjectiveImportSetting(id: string): AxiosPromise<CostObjectiveImportSetting> {
    const url = `${URLS.costObjectiveImportSettingOdata}/${id}`;
    return instance.get<CostObjectiveImportSetting>(url);
  },
  getCostObjectiveImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.costObjectiveImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getCostObjectiveImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteCostObjectiveImportSetting(id: string): AxiosPromise {
    logger.debug('deleteCostObjectiveImportSetting');
    return instance.delete(`${URLS.costObjectiveImportSetting}/${id}`);
  },
  updateCostObjectiveImportSetting(CostObjectiveImportSetting: CostObjectiveImportSetting): AxiosPromise<any> {
    logger.debug('updateCostObjectiveImportSetting');
    return instance.put<CostObjectiveImportSetting>(
      `${URLS.costObjectiveImportSetting}/update`,
      costObjectiveImportSetting.toAPI(CostObjectiveImportSetting)
    );
  },
};
