import { ActionTree } from 'vuex';
import { ProcessListsExportState } from './types';
import { RootState } from '../../types';
import { defaultBackendProcessListsExport } from '@/shared/backend/processListsExport';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import processListsExport, { Value, ProcessListsExport } from '@/shared/model/processListsExport';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.processListsExports');
export const actions: ActionTree<ProcessListsExportState, RootState> = {
  getProcessListsExports({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setProcessListsExportsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getProcessListsExportsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendProcessListsExport
      .getProcessListsExports(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setProcessListsExports', response.data);
        commit('setProcessListsExportsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setProcessListsExportsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getProcessListsExport({ commit, dispatch }, id: string) {
    return defaultBackendProcessListsExport
      .getProcessListsExport(id)
      .then((response: AxiosResponse<ProcessListsExport>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateProcessListsExport({ commit, dispatch }, file: ProcessListsExport) {
    return defaultBackendProcessListsExport
      .updateProcessListsExport(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ProcessListsExport;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.processListsExport_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteProcessListsExport({ commit, dispatch }, id: string) {
    return defaultBackendProcessListsExport
      .deleteProcessListsExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ProcessListsExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
