import { MutationTree } from 'vuex';
import { FolderRuleState } from './types';
import Vue from 'vue';
import folderRule, { FolderRule, Value } from '@/shared/model/folderRule';

export const mutations: MutationTree<FolderRuleState> = {
  setFolderRules(state, payload: Value) {
    state.folderRules.items = payload.value.map((x) => folderRule.parse(x));
    state.folderRules.total = payload['@odata.count'] || 0;
    state.folderRules.isLoading = false;
  },
  setFolderRulesTotal(state, payload: number) {
    state.folderRules.total = payload;
  },
  setFolderRulesIsLoading(state, payload: boolean) {
    state.folderRules.isLoading = payload;
  },
};
