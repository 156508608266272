import TimeSpanUtils from '../utils/TimeSpanUtil';

export interface RoleLoginTime {
  id: string; // ulong
  roleId: string; // string
  organisationId: string; // string
  weekDay: string; // string
  fromTime: string; // System.TimeSpan
  toTime: string; // System.TimeSpan
  allowed: boolean; // bool?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: RoleLoginTime[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<RoleLoginTime>): any {
  const result: any = {
    Id: data?.id || undefined,
    RoleId: data?.roleId || undefined,
    OrganisationId: data?.organisationId || undefined,
    WeekDay: data?.weekDay || '',
    FromTime: data?.fromTime || undefined, // TimeSpanUtils.toAPIString(data?.fromTime || ''),
    ToTime: data?.toTime || undefined, // TimeSpanUtils.toAPIString(data?.toTime || ''),
    Allowed: data?.allowed || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
  if (result.Id) result['Id@odata.type'] = '#Guid';
  if (result.RoleId) result['RoleId@odata.type'] = '#Guid';
  if (result.OrganisationId) result['OrganisationId@odata.type'] = '#Guid';
  if (result.FromTime) result['FromTime@odata.type'] = '#TimeOfDay';
  if (result.ToTime) result['ToTime@odata.type'] = '#TimeOfDay';

  return result;
}

function parse(data?: Partial<RoleLoginTime>): RoleLoginTime {
  return {
    id: data?.id || '',
    roleId: data?.roleId || '',
    organisationId: data?.organisationId || '',
    weekDay: data?.weekDay || '',
    fromTime: TimeSpanUtils.fromAPIString(data?.fromTime || ''),
    toTime: TimeSpanUtils.fromAPIString(data?.toTime || ''),
    allowed: data?.allowed || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
