export interface User {
  iKnowThatUserExist?: boolean;
  id: string; // ulong
  userId: string; // string
  mainOrganisationId: string; // string
  email: string; // string
  username: string; // string
  password: string; // string
  emailToken: string; // string
  pwdReset: string; // string
  reloginPassword: string; // string
  access: string; // string
  firstName: string; // string
  lastName: string; // string
  kurzform: string; // string
  language: string; // string
  theme: string; // string
  gender: string; // string
  street: string; // string
  zipCode: string; // string
  locale: string; // string
  country: string; // string
  kundenNummer: number; // int
  loginTimeStamp: number; // int
  userType: number; // int
  accepted: boolean; // bool
  banned: boolean; // bool
  rememberToken: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  deleted: boolean; // bool
  disabled: boolean; // bool
  simulateUserId: string; // string
  isSeller: boolean; // bool
  sessionTime: string; // System.DateTime?
  personalNumber: string; // System.DateTime?
  userRoles: { id: string; roleId: string; userId: string }[]; //UserRole
  phoneNumber: string;
  pdfZoom: string;
}

export interface Value {
  value: User[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<User>): any {
  const x: any = {
    //for correct parce guid field value from string in json format
  };
  if (data?.email) x.Email = data?.email || '';
  if (data?.firstName !== undefined) x.FirstName = data?.firstName || '';
  if (data?.lastName !== undefined) x.LastName = data?.lastName || '';
  if (data?.phoneNumber !== undefined) x.phoneNumber = data?.phoneNumber || '';
  if (data?.kurzform !== undefined) x.Kurzform = data?.kurzform || '';
  if (data?.password) x.Password = data?.password || ''; // for register user with Password
  if (data?.language) x.Language = data?.language || '';
  if (data?.theme) x.Theme = data?.theme || '';
  if (data?.kundenNummer) x.KundenNummer = data?.kundenNummer || 0;
  if (data?.personalNumber) x.PersonalNumber = data?.personalNumber || '';
  if (data?.pdfZoom) x.pdfZoom = data?.pdfZoom || '';

  if (data?.simulateUserId) x.SimulateUserId = data?.simulateUserId;
  if (data?.userId) {
    x['UserId@odata.type'] = '#Guid';
    x.UserId = data?.userId;
  }
  if (data?.id) {
    x['Id@odata.type'] = '#Guid';
    x.Id = data?.id;
  }
  if (data?.mainOrganisationId) {
    x['MainOrganisationId@odata.type'] = '#Guid';
    x.MainOrganisationId = data?.mainOrganisationId;
  }
  if (data?.createdAt) x.CreatedAt = data?.createdAt;
  if (data?.updatedAt) x.UpdatedAt = data?.updatedAt;
  if (data?.sessionTime) x.SessionTime = data?.sessionTime;

  if (data?.deleted != undefined) x.Deleted = data?.deleted;
  if (data?.disabled != undefined) x.Disabled = data?.disabled;
  if (data?.iKnowThatUserExist != undefined) x.IKnowThatUserExist = data?.iKnowThatUserExist;

  return x;
}

function parse(data?: Partial<User>): User {
  return {
    id: data?.id || '',
    userId: data?.userId || data?.id || '',
    mainOrganisationId: data?.mainOrganisationId || '',
    email: data?.email || '',
    username: data?.username || '',
    password: data?.password || '',
    emailToken: data?.emailToken || '',
    pwdReset: data?.pwdReset || '',
    reloginPassword: data?.reloginPassword || '',
    access: data?.access || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    kurzform: data?.kurzform || '',
    language: data?.language || '',
    theme: data?.theme || '',
    gender: data?.gender || '',
    street: data?.street || '',
    zipCode: data?.zipCode || '',
    locale: data?.locale || '',
    country: data?.country || '',
    kundenNummer: data?.kundenNummer || 0,
    loginTimeStamp: data?.loginTimeStamp || 0,
    userType: data?.userType || 0,
    accepted: data?.accepted || false,
    banned: data?.banned || false,
    rememberToken: data?.rememberToken || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    deleted: data?.deleted || false,
    disabled: data?.disabled || false,
    simulateUserId: data?.simulateUserId || '',
    isSeller: data?.isSeller || false,
    sessionTime: data?.sessionTime || '',
    personalNumber: data?.personalNumber || '',
    userRoles: data?.userRoles || [],
    phoneNumber: data?.phoneNumber || '',
    pdfZoom: data?.pdfZoom || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
