import { ActionTree } from 'vuex';
import { AddressesImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAddressesImportSetting } from '@/shared/backend/addressesImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import addressesImportSetting, { Value, AddressesImportSetting } from '@/shared/model/addressesImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.addressesImportSettings');
export const actions: ActionTree<AddressesImportSettingState, RootState> = {
  getAddressesImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAddressesImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAddressesImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAddressesImportSetting
      .getAddressesImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAddressesImportSettings', response.data);
        commit('setAddressesImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAddressesImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddressesImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setAddressesImportSettingsIsLoading', true);
    const searchParams = getters.getAddressesImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAddressesImportSetting
      .getAddressesImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAddressesImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAddressesImportSettingsIsLoading', false);
      });
  },
  getAddressesImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendAddressesImportSetting
      .getAddressesImportSetting(id)
      .then((response: AxiosResponse<AddressesImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAddressesImportSetting({ commit, dispatch, rootGetters }, file: AddressesImportSetting) {
    commit('setInProgressUpdateAddressesImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAddressesImportSetting
      .updateAddressesImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AddressesImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.addressesImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateAddressesImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getAddressesImportSetting.clearBeforeImport = payload.value;
    dispatch('updateAddressesImportSetting', getters.getAddressesImportSetting)
      .then((result: any) => {
        getters.getAddressesImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getAddressesImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteAddressesImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendAddressesImportSetting
      .deleteAddressesImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AddressesImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
