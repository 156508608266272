import { GetterTree } from 'vuex';
import { ActionLogState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ActionLogState, RootState> = {
  ['getActionLogs']: (state) => state.actionLogs,
  ['getActionLogsIsLoading']: (state) => state.actionLogs?.isLoading,
  ['getActionLogsTotal']: (state) => state.actionLogs?.total,
  ['getActionLogsSearchParams']: (state) => state.actionLogs?.searchParams,

  //(ED-892) separate getters for QA(Questions and answers)[DE= Fragen & Antworten ]
  ['getThreadMessages']: (state) => state.threadMessages,
  //(ED-892) separate getters for QA(Questions and answers)[DE= Fragen & Antworten ]
  ['getActionLogsQa']: (state) => state.actionLogsQa,
  ['getActionLogsQaIsLoading']: (state) => state.actionLogsQa?.isLoading,
  ['getActionLogsQaTotal']: (state) => state.actionLogsQa?.total,
  ['getActionLogsQaSearchParams']: (state) => state.actionLogsQa?.searchParams,

  //(ED-363)
  ['getActionLogsQaOverview']: (state) => state.actionLogsQaOverview,
  ['getActionLogsQaOverviewTotal']: (state) => state.actionLogsQaOverview?.total,
  ['getActionLogsQaOverviewIsLoading']: (state) => state.actionLogsQaOverview?.isLoading,
  ['getActionLogQaUsers']: (state) => state.actionLogQaUsers,
  ['getActionLogQaUsersTotal']: (state) => state.actionLogQaUsers?.total,
  ['getActionLogQaUsersIsLoading']: (state) => state.actionLogQaUsers?.isLoading,
};
