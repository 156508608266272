import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationDocumentExtraStatusSetting, {
  OrganisationDocumentExtraStatusSetting,
  Value,
} from '../model/organisationDocumentExtraStatusSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.OrganisationDocumentExtraStatusSetting');
export interface BackendOrganisationDocumentExtraStatusSetting {
  getFoldersByUrl(url: string): AxiosPromise<Value>;
  getOrganisationDocumentExtraStatusSetting(id: string): AxiosPromise<OrganisationDocumentExtraStatusSetting>;
  getOrganisationDocumentExtraStatusSettings: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteOrganisationDocumentExtraStatusSetting(id: string): AxiosPromise;
  updateOrganisationDocumentExtraStatusSetting(
    OrganisationDocumentExtraStatusSetting: OrganisationDocumentExtraStatusSetting
  ): AxiosPromise<any>;
}

export const defaultBackendOrganisationDocumentExtraStatusSetting: BackendOrganisationDocumentExtraStatusSetting = {
  getFoldersByUrl(url: string): AxiosPromise<Value> {
    return instance.get<Value>(DefaultBackendHelper.removeUrlAppendix(url));
  },
  getOrganisationDocumentExtraStatusSetting(id: string): AxiosPromise<OrganisationDocumentExtraStatusSetting> {
    const url = `${URLS.organisationDocumentExtraStatusSettingOdata}/${id}`;
    return instance.get<OrganisationDocumentExtraStatusSetting>(url);
  },
  getOrganisationDocumentExtraStatusSettings(searchParams: SearchParams): AxiosPromise<Value> {
    //TODO make cashing
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationDocumentExtraStatusSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getOrganisationDocumentExtraStatusSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteOrganisationDocumentExtraStatusSetting(id: string): AxiosPromise {
    logger.debug('deleteOrganisationDocumentExtraStatusSetting');
    return instance.delete(`${URLS.organisationDocumentExtraStatusSetting}/${id}`);
  },
  updateOrganisationDocumentExtraStatusSetting(
    OrganisationDocumentExtraStatusSetting: OrganisationDocumentExtraStatusSetting
  ): AxiosPromise<any> {
    logger.debug('updateOrganisationDocumentExtraStatusSetting');
    return instance.put<OrganisationDocumentExtraStatusSetting>(
      `${URLS.organisationDocumentExtraStatusSetting}/update`,
      organisationDocumentExtraStatusSetting.toAPI(OrganisationDocumentExtraStatusSetting)
    );
  },
};
