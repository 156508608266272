import { Component, Prop, PropSync, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import allocationTemplateProperty, { AllocationTemplateProperty } from '@/shared/model/allocationTemplateProperty';
import { AllocationPropertyEx } from '@/shared/model/allocation';
import allocationTemplateEx, { AllocationTemplateEx } from '@/shared/model/allocationTemplateEx';
import supplier, { Supplier } from '@/shared/model/supplier';
import { CostCentre } from '@/shared/model/costCentre';
import { CostObjective } from '@/shared/model/costObjective';
import { TradeChannel } from '@/shared/model/tradeChannel';
import { OrganisationAllocationProperty } from '@/shared/model/organisationAllocationProperty';
import NumberField from '@/components/number-field/number-field.vue';
import DateField from '@/components/date-field/date-field.vue';
import DateUtils from '@/shared/utils/DateUtils';
import { AllocationOffice } from '@/shared/model/allocationOffice';
import { AllocationBrand } from '@/shared/model/allocationBrand';
import { buildOnEnter } from '@/shared/utils/formUtils';

const name = 'account-template-edit-component';
const logger = new Logger(name);
const supplierModule = namespace('supplier');
const allocationModule = namespace('allocation');
const allocationTaxSettingModule = namespace('allocationTaxSetting');
const allocationOfficeModule = namespace('allocationOffice');
const allocationBrandModule = namespace('allocationBrand');
const costCentreModule = namespace('costCentre');
const costObjectiveModule = namespace('costObjective');
const tradeChannelModule = namespace('tradeChannel');
const organisationAllocationPropertyModule = namespace('organisationAllocationProperty');

@Component({
  name: name,
  components: { NumberField, DateField },
})
export default class AccountTemplateEditComponent extends Vue {
  @PropSync('value', { default: () => allocationTemplateEx.parse({}) })
  private model!: AllocationTemplateEx;
  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationPropertiesActiveItems')
  private organisationAllocationPropertiesActiveItems!: OrganisationAllocationProperty[];

  private accountSupplier?: Supplier = supplier.parse({});
  get getAccountSupplier() {
    return this.accountSupplier;
  }
  @supplierModule.Action('getAccountSupplierByNameVithoutCommit')
  private actionGetAccountSupplierByName?: any;

  private contraAccountSupplier?: Supplier = supplier.parse({});
  get getContraAccountSupplier() {
    return this.contraAccountSupplier;
  }
  @supplierModule.Action('getContraAccountSupplierByNameWithoutCommit')
  private actionGetContraAccountSupplierByName?: any;

  @allocationTaxSettingModule.Getter('getAllocationTaxSettingsAllForCombo')
  private getAllocationTaxSettings?: any;
  @allocationTaxSettingModule.Action('getAllocationTaxSettingsAllForCombo')
  private actionGetAllocationTaxSettings?: any;
  @allocationTaxSettingModule.Action('getAllocationTaxSettingByGroupDate')
  private getAllocationTaxSettingByGroupDate: any;

  @costCentreModule.Getter('getCostCentres')
  private getCostCentres?: any;
  @costCentreModule.Action('getCostCentres')
  private actionGetCostCentres?: any;

  @tradeChannelModule.Getter('getTradeChannels')
  private getTradeChannels?: any;
  @tradeChannelModule.Action('getTradeChannels')
  private actionПetTradeChannels?: any;

  @costObjectiveModule.Getter('getCostObjectives')
  private getCostObjectives?: any;
  @costObjectiveModule.Action('getCostObjectives')
  private actionGetCostObjectives?: any;

  @allocationModule.Getter('getAllocationCurrencies')
  private getAllocationCurrencies?: any[];

  accountGroups = [
    { name: 'Haben', value: '1' },
    { name: 'Soll', value: '2' },
  ];

  taxDate = DateUtils.formatDateToDotDate(new Date());

  async created() {
    const promiseAll = [
      this.actionGetAllocationTaxSettings(),
      this.actionGetCostCentres(),
      this.actionGetCostObjectives(),
    ];
    await Promise.all(promiseAll);
    this.getAllocationTaxSettingByGroupDate(DateUtils.dotDateToIsoDate(this.taxDate));
  }

  deleteSplit(index: number) {
    this.$emit('click:delete', index);
  }

  getName(index: number) {
    if (index == 0) return 'Kontierung';
    else return `Splittbuchung ${index}`;
  }

  fire(fieldName: string) {
    this.$emit('click:account', fieldName, this.model);
    this.GetSuppliersExtraInfo();
  }

  showSerialError = '';
  get serialNumberRules() {
    const isRequired =
      this.getAccountSupplier?.serialNumberRequired || this.getContraAccountSupplier?.serialNumberRequired;
    return [
      //`${v}`.toString().trim().length fixes --> "TypeError: Cannot read properties of null (reading 'toString')"
      (v: any) => {
        if (`${v}`.toString().trim().length == 0 && isRequired) {
          this.showSerialError = this.$i18n.t('messages.field_required').toString();
          return false;
        } else {
          this.showSerialError = '';
          return true;
        }
      },
    ];
  }

  private accountChanged: boolean = false;
  private contraAccountChanged: boolean = false;

  get accountRules() {
    return {
      required: (v: any) =>
        !(this.accountChanged && v.toString().trim().length == 0) || this.$t('messages.field_required'),
      not_found: (v: any) =>
        !(this.accountChanged && this.getAccountSupplier!.konto == '') || this.$t('messages.account_not_found'),
      one_can_have: (v: any) =>
        !(this.accountChanged && !this.HasOnlyOneCostCenterAndCostUnitAndTradeChanel()) ||
        this.$t('messages.only_one_can_have'),
      same_account: (v: any) =>
        !(
          this.model.account != '' &&
          this.model.contra_account != '' &&
          this.model.account == this.model.contra_account
        ) || this.$t('messages.the_same_account'),
    };
  }

  private isValidPercentage(v: any) {
    if (v.includes('%') == false) {
      return false;
    }
    var x = parseFloat(v.replace('%', '').replace(',', '.'));
    if (isNaN(x) || x < 0 || x > 100) {
      // value is out of range
      return false;
    }
    return true;
  }

  get amountRules() {
    return {
      only_percent: (v: any) =>
        !(this.isValidPercentage(v) == false && this.model.index > 0) || this.$t('messages.only_percent'),
    };
  }

  get contraAccountRules() {
    return {
      required: (v: any) =>
        !(this.contraAccountChanged && v.toString().trim().length == 0) || this.$t('messages.field_required'),
      not_found: (v: any) =>
        !(this.contraAccountChanged && this.getContraAccountSupplier!.konto == '') ||
        this.$t('messages.account_not_found'),
      one_can_have: (v: any) =>
        !(this.contraAccountChanged && !this.HasOnlyOneCostCenterAndCostUnitAndTradeChanel()) ||
        this.$t('messages.only_one_can_have'),
      same_account: (v: any) =>
        !(
          this.model.account != '' &&
          this.model.contra_account != '' &&
          this.model.account == this.model.contra_account
        ) || this.$t('messages.the_same_account'),
    };
  }

  private HasOnlyOneCostCenterAndCostUnitAndTradeChanel() {
    let result = false;
    if (this.getAccountSupplier?.konto.length == 0 || this.getContraAccountSupplier?.konto.length == 0) return true;

    if (
      //  all account kostenstelle-cost_center, kostentraeger-cost_unit, absatzkanal-sales_channel, antriebsart-drive_type == 0 and one of contra account !=0
      this.getAccountSupplier!.kostenstelle.length == 0 &&
      this.getAccountSupplier!.kostentraeger.length == 0 &&
      this.getAccountSupplier!.absatzkanal.length == 0 &&
      this.getAccountSupplier!.antriebsart.length == 0 &&
      (this.getContraAccountSupplier!.kostenstelle.length != 0 ||
        this.getContraAccountSupplier!.kostentraeger.length != 0 ||
        this.getContraAccountSupplier!.absatzkanal.length != 0 ||
        this.getContraAccountSupplier!.antriebsart.length != 0)
    )
      result = true;

    if (
      // one of account !=0 and contra account kostenstelle, kostentraeger, absatzkanal, Antriebsart == 0
      (this.getAccountSupplier!.kostenstelle.length != 0 ||
        this.getAccountSupplier!.kostentraeger.length != 0 ||
        this.getAccountSupplier!.absatzkanal.length != 0 ||
        this.getAccountSupplier!.antriebsart.length != 0) &&
      this.getContraAccountSupplier!.kostenstelle.length == 0 &&
      this.getContraAccountSupplier!.kostentraeger.length == 0 &&
      this.getContraAccountSupplier!.absatzkanal.length == 0 &&
      this.getContraAccountSupplier!.antriebsart.length == 0
    )
      result = true;

    if (
      // account == 0 and contra account == 0
      this.getAccountSupplier!.kostenstelle.length == 0 &&
      this.getAccountSupplier!.kostentraeger.length == 0 &&
      this.getAccountSupplier!.absatzkanal.length == 0 &&
      this.getAccountSupplier!.antriebsart.length == 0 &&
      this.getContraAccountSupplier!.kostenstelle.length == 0 &&
      this.getContraAccountSupplier!.kostentraeger.length == 0 &&
      this.getContraAccountSupplier!.absatzkanal.length == 0 &&
      this.getContraAccountSupplier!.antriebsart.length == 0
    )
      result = true;

    return result;
  }

  private async getAccount(event: any) {
    if (event.target.value != '') this.accountChanged = true;
    await this.actionGetAccountSupplierByName(event.target.value).then((value: Supplier) => {
      this.accountSupplier = value;
      this.$emit('click:validate');
    });
  }

  private async getContraAccount(event: any) {
    if (event.target.value != '') this.contraAccountChanged = true;
    await this.actionGetContraAccountSupplierByName(event.target.value).then((value: Supplier) => {
      this.contraAccountSupplier = value;
      this.$emit('click:validate');
    });
  }

  @Watch('model.account', { immediate: true, deep: true })
  async onAccountChange(newVal: any, oldVal: any) {
    if (newVal) {
      this.actionGetAccountSupplierByName(newVal).then((value: Supplier) => {
        this.accountSupplier = value;
      });
    }
  }

  get accountDescription() {
    return this.getAccountSupplier?.bezeichnung;
  }

  @Watch('model.contra_account', { immediate: true, deep: true })
  async onContraAccountChange(newVal: any, oldVal: any) {
    if (newVal) {
      this.actionGetContraAccountSupplierByName(newVal).then((value: Supplier) => {
        this.contraAccountSupplier = value;
      });
    }
  }

  get contraAccountDescription() {
    return this.getContraAccountSupplier?.bezeichnung;
  }

  get accountErrorColor() {
    if (this.accountDescription == undefined) return true;
    if (!this.accountDescription.length) return true;
    return false;
  }

  get contraAccountErrorColor() {
    if (this.contraAccountDescription == undefined) return true;
    if (!this.contraAccountDescription.length) return true;
    return false;
  }

  private async GetSuppliersExtraInfo() {
    // kostenstelle
    let kostenstelleToFind = '';
    if (this.getAccountSupplier!.kostenstelle.length > 0) {
      kostenstelleToFind = this.getAccountSupplier!.kostenstelle;
    }
    if (this.getContraAccountSupplier!.kostenstelle.length > 0) {
      kostenstelleToFind = this.getContraAccountSupplier!.kostenstelle;
    }
    const kostenstelle = this.getCostCentres.items.find(
      (x: CostCentre) => x.value.toString() === kostenstelleToFind
    ) ?? [0];

    // kostentraeger
    let kostentraegerToFind = '';
    if (this.getAccountSupplier!.kostentraeger.length > 0) {
      kostentraegerToFind = this.getAccountSupplier!.kostentraeger;
    }
    if (this.getContraAccountSupplier!.kostentraeger.length > 0) {
      kostentraegerToFind = this.getContraAccountSupplier!.kostentraeger;
    }
    const kostentraeger = this.getCostObjectives.items.find(
      (x: CostObjective) => x.value.toString() === kostentraegerToFind
    ) ?? [0];

    // absatzkanal
    let absatzkanalToFind = '';
    if (this.getAccountSupplier!.absatzkanal.length > 0) {
      absatzkanalToFind = this.getAccountSupplier!.absatzkanal;
    }
    if (this.getContraAccountSupplier!.absatzkanal.length > 0) {
      absatzkanalToFind = this.getContraAccountSupplier!.absatzkanal;
    }
    const absatzkanal = this.getTradeChannels.items.find(
      (x: TradeChannel) => x.value.toString() === absatzkanalToFind
    ) ?? [0];

    this.model['cost_center'] = kostenstelle.id;

    this.model['cost_unit'] = kostentraeger.id;

    this.model['sales_channel'] = absatzkanal.id;
  }

  @allocationBrandModule.Getter('getAllAllocationBrands')
  private allAllocationBrands!: AllocationBrand[];
  get allocationBrands(): AllocationBrand[] {
    if (this.model.branch) {
      const currenOffices = this.allocationOffices.filter((x) => x.officeNumber == this.model.branch);
      if (currenOffices.length > 0) {
        const allowedBrands = currenOffices[0].data.allowedBrands;
        return this.allAllocationBrands.filter((x) => allowedBrands.includes(x.brandNumber)) ?? [];
      }
    }
    return this.allAllocationBrands;
  }
  @allocationOfficeModule.Getter('getAllAllocationOffices')
  private allAllocationOffices!: AllocationOffice[];
  get allocationOffices(): AllocationOffice[] {
    if (this.model.brand)
      return this.allAllocationOffices.filter((x) => x.data.allowedBrands.includes(this.model.brand)) ?? [];
    return this.allAllocationOffices;
  }

  //#region process keys Enter, Control
  onFocus(event: any, refname: string) {
    this.openItemsInAutocomplete(refname);
  }
  keyUp(event: any, refname: string) {
    if (event.key == 'Control') this.openItemsInAutocomplete(refname);
    if (event.key == 'Enter') {
      this.closeItemsInAutocomplete(refname);
      this.focusNextInputOnEnter(event.target);
    }
  }
  private openItemsInAutocomplete(refname: string) {
    if (this.$refs[refname] as any) {
      (this.$refs[refname] as any)[0].activateMenu();
    }
  }

  private closeItemsInAutocomplete(refname: string) {
    if (this.$refs[refname] as any) {
      const menu = (this.$refs[refname] as any)[0].$refs as any;
      if (menu?.menu?.value) {
        menu.menu.isActive = false;
      }
    }
  }

  private focusNextInputOnEnter(el: HTMLInputElement) {
    const lastElement = (this.$refs.saveBtn as Vue).$el as HTMLElement;
    buildOnEnter(lastElement)(el);
  }
  //#endregion

  private saveAccount() {
    this.$emit('click:validate');
  }
}
