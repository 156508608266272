import { GetterTree } from 'vuex';
import { AllocationBrandState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationBrandState, RootState> = {
  ['getAllocationBrands']: (state) => state.allocationBrands,
  ['getAllocationBrandsIsLoading']: (state) => state.allocationBrands?.isLoading,
  ['getAllocationBrandsTotal']: (state) => state.allocationBrands?.total,
  ['getAllocationBrandsSearchParams']: (state) => state.allocationBrands?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
  ['getAllAllocationBrandsIsImporting']: (state) => state.editOfficesPermittedDialog_AllAllocationBrandsIsImporting,
  ['getAllAllocationBrands']: (state) => state.editOfficesPermittedDialog_AllAllocationBrands,
};
