import DateUtils from '@/shared/utils/DateUtils';
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
const name = 'date-field';
const logger = new Logger(name);

@Component({ name: name, components: {} })
export default class DateField extends Vue {
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;
  @Prop({ default: 'Label', type: String })
  private modelLabel!: string;
  @Prop({ default: 'Description', type: String })
  private modelDescription!: string;
  @Prop({ default: 'modelId', type: String })
  private modelId!: string;
  @Prop({ default: 6, type: Number })
  private colsTitle!: number;
  @Prop({ default: 6, type: Number })
  private colsValue!: number;
  @Prop({ default: 'text-right', type: String })
  private TextAlign!: string;

  @Prop({ default: '', type: String })
  private value!: string;
  private modelValue: string = '';
  @Watch('value')
  onValueChanged(value: string, oldValue: string) {
    this.modelValue = value;
  }
  @Watch('modelValue')
  onModelValueChanged(value: string, oldValue: string) {
    logger.debug(`onModelValueChanged ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
    this.viewValue = DateUtils.formatDotDate(value, this.$i18n.locale);
    logger.debug(`onModelValueChanged ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
  }
  private viewValue: string = '';
  get dateRules() {
    return [(v: any) => DateUtils.isDate(v, this.$i18n.locale) == true || this.$t('is_not_a_date').toString()];
  }

  async onChange(event: any) {
    logger.debug(`onChange1 ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
    this.modelValue = DateUtils.localInputToDotDe(event, this.$i18n.locale);
    logger.debug(`onChange2 ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
    const valid = await (this.$refs[this.modelId] as any)?.validate();
    this.$emit('change', this.modelValue, this.modelValue);
  }
  mounted() {
    logger.debug(`mounted ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
    this.modelValue = DateUtils.toLocalDateIfDate(this.value, this.$i18n.locale);
    this.viewValue = DateUtils.toLocalDateIfDate(this.modelValue, this.$i18n.locale);
    logger.debug(`mounted ${this.viewValue} - ${this.modelValue}  -  ${this.$i18n.locale}`);
  }
  private onKyeUp(event: any) {
    this.$emit('keyup', event);
  }
  async onBlur(event: any) {
    this.$emit('blur', event);
  }
  hideDetails() {
    const x = this.$refs[this.modelId] as any;
    return x?.validationState != 'error';
  }
}
