import { MutationTree } from 'vuex';
import { AllocationSettingState } from './types';
import Vue from 'vue';
import allocationSetting, { AllocationSetting, Value } from '@/shared/model/allocationSetting';

export const mutations: MutationTree<AllocationSettingState> = {
  setAllocationSettings(state, payload: Value) {
    state.allocationSettings.items = payload.value.map((x) => allocationSetting.parse(x));
    state.allocationSettings.total = payload['@odata.count'] || 0;
    state.allocationSettings.isLoading = false;
  },
  setAllocationSettingsTotal(state, payload: number) {
    state.allocationSettings.total = payload;
  },
  setAllocationSettingsIsLoading(state, payload: boolean) {
    state.allocationSettings.isLoading = payload;
  },
  setAllocationSetting(state, payload: AllocationSetting) {
    state.allocationSetting = payload;
  },
};
