// new comment
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import templateMarkup, { TemplateMarkup, Value } from '../model/templateMarkup';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.TemplateMarkup');
export interface BackendTemplateMarkup {
  getTemplateMarkup(id: string): AxiosPromise<TemplateMarkup>;
  getTemplateMarkups: (searchParams: SearchParams, organizationId: string, documentId: string) => AxiosPromise<Value>;
  deleteTemplateMarkup(id: string): AxiosPromise;
  deleteTemplateMarkupByDocumentId(documentId: string): AxiosPromise;
  updateTemplateMarkup(TemplateMarkup: TemplateMarkup): AxiosPromise<any>;
  getLastRecognizedTextInBox(id: string, x: number, y: number, width: number, height: number): AxiosPromise<any>;
}

export const defaultBackendTemplateMarkup: BackendTemplateMarkup = {
  getTemplateMarkup(id: string): AxiosPromise<TemplateMarkup> {
    const url = `${URLS.templateMarkupOdata}/${id}`;
    return instance.get<TemplateMarkup>(url);
  },
  getTemplateMarkups(searchParams: SearchParams, organizationId: string, userFileIdId: string): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    if (userFileIdId) odfb = odfb.eq('UserFileId', userFileIdId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.templateMarkupOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTemplateMarkups${url}`);
    return instance.get<Value>(url);
  },

  deleteTemplateMarkup(id: string): AxiosPromise {
    logger.debug('deleteTemplateMarkup');
    return instance.delete(`${URLS.templateMarkup}/${id}`);
  },
  deleteTemplateMarkupByDocumentId(documentId: string): AxiosPromise {
    logger.debug('deleteTemplateMarkupByDocumentId');
    return instance.delete(`${URLS.templateMarkup}/${documentId}`);
  },
  updateTemplateMarkup(TemplateMarkup: TemplateMarkup): AxiosPromise<any> {
    logger.debug('updateTemplateMarkup');
    return instance.put<TemplateMarkup>(`${URLS.templateMarkup}/update`, templateMarkup.toAPI(TemplateMarkup));
  },
  getLastRecognizedTextInBox(id: string, x: number, y: number, width: number, height: number): AxiosPromise<any> {
    return instance.get<TemplateMarkup>(`${URLS.ocr}/RecognizeTextInBox/${id}/${x}/${y}/${width}/${height}`);
  },
};
