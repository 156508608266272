import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DocumentQaDialog from './document-qa-dialog/document-qa-dialog.vue';
import QaOverviewDialog from './qa-overview-dialog/qa-overview-dialog.vue';
import { RequestType, ThreadType } from './thread-request-qa-types';
import { ActionLog } from '@/shared/model/actionLog';
import { Document } from '@/shared/model/document';

const actionLogModule = namespace('actionLog');
const documentModule = namespace('document');

const logger = new Logger('menu-document-qa');

@Component({ name: 'menu-document-qa', components: { DocumentQaDialog, QaOverviewDialog } })
export default class MenuDocumentQAView extends Vue {
  @actionLogModule.Action('getActionLogsQa')
  private getActionLogsQa!: any;
  @actionLogModule.Getter('getActionLogsQa')
  private getterActionLogsQa!: any;

  @documentModule.Getter('getDocument')
  private document!: Document;
  @documentModule.Action('refreshDocument')
  private refreshDocument!: any;
  @actionLogModule.Action('getActionLogsQaOverview')
  private getActionLogsQaOverview!: any;

  @PropSync('value', { default: '' })
  private model!: any;

  // TODO: (EGRUP-200) add backend logic for creating new topic + add dialog
  private createNewTopic() {
    logger.debug('createNewTopic');
    this.showDocumentQaDialog();
  }

  //#region (ED-892) Logic for `menu-document-qa` messages
  private documentQaArray: Array<any> = [];
  get documentQAs() {
    return this.documentQaArray;
  }
  get documentId() {
    return this.$route.params['documentId'];
  }

  members(item: any) {
    return item?.data?.members?.length > 0 ? item.data.members.join(', ') : '';
  }
  roles(item: any) {
    return item?.data?.roles?.length > 0 ? item.data.roles.map((x: any) => `Gruppe ${x}`).join(', ') : '';
  }

  formatMessage(item: any) {
    const members = this.members(item);
    const roles = this.roles(item);
    const memberRolesText = `${members ? members + ', ' : ''} ${roles}`;
    const formattedMessage = this.getMessageBasedOnType(item.threadType, memberRolesText);
    return formattedMessage;
  }

  getMessageBasedOnType(threadType: ThreadType, memberRolesText: string) {
    let result = memberRolesText;
    memberRolesText = memberRolesText.trim() ? memberRolesText : `${this.$t('all_employees')}`;
    switch (threadType) {
      case ThreadType.question:
        result = `${this.$t('question_asked_of_x_employees', { memberRolesText })}`;
        break;
      case ThreadType.task:
        result = `${this.$t('task_given_to_x_employees', { memberRolesText })}`;
        break;
      case ThreadType.circular:
        result = `${this.$t('circulars_provided_to_x_employees', { memberRolesText })}`;
        break;

      default:
        break;
    }
    return result;
  }

  openQa(qaId: string) {
    this.showDocumentQaDialog(qaId);
  }
  //#endregion

  //#region (ED-932) logic to block `New topic` button if 1 open Question, 1 open Task and 1 open Circular exist for document
  get isNewThreadsAllowed() {
    return this.document.threadsAllowed.newThreadsAllowed;
  }

  get hasOneOpenQuestion() {
    return this.isOpenThreadTypeExist(ThreadType.question);
  }
  get hasOneOpenTask() {
    return this.isOpenThreadTypeExist(ThreadType.task);
  }
  get hasOneOpenCircular() {
    return this.isOpenThreadTypeExist(ThreadType.circular);
  }

  private isOpenThreadTypeExist(threadType: ThreadType) {
    return !!(
      this.documentQaArray &&
      this.documentQaArray.length > 0 &&
      this.documentQaArray.find((x: any) => x.type === RequestType.start && x.threadType === threadType && !x.closed)
    );
  }
  //#endregion

  async mounted() {
    if (!this.model?.id) {
      this.model = this.document;
    }

    await this.getData(this.model?.id || this.documentId);
  }
  async getData(id: string) {
    const searchData: any = { documentId: id }; // (ED-755) `Id` is null when open `qa-dialog` link from E-Mail
    await this.getActionLogsQa({ searchData })
      .then((result: any) => {
        this.documentQaArray = result.value.filter((x: any) => x.type === RequestType.start);

        this.formatActionLogDataField();
        this.checkIfOpenFromEmail();
        if (this.$route.params && this.$route.params['threadId'])
          this.showDocumentQaDialog(this.$route.params['threadId']);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  formatActionLogDataField() {
    if (this.documentQaArray.length > 0) {
      this.documentQaArray.forEach((element: any) => {
        if (element.data && element.data.notificationType) return; // skip parsing because this ActionLog was already parsed
        // element.data = JSON.parse(element.data);
      });
    }
  }

  private returnBack() {
    this.$emit('click:return');
  }

  //#region Document QA Dialog logic
  private showDocumentQaDialog(qaId?: string) {
    this.dialogDocumentQa.show = true;
    this.dialogDocumentQa.startMessageId = qaId || '';
    this.dialogDocumentQa.model = this.model;
  }

  dialogDocumentQa = {
    show: false,
    model: {},
    startMessageId: '',
    OnClose: () => {
      this.useActionLogGetterData();
      this.dialogDocumentQa.show = false;
    },
    showQaOverviewDialog: () => {
      this.getActionLogsQaOverview({ searchData: { type: RequestType.start, closed: 'open' } });
      this.showQaOverviewDialog();
    },
    OnQAWasCreated: (id: string) => {
      this.getData(id);
      this.refreshDocument(id);
    },
    OnQAWasClosed: (id: string) => {
      this.getData(id);
      this.refreshDocument(id);
    },
  };

  private useActionLogGetterData() {
    if (this.getterActionLogsQa && this.getterActionLogsQa.items?.length > 0) {
      this.documentQaArray = this.getterActionLogsQa.items.filter((x: any) => x.type === RequestType.start);

      this.formatActionLogDataField();
    }
  }

  private checkIfOpenFromEmail() {
    if (this.$route.query && this.$route.query['threadId']) {
      this.showDocumentQaDialog(this.$route.query['threadId'] + '');
    }
  }
  //#endregion

  //#region QA Overview Dialog logic
  private showQaOverviewDialog() {
    this.dialogDocumentQa.show = false;
    this.dialogQaOverview.show = true;
  }

  dialogQaOverview = {
    show: false,
    model: {},

    openQaFromOverview: (qa: ActionLog) => {
      this.showDocumentQaDialogFromOverview(qa);
    },
    OnClose: () => {
      this.dialogQaOverview.show = false;
    },
  };

  private showDocumentQaDialogFromOverview(qa: ActionLog) {
    this.dialogDocumentQa.show = true;
    this.dialogDocumentQa.startMessageId = qa.actionLogId;
    this.dialogDocumentQa.model = { id: qa.documentId, organisationId: qa.organisationId };
  }

  //#endregion
}
