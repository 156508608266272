import { render, staticRenderFns } from "./left-menu-help-dialog.html?vue&type=template&id=621dee44&scoped=true&external"
import script from "./left-menu-help-dialog.ts?vue&type=script&lang=ts&external"
export * from "./left-menu-help-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./left-menu-help-dialog.scss?vue&type=style&index=0&id=621dee44&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621dee44",
  null
  
)

/* custom blocks */
import block0 from "./left-menu-help-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fapp%2Fleft-menu%2Fleft-menu-help-dialog%2Fleft-menu-help-dialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog})
