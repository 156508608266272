import { GetterTree } from 'vuex';
import { OrganisationDocumentExtraStatusSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationDocumentExtraStatusSettingState, RootState> = {
  ['getOrganisationDocumentExtraStatusSettings']: (state) => state.organisationDocumentExtraStatusSettings,
  ['getOrganisationDocumentExtraStatusSettingsIsLoading']: (state) =>
    state.organisationDocumentExtraStatusSettings?.isLoading,
  ['getOrganisationDocumentExtraStatusSettingsTotal']: (state) => state.organisationDocumentExtraStatusSettings?.total,
  ['getOrganisationDocumentExtraStatusSettingsSearchParams']: (state) =>
    state.organisationDocumentExtraStatusSettings?.searchParams,
  getNameByValue: (state) => (statusValue: string) => {
    const values = state.organisationDocumentExtraStatusSettings.items.filter(
      (x) => x.statusValue && statusValue && x.statusValue?.toLowerCase() == statusValue.toLowerCase()
    );
    return values.length > 0 ? values[0].name : statusValue;
  },
};
