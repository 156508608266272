import { UserFile } from './../model/userFile';
import { AxiosResponse } from 'axios';
import { URLS } from '../backend';
import { CONST } from './Constants';

export default class CacheUtils {
  public static async putPreviewInCache(id: string, response: AxiosResponse<UserFile>) {
    const url = CacheUtils.getUserFileUrl(id);
    const cache = await caches.open(CONST.cachePreviews);
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    // `cache.add` return 401 error
    cache.put(new Request(url), new Response(JSON.stringify(response.data), options));
  }

  public static async getPreviewFileFromCache(id: string) {
    const url = CacheUtils.getUserFileUrl(id);
    const cache = await caches.open(CONST.cachePreviews);

    const request = new Request(url);
    const response = await cache.match(request);
    const result = await response?.json();
    return result;
  }

  private static getUserFileUrl(id: string) {
    return `${URLS.userFileOdata}(${id})`;
  }
}
