export interface Office {
  id: string;
  deleted: number;
  name: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  processesUploadFolderId: string;

  createdAt: string;
  updatedAt: string;
}

export interface Value {
  value: Office[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Office>): any {
  return {
    Id: data?.id || undefined,
    Deleted: data?.deleted || 0,
    Name: data?.name || '',
    OrganisationId: data?.organisationId || undefined,
    ProcessesUploadFolderId: data?.processesUploadFolderId || undefined,

    Created_At: data?.createdAt || undefined,
    Updated_At: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<Office>): Office {
  return {
    id: data?.id || '',
    deleted: data?.deleted || 0,
    name: data?.name || '',
    organisationId: data?.organisationId || '',
    processesUploadFolderId: data?.processesUploadFolderId || '',

    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
