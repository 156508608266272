import { EmployeeState } from './types';

export const state = (): EmployeeState => initialState();

export const initialState = (): EmployeeState => ({
  employees: {
    //TODO remove when was made backend
    items: [
      {
        id: '11c2e103-037f-4bfa-953a-269cedc84a31',
        organisationId: '18c2e103-037f-4bfa-953a-269cedc84a31',
        idOld: 1365,
        userId: '611624eaa7a1c30cdb4b6a01',
        firstName: 'asadasd',
        lastName: '4',
        shortForm: '4',
        email: 'asd@zasd.sad',
        username: 'asd@zasd.sad',
        roles: ['60b20d153d10881dc232edb2'],
        isSeller: true,
      },
      {
        id: '12c2e103-037f-4bfa-953a-269cedc84a31',
        organisationId: '18c2e103-037f-4bfa-953a-269cedc84a31',
        idOld: 1301,
        userId: '609e332ed22180768f0e6ac1',
        firstName: '',
        lastName: '',
        shortForm: '',
        email: 'kedas@dev4you.gmbh',
        username: 'kedas@dev4you.gmbh',
        roles: [],
        isSeller: true,
      },
      {
        id: '13c2e103-037f-4bfa-953a-269cedc84a31',
        organisationId: '18c2e103-037f-4bfa-953a-269cedc84a31',
        idOld: 1341,
        userId: '60e430d5b66fa421133efad1',
        firstName: 'Slawa',
        lastName: 'Kedas',
        shortForm: 'ks',
        email: 'kedas@keeptime.de',
        username: 'kedas@keeptime.de',
        roles: ['60b20d153d10881dc232edb2'],
        isSeller: true,
      },
      {
        id: '14c2e103-037f-4bfa-953a-269cedc84a31',
        organisationId: '18c2e103-037f-4bfa-953a-269cedc84a31',
        idOld: 1342,
        userId: '60e473678fb7b73302695e31',
        firstName: 'Empl_name',
        lastName: 'Empl_Lastname',
        shortForm: 'empl',
        email: 'test_ma01@keeptime.de',
        username: 'test_ma01@keeptime.de',
        roles: ['60b20d153d10881dc232edb3'],
        isSeller: true,
      },
    ],
    isLoading: false,
    total: 4, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['email'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
