export interface OrganisationDocumentProperty {
  id: string; // ulong
  fieldId: string; // string
  organisationId: string; // string
  name: string; // string
  sortOrder: number; // int
  enabled: boolean; // bool?
  favorite: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  deleted: boolean; // bool
}

export interface Value {
  value: OrganisationDocumentProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<OrganisationDocumentProperty>): any {
  return {
    Id: data?.id || undefined,
    FieldId: data?.fieldId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    SortOrder: data?.sortOrder || 0,
    Enabled: data?.enabled || false,
    Favorite: data?.favorite || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    Deleted: data?.deleted || false,
  };
}

function parse(data?: Partial<OrganisationDocumentProperty>): OrganisationDocumentProperty {
  return {
    id: data?.id || '',
    fieldId: data?.fieldId || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    sortOrder: data?.sortOrder || 0,
    enabled: data?.enabled || false,
    favorite: data?.favorite || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    deleted: data?.deleted || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
