import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import gobd, { GobdData } from '@/shared/model/gobdData';
import { Document } from '@/shared/model/document';
import DateUtils from '@/shared/utils/DateUtils';
import NumberUtils from '@/shared/utils/NumberUtils';
import { buildOnEnter } from '@/shared/utils/formUtils';
import i18n from '@/i18n';
import MenuDocumentAccountTable from '../menu-document-account/menu-document-account-table/menu-document-account-table.vue';
import MenuDocumentAccountAddresses from '../menu-document-account/menu-document-account-addresses/menu-document-account-addresses.vue';
import { Supplier } from '@/shared/model/supplier';
import moment from 'moment';
import { OrganisationAllocationProperty } from '@/shared/model/organisationAllocationProperty';

const logger = new Logger('menu-document-gobd');
const documentModule = namespace('document');
const gobdModule = namespace('gobdData');
const supplierModule = namespace('supplier');
const commentModule = namespace('comment');
const organisationAllocationPropertyModule = namespace('organisationAllocationProperty');

@Component({ name: 'menu-document-gobd', components: { MenuDocumentAccountTable, MenuDocumentAccountAddresses } })
export default class MenuDocumentGobdView extends Vue {
  @Ref('gobd-menu-form')
  private refGobdMenuForm!: any;

  @gobdModule.Action('updateGobdData')
  private actionUpdateGobdData!: any;
  @gobdModule.Action('detectGobdData')
  private actionDetectGobdData!: any;
  @gobdModule.Getter('getGobdDatasIsLoading')
  private getGobdDatasIsLoading!: any;

  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Action('updateDocumentGobdInState')
  private actionUpdateDocumentGobdInState!: any;
  @documentModule.Getter('getDocument')
  private getterDocument!: any;

  @supplierModule.Action('getAccountSupplierByName')
  private actionGetAccountSupplierByName?: any;
  @supplierModule.Getter('getAccountSupplier')
  private getAccountSupplier?: Supplier;

  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;

  @commentModule.Action('getComments')
  private actionGetComments!: any;

  @organisationAllocationPropertyModule.Action('getOrganisationAllocationProperties')
  private actionGetOrganisationAllocationProperties!: any;
  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationProperties')
  private getOrganisationAllocationProperties!: any;

  private gobd: GobdData = gobd.parse({});
  private initialData: GobdData = gobd.parse({});

  private focusNextInputOnEnter(el: HTMLInputElement) {
    const lastElement = (this.$refs.saveBtn as Vue).$el as HTMLElement;
    buildOnEnter(lastElement)(el);
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  get isMinusGodb() {
    if (!this.gobd.amount) return false;
    return this.gobd.amount!.toString().includes('-');
  }

  makeCredit() {
    if (!this.gobd.amount) return;
    const hasComma = this.gobd.amount!.toString().includes(',');
    const value = parseFloat(this.gobd.amount.replace(',', '.'));
    const minusValue = (Math.abs(value) * -1).toFixed(2).toString();
    this.gobd.amount = hasComma ? minusValue.replace('.', ',') : minusValue;
  }

  private isFormValid = true;
  get positiveNumberRules() {
    //need `(!!v || v === 0)` trick because !!0 will return FALSE
    return [
      (v: any) => {
        logger.log('value of number in validator :>> ', v);
        if (!v) return true;
        return NumberUtils.isNumber(v) || this.$t('messages.number_required');
      },
    ];
  }

  formatDate() {
    if (this.$i18n.locale.toLowerCase() === 'de' && this.gobd.invoiceDate) {
      this.gobd.invoiceDate = DateUtils.formatDotDate(this.gobd.invoiceDate);
    }
  }

  showAccountError = '';
  MaxLengthRule(id: string, field: any) {
    const orgField = this.getOrganisationAllocationProperties.items.find(
      (x: OrganisationAllocationProperty) => x.fieldId == id
    );
    if (!orgField) return true;

    const maxLength = orgField!.maxLength;

    if (maxLength == 0) return true;

    return (
      (field || '').length <= maxLength || this.$i18n.t('messages.max_length', { characters: maxLength }).toString()
    );
  }

  get invoiceDateRules() {
    return [
      (v: any) => {
        return (
          !v || // (ED-782) `!v` allow NULL(empty) value for `invoiceDate`
          (!!v && !!v.toString().trim() && DateUtils.isDateEx(v)) ||
          this.$i18n.t('messages.date_required')
        ); // i18n.tc(`date_required`) is not working here, just shows `name_required_message` key
      },
    ];
  }

  async created() {
    await this.actionGetOrganisationAllocationProperties();
    await this.loadDocumentIfEmptyState()
      .then(() => {
        this.formatInvoiceDateBasedOnLocale();
        this.formatAmountBasedOnLocale();
        this.initialData = gobd.parse(this.gobd);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async mounted() {
    this.actionGetAccountSupplierByName(this.gobd.supplierAccount);
  }

  //#region (ED-673) Format `amount` with 2 digits after integer (like `100,00` for DE locale or `100.00` for EN locale)
  addZeroes(num: any) {
    if (num == '0') num = 0;
    return num.toLocaleString(this.$i18n.locale.toLowerCase(), { useGrouping: false, minimumFractionDigits: 2 });
  }

  formatAmountBasedOnLocale() {
    if (this.gobd.amount == null) this.gobd.amount = '0';
    this.gobd.amount = this.addZeroes(this.gobd.amount);
  }
  //#endregion

  // need it to get `documentId` for GOBD either from state or load directly from backend
  private async loadDocumentIfEmptyState() {
    const doc = this.getterDocument;
    // reuse the `document`(NOT userFile) from the Vuex State if it is currently opened document, otherwise load correct `document` from the backend
    if (doc?.id && doc.id === this.documentId) {
      this.gobd.documentId = doc.id;
      this.checkIfGobDExist(doc);
    } else {
      await this.actionGetDocument(this.documentId)
        .then((result: any) => {
          this.gobd.documentId = result.id;
          this.checkIfGobDExist(doc);
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  checkIfGobDExist(doc: Document) {
    if (doc.documentGobdDatum?.id) {
      this.gobd = Object.assign(this.gobd, doc.documentGobdDatum);
    }

    if (
      (this.gobd.amount.toString().trim().length == 0 ||
        this.gobd.amount.toString().trim() == '0.00' ||
        this.gobd.amount.toString().trim() == '0') &&
      this.gobd.invoiceDate.trim().length == 0 &&
      this.gobd.serialNumber.trim().length == 0
    ) {
      this.actionDetectGobdData(this.documentId).then((result: GobdData) => {
        this.gobd.amount = result.amount;
        this.gobd.invoiceDate = result.invoiceDate;
        this.gobd.invoiceNumber = result.invoiceNumber;
        this.gobd.serialNumber = result.serialNumber;
        this.formatInvoiceDateBasedOnLocale();
        this.formatAmountBasedOnLocale();
        this.initialData = gobd.parse(this.gobd);
      });
    }
  }

  //#region (ED-526) Format `InvoiceDate` based on locale
  private formatInvoiceDateBasedOnLocale() {
    if (this.$i18n.locale.toLowerCase() === 'en' && this.gobd.invoiceDate) {
      this.gobd.invoiceDate = this.getDatePartFromIsoDateTime(this.gobd.invoiceDate);
    } else if (this.$i18n.locale.toLowerCase() === 'de' && this.gobd.invoiceDate) {
      this.gobd.invoiceDate = DateUtils.isoDateToDotDate(this.getDatePartFromIsoDateTime(this.gobd.invoiceDate));
    }
  }

  private getDatePartFromIsoDateTime(date: string) {
    return date.split('T')[0];
  }
  //#endregion

  get isAllGreen() {
    return (
      this.gobd.invoiceAddressVerified &&
      this.gobd.performanceDateVerified &&
      this.gobd.taxNumberVerified &&
      this.gobd.amountVerified
    );
  }
  set isAllGreen(value: boolean) {
    this.gobd.invoiceAddressVerified = value;
    this.gobd.performanceDateVerified = value;
    this.gobd.taxNumberVerified = value;
    this.gobd.amountVerified = value;
  }

  private todayDateIso = new Date().toISOString().slice(0, 10);
  private IsDateInFuture(date: any) {
    if (!date) return false;
    if (date.includes('.')) {
      date = DateUtils.dotDateToIsoDate(date)!;
    }
    const godbDate = new Date(date);
    return godbDate > new Date(this.todayDateIso);
  }

  private IsDateNoreThan2MontOld(date: any) {
    if (!date) return false;
    if (date.includes('.')) {
      date = DateUtils.dotDateToIsoDate(date)!;
    }
    const godbDate = new Date(date);

    const todayMinus2Month = this.addMonth(new Date(), -2);
    return godbDate < todayMinus2Month;
  }

  private addMonth(date: Date, month: number) {
    const result = new Date(date.setMonth(date.getMonth() + month));
    return result;
  }

  private async saveGobdFull() {
    //console.error(this.IsDateInFuture(this.gobd.invoiceDate));
    //console.error(this.IsDateNoreThan2MontOld(this.gobd.invoiceDate));
    const isFormValidResult = await this.refGobdMenuForm.validate();

    if (isFormValidResult) {
      if (this.IsDateInFuture(this.gobd.invoiceDate)) {
        this.$confirm
          .open(`${this.$t(`dialogs.save_title`)}`, `${this.$t('dialogs.in_feature', { 0: this.gobd.invoiceDate })}`, {
            cancelText: this.$t('dialogs.confirmation.noBtn'),
            okText: this.$t('dialogs.confirmation.yesBtn'),
          })
          .then(async (response: any) => {
            if (response) {
              await this.saveGobd();
            } else {
              return;
            }
          });
      }
      if (this.IsDateNoreThan2MontOld(this.gobd.invoiceDate)) {
        this.$confirm
          .open(
            `${this.$t(`dialogs.save_title`)}`,
            `${this.$t('dialogs.more_than_2_months', { 0: this.gobd.invoiceDate })}`,
            {
              cancelText: this.$t('dialogs.confirmation.noBtn'),
              okText: this.$t('dialogs.confirmation.yesBtn'),
            }
          )
          .then(async (response: any) => {
            if (response) {
              await this.saveGobd();
            } else {
              return;
            }
          });
      }

      if (!this.IsDateInFuture(this.gobd.invoiceDate) && !this.IsDateNoreThan2MontOld(this.gobd.invoiceDate)) {
        await this.saveGobd();
      }
    }
  }

  private async saveGobd() {
    const isFormValidResult = await this.refGobdMenuForm.validate();

    if (isFormValidResult) {
      logger.debug('saveGobd');

      if (this.gobd.amount.indexOf(',') != 0) this.gobd.amount = this.gobd.amount.replace(',', '.');
      this.actionUpdateGobdData(this.gobd)
        .then((result: any) => {
          this.actionGetDocument(this.documentId);
          this.gobd.id = result.id; // put `id` in `gobd` object to be able instantly update created Gobd, otherwise get duplicate Error (since no `id` backend thinks we want create new record, not update existing one)
          this.actionUpdateDocumentGobdInState(result.result);
          this.$emit('click:return');
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private returnBack() {
    if (!gobd.compare(this.initialData, this.gobd)) {
      this.$confirm
        .open(`${i18n.tc(`dialogs.confirmation.title`)}`, `${this.$t('dialogs.confirmation.message')}`, {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        })
        .then(async (response: any) => {
          if (response) {
            await this.saveGobd();
            await this.actionGetDocument(this.documentId);
          } else {
            this.actionUpdateDocumentGobdInState(this.initialData);
            this.$emit('click:return');
          }
        });
    } else {
      this.$emit('click:return');
    }
  }

  get supplier() {
    return this.gobd.supplierAccount;
  }

  //#region Document Rights Dialog logic
  private showDocumentAccountDialog(field: string) {
    this.dialogDocumentAccount.show = true;
    this.dialogDocumentAccount.insertIntoField = field;
  }

  updateSupplierDescription() {
    this.actionGetAccountSupplierByName(this.gobd.supplierAccount);
  }

  dialogDocumentAccount = {
    show: false,
    insertIntoField: '',
    model: {},
    OnClose: () => {
      this.dialogDocumentAccount.show = false;
    },
    OnSelect: (value: any) => {
      this.gobd.supplierAccount = value.konto;

      this.dialogDocumentAccount.show = false;
      this.actionGetAccountSupplierByName(this.gobd.supplierAccount);
    },
  };
  //#endregion

  get serialnummer() {
    return this.gobd.serialNumber;
  }

  //#region serialnummer

  private showDocumentAddressesDialog() {
    this.dialogDocumentAddresses.show = true;
  }

  dialogDocumentAddresses = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAddresses.show = false;
    },
    OnSelect: (value: any) => {
      this.gobd.serialNumber = value.seriennummer;
      this.dialogDocumentAddresses.show = false;
    },
  };

  //#endregion

  //#region Notes
  get notesBtnClassObject() {
    return {
      'yellow-bg': this.documentHasNotes,
    };
  }

  get documentHasNotes() {
    return (
      !!this.getterGetDocument?.documentDetailComments && this.getterGetDocument?.documentDetailComments.length > 0
    ); // if no Notes for document then `documentGobdDatum` will be NULL, i.e. return false
  }

  private async openNotices() {
    await this.actionGetComments({ documentId: this.documentId });
    const info_box = document.getElementById('info_box');
    const currentDisplayMode = getComputedStyle(info_box!).display;
    if (currentDisplayMode == 'none') {
      info_box!.style.display = 'block';
    } else {
      info_box!.style.display = 'none';
    }
  }

  beforeDestroy() {
    const info_box = document.getElementById('info_box');
    if (info_box) info_box.style.display = 'none';
  }
  //#endregion
}
