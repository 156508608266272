import { GetterTree } from 'vuex';
import { ActionLogRoleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ActionLogRoleState, RootState> = {
  ['getActionLogRoles']: (state) => state.actionLogRoles,
  ['getActionLogRolesIsLoading']: (state) => state.actionLogRoles?.isLoading,
  ['getActionLogRolesTotal']: (state) => state.actionLogRoles?.total,
  ['getActionLogRolesSearchParams']: (state) => state.actionLogRoles?.searchParams,
};
