import { GetterTree } from 'vuex';
import { FilePagesTextState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<FilePagesTextState, RootState> = {
  ['getFilePagesTexts']: (state) => state.filePagesTexts,
  ['getFilePagesTextsIsLoading']: (state) => state.filePagesTexts?.isLoading,
  ['getFilePagesTextsTotal']: (state) => state.filePagesTexts?.total,
  ['getFilePagesTextsSearchParams']: (state) => state.filePagesTexts?.searchParams,
};
