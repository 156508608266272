import allocationTemplateEx, { AllocationTemplateEx } from './allocationTemplateEx';

export interface AllocationTemplate {
  id: string; // System.Guid
  allocationTemplateId: string; // System.Guid
  organisationId: string; // System.Guid
  name: string; // string
  deleted: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  mainAllocationTemplateId: string; // System.Guid
  sparkle: string;
  booking_type: string;
  document_text_1: string;
  document_text_2: string;
  staff_number: string;
  origin: string;
  exp_distribution_channel: string;
  dealer_own_cost_unit: string;
  project: string;
  discount_rate: string;
  exchange_rate: string;
  drive_type: string;
  account: string;
  contra_account: string;
  invoice_date: string;
  invoice_number: string;
  branch: string;
  brand: string;
  tax_key: string;
  tax_key_amount: string;
  tax_key_name: string;
  posting_text: string;
  cost_center: string;
  cost_center_name: string;
  cost_unit: string;
  cost_unit_name: string;
  account_group: string;
  serial_number: string;
  sales_channel: string;
  vat_code: string;
  contra_account_group: string | number;
  discount_in: string | number;
  days_clean: string | number;
  days_discount: string | number;
  foreign_currency: string | number;
  denate_date: string;
  compensation_date: string;
  delivery_date: string;
  properties?: AllocationTemplateEx[];
}

export interface Value {
  value: AllocationTemplate[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationTemplate>): any {
  return {
    Id: data?.id || undefined,
    AllocationTemplateId: data?.allocationTemplateId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    Deleted: data?.deleted || false,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    MainAllocationTemplateId: data?.mainAllocationTemplateId || undefined,
    Sparkle: data?.sparkle || '',
    Booking_type: data?.booking_type || '',
    Document_text_1: data?.document_text_1 || '',
    Document_text_2: data?.document_text_2 || '',
    Staff_number: data?.staff_number || '',
    Origin: data?.origin || '',
    Exp_distribution_channel: data?.exp_distribution_channel || '',
    Dealer_own_cost_unit: data?.dealer_own_cost_unit || '',
    Project: data?.project || '',
    Discount_rate: data?.discount_rate || '',
    Exchange_rate: data?.exchange_rate || '',
    Drive_type: data?.drive_type || '',
    Account: data?.account || '',
    Contra_account: data?.contra_account || '',
    Invoice_date: data?.invoice_date || '',
    Invoice_number: data?.invoice_number || '',
    Branch: data?.branch || '',
    Brand: data?.brand || '',
    Tax_key: data?.tax_key || '',
    Tax_key_amount: data?.tax_key_amount || '',
    Tax_key_name: data?.tax_key_name || '',
    Posting_text: data?.posting_text || '',
    Cost_center: data?.cost_center || '',
    Cost_unit: data?.cost_unit || '',
    Account_group: data?.account_group || '',
    Serial_number: data?.serial_number || '',
    Sales_channel: data?.sales_channel || '',
    Vat_code: data?.vat_code || '',
    Contra_account_group: data?.contra_account_group || '',
    Discount_in: data?.discount_in || '',
    Days_clean: data?.days_clean || '',
    Days_discount: data?.days_discount || '',
    Foreign_currency: data?.foreign_currency || '',
    Denate_date: data?.denate_date || '',
    Compensation_date: data?.compensation_date || '',
    Delivery_date: data?.delivery_date || '',
    Properties: data?.properties?.map((item) => allocationTemplateEx.toAPI(item)),
  };
}

function parse(data?: Partial<AllocationTemplate>): AllocationTemplate {
  return {
    id: data?.id || '',
    allocationTemplateId: data?.allocationTemplateId || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    deleted: data?.deleted || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    mainAllocationTemplateId: data?.mainAllocationTemplateId || '',
    sparkle: data?.sparkle || '',
    booking_type: data?.booking_type || '',
    document_text_1: data?.document_text_1 || '',
    document_text_2: data?.document_text_2 || '',
    staff_number: data?.staff_number || '',
    origin: data?.origin || '',
    exp_distribution_channel: data?.exp_distribution_channel || '',
    dealer_own_cost_unit: data?.dealer_own_cost_unit || '',
    project: data?.project || '',
    discount_rate: data?.discount_rate || '',
    exchange_rate: data?.exchange_rate || '',
    drive_type: data?.drive_type || '',
    account: data?.account || '',
    contra_account: data?.contra_account || '',
    invoice_date: data?.invoice_date || '',
    invoice_number: data?.invoice_number || '',
    branch: data?.branch || '',
    brand: data?.brand || '',
    tax_key: data?.tax_key || '',
    tax_key_amount: data?.tax_key_amount || '',
    tax_key_name: data?.tax_key_name || '',
    posting_text: data?.posting_text || '',
    cost_center: data?.cost_center || '',
    cost_center_name: data?.cost_center_name || '',
    cost_unit: data?.cost_unit || '',
    cost_unit_name: data?.cost_unit_name || '',
    account_group: data?.account_group || '',
    serial_number: data?.serial_number || '',
    sales_channel: data?.sales_channel || '',
    vat_code: data?.vat_code || '',
    contra_account_group: data?.contra_account_group || '',
    discount_in: data?.discount_in || '',
    days_clean: data?.days_clean || '',
    days_discount: data?.days_discount || '',
    foreign_currency: data?.foreign_currency || '',
    denate_date: data?.denate_date || '',
    compensation_date: data?.compensation_date || '',
    delivery_date: data?.delivery_date || '',
    properties: data?.properties || [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
