import { Document } from '@/shared/model/document';
import { DocumentProperty } from '@/shared/model/documentProperty';
import { OdataItems } from '@/shared/model/OdataItems';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const orgDocumentPropertyModule = namespace('organisationDocumentProperty');
const documentPropertyModule = namespace('documentProperty');

@Component({ name: 'document-favorite-info-fields', components: {} })
export default class DocumentFavoriteInfoFieldsView extends Vue {
  @documentPropertyModule.Getter('getDocumentPropertys')
  private documentProperties!: OdataItems<DocumentProperty>;
  @orgDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private organisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;

  getValue(fieldId: string): string {
    return this.documentProperties.items.find((x) => x.fieldId === fieldId)?.value ?? '';
  }

  get activeOrganisationDocumentPropertys() {
    return this.organisationDocumentPropertys.items.filter((x) => x.enabled == true);
  }
}
