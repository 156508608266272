import { ActionTree } from 'vuex';
import { OrganisationDocumentListSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationDocumentListSetting } from '@/shared/backend/organisationDocumentListSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationDocumentListSetting, {
  Value,
  OrganisationDocumentListSetting,
} from '@/shared/model/organisationDocumentListSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationDocumentListSettings');
export const actions: ActionTree<OrganisationDocumentListSettingState, RootState> = {
  getOrganisationDocumentListSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setOrganisationDocumentListSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationDocumentListSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationDocumentListSetting
      .getOrganisationDocumentListSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationDocumentListSettings', response.data);
        commit('setOrganisationDocumentListSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationDocumentListSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationDocumentListSetting({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentListSetting
      .getOrganisationDocumentListSetting(id)
      .then((response: AxiosResponse<OrganisationDocumentListSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationDocumentListSetting({ commit, dispatch }, file: OrganisationDocumentListSetting) {
    return defaultBackendOrganisationDocumentListSetting
      .updateOrganisationDocumentListSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentListSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisationDocumentListSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteOrganisationDocumentListSetting({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentListSetting
      .deleteOrganisationDocumentListSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentListSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  moveOrganisationDocumentListSetting(
    { commit, dispatch },
    payload: { element: OrganisationDocumentListSetting; newIndex: number; oldIndex: number }
  ) {
    return defaultBackendOrganisationDocumentListSetting
      .moveOrganisationDocumentListSetting(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentListSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
