export interface OrganisationAddress {
  id: string; // ulong
  organisationId: string; // string
  street: string; // string
  zipCode: string; // string
  locale: string; // string
  phone: string; // string
  fax: string; // string
  contact: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: OrganisationAddress[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<OrganisationAddress>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    Street: data?.street || '',
    ZipCode: data?.zipCode || '',
    Locale: data?.locale || '',
    Phone: data?.phone || '',
    Fax: data?.fax || '',
    Contact: data?.contact || '',
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<OrganisationAddress>): OrganisationAddress {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    street: data?.street || '',
    zipCode: data?.zipCode || '',
    locale: data?.locale || '',
    phone: data?.phone || '',
    fax: data?.fax || '',
    contact: data?.contact || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
