import regexSplitterPart, { RegexSplitterPart } from './regexSplitterPart';

export interface RegexSplitter {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  name: string; // string
  targetSeparator: string; // string
  pattern: string; // string
  deleted: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  regexSplitterParts: RegexSplitterPart[];
  active: boolean;
}

export interface Value {
  value: RegexSplitter[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<RegexSplitter>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    TargetSeparator: data?.targetSeparator || '',
    Pattern: data?.pattern || '',
    Deleted: data?.deleted || false,
    //CreatedAt: data?.createdAt || '',
    //UpdatedAt: data?.updatedAt || '',
    RegexSplitterParts: data?.regexSplitterParts?.map((item) => regexSplitterPart.toAPI(item)),
    Active: data?.active || false,
  };
}

function parse(data?: Partial<RegexSplitter>): RegexSplitter {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    targetSeparator: data?.targetSeparator || '',
    pattern: data?.pattern || '',
    deleted: data?.deleted || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    regexSplitterParts: data?.regexSplitterParts || [],
    active: data?.active || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
