import { GetterTree } from 'vuex';
import { CostObjectiveImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CostObjectiveImportSettingState, RootState> = {
  ['getCostObjectiveImportSettings']: (state) => state.costObjectiveImportSettings,
  ['getCostObjectiveImportSettingsIsLoading']: (state) => state.costObjectiveImportSettings?.isLoading,
  ['getCostObjectiveImportSettingsTotal']: (state) => state.costObjectiveImportSettings?.total,
  ['getCostObjectiveImportSettingsSearchParams']: (state) => state.costObjectiveImportSettings?.searchParams,
  ['getCostObjectiveImportSetting']: (state) => state.costObjectiveImportSetting,
  ['getInProgressUpdateCostObjectiveImportSetting']: (state) => state.inProgressUpdateCostObjectiveImportSetting,
};
