import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import regexSplitterPatternPart, { RegexSplitterPatternPart, Value } from '../model/regexSplitterPatternPart';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.RegexSplitterPatternPart');
export interface BackendRegexSplitterPatternPart {
  getRegexSplitterPatternPart(id: string): AxiosPromise<RegexSplitterPatternPart>;
  getRegexSplitterPatternParts: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteRegexSplitterPatternPart(id: string): AxiosPromise;
  updateRegexSplitterPatternPart(RegexSplitterPatternPart: RegexSplitterPatternPart): AxiosPromise<any>;
}

export const defaultBackendRegexSplitterPatternPart: BackendRegexSplitterPatternPart = {
  getRegexSplitterPatternPart(id: string): AxiosPromise<RegexSplitterPatternPart> {
    const url = `${URLS.regexSplitterPatternPartOdata}/${id}`;
    return instance.get<RegexSplitterPatternPart>(url);
  },
  getRegexSplitterPatternParts(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.regexSplitterPatternPartOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getRegexSplitterPatternParts${url}`);
    return instance.get<Value>(url);
  },

  deleteRegexSplitterPatternPart(id: string): AxiosPromise {
    logger.debug('deleteRegexSplitterPatternPart');
    return instance.delete(`${URLS.regexSplitterPatternPart}/${id}`);
  },
  updateRegexSplitterPatternPart(RegexSplitterPatternPart: RegexSplitterPatternPart): AxiosPromise<any> {
    logger.debug('updateRegexSplitterPatternPart');
    return instance.put<RegexSplitterPatternPart>(
      `${URLS.regexSplitterPatternPart}/update`,
      regexSplitterPatternPart.toAPI(RegexSplitterPatternPart)
    );
  },
};
