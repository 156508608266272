import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import userBillingInfo, { UserBillingInfo, Value } from '../model/userBillingInfo';
import ODataFilterBuilder from 'odata-filter-builder';
import JsObjectUtils from '../utils/jsObjectUtils';

const logger = new Logger('backend.UserBillingInfo');
export interface BackendUserBillingInfo {
  getUserBillingInfo(): AxiosPromise<UserBillingInfo>;
  updateUserBillingInfo(UserBillingInfo: UserBillingInfo): AxiosPromise<any>;
}

export const defaultBackendUserBillingInfo: BackendUserBillingInfo = {
  getUserBillingInfo(): AxiosPromise<UserBillingInfo> {
    const url = `${URLS.userBillingInfo}/get`;
    return instance.get<UserBillingInfo>(url);
  },

  updateUserBillingInfo(data: UserBillingInfo): AxiosPromise<any> {
    logger.debug('updateUserBillingInfo');

    if (data.id) {
      return instance.patch<UserBillingInfo>(`${URLS.userBillingInfoOdata}/${data.id}`, userBillingInfo.toAPI(data));
    } else {
      const formData = JsObjectUtils.getFormData(userBillingInfo.toAPI(data));
      return instance.post<UserBillingInfo>(`${URLS.userBillingInfoOdata}`, formData);
    }
  },
};
