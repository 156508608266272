import { ActionTree } from 'vuex';
import { AddressState } from './types';
import { RootState } from '../../types';
import { defaultBackendAddress } from '@/shared/backend/address';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import address, { Value, Address } from '@/shared/model/address';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.Addresses');
export const actions: ActionTree<AddressState, RootState> = {
  getAddresses(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; folderId?: string; searchTerm?: string }
  ) {
    commit('setAddressesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAddressesSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendAddress
      .getAddresses(searchParams, organizationId, payload?.searchTerm)
      .then((response: AxiosResponse<Value>) => {
        commit('setAddresses', response.data);
        commit('setAddressesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAddressesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddress({ commit, dispatch }, id: string) {
    commit('setInProgressGetAddress', true);

    return defaultBackendAddress
      .getAddress(id)
      .then((response: AxiosResponse<Address>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressGetAddress', false);
      });
  },
  updateAddress({ commit, dispatch, rootGetters }, file: Address) {
    const organizationId = rootGetters['organization/getOrganization'].id;
    file.organisationId = organizationId;
    return defaultBackendAddress
      .updateAddress(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Address;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.address_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadAddress(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    commit('setIsImporting', true);
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendAddress
      .uploadAddress(fileInfo)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.address_uploaded`), commit);
        commit('setIsImporting', false);
        return response.data;
      })
      .catch((e: any) => {
        //show first error from responce if exits translate
        let error = [{ code: 'error', description: i18n.tc('error') }];
        if (Array.isArray(e.response.data.errors?.$values)) error = e.response.data.errors?.$values;
        if (Array.isArray(e.response.data.errors)) error = e.response.data.errors;
        if (i18n.te('errors.' + error[0].code)) error[0].description = i18n.tc('errors.' + error[0].code);

        module_utils.error(error[0].description, commit, e, logger);
        throw e.response.data;
      })
      .finally(() => {});
  },
  deleteAddress({ commit, dispatch }, id: string) {
    return defaultBackendAddress
      .deleteAddress(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Address;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
