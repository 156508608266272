import store from '../shared/store/index';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { DefaultAuthService } from '@/shared/utils/AuthService';
import { Logger } from 'fsts';
const logger = new Logger('router');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isLoggedIn'] ?? false; //router.app.$auth.isAuthenticated || false; // store.getters['auth/isLoggedIn'];
  if (isAuthenticated) {
    //already signed in, we can navigate anywhere
    logger.log('authenticating a protected url:' + to.path);
    if (to.path == '/trash' && store.getters['auth/DeleteDocumentsFromTrashAllowed'] != true) {
      // route back to dashboard organisation if disallowed DeleteDocumentsFromTrash
      next('/dashboard/organization/');
    } else if (to.path.startsWith('/export') && store.getters['auth/ExportDocumentAllocationsAllowed'] != true) {
      logger.debug(`export:>> ${to.path} export:${store.getters['auth/ExportDocumentAllocationsAllowed']}`);
      // route back to dashboard organisation if disallowed DeleteDocumentsFromTrash
      next('/dashboard/organization/');
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    logger.log('not authenticated url:' + to.fullPath);
    //
    store
      .dispatch('auth/signinRedirect', { state: to.fullPath })
      .then(() => {
        logger.log('authenticating a protected url:' + to.path);
        next();
      })
      .catch((e) => {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        });
        return;
      });
  } else {
    //No auth required. We can navigate
    next();
  }
});
export default router;
