import { ActionTree } from 'vuex';
import { OrganisationDocumentSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationDocumentSetting } from '@/shared/backend/organisationDocumentSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationDocumentSetting, {
  Value,
  OrganisationDocumentSetting,
} from '@/shared/model/organisationDocumentSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationDocumentSettings');
export const actions: ActionTree<OrganisationDocumentSettingState, RootState> = {
  getOrganisationDocumentSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setOrganisationDocumentSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationDocumentSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationDocumentSetting
      .getOrganisationDocumentSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationDocumentSettings', response.data);
        commit('setOrganisationDocumentSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationDocumentSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationDocumentSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setOrganisationDocumentSettingsIsLoading', true);
    const searchParams = getters.getOrganisationDocumentSettingsSearchParams;
    searchParams.dataOption.itemsPerPage = 1;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationDocumentSetting
      .getOrganisationDocumentSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationDocumentSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganisationDocumentSettingsIsLoading', false);
      });
  },
  getOrganisationDocumentSettingById({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentSetting
      .getOrganisationDocumentSetting(id)
      .then((response: AxiosResponse<OrganisationDocumentSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationDocumentSetting({ commit, dispatch }, file: OrganisationDocumentSetting) {
    commit('setIsUpdating', true);
    return defaultBackendOrganisationDocumentSetting
      .updateOrganisationDocumentSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_document_setting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
  deleteOrganisationDocumentSetting({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentSetting
      .deleteOrganisationDocumentSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
