export interface AllocationsProperty {
  id: string; // System.Guid
  allocationId: string; // System.Guid
  organisationId: string; // System.Guid
  key: string; // string
  value: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  documentId: string; // System.Guid
}

export interface Value {
  value: AllocationsProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationsProperty>): any {
  return {
    Id: data?.id || undefined,
    AllocationId: data?.allocationId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Key: data?.key || '',
    Value: data?.value || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    DocumentId: data?.documentId || undefined,
  };
}

function parse(data?: Partial<AllocationsProperty>): AllocationsProperty {
  return {
    id: data?.id || '',
    allocationId: data?.allocationId || '',
    organisationId: data?.organisationId || '',
    key: data?.key || '',
    value: data?.value || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    documentId: data?.documentId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
