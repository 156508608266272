// TODO: (ED-944) (low) change frontend model to be more similar to backend ActionLogDm model(https://github.com/dev4yougmbh/easy-docs-services/blob/dev/Services/Backend/Backend.DataAccessPostgreSQL/Database/DataModel/ActionLogDm.cs)
export interface ActionLog {
  id: string;
  actionLogId: string;
  actionLogInfos: string;
  actionLogMessage: string;
  actionLogTitle: string;
  actionLogSubtitle: string;
  changeTimeStamp: number;
  closed: boolean;
  createTime: string;
  createTimeStamp: number;
  documentName: string;
  documentNumber: string;
  documentId: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  data: ActionLogMessage;
  receiverName: string;
  senderName: string;
  type: string;
  userId: string;

  threadId: string;
  threadType: string;

  createdAt: string;
  updatedAt: string;
  folderId: string;
  dueTimeStamp: string;
  read: string;
}
export interface ActionLogMessage {
  roles: string[];
  closed: boolean;
  dueDate: string;
  members: string[];
  message: string;
  name: string;
  ruleIndex: string;
  subject: string;
  actionLogInfos: string;
  notificationType: string;
  memberIds: string[];
  roleIds: string[];
  isEmailNotify: boolean;
  date: string;
  address: string;
}

function parseActionLogMessage(data?: Partial<ActionLogMessage>): ActionLogMessage {
  return {
    roles: data?.roleIds || [],
    closed: data?.closed || false,
    dueDate: data?.dueDate || '',
    members: data?.members || [],
    message: data?.message || '',
    name: data?.name || '',
    ruleIndex: data?.ruleIndex || '',
    subject: data?.subject || '',
    actionLogInfos: data?.actionLogInfos || '',
    notificationType: data?.notificationType || '',
    memberIds: data?.memberIds || [],
    roleIds: data?.roleIds || [],
    isEmailNotify: data?.isEmailNotify || false,
    date: data?.date || '',
    address: data?.address || '',
  };
}
export interface Value {
  value: ActionLog[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ActionLog>): any {
  return {
    Id: data?.id || undefined,
    ActionLogInfos: data?.actionLogInfos || '',
    ActionLogMessage: data?.actionLogMessage || '',
    ChangeTimeStamp: data?.changeTimeStamp || 0,
    Closed: data?.closed || 0,
    CreateTime: data?.createTime || '',
    CreateTimeStamp: data?.createTimeStamp || 0,
    DocumentName: data?.documentName || '',
    DocumentNumber: data?.documentNumber || '',
    DocumentId: data?.documentId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Data: data?.data || '',
    ReceiverName: data?.receiverName || '',
    SenderName: data?.senderName || '',
    Type: data?.type || '',
    UserId: data?.userId || undefined,

    threadId: data?.threadId || undefined,
    threadType: data?.threadType || '',

    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    FolderId: data?.folderId || undefined,
    DueTimeStamp: data?.dueTimeStamp || undefined,
  };
}

function parse(data?: Partial<ActionLog>): ActionLog {
  return {
    id: data?.id || '',
    actionLogId: data?.actionLogId || '',
    actionLogInfos: data?.actionLogInfos || '',
    actionLogMessage: data?.actionLogMessage || '',
    actionLogTitle: data?.actionLogTitle || '',
    actionLogSubtitle: data?.actionLogSubtitle || '',
    changeTimeStamp: data?.changeTimeStamp || 0,
    closed: data?.closed || false,
    createTime: data?.createTime || '',
    createTimeStamp: data?.createTimeStamp || 0,
    documentName: data?.documentName || '',
    documentNumber: data?.documentNumber || '',
    documentId: data?.documentId || '',
    organisationId: data?.organisationId || '',
    data: data?.data || parseActionLogMessage({}),
    receiverName: data?.receiverName || '',
    senderName: data?.senderName || '',
    type: data?.type || '',
    userId: data?.userId || '',

    threadId: data?.threadId || '',
    threadType: data?.threadType || '',

    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    folderId: data?.folderId || '',
    dueTimeStamp: data?.dueTimeStamp || '',
    read: data?.read || '',
  };
}

export default {
  parse,
  parseActionLogMessage,
  toAPI: toAPI,
};
