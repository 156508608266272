import { GetterTree } from 'vuex';
import { RegexSplitterPartState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RegexSplitterPartState, RootState> = {
  ['getRegexSplitterParts']: (state) => state.regexSplitterParts,
  ['getRegexSplitterPartsIsLoading']: (state) => state.regexSplitterParts?.isLoading,
  ['getRegexSplitterPartsTotal']: (state) => state.regexSplitterParts?.total,
  ['getRegexSplitterPartsSearchParams']: (state) => state.regexSplitterParts?.searchParams,
};
