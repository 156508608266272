import document from '@/shared/model/document';
import { FilterBntValues } from '@/shared/model/smallPayloadModels/filterBtnData';
import { DocumentState } from './types';

export const state = (): DocumentState => initialState();

export const initialState = (): DocumentState => ({
  selectionMode: false,
  selectedDocumentIds: [],
  hasActiveSearch: false,
  isMetadataProcessing: false,
  documentSearchData: undefined,
  document: document.parse({}),
  documentTemp: document.parse({}),
  documentIsLoading: false,
  documents: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50, // (ED-236) Discussed `50` items with german customer (his wish)
        sortBy: ['DocumentNumber'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  documentsTemp: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['DocumentNumber'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  documentsAllCount: 0,
  documentsDeleted: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['Name'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  documentSearchGobdStatus: 0,
  documentsSearchPayload: {
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50, // (ED-236) Discussed `50` items with german customer (his wish)
        sortBy: ['DocumentNumber'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
    showDeleted: false,
    isOnlyCurrentFolderDocs: false,
    folderId: undefined,
    searchModes: '',
    searchData: { searchWord: '', field: '', searchType: '' },
    chipData: undefined,
    chipDataArray: [],
    filterData: [],
  },
  documentIdsForWhichDocumentPropertiesIsLoading: [],
  documentPropertiesSearchParams: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 3,
        sortBy: ['organisationDocumentProperty/SortOrder'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  documentsSearchUrl: '',
  documentsSearchUrlOld: '',
  documentRoleRights: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['roleName'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['roleName'],
      orClauseFieldsIdsConvertToString: [],
      orClauseFieldsIdsIgnoreCase: ['roleName'],
    },
  },
  updateInProgress: false,
  documentsNeedUpdate: [],
  isDocumentsNeedUpdate: false,
  lastLoadDocumentsDate: new Date(),
});
