import { AxiosPromise } from 'axios';
import { instance } from './index';
import { URLS } from './index';
import credentials, { Credetials } from '../model/credentials';
import i18n from '@/i18n';

export interface BackendAccount {
  authenticate: (
    userName: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    result: {
      authToken: string;
      refreshToken: string;
    };
  }>;
  getMyAccount: () => AxiosPromise<any>;
  logout: () => AxiosPromise;
  impersonateUser: (userId: string) => AxiosPromise<any>;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    userName: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    result: {
      authToken: string;
      refreshToken: string;
    };
  }> {
    const c: Credetials = credentials.parse({
      password,
      userName,
      rememberMe,
    });

    const url = require('url');
    const params = new URLSearchParams();
    params.append('password', c.password!);
    params.append('username', c.userName!);
    params.append('client_id', 'vue_client');
    params.append('grant_type', 'password');
    return instance.post<any>(`${URLS.account}/token`, params);
  },
  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/userinfo`);
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },

  impersonateUser(userId: string): AxiosPromise<any> {
    return instance.post<any>(`${URLS.impersonate}/ImpersonateUser/${userId}`);
  },
};
