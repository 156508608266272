import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationAllocationProperty, {
  OrganisationAllocationProperty,
  Value,
} from '../model/organisationAllocationProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationAllocationProperty');
export interface BackendOrganisationAllocationProperty {
  getOrganisationAllocationProperty(id: string): AxiosPromise<OrganisationAllocationProperty>;
  getOrganisationAllocationPropertys: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  updateOrganisationAllocationProperty(
    OrganisationAllocationProperty: OrganisationAllocationProperty
  ): AxiosPromise<any>;
  moveOrganisationAllocationProperty(payload: {
    element: OrganisationAllocationProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any>;
}

export const defaultBackendOrganisationAllocationProperty: BackendOrganisationAllocationProperty = {
  getOrganisationAllocationProperty(id: string): AxiosPromise<OrganisationAllocationProperty> {
    const url = `${URLS.organisationAllocationPropertyOdata}/${id}`;
    return instance.get<OrganisationAllocationProperty>(url);
  },
  getOrganisationAllocationPropertys(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationAllocationPropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOrganisationAllocationPropertys${url}`);
    return instance.get<Value>(url);
  },

  updateOrganisationAllocationProperty(
    OrganisationAllocationProperty: OrganisationAllocationProperty
  ): AxiosPromise<any> {
    logger.debug('updateOrganisationAllocationProperty');
    return instance.put<OrganisationAllocationProperty>(
      `${URLS.organisationAllocationProperty}/update`,
      organisationAllocationProperty.toAPI(OrganisationAllocationProperty)
    );
  },
  moveOrganisationAllocationProperty(payload: {
    element: OrganisationAllocationProperty;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any> {
    logger.debug('moveOrganisationAllocationProperty');
    const item = organisationAllocationProperty.toAPI(payload.element);
    item.NewIndex = payload.newIndex;
    item.OldIndex = payload.oldIndex;
    return instance.put<OrganisationAllocationProperty>(`${URLS.organisationAllocationProperty}/move`, item);
  },
};
