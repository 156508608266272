import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DocumentSwitchesView from '@/views/menu/rightMainMenu/menu-document-preview/document-switches/document-switches.vue';
import jsObjectUtils from '@/shared/utils/jsObjectUtils';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';

const logger = new Logger('menu-documents-status-values');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const documentStateModule = namespace('documentState');
const documentModule = namespace('document');

@Component({ name: 'menu-documents-status-values', components: { DocumentSwitchesView } })
export default class MenuDocumentsStatusValuesView extends Vue {
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @documentStateModule.Action('addDeleteDocumentStateMultiple')
  private actionAddDeleteDocumentStateMultiple!: any;

  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @documentModule.Mutation('setSelectedDocumentIds')
  private mutationSetSelectedDocumentIds!: any;

  @Prop({ default: () => {} })
  private selectedDocIds!: any;

  valuesState: any = {};
  statusValuesAndIds: any = [];

  created() {
    this.statusValuesAndIds = this.getOrganisationDocumentExtraStatusSettings.items.map(
      (item: OrganisationDocumentExtraStatusSetting) => ({
        id: item.id,
        name: item.name,
        statusValue: item.statusValue,
      })
    );
  }

  updateCheckbox(payload: any) {
    this.valuesState[payload.item.name] = { checkboxValue: payload.event, switchValue: payload.item.active };
  }

  updateSwitch(payload: any) {
    // if user just change `switch` position without checked `checkbox` then ignore it, on backend only values with checked `checkbox` will be sent
    if (this.valuesState[payload.name]) {
      this.valuesState[payload.name].switchValue = payload.active;
    }
  }

  private preparePayloadArray() {
    const objToArrayOfObjects = jsObjectUtils.mergeObjKeysAndValues(this.valuesState);

    const checkedStatuses = objToArrayOfObjects.filter((x: any) => x.checkboxValue);

    // rename `key` object key to `status` + delete `checkboxValue` always TRUE
    const formattedArray = checkedStatuses.map((obj: any) => {
      const returnObj = { ...obj }; // make object copy to avoid mutation of original `obj` (checkedStatuses) object
      const extraStatus = this.statusValuesAndIds.find((x: any) => x.name == returnObj['key']);
      returnObj['statusValue'] = extraStatus?.statusValue;
      returnObj['id'] = extraStatus?.id;

      delete returnObj.checkboxValue;
      delete returnObj.key;
      return returnObj;
    });

    return formattedArray;
  }

  private saveDocsStatusValues() {
    const checkedStatuses = this.preparePayloadArray();
    logger.log('checkedStatuses :>> ', checkedStatuses);

    const payload = {
      documentIds: this.selectedDocIds,
      values: checkedStatuses,
    };
    logger.log('saveDocsStatusValues payload :>> ', payload);
    logger.debug('saveDocsStatusValues');
    this.actionAddDeleteDocumentStateMultiple(payload)
      .then((result: any) => {
        this.actionGetDocuments();
        this.resetDocumentSelectionAndReturnToList();
      })
      .catch((err: any) => {
        logger.error(err);
        this.resetDocumentSelectionAndReturnToList();
      });
  }

  private resetDocumentSelectionAndReturnToList() {
    this.mutationSetSelectedDocumentIds([]);
    this.returnBack();
  }

  private returnBack() {
    this.$emit('click:return');
  }
}
