import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { Snackbar } from '../model/snackbar';
import { RootState } from './types';
import { instance } from '../backend';
import { auth } from './modules/auth';
import { userFile } from './modules/userFile';
import { folder } from './modules/folder';
import { documentStatus } from './modules/documentStatus';
import { document } from './modules/document';
import { documentState } from './modules/documentState';
import { organization } from './modules/organization';
import { employee } from './modules/employee';
import { role } from './modules/role';
import { roleLoginTime } from './modules/roleLoginTime';
import { roleRight } from './modules/roleRight';
import { folderRule } from './modules/folderRule';
import { comment } from './modules/comment';
import { costObjective } from './modules/costObjective';
import { costObjectiveImportSetting } from './modules/costObjectiveImportSetting';
import { costCentre } from './modules/costCentre';
import { costCentreImportSetting } from './modules/costCentreImportSetting';
import { actionLog } from './modules/actionLog';
import { address } from './modules/address';
import { export_ } from './modules/export';
import { gobdData } from './modules/gobdData';
import { office } from './modules/office';
import { organisationAddress } from './modules/organisationAddress';
import { organisationDocumentExtraStatusSetting } from './modules/organisationDocumentExtraStatusSetting';
import { organisationDocumentProperty } from './modules/organisationDocumentProperty';
import { documentProperty } from './modules/documentProperty';
import { allocationSetting } from './modules/allocationSetting';
import { allocationBrandsImportSetting } from './modules/allocationBrandsImportSetting';
import { allocationBrand } from './modules/allocationBrand';
import { allocationOffice } from './modules/allocationOffice';
import { allocationOfficesImportSetting } from './modules/allocationOfficesImportSetting';
import { allocationTaxGroup } from './modules/allocationTaxGroup';
import { allocationTaxGroupsImportSetting } from './modules/allocationTaxGroupsImportSetting';
import { allocationTaxSetting } from './modules/allocationTaxSetting';
import { templateMarkup } from './modules/templateMarkup';
import { tradeChannel } from './modules/tradeChannel';
import { tradeChannelImportSetting } from './modules/tradeChannelImportSetting';
import { driveType } from './modules/driveType';
import { driveTypesImportSetting } from './modules/driveTypesImportSetting';
import { supplier } from './modules/supplier';
import { suppliersImportSetting } from './modules/suppliersImportSetting';
import { addressesImportSetting } from './modules/addressesImportSetting';
import { allocationFrame } from './modules/allocationFrame';
import { organisationDocumentSetting } from './modules/organisationDocumentSetting';
import { datevSetting } from './modules/datevSetting';
import { organisationAllocationProperty } from './modules/organisationAllocationProperty';
import { organisationAllocationExportProperty } from './modules/organisationAllocationExportProperty';
import { userBillingInfo } from './modules/userBillingInfo';
import { userPackage } from './modules/userPackage';
import { user } from './modules/user';
import { file } from './modules/file';
import { documentFile } from './modules/documentFile';
import { documentPagesText } from './modules/documentPagesText';
import { filePagesText } from './modules/filePagesText';
import { actionLogRole } from './modules/actionLogRole';
import { allocationFrameProperty } from './modules/allocationFrameProperty';
import { allocation } from './modules/allocation';
import { allocationsProperty } from './modules/allocationsProperty';
import { allocationTemplate } from './modules/allocationTemplate';
import { allocationTemplateProperty } from './modules/allocationTemplateProperty';
import { autoGobdCheckTerm } from './modules/autoGobdCheckTerm';
import { allocationsExport } from './modules/allocationsExport';
import { processListsExport } from './modules/processListsExport';
import { documentFilesExport } from './modules/documentFilesExport';
import { documentEditSession } from './modules/documentEditSession';
import { allocationsExportAllocation } from './modules/allocationsExportAllocation';
import { regexSplitter } from './modules/regexSplitter';
import { regexSplitterPart } from './modules/regexSplitterPart';
import { regexSplitterPatternPart } from './modules/regexSplitterPatternPart';
import { organisationDocumentListSetting } from './modules/organisationDocumentListSetting';
import { fileUpload } from './modules/fileUpload';
import { documentReminder } from './modules/documentReminder';

import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export const actions: ActionTree<State, RootState> = {
  async releaseSnackbar({ commit }) {
    commit('setSnackbarVisibility', false);
  },
  setLocale({ commit }) {
    // i18n.locale = localStorage.getItem('locale')!;
    // commit('set_currentLanguage', i18n.locale);
  },
  async setListViewMode({ commit }, isListviewMode: boolean) {
    commit('set_isListviewMode', isListviewMode);
  },
  /**
   * Load backend API version information.
   * @param commit
   */
  ['load_info']: ({ commit }) => {
    instance.get<any>('/api/info').then((value) => {
      if (value && value.data) {
        commit('set_info', value.data);
      }
    });
  },
  resetAllState({ commit }) {
    temp.forEach((moduleName: any) => {
      commit(`${moduleName}/reset`);
    });
  },
};

var temp = ['auth'];
export const mutations: MutationTree<State> = {
  showError: (s: State, payload: any) => {
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload;
    s.snackbar.duration = 6000; // default duration at `https://neu.easy-docs.de/` is about 2000-3000 ms
  },
  showSuccess: (s: State, payload: any) => {
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload;
    s.snackbar.duration = 1000; // default duration at `https://neu.easy-docs.de/` is about 2000-3000 ms
  },
  setSnackbarError: (s: State, payload: any) => {
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarSuccess: (s: State, payload: any) => {
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarVisibility: (s: State, visibility: boolean) => {
    s.snackbar.show = visibility;
  },
  ['set_info']: (s: State, info: any) => {
    s.apiInfo = info;
  },
  ['set_currentLanguage']: (s: State, currentLanguage: string) => {
    s.currentLanguage = currentLanguage;
  },
  ['set_isListviewMode']: (s: State, isListviewMode: boolean) => {
    s.isListviewMode = isListviewMode;
  },
};

export const getters: GetterTree<State, RootState> = {
  ['info']: (s: State) => s.apiInfo,
  ['frontend_info']: (s: State) => process.env.VERSION || 0,
  ['currentLanguage']: (s: State) => s.currentLanguage,
  ['isListviewMode']: (s: State) => s.isListviewMode,
};

export interface State {
  apiInfo: string | null;
  snackbar: Snackbar;
  currentLanguage: string;
  isListviewMode: boolean;
}

export const state = (): State => ({
  apiInfo: null,
  snackbar: {
    show: false,
    color: '',
    text: '',
    duration: 5000,
  },
  currentLanguage: '',
  isListviewMode: false,
});

const storeOptions: StoreOptions<RootState> = {
  state: state() as any,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    userFile,
    folder,
    datevSetting,
    documentStatus,
    document,
    documentState,
    organization,
    employee,
    role,
    roleLoginTime,
    roleRight,
    folderRule,
    comment,
    costObjective,
    costObjectiveImportSetting,
    costCentre,
    costCentreImportSetting,
    actionLog,
    address,
    addressesImportSetting,
    export_,
    gobdData,
    office,
    organisationAddress,
    organisationDocumentExtraStatusSetting,
    organisationDocumentProperty,
    documentProperty,
    organisationDocumentSetting,
    organisationAllocationProperty,
    organisationAllocationExportProperty,
    allocationBrandsImportSetting,
    allocationBrand,
    allocationFrame,
    allocationSetting,
    allocationOfficesImportSetting,
    allocationOffice,
    allocationTaxGroup,
    allocationTaxGroupsImportSetting,
    allocationTaxSetting,
    templateMarkup,
    tradeChannel,
    tradeChannelImportSetting,
    driveType,
    driveTypesImportSetting,
    supplier,
    suppliersImportSetting,
    userBillingInfo,
    userPackage,
    user,
    file,
    documentFile,
    documentPagesText,
    filePagesText,
    actionLogRole,
    allocationFrameProperty,
    allocation,
    allocationsProperty,
    allocationTemplate,
    allocationTemplateProperty,
    autoGobdCheckTerm,
    allocationsExport,
    processListsExport,
    documentFilesExport,
    documentEditSession,
    allocationsExportAllocation,
    regexSplitter,
    regexSplitterPart,
    regexSplitterPatternPart,
    organisationDocumentListSetting,
    fileUpload,
    documentReminder,
  },
  plugins: [
    createPersistedState({
      key: 'dev4you easy-docs',
      paths: [
        'auth.isLoggedIn',
        'auth.account.profile',
        'auth.headerNavData',
        'address.isImporting',
        'address.importingFile',
        'document.documentsSearchPayload',
        'organization.currentOrganization',
        'apiInfo',
        'allocationsExport',
        'documentFilesExports',

        //TODO for processes export
      ],
    }),
  ],
};

const store = new Vuex.Store<RootState>(storeOptions);

export default store;
