import { GetterTree } from 'vuex';
import { RegexSplitterState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RegexSplitterState, RootState> = {
  ['getRegexSplitters']: (state) => state.regexSplitters,
  ['getRegexSplittersIsLoading']: (state) => state.regexSplitters?.isLoading,
  ['getRegexSplittersTotal']: (state) => state.regexSplitters?.total,
  ['getRegexSplittersSearchParams']: (state) => state.regexSplitters?.searchParams,
};
