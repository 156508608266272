export default class JsArrayUtils {
  /**
   * Return the last element of an array
   * @param {array} array
   * @returns {any} last element of the passed array
   */
  public static getLastArrayElement(array: Array<any>): any {
    if (array.length == 0) {
      throw new Error('Cannot get last element of empty array!');
    }

    const lastElement = array[array.length - 1]; // the fastest way according to https://stackoverflow.com/a/61839489 (2020-05)
    return lastElement;
  }
}
