import { Address } from '@/shared/model/address';
import actionLog, { ActionLog } from './actionLog';
import { DocumentProperty } from './documentProperty';
import gobdData, { GobdData } from './gobdData';
import { Comment } from './comment';
import { DocumentPagesText } from './documentPagesText';
import { File } from './file';
import updatedDiff from '@/shared/utils/deep-object-utils';
import DateUtils from '../utils/DateUtils';
import { Allocation } from './allocation';
import documentReminders, { DocumentReminder } from './documentReminder';

export interface Document {
  id: string;
  storageId: string;
  documentNumber: number;
  pageCount: number;

  name: string;
  text: string;
  size: string;
  organisationId: string;
  folderId: string;

  deleted: boolean;
  imagePreview: string;
  previewFileId: string;
  createdAt: string;
  realDeleteAfter?: number; // in https://neu.easy-docs.de/ the `Unix timestamp` in seconds is used

  documentStates: { name: string; id: string; updatedAt?: Date }[];
  documentGobdDatum?: GobdData;
  documentDetailComments?: Comment[];
  documentPropertys?: DocumentProperty[];
  documentPropertysString?: string;
  enabledFavoriteValue1: string;
  enabledFavoriteValue2: string;
  enabledFavoriteValue3: string;
  actionLogs?: ActionLog[];
  documentReminders?: DocumentReminder[];

  vorkontiert: string;
  iban: string;
  kundennummer: string;
  steuernummer: string;

  addressId: string;
  adresseKundennummer: string;
  adresseSeriennummer: string;
  adresseKennzeichen: string;
  address?: Address;

  documentPagesText: string;
  documentPagesTextId: string;
  documentFiles?: { id: string; fileId: string; documentId: string; file: File; mergedToFileId: string }[];

  newStatusUserId: string;
  newStatusId: string;
  newInFolderStatusUserId: string;
  newInFolderStatusId: string;
  payedStatusUserId: string;
  payedStatusId: string;
  acceptedStatusUserId: string;
  acceptedStatusId: string;
  allocatedStatusUserId: string;
  allocatedStatusId: string;
  documentedStatusUserId: string;
  documentedStatusId: string;
  finalCheckStatusUserId: string;
  finalCheckStatusId: string;
  hasgobdStatusUserId: string;
  hasgobdStatusId: string;
  extra1StatusUserId: string;
  extra1StatusId: string;
  extra2StatusUserId: string;
  extra2StatusId: string;
  extra3StatusUserId: string;
  extra3StatusId: string;
  extra4StatusUserId: string;
  extra4StatusId: string;
  extra5StatusUserId: string;
  extra5StatusId: string;

  extra6StatusUserId: string;
  extra6StatusId: string;
  extra7StatusUserId: string;
  extra7StatusId: string;
  extra8StatusUserId: string;
  extra8StatusId: string;
  extra9StatusUserId: string;
  extra9StatusId: string;
  extra10StatusUserId: string;
  extra10StatusId: string;
  extra11StatusUserId: string;
  extra11StatusId: string;
  extra12StatusUserId: string;
  extra12StatusId: string;

  // documentStateNew: boolean;
  // documentStateNewInFolder: boolean;
  documentStatePayed: boolean;
  documentStateAccepted: boolean;
  documentStateFinalCheck: boolean;
  documentStateAllocated: boolean;
  documentStateDocumented: boolean;
  documentStateHasGobd: boolean;
  documentStateExtra1: boolean;
  documentStateExtra2: boolean;
  documentStateExtra3: boolean;
  documentStateExtra4: boolean;
  documentStateExtra5: boolean;
  documentStateExtra6: boolean;
  documentStateExtra7: boolean;
  documentStateExtra8: boolean;
  documentStateExtra9: boolean;
  documentStateExtra10: boolean;
  documentStateExtra11: boolean;
  documentStateExtra12: boolean;
  documentHasNotes: boolean;

  allocations: Allocation[];
  haveAllocations: boolean;
  haveTemplateMarkups: boolean;
  'templateMarkups@odata.count': number;

  wasAppUpload: boolean;
  infoText: string;
  previewType: string;
  ocrdetectionDate: string;
  ocrparserUsed: string;
  isDocumentStateHasGobdYellow: boolean;
  isDocumentStateHasGobdGreen: boolean;
  isDocumentStateHasGobdBlue: boolean;
  documentStateHasGobdAmount?: number;
  hasActiveReminders: boolean;
  pdfExported: boolean;
  alternativeFiles?: { id: string; fileId: string; documentId: string; alternativeFileId: string }[];

  deleteAfter: string;
  threadCircular: ActionLog;
  threadQuestion: ActionLog;
  threadTask: ActionLog;
  threadsAllowed: { question: boolean; task: boolean; circular: boolean; newThreadsAllowed: boolean };
}

export interface Value {
  value: Document[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<Document>): any {
  return {
    Id: data?.id || undefined,
    StorageId: data?.storageId || '',
    DocumentNumber: data?.documentNumber || 0,
    PageCount: data?.pageCount || 0,

    Name: data?.name ?? '',
    Text: data?.text ?? '',
    Size: data?.size ?? '',
    OrganisationId: data?.organisationId || undefined,
    FolderId: data?.folderId || '',

    Deleted: data?.deleted || false,
    ImagePreview: data?.imagePreview || '',
    createdAt: data?.createdAt || undefined,
    RealDeleteAfter: data?.realDeleteAfter || undefined,

    Vorkontiert: data?.vorkontiert || '',
    Iban: data?.iban || '',
    Kundennummer: data?.kundennummer || '',
    Steuernummer: data?.steuernummer || '',

    AddressId: data?.addressId || undefined,
    AdresseKundennummer: data?.adresseKundennummer || '',
    AdresseSeriennummer: data?.adresseSeriennummer || '',
    AdresseKennzeichen: data?.adresseKennzeichen || '',
    PreviewFileId: data?.previewFileId || '',

    WasAppUpload: data?.wasAppUpload || false,
    InfoText: data?.infoText || '',
    PreviewType: data?.previewType || '',
    OcrdetectionDate: data?.ocrdetectionDate || '',
    OcrparserUsed: data?.ocrparserUsed || '',
    deleteAfter: data?.deleteAfter || undefined,
  };
}
function parse(data: Partial<Document>): Document {
  return {
    id: data!.id || '',
    storageId: data?.storageId || '',
    documentNumber: data?.documentNumber || 0,
    pageCount: data?.pageCount || 0,

    name: data!.name || '',
    text: data!.text || '',
    size: data!.size || '',
    organisationId: data?.organisationId || '',
    folderId: data?.folderId || '',

    deleted: data?.deleted || false,
    imagePreview: data?.imagePreview || '',
    previewFileId: data?.previewFileId || '',
    createdAt: data?.createdAt || '',
    realDeleteAfter: data?.realDeleteAfter || undefined,

    documentStates: data?.documentStates || [],
    documentGobdDatum: data?.documentGobdDatum?.id ? gobdData.parse(data?.documentGobdDatum) : undefined, // will show NULL in response
    documentDetailComments: data?.documentDetailComments || [], // will show NULL in response
    documentPropertys: data?.documentPropertysString
      ? JSON.parse(data?.documentPropertysString ?? '[]')
      : data?.documentPropertys,
    enabledFavoriteValue1: data?.enabledFavoriteValue1 || '',
    enabledFavoriteValue2: data?.enabledFavoriteValue2 || '',
    enabledFavoriteValue3: data?.enabledFavoriteValue3 || '',
    actionLogs: data?.actionLogs ? data?.actionLogs.map((x) => actionLog.parse(x)) : [], // will show NULL in response
    documentReminders: data?.documentReminders ? data?.documentReminders.map((x) => documentReminders.parse(x)) : [], // will show NULL in response

    vorkontiert: data?.vorkontiert || '',
    iban: data?.iban || '',
    kundennummer: data?.kundennummer || '',
    steuernummer: data?.steuernummer || '',

    addressId: data?.addressId || '',
    adresseKundennummer: data?.adresseKundennummer || '',
    adresseSeriennummer: data?.adresseSeriennummer || '',
    adresseKennzeichen: data?.adresseKennzeichen || '',
    address: data?.address || undefined, // will show NULL in response

    documentPagesText: data?.documentPagesText ?? '', // will show NULL in response
    documentPagesTextId:
      //if from documentView then documentPagesTextId if from documents with expand then from documentPagesText.id
      data?.documentPagesTextId ?? ((data?.documentPagesText as any) ? (data?.documentPagesText as any)['id'] : ''), // will show NULL in response
    documentFiles: data?.documentFiles || [],

    newStatusUserId: data?.newStatusUserId || '',
    newStatusId: data?.documentStates?.find((x) => x.name == 'new')?.id || '',
    newInFolderStatusUserId: data?.newInFolderStatusUserId || '',
    newInFolderStatusId: data?.documentStates?.find((x) => x.name == 'new_in_folder')?.id || '',
    payedStatusUserId: data?.payedStatusUserId || '',
    payedStatusId: data?.documentStates?.find((x) => x.name == 'payed')?.id || '',
    acceptedStatusUserId: data?.acceptedStatusUserId || '',
    acceptedStatusId: data?.documentStates?.find((x) => x.name == 'accepted')?.id || '',
    allocatedStatusUserId: data?.allocatedStatusUserId || '',
    allocatedStatusId: data?.documentStates?.find((x) => x.name == 'allocated')?.id || '',
    documentedStatusUserId: data?.documentedStatusUserId || '',
    documentedStatusId: data?.documentStates?.find((x) => x.name == 'documented')?.id || '',
    finalCheckStatusUserId: data?.finalCheckStatusUserId || '',
    finalCheckStatusId: data?.documentStates?.find((x) => x.name == 'final_check')?.id || '',
    hasgobdStatusUserId: data?.hasgobdStatusUserId || '',
    hasgobdStatusId: data?.documentStates?.find((x) => x.name == 'hasgobd')?.id || '',
    extra1StatusUserId: data?.extra1StatusUserId || '',
    extra1StatusId: data?.documentStates?.find((x) => x.name == 'extra1')?.id || '',
    extra2StatusUserId: data?.extra2StatusUserId || '',
    extra2StatusId: data?.documentStates?.find((x) => x.name == 'extra2')?.id || '',
    extra3StatusUserId: data?.extra3StatusUserId || '',
    extra3StatusId: data?.documentStates?.find((x) => x.name == 'extra3')?.id || '',
    extra4StatusUserId: data?.extra4StatusUserId || '',
    extra4StatusId: data?.documentStates?.find((x) => x.name == 'extra4')?.id || '',
    extra5StatusUserId: data?.extra5StatusUserId || '',
    extra5StatusId: data?.documentStates?.find((x) => x.name == 'extra5')?.id || '',
    extra6StatusUserId: data?.extra6StatusUserId || '',
    extra6StatusId: data?.documentStates?.find((x) => x.name == 'extra6')?.id || '',
    extra7StatusUserId: data?.extra7StatusUserId || '',
    extra7StatusId: data?.documentStates?.find((x) => x.name == 'extra7')?.id || '',
    extra8StatusUserId: data?.extra8StatusUserId || '',
    extra8StatusId: data?.documentStates?.find((x) => x.name == 'extra8')?.id || '',
    extra9StatusUserId: data?.extra9StatusUserId || '',
    extra9StatusId: data?.documentStates?.find((x) => x.name == 'extra9')?.id || '',
    extra10StatusUserId: data?.extra10StatusUserId || '',
    extra10StatusId: data?.documentStates?.find((x) => x.name == 'extra10')?.id || '',
    extra11StatusUserId: data?.extra11StatusUserId || '',
    extra11StatusId: data?.documentStates?.find((x) => x.name == 'extra11')?.id || '',
    extra12StatusUserId: data?.extra12StatusUserId || '',
    extra12StatusId: data?.documentStates?.find((x) => x.name == 'extra12')?.id || '',
    documentStateAccepted: data?.documentStateAccepted || false,
    documentStateAllocated: data?.documentStateAllocated || false,
    documentStateDocumented: data?.documentStateDocumented || false,
    documentStateHasGobd: data?.documentStateHasGobd || false,
    documentStatePayed: data?.documentStatePayed || false,
    documentStateFinalCheck: data?.documentStateFinalCheck || false,
    documentStateExtra1: data?.documentStateExtra1 || false,
    documentStateExtra2: data?.documentStateExtra2 || false,
    documentStateExtra3: data?.documentStateExtra3 || false,
    documentStateExtra4: data?.documentStateExtra4 || false,
    documentStateExtra5: data?.documentStateExtra5 || false,
    documentStateExtra6: data?.documentStateExtra6 || false,
    documentStateExtra7: data?.documentStateExtra7 || false,
    documentStateExtra8: data?.documentStateExtra8 || false,
    documentStateExtra9: data?.documentStateExtra9 || false,
    documentStateExtra10: data?.documentStateExtra10 || false,
    documentStateExtra11: data?.documentStateExtra11 || false,
    documentStateExtra12: data?.documentStateExtra12 || false,
    documentHasNotes: data?.documentHasNotes || false,

    allocations: data?.allocations || [],
    haveAllocations: ((data as any)['allocations@odata.count'] ?? 0) + data?.allocations?.length > 0,
    haveTemplateMarkups: (data as Document)['templateMarkups@odata.count'] > 0 || false,
    'templateMarkups@odata.count': (data as Document)['templateMarkups@odata.count'] || 0,
    wasAppUpload: data?.wasAppUpload || false,
    infoText: data?.infoText || '',
    previewType: data?.previewType || '',
    ocrdetectionDate: data?.ocrdetectionDate || '',
    ocrparserUsed: data?.ocrparserUsed || '',
    isDocumentStateHasGobdBlue: data?.isDocumentStateHasGobdBlue || false,
    isDocumentStateHasGobdGreen: data?.isDocumentStateHasGobdGreen || false,
    isDocumentStateHasGobdYellow: data?.isDocumentStateHasGobdYellow || false,
    documentStateHasGobdAmount: data?.documentStateHasGobdAmount,
    hasActiveReminders: data?.hasActiveReminders || false,
    pdfExported: data?.pdfExported || false,
    alternativeFiles: data?.alternativeFiles || [],

    deleteAfter: data?.deleteAfter || '',
    threadCircular: data?.threadCircular ?? actionLog.parse({}),
    threadQuestion: data?.threadQuestion ?? actionLog.parse({}),
    threadTask: data?.threadTask ?? actionLog.parse({}),
    threadsAllowed: data?.threadsAllowed ?? { question: false, task: false, circular: false, newThreadsAllowed: false },
  };
}

function compare(obj1: Document, obj2: Document) {
  const diff = updatedDiff(parse(obj1), parse(obj2));
  return Object.keys(diff).length == 0;
}

export default {
  parse,
  toAPI: toAPI,
  compare,
};
