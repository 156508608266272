import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import uf, { UserFile, Value } from '../model/userFile';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.user_files');
export interface BackendUserFile {
  getUserFile(id: string): AxiosPromise<UserFile>;
  getUserFiles: (searchParams: SearchParams) => AxiosPromise<Value>;
  uploadUserFile(payload: any): AxiosPromise<any>;
  downloadUserFile(payload: any): AxiosPromise<any>;
  deleteUserFile(id: string): AxiosPromise;
  updateUserFile(document: UserFile): AxiosPromise<UserFile>;
}

export const defaultBackendUserFile: BackendUserFile = {
  getUserFile(id: string): AxiosPromise<UserFile> {
    const url = `${URLS.userFileOdata}(${id})`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<UserFile>(url);
  },
  getUserFiles(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.userFileOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    return instance.get<Value>(url);
  },

  uploadUserFile(registerPayload: any): AxiosPromise<any> {
    return instance.post<any>(`${URLS.userFile}/post`, registerPayload);
  },
  downloadUserFile(id: any): AxiosPromise<any> {
    return instance.get<any>(`${URLS.userFile}/download/${id}`, {
      responseType: 'blob',
    });
  },

  deleteUserFile(id: string): AxiosPromise {
    logger.debug('deleteUserFile');
    return instance.delete(`${URLS.userFile}/${id}`);
  },
  updateUserFile(document: UserFile): AxiosPromise<UserFile> {
    return instance.put<UserFile>(`${URLS.userFile}/update`, uf.toAPI(document));
  },
};
