import { GetterTree } from 'vuex';
import { OrganisationAllocationPropertyState } from './types';
import { RootState } from '../../types';
import organisationAllocationProperty from '@/shared/model/organisationAllocationProperty';

export const getters: GetterTree<OrganisationAllocationPropertyState, RootState> = {
  ['getOrganisationAllocationProperties']: (state) => state.organisationAllocationProperties,
  ['getOrganisationAllocationPropertiesActiveItems']: (state) => {
    const items = state.organisationAllocationProperties?.items;
    const indexTax = items.findIndex((x) => x.fieldId == '53204fe8-b606-aab2-3c55-ece700000000');

    const tax_key_amount = organisationAllocationProperty.parse({
      fieldId: 'a98a9b59-8786-405d-bcfa-80bf4aea97c8',
      enabled: true,
    });
    items.splice(indexTax + 1, 0, tax_key_amount);
    return items.filter((x) => x.enabled == true);
  },
  ['getOrganisationAllocationPropertiesIsLoading']: (state) => state.organisationAllocationProperties?.isLoading,
  ['getOrganisationAllocationPropertiesTotal']: (state) => state.organisationAllocationProperties?.total,
  ['getOrganisationAllocationPropertiesSearchParams']: (state) => state.organisationAllocationProperties?.searchParams,
  ['getIsUpdating']: (state) => state.isUpdating,
  ['isActiveOrganisationAllocationProperty']: (state) => (id: string) =>
    state.organisationAllocationProperties?.items.find((item) => item.id === id)?.enabled == true,
};
