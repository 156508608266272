export interface CostObjectiveImportSetting {
  id: string; // ulong
  organisationId: string; // string
  importClearThreshold: number; // int
  clearBeforeImport: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: CostObjectiveImportSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<CostObjectiveImportSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || '',
    ImportClearThreshold: data?.importClearThreshold || 0,
    ClearBeforeImport: data?.clearBeforeImport || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<CostObjectiveImportSetting>): CostObjectiveImportSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    importClearThreshold: data?.importClearThreshold || 0,
    clearBeforeImport: data?.clearBeforeImport || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
