export interface TemplateMarkup {
  id: string; // System.Guid
  userFileId: string; // System.Guid
  documentId: string; // System.Guid
  page: number; // int
  x: number; // int
  y: number; // int
  width: number; // int
  height: number; // int
  fieldName: string; // string
  name: string; // string
  isMarker: boolean; // bool
  markerText: string; // string
  organisationId: string; // System.Guid
  isSelected: boolean;
}

export interface Value {
  value: TemplateMarkup[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TemplateMarkup>): any {
  return {
    Id: data?.id || undefined,
    UserFileId: data?.userFileId || undefined,
    documentId: data?.documentId || '',
    Page: data?.page || 0,
    X: data?.x || 0,
    Y: data?.y || 0,
    Width: data?.width || 0,
    Height: data?.height || 0,
    FieldName: data?.fieldName || '',
    Name: data?.name || '',
    IsMarker: data?.isMarker || false,
    MarkerText: data?.markerText || '',
    OrganisationId: data?.organisationId || undefined,
  };
}

function parse(data?: Partial<TemplateMarkup>): TemplateMarkup {
  return {
    id: data?.id || '',
    userFileId: data?.userFileId || '',
    documentId: data?.documentId || '',
    page: data?.page || 0,
    x: data?.x || 0,
    y: data?.y || 0,
    width: data?.width || 0,
    height: data?.height || 0,
    fieldName: data?.fieldName || '',
    name: data?.name || '',
    isMarker: data?.isMarker || false,
    markerText: data?.markerText || '',
    organisationId: data?.organisationId || '',
    isSelected: false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
