import { render, staticRenderFns } from "./document-qa-dialog.html?vue&type=template&id=c05cefca&scoped=true&external"
import script from "./document-qa-dialog.ts?vue&type=script&lang=ts&external"
export * from "./document-qa-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./document-qa-dialog.scss?vue&type=style&index=0&id=c05cefca&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c05cefca",
  null
  
)

/* custom blocks */
import block0 from "./document-qa-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-qa%2Fdocument-qa-dialog%2Fdocument-qa-dialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VDialog,VForm,VIcon,VMenu,VRow,VSelect,VSpacer,VTextField,VTextarea})
