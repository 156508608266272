import { MutationTree } from 'vuex';
import { UserFileState } from './types';
import uf, { Value, UserFile } from '@/shared/model/userFile';
import { Logger } from 'fsts';
const logger = new Logger('actions.userFile');

export const mutations: MutationTree<UserFileState> = {
  setUserFile(state, payload: UserFile) {
    state.userFile = payload;
  },
  setUserFileIsLoading(state, payload: string) {
    if (state.userFileIsLoading.includes(payload)) {
      const inddex = state.userFileIsLoading.indexOf(payload);
      state.userFileIsLoading.splice(inddex, 1);
    } else state.userFileIsLoading.push(payload);
  },
  resetUserFile(state, payload?: UserFile) {
    state.userFile = Object.assign({}, payload);
  },
  setUserFiles(state, payload: Value) {
    state.userFiles.items = payload.value.map((x) => uf.parse(x));
    state.userFiles.total = payload['@odata.count'] || 0;
    state.userFiles.isLoading = false;
  },
  setUserFilesTotal(state, payload: number) {
    state.userFiles.total = payload;
  },
  setUserFilesIsLoading(state, payload: boolean) {
    state.userFiles.isLoading = payload;
  },
};
