import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';

const logger = new Logger('ModalIdle');
const authModule = namespace('auth');
@Component({
  components: {},
})
export default class ModalIdle extends Vue {
  @authModule.Action('logout')
  private logout!: any;
  options = { width: 330, zIndex: 20 };

  showTimeInit: number = 60 * 1000;
  showTime: number = this.showTimeInit;
  get second() {
    return this.showTime / 1000;
  }

  @authModule.Getter('isLoggedIn')
  public isLoggedIn!: boolean;

  @Watch('isLoggedIn')
  isLoggedinChange(newVal: boolean) {
    if (newVal) {
      this.startTimer();
    }
  }

  startTimer() {
    const timerId = setInterval(() => {
      this.showTime -= 1000;
      if (!this.$store.state.idleVue.isIdle) {
        this.showTime = this.showTimeInit;
      }
      if (this.showTime < 1 && this.isLoggedIn) {
        clearInterval(timerId);
        this.logout();
      }
    }, 1000);
  }

  showDialog() {
    if (!this.isLoggedIn) return false;

    const idleState = this.$store.state.idleVue.isIdle;
    return idleState && this.showTime > 0;
  }
  mounted() {
    this.startTimer();
  }
}
