import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import costCentre, { CostCentre, Value } from '../model/costCentre';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.CostCentre');
export interface BackendCostCentre {
  getCostCentre(id: string): AxiosPromise<CostCentre>;
  getCostCentres: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteCostCentre(id: string): AxiosPromise;
  updateCostCentre(CostCentre: CostCentre): AxiosPromise<any>;
  uploadCostCentre(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendCostCentre: BackendCostCentre = {
  getCostCentre(id: string): AxiosPromise<CostCentre> {
    const url = `${URLS.costCentreOdata}/${id}`;
    return instance.get<CostCentre>(url);
  },
  getCostCentres(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.costCentreOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getCostCentres${url}`);
    return instance.get<Value>(url);
  },

  deleteCostCentre(id: string): AxiosPromise {
    logger.debug('deleteCostCentre');
    return instance.delete(`${URLS.costCentre}/${id}`);
  },
  updateCostCentre(CostCentre: CostCentre): AxiosPromise<any> {
    logger.debug('updateCostCentre');
    return instance.put<CostCentre>(`${URLS.costCentre}/update`, costCentre.toAPI(CostCentre));
  },
  uploadCostCentre(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.costCentre}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
