export interface Address {
  id: string;
  anrede: string;
  changeTimeStamp: number;
  isOwn: boolean;
  kennzeichen: string;
  konto: string;
  kontoInteger: number;
  mail1: string;
  mail2: string;
  nachname: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  ort: string;
  plz: string;
  seriennummer: string;
  strasse: string;
  tel1: string;
  tel2: string;
  userId: string;
  vorname: string;
  zbv1: string;
  zbv2: string;

  createdAt: string;
  updatedAt: string;
  documentsCount: number;
}

export interface Value {
  value: Address[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Address>): any {
  return {
    Id: data?.id || undefined,
    Anrede: data?.anrede || '',
    ChangeTimeStamp: data?.changeTimeStamp || 0,
    IsOwn: data?.isOwn || false,
    Kennzeichen: data?.kennzeichen || '',
    Konto: data?.konto || '',
    KontoInteger: data?.kontoInteger || 0,
    Mail1: data?.mail1 || '',
    Mail2: data?.mail2 || '',
    Nachname: data?.nachname || '',
    OrganisationId: data?.organisationId || undefined,
    Ort: data?.ort || '',
    Plz: data?.plz || '',
    Seriennummer: data?.seriennummer || '',
    Strasse: data?.strasse || '',
    Tel1: data?.tel1 || '',
    Tel2: data?.tel2 || '',
    UserId: data?.userId || undefined,
    Vorname: data?.vorname || '',
    Zbv1: data?.zbv1 || '',
    Zbv2: data?.zbv2 || '',

    Created_At: data?.createdAt || undefined,
    Updated_At: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<Address>): Address {
  return {
    id: data?.id || '',
    anrede: data?.anrede || '',
    changeTimeStamp: data?.changeTimeStamp || 0,
    isOwn: data?.isOwn || false,
    kennzeichen: data?.kennzeichen || '',
    konto: data?.konto || '',
    kontoInteger: data?.kontoInteger || 0,
    mail1: data?.mail1 || '',
    mail2: data?.mail2 || '',
    nachname: data?.nachname || '',
    organisationId: data?.organisationId || '',
    ort: data?.ort || '',
    plz: data?.plz || '',
    seriennummer: data?.seriennummer || '',
    strasse: data?.strasse || '',
    tel1: data?.tel1 || '',
    tel2: data?.tel2 || '',
    userId: data?.userId || '',
    vorname: data?.vorname || '',
    zbv1: data?.zbv1 || '',
    zbv2: data?.zbv2 || '',

    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    documentsCount: data?.documentsCount || 0,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
