import { MutationTree } from 'vuex';
import { FileState } from './types';
import Vue from 'vue';
import file, { File, Value } from '@/shared/model/file';

export const mutations: MutationTree<FileState> = {
  setFiles(state, payload: Value) {
    state.files.items = payload.value.map((x) => file.parse(x));
    state.files.total = payload['@odata.count'] || 0;
    state.files.isLoading = false;
  },
  setFilesTotal(state, payload: number) {
    state.files.total = payload;
  },
  setFilesIsLoading(state, payload: boolean) {
    state.files.isLoading = payload;
  },
};
