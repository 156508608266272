import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from 'oidc-client';
import { OdataItems } from '@/shared/model/OdataItems';
import { Logger } from 'fsts';
import GeneralUtils from '@/shared/utils/generalUtils';

const name = 'all-processes-waiting-indicator';
const logger = new Logger(name);
const authModule = namespace('auth');
const documentModule = namespace('document');
const documentPropertyModule = namespace('documentProperty');
const documentFileModule = namespace('documentFile');
const documentFilesExportModule = namespace('documentFilesExport');
const folderModule = namespace('folder');
const organizationModule = namespace('organization');
const userFileModule = namespace('userFile');
const userBillingInfoModule = namespace('userBillingInfo');
const allocationsExportModule = namespace('allocationsExport');

@Component({ name })
export default class AllProcessesWaitingIndicator extends Vue {
  @documentPropertyModule.Getter('getDocumentPropertysIsLoading')
  private documentPropertysIsLoading!: boolean;
  @organizationModule.Getter('getOrganizationsIsLoading')
  private getOrganizationsIsLoading!: boolean;
  @folderModule.Getter('menuFoldersIsLoading')
  private menuFoldersIsLoading!: boolean;
  @documentModule.Getter('getDocumentsIsLoading')
  private getDocumentsIsLoading!: boolean;
  @documentModule.Getter('getDocumentsDeletedIsLoading')
  private getDocumentsDeletedIsLoading!: boolean;
  @documentModule.Getter('getDocumentIsLoading')
  private getDocumentIsLoading!: boolean;
  @documentModule.Getter('isUpdateInProgress')
  private docmentIsUpdateInProgress!: boolean;
  @documentFileModule.Getter('getDocumentFilesIsLoading')
  private getDocumentFilesIsLoading!: boolean;
  @documentFileModule.Getter('getDocumentFileIsLoading')
  private getDocumentFileIsLoading!: boolean;
  @userFileModule.Getter('getUserFileIsLoading')
  private getUserFileIsLoading!: boolean;
  @userFileModule.Getter('getUserFilesIsLoading')
  private getUserFilesIsLoading!: boolean;
  @userBillingInfoModule.Getter('getInProgressUpdateUserBillingInfo')
  private inProgressUpdateUserBillingInfo!: any;
  @organizationModule.Getter('getInProgressUpdateToMain')
  private inProgressUpdateToMain!: any;

  @documentFilesExportModule.Getter('getDocumentFilesExportsIsLoading')
  private getDocumentFilesExportsIsLoading!: any;
  @allocationsExportModule.Getter('getAllocationsExportsIsLoading')
  private getAllocationsExportsIsLoading!: any;

  @authModule.Getter('isLoggedIn')
  public isLoggedIn!: boolean;
  @authModule.Getter('getAccount')
  private account!: User;

  get isLoading() {
    // return true;
    if (this.getOrganizationsIsLoading) return true;
    if (this.menuFoldersIsLoading) return true;
    if (this.getDocumentsIsLoading) return true;
    if (this.getDocumentsDeletedIsLoading) return true;
    if (this.getDocumentIsLoading) return true;
    if (this.docmentIsUpdateInProgress) return true;
    if (this.getDocumentFileIsLoading) return true;
    if (this.getDocumentFilesIsLoading) return true;
    if (this.getUserFileIsLoading) return true;
    if (this.getUserFilesIsLoading) return true;
    if (this.documentPropertysIsLoading) return true;
    if (this.inProgressUpdateUserBillingInfo) return true;
    if (this.inProgressUpdateToMain) return true;
    if (this.getDocumentFilesExportsIsLoading) return true;
    if (this.getAllocationsExportsIsLoading) return true;

    return false;
  }
}
