import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import doc, { Document } from '@/shared/model/document';
import JsArrayUtils from '@/shared/utils/jsArrayUtils';
import addr, { Address } from '@/shared/model/address';
import RouterUtils from '@/shared/utils/RouterUtils';
import docProperty, { DocumentProperty } from '@/shared/model/documentProperty';
import { SearchParams } from '@/shared/model/searchParams';
import { buildOnEnter } from '@/shared/utils/formUtils';
import i18n from '@/i18n';
import { OdataItems } from '@/shared/model/OdataItems';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import MenuDocumentReminders from './menu-document-reminders/menu-document-reminders.vue';

const logger = new Logger('menu-document-information');
const documentModule = namespace('document');
const addressModule = namespace('address');
const orgDocumentPropertyModule = namespace('organisationDocumentProperty');
const docPropertyModule = namespace('documentProperty');
const authModule = namespace('auth');

@Component({ name: 'menu-document-information', components: { MenuDocumentReminders } })
export default class MenuDocumentInformationView extends Vue {
  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocumentIds!: any;
  @authModule.Getter('EditDocumentInformationsAllowed')
  private editDocumentInformationsAllowed!: any;

  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Action('updateDocument')
  private actionUpdateDocument!: any;
  @documentModule.Action('updateDocumentInState')
  private actionUpdateDocumentInState!: any;
  @documentModule.Action('updateDocumentInformationMultiple')
  private updateDocumentInformationMultiple!: any;
  @documentModule.Getter('getDocument')
  private getterDocument!: any;
  @documentModule.Getter('getDocuments')
  private documents!: OdataItems<Document>;
  @documentModule.Mutation('setDocumentAddress')
  private mutationSetDocumentAddress?: any;
  @documentModule.Mutation('setDocumentsAddress')
  private mutationSetDocumentsAddress?: any;
  @documentModule.Mutation('setDocumentPropertys')
  private mutationSetDocumentPropertys?: any;

  @addressModule.Action('getAddresses')
  private actionGetAddresses?: any;
  @addressModule.Action('getAddress')
  private actionGetAddress?: any;
  @addressModule.Getter('inProgressGetAddress')
  private inProgressGetAddress!: boolean;

  @Prop({ default: '' })
  private documentNumber!: string;

  private isUpdateAllIbans = false;
  private isUpdateAllHomepages = false;
  private isUpdateAllTaxNumbers = false;

  private documentInfo: Document = doc.parse({});
  private initialInfo: Document = doc.parse({});
  private initialAddresses: string[] = [];
  private initialProperties: string[] = [];

  get isChangeSingleDocument() {
    return this.getterSelectedDocumentIds.length === 0;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  // TODO: (ED-480) move `autocomplete` with this logic in separate component since it is also used in `file-upload dialog`
  //#region : Address autocomplete logic
  // !!TODO: [03.12.2021] (ED-480) cannot save `address` name because DocumentDm `Name` property wrong, now we put FileName in it, can save it after changes from @Kitkevich (see https://dev4you.atlassian.net/browse/ED-480?focusedCommentId=10942)
  private addresses: Address[] = [];
  private currentAddress: Address = addr.parse({});
  private addressValue: string = ''; // will store `AddressId` from `getItemId`

  private isLoading = false;
  private hideNoData = true;

  // empty Address to show `no change` when edit multiple docs
  private emptyAddressForMultipleDocsEdit: Address = {
    ...addr.parse({}),
    id: '', //empty GUID
    konto: 'Keine Änderung', // 'no changes'
  };

  getItemId(item: Address) {
    // (ED-1113) Fix bug when saved wrong address, put `item` in `currentAddress` only if it equal to Autocomplete `v-model` id
    if (this.addressValue === item.id) {
      this.currentAddress = item;
    }
    return `${item.id}`;
  }

  getItemText(item: Address) {
    return `${item.konto} ${item.vorname} ${item.nachname} ${item.kennzeichen} ${item.seriennummer}`;
  }

  async searchAddresses(searchWord: any) {
    if (!searchWord || searchWord.length < 2) return;
    this.isLoading = true;

    await this.actionGetAddresses({ searchTerm: searchWord })
      .then((result: any) => {
        this.addresses = result.value;
        if (this.addresses.length == 0) {
          this.hideNoData = false; // show `NO addresses` menu
        } else {
          this.hideNoData = true;
        }
      })
      .catch((err: any) => {
        logger.error(err);
        this.hideNoData = false;
      })
      .finally(() => (this.isLoading = false));
  }

  clearAddresses() {
    this.documentInfo.address = undefined;
    this.documentInfo.addressId = '';
    this.documentInfo.adresseKennzeichen = '';
    this.documentInfo.adresseKundennummer = '';
    this.documentInfo.adresseSeriennummer = '';
  }

  //#endregion

  async created() {
    if (this.documentId && this.isChangeSingleDocument) {
      await this.loadDocumentIfEmptyState();
    } else {
      await this.loadLastSelectedDocumentFromDocumentList();
    }

    await this.loadOrgDocumentProperties(); // load `DocumentProperties` after document to have `documentId` from Document getter

    if (this.hasDocumentAddressId()) {
      this.loadDocumentAddress();
    } else if (!this.isChangeSingleDocument) {
      // (ED-538) If All (or last) `addressId` is empty then show `Keine Änderung` in the Address autocomplete
      this.addresses.push(this.emptyAddressForMultipleDocsEdit);
    }
    this.initialInfo = doc.parse(this.documentInfo);
  }
  mounted() {
    this.initialInfo = doc.parse(this.documentInfo);
  }
  private hasDocumentAddressId() {
    const hasDocumentAddressId = !!this.documentInfo.addressId;
    const hasDocumentAddressEntity = !!this.documentInfo.address;

    // (ED-1113) `this.documentInfo` in sync with getter `getDocument` and if clear Address and return to `document-preview` without save and then return again in info menu populate `addressId` from `Address` property
    if (!hasDocumentAddressId && hasDocumentAddressEntity) {
      this.documentInfo.addressId = this.documentInfo.address!.id;
    }
    return !!this.documentInfo.addressId;
  }

  private async loadDocumentAddress() {
    await this.actionGetAddress(this.documentInfo.addressId)
      .then((result: any) => {
        if (this.isChangeSingleDocument) {
          this.documentInfo.address = result;
          this.addressValue = this.documentInfo.addressId;
          this.addresses.push(result);
        } else {
          this.addresses.push(this.emptyAddressForMultipleDocsEdit);
          this.getItemText(this.emptyAddressForMultipleDocsEdit);
          this.getItemId(this.emptyAddressForMultipleDocsEdit);
          this.emptyAddressForMultipleDocsEdit.konto = this.$i18n.tc('no_changes'); // by default German locale string is used, but update it if another locale is used
        }
        this.initialAddresses = this.addresses.map((a) => a.id);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async loadLastSelectedDocumentFromDocumentList() {
    const lastSelectedDocumentId = JsArrayUtils.getLastArrayElement(this.getterSelectedDocumentIds);
    // TODO: (ED-449) not just return `information` for last selected document, but for the last document position in `document-list` (as now in https://neu.easy-docs.de/)
    await this.actionGetDocument(lastSelectedDocumentId)
      .then((result: any) => {
        this.documentInfo = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async loadDocumentIfEmptyState() {
    const doc = this.getterDocument;
    // reuse the `document`(NOT userFile) from the Vuex State if it is currently opened document, otherwise load correct `document` from the backend
    if (doc?.id && doc.id === this.documentId) {
      this.documentInfo = doc;
    } else {
      await this.actionGetDocument(this.documentId)
        .then((result: any) => {
          this.documentInfo = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private addressIdBeforeSave = '';
  private saveDocInformation() {
    logger.debug('saveDocInformation');
    if (this.isChangeSingleDocument) {
      this.addressIdBeforeSave = this.documentInfo.addressId; // (ED-538) need this `addressIdBeforeSave` to compare `addressId` on open `menu-information` and on Save to avoid extra request to `GetAddress` if value of `addressId` was not changed
      this.setAddressIdInPayload();
      this.saveDocumentPropertys([this.getterDocument.id]);
      this.actionUpdateDocument(this.documentInfo)
        .then((result: any) => {
          this.checkMultipleDocsAddress(
            { result: [this.getterDocument.id] },
            this.documentInfo.addressId ? this.documentInfo.addressId : RouterUtils.emptyGuid
          );
          this.$emit('click:return');
        })
        .catch((err: any) => {
          logger.error(err);
        });
    } else {
      const payload = this.formatPayloadForMultipleDocuments();
      this.updateDocumentInformationMultiple(payload)
        .then((result: any) => {
          this.checkMultipleDocsAddress(result, payload.documentIdsWithData.commonAddressId);
          this.$emit('click:return');
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  async checkMultipleDocsAddress(result: any, addressId: any) {
    const documentIds = result.result;
    // (ED-538) if Delete `address` for multiple Documents (empty GUID for `addressId`) then don't call the `checkDocAddress` method (will return error)
    if (RouterUtils.IsEmptyGuid(addressId)) {
      this.mutationSetDocumentsAddress({ address: null, documentIds });
      return;
    }
    await this.checkDocAddress(addressId, documentIds);
  }

  async checkDocAddress(addressIdFromBackend: string, documentIds?: any) {
    // if `addressId` was changed query new Address from backend and put it in Vuex
    if (this.addressIdBeforeSave != addressIdFromBackend && addressIdFromBackend && addressIdFromBackend.length > 0) {
      await this.actionGetAddress(addressIdFromBackend)
        .then((result: Address) => {
          this.mutationSetDocumentAddress(result);
          this.mutationSetDocumentsAddress({ address: result, documentIds });
        })
        .catch((err: any) => {
          logger.error(err);
        });
    } else if (this.documentInfo.addressId != addressIdFromBackend && !addressIdFromBackend) {
      // clear Vuex `document.address` if `addressId` was deleted
      this.mutationSetDocumentAddress(null);
    }
  }

  setAddressIdInPayload() {
    if (this.addressValue) {
      this.documentInfo.addressId = this.addressValue;
      this.setDocumentAddressFields();
    }
  }

  // (ED-539) save some `Address` info in Document to show it in Document if the `Address` was deleted
  private setDocumentAddressFields() {
    this.documentInfo.adresseKundennummer =
      this.currentAddress.konto != this.$i18n.tc('no_changes') ? this.currentAddress.konto : '';
    this.documentInfo.adresseSeriennummer = this.currentAddress.seriennummer;
    this.documentInfo.adresseKennzeichen = this.currentAddress.kennzeichen;
  }

  // (ED-512): there are 3 save mods: 1) `no change` (addresses don't change) 2) empty Autocomplete (delete Addresses for selected docs) 3) Autocomplete with value (update selected docs with specified address)
  setAddressIdInPayloadMultipleDocs() {
    const result =
      this.addressValue == null // `addressValue` NULL when empty Autocomplete, so delete address for document on backend
        ? '00000000-0000-0000-0000-000000000000'
        : this.addressValue === '' // `empty Id` means `No change` and will be sent as NULL on backend (so Address will NOT be deleted)
        ? null
        : this.addressValue;
    return result;
  }

  formatPayloadForMultipleDocuments() {
    const documentIdsWithData = {
      documentIds: this.getterSelectedDocumentIds,
      commonAddressId: this.setAddressIdInPayloadMultipleDocs(),
      isUpdateAllIbans: this.isUpdateAllIbans,
      isUpdateAllHomepages: this.isUpdateAllHomepages,
      isUpdateAllTaxNumbers: this.isUpdateAllTaxNumbers,
    };
    this.setDocumentAddressFields();
    const updatedDocumentProperties: any = [];
    this.fillWithChangedDocumentPropertiesForDocuments(updatedDocumentProperties, this.getterSelectedDocumentIds);
    const payload = {
      documentIdsWithData,
      document: this.documentInfo,
      documentPropertys: updatedDocumentProperties,
    };

    return payload;
  }

  //#region Logic connected with `OrganisationDocumentPropertys` (Settings -> Document -> Information fields)
  @orgDocumentPropertyModule.Action('getOrganisationDocumentPropertys')
  private actionGetOrganisationDocumentPropertys!: any;
  @orgDocumentPropertyModule.Getter('getOrganisationDocumentPropertys')
  private getOrganisationDocumentPropertys!: OdataItems<OrganisationDocumentProperty>;
  @orgDocumentPropertyModule.Getter('getOrganisationDocumentPropertysIsLoading')
  private organisationDocumentPropertysIsLoading!: any;

  @docPropertyModule.Action('getDocumentPropertys')
  private actionGetDocumentPropertys!: any;
  @docPropertyModule.Action('updateDocumentPropertys')
  private actionUpdateDocumentPropertys!: any;
  @docPropertyModule.Getter('getDocumentPropertys')
  private getDocumentPropertys!: any;
  @docPropertyModule.Getter('getDocumentPropertysIsLoading')
  private documentPropertysIsLoading!: any;

  private documentProperties: any = {};
  private documentPropertiesFromBackend: Array<any> = [];

  get hasDocumentPropertyFields() {
    return this.getOrganisationDocumentPropertys.items.length > 0;
  }

  async loadOrgDocumentProperties() {
    await this.actionGetOrganisationDocumentPropertys()
      .then(async () => {
        await this.loadDocumentProperties();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async loadDocumentProperties() {
    await this.actionGetDocumentPropertys({ documentId: this.getterDocument.id })
      .then((result: any) => {
        this.documentPropertiesFromBackend = result.value;
        if (this.documentPropertiesFromBackend.length > 0) {
          this.documentPropertiesFromBackend.forEach((element: any) => {
            this.documentProperties[element.fieldId] = element.value; // fill the `Information fields` in `menu-document-information`
            this.initialProperties.push(`${element.fieldId}_${element.value}`);
          });
        }
        this.documentProperties = Object.assign({}, this.documentProperties); // use this getter to trick Vue reactivity system with dynamic objects (see https://vuejs.org/v2/guide/reactivity.html#For-Objects)
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private saveDocumentPropertys(documentIds: string[]) {
    if (Object.keys(this.documentProperties).length > 0) {
      const updatedDocumentProperties: any = [];
      this.fillWithChangedDocumentPropertiesForDocuments(updatedDocumentProperties, documentIds);
      if (updatedDocumentProperties.length > 0)
        this.actionUpdateDocumentPropertys(updatedDocumentProperties)
          .then((result: any) => {
            this.documentPropertiesFromBackend = result.result; // set `result` if user want to update information fields immediately so we should have existing `documentProperty` ID, otherwise with empty ID new duplicate `documentProperty` will be created

            // (ED-704) `result.result.$values` contains custom `DocumentPropertyDto` list from backend (same fields as in OData request)
            if (result.result.$values) {
              this.mutationSetDocumentPropertys(result.result.$values);
            }
            if (this.isChangeSingleDocument) {
              const documentProperties = this.actionGetDocumentPropertys({ documentId: documentIds[0] });
              this.mutationSetDocumentPropertys({
                items: documentProperties,
                anabledFavoritePropertysIds: this.getOrganisationDocumentPropertys.items.filter(
                  (prop: OrganisationDocumentProperty) => prop.enabled == true && prop.favorite == true
                ),
              });
            }
          })
          .catch((err: any) => {
            logger.error(err);
          });
    }
  }
  selectedDocumentProperties: string[] = [];
  private fillWithChangedDocumentPropertiesForDocuments(updatedDocumentProperties: any, documentIds: string[]) {
    const documents = this.documents.items.filter((x) => documentIds.includes(x.id));
    const selectedOrganizationDocProperties = this.isChangeSingleDocument
      ? this.getOrganisationDocumentPropertys.items
      : this.getOrganisationDocumentPropertys.items.filter((x: OrganisationDocumentProperty) =>
          this.selectedDocumentProperties.includes(x.fieldId)
        );
    if (documents.length == 0) documents.push(this.documentInfo);
    for (const document of documents) {
      const docProperties = document.documentPropertys;
      for (const orgDocProperty of selectedOrganizationDocProperties) {
        const documentProperty =
          docProperties?.filter((x) => x.organisationDocumentProperty?.fieldId == orgDocProperty.fieldId)[0] ??
          docProperty.parse({});
        documentProperty.fieldId = orgDocProperty.fieldId;
        documentProperty.documentId = document.id;
        documentProperty.value = this.documentProperties[orgDocProperty.fieldId];

        updatedDocumentProperties.push(documentProperty);
      }
    }
  }

  //#endregion

  private isPropertiesChanged() {
    const keys = Object.keys(this.documentProperties);
    const properties: string[] = [];
    for (const key of keys) {
      const value = this.documentProperties[key];
      properties.push(`${key}_${value}`);
    }
    if (properties.length !== this.initialProperties.length) {
      return true;
    }
    const propertiesStr = properties.reduce((acc, id) => acc + id, '');
    const initialPropertiesStr = this.initialProperties.reduce((acc, id) => acc + id, '');
    return propertiesStr != initialPropertiesStr;
  }

  private isAddressesChanged() {
    if (this.addressValue != '') {
      return true;
    }
    return false;
  }

  private focusNextInputOnEnter(el: HTMLInputElement) {
    const lastElement = (this.$refs.saveBtn as Vue).$el as HTMLElement;
    buildOnEnter(lastElement)(el);
  }

  private returnBack() {
    if (this.isAddressesChanged() || this.isPropertiesChanged() || !doc.compare(this.initialInfo, this.documentInfo)) {
      this.$confirm
        .open(`${i18n.tc(`dialogs.confirmation.title`)}`, `${this.$t('dialogs.confirmation.message')}`, {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        })
        .then(async (response: any) => {
          if (response) {
            await this.saveDocInformation();
          } else {
            this.actionUpdateDocumentInState(this.initialInfo);
            this.$emit('click:return');
          }
        });
    } else {
      this.$emit('click:return');
    }
  }

  //reminders
  itemEditDialog = {
    model: this.documentInfo,
  };

  async itemEditDialogOnClose(item: any) {}

  async itemEditDialogOnUpdate(item: any) {}
}
