export interface UserFile {
  id: string;
  refId: string;

  title: string; // from `Upload` model on backend (according to swagger)
  documentName: string; // from `UserFileDm` model on backend (according to swagger) when GET file

  lastActionDate: string;
  created: string; // maybe backend rename `createdDate`, name on backend now `created`
  document: string;
}

export const UserFileEmpty: UserFile = {
  id: '',
  refId: '',

  title: '',
  documentName: '',
  document: '',

  lastActionDate: '',
  created: '',
};
export interface Value {
  value: UserFile[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<UserFile>): any {
  return {
    Id: data?.id || '',
    RefId: data?.refId || '',

    Title: data?.title || '',
    DocumentName: data?.documentName || '',

    LastActionDate: data!.lastActionDate || '',
    Created: data!.created || '',
  };
}
function parse(data?: Partial<UserFile>): UserFile {
  return {
    id: data!.id || '',
    refId: data?.refId || '',

    title: data?.title || '',
    documentName: data?.documentName || '',
    document: data?.document || '',

    lastActionDate: data!.lastActionDate || '',
    created: data!.created || '',
  };
}
export default {
  parse,
  toAPI: toAPI,
};
