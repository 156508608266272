import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import GuidUtils from '@/shared/utils/GuidUtils';
import documentReminder, { DocumentReminder } from '@/shared/model/documentReminder';
import DateTimePicker from '@/components/date-time-picker/date-time-picker.vue';
import documentRemindersReceiverUser, {
  DocumentRemindersReceiverUser,
} from '@/shared/model/documentRemindersReceiverUser';
import { User } from 'oidc-client';

const name = 'menu-document-reminder-edit';
const logger = new Logger(name);

const documentReminderModule = namespace('documentReminder');
const userModule = namespace('user');
const authModule = namespace('auth');

@Component({
  name: 'menu-document-reminder-edit',
  components: { DateTimePicker },
})
export default class MenuDocumentReminderEdit extends Vue {
  @documentReminderModule.Action('getDocumentReminders')
  private actionGetDocumentReminders?: any;
  @documentReminderModule.Action('deleteDocumentReminder')
  private actionDeleteDocumentReminder?: any;

  @userModule.Action('getUsers')
  private actionGetUsers!: any;
  @userModule.Getter('getUsers')
  private users!: any;

  @authModule.Getter('getAccount')
  private account!: User;

  @Ref('menu-document-reminder-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => documentReminder.parse({}) })
  private value!: DocumentReminder;

  @Watch('value')
  onUpdateValue(newV: DocumentReminder, oldV: DocumentReminder) {
    this.editedItem = Object.assign(this.editedItem, newV);
    this.resetValidation();
  }

  private editedItem: DocumentReminder = documentReminder.parse({});

  get getPrioritats() {
    return [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
    ];
  }

  //#region Date-field logic
  @Ref('menu')
  private refMenu: any;
  menu = false;
  date = '';

  get computedDateFormatted(): any {
    //https://www.w3schools.com/jsref/jsref_tolocalestring.asp
    const date = new Date(this.date);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) return '';
    const enDate = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const enTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const deDate = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const deTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    if (this.$i18n.locale.toLowerCase() === 'de' && this.date) {
      return deDate + ' ' + deTime; //return DateUtils.isoDateToDotDate(this.date);
    }
    return enDate + ' ' + enTime; //return this.date;
  }

  set computedDateFormatted(v: any) {
    this.menu = false;
    if (v instanceof Date) {
      this.date = v.toDateString();
    }
  }

  saveDate(date: any) {
    this.date = date;
    this.editedItem.startAt = date; // save ISO date (YYYY-MM-DD) in the database

    this.refMenu.save(date); // without this line `v-menu` clears input with `update:return-value` event
    this.menu = false;
  }

  @Watch('editedItem.startAt')
  public onDateChanged(newVal: any, oldVal: any) {
    this.date = this.editedItem.startAt;
  }

  //#endregion

  //#region rules
  private isFormValid = true;

  get rules() {
    return {
      required: (value: any, field: any) => !!value || this.$t('messages.required', { 0: field }),
      requiredAutocompleted: (value: any, field: any) =>
        !!(value && value.length) || this.$t('messages.required', { 0: field }),
      maxlength: (value: any, limit: any) => value.length <= 255 || this.$t('messages.maxlength', { 0: limit }),
      min: (v: any) => v.length >= 3 || this.$t('messages.min'),
    };
  }
  //#endregion

  async created() {
    this.actionGetUsers();
  }

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    if (isFormValidResult) {
      const users = this.editedItem.documentRemindersReceiverUsers;
      const apiUsers: DocumentRemindersReceiverUser[] = [];
      this.editedItem.documentRemindersReceiverUsers.forEach((item: DocumentRemindersReceiverUser) => {
        apiUsers.push(
          documentRemindersReceiverUser.parse({
            id: GuidUtils.newGuid(),
            documentId: this.editedItem.documentId,
            documentRemindersId: this.editedItem.id,
            organisationId: this.account.profile.current_organization_id,
            userId: item.userId,
          })
        );
      });
      this.editedItem.documentRemindersReceiverUsers = apiUsers;

      this.$emit('click:update', this.editedItem);
    }
  }

  async onClickDelete(item: DocumentReminder) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: item.subject,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteDocumentReminder(item.id)
            .then(() => {
              this.actionGetDocumentReminders({ documentId: this.editedItem.documentId });
              this.onClickClose();
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }
}
