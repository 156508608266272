import { ActionTree } from 'vuex';
import { ActionLogState } from './types';
import { RootState } from '../../types';
import { defaultBackendActionLog } from '@/shared/backend/actionLog';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import actionLog, { Value, ActionLog } from '@/shared/model/actionLog';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { Value as ActionLogUserValue } from '@/shared/model/actionLogUser';

const logger = new Logger('actions.ActionLogs');
export const actions: ActionTree<ActionLogState, RootState> = {
  getActionLogs(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; documentIdFilter: string }
  ) {
    commit('setActionLogsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getActionLogsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendActionLog
      .getActionLogs(searchParams, organizationId, payload?.documentIdFilter ?? '')
      .then((response: AxiosResponse<Value>) => {
        commit('setActionLogs', response.data);
        commit('setActionLogsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setActionLogsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getActionLogQaUsers({ commit, dispatch, getters, rootGetters }, actionLogId) {
    commit('setActionLogQaUsersIsLoading', true);
    const searchParams = getters.getActionLogQaUsers.searchParams;
    return defaultBackendActionLog
      .getActionLogUsers(actionLogId, searchParams)
      .then((response: AxiosResponse<ActionLogUserValue>) => {
        commit('setActionLogQaUsers', response.data);
        commit('setActionLogQaUsersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setActionLogQaUsersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  markAsReadNotification({ commit, dispatch, getters, rootGetters }, documentId) {
    return defaultBackendActionLog
      .markAsReadNotification(documentId)
      .then(({ id }: any) => {
        commit('markAsReadNotificationById', id);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
      });
  },
  closeThread({ commit, dispatch, getters, rootGetters }, id) {
    return defaultBackendActionLog
      .closeThread(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ActionLog;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.actionLog_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
      });
  },
  getActionLogsQa({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData: any }) {
    commit('setActionLogsQaIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getActionLogsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    const searchData = payload?.searchData ?? {};
    searchData.organizationId = organizationId;
    const isUpdateState = !(searchData?.isSkipUpdateState || false); // (ED-363) need default `FALSE` to update state where `isSkipUpdateState` was not specified

    return defaultBackendActionLog
      .getActionLogsQa(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        if (isUpdateState) {
          // (ED-363) avoid updating `setActionLogsQa` when different set of QAs in `menu-document-QA` and `qa-dialog` (difference because of `qa-overview-dialog` has ALL QAs)
          commit('setActionLogsQa', response.data);
        }
        commit(
          'document/setDocumentsActionLogs',
          {
            id: payload?.searchData.documentId,
            actionLogs: response.data.value.map((x) => x),
          },
          { root: true }
        );
        commit('setActionLogsQaIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setActionLogsQaIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getThreadMessages({ commit, dispatch, getters, rootGetters }, threadId) {
    commit('setThreadMessagesIsLoading', true);
    const searchParams = getters.getThreadMessages.searchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;

    return defaultBackendActionLog
      .getActionLogsQa(searchParams, { threadId: threadId, organizationId: organizationId })
      .then((response: AxiosResponse<Value>) => {
        commit('setThreadMessages', response.data);
        commit('setThreadMessagesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setThreadMessagesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  // (ED-363) separate action for QA(Questions and answers) overview dialog to prevent conflict with separate `document` QA
  getActionLogsQaOverview(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; searchData: any }
  ) {
    commit('setActionLogsQaOverviewIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getActionLogsQaOverview.searchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    const searchData = payload?.searchData;
    if (payload?.searchData?.page) searchParams.dataOption.page = payload?.searchData?.page;
    searchData.organizationId = organizationId;
    return defaultBackendActionLog
      .getActionLogsQa(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setActionLogsQaOverview', response.data);
        commit('setActionLogsQaOverviewIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setActionLogsQaOverviewIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getActionLog({ commit, dispatch }, id: string) {
    return defaultBackendActionLog
      .getActionLog(id)
      .then((response: AxiosResponse<ActionLog>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateActionLog({ commit, dispatch }, file: ActionLog) {
    return defaultBackendActionLog
      .updateActionLog(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ActionLog;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.actionLog_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteActionLog({ commit, dispatch }, id: string) {
    return defaultBackendActionLog
      .deleteActionLog(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ActionLog;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
