import { Vue } from 'vue-property-decorator';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { PluginFunction, PluginObject } from 'vue';
import store from '@/shared/store';
import LSService from '@/shared/utils/LocalStorageService';
import { Logger } from 'fsts';

const logger = new Logger('BackendHub');
const lsService = LSService.getService();
export default class BackendHub extends Vue {
  constructor(plugin: PluginObject<unknown> | PluginFunction<unknown>, options?: unknown) {
    super(plugin);
  }
  static install(): void {
    // use new Vue instance as an event bus
    const backendHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$backendHub = backendHub;
    // Provide methods to connect/disconnect from the SignalR hub
    let connection: any = null;
    let startedPromise: any = null;
    let manuallyClosed = false;
    Vue.prototype.startSignalR = (jwtToken: string) => {
      const options = jwtToken ? { accessTokenFactory: () => jwtToken } : {};
      connection = new HubConnectionBuilder()
        .withUrl(
          `/hub/bapi/BackendHub`
          // options
        )
        .configureLogging(LogLevel.Information)
        .build();
      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('AddressImportFileEnd', (addressFileName: any, time: any) => {
        //can also emit event https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
        //   backendHub.$emit('address-import-file-end', time);
        store.commit('address/updateIsImporting', time);
      });
      connection.on('DocumentChanged', (docId: any, time: any) => {
        //can also emit event https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
        backendHub.$emit('document-changed', docId, time);
        // store.commit('document/addDocumentsNeedUpdate', { docId: docId, date: time });
      });
      connection.on('DocumentMovedFromTo', (docId: any, fromId: string, toId: string, time: any) => {
        logger.info(`signalR message received DocumentMovedFromTo (${docId},${fromId},${toId},${time})`);
        // backendHub.$emit('document-moved', docId, fromId, toId, time);
        store.dispatch('document/getDocuments');
      });
      connection.on('PreviewSaved', (previewFileId: any, time: any) => {
        //can also emit event https://www.dotnetcurry.com/aspnet-core/1480/aspnet-core-vuejs-signalr-app
        backendHub.$emit('preview-saved', '', previewFileId);
        // store.commit('document/addDocumentsNeedUpdate', { docId: docId, date: time });
      });

      connection.on('DocumentTextExtracted', (docId: any) => {
        backendHub.$emit('document-text-extracted', docId);
      });

      // need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start() {
        startedPromise = connection
          .start()
          .then((x: any) => {
            const profile = lsService.getAccount().profile;
            // startedPromise.invoke('JoinUserGroup', profile?.sub);
            // startedPromise.invoke('JoinOrganizationGroup', profile?.current_organization_id);
          })
          .catch((err: any) => {
            console.error('Failed to connect with hub', err);
            // if (!manuallyClosed)
            //   return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
          });
        return startedPromise;
      }
      connection.onclose(() => {
        if (!manuallyClosed) start();
      });

      // Start everything
      manuallyClosed = false;
      start();
    };
    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return;

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null;
        });
    };
    Vue.prototype.addressOpened = (userId: string) => {
      if (!startedPromise) return;

      return startedPromise.then(() => connection.invoke('JoinUserGroup', userId)).catch(console.error);
    };
    Vue.prototype.addressClosed = (userId: string) => {
      if (!startedPromise) return;

      return startedPromise.then(() => connection.invoke('LeaveUserGroup', userId)).catch(console.error);
    };
    Vue.prototype.OrgLeave = (orgId: string) => {
      if (!startedPromise) return;

      return startedPromise.then(() => connection.invoke('LeaveOrganizationGroup', orgId)).catch(console.error);
    };
    Vue.prototype.OrgJoin = (orgId: string) => {
      if (!startedPromise) return;

      return startedPromise.then(() => connection.invoke('JoinOrganizationGroup', orgId)).catch(console.error);
    };
  }
  created() {
    const profile = lsService.getAccount().profile;
    // Load the question and notify the server we are watching the question
    return this.$backendHub.addressOpened(profile.sub);
  }
  beforeDestroy() {
    const profile = lsService.getAccount().profile;
    // Notify the server we stopped watching the question
    this.$backendHub.addressClosed(profile.sub);
    this.$backendHub.OrgLeave(profile.current_organization_id);
  }
}
