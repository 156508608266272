import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import processListsExport, { ProcessListsExport, Value } from '../model/processListsExport';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ProcessListsExport');
export interface BackendProcessListsExport {
  getProcessListsExport(id: string): AxiosPromise<ProcessListsExport>;
  getProcessListsExports: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteProcessListsExport(id: string): AxiosPromise;
  updateProcessListsExport(ProcessListsExport: ProcessListsExport): AxiosPromise<any>;
}

export const defaultBackendProcessListsExport: BackendProcessListsExport = {
  getProcessListsExport(id: string): AxiosPromise<ProcessListsExport> {
    const url = `${URLS.processListsExportOdata}/${id}`;
    return instance.get<ProcessListsExport>(url);
  },
  getProcessListsExports(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.processListsExportOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getProcessListsExports${url}`);
    return instance.get<Value>(url);
  },

  deleteProcessListsExport(id: string): AxiosPromise {
    logger.debug('deleteProcessListsExport');
    return instance.delete(`${URLS.processListsExport}/${id}`);
  },
  updateProcessListsExport(ProcessListsExport: ProcessListsExport): AxiosPromise<any> {
    logger.debug('updateProcessListsExport');
    return instance.put<ProcessListsExport>(
      `${URLS.processListsExport}/update`,
      processListsExport.toAPI(ProcessListsExport)
    );
  },
};
