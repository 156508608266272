import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentFile, { DocumentFile, Value } from '../model/documentFile';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentFile');
export interface BackendDocumentFile {
  getDocumentFile(id: string): AxiosPromise<DocumentFile>;
  getDocumentFiles: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentFile(id: string): AxiosPromise;
  updateDocumentFile(DocumentFile: DocumentFile): AxiosPromise<any>;
}

export const defaultBackendDocumentFile: BackendDocumentFile = {
  getDocumentFile(id: string): AxiosPromise<DocumentFile> {
    const url = `${URLS.documentFileOdata}/${id}`;
    return instance.get<DocumentFile>(url);
  },
  getDocumentFiles(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentFileOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentFiles${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentFile(id: string): AxiosPromise {
    logger.debug('deleteDocumentFile');
    return instance.delete(`${URLS.documentFile}/${id}`);
  },
  updateDocumentFile(DocumentFile: DocumentFile): AxiosPromise<any> {
    logger.debug('updateDocumentFile');
    return instance.put<DocumentFile>(`${URLS.documentFile}/update`, documentFile.toAPI(DocumentFile));
  },
};
