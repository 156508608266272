import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LoginHeaderNav from './login-header-nav/login-header-nav.vue';

const authModule = namespace('auth');
@Component({
  components: {
    LoginHeaderNav,
  },
})
export default class Login extends Vue {
  @Prop() private msg!: string;
  valid: boolean = true;
  user: { userName?: string; password?: string } = {};

  @authModule.Action('login')
  private actionLogin!: any;
  @authModule.Action('logout')
  private actionLogout!: any;
  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.Action('getMyAccount')
  private actionGetMyAccount!: any;
  showPassword = false;
  async onLogin(organizationId?: string) {
    await this.actionLogin({ organizationId: organizationId });
  }
  async onLogout() {
    await this.actionLogout();
  }
}
