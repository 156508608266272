import { ActionTree } from 'vuex';
import { AllocationsPropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationsProperty } from '@/shared/backend/allocationsProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationsProperty, { Value, AllocationsProperty } from '@/shared/model/allocationsProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationsPropertys');
export const actions: ActionTree<AllocationsPropertyState, RootState> = {
  getAllocationsPropertys({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationsPropertysIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationsPropertysSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationsProperty
      .getAllocationsPropertys(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationsPropertys', response.data);
        commit('setAllocationsPropertysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsPropertysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationsProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsProperty
      .getAllocationsProperty(id)
      .then((response: AxiosResponse<AllocationsProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationsProperty({ commit, dispatch }, file: AllocationsProperty) {
    return defaultBackendAllocationsProperty
      .updateAllocationsProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationsProperty_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationsProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsProperty
      .deleteAllocationsProperty(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsProperty;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
