export interface AllocationSetting {
  id: string; // ulong
  organisationId: string; // string
  officeDigits: number; // byte
  brandDigits: number; // byte
  officesAllowed: string; // string
  brandsAllowed: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  exportApprovalStatus: string; // string
  exportType: AllocationSettingExportTypes; // string
  exportGobdRequired: boolean; // boolean
  exportEachOffice: boolean; // boolean
}

export interface Value {
  value: AllocationSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    OfficeDigits: data?.officeDigits || 0,
    BrandDigits: data?.brandDigits || 0,
    OfficesAllowed: data?.officesAllowed || '',
    BrandsAllowed: data?.brandsAllowed || '',
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    ExportApprovalStatus: data?.exportApprovalStatus || '',
    ExportType: data?.exportType || AllocationSettingExportTypes.custom,
    ExportGobdRequired: data?.exportGobdRequired || false,
    ExportEachOffice: data?.exportEachOffice || false,
  };
}

function parse(data?: Partial<AllocationSetting>): AllocationSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    officeDigits: data?.officeDigits || 0,
    brandDigits: data?.brandDigits || 0,
    officesAllowed: data?.officesAllowed || '',
    brandsAllowed: data?.brandsAllowed || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    exportApprovalStatus: data?.exportApprovalStatus || '',
    exportType: data?.exportType || AllocationSettingExportTypes.custom,
    exportGobdRequired: data?.exportGobdRequired || false,
    exportEachOffice: data?.exportEachOffice || false,
  };
}
export enum AllocationSettingExportTypes {
  loco_soft = 'loco_soft',
  datev = 'datev',
  custom = 'custom',
}
export default {
  parse,
  toAPI: toAPI,
};
