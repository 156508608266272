import { ActionTree } from 'vuex';
import { CostObjectiveImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendCostObjectiveImportSetting } from '@/shared/backend/costObjectiveImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import costObjectiveImportSetting, {
  Value,
  CostObjectiveImportSetting,
} from '@/shared/model/costObjectiveImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.costObjectiveImportSettings');
export const actions: ActionTree<CostObjectiveImportSettingState, RootState> = {
  getCostObjectiveImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setCostObjectiveImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getCostObjectiveImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostObjectiveImportSetting
      .getCostObjectiveImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setCostObjectiveImportSettings', response.data);
        commit('setCostObjectiveImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCostObjectiveImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostObjectiveImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setCostObjectiveImportSettingsIsLoading', true);
    const searchParams = getters.getCostObjectiveImportSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostObjectiveImportSetting
      .getCostObjectiveImportSettings(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setCostObjectiveImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setCostObjectiveImportSettingsIsLoading', false);
      });
  },
  getCostObjectiveImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendCostObjectiveImportSetting
      .getCostObjectiveImportSetting(id)
      .then((response: AxiosResponse<CostObjectiveImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCostObjectiveImportSetting({ commit, dispatch, rootGetters }, file: CostObjectiveImportSetting) {
    commit('setInProgressUpdateCostObjectiveImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendCostObjectiveImportSetting
      .updateCostObjectiveImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostObjectiveImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.costObjectiveImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateCostObjectiveImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getCostObjectiveImportSetting.clearBeforeImport = payload.value;
    dispatch('updateCostObjectiveImportSetting', getters.getCostObjectiveImportSetting)
      .then((result: any) => {
        getters.getCostObjectiveImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getCostObjectiveImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteCostObjectiveImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendCostObjectiveImportSetting
      .deleteCostObjectiveImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CostObjectiveImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
