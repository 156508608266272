import { render, staticRenderFns } from "./number-field.html?vue&type=template&id=328a21a6&scoped=true&external"
import script from "./number-field.ts?vue&type=script&lang=ts&external"
export * from "./number-field.ts?vue&type=script&lang=ts&external"
import style0 from "./number-field.scss?vue&type=style&index=0&id=328a21a6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328a21a6",
  null
  
)

/* custom blocks */
import block0 from "./number-field.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fnumber-field%2Fnumber-field.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VRow,VTextField})
