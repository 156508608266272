import folder from '../model/folder';
import folderModel, { Folder } from '../model/folder';
import { Logger } from 'fsts';
const name = 'treeUtils';
const logger = new Logger(name);
export default class TreeUtils {
  static openFoldersAndChildrens(folderId: string, folders: any[], opened: any[]) {
    if (folders && folders.length > 0)
      folders
        .filter((x) => x.children !== undefined)
        .forEach((element) => {
          opened.push(element.id);
          TreeUtils.openFoldersAndChildrens(element.id, element.children, opened);
        });
  }
  public static convertTreeToList(root: any): Folder[] {
    const flatten = (root: any, flat: any[] = [], parentFolderId: any = '', depth: any = 0, key: any = 'id') => {
      if (Array.isArray(root)) root.forEach((child: any) => flatten(child, flat, root[key], depth, key));
      else {
        flat.push({
          parentFolderId,
          [key]: root[key],
          depth: depth++,
          ...root,
        });

        if (Array.isArray(root.children)) {
          root.children.forEach((child: any) => flatten(child, flat, root[key], depth, key));
        }
      }
    };

    const flat: Folder[] = [];
    flatten(root, flat);
    return flat;
  }
  public static FoldersToTreeViewFlat(folders: Folder[]): Folder[] {
    return TreeUtils.convertTreeToList(TreeUtils.FoldersToTreeView(folders));
  }
  public static FoldersToTreeViewWrite(folders: Folder[]): Folder[] {
    return this.FoldersToTreeViewWithoutRight(folders, 'Write');
  }
  public static FoldersToTreeViewWithoutHidden(folders: Folder[]): Folder[] {
    return this.FoldersToTreeViewWithoutRight(folders, 'View');
  }
  public static FoldersTreeViewFlatWithRight(foldersFlat: Folder[], right: 'Upload') {
    return foldersFlat.filter((x) => x.roleRights.includes(`Folder_${x.id}_${right}`));
  }
  public static FoldersToTreeViewWithoutRight(folders: Folder[], right: 'View' | 'Write' | 'Upload'): Folder[] {
    const rootFolders: Folder[] = [];
    const rootHidden: Folder[] = [folder.parse({ children: rootFolders })];

    this.sortFolders(folders);
    // select root elements. if not make in now sorting was be critical (root element can be added after child and tree was be wront)
    folders
      .filter((x) => x.parentFolderId == '')
      .forEach((el) => {
        el.fullPath = el.name;
        (el as any).disabled = false; //for init vtree modelrootFolders.push(el);
        if (el.roleRights.includes(`Folder_${el.id}_${right}`)) {
          if (rootHidden[0].children === undefined) rootHidden[0].children = Object.assign([], rootHidden[0].children);
          rootHidden[0].children?.push(el);
          this.getChildWithoutRight(el, folders, el, right);
        } else {
          this.getChildWithoutRight(el, folders, rootHidden[0], right);
        }
      });

    return rootHidden[0].children ?? [];
  }

  public static getChildWithoutRight(
    parent: Folder,
    folders: Folder[],
    newParent: Folder,
    right: 'View' | 'Write' | 'Upload'
  ): Folder[] | undefined {
    const childs = folders.filter((f) => f.parentFolderId == parent.id);
    for (const key in childs) {
      const child = childs[key];
      child.fullPath = parent.fullPath + ' / ' + child.name;
      (child as any).disabled = false; //for init vtree model
      if (child.roleRights.includes(`Folder_${child.id}_${right}`)) {
        if (newParent.children === undefined) newParent.children = Object.assign([], newParent.children);
        newParent.children.push(child);
        this.getChildWithoutRight(child, folders, child, right);
      } else {
        this.getChildWithoutRight(child, folders, parent, right);
      }
    }

    return parent.children;
  }

  public static FoldersToTreeView(folders: Folder[]): Folder[] {
    // https://typeofnan.dev/an-easy-way-to-build-a-tree-with-object-references/
    const idParentIdMapping = folders.reduce((acc: any, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});
    const rootFolders: Folder[] = [];
    // select root elements. if not make in now sorting was be critical (root element can be added after child and tree was be wront)
    folders
      .filter((x) => x.parentFolderId == '')
      .forEach((el) => {
        if (el.parentFolderId == '') {
          (el as any).fullPath = el.name;
          rootFolders.push(el);
          return;
        }
      });
    for (const folder in rootFolders) {
      if (Object.prototype.hasOwnProperty.call(rootFolders, folder)) {
        const el = rootFolders[folder];
        el.fullPath = el.name;
        (el as any).disabled = false; //for init vtree model

        this.findChildsForIn(
          el,
          folders.filter((x) => x.parentFolderId != '')
        );
      }
    }
    // sorting must be after making tree. reason is root folders must be inserted first into the tree (for find as parent for child elements)
    this.sortFolders(rootFolders);
    return rootFolders;
  }
  public static findParrentByFullPathInTreeView(folder: Folder, inFolders: any[]) {
    let tempChilds = inFolders;
    let tempParrent: Folder | undefined = undefined;
    let folderNames = folder.fullPath.split(' / ');
    folderNames = folderNames.slice(0, folderNames.length - 1);

    for (const key in folderNames) {
      if (Object.prototype.hasOwnProperty.call(folderNames, key)) {
        tempParrent = tempChilds.filter((y) => y.name == folderNames[key])[0];
        tempChilds = tempParrent?.children ?? [];
      }
    }
    return tempParrent;
  }
  public static findChildsForIn(folder: Folder, inFolders: Folder[]) {
    const childrens = inFolders.filter((f) => f.parentFolderId == folder.id);
    if (childrens.length > 0) {
      folder.children = childrens;

      folder.children?.forEach((el) => {
        el.fullPath = folder.fullPath + ' / ' + el.name;
        (el as any).disabled = false; //for init vtree model
        this.findChildsForIn(
          el,
          inFolders.filter((f) => f.parentFolderId != folder.id)
        );
      });
    }
  }
  public static getFolderPath(folderId: string, folders: Folder[]): string {
    const folder = folders?.filter((x) => {
      if (x.id.toLocaleLowerCase() == folderId.toLocaleLowerCase()) return x;
    });

    return folder.length > 0 ? folder[0].fullPath : '';
  }
  public static getFoldersFromFlat(folderId: string, folders: Folder[]): Folder[] {
    const folder = folders?.filter((x) => {
      if (x.id.toLocaleLowerCase() == folderId.toLocaleLowerCase()) return x;
    });

    return folder;
  }
  public static setDisableById(fullPath: string, folderId: string, folders: any, val: boolean = true): boolean {
    const index = folders?.findIndex((x: { fullPath: string }) =>
      fullPath.length > 0
        ? fullPath.toLocaleLowerCase().startsWith(x.fullPath.toLocaleLowerCase())
        : fullPath == x.fullPath
    );
    // logger.debug(`fullPath:${fullPath},index:${index},childre:$-{JSON.stringify(folders[index].children)}`);
    if (folders !== undefined && folders[index] !== undefined) {
      if (folders[index].fullPath.toLocaleLowerCase().endsWith(fullPath.toLocaleLowerCase())) {
        (folders[index] as any).disabled = val;
        return true;
      } else {
        return this.setDisableById(fullPath, folderId, folders[index].children, val);
      }
    }
    return false;
  }
  public static sortFolders(folders: { isBuiltIn: boolean; name: string }[]) {
    folders.sort((a, b) => {
      if (a.isBuiltIn && !b.isBuiltIn) {
        return -1;
      }
      if (!a.isBuiltIn && b.isBuiltIn) {
        return 1;
      }
      if (a.name > b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    });
  }
}
