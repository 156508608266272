import { GetterTree } from 'vuex';
import { DocumentReminderState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentReminderState, RootState> = {
  ['getDocumentReminders']: (state) => state.documentReminders,
  ['getDocumentRemindersIsLoading']: (state) => state.documentReminders?.isLoading,
  ['getDocumentRemindersTotal']: (state) => state.documentReminders?.total,
  ['getDocumentRemindersSearchParams']: (state) => state.documentReminders?.searchParams,
};
