import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import uf, { Folder, Value } from '../model/folder';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';
import roleRight, { RoleRight } from '../model/roleRight';

const logger = new Logger('backend.folder');
export interface BackendFolder {
  getFolder(id: string): AxiosPromise<Folder>;
  getFoldersByUrl(url: string): AxiosPromise<Value>;
  getFolders: (
    searchParams: SearchParams,
    parentFolderId?: string,
    roleId?: string,
    isVisibleFoUpload?: boolean
  ) => AxiosPromise<Value>;
  deleteFolder(id: string, moveToId: string): AxiosPromise;
  updateFolder(document: Folder): AxiosPromise<Folder>;
  copyFolder(fromId: string, toId: string, toName: string): AxiosPromise<any>;
  updateRoleRights(folderId: string, roleId: string, rights: RoleRight[]): AxiosPromise<any>;
}

// TODO (ED-254): change `OrganizationId` (with `Z`) to `OrganisationId` (with `S`) here and `FolderDm` backend class,  `OrganisationId` is used in old `https://neu.easy-docs.de/` tables, so we also use should `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
export const defaultBackendFolder: BackendFolder = {
  getFolder(id: string): AxiosPromise<Folder> {
    const url = `${URLS.folderOdata}/${id}`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<Folder>(url);
  },
  getFolders(
    searchParams: SearchParams,
    parentFolderId?: string,
    roleId?: string,
    isVisibleFoUpload?: boolean
  ): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    if (parentFolderId) odfb.eq(CONST.ParentFolderId, parentFolderId, false);
    if (isVisibleFoUpload) odfb.eq('isVisibleFoUpload', isVisibleFoUpload, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.folderOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      parentFolderId || isVisibleFoUpload ? odfb : undefined,
      [`$expand=RoleRights${roleId ? '($filter=roleId%20eq%20' + roleId + ')' : ''}`],
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getFolders${url}`);
    return instance.get<Value>(url);
  },
  getFoldersByUrl(url: string): AxiosPromise<Value> {
    return instance.get<Value>(DefaultBackendHelper.removeUrlAppendix(url));
  },
  deleteFolder(id: string, moveToId: string): AxiosPromise {
    logger.debug('deleteFolder');
    return instance.delete(`${URLS.folder}/${id}/${moveToId}`);
  },
  updateFolder(document: Folder): AxiosPromise<Folder> {
    return instance.put<Folder>(`${URLS.folder}/update`, uf.toAPI(document));
  },
  copyFolder(fromId: string, toId: string, toName: string): AxiosPromise<any> {
    return instance.post<any>(`${URLS.folder}/Copy`, {
      FromId: fromId,
      ToId: toId || undefined,
      ToName: toName,
    });
  },
  updateRoleRights(folderId: string, roleId: string, rights: RoleRight[]): AxiosPromise<any> {
    logger.debug('updateRoleRights');
    return instance.post<RoleRight>(`${URLS.folderOdata}(${folderId})/MultipleUpdateRoleRights`, {
      rights: rights,
      roleId: roleId,
    });
  },
};
