import { GetterTree } from 'vuex';
import { AllocationTaxGroupsImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationTaxGroupsImportSettingState, RootState> = {
  ['getAllocationTaxGroupsImportSettings']: (state) => state.allocationTaxGroupsImportSettings,
  ['getAllocationTaxGroupsImportSettingsIsLoading']: (state) => state.allocationTaxGroupsImportSettings?.isLoading,
  ['getAllocationTaxGroupsImportSettingsTotal']: (state) => state.allocationTaxGroupsImportSettings?.total,
  ['getAllocationTaxGroupsImportSettingsSearchParams']: (state) =>
    state.allocationTaxGroupsImportSettings?.searchParams,
  ['getAllocationTaxGroupsImportSetting']: (state) => state.allocationTaxGroupsImportSetting,
  ['getInProgressUpdateAllocationTaxGroupsImportSetting']: (state) =>
    state.inProgressUpdateAllocationTaxGroupsImportSetting,
};
