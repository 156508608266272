import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentProperty, { DocumentProperty, Value } from '../model/documentProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentProperty');
export interface BackendDocumentProperty {
  getDocumentProperty(id: string): AxiosPromise<DocumentProperty>;
  getDocumentPropertyQueryFirstEnabled(id: string): AxiosPromise<any>;
  getDocumentPropertys: (searchParams: SearchParams, organisationId: string, documentId: string) => AxiosPromise<Value>;
  deleteDocumentProperty(id: string): AxiosPromise;
  updateDocumentPropertys(DocumentPropertys: DocumentProperty[]): AxiosPromise<any>;
}

export const defaultBackendDocumentProperty: BackendDocumentProperty = {
  getDocumentProperty(id: string): AxiosPromise<DocumentProperty> {
    const url = `${URLS.documentPropertyOdata}/${id}`;
    return instance.get<DocumentProperty>(url);
  },
  getDocumentPropertyQueryFirstEnabled(id: string): AxiosPromise<any> {
    const url = `${URLS.documentProperty}/DocumentPropertyQueryFirstEnabled/${id}`;
    return instance.get<DocumentProperty>(url);
  },
  getDocumentPropertys(searchParams: SearchParams, organisationId: string, documentId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    odfb.eq(CONST.DocumentId, documentId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentPropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentPropertys${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentProperty(id: string): AxiosPromise {
    logger.debug('deleteDocumentProperty');
    return instance.delete(`${URLS.documentProperty}/${id}`);
  },
  updateDocumentPropertys(DocumentPropertys: DocumentProperty[]): AxiosPromise<any> {
    logger.debug('updateDocumentProperty');
    return instance.put<DocumentProperty>(`${URLS.documentProperty}/update`, {
      DocumentPropertys: DocumentPropertys.map((x) => documentProperty.toAPI(x)),
    });
  },
};
