import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import roleLoginTime, { RoleLoginTime, Value } from '../model/roleLoginTime';
import ODataFilterBuilder from 'odata-filter-builder';
import JsObjectUtils from '../utils/jsObjectUtils';

const logger = new Logger('backend.RoleLoginTime');
export interface BackendRoleLoginTime {
  getRoleLoginTime(id: string): AxiosPromise<RoleLoginTime>;
  getRoleLoginTimes: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteRoleLoginTime(id: string): AxiosPromise;
  updateRoleLoginTime(RoleLoginTime: RoleLoginTime): AxiosPromise<any>;
}

export const defaultBackendRoleLoginTime: BackendRoleLoginTime = {
  getRoleLoginTime(id: string): AxiosPromise<RoleLoginTime> {
    const url = `${URLS.roleLoginTimeOdata}/${id}`;
    return instance.get<RoleLoginTime>(url);
  },
  getRoleLoginTimes(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.roleLoginTimeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getRoleLoginTimes${url}`);
    return instance.get<Value>(url);
  },

  deleteRoleLoginTime(id: string): AxiosPromise {
    logger.debug('deleteRoleLoginTime');
    return instance.delete(`${URLS.roleLoginTime}/${id}`);
  },
  updateRoleLoginTime(item: RoleLoginTime): AxiosPromise<any> {
    logger.debug('updateRoleLoginTime');
    const formData = JsObjectUtils.getFormData(roleLoginTime.toAPI(item));
    return instance.post<RoleLoginTime>(`${URLS.roleLoginTimeOdata}`, formData);
  },
};
