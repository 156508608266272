export interface DocumentFilesExport {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  fileId: string; // System.Guid
  status: string; // string
  filesCount: number; // int
  done: boolean; // bool
  deleted: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  downloaded: string; // System.DateTime?
  fileName: string;
}

export interface Value {
  value: DocumentFilesExport[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentFilesExport>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    FileId: data?.fileId || undefined,
    Status: data?.status || '',
    FilesCount: data?.filesCount || 0,
    Done: data?.done || false,
    Deleted: data?.deleted || false,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    Downloaded: data?.downloaded || '',
  };
}

function parse(data?: Partial<DocumentFilesExport>): DocumentFilesExport {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    fileId: data?.fileId || '',
    status: data?.status || '',
    filesCount: data?.filesCount || 0,
    done: data?.done || false,
    deleted: data?.deleted || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    downloaded: data?.downloaded || '',
    fileName: data?.fileName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
