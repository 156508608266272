import { OrganisationDocumentPropertyState } from './types';

export const state = (): OrganisationDocumentPropertyState => initialState();

export const initialState = (): OrganisationDocumentPropertyState => ({
  organisationDocumentPropertys: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 25, // same value as on backend `Get` method (`[EnableQuery(PageSize = 25...`)
        sortBy: ['SortOrder'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['ActionEmailMessage'],
    },
  },
});
