import { MutationTree } from 'vuex';
import { RoleLoginTimeState } from './types';
import roleLoginTime, { RoleLoginTime, Value } from '@/shared/model/roleLoginTime';

export const mutations: MutationTree<RoleLoginTimeState> = {
  setRoleLoginTimes(state, payload: Value) {
    state.roleLoginTimes.items = payload.value.map((x) => roleLoginTime.parse(x));
    state.roleLoginTimes.total = payload['@odata.count'] || 0;
    state.roleLoginTimes.isLoading = false;
  },
  setRoleLoginTimesTotal(state, payload: number) {
    state.roleLoginTimes.total = payload;
  },
  setRoleLoginTimesIsLoading(state, payload: boolean) {
    state.roleLoginTimes.isLoading = payload;
  },
  updateRoleLoginTime(state, payload: RoleLoginTime) {
    const index = state.roleLoginTimes.items.findIndex((x: RoleLoginTime) => x.id === payload?.id);
    if (index >= 0 && payload?.id) {
      state.roleLoginTimes.items[index] = payload;
    }
  },
};
