import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationsExport, { AllocationsExport, Value } from '../model/allocationsExport';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationsExport');
export interface BackendAllocationsExport {
  getAllocationsExport(id: string): AxiosPromise<AllocationsExport>;
  getAllocationsExports: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationsExport(id: string): AxiosPromise;
  updateAllocationsExport(AllocationsExport: AllocationsExport): AxiosPromise<any>;
  createAllocationsExport(timezoneOffet: number, branchCode: string, exportType: number): AxiosPromise;
  markAsDownloadedAllocationExport(id: string): AxiosPromise;
}

export const defaultBackendAllocationsExport: BackendAllocationsExport = {
  getAllocationsExport(id: string): AxiosPromise<AllocationsExport> {
    const url = `${URLS.allocationsExportOdata}/${id}`;
    return instance.get<AllocationsExport>(url);
  },

  getAllocationsExports(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationsExportOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationsExports${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationsExport(id: string): AxiosPromise {
    logger.debug('deleteAllocationsExport');
    return instance.delete(`${URLS.allocationsExport}/${id}`);
  },

  updateAllocationsExport(AllocationsExport: AllocationsExport): AxiosPromise<any> {
    logger.debug('updateAllocationsExport');
    return instance.put<AllocationsExport>(
      `${URLS.allocationsExport}/update`,
      allocationsExport.toAPI(AllocationsExport)
    );
  },

  markAsDownloadedAllocationExport(id: string): AxiosPromise {
    return instance.put<AllocationsExport>(`${URLS.allocationsExport}/markAsDownloaded/${id}`);
  },

  createAllocationsExport(timezoneOffet: number, branchCode: string, exportType: number): AxiosPromise {
    return instance.put(`${URLS.allocationsExport}/create/${timezoneOffet}/${branchCode}/${exportType}`);
  },
};
