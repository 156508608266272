export interface DocumentRoleRight {
  id: string;
  documentId: string;
  roleId: string;
  roleName: string;
  name: string;
  viewAllowed: boolean;
  writeAllowed: boolean;
  uploadAllowed: boolean;
}
export interface Value {
  value: DocumentRoleRight[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<DocumentRoleRight>): any {
  return {
    Id: data?.id || undefined,
    DocumentId: data?.documentId || undefined,
    Name: data?.name || '',
  };
}
function parse(data?: Partial<DocumentRoleRight>): DocumentRoleRight {
  return {
    id: data?.id || '',
    documentId: data?.documentId || '',
    roleId: data?.roleId || '',
    roleName: data?.roleName || '',
    viewAllowed: data?.viewAllowed || false,
    writeAllowed: data?.writeAllowed || false,
    uploadAllowed: data?.uploadAllowed || false,
    name: data?.name || '',
  };
}
export default {
  parse,
  toAPI: toAPI,
};
