import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';
import { format, parse } from 'date-fns';
import AllocationExportDialog from '@/components/dialogs/allocationExportDialog/allocationExportDialog.vue';
import RouterUtils from '@/shared/utils/RouterUtils';
import { Organisation } from '@/shared/model/organisation';
import { AllocationSetting } from '@/shared/model/allocationSetting';

const logger = new Logger('menu-export');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const allocationsExportModule = namespace('allocationsExport');
const documentFilesExportModule = namespace('documentFilesExport');
const organizationModule = namespace('organization');
const allocationSettingModule = namespace('allocationSetting');
const documentModule = namespace('document');

@Component({ name: 'menu-export', components: { AllocationExportDialog } })
export default class MenuExportView extends Vue {
  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: any;

  @allocationsExportModule.Action('getAllocationsExports')
  private actionGetAllocationsExports!: any;
  @allocationsExportModule.Getter('getAllocationsExports')
  private getAllocationsExports!: any;
  @allocationsExportModule.Action('createAllocationsExport')
  private actionCreateAllocationsExport!: any;

  @documentFilesExportModule.Action('getDocumentFilesExports')
  private actionGetDocumentFilesExports!: any;
  @documentFilesExportModule.Getter('getDocumentFilesExports')
  private getDocumentFilesExports!: any;
  @documentFilesExportModule.Action('createPdfExport')
  private actionCreatePdfExport!: any;

  @documentFilesExportModule.Getter('getDocumentFilesExportsIsLoading')
  private getDocumentFilesExportsIsLoading!: any;

  @organizationModule.Getter('getOrganization')
  private organisation!: Organisation;
  @organizationModule.Action('getOrganization')
  private actionGetOrganization!: any;

  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;

  @documentModule.Action('getDocuments')
  private getDocuments!: any;
  @documentModule.Mutation('resetLastLoadDate')
  private resetLastLoadDate!: any;

  private exportProcesses() {
    logger.debug('exportProcesses');
  }

  payed = 'Payed';
  accepted = 'Accepted';
  final_check = 'Final check';
  extras = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => `Extra${x}`);

  created() {
    this.actionGetOrganization(this.organisation.id);
    this.actionGetOrganisationDocumentExtraStatusSettings().then(() => {
      this.getOrganisationDocumentExtraStatusSettings.items.forEach(
        (element: OrganisationDocumentExtraStatusSetting) => {
          if (element.allowExport == true) {
            this.buttons.push(element.statusValue);
            switch (element.statusValue) {
              case 'payed':
                this.payed = element.name;
                break;

              case 'accepted':
                this.accepted = element.name;
                break;

              case 'final_check':
                this.final_check = element.name;
                break;

              default:
                break;
            }
          }
        }
      );
    });
  }

  private buttons: string[] = [];

  get isVisiblePayed() {
    return this.buttons.includes('payed');
  }

  get isVisibleAccepted() {
    return this.buttons.includes('accepted');
  }

  get isVisibleFinalCheck() {
    return this.buttons.includes('final_check');
  }

  isVisible(
    extraX:
      | 'extra1'
      | 'extra2'
      | 'extra3'
      | 'extra4'
      | 'extra5'
      | 'extra6'
      | 'extra7'
      | 'extra8'
      | 'extra9'
      | 'extra10'
      | 'extra11'
      | 'extra12'
  ) {
    return this.buttons.includes(extraX.toLowerCase());
  }

  exportPdfType(value: string) {
    this.actionCreatePdfExport({ value: value, timezoneOffet: new Date().getTimezoneOffset() }).then(() => {
      this.actionGetDocumentFilesExports().then(() => {
        this.resetLastLoadDate();
        this.getDocuments();
      });
    });
  }

  //#region : AllocationExportDialog
  private isSaveBtnLoading = false;

  onClickAdd() {
    this.itemEditDialog.model = {};
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: any) {
    this.itemEditDialog.model = {};
    this.itemEditDialog.show = true;
  }

  itemEditDialog = {
    show: false,
    model: {},
  };

  async itemEditDialogOnClose(account: any) {
    this.itemEditDialog.show = false;
  }

  private translateDatevToNumber(date: string) {
    switch (date) {
      case 'custom':
        return 1; //CSV
      case 'datev':
        return 2; //DATEV
      case 'loco_soft':
        return 3; //LOCO SOFT
      default:
        return 1;
    }
  }

  async itemEditDialogOnExport(branch: string) {
    this.itemEditDialog.show = false;
    this.actionCreateAllocationsExport({
      timezoneOffet: new Date().getTimezoneOffset(),
      branchCode: branch == '' ? RouterUtils.emptyGuid : branch,
      exportType: this.translateDatevToNumber(this.allocationSetting.exportType),
    }).then(() => {
      let oldItemsPerpage = this.getAllocationsExports.searchParams.dataOption.itemsPerPage;
      this.getAllocationsExports.searchParams.dataOption = {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: oldItemsPerpage,
      };
      this.actionGetAllocationsExports().then(() => {});
    });
  }
  //#endregion
}
