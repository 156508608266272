export interface RoleRight {
  id: string; // ulong
  roleId: string; // string
  folderId: string; // string
  name: string; // string
  claimType?: string; // string
  claimValue?: string; // string
  sName?: string; // string
  allowed: boolean; // bool
  createdAt: string | null; // System.DateTime?
  updatedAt: string | null; // System.DateTime?
}

export interface Value {
  value: RoleRight[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<RoleRight>): any {
  const x: any = {
    Name: data?.name || '',
    Allowed: data?.allowed || false,
  };
  if (data?.updatedAt) {
    x.UpdatedAt = data?.updatedAt;
  }
  if (data?.createdAt) {
    x.CreatedAt = data?.createdAt;
  }
  if (data?.roleId) {
    x['RoleId@odata.type'] = '#Guid';
    x.RoleId = data?.roleId;
  }
  if (data?.folderId) {
    x['FolderId@odata.type'] = '#Guid';
    x.FolderId = data?.folderId;
  }
  if (data?.id) {
    x.Id = data?.id;
  }
  if (data?.createdAt) {
    x.CreatedAt = data?.createdAt;
  }
  if (data?.updatedAt) {
    x.UpdatedAt = data?.updatedAt;
  }
  return x;
}

function parse(data?: Partial<RoleRight>): RoleRight {
  const x: RoleRight = {
    id: data?.id || '',
    roleId: data?.roleId || '',
    folderId: data?.folderId || '',

    claimType: data?.claimType || '',
    claimValue: data?.claimValue || '',
    sName: data?.claimType || '',
    name: '__' + data?.claimType || '',

    allowed: data?.claimValue == 'allowed' ? true : false || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };

  return x;
}

export default {
  parse,
  toAPI: toAPI,
};
