import { GetterTree } from 'vuex';
import { TradeChannelState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TradeChannelState, RootState> = {
  ['getTradeChannels']: (state) => state.tradeChannels,
  ['getTradeChannelsIsLoading']: (state) => state.tradeChannels?.isLoading,
  ['getTradeChannelsTotal']: (state) => state.tradeChannels?.total,
  ['getTradeChannelsSearchParams']: (state) => state.tradeChannels?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
};
