import { ActionTree } from 'vuex';
import { AllocationState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocation } from '@/shared/backend/allocation';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocation, { Value, Allocation, AllocationEx } from '@/shared/model/allocation';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocations');
export const actions: ActionTree<AllocationState, RootState> = {
  getAllocations({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocation
      .getAllocations(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocations', response.data);
        commit('setAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllByDocumentId({ commit, dispatch, getters, rootGetters }, payload: { id: string; allocationId: string }) {
    commit('setAllocationsIsLoading', true);
    return defaultBackendAllocation
      .getAllByDocumentId(payload.id, payload.allocationId)
      .then((response: AxiosResponse<AllocationEx>) => {
        commit('allocationBrand/setAllocationBrandsEx', response.data.allocationBrands, { root: true });
        commit('allocationOffice/setAllocationOfficesEx', response.data.allocationOffices, { root: true });
        commit('setAllocationPropertyEx', response.data.allocationFrame.properties);
        commit('setAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getListByDocumentId({ commit, dispatch, getters, rootGetters }, payload: { id: string; allocationId: string }) {
    commit('setAllocationsIsLoading', true);
    return defaultBackendAllocation
      .getListByDocumentId(payload.id, payload.allocationId)
      .then((response: AxiosResponse<Allocation[]>) => {
        commit('setAllocationList', response.data);
        commit('setAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMainAllocationsByDocumentId({ commit, dispatch, getters, rootGetters }, id: string) {
    commit('setAllocationsIsLoading', true);
    return defaultBackendAllocation
      .getMainAllocationsByDocumentId(id)
      .then((response: AxiosResponse<Allocation[]>) => {
        commit('setAllocationAllocationsData', response.data);
        commit('document/setDocumentsAllocations', { allocations: response.data, documentId: id }, { root: true });
        commit('setAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocation({ commit, dispatch }, id: string) {
    return defaultBackendAllocation
      .getAllocation(id)
      .then((response: AxiosResponse<Allocation>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocation({ commit, dispatch }, file: Allocation) {
    return defaultBackendAllocation
      .updateAllocation(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Allocation;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocation_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocation({ commit, dispatch }, id: string) {
    return defaultBackendAllocation
      .deleteAllocation(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Allocation;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  copyAllocation({ commit, dispatch }, id: string) {
    return defaultBackendAllocation
      .copyAllocation(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Allocation;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
