import { render, staticRenderFns } from "./qa-overview-dialog.html?vue&type=template&id=0814e8ca&scoped=true&external"
import script from "./qa-overview-dialog.ts?vue&type=script&lang=ts&external"
export * from "./qa-overview-dialog.ts?vue&type=script&lang=ts&external"
import style0 from "./qa-overview-dialog.scss?vue&type=style&index=0&id=0814e8ca&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0814e8ca",
  null
  
)

/* custom blocks */
import block0 from "./qa-overview-dialog.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-qa%2Fqa-overview-dialog%2Fqa-overview-dialog.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardActions,VCardText,VCardTitle,VCol,VDataFooter,VDataIterator,VDialog,VSpacer})
