import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import role, { Role, Value } from '../model/role';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';
import JsObjectUtils from '../utils/jsObjectUtils';
import roleRight, { RoleRight, Value as RoleRigtValue } from '../model/roleRight';

const logger = new Logger('backend.Role');
export interface BackendRole {
  getRole(id: string): AxiosPromise<Role>;
  getRoles: (searchParams: SearchParams) => AxiosPromise<Value>;
  getRoleRigts: (roleId: string, searchParams: SearchParams) => AxiosPromise<RoleRigtValue>;
  deleteRole(id: string): AxiosPromise;
  updateRole(Role: Role): AxiosPromise<any>;
  updateRoleRights(roleId: string, item: RoleRight[], folderRights: RoleRight[]): AxiosPromise<any>;
}

export const defaultBackendRole: BackendRole = {
  getRole(id: string): AxiosPromise<Role> {
    const url = `${URLS.roleOdata}/${id}`;
    return instance.get<Role>(url);
  },
  getRoles(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.roleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      ['$expand=RoleLoginTimes'],
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getRoles${url}`);
    return instance.get<Value>(url);
  },
  getRoleRigts(roleId: string, searchParams: SearchParams): AxiosPromise<RoleRigtValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.roleOdata}(${roleId})/RoleRights`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined
    );
    logger.log(`getRoleRigts${url}`);
    return instance.get<RoleRigtValue>(url);
  },

  deleteRole(id: string): AxiosPromise {
    logger.debug('deleteRole');
    return instance.delete(`${URLS.role}/${id}`);
  },
  updateRole(item: Role): AxiosPromise<any> {
    logger.debug('updateRole');
    if (item.id) {
      return instance.patch<Role>(`${URLS.roleOdata}(${item.id})`, role.toAPI(item));
    } else {
      const formData = JsObjectUtils.getFormData(role.toAPI(item));
      return instance.post<Role>(`${URLS.roleOdata}`, formData);
    }
  },
  updateRoleRights(roleId: string, items: RoleRight[], folderRights: RoleRight[]): AxiosPromise<any> {
    logger.debug('updateRoleRights');
    return instance.post<Role>(`${URLS.roleOdata}(${roleId})/MultipleUpdateRoleRights`, {
      rights: items.map((item) => roleRight.toAPI(item)),
      folderRights: folderRights,
    });
  },
};
