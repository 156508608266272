import { MutationTree } from 'vuex';
import { DocumentFilesExportState } from './types';
import Vue from 'vue';
import documentFilesExport, { DocumentFilesExport, Value } from '@/shared/model/documentFilesExport';

export const mutations: MutationTree<DocumentFilesExportState> = {
  setDocumentFilesExports(state, payload: Value) {
    state.documentFilesExports.items = payload.value.map((x) => documentFilesExport.parse(x));
    state.documentFilesExports.total = payload['@odata.count'] || 0;
    state.documentFilesExports.isLoading = false;
  },
  setDocumentFilesExportsTotal(state, payload: number) {
    state.documentFilesExports.total = payload;
  },
  setDocumentFilesExportsIsLoading(state, payload: boolean) {
    state.documentFilesExports.isLoading = payload;
  },
};
