import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationFrameProperty, { AllocationFrameProperty, Value } from '../model/allocationFrameProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationFrameProperty');
export interface BackendAllocationFrameProperty {
  getAllocationFrameProperty(id: string): AxiosPromise<AllocationFrameProperty>;
  getAllocationFramePropertys: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationFrameProperty(id: string): AxiosPromise;
  updateAllocationFrameProperty(AllocationFrameProperty: AllocationFrameProperty): AxiosPromise<any>;
}

export const defaultBackendAllocationFrameProperty: BackendAllocationFrameProperty = {
  getAllocationFrameProperty(id: string): AxiosPromise<AllocationFrameProperty> {
    const url = `${URLS.allocationFramePropertyOdata}/${id}`;
    return instance.get<AllocationFrameProperty>(url);
  },
  getAllocationFramePropertys(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationFramePropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationFramePropertys${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationFrameProperty(id: string): AxiosPromise {
    logger.debug('deleteAllocationFrameProperty');
    return instance.delete(`${URLS.allocationFrameProperty}/${id}`);
  },
  updateAllocationFrameProperty(AllocationFrameProperty: AllocationFrameProperty): AxiosPromise<any> {
    logger.debug('updateAllocationFrameProperty');
    return instance.put<AllocationFrameProperty>(
      `${URLS.allocationFrameProperty}/update`,
      allocationFrameProperty.toAPI(AllocationFrameProperty)
    );
  },
};
