export interface Employee {
  email: string;
  firstName: string;
  shortForm: string;
  lastName: string;
  roles: string[];
  userId: string;
  username: string;
  idOld: number;
  isSeller: boolean;

  id: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
}

export interface Value {
  value: Employee[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<Employee>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,

    Email: data?.email || undefined,

    ShortForm: data?.shortForm || '',
    FirstName: data?.firstName || '',
    LastName: data?.lastName || '',
    IsSeller: data?.isSeller || false,
  };
}
function parse(data?: Partial<Employee>): Employee {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',

    email: data?.email || '',
    shortForm: data?.shortForm || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    roles: data?.roles || [],
    userId: data?.userId || '',
    username: data?.username || '',
    idOld: data?.idOld || 0,
    isSeller: data?.isSeller || false,
  };
}
export default {
  parse,
  toAPI: toAPI,
};
