import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import doc, { Document } from '@/shared/model/document';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { OCR_RADIO_OPTIONS as OcrOptions } from './ocrDialogRadioOptions';
import docPageTxt, { DocumentPagesText } from '@/shared/model/documentPagesText';

const logger = new Logger('ocr-text-dialog');
const documentModule = namespace('document');
const documentPageTextModule = namespace('documentPagesText');

@Component
export default class OcrTextDialog extends Vue {
  @documentPageTextModule.Action('updateDocumentPagesText')
  private actionUpdateDocumentPageText!: any;
  @documentPageTextModule.Action('getDocumentPagesText')
  private actionGetDocumentPagesText!: any;

  @documentModule.Action('rescanDocument')
  private actionRescanDocuments!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: () => doc.parse({}) })
  private value!: Document;

  private model: DocumentPagesText = docPageTxt.parse({});

  isLoading = false;

  // (ED-1167) load data on dialog open (since we removed v-if in `menu-maintenance-btn` for dialog)
  @Watch('dialog')
  onDialog(newV: boolean, oldV: boolean) {
    if (newV) this.loadDocumentText();
  }

  private async loadDocumentText() {
    if (!this.value.text && this.value.documentPagesTextId) {
      await this.actionGetDocumentPagesText(this.value.documentPagesTextId)
        .then((result: any) => {
          this.model = result;
          this.value.text = this.model.pagesText; // (ED-1167) update Document `text` to avoid extra queries when open dialog
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  //#region Logic for RadioGroup OCR options
  ocrTextAction: OcrOptions = OcrOptions.ocrFile;

  get isAutoOcrMode() {
    return this.ocrTextAction !== OcrOptions.editOcrManually;
  }

  get ocrFile() {
    return OcrOptions.ocrFile;
  }

  get newOcr() {
    return OcrOptions.newOcr;
  }

  get editOcrManually() {
    return OcrOptions.editOcrManually;
  }
  //#endregion

  private lastOcrDialogAction = '';
  clickClose() {
    this.$emit('click:close', this.lastOcrDialogAction);
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  clickSave() {
    logger.debug('clickSave');

    if (this.ocrTextAction === OcrOptions.newOcr) {
      this.$confirm
        .open(`${this.$t('warning_recreate_ocr')}`, `${this.$t('warning_recreate_ocr_message')}`, {
          cancelText: this.$t('cancel'),
          okText: this.$t('continue'),
        })
        .then(async (response: any) => {
          if (response) {
            this.isLoading = true;

            this.lastOcrDialogAction = OcrOptions.newOcr;
            this.clickClose();
            this.actionRescanDocuments({ id: this.value.id, textOrPreview: 1 })
              .catch((err: any) => {
                logger.error(err);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            return;
          }
        });
    }

    if (this.ocrTextAction === OcrOptions.editOcrManually) {
      this.isLoading = true;

      this.lastOcrDialogAction = OcrOptions.editOcrManually;
      this.actionUpdateDocumentPageText(this.model)
        .then(() => {
          this.value.text = this.model.pagesText;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.clickClose();
        });
    }
  }
}
