import { MutationTree } from 'vuex';
import { AllocationOfficesImportSettingState } from './types';
import Vue from 'vue';
import allocationOfficesImportSetting, {
  AllocationOfficesImportSetting,
  Value,
} from '@/shared/model/allocationOfficesImportSetting';

export const mutations: MutationTree<AllocationOfficesImportSettingState> = {
  setAllocationOfficesImportSettings(state, payload: Value) {
    state.allocationOfficesImportSettings.items = payload.value.map((x) => allocationOfficesImportSetting.parse(x));
    state.allocationOfficesImportSettings.total = payload['@odata.count'] || 0;
    state.allocationOfficesImportSettings.isLoading = false;
  },
  setAllocationOfficesImportSettingsTotal(state, payload: number) {
    state.allocationOfficesImportSettings.total = payload;
  },
  setAllocationOfficesImportSettingsIsLoading(state, payload: boolean) {
    state.allocationOfficesImportSettings.isLoading = payload;
  },
  setAllocationOfficesImportSetting(state, payload: Value) {
    state.allocationOfficesImportSetting = Object.assign({}, allocationOfficesImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateAllocationOfficesImportSetting(state, payload: boolean) {
    state.inProgressUpdateAllocationOfficesImportSetting = payload;
  },
};
