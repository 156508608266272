import { GetterTree } from 'vuex';
import { UserFileState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UserFileState, RootState> = {
  ['getUserFile']: (state) => state.userFile,
  ['getUserFileIsLoading']: (state) => state.userFileIsLoading.length > 0,
  ['getUserFileIsLoadingById']: (state) => (id: string) => state.userFileIsLoading.includes(id),
  ['getUserFiles']: (state) => state.userFiles,
  ['getUserFilesIsLoading']: (state) => state.userFiles?.isLoading,
  ['getUserFilesTotal']: (state) => state.userFiles?.total,
  ['getUserFilesSearchParams']: (state) => state.userFiles?.searchParams,
};
