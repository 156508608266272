import { render, staticRenderFns } from "./document-breadcrumb.html?vue&type=template&id=e2ab5bcc&scoped=true&external"
import script from "./document-breadcrumb.ts?vue&type=script&lang=ts&external"
export * from "./document-breadcrumb.ts?vue&type=script&lang=ts&external"
import style0 from "./document-breadcrumb.scss?vue&type=style&index=0&id=e2ab5bcc&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2ab5bcc",
  null
  
)

/* custom blocks */
import block0 from "./document-breadcrumb.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fapp%2Ftop-menu%2Fdocument-breadcrumb%2Fdocument-breadcrumb.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VBtn,VIcon,VToolbarTitle})
