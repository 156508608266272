export interface DocumentPagesText {
  id: string; // System.Guid
  documentId: string; // System.Guid
  pagesText: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationId: string; // System.Guid
}

export interface Value {
  value: DocumentPagesText[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentPagesText>): any {
  return {
    Id: data?.id || undefined,
    DocumentId: data?.documentId || undefined,
    PagesText: data?.pagesText || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    OrganisationId: data?.organisationId || undefined,
  };
}

function parse(data?: Partial<DocumentPagesText>): DocumentPagesText {
  return {
    id: data?.id || '',
    documentId: data?.documentId || '',
    pagesText: data?.pagesText || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
