export interface DocumentFile {
  id: string; // System.Guid
  documentId: string; // System.Guid
  fileId: string; // System.Guid
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationId: string; // System.Guid
}

export interface Value {
  value: DocumentFile[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentFile>): any {
  return {
    Id: data?.id || undefined,
    DocumentId: data?.documentId || undefined,
    FileId: data?.fileId || undefined,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    OrganisationId: data?.organisationId || undefined,
  };
}

function parse(data?: Partial<DocumentFile>): DocumentFile {
  return {
    id: data?.id || '',
    documentId: data?.documentId || '',
    fileId: data?.fileId || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
