import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import address, { Address, Value } from '../model/address';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.Address');
export interface BackendAddresses {
  getAddress(id: string): AxiosPromise<Address>;
  getAddresses: (searchParams: SearchParams, organizationId: string, searchTerm?: string) => AxiosPromise<Value>;
  deleteAddress(id: string): AxiosPromise;
  updateAddress(Address: Address): AxiosPromise<any>;
  uploadAddress(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendAddress: BackendAddresses = {
  getAddress(id: string): AxiosPromise<Address> {
    const url = `${URLS.addressOdata}/${id}`;
    return instance.get<Address>(url);
  },
  getAddresses(searchParams: SearchParams, organizationId: string, searchTerm?: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);

    if (searchTerm) {
      searchParams.filter = searchTerm!;
    }

    const url = DefaultBackendHelper.makeUrl(
      `${URLS.addressOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAddress${url}`);
    return instance.get<Value>(url);
  },

  deleteAddress(id: string): AxiosPromise {
    logger.debug('deleteAddress');
    return instance.delete(`${URLS.address}/${id}`);
  },
  updateAddress(Address: Address): AxiosPromise<any> {
    logger.debug('updateAddress');
    return instance.put<Address>(`${URLS.address}/update`, address.toAPI(Address));
  },
  uploadAddress(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.address}/post`, formData, {
      timeout: 20 * 60 * 1000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
