import { ActionTree } from 'vuex';
import { DocumentPropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentProperty } from '@/shared/backend/documentProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentProperty, { Value, DocumentProperty } from '@/shared/model/documentProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';

const logger = new Logger('actions.documentPropertys');
export const actions: ActionTree<DocumentPropertyState, RootState> = {
  getDocumentPropertys(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; documentId: string }
  ) {
    commit('setDocumentPropertysIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentPropertysSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    const documentId = payload?.documentId ?? '';
    return defaultBackendDocumentProperty
      .getDocumentPropertys(searchParams, organisationId, documentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentPropertys', response.data);
        commit('setDocumentPropertysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentPropertysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentProperty({ commit, dispatch }, id: string) {
    return defaultBackendDocumentProperty
      .getDocumentProperty(id)
      .then((response: AxiosResponse<DocumentProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentPropertyQueryFirstEnabled({ commit, dispatch }, id: string) {
    return defaultBackendDocumentProperty
      .getDocumentPropertyQueryFirstEnabled(id)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentPropertys({ commit, dispatch, rootGetters }, items: DocumentProperty[]) {
    return defaultBackendDocumentProperty
      .updateDocumentPropertys(items)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentProperty;
          }>
        ) => {
          commit(
            'document/setDocumentsProperties',
            {
              items,
              anabledFavoritePropertysIds: rootGetters[
                'organisationDocumentProperty/getOrganisationDocumentPropertys'
              ].items.filter((prop: OrganisationDocumentProperty) => prop.enabled == true && prop.favorite == true),
            },
            {
              root: true,
            }
          );
          module_utils.ok(i18n.tc(`success.documentProperty_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentProperty({ commit, dispatch }, id: string) {
    return defaultBackendDocumentProperty
      .deleteDocumentProperty(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentProperty;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
