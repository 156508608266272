import { ActionTree } from 'vuex';
import { AllocationTemplateState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationTemplate } from '@/shared/backend/allocationTemplate';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationTemplate, { Value, AllocationTemplate } from '@/shared/model/allocationTemplate';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { AllocationTemplateEx } from '@/shared/model/allocationTemplateEx';

const logger = new Logger('actions.allocationTemplates');
export const actions: ActionTree<AllocationTemplateState, RootState> = {
  getAllocationTemplates({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationTemplatesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationTemplatesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTemplate
      .getAllocationTemplates(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTemplates', response.data);
        commit('setAllocationTemplatesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationTemplatesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTemplate({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTemplate
      .getAllocationTemplate(id)
      .then((response: AxiosResponse<AllocationTemplate>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTemplatesById({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTemplate
      .getAllocationTemplatesById(id)
      .then((response: AxiosResponse<AllocationTemplateEx[]>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationTemplate({ commit, dispatch }, file: AllocationTemplate) {
    return defaultBackendAllocationTemplate
      .updateAllocationTemplate(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTemplate;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationTemplate_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationTemplate({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTemplate
      .deleteAllocationTemplate(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTemplate;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
