import { MutationTree } from 'vuex';
import { DatevSettingState } from './types';
import Vue from 'vue';
import datevSetting, { DatevSetting, Value } from '@/shared/model/datevSetting';

export const mutations: MutationTree<DatevSettingState> = {
  setDatevSettings(state, payload: Value) {
    state.datevSettings.items = payload.value.map((x) => datevSetting.parse(x));
    state.datevSettings.total = payload['@odata.count'] || 0;
    state.datevSettings.isLoading = false;
  },
  setDatevSettingsTotal(state, payload: number) {
    state.datevSettings.total = payload;
  },
  setDatevSettingsIsLoading(state, payload: boolean) {
    state.datevSettings.isLoading = payload;
  },
  setIsUpdating(state, payload: boolean) {
    state.isUpdating = payload;
  },
  setDatevSetting(state, payload: Value) {
    state.current = Object.assign({}, datevSetting.parse(payload.value[0]));
  },
};
