import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FilteringButtons from '@/components/filtering-buttons/filtering-buttons.vue';
import { Logger } from 'fsts';
import { OdataItems } from '@/shared/model/OdataItems';
import { Document } from '@/shared/model/document';
import DocumentsMergeDialog from './documents-merge-dialog/documents-merge-dialog.vue';
import DocumentRestoreDialog from './documents-restore-dialog/documents-restore-dialog.vue';
import DocumentsDatesDialog from './documents-dates-dialog/documents-dates-dialog.vue';
import DocumentsDeleteDialog from './documents-delete-dialog/documents-delete-dialog.vue';
import MenuDocumentAssignFolderView from '../menu-document-assign-folder/menu-document-assign-folder.vue';
import MenuDocumentInformationView from '../menu-document-information/menu-document-information.vue';
import MenuDocumentsStatusValuesView from './menu-documents-status-values/menu-documents-status-values.vue';
import { SearchParams } from '@/shared/model/searchParams';
import { Folder } from '@/shared/model/folder';
import { VueExtensions } from 'vue/types/vue';
import { FilterBntValues, FilterBtnData } from '@/shared/model/smallPayloadModels/filterBtnData';
import { ChipData } from '@/shared/model/ChipData';
import TreeUtils from '@/shared/utils/treeUtils';
import GeneralUtils from '@/shared/utils/generalUtils';
import DocumentDeleteDialog from '@/components/dialogs/documentDeleteDialog/documentDeleteDialog.vue';
import store from '@/shared/store';

const logger = new Logger('menu-document-list');

const documentModule = namespace('document');
const authModule = namespace('auth');
const folderModule = namespace('folder');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

type SearchMenuItem = {
  title: string;
  field: string;
};

const searchWordMinLenght = 1;
@Component({
  name: 'menu-document-list',
  components: {
    FilteringButtons,
    MenuDocumentAssignFolderView,
    DocumentsMergeDialog,
    DocumentRestoreDialog,
    DocumentsDatesDialog,
    DocumentsDeleteDialog,

    MenuDocumentInformationView,
    MenuDocumentsStatusValuesView,

    DocumentDeleteDialog,
  },
})
export default class MenuDocumentListView extends Vue {
  @authModule.Getter('getIsLeftDrawerShown')
  private getterIsLeftDrawerShown: any;
  @authModule.Getter('DocumentShowOnlyIdSearchAllowed')
  private DocumentShowOnlyIdSearchAllowed: any;
  @authModule.Getter('DeleteDocumentsAllowed')
  private DeleteDocumentsAllowed: any;
  @authModule.Getter('MergeDocumentsAllowed')
  private MergeDocumentsAllowed: any;

  @authModule.Getter('isRightViewAllowed')
  private isRightViewAllowed!: (name: string) => boolean;

  @Prop({ default: false, type: Boolean })
  private isTrashMenu!: boolean;

  @authModule.Getter('isSiteOwner')
  private isSiteOwner!: boolean;

  drawerSubRight = false;

  @Watch('getterIsLeftDrawerShown')
  private onLeftDrawerChange(newValue: any, o: any) {
    if (!newValue) {
      this.resetSubMenus();
    }
  }

  documentSelection = 'by_one';
  isSelectionModeOn = false;

  //#region searchModes

  @documentModule.Action('updateSearchModes')
  private actionUpdateSearchModes!: any;
  @documentModule.Getter('getSearchModes')
  private getSearchModes!: any;

  private documentSearchSelection: string[] = ['1'];

  @Watch('documentSearchSelection')
  private ondocumentSearchSelectionChange(newValue: any, oldValue: any) {
    if (!oldValue.includes('2') && newValue.includes('2') && newValue.length > 1) {
      this.documentSearchSelection = ['2'];
    }
    if (oldValue.includes('2') && newValue.length > 1) {
      var index = this.documentSearchSelection.indexOf('2');
      if (index !== -1) {
        this.documentSearchSelection.splice(index, 1);
      }
    }

    if (!oldValue.includes('0') && newValue.includes('0') && newValue.length > 1) {
      this.documentSearchSelection = ['0'];
    }
    if (oldValue.includes('0') && newValue.length > 1) {
      var index = this.documentSearchSelection.indexOf('0');
      if (index !== -1) {
        this.documentSearchSelection.splice(index, 1);
      }
    }
    this.actionUpdateSearchModes(this.documentSearchSelection);
  }

  //#endregion

  get showFutureFeature() {
    return true; //GeneralUtils.showFutureFeature; DocumentShowOnlyIdSearchAllowed
  }

  filteredButtonsHeaders = [
    'Kontiert',
    'GoBD',
    'Adresse',
    'Templates', // Templates: `menu-document-template`
    'Genehmigt',
    'Endkontrolle',
    'Zahlung offen',
    'Prüfen',
    'DMS gebucht',
    'per Lastschrift',
    'Test_01',
    'Extra5',
    'Extra6',
  ];

  get goBDStatuses() {
    return [
      { id: 0, name: this.$t(`gobd_status.all`) },
      { id: 1, name: this.$t(`gobd_status.without`) },
      { id: 2, name: this.$t(`gobd_status.incomplete`) },
      { id: 3, name: this.$t(`gobd_status.manual`) },
      { id: 4, name: this.$t(`gobd_status.automatic`) },
    ];
  }

  // (ED-552) keep `filter data` when return from `document-preview`
  getFilterType(statusValue: string) {
    if (this.filterData.length > 0 && this.filterData.find((x) => x.docStateStatus == statusValue)) {
      return this.filterData.find((x) => x.docStateStatus == statusValue)?.value;
    }
  }

  //#region Lifecycle hooks
  mounted() {
    logger.log('mounted this.getDocumentSearchData', this.getDocumentSearchData);
    this.takeSearchDataFromState();
    this.documentSearchSelection = this.getSearchModes;
    // this.actionGetDocuments(); // (ED-1261) prevent to load documents each time when `right-menu` is shown
  }
  destroyed() {
    this.populateSearchDataStateForDocumentPreviewRoute();
    logger.log('destroyed this.chipData', this.documentsSearchPayload.chipDataArray);
  }
  //#endregion

  //#region (ED-385) `Filtering-button` logic
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getterGetOrganisationDocumentExtraStatusSettings!: any;

  get filterData(): Array<FilterBtnData> {
    return this.documentsSearchPayload.filterData;
  }

  // (ED-385) need this more complicated object than just names because in `OrganisationDocumentExtraStatusSettings` table there are 2 different properties `Name` (rendered in HTML and can be renamed) and `StatusValue` (constant value for status wich cannot be changed) if use just `Name` as was before then after renaming the status the Filtering buttons(Yes,No, All) will NOT work.
  // even some default statuses didn't work until that changes because had different `Name` and `StatusValue` by default (ie `Bezahlt` and `payed`)
  get statusNames() {
    const extraStatusNamesAndValues = this.items.map((x: any) => ({
      name: x?.name,
      statusValue: x?.statusValue,
    }));
    const kontiertAndAddress = this.filteredButtonsHeaders.slice(0, 4).map((x: string) => ({
      name: x,
      statusValue: x.toLowerCase(),
    }));
    const extraButtons = [
      {
        name: this.$t(`reminder`),
        statusValue: 'reminder'.toLowerCase(),
      },
    ];
    if (this.isSiteOwner) {
      extraButtons.push({
        name: this.$t(`OCR`),
        statusValue: 'OCR'.toLowerCase(),
      });
    }
    const goodObject = [...kontiertAndAddress, ...extraStatusNamesAndValues, ...extraButtons];
    return goodObject;
  }

  get items() {
    return this.getterGetOrganisationDocumentExtraStatusSettings.items;
  }

  //GOBD
  changeGoBdStatus(value: any) {
    this.setDocumentSearchGobdStatus(value);
    switch (value) {
      case 0:
        this.changeFilBtnValue(FilterBntValues.all, 'gobd');
        break;
      case 1:
        this.changeFilBtnValue(FilterBntValues.no, 'gobd');
        break;
      case 2:
        this.changeFilBtnValue(FilterBntValues.incomplete, 'gobd');
        break;
      case 3:
        this.changeFilBtnValue(FilterBntValues.manual, 'gobd');
        break;
      case 4:
        this.changeFilBtnValue(FilterBntValues.auto, 'gobd');
        break;
      default:
        this.changeFilBtnValue(FilterBntValues.yes, 'gobd');
        break;
    }
  }

  changeFilBtnValue(value: FilterBntValues, header: string) {
    const arrayIndex = this.filterData.findIndex((x) => x.docStateStatus === header);
    // no value in array
    if (
      (value === FilterBntValues.yes ||
        value === FilterBntValues.no ||
        value === FilterBntValues.manual ||
        value === FilterBntValues.auto ||
        value === FilterBntValues.incomplete) &&
      arrayIndex < 0
    ) {
      this.filterData.push({ value: value, docStateStatus: header });
    } else if (
      (value === FilterBntValues.yes ||
        value === FilterBntValues.no ||
        value === FilterBntValues.manual ||
        value === FilterBntValues.auto ||
        value === FilterBntValues.incomplete) &&
      arrayIndex >= 0
    ) {
      // if there is value in array, then update it
      this.filterData[arrayIndex].value = value;
    } else if (value === FilterBntValues.all) {
      // if `ALL` delete the filter item from `filterData` array
      this.filterData.splice(arrayIndex, 1);
    }

    this.updateSearchResults();

    if (this.filterData.length > 0) {
      this.mutationSetHasActiveSearch(true);
    } else {
      this.mutationSetHasActiveSearch(false);
    }
  }
  //#endregion

  //#region (ED-552) Logic to keep search (filtered documents) when return from `document-preview`
  @documentModule.Mutation('setHasActiveSearch')
  private mutationSetHasActiveSearch!: any;
  @documentModule.Mutation('setDocumentSearchData')
  private mutationSetDocumentSearchData!: any;
  @documentModule.Getter('getDocumentSearchData')
  private getDocumentSearchData!: any;
  @documentModule.State('documentSearchGobdStatus')
  private goBDStatus!: number; // = 0;
  @documentModule.Mutation('setDocumentSearchGobdStatus')
  private setDocumentSearchGobdStatus!: any; // = 0;

  private populateSearchDataStateForDocumentPreviewRoute() {
    if (this.$router.currentRoute.name == 'document-preview') {
      this.mutationSetDocumentSearchData({
        chipData: this.documentsSearchPayload.chipDataArray,
        searchType: this.searchType,
        filterData: this.filterData,
      });
    }
  }

  private takeSearchDataFromState() {
    this.takeSearchDataFromStateSingle('AdresseKundennummer', `Kundennummer (Adresse):`);
    this.takeSearchDataFromStateSingle('AdresseKennzeichen', `Kennzeichen (Adresse):`);
    this.takeSearchDataFromStateSingle('AdresseSeriennummer', `Seriennummer (Adresse):`);
    this.takeSearchDataFromStateSingle('AddressId', `Id (Adresse):`);
  }

  private takeSearchDataFromStateSingle(fieldId: string, title: string) {
    if (this.documentsSearchPayload.chipData?.startsWith(fieldId)) {
      const item = {
        field: fieldId,
        //TODO do we need translate fields ?
        title: title,
      };
      const fieldValue = this.documentsSearchPayload.chipData.split(':')[1];
      const chipText = this.formatChipTextForFrontend(item, `${fieldValue}`);
      if (this.hasChiptext(chipText)) return;
      this.documentsSearchPayload.chipDataArray.push({
        chipText: chipText,
        field: this.mapFieldWitBackendFieldName(item),
        searchWord: `${fieldValue}`,
      });
      this.searchWord = '';
    }
  }

  hasChiptext(chipText: string) {
    const stateHasChipText = this.documentsSearchPayload.chipDataArray.some((x: any) => x.chipText === chipText);
    return stateHasChipText;
  }

  //TODO remove
  private resetDocumentSearchData() {
    this.mutationSetDocumentSearchData(null);
  }
  //#endregion

  //#region Logic for search in CurrentFolder and child folders
  @authModule.Getter('getHeaderNavData')
  private getHeaderNavData: any;
  @folderModule.Getter('getMenuFoldersTreeViewFlat')
  private folders!: any;
  get getSearchFolderIds() {
    let folderIds: string | undefined = undefined;
    if (this.folderId && this.getHeaderNavData.translateId != 'all_documents') {
      folderIds = this.populateFolderIdsPayload();
    } else {
      folderIds = undefined; // (ED-367) reset `fullPath` when navigate to `ALL documents` or `Trash`
    }

    return folderIds;
  }

  /**
   * (ED-1195) Return array of folderId(s) to filter documents in facet search by using only current folder and its children (if specified)
   * @returns {number} Array of folderId(s) if which facet search should be done
   */
  private populateFolderIdsPayload() {
    let folderIds = this.folderId;
    if (this.documentsSearchPayload.isOnlyCurrentFolderDocs == false) {
      const currentFolderPath = TreeUtils.getFolderPath(this.folderId, this.folders);
      const ids = this.folders
        .filter((folder: any) => folder.fullPath.includes(currentFolderPath))
        .map((el: any) => el.id);
      if (ids.length > 0) {
        folderIds = ids.join(',');
      }
    }
    return folderIds;
  }

  get folderId() {
    return this.$route.params['folderId'];
  }
  //#endregion

  //#region Logic for SearchType switch and related methods
  get searchType() {
    return this.documentsSearchPayload.searchData.searchType;
  }

  get isOrSearch() {
    return this.documentsSearchPayload.searchData.searchType === 'OR';
  }

  setSearchType(value: any) {
    this.switchAndOrSearch();
  }

  /**
   * Get documents when change `Search type` mode (AND or OR)
   */
  switchAndOrSearch() {
    this.updateSearchResults();
  }
  //#endregion

  //#region `Facet search` for documents logic

  // TODO: (ED-312) probably move `chipData` and `searchWord` to Vuex and reset via global Vuex mutations
  //reset the `chips` and `text` from search input on route change
  @Watch('$route.path', { immediate: false, deep: true })
  onRouteChange(newVal: any, oldVal: any) {
    this.resetBlueSelectionIfActive(); // (ED-587) Reset `document-list` blue selection (if ACTIVE) when change Folder or Route
  }

  @documentModule.Action('getFacetSearchResult')
  private actionGetFacetSearchResult!: any;

  private menuItems: Array<any> = [];

  facetSearch = false;
  isSearching = false;
  menuDisabled = true;
  searchWord = '';
  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }

  //#region (ED-1194) Prevent `ghost facet result` menu with previous backend results, when input is empty
  resetMenuOnEmptyInput(e: any) {
    if (e.target.value.length === 0) {
      this.clearFacetResultAndDisableMenu();
    }
  }

  clearFacetResultAndDisableMenu() {
    this.menuItems = [];
    this.menuDisabled = true;
    this.facetSearch = false;
  }
  //#endregion

  clearSearchData() {
    this.clearFacetResultAndDisableMenu();

    this.documentsSearchPayload.chipDataArray = [];
    this.searchWord = '';
    //this.searchType = 'AND';
    this.mutationSetHasActiveSearch(false);
    this.resetSearchData();
    this.updateSearchResults();
  }

  removeChip(chipText: any) {
    const index = this.documentsSearchPayload.chipDataArray.findIndex((x) => x.chipText === chipText);
    this.documentsSearchPayload.chipDataArray.splice(index, 1);

    this.resetSearchData();
    this.updateSearchResults();
  }

  // (ED-1062) correctly reset Vuex `searchData` when search input is empty
  private resetSearchData() {
    this.documentsSearchPayload.searchData = {
      searchWord: this.searchWord,
      field: '',
      searchType: this.searchType,
    };
  }

  async updateSearchResults() {
    this.documentsSearchPayload.chipData = this.formatChipDataAsPayloadParameter() ?? '';
    // need `this.getCurrentFolderFullPath` otherwise on delete the chip the search is GLOBAL (on all documents) not in current folder and when delete the last chip `All documents` are loaded
    this.setFirstPage();
    await this.actionGetDocuments();
  }
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: {
    searchParams: SearchParams;
    showDeleted: boolean;
    isOnlyCurrentFolderDocs: boolean;
    folderId: string;
    searchData: { searchWord: string; field: string; searchType: string };
    chipData: string;
    chipDataArray: Array<ChipData>;
    filterData: Array<FilterBtnData>;
  };
  /**
   * Make the actual search in the documents table with returning documents based on results from `facet search`
   * @param {object} Menu item `object` with title(shown in menu) and field(backend field name) properties
   */
  async searchInRealDocuments(item: SearchMenuItem) {
    // TODO: (ED-367) !!! create class for `searchData`
    this.documentsSearchPayload.searchData = {
      searchWord: this.searchWord,
      field: this.mapFieldWitBackendFieldName(item),
      searchType: this.searchType,
    };
    this.documentsSearchPayload.chipData = this.formatChipDataAsPayloadParameter() ?? '';
    this.setFirstPage(); // (ED-1127) set 1st page when add new `searchWord` (chip) to the search query

    if (this.searchWord.length >= searchWordMinLenght && item.field.length > 0) {
      const searchWord = this.searchWord;
      this.documentsSearchPayload.chipDataArray.push({
        chipText: this.formatChipTextForFrontend(item, searchWord),
        field: this.mapFieldWitBackendFieldName(item),
        searchWord: searchWord,
      });
      this.searchWord = '';
      this.mutationSetHasActiveSearch(true);
      await this.actionGetDocuments().then(() => {
        if (this.getDocuments.total == 1) {
          if (this.searchType === 'AND' && this.documentSearchSelection.indexOf('2') !== -1) {
            //clear search chips when searching by IDS and AND switch
            this.documentsSearchPayload.chipDataArray = [];
            this.searchWord = '';
          }
          if (this.searchType !== 'OR') {
            // immediatly open founded SINGLE document
            const foundedSingleDocument: Document = this.getDocuments.items[0];
            this.$router.push(`/document/${foundedSingleDocument?.id}`);
          }
        }
      });
      this.menuDisabled = true;
    }
  }

  setFirstPage() {
    this.documentsSearchPayload.searchParams.dataOption.page = 1;
  }

  /**
   * Make `facet search` in documents to know in which document fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */
  private searchFacetModeInDocuments(e: any) {
    // restrict number of searches (chips) to 5
    if (this.documentsSearchPayload.chipDataArray.length === 5) {
      this.resetMenuItems();
      this.menuItems.push({ title: this.$i18n.t('menu_message.too_many_searches') });
      this.facetSearch = true;
      this.menuDisabled = false;
      return;
    }

    const searchWord: string = e.target.value;
    this.searchWord = searchWord;
    const chipDataString = this.formatChipDataAsPayloadParameter();
    // handle facet search for words more than 2 letters
    if (searchWord.length >= searchWordMinLenght) {
      this.isSearching = true;
      this.actionGetFacetSearchResult({
        showDeleted: this.documentsSearchPayload.showDeleted,
        word: searchWord,
        searchType: this.searchType,
        searchFolderIds: this.getSearchFolderIds,
        chipData: chipDataString,
        searchModes: this.documentSearchSelection,
      })
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
          this.isSearching = false;
        })
        .catch((err: any) => {
          this.isSearching = false;
          logger.error(err);
        });
    } else if (searchWord.trim().length === 0 && this.documentsSearchPayload.chipDataArray.length === 0) {
      // load all documents if press `Enter` with clear input
      this.actionGetDocuments();
    }
  }

  private formatChipDataAsPayloadParameter() {
    return this.documentsSearchPayload.chipDataArray.length > 1
      ? this.documentsSearchPayload.chipDataArray.map((x) => this.formatChipDataForBackendOdataRequest(x)).join(',')
      : this.documentsSearchPayload.chipDataArray.length === 1
      ? this.formatChipDataForBackendOdataRequest(this.documentsSearchPayload.chipDataArray[0])
      : undefined;
  }

  //#region (ED-505) logic for additional search (mapping for title, etc)
  // TODO: (ED-890) later (after completing ED-505) move these mapping variables in the separate CONST file
  private fieldTitleMapping: any = {
    DocumentNumber: 'Dokument-ID',
    TaxNumber: 'Steuernummer',
    Note: 'Notiz',
    AddressNumber: 'Kundennummer (Adresse)',
    AddressMark: 'Kennzeichen (Adresse)',
    AddressSerialNumber: 'Seriennummer (Adresse)',
    AddressFirstName: 'Vorname (Adresse)',
    AddressLastName: 'Nachname (Adresse)',
    InvoiceNumber: 'Belegnummer (GoBD)',
    SupplierAccount: 'Lieferant (GoBD)',
    DocumentProperty: 'Informationsfelder',
    AllocationPropertiesPostingText: `Buchungstext (Kontieren)`,
  };

  private backendFields: any = {
    Notiz: 'Note',
    Homepage: 'Kundennummer',
    TaxNumber: 'Steuernummer',
    AddressNumber: 'AdresseKundennummer',
    AddressMark: 'AdresseKennzeichen',
    AddressSerialNumber: 'AdresseSeriennummer',
  };

  private formatChipTextForFrontend(item: SearchMenuItem, searchWord: string): string {
    return `${item.title.split(':')[0]}: ${searchWord}`;
  }

  private formatChipDataForBackendOdataRequest(x: ChipData): string {
    return `${x.field}: ${x.searchWord}`;
  }

  private mapFieldWitBackendFieldName(item: SearchMenuItem): string {
    return this.backendFields[item.field] ? this.backendFields[item.field] : item.field;
  }
  // we need this type of mapping to show for 1 word backend field like `AdresseKundennummer` we should show 2 word title `Kundennummer (Adresse)` in the menu and Chip later
  private mapFieldWithTitle(fieldName: string) {
    let title = fieldName;
    if (this.fieldTitleMapping[title]) {
      title = this.fieldTitleMapping[title];
    }
    return title;
  }
  //#endregion

  formatResultFromSearch(result: any) {
    delete result['$id']; // delete `$id` key (strange return value from API routes)

    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);

    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1, -1);
      const title = this.mapFieldWithTitle(fieldName);
      this.menuItems.push({ title: `${title}: ${this.searchWord} (${result[element]})`, field: fieldName });
    });

    const resultDocumentPropertys: { value: string; name: string; count: number }[] = result['documentPropertys'];
    const filteredResultDocumentPropertys = resultDocumentPropertys.filter((x) => x.count > 0);
    filteredResultDocumentPropertys.forEach((element) => {
      this.menuItems.push({
        title: `${element.name} (Inf): ${this.searchWord} (${element.count})`,
        field: `documentPropertys/${element.name}`,
      });
    });

    if (fieldsWithResult.length + filteredResultDocumentPropertys.length === 0) {
      this.menuItems.push({ title: this.$i18n.t('menu_message.no_results') });
    }
  }

  /**
    Should reset `menuItems` before add result from the next search otherwise the previous result will be summed with current result
    */
  private resetMenuItems() {
    this.menuItems = [];
  }
  //#endregion

  //#region `SelectionMode` button-toggle logic
  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocumentIds!: any;
  @documentModule.Mutation('setSelectionMode')
  private mutationDocumentSelectionMode!: any;
  @documentModule.Mutation('setSelectedDocumentIds')
  private mutationSetSelectedDocumentIds!: any;
  @documentModule.Getter('getDocuments')
  private getterDocuments!: OdataItems<Document>;

  private activateSelection() {
    this.isSelectionModeOn = !this.isSelectionModeOn;
    this.mutationDocumentSelectionMode(this.isSelectionModeOn);
    if (!this.isSelectionModeOn) {
      this.clearSelectedItems();
    }
  }
  private disableSelectionMode() {
    this.isSelectionModeOn = false;
    this.mutationSetSelectionMode(false);
    this.clearSelectedItems();
  }
  private resetBlueSelectionIfActive() {
    if (this.isSelectionModeOn) {
      this.activateSelection();
    }
  }

  get isFilesSelected() {
    return this.isSelectionModeOn && this.getterSelectedDocumentIds.length > 0;
  }

  private clearSelectedItems() {
    this.mutationSetSelectedDocumentIds([]);
  }

  private selectAllItems() {
    const allIds: string[] = this.getterDocuments.items.map((x: Document) => x.id);

    this.mutationSetSelectedDocumentIds(allIds);
  }

  //#endregion

  //#region : DeleteDialog
  private isSaveBtnLoading = false;

  onClickDelete() {
    this.deleteDialog.model = {};
    this.deleteDialog.show = true;
  }

  deleteDialog = {
    show: false,
    model: {},
  };

  async deleteDialogOnClose() {
    this.deleteDialog.show = false;
  }

  async deleteDialogOnDelete(date: string) {
    this.deleteDialog.show = false;

    await this.actionUpdateDeletedStatus({
      documentIds: this.getterSelectedDocumentIds,
      isRecover: false,
      deleteAfter: date,
    })
      .then(() => {
        this.actionGetDocuments().then(() => {
          this.actionGetDocumentFoldersCounts(); // (ED-1196) Update `left-menu` count after moving documents to `trash` folder
        }); // update document-list after moving data to `trash` folder
        this.$router.push('/dashboard/organization');
      })
      .catch((err: any) => {
        logger.error(err);
      });

    this.disableSelectionMode();
  }
  //#endregion

  //#region Move to `trash` folder logic
  @documentModule.Action('updateDeletedStatus')
  private actionUpdateDeletedStatus!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @documentModule.Action('getDocumentFoldersCounts')
  private actionGetDocumentFoldersCounts!: any;

  private async moveToBin() {
    logger.debug('document_moveToBin');

    this.onClickDelete();
  }
  //#endregion

  //#region ` Filter aus Auswahl ` button logic
  @documentModule.Getter('getDocuments')
  private getDocuments!: OdataItems<Document>;
  @documentModule.Mutation('setDocuments')
  private mutationSetDocuments!: any;
  @documentModule.Mutation('setSelectionMode')
  private mutationSetSelectionMode!: any;

  // use same logic as in `https://neu.easy-docs.de/` they also don't send any request to backend when click this btn
  private filterSelectedDocuments() {
    if (this.isFilesSelected) {
      const filteredDocuments = this.getDocuments.items.filter((item: Document) =>
        this.getterSelectedDocumentIds.includes(item.id)
      );

      const mutationPayload = {
        value: filteredDocuments,
        '@odata.count': filteredDocuments.length,
      };

      this.mutationSetDocuments(mutationPayload);
      this.disableSelectionMode();
    }
  }
  //#endregion

  created() {
    // TODO: (refactor) (ED-370) maybe redo this approach with `$root.$refs` with Vuex or EventBus
    this.$root.$refs.MenuDocumentListCmp = this;
  }

  private isFolderMenu = false;
  private isInformationMenu = false;
  private isStatusValuesMenu = false;

  private openFolderSubmenu() {
    this.isFolderMenu = true;
    this.drawerSubRight = true;
  }

  private openInformationSubmenu() {
    this.isInformationMenu = true;
    this.drawerSubRight = true;
  }

  private openStatusValuesSubmenu() {
    this.isStatusValuesMenu = true;
    this.drawerSubRight = true;
  }

  private returnToMain() {
    this.isFolderMenu = false;
    this.isInformationMenu = false;
    this.isStatusValuesMenu = false;

    this.drawerSubRight = false;
    this.disableSelectionMode();
  }

  // TODO: (EGRUP-172) optimize logic when more submenus
  private resetSubMenus() {
    this.returnToMain();
  }

  private selectDocumentById() {
    const filteredDocuments = this.getDocuments.items.filter((item: Document) =>
      this.getterSelectedDocumentIds.includes(item.id)
    );
    return filteredDocuments;
  }

  //#region Document Merge Dialog logic
  private showDocumentsMergeDialog() {
    const documents = this.selectDocumentById();
    const accounttedDocuments = documents.filter((x: any) => x.haveAllocations);
    if (accounttedDocuments.length >= 2) {
      store.commit(
        'setSnackbarError',
        {
          message: this.$i18n.t('menu_message.two_accounting'),
          duration: 6000,
        },
        { root: true }
      );
      return;
    }
    this.dialogDocumentsMerge.show = true;
    this.dialogDocumentsMerge.model = this.selectDocumentById();
  }

  dialogDocumentsMerge = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentsMerge.show = false;
    },
    OnMerge: () => {
      this.dialogDocumentsMerge.show = false;
      this.disableSelectionMode();
      this.actionGetDocuments();
    },
  };
  //#endregion

  //#region Restore Document dialog logic
  openRestoreDialog() {
    this.dialogDocumentRestore.show = true;
  }

  dialogDocumentRestore = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentRestore.show = false;
    },
  };

  private restoreDocuments() {
    logger.debug('restoreDocuments');
    this.openRestoreDialog();
  }
  //#endregion

  //#region (ED-384) Document Dates dialog logic
  openDocumentsDatesDialog() {
    this.dialogDocumentDates.show = true;
  }

  dialogDocumentDates = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentDates.show = false;
    },
  };

  //#endregion

  //#region (ED-424) Document Dates dialog logic

  @documentModule.Mutation('resetLastLoadDate')
  private resetLastLoadDate!: any;

  removeDocuments() {
    this.dialogDocumentsDelete.show = true;
    this.dialogDocumentsDelete.deleteAll = false;
    this.dialogDocumentsDelete.model = this.getterSelectedDocumentIds;
  }

  dialogDocumentsDelete = {
    show: false,
    deleteAll: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentsDelete.show = false;
    },
    updateAndResetBlueSelection: () => {
      this.resetLastLoadDate();
      this.actionGetDocuments();
      this.resetBlueSelectionIfActive();
    },
  };

  private removeAll() {
    this.dialogDocumentsDelete.show = true;
    this.dialogDocumentsDelete.deleteAll = true;
  }
  //#endregion
}
