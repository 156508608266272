import { GetterTree } from 'vuex';
import { SupplierState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SupplierState, RootState> = {
  ['getSuppliers']: (state) => state.suppliers,
  ['getSuppliersIsLoading']: (state) => state.suppliers?.isLoading,
  ['getSuppliersTotal']: (state) => state.suppliers?.total,
  ['getSuppliersSearchParams']: (state) => state.suppliers?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
  ['getAccountSupplier']: (state) => state.accountSupplier,
  ['getContraAccountSupplier']: (state) => state.contraAccountSupplier,
};
