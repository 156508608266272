import { ActionTree } from 'vuex';
import { FolderState } from './types';
import { RootState } from '../../types';
import { defaultBackendFolder } from '@/shared/backend/folder';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import uf, { Value, Folder } from '@/shared/model/folder';
import { AxiosResponse } from 'axios';
import folder from '@/shared/model/folder';
import { CONST } from '@/shared/utils/Constants';
import i18n from '@/i18n';
import { RoleRight } from '@/shared/model/roleRight';

const logger = new Logger('actions.folder');
export const actions: ActionTree<FolderState, RootState> = {
  getFolder({ commit, dispatch }, id: string) {
    return defaultBackendFolder
      .getFolder(id)
      .then((response: AxiosResponse<Folder>) => {
        commit('setFolder', uf.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getFolders({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; parentFolderId?: string }) {
    commit('setFoldersIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getFoldersSearchParams;
    return defaultBackendFolder
      .getFolders(searchParams, payload?.parentFolderId)
      .then((response: AxiosResponse<Value>) => {
        commit('setFolders', response.data);
        commit('setFoldersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setFoldersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getFoldersAll(
    { commit, getters, rootGetters },
    payload?: { searchParams?: any; parentFolderId?: string; roleId?: string }
  ) {
    //if start get action don't run second
    if (getters.getFoldersAll.isLoading) return;
    //simple cashing if exist don't load
    if (getters.getFoldersAll.items.length && getters.getFoldersAll.items[0].parentFolderId == payload?.parentFolderId)
      return;

    commit('setFoldersAllIsLoading', true);
    const searchParams = payload?.searchParams ?? { ...getters.getFoldersAll.searchParams };
    const roleId = payload?.roleId ?? rootGetters['auth/getAccount'].profile.role_id;
    return defaultBackendFolder
      .getFolders(searchParams, payload?.parentFolderId, roleId)
      .then(async (response: AxiosResponse<Value>) => {
        const folders = response.data;

        while (response.data['@odata.nextLink']) {
          response = await defaultBackendFolder.getFoldersByUrl(response.data['@odata.nextLink']);
          folders.value = folders.value.concat(response.data.value);
        }
        commit('setFoldersAll', folders);
        commit('setFoldersAllIsLoading', false);
        return folders;
      })
      .catch((e: any) => {
        commit('setFoldersAllIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMenuFolders({ commit, getters, rootGetters }, payload?: { searchParams?: any; parentFolderId?: string }) {
    //if start get action don't run second
    if (getters.getMenuFolders.isLoading) return;
    //simple cashing if exist don't load
    if (
      getters.getMenuFolders.items.length &&
      getters.getMenuFolders.items[0].parentFolderId == payload?.parentFolderId
    )
      return;

    commit('setMenuFoldersIsLoading', true);
    const searchParams = payload?.searchParams ?? { ...getters.getMenuFolders.searchParams };
    const roleId = rootGetters['auth/roleId'];
    const isOwner = rootGetters['auth/isOwner'];
    return defaultBackendFolder
      .getFolders(searchParams, payload?.parentFolderId, roleId)
      .then(async (response: AxiosResponse<Value>) => {
        const folders = response.data;
        while (response.data['@odata.nextLink']) {
          response = await defaultBackendFolder.getFoldersByUrl(response.data['@odata.nextLink']);
          folders.value = folders.value.concat(response.data.value);
        }
        commit('setMenuFolders', { folders, isOwner });
        return folders;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setMenuFoldersIsLoading', false);
      });
  },

  updateFolder({ commit, dispatch, rootGetters }, folder: Folder) {
    commit('setInProgressUpdateFolder', true);
    const organizationId = rootGetters[CONST.getOrganisation].id;
    if (!folder.id || !folder.organisationId) {
      folder.organisationId = organizationId;
    }

    return defaultBackendFolder
      .updateFolder(folder)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        module_utils.error(i18n.tc(`errors.folder_update`), commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateFolder', false);
      });
  },
  copyFolder({ commit, dispatch, rootGetters }, payload: { fromId: string; toId: string; toName: string }) {
    return defaultBackendFolder
      .copyFolder(payload.fromId, payload.toId, payload.toName)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteFolder({ commit, dispatch }, payload: { id: string; moveToId: string }) {
    return defaultBackendFolder
      .deleteFolder(payload.id, payload.moveToId)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateFoldersRoleRights({ commit, dispatch }, payload: { folderId: string; roleId: string; rights: RoleRight[] }) {
    commit('setInProgressUpdateFoldersRoleRights', true);
    return defaultBackendFolder
      .updateRoleRights(payload.folderId, payload.roleId, payload.rights)
      .then((response) => {
        module_utils.ok(i18n.tc(`success.role_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateFoldersRoleRights', false);
      });
  },
};
