import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import employee, { Employee, Value } from '../model/employee';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.Employee');
export interface BackendEmployee {
  getEmployee(id: string): AxiosPromise<Employee>;
  getEmployees: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteEmployee(id: string): AxiosPromise;
  updateEmployee(Employee: Employee): AxiosPromise<any>;
}

export const defaultBackendEmployee: BackendEmployee = {
  getEmployee(id: string): AxiosPromise<Employee> {
    const url = `${URLS.employeeOdata}/${id}`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<Employee>(url);
  },
  getEmployees(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.employeeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getFolders${url}`);
    return instance.get<Value>(url);
  },

  deleteEmployee(id: string): AxiosPromise {
    logger.debug('deleteEmployee');
    return instance.delete(`${URLS.employee}/${id}`);
  },
  updateEmployee(Employee: Employee): AxiosPromise<any> {
    return instance.put<Employee>(`${URLS.employee}/update`, employee.toAPI(Employee));
  },
};
