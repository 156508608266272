import Vue from 'vue';
import { Logger } from 'fsts';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import allocationTemplate, { AllocationTemplate } from '@/shared/model/allocationTemplate';
import MenuDocumentAccountTemplateEdit from '../menu-document-account-template-edit/menu-document-account-template-edit.vue';
import { OrganisationAllocationProperty } from '@/shared/model/organisationAllocationProperty';
import { AllocationPropertyEx } from '@/shared/model/allocation';

const logger = new Logger('allocationTemplate');
const allocationTemplateModule = namespace('allocationTemplate');
const organisationAllocationPropertyModule = namespace('organisationAllocationProperty');

@Component({ components: { MenuDocumentAccountTemplateEdit } })
export default class MenuDocumentAccountTemplateTable extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: '' })
  private model!: any;

  @allocationTemplateModule.Getter('getAllocationTemplates')
  private allocationTemplates?: any;
  @allocationTemplateModule.Action('getAllocationTemplates')
  private actionGetAllocationTemplates?: any;
  @allocationTemplateModule.Action('updateAllocationTemplate')
  private actionUpdateAllocationTemplate?: any;
  @allocationTemplateModule.Action('deleteAllocationTemplate')
  private actionDeleteAllocationTemplate?: any;

  @allocationTemplateModule.Action('getAllocationTemplatesById')
  private actionGetAllocationTemplatesById?: any;

  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationPropertiesActiveItems')
  private organisationAllocationPropertiesActiveItems!: OrganisationAllocationProperty[];

  get accountsItems() {
    return this.allocationTemplates.items;
  }

  private isSaveBtnLoading = false;

  private allocationsPropertiesDict: Record<string, Partial<AllocationPropertyEx>> = {
    '5fc8be6f-64b1-c407-9f49-6e7100000000': { name: 'Antriebsart', key: 'drive_type', value: '' },
    '53204fe8-b606-aab2-3c55-ecd500000000': { name: 'Marke', key: 'brand', value: '' }, //brand
    '53204fe8-b606-aab2-3c55-ecd300000000': { name: 'Filiale', key: 'branch', value: '' }, //branch
    '53204fe8-b606-aab2-3c55-ecd100000000': { name: 'Persornalnummer', key: 'staff_number', value: '' },
    '53204fe8-b606-aab2-3c55-eccf00000000': { name: 'Buchungsart', key: 'booking_type', value: '' },
    '53204fe8-b606-aab2-3c55-eccd00000000': { name: 'Belegstext 2', key: 'document_text_2', value: '' },
    '53204fe8-b606-aab2-3c55-eccb00000000': { name: 'Belegstext 1', key: 'document_text_1', value: '' },
    '53204fe8-b606-aab2-3c55-ecd700000000': { name: 'Kostenstelle', key: 'cost_center', value: '' }, //cost_center
    '53204fe8-b606-aab2-3c55-ecc900000000': { name: 'Buchungstext', key: 'posting_text', value: '' }, //posting_text
    '53204fe8-b606-aab2-3c55-ecc500000000': { name: 'Betrag', key: 'amount', value: '' }, //amount
    '53204fe8-b606-aab2-3c55-ecc300000000': { name: 'Gegenkontenkreis', key: 'contra_account_group', value: '' }, //contra_account_group
    '53204fe8-b606-aab2-3c55-ecc100000000': { name: 'Kontenkreis', key: 'account_group', value: '1' }, //account_group
    '53204fe8-b606-aab2-3c55-ecbf00000000': { name: 'Gegenkonto', key: 'contra_account', value: '' }, //contra_account
    '53204fe8-b606-aab2-3c55-ecbd00000000': { name: 'Konto', key: 'account', value: '' }, //account
    '53204fe8-b606-aab2-3c55-ecbb00000000': { name: 'Belegkreis', key: 'sparkle', value: '' },
    '53204fe8-b606-aab2-3c55-ecc700000000': { name: 'Rechnungsnummer', key: 'invoice_number', value: '' }, //invoice_number
    '53204fe8-b606-aab2-3c55-ecd900000000': { name: 'Kostenträger', key: 'cost_unit', value: '' }, //cost_unit
    '53204fe8-b606-aab2-3c55-ecdb00000000': { name: 'Herkunft', key: 'origin', value: '' },
    '53204fe8-b606-aab2-3c55-ecdd00000000': { name: 'Absatzkanal', key: 'sales_channel', value: '' }, //sales_channel
    '59a7cbe9-80dc-8475-077b-23c600000000': { name: 'Fremdwährung', key: 'foreign_currency', value: '' }, //foreign_currency
    '59a3f424-5746-d9f7-687e-6b8300000000': { name: 'Währung', key: 'exchange_rate', value: '' },
    '537b2cb2-09bb-e01a-1529-3c9e00000000': { name: 'Skontosatz', key: 'discount_rate', value: '' },
    '537b2cb2-09bb-e01a-1529-3c9c00000000': { name: 'Tage skonto', key: 'days_discount', value: '' }, //days_discount
    '537b2cb2-09bb-e01a-1529-3c9a00000000': { name: 'Tage netto', key: 'days_clean', value: '' }, //days_clean
    '5368eb93-09bb-e067-0911-69e200000000': { name: 'Skonto in &euro;', key: 'discount_in', value: '' }, //discount_in
    '53204fe8-b606-aab2-3c55-ecef00000000': { name: 'Lieferdatum', key: 'delivery_date', value: '' }, //delivery_date
    '53204fe8-b606-aab2-3c55-eced00000000': { name: 'Ausgleichsdatum', key: 'compensation_date', value: '' }, //compensation_date
    '53204fe8-b606-aab2-3c55-eceb00000000': { name: 'Seriennummer', key: 'serial_number', value: '' }, //serial_number
    '53204fe8-b606-aab2-3c55-ece900000000': { name: 'USt Code', key: 'vat_code', value: '' }, //vat_code
    '53204fe8-b606-aab2-3c55-ece700000000': { name: 'Steuer-Schlüssel', key: 'tax_key', value: '' }, //tax_key
    '53204fe8-b606-aab2-3c55-ece500000000': { name: 'Kontierungsdatum', key: 'denate_date', value: '' }, //denate_date
    '53204fe8-b606-aab2-3c55-ece300000000': { name: 'Projekt', key: 'project', value: '' },
    '53204fe8-b606-aab2-3c55-ece100000000': {
      name: 'Händler eigener Kostenträger',
      key: 'dealer_own_cost_unit',
      value: '',
    },
    '53204fe8-b606-aab2-3c55-ecdf00000000': { name: 'Erw. Vertriebsweg', key: 'exp_distribution_channel', value: '' },
    '53204fe8-b606-aab2-3c55-ecb900000000': { name: 'Rechnungsdatum', key: 'invoice_date', value: '' }, //invoice_date
    'a98a9b59-8786-405d-bcfa-80bf4aea97c8': { name: 'Steuerbetrag', key: 'tax_key_amount', value: '0,00' }, //tax_key_amount
  };

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name', width: '250px' },
      { text: this.$t('parameter'), value: 'parameter', sortable: false },
      { text: this.$t('actions'), value: 'actions', sortable: false },
    ];
    return headers;
  }

  clickClose() {
    this.allocationTemplates.searchParams.filter = '';
    this.$emit('click:close');
  }

  mounted() {
    this.actionGetAllocationTemplates();
  }

  accountEditTemplateDialog = {
    show: false,
    model: { ...allocationTemplate.parse({}) },
    properties: [],
  };

  async accountEditDialogOnClose(account: AllocationTemplate) {
    this.accountEditTemplateDialog.show = false;
  }

  async accountEditDialogOnUpdate(account: AllocationTemplate) {
    this.isSaveBtnLoading = true;
    this.actionUpdateAllocationTemplate(account)
      .then((result: any) => {})
      .catch((err: any) => {})
      .finally(() => {
        this.accountEditTemplateDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetAllocationTemplates();
      });
  }

  async accountEditDialogOnDelete(account: AllocationTemplate) {
    this.accountEditTemplateDialog.show = false;
    this.actionGetAllocationTemplates();
  }

  onClickAccountAdd() {
    this.accountEditTemplateDialog.model = { ...allocationTemplate.parse({}) };
    this.accountEditTemplateDialog.show = true;
  }
  async onClickAccountEdit(account: AllocationTemplate) {
    const editedTemplate = await this.actionGetAllocationTemplatesById(account.allocationTemplateId);
    this.accountEditTemplateDialog.properties = { ...editedTemplate };
    this.accountEditTemplateDialog.model = { ...account };
    this.accountEditTemplateDialog.show = true;
  }

  async onClickAccountSelect(account: AllocationTemplate) {
    this.allocationTemplates.searchParams.filter = '';
    this.$emit('click:select', account);
  }

  async onClickAccountDelete(account: AllocationTemplate) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: account.name,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteAllocationTemplate(account.allocationTemplateId)
            .then(() => {
              this.actionGetAllocationTemplates();
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }

  //#region search update
  @Watch('allocationTemplates.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }

  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('allocationTemplates.searchParams.dataOption.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      this.reloadData();
    }
  }

  @Watch('allocationTemplates.searchParams.dataOption.sortDesc', { deep: true })
  public onOptionsSortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    logger.debug(`-od-sortDesc:${oldValAsString}->${newValAsString}`);
    if (oldValAsString != newValAsString) {
      this.reloadData();
    }
  }

  @Watch('allocationTemplates.searchParams.dataOption.page', { deep: true })
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }

  private onOptionsChanged(newVal: any) {
    this.reloadData();
  }

  async reloadData() {
    await this.actionGetAllocationTemplates({ searchParams: this.allocationTemplates.searchParams });
  }
  //#endregion
}
