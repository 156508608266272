import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import address, { Address } from '@/shared/model/address';

const name = 'address-edit-dialog';
const logger = new Logger(name);

@Component({ name: name })
export default class AddressEditDialog extends Vue {
  private get salutationVariants(): { text: string; value: string }[] {
    //value Frau and Her stored in original german format for accept import from text/xml files
    return ['empty', 'Frau', 'Her'].map((x: string) => {
      // 'empty' used only for get translate, value for empty = ''
      return { value: x == 'empty' ? '' : x, text: `${this.$t('salutation.' + x)}` };
    });
  }
  @Ref('address-edit-form')
  private refAddressEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => address.parse({}) })
  private value!: Address;
  @Watch('value')
  onUpdateValue(newV: Address, oldV: Address) {
    this.address = Object.assign(this.address, newV);
    this.resetValidation(); // (ED-1291) reset validation errors when open empty dialog after create/update address (when `Address` model had values )
  }
  private address: Address = address.parse({});

  private isFormValid = true;
  get accountNumberRules() {
    return [
      (v: any) => !!v.toString().trim() || this.$i18n.t('messages.account_number_required'), // i18n.tc(`name_required_message`) is not working here, just shows `name_required_message` key
    ];
  }

  get lastNameRules() {
    return [];
  }

  get firstNameRules() {
    return [];
  }

  get markRules() {
    return [];
  }

  get serialNumberRules() {
    return [];
  }

  resetValidation() {
    if (this.refAddressEditForm) this.refAddressEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refAddressEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.address);
    }
  }
}
