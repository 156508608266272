import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class HomeHelpDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  clickClose() {
    this.$emit('click:close');
  }
}
