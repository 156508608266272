import { GetterTree } from 'vuex';
import { AllocationFrameState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationFrameState, RootState> = {
  ['getAllocationFrames']: (state) => state.allocationFrames,
  ['getAllocationFramesIsLoading']: (state) => state.allocationFrames?.isLoading,
  ['getAllocationFramesTotal']: (state) => state.allocationFrames?.total,
  ['getAllocationFramesSearchParams']: (state) => state.allocationFrames?.searchParams,
};
