import { render, staticRenderFns } from "./change-locale-radio-group.html?vue&type=template&id=e5c484fc&scoped=true&external"
import script from "./change-locale-radio-group.ts?vue&type=script&lang=ts&external"
export * from "./change-locale-radio-group.ts?vue&type=script&lang=ts&external"
import style0 from "./change-locale-radio-group.scss?vue&type=style&index=0&id=e5c484fc&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5c484fc",
  null
  
)

/* custom blocks */
import block0 from "./change-locale-radio-group.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fapp%2Fdev-components-not-for-users%2Fchange-locale-radio-group%2Fchange-locale-radio-group.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
