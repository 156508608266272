import { render, staticRenderFns } from "./menu-document-assign-folder.html?vue&type=template&id=0255c396&scoped=true&external"
import script from "./menu-document-assign-folder.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-assign-folder.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-assign-folder.scss?vue&type=style&index=0&id=0255c396&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0255c396",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-assign-folder.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-assign-folder%2Fmenu-document-assign-folder.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VIcon,VTextField,VTreeview})
