import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationOfficesImportSetting, {
  AllocationOfficesImportSetting,
  Value,
} from '../model/allocationOfficesImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationOfficesImportSetting');
export interface BackendAllocationOfficesImportSetting {
  getAllocationOfficesImportSetting(id: string): AxiosPromise<AllocationOfficesImportSetting>;
  getAllocationOfficesImportSettings: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationOfficesImportSetting(id: string): AxiosPromise;
  updateAllocationOfficesImportSetting(
    AllocationOfficesImportSetting: AllocationOfficesImportSetting
  ): AxiosPromise<any>;
}

export const defaultBackendAllocationOfficesImportSetting: BackendAllocationOfficesImportSetting = {
  getAllocationOfficesImportSetting(id: string): AxiosPromise<AllocationOfficesImportSetting> {
    const url = `${URLS.allocationOfficesImportSettingOdata}/${id}`;
    return instance.get<AllocationOfficesImportSetting>(url);
  },
  getAllocationOfficesImportSettings(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationOfficesImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationOfficesImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationOfficesImportSetting(id: string): AxiosPromise {
    logger.debug('deleteAllocationOfficesImportSetting');
    return instance.delete(`${URLS.allocationOfficesImportSetting}/${id}`);
  },
  updateAllocationOfficesImportSetting(
    AllocationOfficesImportSetting: AllocationOfficesImportSetting
  ): AxiosPromise<any> {
    logger.debug('updateAllocationOfficesImportSetting');
    return instance.put<AllocationOfficesImportSetting>(
      `${URLS.allocationOfficesImportSetting}/update`,
      allocationOfficesImportSetting.toAPI(AllocationOfficesImportSetting)
    );
  },
};
