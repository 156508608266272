import { GetterTree } from 'vuex';
import { AllocationsExportAllocationState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationsExportAllocationState, RootState> = {
  ['getAllocationsExportAllocations']: (state) => state.allocationsExportAllocations,
  ['getAllocationsExportAllocationsIsLoading']: (state) => state.allocationsExportAllocations?.isLoading,
  ['getAllocationsExportAllocationsTotal']: (state) => state.allocationsExportAllocations?.total,
  ['getAllocationsExportAllocationsSearchParams']: (state) => state.allocationsExportAllocations?.searchParams,
};
