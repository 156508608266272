import { GetterTree } from 'vuex';
import { OrganisationAddressState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationAddressState, RootState> = {
  ['getOrganisationAddresses']: (state) => state.organisationAddresses,
  ['getOrganisationAddressesIsLoading']: (state) => state.organisationAddresses?.isLoading,
  ['getOrganisationAddressesTotal']: (state) => state.organisationAddresses?.total,
  ['getOrganisationAddressesSearchParams']: (state) => state.organisationAddresses?.searchParams,
  ['getCurrent']: (state) => state.current,
};
