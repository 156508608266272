import roleRight, { RoleRight } from './roleRight';

export interface Folder {
  id: string;
  folderId: string;
  parentFolderId: string | null;
  organisationId: string;
  ownerId: string;
  createdDate: string;
  changeTimeStamp: number | null;
  createTimeStamp: number | null;
  createdAt: string | null;
  updatedAt: string | null;
  deleted: boolean;
  lastWorkflowExecutionTimeStamp: string | null;
  color: string | null;
  isVisibleFoUpload: boolean;
  name: string;
  fullPath: string;
  isBuiltIn: boolean;
  //old
  folderIdOld: string;

  children?: Folder[]; // only `frontend` property to show `Folders` in Vuetify `v-treeview`
  folderRulesCount: number;
  roleRights: string[];
  giveAllRights: boolean;
  folderDocumentsCount: number;
}

export interface Value {
  value: Folder[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
  '@odata.nextLink'?: string;
}
// init api object
function toAPI(data: Partial<Folder>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    ParentFolderId: data?.parentFolderId || undefined,

    CreatedDate: data?.createdDate || undefined,

    OwnerId: data?.ownerId || undefined,
    Name: data?.name || '',
    FolderIdOld: data?.folderIdOld || '',
    GiveAllRights: data.giveAllRights || false,
    Color: data.color,
  };
}
function parse(data?: Partial<Folder>): Folder {
  return {
    id: data!.id || '',
    folderId: data!.folderId || '',
    organisationId: data?.organisationId || '',
    parentFolderId: data?.parentFolderId == undefined || data?.parentFolderId == null ? '' : data.parentFolderId,

    createdDate: data?.createdDate || '',

    ownerId: data!.ownerId || '',
    name: data!.name || '',
    fullPath: data?.fullPath || '',
    isBuiltIn: data?.isBuiltIn || false,
    folderIdOld: data?.folderIdOld || '',
    folderRulesCount: data?.folderRulesCount || 0,
    roleRights: data?.roleRights?.map((x: any) => x.name) || [],
    giveAllRights: data?.giveAllRights || true,
    folderDocumentsCount: data?.folderDocumentsCount || 0,

    changeTimeStamp: data?.changeTimeStamp || 0,
    createTimeStamp: data?.createTimeStamp || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    deleted: data?.deleted || false,
    lastWorkflowExecutionTimeStamp: data?.lastWorkflowExecutionTimeStamp || '',
    color: data?.color || '',
    isVisibleFoUpload: data?.isVisibleFoUpload || false,
    children: data?.children,
  };
}
export default {
  parse,
  toAPI: toAPI,
};
