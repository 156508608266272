import { DocumentRoleRight } from '@/shared/model/documentRoleRight';
import { OdataItems } from '@/shared/model/OdataItems';
import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const documentModule = namespace('document');

@Component
export default class DocumentGroupRightsDialog extends Vue {
  // TODO:(ED-210) load data from backend for this document after setting things up in the backend
  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: '' })
  private model!: any;
  @documentModule.Action('getDocumentRoleRights')
  private getDocumentRoleRights!: any;
  @documentModule.Getter('getDocumentRoleRights')
  private documentRoleRights!: OdataItems<DocumentRoleRight>;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('group'), value: 'roleName' },
      { text: this.$t('right'), value: 'right', sortable: false },
    ];
    return headers;
  }
  @Watch('documentRoleRights.searchParams.filter')
  onSearchChanged(old: string, newv: string) {
    this.getDocumentRoleRights({
      documentId: this.model.trim(),
    });
  }
  getDocumentRoleRightsOnOptionsUpdate(searchOptions: any) {
    this.getDocumentRoleRights({
      documentId: this.model.trim(),
    });
  }
  clickClose() {
    this.$emit('click:close');
  }
}
