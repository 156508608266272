import { MutationTree } from 'vuex';
import { ActionLogRoleState } from './types';
import Vue from 'vue';
import actionLogRole, { ActionLogRole, Value } from '@/shared/model/actionLogRole';

export const mutations: MutationTree<ActionLogRoleState> = {
  setActionLogRoles(state, payload: Value) {
    state.actionLogRoles.items = payload.value.map((x) => actionLogRole.parse(x));
    state.actionLogRoles.total = payload['@odata.count'] || 0;
    state.actionLogRoles.isLoading = false;
  },
  setActionLogRolesTotal(state, payload: number) {
    state.actionLogRoles.total = payload;
  },
  setActionLogRolesIsLoading(state, payload: boolean) {
    state.actionLogRoles.isLoading = payload;
  },
};
