export interface OrganisationProcessSettings {
  changeTimeStamp: number;
  updatedAt: string; //DateTime?
  processesFolderId: string;
  processesDocumentPropertyId: string;
}

function toAPI(data: Partial<OrganisationProcessSettings>): any {
  const result: any = {
    ChangeTimeStamp: data?.changeTimeStamp || undefined, //DateTime?
    UpdatedAt: data?.updatedAt || undefined, //DateTime?
    ProcessesFolderId: data?.processesFolderId || 0,
    ProcessesDocumentPropertyId: data?.processesDocumentPropertyId || false,
  };
  return result;
}
function parse(data?: Partial<OrganisationProcessSettings>): OrganisationProcessSettings {
  return {
    changeTimeStamp: data?.changeTimeStamp || 0,
    updatedAt: data?.updatedAt || '', //DateTime?
    processesFolderId: data?.processesFolderId || '',
    processesDocumentPropertyId: data?.processesDocumentPropertyId || '',
  };
}
export default {
  parse,
  toAPI: toAPI,
};
