export interface File {
  id: string; // System.Guid
  fileId: string; // System.Guid
  organisationId: string; // System.Guid
  previewFileId: string; // System.Guid
  pageCount: number; // int
  filename: string; // string
  shortFilename: string; // string
  ftpBackupDone: boolean; // bool
  mimeType: string; // string
  md5: string; // string
  fileSize: number; // uint
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  kind: string; // string
  documentNumbers: string;
  sortOrder: number;
  mergedToFileId?: string;
}

export interface Value {
  value: File[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<File>): any {
  return {
    Id: data?.id || undefined,
    FileId: data?.fileId || undefined,
    OrganisationId: data?.organisationId || undefined,
    PreviewFileId: data?.previewFileId || undefined,
    PageCount: data?.pageCount || 0,
    Filename: data?.filename || '',
    ShortFilename: data?.shortFilename || '',
    FtpBackupDone: data?.ftpBackupDone || false,
    MimeType: data?.mimeType || '',
    Md5: data?.md5 || '',
    FileSize: data?.fileSize || 0,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    Kind: data?.kind || '',
    DocumentNumbers: data?.documentNumbers || '',
    SortOrder: data?.sortOrder || 0,
    MergedToFileId: data?.mergedToFileId || '',
  };
}

function parse(data?: Partial<File>): File {
  return {
    id: data?.id || '',
    fileId: data?.fileId || '',
    organisationId: data?.organisationId || '',
    previewFileId: data?.previewFileId || '',
    pageCount: data?.pageCount || 0,
    filename: data?.filename || '',
    shortFilename: data?.shortFilename || '',
    ftpBackupDone: data?.ftpBackupDone || false,
    mimeType: data?.mimeType || '',
    md5: data?.md5 || '',
    fileSize: data?.fileSize || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    kind: data?.kind || '',
    documentNumbers: data?.documentNumbers || '',
    sortOrder: data?.sortOrder || 0,
    mergedToFileId: data?.mergedToFileId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
