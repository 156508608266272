import { ActionTree } from 'vuex';
import { OrganisationDocumentPropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationDocumentProperty } from '@/shared/backend/organisationDocumentProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationDocumentProperty, {
  Value,
  OrganisationDocumentProperty,
} from '@/shared/model/organisationDocumentProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationDocumentPropertys');
export const actions: ActionTree<OrganisationDocumentPropertyState, RootState> = {
  getOrganisationDocumentPropertys({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setOrganisationDocumentPropertysIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationDocumentPropertysSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationDocumentProperty
      .getOrganisationDocumentPropertys(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationDocumentPropertys', response.data);
        commit('setOrganisationDocumentPropertysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationDocumentPropertysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationDocumentProperty({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentProperty
      .getOrganisationDocumentProperty(id)
      .then((response: AxiosResponse<OrganisationDocumentProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationDocumentProperty({ commit, dispatch }, file: OrganisationDocumentProperty) {
    return defaultBackendOrganisationDocumentProperty
      .updateOrganisationDocumentProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_document_property_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteOrganisationDocumentProperty({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentProperty
      .deleteOrganisationDocumentProperty(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentProperty;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  moveOrganisationDocumentProperty(
    { commit, dispatch },
    payload: { element: OrganisationDocumentProperty; newIndex: number; oldIndex: number }
  ) {
    return defaultBackendOrganisationDocumentProperty
      .moveOrganisationDocumentProperty(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_document_property_order_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
