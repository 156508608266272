export interface AllocationOffice {
  id: string; // ulong
  organisationId: string; // string
  officeNumber: string; // string
  name: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  sortOrder: number; // int
  data: { allowedBrands: string[] };
}
export interface AllocationOfficeGroupByAllowedBrands {
  allocationOfficeData: { allowedBrands: string[] };
  allocationOfficesIds: string[];
}

export interface Value {
  value: AllocationOffice[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
  '@odata.nextLink'?: string | undefined;
}

// init api object
function toAPI(data: Partial<AllocationOffice>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    OfficeNumber: data?.officeNumber || '',
    Name: data?.name || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    SortOrder: data?.sortOrder || 0,
  };
}
function toAPIAllocationOfficeGroupByAllowedBrands(data: Partial<AllocationOfficeGroupByAllowedBrands>): any {
  return {
    AllocationOfficesIds: data?.allocationOfficesIds ?? [],
    AllocationOfficeData: data?.allocationOfficeData ?? [{ allowedBrands: [] }],
  };
}

function parseAllocationOfficeGroupByAllowedBrands(
  data?: Partial<AllocationOfficeGroupByAllowedBrands>
): AllocationOfficeGroupByAllowedBrands {
  return {
    allocationOfficesIds: data?.allocationOfficesIds ?? [],
    allocationOfficeData: data?.allocationOfficeData ?? { allowedBrands: [] },
  };
}
function parse(data?: Partial<AllocationOffice>): AllocationOffice {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    officeNumber: data?.officeNumber || '',
    name: data?.name || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    sortOrder: data?.sortOrder || 0,
    data: data?.data ?? { allowedBrands: [] },
  };
}

export default {
  parse,
  parseAllocationOfficeGroupByAllowedBrands,
  toAPI: toAPI,
  toAPIAllocationOfficeGroupByAllowedBrands,
};
