import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Logger } from 'fsts';
import { FolderRule } from '@/shared/model/folderRule';
import { Role } from '@/shared/model/role';
import { Employee } from '@/shared/model/employee';
import { OdataItems } from '@/shared/model/OdataItems';

const name = 'action-send-email';
const logger = new Logger(name);
const roleModule = namespace('role');
const employeeModule = namespace('employee');
const userModule = namespace('user');
const organizationModule = namespace('organization');

@Component({
  name: name,
  components: {},
})
export default class ActionSendEmail extends Vue {
  @roleModule.Action('getRoles')
  private getRoles!: any;
  @organizationModule.Action('getOrganisationUsers')
  private getEmployees!: any;
  @organizationModule.Getter('getOrganisationUsers')
  private employees!: OdataItems<Employee>;

  @Prop({ default: true, type: Boolean })
  private showEmailInputs!: boolean;
  @PropSync('selectedEmployees', { type: [], default: () => [] })
  private employeesSelected!: Employee[];
  @PropSync('value', { default: () => {} })
  private model!: FolderRule;
  @Watch('model', { deep: true })
  onValueUpdated(newV: any) {
    this.setSelectedRolesAndEmployees();
  }

  get employeesHeaders() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('email'), value: 'email' },
      { text: this.$t('first_name'), value: 'firstName' },
      { text: this.$t('last_name'), value: 'lastName' },
      { text: this.$t('role_name'), value: 'roleName' },
    ];
    return headers;
  }
  @PropSync('selectedRoles', { type: [], default: () => [] })
  private rolesSelected!: Role[];
  @roleModule.Getter('getRoles')
  private roles!: OdataItems<Role>;
  get roleHeaders() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [{ text: this.$t('role_name'), value: 'name' }];
    return headers;
  }

  @roleModule.Action('getRoles')
  private actionGetRoles!: any;

  get variables() {
    return [
      { name: '{{Benutzername}}', tooltip: 'benutzername' },
      { name: '{{Vorname}}', tooltip: 'vorname' },
      { name: '{{Nachname}}', tooltip: 'nachname' },
      { name: '{{Ordner}}', tooltip: 'ordner' },
      { name: '{{Dokument-ID}}', tooltip: 'document_id' },
      { name: '{{Tage}}', tooltip: 'tage' },
    ];
  }
  rolesOnInput(items: Role[]) {
    // (ED-895) avoid `Cannot set properties of undefined (setting 'actionRoles')` when open `document-qa-dialog`
    if (this.model) {
      this.model.actionRoles = items.map((x) => x.id);
    }
  }
  employeesOnInput(items: Employee[]) {
    // (ED-895) avoid `Cannot set properties of undefined (setting 'actionUsers')` when open `document-qa-dialog`
    if (this.model) {
      this.model.actionUsers = items.map((x) => x.userId);
    }
  }

  async mounted() {
    await this.getRoles({ searchParams: this.roles.searchParams });
    await this.getEmployees({ searchParams: this.employees.searchParams });
    this.setSelectedRolesAndEmployees();
  }

  private setSelectedRolesAndEmployees() {
    // (ED-895) `this.model` is UNDEFINED when open `document-qa-dialog` so need IF to avoid `Cannot read properties of undefined (reading 'actionRoles/actionUsers')` error
    if (this.model) {
      //this.rolesSelected = this.roles.items.filter((x) => this.model.actionRoles.filter((y) => y == x.id).length > 0);
      //this.employeesSelected = this.employees.items.filter(
      //  (x) => this.model.actionUsers.filter((y) => y == x.userId).length > 0
      //);
      Object.assign(this.rolesSelected, []);
      this.model.actionRoles.forEach((x) => {
        var findInRoles = this.roles.items.find((y: any) => y.id == x);
        if (findInRoles) if (!this.rolesSelected.includes(findInRoles)) this.rolesSelected.push(findInRoles!);
      });

      Object.assign(this.employeesSelected, []);
      this.model.actionUsers.forEach((x) => {
        var findInEmployees = this.employees.items.find((y: any) => y.userId == x);
        if (findInEmployees)
          if (!this.employeesSelected.includes(findInEmployees)) this.employeesSelected.push(findInEmployees!);
      });
    }
  }

  //#region (ED-895) logic for search in `Roles` and `Users`(employees) tables
  // need these 2 boolean variables to avoid extra queries for backend (ie if user press Enter 10 times for empty search input in table 0 requests will be send, before without these 2 boolean variables 10 requests would be send )

  @Watch('roles.searchParams.filter')
  public onOptionsRolesFilterChanged(newVal: any, oldVal: any) {
    this.onInputSearchRoleEx(newVal);
  }

  @Watch('employees.searchParams.filter')
  public onOptionsEmployeesFilterChanged2(newVal: any, oldVal: any) {
    this.onInputSearchEmployeeEx(newVal);
  }

  private isLastRoleRequestWithSearch = false;
  private isLastUserRequestWithSearch = false;

  async onInputSearchRole(event: any) {
    const searchTerm = event.target.value;
    this.onInputSearchRoleEx(searchTerm);
  }

  async onInputSearchRoleEx(searchTerm: string) {
    if (searchTerm && searchTerm.length >= 1) {
      await this.actionGetRoles({ searchParams: this.roles.searchParams })
        .then((result: any) => {
          this.isLastRoleRequestWithSearch = true;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    } else if (this.isLastRoleRequestWithSearch) {
      await this.actionGetRoles({ searchParams: this.roles.searchParams })
        .then(() => {
          this.isLastRoleRequestWithSearch = false;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  async onInputSearchEmployee(event: any) {
    const searchTerm = event.target.value;
    this.onInputSearchEmployeeEx(searchTerm);
  }

  async onInputSearchEmployeeEx(searchTerm: string) {
    if (searchTerm && searchTerm.length >= 1) {
      await this.getEmployees({ searchParams: this.employees.searchParams })
        .then((result: any) => {
          this.isLastUserRequestWithSearch = true;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    } else if (this.isLastUserRequestWithSearch) {
      await this.getEmployees({ searchParams: this.employees.searchParams })
        .then(() => {
          this.isLastUserRequestWithSearch = false;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }
  //#endregion
}
