import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentState, { DocumentState, Value } from '../model/documentState';
import ODataFilterBuilder from 'odata-filter-builder';
import { DocumentStateDto } from '../model/smallPayloadModels/documentStateDto';

const logger = new Logger('backend.DocumentState');
export interface BackendDocumentState {
  getDocumentState(id: string): AxiosPromise<DocumentState>;
  getDocumentStates: (searchParams: SearchParams, documentId: string, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentState(id: string, organisationId: string): AxiosPromise;
  updateDocumentState(DocumentState: DocumentState): AxiosPromise<any>;
  addDeleteDocumentStateMultiple(data: DocumentStateDto): AxiosPromise<any>;
}

export const defaultBackendDocumentState: BackendDocumentState = {
  getDocumentState(id: string): AxiosPromise<DocumentState> {
    const url = `${URLS.documentStateOdata}/${id}`;
    return instance.get<DocumentState>(url);
  },
  getDocumentStates(searchParams: SearchParams, documentId: string, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    // TODO: (ED-294) Uncomment when OrganisationId would be setup on the backend (read from `_userAccessor` ) OR just set `organisationId` here on the frontend from Vuex state
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    odfb.eq('documentId', documentId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentStateOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentStates${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentState(id: string, organisationId: string): AxiosPromise {
    logger.debug('deleteDocumentState');
    return instance.delete(`${URLS.documentState}/${id}/${organisationId}`);
  },
  updateDocumentState(DocumentState: DocumentState): AxiosPromise<any> {
    logger.debug('updateDocumentState');
    return instance.put<DocumentState>(`${URLS.documentState}/update`, documentState.toAPI(DocumentState));
  },
  addDeleteDocumentStateMultiple(data: DocumentStateDto): AxiosPromise<any> {
    logger.debug('addDeleteDocumentStateMultiple');
    return instance.put<DocumentState>(`${URLS.documentState}/AddDeleteMultiple`, data);
  },
};
