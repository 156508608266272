import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import costObjective, { CostObjective, Value } from '../model/costObjective';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.CostObjective');
export interface BackendCostObjective {
  getCostObjective(id: string): AxiosPromise<CostObjective>;
  getCostObjectives: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteCostObjective(id: string): AxiosPromise;
  updateCostObjective(CostObjective: CostObjective): AxiosPromise<any>;
  uploadCostObjective(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendCostObjective: BackendCostObjective = {
  getCostObjective(id: string): AxiosPromise<CostObjective> {
    const url = `${URLS.costObjectiveOdata}/${id}`;
    return instance.get<CostObjective>(url);
  },
  getCostObjectives(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.costObjectiveOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getCostObjectives${url}`);
    return instance.get<Value>(url);
  },

  deleteCostObjective(id: string): AxiosPromise {
    logger.debug('deleteCostObjective');
    return instance.delete(`${URLS.costObjective}/${id}`);
  },
  updateCostObjective(CostObjective: CostObjective): AxiosPromise<any> {
    logger.debug('updateCostObjective');
    return instance.put<CostObjective>(`${URLS.costObjective}/update`, costObjective.toAPI(CostObjective));
  },
  uploadCostObjective(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.costObjective}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
