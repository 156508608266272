import { datevSetting } from './../../../../../../shared/store/modules/datevSetting/index';
import { isEmpty } from './../../../../../../shared/utils/deep-object-utils';
import Vue from 'vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
// C:\dev\work\ed\easy-docs-frontend\src\views\settings\company\folder\workflow-rules\edit-workflow-rule\action-send-email\action-send-email.html
import ActionSendEmail from '@/views/settings/company/folder/workflow-rules/edit-folder-rule/action-send-email/action-send-email.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import DateUtils from '@/shared/utils/DateUtils';
import { RequestType, ThreadType } from '../thread-request-qa-types';
import RouterUtils from '@/shared/utils/RouterUtils';
import actionLog, { ActionLog } from '@/shared/model/actionLog';
import SelectedEmployeesTable from './selected-employees-table/selected-employees-table.vue';
import { EmailData, EmailType } from '@/shared/model/smallPayloadModels/emailData';
import { Document } from '@/shared/model/document';
import DateTimePicker from '@/components/date-time-picker/date-time-picker.vue';

const logger = new Logger('document-qa-dialog');
const roleModule = namespace('role');
const userModule = namespace('user');
const authModule = namespace('auth');
const actionLogModule = namespace('actionLog');
const documentModule = namespace('document');

@Component({
  name: 'document-qa-dialog',
  components: { ActionSendEmail, SelectedEmployeesTable, DateTimePicker },
})
export default class DocumentQaDialog extends Vue {
  @Ref('groupsEmployees')
  private groupsEmployeesRef!: any;
  @Ref('document-qa-form')
  private refDocumentQaForm!: any;

  @roleModule.Action('getRoles')
  private actionGetRoles!: any;
  @userModule.Action('getUsers')
  private actionGetUsers!: any;

  @actionLogModule.Action('getThreadMessages')
  private getThreadMessages!: any;
  @actionLogModule.Getter('getThreadMessages')
  private threadMessages!: any;
  @actionLogModule.Action('getActionLogsQa')
  private getActionLogsQa!: any;
  @actionLogModule.Getter('getActionLogsQa')
  private getterActionLogsQa!: any;
  @actionLogModule.Action('updateActionLog')
  private updateActionLog!: any;
  @actionLogModule.Action('closeThread')
  private closeThread!: any;
  @actionLogModule.Action('markAsReadNotification')
  private markAsReadNotification!: any;

  @authModule.Getter('getAccount')
  private getAccount!: any;
  @authModule.Getter('CloseAllDocumentThreadsAllowed')
  private CloseAllDocumentThreadsAllowed!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: '' })
  private startMessageId!: string;

  // @Prop({ default: () => workflowRule.parse({}) }) from `edit-workflow-rule`
  @PropSync('value', { default: () => {} })
  private model!: Document;

  private isQaCreated = false;

  get qaMessages() {
    return this.threadMessages.items;
  }

  get canCloseTheme() {
    return (this.amIAuthorOfFirstMessage || this.CloseAllDocumentThreadsAllowed) && this.isQaClosed != true;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  get firstMessage() {
    const result =
      this.qaMessages && this.qaMessages.length > 0 ? this.qaMessages[this.qaMessages.length - 1]?.data?.subject : '';

    return result;
  }

  get firstMessageDueDate() {
    const result =
      this.qaMessages && this.qaMessages.length > 0 ? this.qaMessages[this.qaMessages.length - 1]?.data?.dueDate : '';

    return result;
  }

  get isExpiredfirstMessageDueDate() {
    const todayDateIso = new Date().toISOString();
    return new Date(this.firstMessageDueDate) < new Date(todayDateIso);
  }

  get firstMessageDueDateFormatted() {
    return DateUtils.isoDateToScreenDateWithLocale(this.firstMessageDueDate, this.$i18n.locale);
  }

  get hasCreatedQa() {
    return this.isQaCreated || !!this.startMessageId;
  }

  get isQaClosed() {
    // QA are filtered in `setMessages` or `filterGetActionResult` method
    return this.topicData.closed;
  }

  get getStartMessageId() {
    return this.startMessageId || this.getStartMessage()?.actionLogId;
  }
  @Watch('dialog', { immediate: true, deep: true })
  async onValueChanged(value: any, _oldv: any) {
    if (value) {
      await this.getDocument(this.documentId);
      this.selectFirstEnabledType();
      await this.setMessages();
    }
  }
  //#region Date-field logic
  @Ref('menu')
  private refMenu: any;
  menu = false;
  date = '';

  get computedDateFormatted(): string {
    //https://www.w3schools.com/jsref/jsref_tolocalestring.asp
    const date = new Date(this.date);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) return '';
    const enDate = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const enTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const deDate = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const deTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    if (this.$i18n.locale.toLowerCase() === 'de' && this.date) {
      return deDate + ' ' + deTime; //return DateUtils.isoDateToDotDate(this.date);
    }
    return enDate + ' ' + enTime; //return this.date;
  }

  saveDate(date: any) {
    this.date = date;
    this.topicData.dueDate = date; // save ISO date (YYYY-MM-DD) in the database

    this.refMenu.save(date); // without this line `v-menu` clears input with `update:return-value` event
    this.menu = false;
  }

  //#endregion

  //#region (ED-942) Validation logic for message and type
  private isFormValid = true;

  private isCloseRequest = false;
  get qaMessageRules() {
    // (ED-898 disable message validation for `closeRequest` (`close_thread`)
    return [(v: string) => !!v.trim() || this.isCloseRequest || this.$i18n.t('messages.qa_message_required')]; // i18n.tc(`qa_message_required`) is not working here, just shows `qa_message_required` key
  }
  get isEmptyMessage() {
    return !(this.topicData.message.trim().length === 0); // negate expression for `hide-details` prop (if `hide-details` is TRUE then `error message` is hidden)
  }

  get qaTypeRules() {
    return [
      (v: any) => {
        return !!v || this.$i18n.t('messages.qa_thread_type_required'); // i18n.tc(`qa_thread_type_required`) is not working here, just shows `qa_thread_type_required` key
      },
    ];
  }
  //#endregion

  // use the logic (disable select items) from https://neu.easy-docs.de/
  get notificationTypes() {
    return [
      { name: 'Frage', type: ThreadType.question, disabled: this.hasOneOpenQuestion },
      { name: 'Aufgabe', type: ThreadType.task, disabled: this.hasOneOpenTask },
      { name: 'Rundschreiben', type: ThreadType.circular, disabled: this.hasOneOpenCircular },
    ];
  } // Question, Task, circular

  //#region (ED-932) Disable type if 1 such open type (Question, Task, Circular) already exist
  get notificationTypesEnabled() {
    return this.notificationTypes.filter((x: any) => !x.disabled);
  }

  get isThreadCircular() {
    return this.getStartMessage().threadType == ThreadType.circular;
  }
  get iInRecipients() {
    return (
      this.getStartMessage().data.memberIds.includes(this.getAccount.profile?.sub) ||
      this.getStartMessage().data.roleIds.includes(this.getAccount.profile?.role_id)
    );
  }
  get isDisabledMarkAsRead() {
    if (this.getStartMessage().read || this.isQaClosed) return true;
    return false;
  }
  get hasOneOpenQuestion() {
    return this.checkQuestionType(ThreadType.question);
  }
  get hasOneOpenTask() {
    return this.checkQuestionType(ThreadType.task);
  }
  get hasOneOpenCircular() {
    return this.checkQuestionType(ThreadType.circular);
  }

  @documentModule.Action('getDocument')
  private getDocument!: (id: string) => {};
  @documentModule.Getter('getDocument')
  private document!: Document;
  private checkQuestionType(threadType: ThreadType) {
    return this.document.threadsAllowed.newThreadsAllowed && !this.document.threadsAllowed[threadType];
  }

  selectFirstEnabledType() {
    this.topicData.notificationType = this.notificationTypesEnabled[0]?.type;
  }
  //#endregion

  private topicData = {
    actionLogInfos: '',
    subject: '',
    message: '',
    isEmailNotify: false,
    notificationType: ThreadType.question,
    dueDate: '',
    closed: false,
    memberIds: [],
    members: [],
    roleIds: [],
    roles: [],
  };

  async created() {
    // this.selectFirstEnabledType();
    // await this.setMessages();
  }
  fillCurrentThreadToTopicData(startMessageId: string) {
    const currentThread = this.qaMessages.filter((x: ActionLog) => x.actionLogId == startMessageId)[0];
    this.topicData.closed = currentThread?.closed;
  }

  async setMessages() {
    if (!this.startMessageId) return;
    this.getThreadMessages(this.startMessageId).then(() => {
      this.fillCurrentThreadToTopicData(this.startMessageId);
    });
    // if (!this.qaMessages || this.qaMessages?.length == 0) {
    const searchData: any = { documentId: this.model.id, isSkipUpdateState: this.isSkipStateUpdate() };
    await this.getActionLogsQa({ searchData })
      .then((result: any) => {
        this.isQaCreated = true;
        this.model.actionLogs = this.qaMessages;
      })
      .catch((err: any) => {
        logger.error(err);
      });
    // }
  }
  @actionLogModule.Action('getActionLogsQaOverview')
  private getActionLogsQaOverview!: any;

  async mounted() {
    // this.selectFirstEnabledType();
    // await this.setMessages();

    // await this.actionGetRoles(); // (ED-895) load real roles from Backend because now (2022-02-17) 2 roles from State (`src\shared\store\modules\role\state.ts`) are used
    // use empty `searchParams` because otherwise get error `ApplicationUser doesn't have OrganizationId field`
    await this.actionGetUsers({ searchParams: {} }); // (ED-895) load real users from Backend because now (2022-02-17) 4 users from state (`src\shared\store\modules\user\state.ts`) are used
    if (this.$route.params['themeId']) this.dialog = true;
  }

  private openNotificationsModal() {
    logger.log('openNotificationsModal');
    this.$emit('show:qa-overview-dialog');
  }

  private startRequest = RequestType.start;
  private commentRequest = RequestType.comment;
  private closeRequest = RequestType.close;

  private sendCloseThread() {
    this.isCloseRequest = true;
    this.closeThread(this.getStartMessage()?.actionLogId).then((resultCreate: any) => {
      this.topicData.closed = true;
      this.$emit('qa-was-closed', this.model.id);
    });
  }
  selectedRoles = [];
  selectedEmployees = [];
  private async sendThreadComment(requestType: RequestType) {
    this.isFormValid = await this.refDocumentQaForm.validate();

    if (this.isFormValid) {
      if (!this.hasCreatedQa && this.selectedRoles.length + this.selectedEmployees.length < 1) {
        const response = await this.$confirm.open(
          `${this.$t('empty_roles_and_employees.message_title')}`,
          `${this.$t('empty_roles_and_employees.message')}`,
          {
            cancelColor: 'error',
            okColor: 'grey',
            cancelText: this.$t('empty_roles_and_employees.btn_edit'),
            okText: this.$t('empty_roles_and_employees.btn_send'),
          }
        );
        if (!response) return;
      }
      logger.log('sendThreadComment requestType :>> ', requestType);
      const payload: any = this.createPayload(requestType);
      const searchData: any = { documentId: this.model.id };
      payload.data.notificationType = payload.threadType;
      await this.updateActionLog(payload)
        .then((resultCreate: any) => {
          this.model.actionLogs?.unshift(resultCreate.result);
          if (requestType == RequestType.start) this.startMessageId = resultCreate.id;
          this.setMessages();
          this.getActionLogsQa({ searchData })
            .then((result: any) => {
              this.isQaCreated = true;
              this.topicData.message = '';
              this.resetValidation();
              this.$emit('qa-was-created', this.model.id);
            })
            .catch((err: any) => {
              logger.error(err);
            });
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }
  private async markAsRead() {
    this.markAsReadNotification(this.model.id);
    await this.setMessages();
    const searchData: any = { documentId: this.model.id, isSkipUpdateState: this.isSkipStateUpdate() };
    await this.getActionLogsQa({ searchData })
      .then((result: any) => {
        this.isQaCreated = true;
        this.model.actionLogs = this.qaMessages;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  // (ED-363) avoid updating `setActionLogsQa` when in `qa-dialog` change `Document` different from its `documentId` in URL  (difference because of `qa-overview-dialog` has ALL QAs)
  private isSkipStateUpdate() {
    return this.documentId !== this.model.id;
  }

  private createPayload(requestType: RequestType) {
    const payload: any = {}; // (ED-892) if use `actLog.parse({})` get NULL in the `command` in the Controller `Update` action (since backend and frontend `ActionLog` models too different)
    payload.isNotifyByEmail = this.isNotifyByEmail;
    this.topicData.actionLogInfos = `${
      this.getAccount?.profile?.name || 'user'
    } - ${DateUtils.dotDateTimeFromIsoDateTime(new Date().toISOString())} Uhr`;
    this.topicData.subject = this.topicData.message.slice(0, 30); // take 30 characters of message (copy implementation of `https://neu.easy-docs.de`)
    payload.documentId = this.model.id == '' ? this.documentId : this.model.id;
    payload.organisationId = this.model.organisationId;
    payload.type = requestType;
    if (requestType !== RequestType.start) {
      payload.threadId = this.getStartMessageId;
      payload.threadType = this.getStartMessage().threadType;
    } else {
      payload.threadType = this.topicData.notificationType;
    }
    payload.userId = this.getAccount.profile?.sub || RouterUtils.emptyGuid;
    payload.dueTimeStamp = this.topicData.dueDate;

    if (this.groupsEmployeesRef?.employeesSelected) {
      this.topicData.memberIds = this.groupsEmployeesRef.employeesSelected.map((x: any) => x.userId);
      this.topicData.members = this.groupsEmployeesRef.employeesSelected.map(
        (x: any) => `${x.firstName} ${x.lastName}`
      );
    }
    if (this.groupsEmployeesRef?.rolesSelected) {
      this.topicData.roleIds = this.groupsEmployeesRef.rolesSelected.map((x: any) => x.id);
      this.topicData.roles = this.groupsEmployeesRef.rolesSelected.map((x: any) => x.name);
    }

    if (requestType === RequestType.close) {
      payload.closed = true;
      this.addCloseMessageIfEmpty();
    }
    //payload.DueTimeStamp = DateUtils.dotDateTimeFromIsoDateTime(payload.DueTimeStamp);
    logger.log('createPayload payload :>> ', payload);
    payload.data = this.topicData;
    return payload;
  }

  private addCloseMessageIfEmpty() {
    if (!this.topicData.message.trim()) {
      this.topicData.message = `${
        this.notificationTypes.find((x: any) => x.type === this.getStartMessage().threadType)?.name
      } wurde als erledigt markiert.`;
    }
  }

  get amIAuthorOfFirstMessage() {
    const message = this.getStartMessage();
    if (message.userId == this.getAccount.profile.sub) return true;
    return false;
  }

  getStartMessage(): ActionLog {
    return (
      this.qaMessages?.find((x: ActionLog) => x.type == RequestType.start && x.documentId == this.model.id) ??
      actionLog.parse({})
    );
  }

  getMessagesChain(): ActionLog[] {
    return (
      this.qaMessages?.filter((x: ActionLog) => x.documentId == this.model.id && !x.closed) ?? [actionLog.parse({})]
    );
  }

  resetValidation() {
    this.refDocumentQaForm.resetValidation();
  }

  clickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  goToDocument() {
    logger.log('goToDocument');
  }

  // #region ED-896 Send QA email logic
  private isNotifyByEmail = true;
  // #endregion
}
