import { ActionTree } from 'vuex';
import { OrganisationAddressState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationAddress } from '@/shared/backend/organisationAddress';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationAddress, { Value, OrganisationAddress } from '@/shared/model/organisationAddress';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationAddresss');
export const actions: ActionTree<OrganisationAddressState, RootState> = {
  getOrganisationAddresses({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setOrganisationAddressesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationAddressesSearchParams;
    return defaultBackendOrganisationAddress
      .getOrganisationAddresses(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationAddresses', response.data);
        commit('setOrganisationAddressesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationAddressesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationAddress({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationAddress
      .getOrganisationAddress(id)
      .then((response: AxiosResponse<OrganisationAddress>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCurrent({ commit, dispatch, getters }, payload: string) {
    const searchParams = getters.getOrganisationAddressesSearchParams;
    return defaultBackendOrganisationAddress
      .getOrganisationAddresses(searchParams)
      .then((response: AxiosResponse<Value>) => {
        const current = organisationAddress.parse(response.data.value[0]);
        commit('setCurrent', current);
        return current;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationAddress({ commit, dispatch }, file: OrganisationAddress) {
    return defaultBackendOrganisationAddress
      .updateOrganisationAddress(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAddress;
          }>
        ) => {
          // module_utils.ok(i18n.tc(`success.organisationAddress_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteOrganisationAddress({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationAddress
      .deleteOrganisationAddress(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAddress;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
