export interface OrganisationDocumentSetting {
  id: string; // Guid
  organisationId: string; // Guid
  detectDuplicates: false; // bool?
  detectDoubleBookings: false; // bool?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  pagesTextSearchType: string; // string
}

export interface Value {
  value: OrganisationDocumentSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<OrganisationDocumentSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    DetectDuplicates: data?.detectDuplicates || false,
    DetectDoubleBookings: data?.detectDoubleBookings || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    PagesTextSearchType: data?.pagesTextSearchType || '',
  };
}

function parse(data?: Partial<OrganisationDocumentSetting>): OrganisationDocumentSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    detectDuplicates: data?.detectDuplicates || false,
    detectDoubleBookings: data?.detectDoubleBookings || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    pagesTextSearchType: data?.pagesTextSearchType || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
