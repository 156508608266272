export interface AllocationsExport {
  id: string; // System.Guid
  allocationsExportId: string; // System.Guid
  allocationsExportFileId: string; // System.Guid
  allocationsExportFileName: string; // string
  organisationId: string; // System.Guid
  monthTimeStamp: string; // System.DateTime?
  fromTimeStamp: string; // System.DateTime?
  toTimeStamp: string; // System.DateTime?
  deleted: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  downloaded: string; // System.DateTime?
  officeName: string;
}

export interface Value {
  value: AllocationsExport[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationsExport>): any {
  return {
    Id: data?.id || undefined,
    AllocationsExportId: data?.allocationsExportId || undefined,
    AllocationsExportFileId: data?.allocationsExportFileId || undefined,
    AllocationsExportFileName: data?.allocationsExportFileName || '',
    OrganisationId: data?.organisationId || undefined,
    MonthTimeStamp: data?.monthTimeStamp || '',
    FromTimeStamp: data?.fromTimeStamp || '',
    ToTimeStamp: data?.toTimeStamp || '',
    Deleted: data?.deleted || false,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    Downloaded: data?.downloaded || '',
    OfficeName: data?.officeName || '',
  };
}

function parse(data?: Partial<AllocationsExport>): AllocationsExport {
  return {
    id: data?.id || '',
    allocationsExportId: data?.allocationsExportId || '',
    allocationsExportFileId: data?.allocationsExportFileId || '',
    allocationsExportFileName: data?.allocationsExportFileName || '',
    organisationId: data?.organisationId || '',
    monthTimeStamp: data?.monthTimeStamp || '',
    fromTimeStamp: data?.fromTimeStamp || '',
    toTimeStamp: data?.toTimeStamp || '',
    deleted: data?.deleted || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    downloaded: data?.downloaded || '',
    officeName: data?.officeName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
