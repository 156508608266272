import { render, staticRenderFns } from "./modal-idle.html?vue&type=template&id=42813f78&scoped=true&external"
import script from "./modal-idle.ts?vue&type=script&lang=ts&external"
export * from "./modal-idle.ts?vue&type=script&lang=ts&external"
import style0 from "./modal-idle.scss?vue&type=style&index=0&id=42813f78&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42813f78",
  null
  
)

/* custom blocks */
import block0 from "./modal-idle.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdialogs%2Fmodal-idle%2Fmodal-idle.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog})
