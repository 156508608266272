import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const documentModule = namespace('document');

@Component
export default class DocumentsMergeDialog extends Vue {
  @documentModule.Action('merge')
  private actionMerge!: any;

  @documentModule.Action('getDocumentFoldersCounts')
  private actionGetDocumentFoldersCounts!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: [] })
  private model!: any;

  selectedDocument = {};

  get documents() {
    return this.model;
  }

  private createNewDocument: boolean = false;

  clickClose() {
    this.$emit('click:close');
  }

  get isTargetDocumentSelected() {
    const result = JSON.parse(JSON.stringify(this.selectedDocument)).length;
    return result == undefined;
  }

  get hasAccountings() {
    let result = false;

    if (this.documents instanceof Array) {
      this.documents.forEach((element: any) => {
        if (element.haveAllocations) result = true;
      });
    }

    return result;
  }

  merge() {
    const sourceIds: any = [];
    this.documents.forEach((element: any) => {
      sourceIds.push(element.id);
    });
    this.actionMerge({
      sourceIds: sourceIds,
      destinationId: this.selectedDocument,
      asNewDocument: this.createNewDocument,
    }).then(() => {
      this.actionGetDocuments().then(() => {
        this.actionGetDocumentFoldersCounts().then(() => {
          this.$router.push('/dashboard/organization');
          this.$emit('click:merge');
        });
      });
    });
  }
}
