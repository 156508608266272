import { MutationTree } from 'vuex';
import { FolderState } from './types';
import folder, { Value, Folder } from '@/shared/model/folder';
import TreeUtils from '@/shared/utils/treeUtils';

export const mutations: MutationTree<FolderState> = {
  setFolder(state, payload?: Folder) {
    state.folder = payload;
  },
  resetFolder(state, payload?: Folder) {
    state.folder = Object.assign({}, payload);
  },
  setFolders(state, payload: Value) {
    state.folders.items = payload.value.map((x) => folder.parse(x));
    state.folders.total = payload['@odata.count'] || 0;
    state.folders.isLoading = false;
  },
  setUploadDialogFolders(state, payload: Value) {
    state.uploadDialogFolders.items = payload.value.map((x) => folder.parse(x));
    state.uploadDialogFolders.total = payload['@odata.count'] || 0;
    state.uploadDialogFolders.isLoading = false;
  },
  setFoldersTotal(state, payload: number) {
    state.folders.total = payload;
  },
  setFoldersIsLoading(state, payload: boolean) {
    state.folders.isLoading = payload;
  },

  setFoldersAll(state, payload: Value) {
    state.foldersAll.items = payload.value.map((x) => folder.parse(x));
    state.foldersAll.total = payload['@odata.count'] || 0;
    state.foldersAll.isLoading = false;
    const allFolders = state.foldersAll.items.map((i) => ({ ...i }));
    state.foldersAll.treeView = Object.assign([], TreeUtils.FoldersToTreeView(allFolders));
    state.foldersAll.treeViewWithoutHidden = Object.assign([], TreeUtils.FoldersToTreeViewWithoutHidden(allFolders));
    state.foldersAll.treeViewWrite = Object.assign([], TreeUtils.FoldersToTreeViewWrite(allFolders));
    state.foldersAll.treeViewFlat = Object.assign([], TreeUtils.FoldersToTreeViewFlat(allFolders));
  },
  setFoldersAllTotal(state, payload: number) {
    state.foldersAll.total = payload;
  },
  setFoldersAllIsLoading(state, payload: boolean) {
    state.foldersAll.isLoading = payload;
  },

  setMenuFolders(state, payload: { folders: Value; isOwner: boolean }) {
    state.menuFolders.items = payload.folders.value.map((x) => folder.parse(x));
    state.menuFolders.total = payload.folders['@odata.count'] || 0;
    state.menuFolders.isLoading = false;
    const allFolders = state.menuFolders.items.map((i) => ({ ...i }));

    state.menuFolders.treeView = Object.assign([], TreeUtils.FoldersToTreeView(allFolders));
    state.menuFolders.treeViewWithoutHidden = Object.assign(
      [],
      payload.isOwner ? TreeUtils.FoldersToTreeView(allFolders) : TreeUtils.FoldersToTreeViewWithoutHidden(allFolders)
    );
    state.menuFolders.treeViewWithoutHiddenFlat = TreeUtils.FoldersToTreeViewFlat(
      payload.isOwner
        ? TreeUtils.FoldersToTreeView(allFolders)
        : TreeUtils.FoldersToTreeViewWithoutRight(state.menuFolders.items, 'View')
    );
    state.menuFolders.treeViewUploadFlat = TreeUtils.FoldersToTreeViewFlat(allFolders);

    if (!payload.isOwner) {
      //appy filter to upload folders
      state.menuFolders.treeViewUploadFlat = TreeUtils.FoldersTreeViewFlatWithRight(
        state.menuFolders.treeViewUploadFlat,
        'Upload'
      );
    }
    state.menuFolders.treeViewWrite = Object.assign(
      [],
      payload.isOwner ? TreeUtils.FoldersToTreeView(allFolders) : TreeUtils.FoldersToTreeViewWrite(allFolders)
    );
    state.menuFolders.treeViewWriteFlat = TreeUtils.convertTreeToList(state.menuFolders.treeViewWrite);
    state.menuFolders.treeViewFlat = Object.assign([], TreeUtils.FoldersToTreeViewFlat(allFolders));
  },
  setMenuFoldersIsLoading(state, payload: boolean) {
    state.menuFolders.isLoading = payload;
  },
  setInProgressUpdateFoldersRoleRights(state, payload: boolean) {
    state.inProgressUpdateFoldersRoleRights = state.inProgressUpdateFoldersRoleRights + (payload ? +1 : -1);
  },
  setInProgressUpdateFolder(state, payload: boolean) {
    state.inProgressUpdateFolder = payload;
  },
};
