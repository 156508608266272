import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import roleRight, { RoleRight, Value } from '../model/roleRight';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.RoleRight');
export interface BackendRoleRight {
  getRoleRight(id: string): AxiosPromise<RoleRight>;
  getRoleRights: (searchParams: SearchParams, roleId?: string) => AxiosPromise<Value>;
  updateRoleRight(RoleRight: RoleRight): AxiosPromise<any>;
}

export const defaultBackendRoleRight: BackendRoleRight = {
  getRoleRight(id: string): AxiosPromise<RoleRight> {
    const url = `${URLS.roleRightOdata}/${id}`;
    return instance.get<RoleRight>(url);
  },
  getRoleRights(searchParams: SearchParams, roleId?: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq('RoleId', roleId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.roleRightOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      roleId ? odfb : undefined
    );
    logger.log(`getRoleRights${url}`);
    return instance.get<Value>(url);
  },
  updateRoleRight(RoleRight: RoleRight): AxiosPromise<any> {
    logger.debug('updateRoleRight');
    return instance.put<RoleRight>(`${URLS.roleRight}/update`, roleRight.toAPI(RoleRight));
  },
};
