import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationBrand, { AllocationBrand, Value } from '../model/allocationBrand';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationBrand');
export interface BackendAllocationBrand {
  getAllocationBrand(id: string): AxiosPromise<AllocationBrand>;
  getAllocationBrands: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationBrand(id: string): AxiosPromise;
  updateAllocationBrand(AllocationBrand: AllocationBrand): AxiosPromise<any>;
  uploadAllocationBrand(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
  getByUrl(url: string): AxiosPromise<Value>;
}

export const defaultBackendAllocationBrand: BackendAllocationBrand = {
  getAllocationBrand(id: string): AxiosPromise<AllocationBrand> {
    const url = `${URLS.allocationBrandOdata}/${id}`;
    return instance.get<AllocationBrand>(url);
  },
  getAllocationBrands(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationBrandOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAllocationBrands${url}`);
    return instance.get<Value>(url);
  },
  getByUrl(url: string): AxiosPromise<Value> {
    return instance.get<Value>(DefaultBackendHelper.removeUrlAppendix(url));
  },
  deleteAllocationBrand(id: string): AxiosPromise {
    logger.debug('deleteAllocationBrand');
    return instance.delete(`${URLS.allocationBrand}/${id}`);
  },
  updateAllocationBrand(AllocationBrand: AllocationBrand): AxiosPromise<any> {
    logger.debug('updateAllocationBrand');
    return instance.put<AllocationBrand>(`${URLS.allocationBrand}/update`, allocationBrand.toAPI(AllocationBrand));
  },
  uploadAllocationBrand(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.allocationBrand}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
