import { getuid } from 'process';

export interface AutoGobdCheckTerm {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  userId: string; // System.Guid
  searchTerm1: string; // string
  searchTerm2: string; // string
  searchTerm3: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  deleted: boolean; // bool
  deletedByUserId: string; // System.Guid
  allocationTemplateId: string; // System.Guid
  allocationTemplateName: string; // System.Guid
  searchTerm4: string; // string
  searchTerm5: string; // string
  priority: number; // sbyte
  supplierAccount: string; // System.Guid
  supplierAccountName: string; // System.Guid
  documentStatus: string[] | string; // string
  documentStatusName: string; // string
  typeSearch: number;
  updateExistingDocuments?: boolean;
  moveToFolderId: string;
  moveToFolderPattern: string;
  moveToFolderPatternType: string;
}

export interface Value {
  value: AutoGobdCheckTerm[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AutoGobdCheckTerm>): any {
  return {
    Id: data?.id || '00000000-0000-0000-0000-000000000000',
    OrganisationId: data?.organisationId || '00000000-0000-0000-0000-000000000000',
    UserId: data?.userId || '00000000-0000-0000-0000-000000000000',
    SearchTerm1: data?.searchTerm1 || '',
    SearchTerm2: data?.searchTerm2 || '',
    SearchTerm3: data?.searchTerm3 || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    Deleted: data?.deleted || false,
    DeletedByUserId: data?.deletedByUserId || '00000000-0000-0000-0000-000000000000',
    AllocationTemplateId: data?.allocationTemplateId || '00000000-0000-0000-0000-000000000000',
    AllocationTemplateName: data?.allocationTemplateName || '00000000-0000-0000-0000-000000000000',
    SearchTerm4: data?.searchTerm4 || '',
    SearchTerm5: data?.searchTerm5 || '',
    Priority: data?.priority || '1',
    SupplierAccount: data?.supplierAccount || '00000000-0000-0000-0000-000000000000',
    SupplierAccountName: data?.supplierAccountName || '',
    DocumentStatus: (data?.documentStatus as Array<string>).join(',') || '00000000-0000-0000-0000-000000000000',
    TypeSearch: data?.typeSearch || 1,
    UpdateExistingDocuments: data?.updateExistingDocuments,
    MoveToFolderId: data?.moveToFolderId || '00000000-0000-0000-0000-000000000000',
    MoveToFolderPattern: data?.moveToFolderPattern || '',
    MoveToFolderPatternType: data?.moveToFolderPatternType || '',
  };
}

function parse(data?: Partial<AutoGobdCheckTerm>): AutoGobdCheckTerm {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    userId: data?.userId || '',
    searchTerm1: data?.searchTerm1 || '',
    searchTerm2: data?.searchTerm2 || '',
    searchTerm3: data?.searchTerm3 || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    deleted: data?.deleted || false,
    deletedByUserId: data?.deletedByUserId || '',
    allocationTemplateId: data?.allocationTemplateId || '',
    allocationTemplateName: data?.allocationTemplateName || '',
    searchTerm4: data?.searchTerm4 || '',
    searchTerm5: data?.searchTerm5 || '',
    priority: data?.priority || 0,
    supplierAccount: data?.supplierAccount || '',
    supplierAccountName: data?.supplierAccountName || '',
    documentStatus: Array.isArray(data?.documentStatus)
      ? data?.documentStatus ?? []
      : (data?.documentStatus as string)?.split(',') || [],
    documentStatusName: data?.documentStatusName || '',
    typeSearch: data?.typeSearch || 0,

    moveToFolderId: data?.moveToFolderId || '',
    moveToFolderPattern: data?.moveToFolderPattern || '',
    moveToFolderPatternType: data?.moveToFolderPatternType || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
