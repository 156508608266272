import { ActionTree } from 'vuex';
import { DocumentStatusState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentStatus } from '@/shared/backend/documentStatus';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import ds, { Value, DocumentStatus } from '@/shared/model/documentStatus';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.documentStatus');
export const actions: ActionTree<DocumentStatusState, RootState> = {
  getDocumentStatus({ commit, dispatch }, id: string) {
    return defaultBackendDocumentStatus
      .getDocumentStatus(id)
      .then((response: AxiosResponse<DocumentStatus>) => {
        commit('setDocumentStatus', ds.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentStatuses(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; parentDocumentStatusId?: string }
  ) {
    commit('setDocumentStatusesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentStatusesSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendDocumentStatus
      .getDocumentStatuses(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentStatuses', response.data);
        commit('setDocumentStatusesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentStatusesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDocumentStatus({ commit, dispatch }, file: DocumentStatus) {
    return defaultBackendDocumentStatus
      .updateDocumentStatus(file)
      .then((response) => {
        commit('updateDocumentStatus', response.data.result);
        module_utils.ok(i18n.tc(`success.document_status_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentStatus({ commit, dispatch }, id: string) {
    return defaultBackendDocumentStatus
      .deleteDocumentStatus(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
