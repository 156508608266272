import Vue from 'vue';
import { Logger } from 'fsts';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MenuDocumentAccountEdit from '../menu-document-account-edit/menu-document-account-edit.vue';
import supplier, { Supplier } from '@/shared/model/supplier';
import i18n from '@/i18n';

const logger = new Logger('supplier');
const supplierModule = namespace('supplier');
@Component({ components: { MenuDocumentAccountEdit } })
export default class MenuDocumentAccountTable extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: '' })
  private search!: string;
  @Watch('search', { deep: true })
  onUpdateSearch(nwal: any, owal: any) {
    this.getSuppliers.searchParams.filter = this.search;
  }

  @PropSync('value', { default: '' })
  private model!: any;

  @supplierModule.Getter('getSuppliers')
  private getSuppliers?: any;
  @supplierModule.Action('getSuppliers')
  private actionGetSuppliers?: any;
  @supplierModule.Action('updateSupplier')
  private actionUpdateSupplier?: any;
  @supplierModule.Mutation('resetSuppliersCurrentPage')
  private resetSuppliersCurrentPage!: () => undefined;
  @supplierModule.Action('deleteSupplier')
  private actionDeleteSupplier?: any;
  @Watch('getSuppliers.searchParams.filter', { deep: true })
  onUpdateSearchParamsFilter(nwal: string, owal: string) {
    if (nwal != owal) this.resetSuppliersCurrentPage();
  }
  @Watch('getSuppliers.searchParams', { deep: true })
  onUpdateSearchParams(nwal: any, owal: any) {
    this.actionGetSuppliers({ searchParams: this.getSuppliers.searchParams });
  }

  get accountsItems() {
    return this.getSuppliers;
  }

  private isSaveBtnLoading = false;

  private account: any = {
    searchParams: {},
  };

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('account_number'), value: 'kontoInteger' },
      { text: this.$t('description'), value: 'bezeichnung' },
      { text: this.$t('cost_centre'), value: 'kostenstelle' },
      { text: this.$t('sales_channel'), value: 'absatzkanal' },
      { text: this.$t('payer'), value: 'kostentraeger' },
      { text: this.$t('drive_type'), value: 'antriebsart' },
      { text: this.$t('serial_number_required'), value: 'serialNumberRequired' },
      { text: this.$t('actions'), value: 'actions', sortable: false, width: '1%' },
    ];
    return headers;
  }

  clickClose() {
    this.getSuppliers.searchParams.filter = this.search;
    this.$emit('click:close');
  }
  @Watch('dialog') onShow(n: boolean, o: boolean) {
    if (n) {
      this.resetSuppliersCurrentPage();
      this.$nextTick(() => {
        setTimeout(() => {
          (this.$refs?.tfKontoFilter as any).$refs.input.focus();
        });
      });
    }
  }
  mounted() {
    this.actionGetSuppliers().then(() => {
      this.getSuppliers.searchParams.filter = this.search;
    });
  }

  accountEditDialog = {
    show: false,
    model: { ...supplier.parse({}) },
  };

  async accountEditDialogOnClose(account: Supplier) {
    this.accountEditDialog.show = false;
  }

  async accountEditDialogOnUpdate(account: Supplier) {
    this.isSaveBtnLoading = true;
    this.actionUpdateSupplier(account)
      .then((result: any) => {
        this.accountEditDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetSuppliers();
      })
      .catch((err: any) => {})
      .finally(() => {
        this.isSaveBtnLoading = false;
      });
  }

  onClickAccountAdd() {
    this.accountEditDialog.model = { ...supplier.parse({}) };
    this.accountEditDialog.show = true;
  }
  onClickAccountEdit(account: Supplier) {
    this.accountEditDialog.model = { ...account };
    this.accountEditDialog.show = true;
  }

  kontoClick(item: any) {
    this.$emit('click:select', item);
  }

  onClickDeleteEdit(account: Supplier) {
    this.$confirm
      .open(`${this.$t('delete_supplier')}`, `${this.$t('delete_confirmation')}`, {
        cancelText: this.$t('cancel'),
        okText: this.$t('ok'),
      })
      .then(async (response: any) => {
        if (response) {
          this.actionDeleteSupplier(account.id).then(() => {
            this.actionGetSuppliers();
            this.$emit('click:return');
          });
        } else {
          this.$emit('click:return');
        }
      });
  }
}
