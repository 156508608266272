import { AllocationBrand } from './allocationBrand';
import { AllocationFrameProperty } from './allocationFrameProperty';
import { AllocationOffice } from './allocationOffice';
import allocationsProperty, { AllocationsProperty } from './allocationsProperty';

export interface Allocation {
  id: string; // System.Guid
  allocationId: string; // System.Guid
  organisationId: string; // System.Guid
  userId: string; // System.Guid
  createTimeStamp: number; // int?
  createDate: string; // string
  changeTimeStamp: number; // int?
  changeDate: string; // string
  invoiceTimeStamp: number; // int?
  invoiceDate: string; // string
  exported: false; // bool?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  documentId: string; // System.Guid
  mainAllocationId: string; // System.Guid
  exportTimeStamp: number; // int
  reverseAllocationId: string; // System.Guid
  accountName: string; // string
  contraAccountName: string; // string
  ignoreAsProposal: boolean; // bool
  email: string;
  properties?: AllocationPropertyEx[];
}
export interface AllocationPropertyEx {
  allocationFieldID: string;
  key: string;
  name: string;
  type: string;
  value: string;
  sortOrder: number;
  enabled: boolean;
}

export interface AllocationFrameEx {
  allocationFrameID: string;
  properties: AllocationPropertyEx[];
  splittbuchungen: Allocation[];
}

export interface AllocationEx {
  allocationBrands: AllocationBrand[];
  allocationOffices: AllocationOffice[];
  allocationFrame: AllocationFrameEx;
}

export interface Value {
  value: Allocation[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Allocation>): any {
  return {
    Id: data?.id || undefined,
    AllocationId: data?.allocationId || undefined,
    OrganisationId: data?.organisationId || undefined,
    UserId: data?.userId || undefined,
    CreateTimeStamp: data?.createTimeStamp || 0,
    CreateDate: data?.createDate || '',
    ChangeTimeStamp: data?.changeTimeStamp || 0,
    ChangeDate: data?.changeDate || '',
    InvoiceTimeStamp: data?.invoiceTimeStamp || 0,
    InvoiceDate: data?.invoiceDate || '',
    Exported: data?.exported || false,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    DocumentId: data?.documentId || undefined,
    MainAllocationId: data?.mainAllocationId || undefined,
    ExportTimeStamp: data?.exportTimeStamp || 0,
    ReverseAllocationId: data?.reverseAllocationId || undefined,
    AccountName: data?.accountName || '',
    ContraAccountName: data?.contraAccountName || '',
    IgnoreAsProposal: data?.ignoreAsProposal || false,
    Properties: data?.properties?.map((item) => allocationsProperty.toAPI(item)),
    Email: data?.email || '',
  };
}

function parse(data?: Partial<Allocation>): Allocation {
  return {
    id: data?.id || '',
    allocationId: data?.allocationId || '',
    organisationId: data?.organisationId || '',
    userId: data?.userId || '',
    createTimeStamp: data?.createTimeStamp || 0,
    createDate: data?.createDate || '',
    changeTimeStamp: data?.changeTimeStamp || 0,
    changeDate: data?.changeDate || '',
    invoiceTimeStamp: data?.invoiceTimeStamp || 0,
    invoiceDate: data?.invoiceDate || '',
    exported: data?.exported || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    documentId: data?.documentId || '',
    mainAllocationId: data?.mainAllocationId || '',
    exportTimeStamp: data?.exportTimeStamp || 0,
    reverseAllocationId: data?.reverseAllocationId || '',
    accountName: data?.accountName || '',
    contraAccountName: data?.contraAccountName || '',
    ignoreAsProposal: data?.ignoreAsProposal || false,
    properties: data?.properties || [],
    email: data?.email || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
