import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import driveType, { DriveType, Value } from '../model/driveType';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DriveType');
export interface BackendDriveType {
  getDriveType(id: string): AxiosPromise<DriveType>;
  getDriveTypes: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDriveType(id: string): AxiosPromise;
  updateDriveType(DriveType: DriveType): AxiosPromise<any>;
  uploadDriveType(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendDriveType: BackendDriveType = {
  getDriveType(id: string): AxiosPromise<DriveType> {
    const url = `${URLS.driveTypeOdata}/${id}`;
    return instance.get<DriveType>(url);
  },
  getDriveTypes(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.driveTypeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getDriveTypes${url}`);
    return instance.get<Value>(url);
  },

  deleteDriveType(id: string): AxiosPromise {
    logger.debug('deleteDriveType');
    return instance.delete(`${URLS.driveType}/${id}`);
  },
  updateDriveType(DriveType: DriveType): AxiosPromise<any> {
    logger.debug('updateDriveType');
    return instance.put<DriveType>(`${URLS.driveType}/update`, driveType.toAPI(DriveType));
  },
  uploadDriveType(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.driveType}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
