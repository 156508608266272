export interface UserRight {
  id?: number; // ulong
  userId: string; // string
  claimType: string; // string
  claimValue: string; // string
  sName: string; // string
  name: string; // string
  allowed: boolean; // bool
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: UserRight[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<UserRight>): any {
  const x: any = {
    ClaimType: data?.claimType || '',
    ClaimValue: data?.claimValue || '',
    Name: data?.name || '',
    Allowed: data?.allowed || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
  if (data?.id) {
    x.Id = data?.id;
  }
  if (data?.userId) {
    x['UserId@odata.type'] = '#Guid';
    x.UserId = data?.userId;
  }
  return x;
}

function parse(data?: Partial<UserRight>): UserRight {
  return {
    id: data?.id || undefined,
    userId: data?.userId || '',
    claimType: data?.claimType || '',
    claimValue: data?.claimValue || '',
    sName: data?.claimType?.substring(data?.claimType?.indexOf('_', 5) + 1).replace('_View', '') || '',
    name: data?.name || '',
    allowed: data?.claimValue == 'allowed' ? true : false || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}
export enum OwnerRight {
  payed_View = 'OwnerRight_payed_View',
  final_check_View = 'OwnerRight_final_check_View',
  accepted_View = 'OwnerRight_accepted_View',
  extra1_View = 'OwnerRight_extra1_View',
  extra2_View = 'OwnerRight_extra2_View',
  extra3_View = 'OwnerRight_extra3_View',
  extra4_View = 'OwnerRight_extra4_View',
  extra5_View = 'OwnerRight_extra5_View',
  extra6_View = 'OwnerRight_extra6_View',
}

function listOwnerRights(orgaId: any, ownerId: string): UserRight[] {
  //example userRight.parse({ claimType: 'Orga_' + orgaId + '_OwnerRight_final_check_View', userId: this.owner.id }),
  return Object.keys(OwnerRight).map((x: any) =>
    parse({ claimType: `Orga_${orgaId}_${(OwnerRight as any)[x]}`, userId: ownerId })
  );
}
export default {
  parse,
  toAPI: toAPI,
  listOwnerRights: listOwnerRights,
};
