import { ActionTree } from 'vuex';
import { DocumentEditSessionState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentEditSession } from '@/shared/backend/documentEditSession';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentEditSession, { Value, DocumentEditSession } from '@/shared/model/documentEditSession';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentEditSessions');
export const actions: ActionTree<DocumentEditSessionState, RootState> = {
  getDocumentEditSessions({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDocumentEditSessionsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentEditSessionsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocumentEditSession
      .getDocumentEditSessions(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentEditSessions', response.data);
        commit('setDocumentEditSessionsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentEditSessionsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentEditSession({ commit, dispatch }, id: string) {
    return defaultBackendDocumentEditSession
      .getDocumentEditSession(id)
      .then((response: AxiosResponse<DocumentEditSession>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentEditSession({ commit, dispatch }, file: DocumentEditSession) {
    return defaultBackendDocumentEditSession
      .updateDocumentEditSession(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentEditSession;
          }>
        ) => {
          //module_utils.ok(i18n.tc(`success.documentEditSession_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        // throw e; ???? so many errrors
      });
  },
  deleteDocumentEditSession({ commit, dispatch }, id: string) {
    return defaultBackendDocumentEditSession
      .deleteDocumentEditSession(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentEditSession;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
