import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationDocumentListSetting, {
  OrganisationDocumentListSetting,
  Value,
} from '../model/organisationDocumentListSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationDocumentListSetting');
export interface BackendOrganisationDocumentListSetting {
  getOrganisationDocumentListSetting(id: string): AxiosPromise<OrganisationDocumentListSetting>;
  getOrganisationDocumentListSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteOrganisationDocumentListSetting(id: string): AxiosPromise;
  updateOrganisationDocumentListSetting(
    OrganisationDocumentListSetting: OrganisationDocumentListSetting
  ): AxiosPromise<any>;
  moveOrganisationDocumentListSetting(payload: {
    element: OrganisationDocumentListSetting;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any>;
}

export const defaultBackendOrganisationDocumentListSetting: BackendOrganisationDocumentListSetting = {
  getOrganisationDocumentListSetting(id: string): AxiosPromise<OrganisationDocumentListSetting> {
    const url = `${URLS.organisationDocumentListSettingOdata}/${id}`;
    return instance.get<OrganisationDocumentListSetting>(url);
  },
  getOrganisationDocumentListSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationDocumentListSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOrganisationDocumentListSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteOrganisationDocumentListSetting(id: string): AxiosPromise {
    logger.debug('deleteOrganisationDocumentListSetting');
    return instance.delete(`${URLS.organisationDocumentListSetting}/${id}`);
  },
  updateOrganisationDocumentListSetting(
    OrganisationDocumentListSetting: OrganisationDocumentListSetting
  ): AxiosPromise<any> {
    logger.debug('updateOrganisationDocumentListSetting');
    return instance.put<OrganisationDocumentListSetting>(
      `${URLS.organisationDocumentListSetting}/update`,
      organisationDocumentListSetting.toAPI(OrganisationDocumentListSetting)
    );
  },
  moveOrganisationDocumentListSetting(payload: {
    element: OrganisationDocumentListSetting;
    newIndex: number;
    oldIndex: number;
  }): AxiosPromise<any> {
    logger.debug('moveOrganisationDocumentProperty');
    const item = organisationDocumentListSetting.toAPI(payload.element);
    item.NewIndex = payload.newIndex;
    item.OldIndex = payload.oldIndex;
    return instance.put<OrganisationDocumentListSetting>(`${URLS.organisationDocumentListSetting}/move`, item);
  },
};
