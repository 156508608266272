import { GetterTree } from 'vuex';
import { AllocationsPropertyState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationsPropertyState, RootState> = {
  ['getAllocationsPropertys']: (state) => state.allocationsPropertys,
  ['getAllocationsPropertysIsLoading']: (state) => state.allocationsPropertys?.isLoading,
  ['getAllocationsPropertysTotal']: (state) => state.allocationsPropertys?.total,
  ['getAllocationsPropertysSearchParams']: (state) => state.allocationsPropertys?.searchParams,
};
