export interface RegexSplitterPatternPart {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  regexSplitterPartsId: string; // System.Guid
  type: string; // string
  value: string; // string
  minCount: number; // int
  maxCount: number; // int
  patternPartSortOrder: number; // int
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: RegexSplitterPatternPart[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<RegexSplitterPatternPart>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    RegexSplitterPartsId: data?.regexSplitterPartsId || undefined,
    Type: data?.type || '',
    Value: data?.value || '',
    MinCount: data?.minCount || 0,
    MaxCount: data?.maxCount || 0,
    PatternPartSortOrder: data?.patternPartSortOrder || 0,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
  };
}

function parse(data?: Partial<RegexSplitterPatternPart>): RegexSplitterPatternPart {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    regexSplitterPartsId: data?.regexSplitterPartsId || '',
    type: data?.type || '',
    value: data?.value || '',
    minCount: data?.minCount || 0,
    maxCount: data?.maxCount || 0,
    patternPartSortOrder: data?.patternPartSortOrder || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
