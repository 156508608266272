import { GetterTree } from 'vuex';
import { DocumentEditSessionState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentEditSessionState, RootState> = {
  ['getDocumentEditSessions']: (state) => state.documentEditSessions,
  ['getDocumentEditSessionsIsLoading']: (state) => state.documentEditSessions?.isLoading,
  ['getDocumentEditSessionsTotal']: (state) => state.documentEditSessions?.total,
  ['getDocumentEditSessionsSearchParams']: (state) => state.documentEditSessions?.searchParams,
};
