import { MutationTree } from 'vuex';
import { DocumentPropertyState } from './types';
import Vue from 'vue';
import documentProperty, { DocumentProperty, Value } from '@/shared/model/documentProperty';

export const mutations: MutationTree<DocumentPropertyState> = {
  setDocumentPropertys(state, payload: Value) {
    state.documentPropertys.items = payload.value.map((x) => documentProperty.parse(x));
    state.documentPropertys.total = payload['@odata.count'] || 0;
    state.documentPropertys.isLoading = false;
  },
  setDocumentPropertysTotal(state, payload: number) {
    state.documentPropertys.total = payload;
  },
  setDocumentPropertysIsLoading(state, payload: boolean) {
    state.documentPropertys.isLoading = payload;
  },
};
