import { ActionTree } from 'vuex';
import { AllocationTaxSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationTaxSetting } from '@/shared/backend/allocationTaxSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationTaxSetting, { Value, AllocationTaxSetting } from '@/shared/model/allocationTaxSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationTaxSettings');
export const actions: ActionTree<AllocationTaxSettingState, RootState> = {
  getAllocationTaxSettings(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; allocationTaxGroupsId?: string }
  ) {
    commit('setAllocationTaxSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationTaxSettingsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTaxSetting
      .getAllocationTaxSettings(searchParams, organizationId, payload?.allocationTaxGroupsId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTaxSettings', response.data);
        commit('setAllocationTaxSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationTaxSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTaxSettingByGroupDate({ commit, dispatch, getters }, date: string) {
    commit('setAllocationTaxSettingsAllForComboIsLoading', true);
    const searchParams = getters.getAllocationTaxSettingsAllForCombo.searchParams;
    return defaultBackendAllocationTaxSetting
      .getAllocationTaxSettingByGroupDate(searchParams, date)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTaxSettingsAllForCombo', response.data);
        commit('setAllocationTaxSettingsAllForComboIsLoading', false);
        const result = {
          items: response.data.value.map((x) => allocationTaxSetting.parse(x)),
          total: response.data['@odata.count'] || 0,
        };
        return result;
      })
      .catch((e: any) => {
        commit('setAllocationTaxSettingsAllForComboIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTaxSettingsAllForCombo(
    { commit, getters, rootGetters },
    payload: { searchParams?: any; allocationTaxGroupsId?: string }
  ) {
    commit('setAllocationTaxSettingsAllForComboIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationTaxSettingsAllForCombo.searchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTaxSetting

      .getAllocationTaxSettings(searchParams, organizationId, payload?.allocationTaxGroupsId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationTaxSettingsAllForCombo', response.data);
        commit('setAllocationTaxSettingsAllForComboIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationTaxSettingsAllForComboIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationTaxSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTaxSetting
      .getAllocationTaxSetting(id)
      .then((response: AxiosResponse<AllocationTaxSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationTaxSetting({ commit, dispatch }, file: AllocationTaxSetting) {
    return defaultBackendAllocationTaxSetting
      .updateAllocationTaxSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTaxSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationTaxSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadAllocationTaxSetting(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      allocationTaxGroupsId: string;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationTaxSetting
      .uploadAllocationTaxSetting(fileInfo)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.allocation_tax_setting_uploaded`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e.response.data;
      });
  },
  deleteAllocationTaxSetting({ commit, dispatch }, id: string) {
    return defaultBackendAllocationTaxSetting
      .deleteAllocationTaxSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationTaxSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
