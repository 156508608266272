import { GetterTree } from 'vuex';
import { AllocationsExportState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationsExportState, RootState> = {
  ['getAllocationsExports']: (state) => state.allocationsExports,
  ['getAllocationsExportsIsLoading']: (state) => state.allocationsExports?.isLoading,
  ['getAllocationsExportsTotal']: (state) => state.allocationsExports?.total,
  ['getAllocationsExportsSearchParams']: (state) => state.allocationsExports?.searchParams,
};
