import { MutationTree } from 'vuex';
import { AddressesImportSettingState } from './types';
import Vue from 'vue';
import addressesImportSetting, { AddressesImportSetting, Value } from '@/shared/model/addressesImportSetting';

export const mutations: MutationTree<AddressesImportSettingState> = {
  setAddressesImportSettings(state, payload: Value) {
    state.addressesImportSettings.items = payload.value.map((x) => addressesImportSetting.parse(x));
    state.addressesImportSettings.total = payload['@odata.count'] || 0;
    state.addressesImportSettings.isLoading = false;
  },
  setAddressesImportSettingsTotal(state, payload: number) {
    state.addressesImportSettings.total = payload;
  },
  setAddressesImportSettingsIsLoading(state, payload: boolean) {
    state.addressesImportSettings.isLoading = payload;
  },
  setAddressesImportSetting(state, payload: Value) {
    state.addressesImportSetting = Object.assign({}, addressesImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateAddressesImportSetting(state, payload: boolean) {
    state.inProgressUpdateAddressesImportSetting = payload;
  },
};
