import { render, staticRenderFns } from "./right-main-menu.html?vue&type=template&id=789a1168&scoped=true&external"
import script from "./right-main-menu.ts?vue&type=script&lang=ts&external"
export * from "./right-main-menu.ts?vue&type=script&lang=ts&external"
import style0 from "./right-main-menu.scss?vue&type=style&index=0&id=789a1168&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789a1168",
  null
  
)

/* custom blocks */
import block0 from "./right-main-menu.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fright-main-menu.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
