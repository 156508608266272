/**
 * Container to collect helper functions for backends
 */
import { URL_PARAMS } from '../model/url_params';
import { DataOptions } from '../model/DataOptions';
import { ODataFilterBuilder } from 'odata-filter-builder';
import { Logger } from 'fsts';
const logger = new Logger('BackendHelper');
export interface BackendHelper {
  removeUrlAppendix: (url: string) => string;
  /**
   * Append given parameter to given URL
   *
   * @param {string} url URL to append parameter to
   * @param {string[]} params List of parameter to be appended to URL
   */
  addParamsToUrl: (url: string, params: string[]) => string;
  /**
   * Add paging parameter to URL parameter list.
   * If `page` or `pageSize` is omitted, default values will be assumed.
   *
   * @param {string[]} params Existing URL parameter list
   * @param {string} top Index of page to be returned
   * @param {string} skip Size of page to be returned
   */
  addPagingParams: (params: string[], top?: number, skip?: number) => string[];
  /**
   * Add sorting parameter to URL parameter list
   *
   * @param {string[]} params Existing URL parameter list
   * @param {string[]} sorting List of parameters to be treated as sorting queries
   */
  addSortingParams: (params: string[], ...sorting: string[]) => string[];

  returnSql: (source: string) => string;

  convertDataOptionToOdata(options: DataOptions): string[];

  makeUrl(
    baseUrl: string,
    dataOptions?: DataOptions,
    orClauseFieldsIds?: string[],
    filter?: string,
    andClauseFilters?: { orClauseFieldsIds: string[]; filter: string }[] | ODataFilterBuilder,
    params?: string[],
    orClauseFieldsIdsConvertToString?: string[],
    orClauseFieldsIdsIgnoreCase?: string[],
    orClauseFieldsIdsGraterThen?: string[],
    orClauseFieldsIdsConvertToNumber?: string[]
  ): string;
}

export const DefaultBackendHelper: BackendHelper = {
  addParamsToUrl(url: string, params: string[]) {
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    return url;
  },

  addPagingParams(params: string[], top?: number, skip?: number) {
    if (top === undefined || isNaN(top)) {
      top = Number(process.env.VUE_APP_PAGING_PAGE_INDEX_DEFAULT);
    }
    params.push(URL_PARAMS.top + '=' + top);
    if (skip === undefined || isNaN(skip)) {
      skip = Number(process.env.VUE_APP_PAGING_PAGE_SIZE_DEFAULT);
    }
    params.push(URL_PARAMS.skip + '=' + skip);
    return params;
  },

  addSortingParams(params: string[], ...sorting: string[]) {
    if (sorting && sorting.length > 0) {
      for (const sort of sorting) {
        if (sort != undefined) params.push(URL_PARAMS.orderby + '=' + sort);
      }
    }
    return params;
  },

  returnSql(source: string) {
    if (source === '') {
      source = URL_PARAMS.filterClause + '=';
    } else {
      source = ' AND ';
    }
    return source;
  },

  convertDataOptionToOdata(options: DataOptions): string[] {
    const top = options.itemsPerPage;
    const skip = (options.page - 1) * (options.page > 1 ? 1 : 0) * options.itemsPerPage;
    let params: string[] = [];
    if (top > 0) params = DefaultBackendHelper.addPagingParams([], top, skip);
    else if (top == 0) params.push('$top=0');
    //logger.debug(`sortBy:${options.sortBy}`);
    if (options.sortBy && options.sortBy.length > 0) {
      const orders: string[] = options.sortBy.map(
        (item, i) =>
          `${item} ${
            typeof options.sortDesc == 'boolean'
              ? options.sortDesc == true
                ? 'desc'
                : 'asc'
              : options.sortDesc[i] == 'desc' || options.sortDesc[i] == true
              ? 'desc'
              : 'asc'
          }`
      );

      //logger.debug(`order clause:${orders}`);

      DefaultBackendHelper.addSortingParams(params, orders.join(','));
    }

    return params;
  },
  makeUrl(
    baseUrl: string,
    dataOptions?: DataOptions,
    orClauseFieldsIds: string[] = [],
    filter: string = '',
    andClauseFilters?: { orClauseFieldsIds: string[]; filter: string }[] | ODataFilterBuilder,
    paramsAdditional: string[] = [],
    orClauseFieldsIdsConvertToString: string[] = [],
    orClauseFieldsIdsIgnoreCase: string[] = [],
    orClauseFieldsIdsGraterThen: string[] = []
  ): string {
    //logger.debug(baseUrl);
    const params: string[] = ['$count=true'];
    if (dataOptions) {
      this.convertDataOptionToOdata(dataOptions).forEach((x) => params.push(x));
      applyFiltersForFields(
        orClauseFieldsIds,
        filter,
        andClauseFilters,
        params,
        orClauseFieldsIdsConvertToString,
        orClauseFieldsIdsIgnoreCase,
        orClauseFieldsIdsGraterThen
      );
    }
    return DefaultBackendHelper.addParamsToUrl(baseUrl, params.concat(paramsAdditional));
  },
  removeUrlAppendix: function (url: string): string {
    return '/odata' + url.substring(url.indexOf('/OData') + 6);
  },
};

function applyFiltersForFields(
  orClauseFieldsIds: string[],
  filter: string,
  andClauseFilters: { orClauseFieldsIds: string[]; filter: string }[] | ODataFilterBuilder | undefined,
  params: string[],
  orClauseFieldsIdsConvertToString?: string[],
  orClauseFieldsIdsIgnoreCase?: string[],
  orClauseFieldsIdsGraterThen?: string[]
) {
  const orClause = makeODataOrFilterPart(
    orClauseFieldsIds,
    filter,
    orClauseFieldsIdsConvertToString,
    orClauseFieldsIdsIgnoreCase,
    orClauseFieldsIdsGraterThen
  );
  let andClause: ODataFilterBuilder;
  if (andClauseFilters instanceof ODataFilterBuilder) {
    andClause = andClauseFilters;
  } else andClause = makeODataAndFilterPart(andClauseFilters);
  //logger.debug('and clause:' + andClause.toString());
  //logger.debug('or  clause:' + orClause.toString());
  const orand = encodeURIComponent(
    new ODataFilterBuilder('and').and(orClause.and(andClause).toString()).toString().trim()
  );
  if (orand) params.push(URL_PARAMS.filterClause + '=' + orand);
}

function makeODataOrFilterPart(
  orClauseFieldsIds: string[],
  filter: string,
  orClauseFieldsIdsConvertToString?: string[],
  orClauseFieldsIdsIgnoreCase?: string[],
  orClauseFieldsIdsGraterThen?: string[]
): ODataFilterBuilder {
  const result = new ODataFilterBuilder('or');
  if (orClauseFieldsIds && orClauseFieldsIds.length > 0 && filter)
    orClauseFieldsIds.forEach((element: string) => {
      // (ED-895) fix case sensitive search bug (when OData `element` (field) was `toLower` and the `filter` was NOT)
      //result.contains((x: any) => x.toLower(element), filter.toLowerCase().replace(/\'/g, "''"));
      if (orClauseFieldsIdsConvertToString || orClauseFieldsIdsIgnoreCase) {
        let field = element;
        if (orClauseFieldsIdsConvertToString?.includes(element)) field = `cast(${field}, 'Edm.String')`;
        if (orClauseFieldsIdsGraterThen?.includes(element)) {
          if (+filter !== Number.NaN) result.gt(field, +filter, false);
        } else {
          if (orClauseFieldsIdsIgnoreCase?.includes(element)) {
            field = `tolower(${field})`;
            result.or(`indexof(${field},'${filter.toLowerCase().replace(/\'/g, "''")}') gt -1`);
            // result.contains((x: any) => field, filter.toLowerCase().replace(/\'/g, "''"));
            // } else result.contains((x: any) => field, filter.replace(/\'/g, "''"));
          } else result.or(`indexof(${field},'${filter.replace(/\'/g, "''")}') gt -1`);
        }
      } // without config case & string convertations
      // else result.contains((x: any) => `cast(${element}, 'Edm.String')`, filter.toLowerCase().replace(/\'/g, "''"));
      else result.or(`indexof(cast(${element}, 'Edm.String'),'${filter.toLowerCase().replace(/\'/g, "''")}') gt -1`);
    });
  return result;
}
function makeODataAndFilterPart(
  andClauseFilters?: { orClauseFieldsIds: string[]; filter: string }[]
): ODataFilterBuilder {
  const result = new ODataFilterBuilder('and');

  if (andClauseFilters && andClauseFilters.length > 0)
    andClauseFilters!.forEach((element) => {
      const orClause = new ODataFilterBuilder('or');
      element.orClauseFieldsIds.forEach((field) => {
        orClause.eq(field, element.filter.replace(/\'/g, "''"), false);
      });
      result.and(orClause);
    });
  return result;
}
