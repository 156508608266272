import { DocumentFilesExportState } from './types';

export const state = (): DocumentFilesExportState => initialState();

export const initialState = (): DocumentFilesExportState => ({
  documentFilesExports: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['ActionEmailMessage'],
    },
  },
});
