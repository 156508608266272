import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationTaxGroupsImportSetting, {
  AllocationTaxGroupsImportSetting,
  Value,
} from '../model/allocationTaxGroupsImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationTaxGroupsImportSetting');
export interface BackendAllocationTaxGroupsImportSetting {
  getAllocationTaxGroupsImportSetting(id: string): AxiosPromise<AllocationTaxGroupsImportSetting>;
  getAllocationTaxGroupsImportSettingByGroupId(searchParams: SearchParams, idGroup: string): AxiosPromise<Value>;
  getAllocationTaxGroupsImportSettings: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationTaxGroupsImportSetting(id: string): AxiosPromise;
  updateAllocationTaxGroupsImportSetting(
    AllocationTaxGroupsImportSetting: AllocationTaxGroupsImportSetting
  ): AxiosPromise<any>;
}

export const defaultBackendAllocationTaxGroupsImportSetting: BackendAllocationTaxGroupsImportSetting = {
  getAllocationTaxGroupsImportSetting(id: string): AxiosPromise<AllocationTaxGroupsImportSetting> {
    const url = `${URLS.allocationTaxGroupsImportSettingOdata}/${id}`;
    return instance.get<AllocationTaxGroupsImportSetting>(url);
  },
  getAllocationTaxGroupsImportSettings(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxGroupsImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationTaxGroupsImportSettings${url}`);
    return instance.get<Value>(url);
  },
  getAllocationTaxGroupsImportSettingByGroupId(searchParams: SearchParams, idGroup: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq('allocationTaxGroupsId', idGroup, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTaxGroupsImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationTaxGroupsImportSettingByGroupId${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationTaxGroupsImportSetting(id: string): AxiosPromise {
    logger.debug('deleteAllocationTaxGroupsImportSetting');
    return instance.delete(`${URLS.allocationTaxGroupsImportSetting}/${id}`);
  },
  updateAllocationTaxGroupsImportSetting(
    AllocationTaxGroupsImportSetting: AllocationTaxGroupsImportSetting
  ): AxiosPromise<any> {
    logger.debug('updateAllocationTaxGroupsImportSetting');
    return instance.put<AllocationTaxGroupsImportSetting>(
      `${URLS.allocationTaxGroupsImportSetting}/update`,
      allocationTaxGroupsImportSetting.toAPI(AllocationTaxGroupsImportSetting)
    );
  },
};
