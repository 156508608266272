import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';
import { Logger } from 'fsts';
import { URLS } from '@/shared/backend';

const logger = new Logger('menu-document-files');
const fileModule = namespace('file');
const documentModule = namespace('document');

@Component({ name: 'menu-document-files', components: {} })
export default class MenuDocumentFilesView extends Vue {
  @fileModule.Action('getFilesForDocumentId')
  private actionGetFilesForDocumentId!: any;
  @fileModule.Getter('getFiles')
  private getterFile!: any;
  @documentModule.Action('createMetadata')
  private actionCreateMetadata!: any;
  @Getter('document/getIsMetadataProcessing') private getterGetIsMetadataProcessing: any;

  get documentId() {
    return this.$route.params['documentId'];
  }

  get files() {
    return this.getterFile.items.filter(
      (x: any) => x.mergedToFileId == x.fileId || x.mergedToFileId == '00000000-0000-0000-0000-000000000000'
    );
  }

  private showMergedFiles: boolean = false;

  showHideMergedFiles() {
    this.showMergedFiles = !this.showMergedFiles;
  }

  get getMergedIcon() {
    return this.showMergedFiles ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline';
  }

  get mergedFiles() {
    return this.getterFile.items.filter(
      (x: any) => x.mergedToFileId != x.fileId && x.mergedToFileId != '00000000-0000-0000-0000-000000000000'
    );
  }

  async mounted() {
    await this.actionGetFilesForDocumentId(this.documentId)
      .then(() => {})
      .catch(() => {});
  }

  private createMetadataFile() {
    this.actionCreateMetadata({ id: this.documentId, timezoneOffet: new Date().getTimezoneOffset() }).then(() => {
      this.actionGetFilesForDocumentId(this.documentId);
    });
  }

  private makeFileNameShorten(fileName: string) {
    const length = fileName.length;
    if (length > 25) {
      const leftPart = fileName.substring(0, 14);
      const rightPart = fileName.substring(length - 8, length);
      return `${leftPart}...${rightPart}`;
    } else return fileName;
  }

  get isMetadataProcessing() {
    if (this.getterGetIsMetadataProcessing) return 1;
    else return 0;
  }

  private returnBack() {
    this.$emit('click:return');
  }

  private openFile(id: string, shortFilename: string) {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${id}`;
    this.download(fileUrl, shortFilename);
  }

  private download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}
