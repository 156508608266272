import { UserState } from './types';

export const state = (): UserState => initialState();

export const initialState = (): UserState => ({
  users: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Email', 'Kurzform', 'FirstName', 'LastName', 'name', 'personalNumber'],
      orClauseFieldsIdsIgnoreCase: ['Email', 'Kurzform', 'FirstName', 'LastName', 'name', 'personalNumber'],
    },
  },

  qaUsers: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Email', 'Kurzform', 'FirstName', 'LastName', 'name'],
    },
  },

  userRights: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 55,
        sortBy: ['sortOrder'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  updateUserRightsIsLoading: false,
  registerUserInProcess: false,
  isUserEmailExistInProgress: false,
});
