import { OdataItems } from './../../../model/OdataItems';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import { GetterTree } from 'vuex';
import { DocumentPropertyState } from './types';
import { RootState } from '../../types';
import { DocumentProperty } from '@/shared/model/documentProperty';

export const getters: GetterTree<DocumentPropertyState, RootState> = {
  ['getDocumentPropertys']: (state) => state.documentPropertys,
  ['getDocumentPropertysIsLoading']: (state) => state.documentPropertys?.isLoading,
  ['getDocumentPropertysTotal']: (state) => state.documentPropertys?.total,
  ['getDocumentPropertysSearchParams']: (state) => state.documentPropertys?.searchParams,
  ['getDocumentPropertysEnabledFavorite']: (state, getters, rootState) => (
    documentPropertys: DocumentProperty[],
    odps: OdataItems<OrganisationDocumentProperty>,
    i: number
  ) => {
    const enabledFavorite = odps.items.filter((x) => x.enabled == true && x.favorite == true);
    if (enabledFavorite[i]) {
      const x = documentPropertys.filter((x) => x.fieldId == enabledFavorite[i].fieldId);
      return x[0]?.value;
    } else return '';
  },
};
