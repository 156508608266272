export interface AllocationBrand {
  id: string; // guid
  organisationId: string; // guid
  brandNumber: string; // string
  name: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  sortOrder: number; // int
}

export interface Value {
  value: AllocationBrand[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
  '@odata.nextLink'?: string | undefined;
}

// init api object
function toAPI(data: Partial<AllocationBrand>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    BrandNumber: data?.brandNumber || '',
    Name: data?.name || '',
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
    SortOrder: data?.sortOrder || 0,
  };
}

function parse(data?: Partial<AllocationBrand>): AllocationBrand {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    brandNumber: data?.brandNumber || '',
    name: data?.name || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    sortOrder: data?.sortOrder || 0,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
