import { ActionTree } from 'vuex';
import { OrganisationDocumentExtraStatusSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationDocumentExtraStatusSetting } from '@/shared/backend/organisationDocumentExtraStatusSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationDocumentExtraStatusSetting, {
  Value,
  OrganisationDocumentExtraStatusSetting,
} from '@/shared/model/organisationDocumentExtraStatusSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.organisationDocumentExtraStatusSettings');
export const actions: ActionTree<OrganisationDocumentExtraStatusSettingState, RootState> = {
  getOrganisationDocumentExtraStatusSettings(
    { commit, dispatch, getters },
    payload?: { searchParams?: any; folderId?: string }
  ) {
    commit('setOrganisationDocumentExtraStatusSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationDocumentExtraStatusSettingsSearchParams;
    return defaultBackendOrganisationDocumentExtraStatusSetting
      .getOrganisationDocumentExtraStatusSettings(searchParams)
      .then(async (response: AxiosResponse<Value>) => {
        const odesss = response.data;
        while (response.data['@odata.nextLink']) {
          response = await defaultBackendOrganisationDocumentExtraStatusSetting.getFoldersByUrl(
            response.data['@odata.nextLink']
          );
          odesss.value = odesss.value.concat(response.data.value);
        }
        commit('setOrganisationDocumentExtraStatusSettings', odesss);
        commit('setOrganisationDocumentExtraStatusSettingsIsLoading', false);
        return getters.getOrganisationDocumentExtraStatusSettings.items;
      })
      .catch((e: any) => {
        commit('setOrganisationDocumentExtraStatusSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationDocumentExtraStatusSetting({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentExtraStatusSetting
      .getOrganisationDocumentExtraStatusSetting(id)
      .then((response: AxiosResponse<OrganisationDocumentExtraStatusSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationDocumentExtraStatusSetting({ commit, dispatch }, file: OrganisationDocumentExtraStatusSetting) {
    return defaultBackendOrganisationDocumentExtraStatusSetting
      .updateOrganisationDocumentExtraStatusSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentExtraStatusSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisationDocumentExtraStatusSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteOrganisationDocumentExtraStatusSetting({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationDocumentExtraStatusSetting
      .deleteOrganisationDocumentExtraStatusSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationDocumentExtraStatusSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
