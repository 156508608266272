import GeneralUtils from '@/shared/utils/generalUtils';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

// Component for developers
const authModule = namespace('auth');

@Component
export default class ChangeLocaleRadioGroup extends Vue {
  @authModule.Getter('getAccount')
  private account!: any;
  get language() {
    return this.account?.profile?.language;
  }
  private setLocale(locale: string) {
    this.$root.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
  }
}
