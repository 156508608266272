import { DriveTypeState } from './types';

export const state = (): DriveTypeState => initialState();

export const initialState = (): DriveTypeState => ({
  driveTypes: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sValue'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Name', 'sValue'],
      orClauseFieldsIdsIgnoreCase: ['Name', 'sValue'],
    },
  },
  isImporting: false,
});
