import { GetterTree } from 'vuex';
import { DocumentStatusState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentStatusState, RootState> = {
  ['getDocumentStatus']: (state) => state.documentStatus,
  ['getDocumentStatuses']: (state) => state.documentStatuses,
  ['getDocumentStatusesIsLoading']: (state) => state.documentStatuses?.isLoading,
  ['getDocumentStatusesTotal']: (state) => state.documentStatuses?.total,
  ['getDocumentStatusesSearchParams']: (state) => state.documentStatuses?.searchParams,
};
