import userFile from '@/shared/model/userFile';
import { UserFileState } from './types';

export const state = (): UserFileState => initialState();

export const initialState = (): UserFileState => ({
  userFile: userFile.parse({}),
  userFileIsLoading: [],
  userFiles: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['created'], // on backend field called `created`, NOT `createdDate`
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['title'],
    },
  },
});
