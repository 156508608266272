export interface Supplier {
  id: string; // ulong
  organisationId: string; // string
  userId: string; // string
  allocationMatrixEntryId: string; // string
  changeTimeStamp: number; // int
  konto: string; // string
  bezeichnung: string; // string
  kostenstelle: string; // string
  absatzkanal: string; // string
  kostentraeger: string; // string
  antriebsart: string; // string
  kontoInteger: number; // long
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  herkunft: string; // string
  haendlerEigenerKostentraeger: string; // string
  erweiterterVertriebsweg: string; // string
  serialNumberRequired: boolean;
}

export interface Value {
  value: Supplier[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Supplier>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    UserId: data?.userId || undefined,
    AllocationMatrixEntryId: data?.allocationMatrixEntryId || undefined,
    ChangeTimeStamp: data?.changeTimeStamp || 0,
    Konto: data?.konto || '',
    Bezeichnung: data?.bezeichnung || '',
    Kostenstelle: data?.kostenstelle || '',
    Absatzkanal: data?.absatzkanal || '',
    Kostentraeger: data?.kostentraeger || '',
    Antriebsart: data?.antriebsart || '',
    KontoInteger: data?.kontoInteger || 0,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    Herkunft: data?.herkunft || '',
    HaendlerEigenerKostentraeger: data?.haendlerEigenerKostentraeger || '',
    ErweiterterVertriebsweg: data?.erweiterterVertriebsweg || '',
    SerialNumberRequired: data?.serialNumberRequired || false,
  };
}

function parse(data?: Partial<Supplier>): Supplier {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    userId: data?.userId || '',
    allocationMatrixEntryId: data?.allocationMatrixEntryId || '',
    changeTimeStamp: data?.changeTimeStamp || 0,
    konto: data?.konto || '',
    bezeichnung: data?.bezeichnung || '',
    kostenstelle: data?.kostenstelle || '',
    absatzkanal: data?.absatzkanal || '',
    kostentraeger: data?.kostentraeger || '',
    antriebsart: data?.antriebsart || '',
    kontoInteger: data?.kontoInteger || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    herkunft: data?.herkunft || '',
    haendlerEigenerKostentraeger: data?.haendlerEigenerKostentraeger || '',
    erweiterterVertriebsweg: data?.erweiterterVertriebsweg || '',
    serialNumberRequired: data?.serialNumberRequired || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
