import TimeSpanUtils from '../utils/TimeSpanUtil';

export interface UserBillingInfo {
  id: string; // guid
  userId: string; // guid
  firm: string; // string
  firstName: string; // string
  lastName: string; // string
  street: string; // string
  zipCode: string; // string
  locale: string; // string
  addressInfo: string; // string
  phone: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?

  // calculeted fields
  kundenNummer: string;
  mainPackagePrice: number;
  mainHddPackageVolume: number;
  addonPackageCount: number;
  addonPackagePrice: number;
  addonHddPackageCount: number;
  addonHddPackagePrice: number;
  addonHddPackageVolume: number;
  fileSize: number[];

  newOrganizationName?: string;
  copyFromOrganizationId?: string;
  addonHddPackangeId?: string;
  bankAccountHolder?: string;
  bankIBAN?: string;
  bankBIC?: string;
  automaticallyDebit?: boolean;

  organisationId: string;
}

export interface Value {
  value: UserBillingInfo[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<UserBillingInfo>): any {
  const result: any = {
    Firm: data?.firm || '',
    FirstName: data?.firstName || '',
    LastName: data?.lastName || '',
    Street: data?.street || '',
    ZipCode: data?.zipCode || '',
    Locale: data?.locale || '',
    AddressInfo: data?.addressInfo || '',
    Phone: data?.phone || '',
  };
  if (data?.id) {
    result['Id'] = data?.id;
    result['Id@odata.type'] = '#Guid';
  }
  if (data?.userId) {
    result['UserId'] = data?.userId;
    result['UserId@odata.type'] = '#Guid';
  }
  if (data?.organisationId) {
    result['OrganisationId'] = data?.organisationId;
    result['OrganisationId@odata.type'] = '#Guid';
  }
  if (data?.newOrganizationName) result.NewOrganizationName = data?.newOrganizationName;
  if (data?.bankAccountHolder) result.BankAccountHolder = data?.bankAccountHolder;
  if (data?.automaticallyDebit) result.AutomaticallyDebit = data?.automaticallyDebit;
  if (data?.bankBIC) result.BankBIC = data?.bankBIC;
  if (data?.bankIBAN) result.BankIBAN = data?.bankIBAN;
  if (data?.addonHddPackangeId && (data?.addonHddPackangeId as any)?.id) {
    result.AddonHddPackangeId = (data?.addonHddPackangeId as any).id;
  } else if (data?.addonHddPackangeId) result.AddonHddPackangeId = data?.addonHddPackangeId;
  if (data?.copyFromOrganizationId) {
    result.CopyFromOrganizationId = data?.copyFromOrganizationId;
    result['CopyFromOrganizationId@odata.type'] = '#Guid';
  }
  if (result.CreatedAt != undefined) {
    result.CreatedAt = data?.createdAt;
    result['CreatedAt@odata.type'] = '#TimeOfDay';
  }
  if (result.UpdatedAt != undefined) {
    result.UpdatedAt = data?.updatedAt;
    result['UpdatedAt@odata.type'] = '#TimeOfDay';
  }
  return result;
}

function parse(data?: Partial<UserBillingInfo>): UserBillingInfo {
  return {
    id: data?.id || '',
    userId: data?.userId || '',
    firm: data?.firm || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    street: data?.street || '',
    zipCode: data?.zipCode || '',
    locale: data?.locale || '',
    addressInfo: data?.addressInfo || '',
    phone: data?.phone || '',
    createdAt: TimeSpanUtils.fromAPIString(data?.createdAt || ''),
    updatedAt: TimeSpanUtils.fromAPIString(data?.updatedAt || ''),

    copyFromOrganizationId: data?.copyFromOrganizationId || '00000000-0000-0000-0000-000000000000',
    kundenNummer: data?.kundenNummer || '',
    mainPackagePrice: data?.mainPackagePrice || 79.9,
    mainHddPackageVolume: data?.mainHddPackageVolume || 200,
    addonPackageCount: data?.addonPackageCount || 0,
    addonPackagePrice: data?.addonPackagePrice || 79.9,
    addonHddPackageCount: data?.addonHddPackageCount || 0,
    addonHddPackagePrice: data?.addonHddPackagePrice || 39.9,
    addonHddPackageVolume: data?.addonHddPackageVolume || 200,
    fileSize: JSON.parse(data?.fileSize?.toString() ?? '[]'),
    organisationId: data?.organisationId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
