import axios from 'axios';
import router from '../../router';
import store from '../store';
import LSService from '../utils/LocalStorageService';

const lsService = LSService.getService();

export const instance = axios.create({
  baseURL: process.env.VUE_KEEPTIME_BACKEND_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = lsService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
      router.push({ name: 'login', params: { checkLogin: 'false' } });
    }
  }
);
/**
 * Global error handler.
 */
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
      router.push({ name: 'login', params: { checkLogin: 'false' } });
    }
    throw error;
  }
);

export enum URLS {
  account = '/api/account',
  impersonate = '/api/impersonate',
  userFile = '/api/userFile',
  userFileOdata = '/odata/userFile',
  document = '/api/documents',
  documentOdata = '/odata/documents',
  documentsDetailedViewOdata = '/odata/DocumentsDetailedView',
  documentStateOdata = '/odata/documentStates',
  documentState = '/api/documentStates',
  folderOdata = '/odata/folders',
  folder = '/api/folders',
  documentStatusOdata = '/odata/documentStatuses',
  documentStatus = '/api/documentStatuses',

  organisationDocumentExtraStatusSettingOdata = '/odata/organisationDocumentExtraStatusSettings',
  organisationDocumentExtraStatusSetting = '/api/organisationDocumentExtraStatusSettings',
  organisationDocumentPropertyOdata = '/odata/organisationDocumentPropertys',
  organisationDocumentProperty = '/api/organisationDocumentPropertys',
  documentPropertyOdata = '/odata/documentPropertys',
  documentProperty = '/api/documentPropertys',

  organisationOdata = '/odata/organisations',
  organisation = '/api/organisations',
  employeeOdata = '/odata/employees',
  employee = '/api/employees',
  roleOdata = '/odata/roles',
  role = '/api/roles',
  rightOdata = '/odata/rights',
  right = '/api/rights',
  workflowruleOdata = '/odata/WorkflowRules',
  workflowrule = '/api/WorkflowRules',
  commentOdata = '/odata/Comments',
  comment = '/api/Comments',
  actionLogOdata = '/odata/ActionLogs',
  actionLog = '/api/ActionLogs',
  addressOdata = '/odata/Addresses',
  address = '/api/Addresses',
  addressesImportSettingOdata = '/odata/addressesImportSettings',
  addressesImportSetting = '/api/addressesImportSettings',
  exportOdata = '/odata/Exports',
  export = '/api/Exports',

  gobdDataOdata = '/odata/DocumentGobdDatums',
  gobdData = '/api/DocumentGobdDatums',
  officeOdata = '/odata/Offices',
  office = '/api/Offices',

  folderRuleOdata = '/odata/folderRules',
  folderRule = '/api/folderRules',
  allocationBrandsImportSettingOdata = '/odata/allocationBrandsImportSettings',
  allocationBrandsImportSetting = '/api/allocationBrandsImportSettings',
  allocationSettingOdata = '/odata/allocationSettings',
  allocationSetting = '/api/allocationSettings',
  allocationBrandOdata = '/odata/allocationBrands',
  allocationBrand = '/api/allocationBrands',
  allocationFrameOdata = '/odata/allocationFrames',
  allocationFrame = '/api/allocationFrames',
  allocationOfficeOdata = '/odata/allocationOffices',
  allocationOffice = '/api/allocationOffices',
  allocationOfficesImportSettingOdata = '/odata/allocationOfficesImportSettings',
  allocationOfficesImportSetting = '/api/allocationOfficesImportSettings',
  allocationTaxGroupOdata = '/odata/allocationTaxGroups',
  allocationTaxGroup = '/api/allocationTaxGroups',
  allocationTaxGroupsImportSettingOdata = '/odata/allocationTaxGroupsImportSettings',
  allocationTaxGroupsImportSetting = '/api/allocationTaxGroupsImportSettings',
  allocationTaxSettingOdata = '/odata/allocationTaxSettings',
  allocationTaxSetting = '/api/allocationTaxSettings',
  organisationOfficeOdata = '/odata/organisationOffices',
  organisationOffice = '/api/organisationOffices',
  processListTodoOdata = '/odata/processListTodos',
  processListTodo = '/api/processListTodos',

  templateMarkupOdata = '/odata/templateMarkups',
  templateMarkup = '/api/templateMarkups',
  ocr = '/api/ocr',
  costObjectiveOdata = '/odata/costObjectives',
  costObjective = '/api/costObjectives',
  costObjectiveImportSettingOdata = '/odata/costObjectiveImportSettings',
  costObjectiveImportSetting = '/api/costObjectiveImportSettings',
  costCentreOdata = '/odata/costCentres',
  costCentre = '/api/costCentres',
  costCentreImportSettingOdata = '/odata/costCentreImportSettings',
  costCentreImportSetting = '/api/costCentreImportSettings',
  driveTypeOdata = '/odata/driveTypes',
  driveType = '/api/driveTypes',
  driveTypesImportSettingOdata = '/odata/driveTypesImportSettings',
  driveTypesImportSetting = '/api/driveTypesImportSettings',
  tradeChannelOdata = '/odata/tradeChannels',
  tradeChannel = '/api/tradeChannels',
  tradeChannelImportSettingOdata = '/odata/tradeChannelImportSettings',
  tradeChannelImportSetting = '/api/tradeChannelImportSettings',
  supplierOdata = '/odata/suppliers',
  supplier = '/api/suppliers',
  suppliersImportSettingOdata = '/odata/suppliersImportSettings',
  suppliersImportSetting = '/api/suppliersImportSettings',
  organisationDocumentSettingOdata = '/odata/organisationDocumentSettings',
  organisationDocumentSetting = '/api/organisationDocumentSettings',
  datevSettingOdata = '/odata/datevSettings',
  datevSetting = '/api/datevSettings',
  organisationAllocationExportPropertyOdata = '/odata/organisationAllocationExportProperties',
  organisationAllocationExportProperty = '/api/organisationAllocationExportProperties',
  organisationAllocationPropertyOdata = '/odata/organisationAllocationProperties',
  organisationAllocationProperty = '/api/organisationAllocationProperties',
  userBillingInfoOdata = '/odata/UserBillingInfos',
  userBillingInfo = '/api/userBillingInfos',
  roleRight = '/api/roleRights',
  roleRightOdata = '/odata/roleRights',
  roleLoginTimeOdata = '/odata/roleLoginTimes',
  roleLoginTime = '/api/roleLoginTimes',
  userOdata = '/odata/users',
  user = '/api/users',
  userRolesOdata = '/odata/userRoles',
  userRoles = '/api/userRoles',

  organisationAddressOdata = '/odata/organisationAddresses',
  organisationAddress = '/api/organisationAddresses',

  fileOdata = '/odata/files',
  file = '/api/files',

  documentFileOdata = '/odata/documentFiles',
  documentFile = '/api/documentFiles',

  documentPagesTextOdata = '/odata/documentPagesTexts',
  documentPagesText = '/api/documentPagesTexts',

  filePagesTextOdata = '/odata/filePagesTexts',
  filePagesText = '/api/filePagesTexts',

  userPackageOdata = '/odata/userPackages',
  userPackage = '/api/userPackages',

  actionLogRoleOdata = '/odata/actionLogRoles',
  actionLogRole = '/api/actionLogRoles',

  allocationFramePropertyOdata = '/odata/allocationFramePropertys',
  allocationFrameProperty = '/api/allocationFramePropertys',

  allocationOdata = '/odata/allocations',
  allocation = '/api/allocations',

  allocationsPropertyOdata = '/odata/allocationsPropertys',
  allocationsProperty = '/api/allocationsPropertys',

  allocationTemplateOdata = '/odata/allocationTemplates',
  allocationTemplate = '/api/allocationTemplates',

  allocationTemplatePropertyOdata = '/odata/allocationTemplatePropertys',
  allocationTemplateProperty = '/api/allocationTemplatePropertys',

  organisationUserOdata = '/odata/organisationUsers',
  organisationUsers = '/api/organisationUsers',

  autoGobdCheckTermOdata = '/odata/autoGobdCheckTerms',
  autoGobdCheckTerm = '/api/autoGobdCheckTerms',

  allocationsExportOdata = '/odata/allocationsExports',
  allocationsExport = '/api/allocationsExports',

  processListsExportOdata = '/odata/processListsExports',
  processListsExport = '/api/processListsExports',

  documentFilesExportOdata = '/odata/documentFilesExports',
  documentFilesExport = '/api/documentFilesExports',

  documentEditSessionOdata = '/odata/documentEditSessions',
  documentEditSession = '/api/documentEditSessions',

  allocationsExportAllocationOdata = '/odata/allocationsExportAllocations',
  allocationsExportAllocation = '/api/allocationsExportAllocations',

  regexSplitterOdata = '/odata/regexSplitters',
  regexSplitter = '/api/regexSplitters',

  regexSplitterPartOdata = '/odata/regexSplitterParts',
  regexSplitterPart = '/api/regexSplitterParts',

  regexSplitterPatternPartOdata = '/odata/regexSplitterPatternParts',
  regexSplitterPatternPart = '/api/regexSplitterPatternParts',

  organisationDocumentListSettingOdata = '/odata/organisationDocumentListSettings',
  organisationDocumentListSetting = '/api/organisationDocumentListSettings',

  documentReminderOdata = '/odata/documentReminders',
  documentReminder = '/api/documentReminders',
}

export enum URL_PARAMS {
  orderby = '$orderby',
  top = '$top',
  skip = '$skip',
  count = '$count',

  nameContains = '$filter',
  filterClause = '$filter',
}
