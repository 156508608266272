import DateUtils from '@/shared/utils/DateUtils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { ActionLog } from '@/shared/model/actionLog';
import { OdataItems } from '@/shared/model/OdataItems';

const name = 'activities-settings-view';
const logger = new Logger(name);
const actionLogModule = namespace('actionLog');
const organisationDocumentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
@Component({ name: name })
export default class ActivitiesSettingsView extends Vue {
  @Prop({ default: '', type: String })
  private documentIdFilter!: string;

  @actionLogModule.Action('getActionLogs')
  private getActionLogs!: any;
  @actionLogModule.Getter('getActionLogs')
  private actionLogs!: OdataItems<ActionLog>;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      // { text: this.$t('action_log_message'), value: 'actionLogMessage' },
      // { text: this.$t('action_log_infos'), value: 'actionLogInfos' },
      { text: this.$t('action_log_message'), value: 'type' },
      { text: this.$t('action_log_infos'), value: 'info' },
      // { text: this.$t('updated_at'), value: 'updatedAt' },
    ];
    return headers;
  }

  //#region search update
  @Watch('actionLogs.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }
  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('actionLogs.searchParams.dataOption.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      this.reloadData();
    }
  }
  @Watch('actionLogs.searchParams.dataOption.sortDesc', { deep: true })
  public onOptionsSortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    if (oldValAsString != newValAsString) {
      this.reloadData();
    }
  }
  @Watch('actionLogs.searchParams.dataOption.page', { deep: true })
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }

  private onOptionsChanged(newVal: any) {
    this.reloadData();
  }
  async reloadData() {
    if (this.documentIdFilter) this.actionLogs.searchParams.filter = '';
    await this.getActionLogs({ searchParams: this.actionLogs.searchParams, documentIdFilter: this.documentIdFilter });
  }
  //#endregion
  getJson(data: any) {
    if (typeof data === 'object')
      return JSON.stringify(data, (key, value) => {
        if (value !== null && value.length != 0 && value != '00000000-0000-0000-0000-000000000000') return value;
      });

    return JSON.parse(data);
  }
  getRole(data: ActionLog) {
    return data?.data.roles.length > 0 ? data?.data.roles[0] : '';
  }
  getMember(data: ActionLog) {
    return data?.data.members.length > 0 ? data?.data.members[0] : '';
  }
  parceDate(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale);
  }

  getKundeNummer(data: ActionLog) {
    if (data?.data.address.length > 0) {
      const part = data?.data.address.split(',')[0];
      return part.length > 0 ? part.split(':')[1] : '';
    } else return '';
  }

  getSeriennummer(data: ActionLog) {
    if (data?.data.address.length > 0) {
      const part = data?.data.address.split(',')[1];
      return part.length > 0 ? part.split(':')[1] : '';
    } else return '';
  }

  getKennzeichen(data: ActionLog) {
    if (data?.data.address.length > 0) {
      const part = data?.data.address.split(',')[2];
      return part.length > 0 ? part.split(':')[1].replace('}', '') : '';
    } else return '';
  }

  @organisationDocumentExtraStatusSettingModule.Getter('getNameByValue')
  private getNameByValue!: any;
  @organisationDocumentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  mounted() {
    this.actionGetOrganisationDocumentExtraStatusSettings();
  }
}
