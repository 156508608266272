import { MutationTree } from 'vuex';
import { SupplierState } from './types';
import Vue from 'vue';
import supplier, { Supplier, Value } from '@/shared/model/supplier';

export const mutations: MutationTree<SupplierState> = {
  setSuppliers(state, payload: Value) {
    state.suppliers.items = payload.value.map((x) => supplier.parse(x));
    state.suppliers.total = payload['@odata.count'] || 0;
    state.suppliers.isLoading = false;
  },
  setSuppliersTotal(state, payload: number) {
    state.suppliers.total = payload;
  },
  resetSuppliersCurrentPage(state) {
    state.suppliers.searchParams.dataOption.page = 1;
  },
  setSuppliersIsLoading(state, payload: boolean) {
    state.suppliers.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
  setAccountSupplier(state, payload: Supplier) {
    state.accountSupplier = payload;
  },
  setContraAccountSupplier(state, payload: Supplier) {
    state.contraAccountSupplier = payload;
  },
};
