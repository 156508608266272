import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OrganisationDocumentExtraStatusSetting } from '@/shared/model/organisationDocumentExtraStatusSetting';
import { format, parse } from 'date-fns';
import { URLS } from '@/shared/backend';

const logger = new Logger('menu-allocation-export-download');
const allocationsExportModule = namespace('allocationsExport');

@Component({ name: 'menu-allocation-export-download', components: {} })
export default class MenuAllocationExportDownloadView extends Vue {
  @allocationsExportModule.Action('markAsDownloadedAllocationExport')
  private actionMarkAsDownloadedAllocationExport!: any;

  get exportId() {
    return this.$route.params['exportId'];
  }

  get exportFileId() {
    return this.$route.params['exportFileId'];
  }

  get exportFileName() {
    return this.$route.params['exportFileName'];
  }

  onClickDownload() {
    this.openFile(this.exportFileId, this.exportFileName, this.exportId);
  }

  private openFile(fileId: string, shortFilename: string, exportId: string) {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${fileId}`;
    this.download(fileUrl, shortFilename);
    this.actionMarkAsDownloadedAllocationExport(exportId).then(() => {});
  }

  private download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }
}
