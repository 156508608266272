import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationFrame, { AllocationFrame, Value } from '../model/allocationFrame';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationFrame');
export interface BackendAllocationFrame {
  getAllocationFrame(id: string): AxiosPromise<AllocationFrame>;
  getAllocationFrames: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationFrame(id: string): AxiosPromise;
  updateAllocationFrame(AllocationFrame: AllocationFrame): AxiosPromise<any>;
}

export const defaultBackendAllocationFrame: BackendAllocationFrame = {
  getAllocationFrame(id: string): AxiosPromise<AllocationFrame> {
    const url = `${URLS.allocationFrameOdata}/${id}`;
    return instance.get<AllocationFrame>(url);
  },
  getAllocationFrames(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationFrameOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationFrames${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationFrame(id: string): AxiosPromise {
    logger.debug('deleteAllocationFrame');
    return instance.delete(`${URLS.allocationFrame}/${id}`);
  },
  updateAllocationFrame(AllocationFrame: AllocationFrame): AxiosPromise<any> {
    logger.debug('updateAllocationFrame');
    return instance.put<AllocationFrame>(`${URLS.allocationFrame}/update`, allocationFrame.toAPI(AllocationFrame));
  },
};
