import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import addressesImportSetting, { AddressesImportSetting, Value } from '../model/addressesImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AddressesImportSetting');
export interface BackendAddressesImportSetting {
  getAddressesImportSetting(id: string): AxiosPromise<AddressesImportSetting>;
  getAddressesImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAddressesImportSetting(id: string): AxiosPromise;
  updateAddressesImportSetting(AddressesImportSetting: AddressesImportSetting): AxiosPromise<any>;
}

export const defaultBackendAddressesImportSetting: BackendAddressesImportSetting = {
  getAddressesImportSetting(id: string): AxiosPromise<AddressesImportSetting> {
    const url = `${URLS.addressesImportSettingOdata}/${id}`;
    return instance.get<AddressesImportSetting>(url);
  },
  getAddressesImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.addressesImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAddressesImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteAddressesImportSetting(id: string): AxiosPromise {
    logger.debug('deleteAddressesImportSetting');
    return instance.delete(`${URLS.addressesImportSetting}/${id}`);
  },
  updateAddressesImportSetting(AddressesImportSetting: AddressesImportSetting): AxiosPromise<any> {
    logger.debug('updateAddressesImportSetting');
    return instance.put<AddressesImportSetting>(
      `${URLS.addressesImportSetting}/update`,
      addressesImportSetting.toAPI(AddressesImportSetting)
    );
  },
};
