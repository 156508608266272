import { UserManager, WebStorageStateStore, User, SessionStatus } from 'oidc-client';
import store from '../store';
import Vue from 'vue';
import { Logger } from 'fsts';
const logger = new Logger('AuthService');
export interface IAuthService {
  getUser: () => Promise<User | null>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  mgr: UserManager;
}
export default class AuthService implements IAuthService {
  public mgr: UserManager;

  constructor() {
    const mainOrg = undefined;
    const settings: any = this.getSettings(mainOrg);
    this.mgr = new UserManager(settings);
  }

  private getSettings(mo?: string, iuser?: string): any {
    const base_path = process.env.VUE_APP_BASE_PATH;
    const base_path_redirect = process.env.VUE_APP_BASE_PATH_REDIRECT;
    const client = process.env.VUE_APP_CLIENT;
    const STS_DOMAIN: string = base_path + ':8005/';
    const acr_value: string[] = [];
    if (mo) acr_value.push(`main_organization_${mo}`);
    if (iuser) acr_value.push(`impersonate_user_${iuser}`);

    return {
      authority: STS_DOMAIN,
      client_id: client,
      scope: 'openid profile storage backend offline_access IdentityServerApi',
      redirect_uri: base_path_redirect + '/callback',
      automaticSilentRenew: true,
      silent_redirect_uri: base_path_redirect + '/silent-renew',
      accessTokenExpiringNotificationTime: 10,
      response_type: 'id_token token',
      post_logout_redirect_uri: base_path_redirect + '/',
      acr_values: acr_value ? acr_value.join(':') : undefined,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      loadUserInfo: true,
    };
  }

  public signinRedirectCallback(): Promise<User | null> {
    return this.mgr.signinRedirectCallback();
  }
  public signinSilent(): Promise<User | undefined> {
    return this.mgr.signinSilent();
  }
  public signinSilentCallback(): Promise<User | undefined> {
    this.mgr = new UserManager(this.getSettings());
    return this.mgr.signinSilentCallback();
  }
  public getUser(): Promise<User | null> {
    return this.mgr.getUser();
  }
  public login(organizationId?: string, impersonateId?: string): Promise<void> {
    this.mgr = new UserManager(this.getSettings(organizationId, impersonateId));
    logger.debug(`login: ${organizationId}, ${impersonateId}`);
    return this.mgr.signinRedirect().then(() => {});
  }

  public logout(): Promise<void> {
    this.mgr.clearStaleState();
    return this.mgr.signoutRedirect().then(() => {});
  }
}
export const DefaultAuthService = new AuthService();
