import { AllocationSettingExportTypes } from './../model/allocationSetting';
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationSetting, { AllocationSetting, Value } from '../model/allocationSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.AllocationSetting');
export interface BackendAllocationSetting {
  getAllocationSetting(id: string): AxiosPromise<AllocationSetting>;
  getAllocationSettings: (searchParams: SearchParams, organizationId: string) => AxiosPromise<Value>;
  deleteAllocationSetting(id: string): AxiosPromise;
  updateAllocationSetting(AllocationSetting: AllocationSetting): AxiosPromise<any>;
  setExportType(exportType: AllocationSettingExportTypes): AxiosPromise<any>;
}

export const defaultBackendAllocationSetting: BackendAllocationSetting = {
  getAllocationSetting(id: string): AxiosPromise<AllocationSetting> {
    const url = `${URLS.allocationSettingOdata}/${id}`;
    return instance.get<AllocationSetting>(url);
  },
  getAllocationSettings(searchParams: SearchParams, organizationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationSetting(id: string): AxiosPromise {
    logger.debug('deleteAllocationSetting');
    return instance.delete(`${URLS.allocationSetting}/${id}`);
  },
  updateAllocationSetting(AllocationSetting: AllocationSetting): AxiosPromise<any> {
    logger.debug('updateAllocationSetting');
    return instance.put<AllocationSetting>(
      `${URLS.allocationSetting}/update`,
      allocationSetting.toAPI(AllocationSetting)
    );
  },
  setExportType(exportType: AllocationSettingExportTypes): AxiosPromise<any> {
    logger.debug('setExportType');
    return instance.post<AllocationSetting>(`${URLS.allocationSetting}/setExportType`, { exportType: exportType });
  },
};
