import { ActionTree } from 'vuex';
import { OfficeState } from './types';
import { RootState } from '../../types';
import { defaultBackendOffice } from '@/shared/backend/office';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import office, { Value, Office } from '@/shared/model/office';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.Offices');
export const actions: ActionTree<OfficeState, RootState> = {
  getOffices({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; folderId?: string }) {
    commit('setOfficesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOfficesSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendOffice
      .getOffices(searchParams, organizationId, payload?.folderId || '')
      .then((response: AxiosResponse<Value>) => {
        commit('setOffices', response.data);
        commit('setOfficesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOfficesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOffice({ commit, dispatch }, id: string) {
    return defaultBackendOffice
      .getOffice(id)
      .then((response: AxiosResponse<Office>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOffice({ commit, dispatch }, file: Office) {
    return defaultBackendOffice
      .updateOffice(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Office;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.office_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteOffice({ commit, dispatch }, id: string) {
    return defaultBackendOffice
      .deleteOffice(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Office;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
