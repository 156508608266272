import allocationTaxSetting, { AllocationTaxSetting, Value as atsValue } from '@/shared/model/allocationTaxSetting';
import DateUtils from '../utils/DateUtils';
import { OdataItems } from './OdataItems';
import allocationTaxGroupsImportSetting, { AllocationTaxGroupsImportSetting } from './allocationTaxGroupsImportSetting';

export interface AllocationTaxGroup {
  id: string; // guid
  orderNumber: number; // number
  organisationId: string; // string
  fromDateTime: string; // System.DateTime?
  toDateTime: string; // System.DateTime?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  allocationTaxSettings?: AllocationTaxSetting[];
  taxSettings?: OdataItems<AllocationTaxSetting>;
  'allocationTaxSettings@odata.count'?: number;
  allocationTaxGroupsImportSetting: AllocationTaxGroupsImportSetting;
}

export interface Value {
  value: AllocationTaxGroup[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationTaxGroup>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    FromDateTime: data?.fromDateTime || undefined,
    ToDateTime: data?.toDateTime || undefined,
  };
}

function parse(data?: Partial<AllocationTaxGroup>): AllocationTaxGroup {
  let taxSettings: OdataItems<AllocationTaxSetting> = {
    items: [],
    total: 0,
    isLoading: false,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sPercent'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['sPercent', 'sCode', 'Type'],
      orClauseFieldsIdsIgnoreCase: ['sPercent', 'sCode', 'Type'],
    },
  };
  if (data?.allocationTaxSettings && (data['allocationTaxSettings@odata.count'] ?? 0 > 0)) {
    taxSettings = {
      items: data['allocationTaxSettings'].map((x) => allocationTaxSetting.parse(x)) ?? [],
      total: data['allocationTaxSettings@odata.count'] || 0,
      isLoading: false,
      searchParams: {
        dataOption: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['sPercent'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: true,
          mustSort: false,
        },
        filter: '',
        orClauseFieldsIds: ['sPercent', 'sCode', 'Type'],
        orClauseFieldsIdsIgnoreCase: ['sPercent', 'sCode', 'Type'],
      },
    };
  }
  const x = {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    fromDateTime: data?.fromDateTime ? data?.fromDateTime.slice(0, 10) : '',
    toDateTime: data?.toDateTime ? data?.toDateTime.slice(0, 10) : '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    orderNumber: data?.orderNumber || 0,
    taxSettings: taxSettings,
    allocationTaxGroupsImportSetting:
      data?.allocationTaxGroupsImportSetting ??
      allocationTaxGroupsImportSetting.parse({
        allocationTaxGroupsId: data?.id || '',
        organisationId: data?.organisationId || '',
        importClearThreshold: -1,
      }),
  };
  // Object.assign(x, {  });
  return x;
}

export default {
  parse,
  toAPI: toAPI,
};
