export interface Organisation {
  id: string;
  organisationId: string;
  allocationFrameId: string;
  ownerId: string;
  name: string;
  contact: string;
  splitBookingAccount: string;
  type: number;
  filesSize: number;
  nextDocumentNumber: number;
  isDemo: boolean;
  blocked: boolean;
  deleted: boolean;
  ocrFallbackParserActive: boolean;
  ftpPassword: string;
  addressIdentificationType: string;
  createTimeStamp: number;
  changeTimeStamp: number;
  allocationsLastDate: string; //DateTime
  createdAt: string; //DateTime?
  updatedAt: string; //DateTime?
  allocationsLockMonth: number | null;
  allocationsLockYear?: number;

  processesFolderId: number;

  processListsActive: boolean;
  documentFilesExportsActive: boolean;
  owner: any;

  kundenNummer: number;
  userPackages: any;
  totalSpace: number;
  totalSpaceForMain: number;
  totalFileSizeForMain: number;
  activeUsersCount: number;
  folderOpenType: number;
  gobdBlueIsProofed?: boolean;
  createdAtString: string;
}

export interface Value {
  value: Organisation[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
  '@odata.nextLink'?: string;
}
// init api object
function toAPI(data: Partial<Organisation>): any {
  //TODO need to check what correct fields list need update
  // in edit form we have :  addressStreet,addressZipCode,addressLocale,addressPhone,addressFax,addressContact
  const result: any = {
    Id: data?.id || undefined,
    // OrganisationId: data?.organisationId || undefined,
    // AllocationFrameId: data?.allocationFrameId || undefined,
    // OwnerId: data?.ownerId || undefined,
    // SplitBookingAccount: data?.splitBookingAccount || '',
    // Type: data?.type || 0,
    // FilesSize: data?.filesSize || 0,
    // NextDocumentNumber: data?.nextDocumentNumber || 0,
    // IsDemo: data?.isDemo || false,
    // Blocked: data?.blocked || false,
    // Deleted: data?.deleted || false,
    // OcrFallbackParserActive: data?.ocrFallbackParserActive || false,
    // FtpPassword: data?.ftpPassword || '',
    // CreateTimeStamp: data?.changeTimeStamp || 0,
    // ChangeTimeStamp: data?.changeTimeStamp || 0,
    // AllocationsLastDate: data?.allocationsLastDate || undefined, //DateTime
    // CreatedAt: data?.createdAt || undefined, //DateTime?
    // UpdatedAt: data?.updatedAt || undefined, //DateTime?
    // AllocationsLockMonth: data?.allocationsLockMonth || undefined,
    // AllocationsLockYear: data?.allocationsLockYear == 0 ? 0 : data?.allocationsLockYear || undefined,

    // ProcessesFolderId: data?.processesFolderId || 0,

    // ProcessListsActive: data?.processListsActive || false,
    // DocumentFilesExportsActive: data?.documentFilesExportsActive || false,
  };
  if (data?.addressIdentificationType !== undefined) result.AddressIdentificationType = data?.addressIdentificationType;
  if (result.Id) result['Id@odata.type'] = '#Guid';
  if (data?.name !== undefined) result.Name = data?.name ?? '';
  if (data?.contact !== undefined) result.Contact = data?.contact ?? '';
  if (data?.isDemo !== undefined) result.IsDemo = data?.isDemo;
  if (data?.folderOpenType !== undefined) result.FolderOpenType = data?.folderOpenType;
  if (data?.splitBookingAccount !== undefined) result.splitBookingAccount = data?.splitBookingAccount ?? '';

  return result;
}
function parse(data?: Partial<Organisation>): Organisation {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    allocationFrameId: data?.allocationFrameId || '',
    ownerId: data?.ownerId || '',
    name: data?.name || '',
    contact: data?.contact || '',
    splitBookingAccount: data?.splitBookingAccount || '',
    type: data?.type || 0,
    filesSize: data?.filesSize || 0,
    nextDocumentNumber: data?.nextDocumentNumber || 0,
    isDemo: data?.isDemo || false,
    blocked: data?.blocked || false,
    deleted: data?.deleted || false,
    ocrFallbackParserActive: data?.ocrFallbackParserActive || false,
    ftpPassword: data?.ftpPassword || '',
    addressIdentificationType: data?.addressIdentificationType || '',
    createTimeStamp: data?.changeTimeStamp || 0,
    changeTimeStamp: data?.changeTimeStamp || 0,
    allocationsLastDate: data?.allocationsLastDate || '', //DateTime
    createdAt: data?.createdAt || '', //DateTime?
    updatedAt: data?.updatedAt || '', //DateTime?
    allocationsLockMonth: data?.allocationsLockMonth || null,
    allocationsLockYear: data?.allocationsLockYear,

    processesFolderId: data?.processesFolderId || 0,

    processListsActive: data?.processListsActive || false,
    documentFilesExportsActive: data?.documentFilesExportsActive || false,
    owner: data?.owner || null,

    kundenNummer: data?.kundenNummer || 0,
    userPackages: data?.userPackages || null,
    totalSpace: data?.totalSpace || 0,
    totalSpaceForMain: data?.totalSpaceForMain || 0,
    totalFileSizeForMain: data?.totalFileSizeForMain || 0,
    activeUsersCount: data?.activeUsersCount || 0,
    folderOpenType: data?.folderOpenType ?? 0,
    gobdBlueIsProofed: data?.gobdBlueIsProofed,
    createdAtString: data?.createdAtString || '',
  };
}
export default {
  parse,
  toAPI: toAPI,
};
