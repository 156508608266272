import './class-component-hooks';

import Vue from 'vue';
import App from './views/app/App.vue';
import './registerServiceWorker';
import router from './router';
import store from './shared/store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { Logger } from 'fsts';
import { func } from './func';
import QuestionHub from './plugins/BackendHub';
import IdleVue from 'idle-vue';

Vue.use(QuestionHub);
Vue.config.productionTip = false;

// Importing the global scss file
import '@/assets/styles/index.scss';
import DateUtils from './shared/utils/DateUtils';
import NumberUtils from './shared/utils/NumberUtils';

// register global component
{
  Vue.component('Dialogbox', require('./components/dialogs/dialogBox/dialogBox.vue').default);

  Vue.prototype.$confirm = {
    open: function (titel: string, message: string, options?: object) {},
  };

  Vue.prototype.$message = {
    open: function (titel: string, message: string, options?: object) {},
  };

  Vue.prototype.$func = func;
}

// set log level in development mode to debug
if (process.env.NODE_ENV == 'development') Logger.LOG_LEVEL = 'DEBUG';

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 15, // 15 minets
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
  keepTracking: true,
});

Vue.filter('formatDate', function (value: Date) {
  if (value) {
    return DateUtils.formatDate(value);
  }
});
Vue.filter('formatDateCurrentLocale', function (value: any) {
  if (value) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(value, i18n.locale.toLowerCase(), true);
  }
});
Vue.filter('formatNumber2AfterDot', function (value: any) {
  if (value) {
    return NumberUtils.addZeroes(value, i18n.locale, 2);
  }
});
Vue.filter('highlight', function (value: any, query: any) {
  if (!value) return;
  return value.replace(new RegExp(query, 'ig'), "<span class='search-results'>$&</span>");
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
