import NumberUtils from '@/shared/utils/NumberUtils';
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component({ name: 'number-field', components: {} })
export default class NumberField extends Vue {
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;
  @Prop({ default: 'Label', type: String })
  private modelLabel!: string;
  @Prop({ default: 'Description', type: String })
  private modelDescription!: string;
  @Prop({ default: 'modelId', type: String })
  private modelId!: string;
  @Prop({ default: 6, type: Number })
  private colsTitle!: number;
  @Prop({ default: 6, type: Number })
  private colsValue!: number;
  @Prop({ default: 'text-right', type: String })
  private TextAlign!: string;

  @PropSync('value', {
    default: 0,
    type: [Number, String],
  })
  private modelValue!: string | number;
  get numberRules() {
    return [(v: any) => (v && NumberUtils.isNumeric(v)) || this.$t('is_not_a_number').toString()];
  }
  async onChange(event: any) {
    const valid = await (this.$refs[this.modelId] as any)?.validate();
    if (valid) {
      this.$emit('change', event, Number.parseInt(this.modelValue + ''));
    }
  }

  private onKyeUp(event: any) {
    this.$emit('keyup', event);
  }

  async onBlur() {}
  hideDetails() {
    const x = this.$refs[this.modelId] as any;
    return x?.validationState != 'error';
  }
}
