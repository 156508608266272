import { ActionTree } from 'vuex';
import { FileUploadState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import fileUpload, { FileUpload } from '@/shared/model/fileUpload';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.files');
export const actions: ActionTree<FileUploadState, RootState> = {
  updateFileUpload({ commit, dispatch }, fileUpload: FileUpload) {
    commit('setFileUpload', fileUpload);
  },
};
