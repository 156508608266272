import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentEditSession, { DocumentEditSession, Value } from '../model/documentEditSession';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentEditSession');
export interface BackendDocumentEditSession {
  getDocumentEditSession(id: string): AxiosPromise<DocumentEditSession>;
  getDocumentEditSessions: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDocumentEditSession(id: string): AxiosPromise;
  updateDocumentEditSession(DocumentEditSession: DocumentEditSession): AxiosPromise<any>;
}

export const defaultBackendDocumentEditSession: BackendDocumentEditSession = {
  getDocumentEditSession(id: string): AxiosPromise<DocumentEditSession> {
    const url = `${URLS.documentEditSessionOdata}/${id}`;
    return instance.get<DocumentEditSession>(url);
  },
  getDocumentEditSessions(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentEditSessionOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDocumentEditSessions${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentEditSession(id: string): AxiosPromise {
    logger.debug('deleteDocumentEditSession');
    return instance.delete(`${URLS.documentEditSession}/${id}`);
  },
  updateDocumentEditSession(DocumentEditSession: DocumentEditSession): AxiosPromise<any> {
    logger.debug('updateDocumentEditSession');
    return instance.put<DocumentEditSession>(
      `${URLS.documentEditSession}/update`,
      documentEditSession.toAPI(DocumentEditSession)
    );
  },
};
