import { GetterTree } from 'vuex';
import { RoleLoginTimeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RoleLoginTimeState, RootState> = {
  ['getRoleLoginTimes']: (state) => state.roleLoginTimes,
  ['getRoleLoginTimesIsLoading']: (state) => state.roleLoginTimes?.isLoading,
  ['getRoleLoginTimesTotal']: (state) => state.roleLoginTimes?.total,
  ['getRoleLoginTimesSearchParams']: (state) => state.roleLoginTimes?.searchParams,
};
