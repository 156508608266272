import { MutationTree } from 'vuex';
import { ExportState } from './types';
import Vue from 'vue';
import export_, { Export, Value } from '@/shared/model/export';

export const mutations: MutationTree<ExportState> = {
  setExports(state, payload: Value) {
    state.exports.items = payload.value.map((x) => export_.parse(x));
    state.exports.total = payload['@odata.count'] || 0;
    state.exports.isLoading = false;
  },
  setExportsTotal(state, payload: number) {
    state.exports.total = payload;
  },
  setExportsIsLoading(state, payload: boolean) {
    state.exports.isLoading = payload;
  },
};
