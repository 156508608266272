import { render, staticRenderFns } from "./menu-document-list.html?vue&type=template&id=24e15e1f&scoped=true&external"
import script from "./menu-document-list.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-list.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-list.scss?vue&type=style&index=0&id=24e15e1f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24e15e1f",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-list.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-list%2Fmenu-document-list.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VChip,VCol,VIcon,VList,VListItem,VListItemTitle,VMenu,VNavigationDrawer,VProgressLinear,VRow,VSelect,VSwitch,VTextField})
