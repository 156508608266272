import organisationDocumentSetting from '@/shared/model/organisationDocumentSetting';
import { OrganisationDocumentSettingState } from './types';

export const state = (): OrganisationDocumentSettingState => initialState();

export const initialState = (): OrganisationDocumentSettingState => ({
  current: organisationDocumentSetting.parse({}),
  organisationDocumentSettings: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 100,
        sortBy: ['CreatedAt'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  isUpdating: false,
});
