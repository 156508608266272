import TimeSpanUtils from '../utils/TimeSpanUtil';

export interface UserPackage {
  id: string; // Guid
  userId: string; // Guid
  organisationId: string; // Guid
  name: string; // string
  count: number; // int
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: UserPackage[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<UserPackage>): any {
  const result: any = {
    Id: data?.id,
  };
  if (result.Id !== undefined) result['Id@odata.type'] = '#Guid';
  if (result.UserId != undefined) {
    result.UserId = data?.userId;
    result['UserId@odata.type'] = '#Guid';
  }
  if (result.OrganisationId != undefined) {
    result.OrganisationId = data?.organisationId;
    result['OrganisationId@odata.type'] = '#Guid';
  }

  if (result.Name != undefined) result.Name = data?.name;
  if (result.Count != undefined) result.Count = data?.count;

  if (result.CreatedAt != undefined) {
    result.CreatedAt = data?.createdAt;
    result['CreatedAt@odata.type'] = '#TimeOfDay';
  }

  if (result.UpdatedAt != undefined) {
    result.UpdatedAt = data?.updatedAt;
    result['UpdatedAt@odata.type'] = '#TimeOfDay';
  }

  return result;
}

function parse(data?: Partial<UserPackage>): UserPackage {
  return {
    id: data?.id || '',
    userId: data?.userId || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    count: data?.count || 0,
    createdAt: TimeSpanUtils.fromAPIString(data?.createdAt || ''),
    updatedAt: TimeSpanUtils.fromAPIString(data?.updatedAt || ''),
  };
}

export default {
  parse,
  toAPI: toAPI,
};
