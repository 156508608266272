export interface DocumentStatus {
  id: string;
  organizationId: string;
  createdDate: string;
  name: string;
  statusValue: string;
  allowExport: boolean;
}
export enum DocumentStatusValues {
  New = 'new',
  NewInFolder = 'new_in_folder',
  Payed = 'payed',
  Accepted = 'accepted',
  Allocated = 'allocated',
  Documented = 'documented',
  FinalCheck = 'final_check',
  Hasgobd = 'hasgobd',
  Extra1 = 'extra1',
  Extra2 = 'extra2',
  Extra3 = 'extra3',
  Extra4 = 'extra4',
  Extra5 = 'extra5',
  Extra6 = 'extra6',
  Extra7 = 'extra7',
  Extra8 = 'extra8',
  Extra9 = 'extra9',
  Extra10 = 'extra10',
  Extra11 = 'extra11',
  Extra12 = 'extra12',
}
export interface Value {
  value: DocumentStatus[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
// init api object
function toAPI(data: Partial<DocumentStatus>): any {
  return {
    Id: data?.id || undefined,
    OrganizationId: data?.organizationId || undefined,
    CreatedDate: data?.createdDate || undefined,

    Name: data?.name || '',
    StatusValue: data?.statusValue || '',
    AllowExport: data?.allowExport || false,
  };
}
function parse(data?: Partial<DocumentStatus>): DocumentStatus {
  return {
    id: data!.id || '',
    organizationId: data?.organizationId || '',
    createdDate: data?.createdDate || '',
    name: data!.name || '',
    statusValue: data?.statusValue || '',
    allowExport: data!.allowExport || false,
  };
}
export default {
  parse,
  toAPI: toAPI,
};
