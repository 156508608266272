import { GetterTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<UserState, RootState> = {
  ['getUsers']: (state) => state.users,
  ['getUsersIsLoading']: (state) => state.users?.isLoading,
  ['getUsersTotal']: (state) => state.users?.total,
  ['getUsersSearchParams']: (state) => state.users?.searchParams,

  ['getQaUsers']: (state) => state.qaUsers,
  ['getQaUsersIsLoading']: (state) => state.qaUsers?.isLoading,
  ['getQaUsersTotal']: (state) => state.qaUsers?.total,
  ['getQaUsersSearchParams']: (state) => state.qaUsers?.searchParams,

  ['getUserRights']: (state) => state.userRights,
  ['getUserRightsIsLoading']: (state) => state.userRights?.isLoading,
  ['getUserRightsTotal']: (state) => state.userRights?.total,
  ['getUserRightsSearchParams']: (state) => state.userRights?.searchParams,

  ['getUpdateUserRightsIsLoading']: (state) => state.updateUserRightsIsLoading,
  ['getRegisterUserInProcess']: (state) => state.registerUserInProcess,
  ['getIsUserEmailExistInProgress']: (state) => state.isUserEmailExistInProgress,
};
