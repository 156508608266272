import { render, staticRenderFns } from "./left-menu.html?vue&type=template&id=2507f290&scoped=true&external"
import script from "./left-menu.ts?vue&type=script&lang=ts&external"
export * from "./left-menu.ts?vue&type=script&lang=ts&external"
import style0 from "./left-menu.scss?vue&type=style&index=0&id=2507f290&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2507f290",
  null
  
)

/* custom blocks */
import block0 from "./left-menu.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fapp%2Fleft-menu%2Fleft-menu.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAutocomplete,VBadge,VBtn,VIcon,VImg,VList,VListItem,VListItemAvatar,VListItemIcon,VListItemTitle,VNavigationDrawer,VTreeview})
