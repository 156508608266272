import { MutationTree } from 'vuex';
import { AllocationsExportAllocationState } from './types';
import Vue from 'vue';
import allocationsExportAllocation, {
  AllocationsExportAllocation,
  Value,
} from '@/shared/model/allocationsExportAllocation';

export const mutations: MutationTree<AllocationsExportAllocationState> = {
  setAllocationsExportAllocations(state, payload: Value) {
    state.allocationsExportAllocations.items = payload.value.map((x) => allocationsExportAllocation.parse(x));
    state.allocationsExportAllocations.total = payload['@odata.count'] || 0;
    state.allocationsExportAllocations.isLoading = false;
  },
  setAllocationsExportAllocationsData(state, payload: AllocationsExportAllocation[]) {
    state.allocationsExportAllocations.items = payload;
    state.allocationsExportAllocations.total = payload.length || 0;
    state.allocationsExportAllocations.isLoading = false;
  },
  setAllocationsExportAllocationsTotal(state, payload: number) {
    state.allocationsExportAllocations.total = payload;
  },
  setAllocationsExportAllocationsIsLoading(state, payload: boolean) {
    state.allocationsExportAllocations.isLoading = payload;
  },
};
