import { MutationTree } from 'vuex';
import { AllocationBrandsImportSettingState } from './types';
import Vue from 'vue';
import allocationBrandsImportSetting, {
  AllocationBrandsImportSetting,
  Value,
} from '@/shared/model/allocationBrandsImportSetting';

export const mutations: MutationTree<AllocationBrandsImportSettingState> = {
  setAllocationBrandsImportSettings(state, payload: Value) {
    state.allocationBrandsImportSettings.items = payload.value.map((x) => allocationBrandsImportSetting.parse(x));
    state.allocationBrandsImportSettings.total = payload['@odata.count'] || 0;
    state.allocationBrandsImportSettings.isLoading = false;
  },
  setAllocationBrandsImportSettingsTotal(state, payload: number) {
    state.allocationBrandsImportSettings.total = payload;
  },
  setAllocationBrandsImportSettingsIsLoading(state, payload: boolean) {
    state.allocationBrandsImportSettings.isLoading = payload;
  },
  setAllocationBrandsImportSetting(state, payload: Value) {
    state.allocationBrandsImportSetting = Object.assign({}, allocationBrandsImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateAllocationBrandsImportSetting(state, payload: boolean) {
    state.inProgressUpdateAllocationBrandsImportSetting = payload;
  },
};
