import { MutationTree } from 'vuex';
import { AllocationTemplatePropertyState } from './types';
import Vue from 'vue';
import allocationTemplateProperty, {
  AllocationTemplateProperty,
  Value,
} from '@/shared/model/allocationTemplateProperty';

export const mutations: MutationTree<AllocationTemplatePropertyState> = {
  setAllocationTemplatePropertys(state, payload: Value) {
    state.allocationTemplatePropertys.items = payload.value.map((x) => allocationTemplateProperty.parse(x));
    state.allocationTemplatePropertys.total = payload['@odata.count'] || 0;
    state.allocationTemplatePropertys.isLoading = false;
  },
  setAllocationTemplatePropertysTotal(state, payload: number) {
    state.allocationTemplatePropertys.total = payload;
  },
  setAllocationTemplatePropertysIsLoading(state, payload: boolean) {
    state.allocationTemplatePropertys.isLoading = payload;
  },
};
