import { AutoGobdCheckTermState } from './types';

export const state = (): AutoGobdCheckTermState => initialState();

export const initialState = (): AutoGobdCheckTermState => ({
  autoGobdCheckTerms: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'documentStatusName',
        'priority',
        'searchTerm1',
        'searchTerm2',
        'searchTerm3',
        'searchTerm4',
        'searchTerm5',
      ],
      orClauseFieldsIdsConvertToString: ['priority'],
      orClauseFieldsIdsIgnoreCase: [
        'documentStatusName',
        'searchTerm1',
        'searchTerm2',
        'searchTerm3',
        'searchTerm4',
        'searchTerm5',
      ],
    },
  },
  isRunAutoGobdCheckTerms: [],
});
