import { Dictionary } from 'vue-router/types/router';
import organization from '@/shared/model/organisation';
import { OrganizationState } from './types';

export const state = (): OrganizationState => initialState();

export const initialState = (): OrganizationState => ({
  inProgressUpdateCurrentOrganization: false,
  currentOrganization: organization.parse({}),
  organizations: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['owner/Email', 'name'],
      orClauseFieldsIdsConvertToString: [''],
      orClauseFieldsIdsIgnoreCase: ['owner/Email', 'name'],
    },
  },
  organizationsAll: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'name',
        'kundenNummer',
        'Owner/kundenNummer',
        'Owner/firstName',
        'Owner/lastName',
        'owner/email',
        'owner/phoneNumber',
        'createdAtString',
      ],
      orClauseFieldsIdsConvertToString: ['Owner/kundenNummer'],
      orClauseFieldsIdsIgnoreCase: ['Owner/firstName', 'Owner/lastName', 'owner/email', 'owner/phoneNumber'],
    },
  },
  organizationsAllForMain: {},
  organizationUsersAdmPage: {},
  organizationFoldersAll: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isBuiltIn', 'name'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
    treeView: [],
    treeViewFlat: [],
  },
  resetInProgress: false,
  organisationUsers: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Email', 'ShortName', 'FirstName', 'LastName', 'RoleName', 'EmployeeNumber'],
      orClauseFieldsIdsIgnoreCase: ['Email', 'ShortName', 'FirstName', 'LastName', 'RoleName', 'EmployeeNumber'],
    },
  },
  organisationUsersAll: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['LastName'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Email', 'ShortName', 'FirstName', 'LastName', 'RoleName', 'EmployeeNumber'],
      orClauseFieldsIdsIgnoreCase: ['Email', 'ShortName', 'FirstName', 'LastName', 'RoleName', 'EmployeeNumber'],
    },
  },
  inProgressUpdateOrganisationUser: false,
  inProgressUpdateToMain: false,
});
