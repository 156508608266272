import { MutationTree } from 'vuex';
import { FilePagesTextState } from './types';
import Vue from 'vue';
import filePagesText, { FilePagesText, Value } from '@/shared/model/filePagesText';

export const mutations: MutationTree<FilePagesTextState> = {
  setFilePagesTexts(state, payload: Value) {
    state.filePagesTexts.items = payload.value.map((x) => filePagesText.parse(x));
    state.filePagesTexts.total = payload['@odata.count'] || 0;
    state.filePagesTexts.isLoading = false;
  },
  setFilePagesTextsTotal(state, payload: number) {
    state.filePagesTexts.total = payload;
  },
  setFilePagesTextsIsLoading(state, payload: boolean) {
    state.filePagesTexts.isLoading = payload;
  },
};
