import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tradeChannelImportSetting, { TradeChannelImportSetting, Value } from '../model/tradeChannelImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TradeChannelImportSetting');
export interface BackendTradeChannelImportSetting {
  getTradeChannelImportSetting(id: string): AxiosPromise<TradeChannelImportSetting>;
  getTradeChannelImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteTradeChannelImportSetting(id: string): AxiosPromise;
  updateTradeChannelImportSetting(TradeChannelImportSetting: TradeChannelImportSetting): AxiosPromise<any>;
}

export const defaultBackendTradeChannelImportSetting: BackendTradeChannelImportSetting = {
  getTradeChannelImportSetting(id: string): AxiosPromise<TradeChannelImportSetting> {
    const url = `${URLS.tradeChannelImportSettingOdata}/${id}`;
    return instance.get<TradeChannelImportSetting>(url);
  },
  getTradeChannelImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.tradeChannelImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTradeChannelImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteTradeChannelImportSetting(id: string): AxiosPromise {
    logger.debug('deleteTradeChannelImportSetting');
    return instance.delete(`${URLS.tradeChannelImportSetting}/${id}`);
  },
  updateTradeChannelImportSetting(TradeChannelImportSetting: TradeChannelImportSetting): AxiosPromise<any> {
    logger.debug('updateTradeChannelImportSetting');
    return instance.put<TradeChannelImportSetting>(
      `${URLS.tradeChannelImportSetting}/update`,
      tradeChannelImportSetting.toAPI(TradeChannelImportSetting)
    );
  },
};
