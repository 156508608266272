import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ name: 'filtering-buttons', components: {} })
export default class FilteringButtons extends Vue {
  // simple string for `menu-process` (TODO:(ED-385) redo later to be also as headerObject)
  @Prop({ default: '', type: String })
  private header!: string; // Guid ID too long for top
  @Prop({ default: 'all', type: String })
  private searchType!: string; // Guid ID too long for top
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({
    default: () => {
      return { name: '', statusValue: '' };
    },
    type: Object,
  })
  private headerObject!: { name: string; statusValue: string }; // Guid ID too long for top

  // (ED-385) In `filtering-buttons` markup (HTML) we shows the `Name` (can be changed in settings), but on the backend we should send `StatusValue`(fixed value, doesn't change when you rename Status `Name` in the site settings)
  private changeValue(e: any) {
    this.$emit('change:value', e, this.headerObject.statusValue);
  }
}
