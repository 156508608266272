import { Document } from '@/shared/model/document';
import { GobdData } from '@/shared/model/gobdData';

const keysForChecking: (keyof GobdData)[] = [
  'amount',
  'amountVerified',
  'invoiceAddressVerified',
  'invoiceDate',
  'invoiceNumber',
  'performanceDateVerified',
  'taxNumberVerified',
];

const isNotEmpty = (value: boolean | string): boolean => {
  if (typeof value === 'undefined') return false;
  return !!value;
};

export default class GobdHelper {
  //GOBD
  public static checkIfAllGobdBackendFieldsFilledForDocument(document?: Document) {
    if (!document) return false;
    if (!document.documentGobdDatum?.id) return false;

    if (!document.documentGobdDatum.amount) return false;
    if (!document.documentGobdDatum.amountVerified) return false;
    if (!document.documentGobdDatum.invoiceAddressVerified) return false;
    if (!document.documentGobdDatum.invoiceDate) return false;
    if (!document.documentGobdDatum.invoiceNumber) return false;
    if (!document.documentGobdDatum.performanceDateVerified) return false;
    if (!document.documentGobdDatum.taxNumberVerified) return false;

    return true;
  }

  public static checkIfAllGobdBackendFieldsVerifiedForGreen(document?: Document) {
    if (!document) return false;
    if (!document.documentGobdDatum?.id) return false;

    if (!document.documentGobdDatum.amountVerified) return false;
    if (!document.documentGobdDatum.invoiceAddressVerified) return false;
    if (!document.documentGobdDatum.performanceDateVerified) return false;
    if (!document.documentGobdDatum.taxNumberVerified) return false;

    if (!document.isDocumentStateHasGobdGreen) return false;

    return true;
  }

  public static checkIfGobdYellow(document?: Document) {
    if (!document) return false;
    if (document.isDocumentStateHasGobdGreen) return false;
    if (document.isDocumentStateHasGobdBlue) return false;
    if (!document.isDocumentStateHasGobdYellow) return false;
    return true;
  }

  public static checkIfGobdGreen(document?: Document) {
    if (!document) return false;
    if (!document.isDocumentStateHasGobdGreen) return false;
    return true;
  }

  public static checkIfGobdBlue(document?: Document) {
    if (!document) return false;
    if (document.isDocumentStateHasGobdGreen) return false;
    if (!document.isDocumentStateHasGobdBlue) return false;
    return true;
  }
}
