import { CostObjectiveState } from './types';

export const state = (): CostObjectiveState => initialState();

export const initialState = (): CostObjectiveState => ({
  costObjectives: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sValue'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Name', 'sValue'],
      orClauseFieldsIdsIgnoreCase: ['Name', 'sValue'],
    },
  },
  isImporting: false,
});
