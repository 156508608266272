import { render, staticRenderFns } from "./login.html?vue&type=template&id=b4081304&scoped=true&external"
import script from "./login.ts?vue&type=script&lang=ts&external"
export * from "./login.ts?vue&type=script&lang=ts&external"
import style0 from "./login.scss?vue&type=style&index=0&id=b4081304&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4081304",
  null
  
)

/* custom blocks */
import block0 from "./login.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Flogin%2Flogin.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VForm})
