import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import filePagesText, { FilePagesText, Value } from '../model/filePagesText';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.FilePagesText');
export interface BackendFilePagesText {
  getFilePagesText(id: string): AxiosPromise<FilePagesText>;
  getFilePagesTexts: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteFilePagesText(id: string): AxiosPromise;
  updateFilePagesText(FilePagesText: FilePagesText): AxiosPromise<any>;
}

export const defaultBackendFilePagesText: BackendFilePagesText = {
  getFilePagesText(id: string): AxiosPromise<FilePagesText> {
    const url = `${URLS.filePagesTextOdata}/${id}`;
    return instance.get<FilePagesText>(url);
  },
  getFilePagesTexts(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.filePagesTextOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getFilePagesTexts${url}`);
    return instance.get<Value>(url);
  },

  deleteFilePagesText(id: string): AxiosPromise {
    logger.debug('deleteFilePagesText');
    return instance.delete(`${URLS.filePagesText}/${id}`);
  },
  updateFilePagesText(FilePagesText: FilePagesText): AxiosPromise<any> {
    logger.debug('updateFilePagesText');
    return instance.put<FilePagesText>(`${URLS.filePagesText}/update`, filePagesText.toAPI(FilePagesText));
  },
};
