import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import supplier, { Supplier, Value } from '../model/supplier';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Supplier');
export interface BackendSupplier {
  getSupplier(id: string): AxiosPromise<Supplier>;
  getSupplierByName(name: string): AxiosPromise<Supplier>;
  getSuppliers: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteSupplier(id: string): AxiosPromise;
  updateSupplier(Supplier: Supplier): AxiosPromise<any>;
  uploadSupplier(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any>;
}

export const defaultBackendSupplier: BackendSupplier = {
  getSupplier(id: string): AxiosPromise<Supplier> {
    const url = `${URLS.supplierOdata}/${id}`;
    return instance.get<Supplier>(url);
  },
  getSupplierByName(name: string): AxiosPromise<Supplier> {
    const url = `${URLS.supplier}/GetByName?name=${encodeURIComponent(name)}`;
    return instance.get<Supplier>(url);
  },
  getSuppliers(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.supplierOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase,
      searchParams.orClauseFieldsIdsGrateThan
    );
    logger.log(`getSuppliers${url}`);
    return instance.get<Value>(url);
  },

  deleteSupplier(id: string): AxiosPromise {
    logger.debug('deleteSupplier');
    return instance.delete(`${URLS.supplier}/${id}`);
  },
  updateSupplier(Supplier: Supplier): AxiosPromise<any> {
    logger.debug('updateSupplier');
    return instance.put<Supplier>(`${URLS.supplier}/update`, supplier.toAPI(Supplier));
  },
  uploadSupplier(uploadInfo: {
    fileForImport: any;
    organisationId: string;
    onUploadProgress: ((progressEvent: any) => void) | undefined;
  }): AxiosPromise<any> {
    const formData = new FormData();

    formData.append('FileForImport', uploadInfo.fileForImport, uploadInfo.fileForImport.name);
    formData.append('OrganisationId', uploadInfo.organisationId);

    return instance.post<any>(`${URLS.supplier}/post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadInfo.onUploadProgress,
    });
  },
};
