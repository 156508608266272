import { MutationTree } from 'vuex';
import { OrganisationAllocationExportPropertyState } from './types';
import Vue from 'vue';
import organisationAllocationExportProperty, {
  OrganisationAllocationExportProperty,
  Value,
} from '@/shared/model/organisationAllocationExportProperty';

export const mutations: MutationTree<OrganisationAllocationExportPropertyState> = {
  setOrganisationAllocationExportProperties(state, payload: Value) {
    state.organisationAllocationExportProperties.items = payload.value.map((x) =>
      organisationAllocationExportProperty.parse(x)
    );
    state.organisationAllocationExportProperties.total = payload['@odata.count'] || 0;
    state.organisationAllocationExportProperties.isLoading = false;
  },
  setOrganisationAllocationExportPropertiesTotal(state, payload: number) {
    state.organisationAllocationExportProperties.total = payload;
  },
  setOrganisationAllocationExportPropertiesIsLoading(state, payload: boolean) {
    state.organisationAllocationExportProperties.isLoading = payload;
  },
  setIsUpdating(state, payload: boolean) {
    state.isUpdating = payload;
  },
};
