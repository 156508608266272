import { MutationTree } from 'vuex';
import { DocumentFileState } from './types';
import Vue from 'vue';
import documentFile, { DocumentFile, Value } from '@/shared/model/documentFile';

export const mutations: MutationTree<DocumentFileState> = {
  setDocumentFiles(state, payload: Value) {
    state.documentFiles.items = payload.value.map((x) => documentFile.parse(x));
    state.documentFiles.total = payload['@odata.count'] || 0;
    state.documentFiles.isLoading = false;
  },
  setDocumentFilesTotal(state, payload: number) {
    state.documentFiles.total = payload;
  },
  setDocumentFilesIsLoading(state, payload: boolean) {
    state.documentFiles.isLoading = payload;
  },
  setDocumentFileIsLoading(state, payload: boolean) {
    state.documentFileIsLoading = payload;
  },
};
