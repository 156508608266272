import { GetterTree } from 'vuex';
import { AllocationState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationState, RootState> = {
  ['getAllocations']: (state) => state.allocations,
  ['getAllocationList']: (state) => state.allocationList,
  ['getAllocationPropertyEx']: (state) => state.properties,
  ['getAllocationsIsLoading']: (state) => state.allocations?.isLoading,
  ['getAllocationsTotal']: (state) => state.allocations?.total,
  ['getAllocationsSearchParams']: (state) => state.allocations?.searchParams,
  ['getAllocationCurrencies']: (state) => {
    return [
      { name: 'CHF', value: 'CHF' },
      { name: 'GBP', value: 'GBP' },
      { name: 'USD', value: 'USD' },
      { name: 'AUD', value: 'AUD' },
      { name: 'BGN', value: 'BGN' },
      { name: 'BRL', value: 'BRL' },
      { name: 'CAD', value: 'CAD' },
      { name: 'CNY', value: 'CNY' },
      { name: 'CZK', value: 'CZK' },
      { name: 'DKK', value: 'DKK' },
      { name: 'EUR', value: 'EUR' },
      { name: 'HKD', value: 'HKD' },
      { name: 'HRK', value: 'HRK' },
      { name: 'HUF', value: 'HUF' },
      { name: 'IDR', value: 'IDR' },
      { name: 'ILS', value: 'ILS' },
      { name: 'INR', value: 'INR' },
      { name: 'JPY', value: 'JPY' },
      { name: 'KRW', value: 'KRW' },
      { name: 'MXN', value: 'MXN' },
      { name: 'MYR', value: 'MYR' },
      { name: 'NOD', value: 'NOD' },
      { name: 'NZD', value: 'NZD' },
      { name: 'PHP', value: 'PHP' },
      { name: 'PLN', value: 'PLN' },
      { name: 'RON', value: 'RON' },
      { name: 'RUB', value: 'RUB' },
      { name: 'SEK', value: 'SEK' },
      { name: 'SGD', value: 'SGD' },
      { name: 'THB', value: 'THB' },
      { name: 'TRY', value: 'TRY' },
      { name: 'ZAR', value: 'ZAR' },
    ];
  },
};
