import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import documentReminder, { DocumentReminder, Value } from '../model/documentReminder';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DocumentReminder');
export interface BackendDocumentReminder {
  getDocumentReminder(id: string): AxiosPromise<DocumentReminder>;
  getDocumentReminders: (
    searchParams: SearchParams,
    organisationId: string,
    documentId?: string
  ) => AxiosPromise<Value>;
  deleteDocumentReminder(id: string): AxiosPromise;
  updateDocumentReminder(DocumentReminder: DocumentReminder): AxiosPromise<any>;
}

export const defaultBackendDocumentReminder: BackendDocumentReminder = {
  getDocumentReminder(id: string): AxiosPromise<DocumentReminder> {
    const url = `${URLS.documentReminderOdata}/${id}`;
    return instance.get<DocumentReminder>(url);
  },
  getDocumentReminders(searchParams: SearchParams, organisationId: string, documentId?: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    if (documentId) {
      odfb.eq(CONST.DocumentId, documentId, false);
    }
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentReminderOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=documentRemindersReceiverUsers']
      //['$expand=documentRemindersReceiverUsers($select=id,documentRemindersId,documentId,organisationId,userId,createdAt,updatedAt)',]
      //['$expand=documentRemindersReceiverUsers','$select=id,documentId,organisationId,days,times,subject,message,startAt,createdAt,updatedAt,documentRemindersReceiverUsers',]
    );

    logger.log(`getDocumentReminders${url}`);
    return instance.get<Value>(url);
  },

  deleteDocumentReminder(id: string): AxiosPromise {
    logger.debug('deleteDocumentReminder');
    return instance.delete(`${URLS.documentReminder}/${id}`);
  },
  updateDocumentReminder(DocumentReminder: DocumentReminder): AxiosPromise<any> {
    logger.debug('updateDocumentReminder');
    return instance.put<DocumentReminder>(`${URLS.documentReminder}/update`, documentReminder.toAPI(DocumentReminder));
  },
};
