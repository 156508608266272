import { GetterTree } from 'vuex';
import { DocumentStateState } from './types';
import { RootState } from '../../types';
import { DocumentState } from '@/shared/model/documentState';

export const getters: GetterTree<DocumentStateState, RootState> = {
  ['getDocumentStates']: (state) => state.documentStates,
  ['getStateId']: (state) => (name: string, ds?: DocumentState[]) => ds?.find((x) => x.name == name)?.id ?? '',
  ['getDocumentStatesIsLoading']: (state) => state.documentStates?.isLoading,
  ['getDocumentStatesTotal']: (state) => state.documentStates?.total,
  ['getDocumentStatesSearchParams']: (state) => state.documentStates?.searchParams,
  ['inProgressUpdateDocumentState']: (state) => state.inProgressUpdateDocumentState,
};
