import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import datevSetting, { DatevSetting, Value } from '../model/datevSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DatevSetting');
export interface BackendDatevSetting {
  getDatevSetting(id: string): AxiosPromise<DatevSetting>;
  getDatevSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDatevSetting(id: string): AxiosPromise;
  updateDatevSetting(DatevSetting: DatevSetting): AxiosPromise<any>;
}

export const defaultBackendDatevSetting: BackendDatevSetting = {
  getDatevSetting(id: string): AxiosPromise<DatevSetting> {
    const url = `${URLS.datevSettingOdata}/${id}`;
    return instance.get<DatevSetting>(url);
  },
  getDatevSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.datevSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDatevSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteDatevSetting(id: string): AxiosPromise {
    logger.debug('deleteDatevSetting');
    return instance.delete(`${URLS.datevSetting}/${id}`);
  },
  updateDatevSetting(DatevSetting: DatevSetting): AxiosPromise<any> {
    logger.debug('updateDatevSetting');
    return instance.put<DatevSetting>(`${URLS.datevSetting}/update`, datevSetting.toAPI(DatevSetting));
  },
};
