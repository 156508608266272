import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import comment, { Comment } from '@/shared/model/comment';
import { Document } from '@/shared/model/document';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('menu-document-notes');
const authModule = namespace('auth');
const commentModule = namespace('comment');
const documentModule = namespace('document');
// updateComment

@Component({ name: 'menu-document-notes', components: {} })
export default class MenuDocumentNotesView extends Vue {
  @commentModule.Action('getComments')
  private actionGetComments!: any;
  @commentModule.Action('updateComment')
  private actionUpdateComment!: any;

  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Action('updateDocumentNotesInState')
  private actionUpdateDocumentNotesInState!: any;

  @documentModule.Getter('getDocument')
  private getterDocument!: any;

  @authModule.Getter('getAccount')
  private getAccount!: any;
  get userEmail() {
    return this.getAccount?.profile?.name || 'user';
  }

  private comments: Array<Comment> = [];
  private comment: Comment = comment.parse({});

  get documentId() {
    return this.$route.params['documentId'];
  }

  async created() {
    await this.loadDocumentIfEmptyState();
  }

  private async loadDocumentIfEmptyState() {
    const doc: Document = this.getterDocument;
    // reuse the `document`(NOT userFile) from the Vuex State if it is currently opened document, otherwise load correct `document` from the backend
    if (doc?.id && doc.id === this.documentId) {
      this.comment.documentId = doc.id;
      await this.loadComments(doc.id);
    } else {
      await this.actionGetDocument(this.documentId)
        .then(async (result: any) => {
          this.comment.documentId = result.id;
          await this.loadComments(result.id);
          // console.log('object :>> ', object);
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private async loadComments(documentId: string) {
    const payload = { documentId };
    await this.actionGetComments(payload)
      .then((result: any) => {
        this.comments = result.value;
        this.actionUpdateDocumentNotesInState(result.value);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private createFileDate(item: Comment) {
    return item.createdAt ? DateUtils.dotDateTimeFromIsoDateTime(item.createdAt) : 'NO DATE';
  }

  private notLastMessage(index: number) {
    return index + 1 < this.comments.length;
  }

  private createNote() {
    if (!this.comment.message) return;
    this.comment.userId = this.getAccount?.profile?.sub;
    this.actionUpdateComment(this.comment)
      .then((result: any) => {
        this.loadComments(this.comment.documentId);
        //this.$emit('click:return');
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.$emit('click:return');
      });
  }

  private returnBack() {
    this.$emit('click:return');
  }
  translateComment(comment: string) {
    const copyFromOrgCommetRegexp = /^(Kopie aus )("[\w ]*")( von Dokument ID )(\d*)/g;
    let matchResult = comment.match(copyFromOrgCommetRegexp);
    if (matchResult == null) {
      return comment;
    }
    matchResult = copyFromOrgCommetRegexp.exec(comment);
    if (matchResult == null) {
      return comment;
    }
    return this.$t(matchResult[1] + matchResult[3], { company: matchResult[2], documentId: matchResult[4] });
  }
}
