export interface AllocationTaxSetting {
  id: string; // ulong
  organisationId: string; // string
  allocationTaxGroupsId: string; // long
  percent: number; // double
  code: number; // byte
  type: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: AllocationTaxSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationTaxSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    AllocationTaxGroupsId: data?.allocationTaxGroupsId || undefined,
    Percent: data?.percent || 0,
    Code: data?.code || 0,
    Type: data?.type || '',
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
  };
}

function parse(data?: Partial<AllocationTaxSetting>): AllocationTaxSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    allocationTaxGroupsId: data?.allocationTaxGroupsId || '',
    percent: data?.percent || 0,
    code: data?.code || 0,
    type: data?.type || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
