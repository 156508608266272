import { GetterTree } from 'vuex';
import { DocumentState } from './types';
import { RootState } from '../../types';
import ActionLogsHelper from '@/shared/utils/actionLogHelper';

export const getters: GetterTree<DocumentState, RootState> = {
  ['getSelectionMode']: (state) => state.selectionMode,
  ['getHasActiveSearch']: (state) => state.hasActiveSearch,
  ['getIsMetadataProcessing']: (state) => state.isMetadataProcessing,
  ['getDocumentSearchData']: (state) => state.documentSearchData,
  ['getDocumentSearchGobdStatus']: (state) => state.documentSearchGobdStatus,
  ['documentsSearchPayload']: (state) => state.documentsSearchPayload,
  ['getSelectedDocumentIds']: (state) => state.selectedDocumentIds,
  ['getDocument']: (state) => state.document,
  ['getDocumentTemp']: (state) => state.documentTemp,
  ['getDocumentIsLoading']: (state) => state.documentIsLoading,
  ['getDocuments']: (state) => state.documents,
  ['getDocumentsIsLoading']: (state) => state.documents?.isLoading,
  ['getDocumentsTotal']: (state) => state.documents?.total,
  ['getDocumentsAllCount']: (state) => state.documentsAllCount,
  ['getDocumentsDeleted']: (state) => state.documentsDeleted,
  ['getDocumentsDeletedIsLoading']: (state) => state.documentsDeleted?.isLoading,
  ['getDocumentsDeletedTotal']: (state) => state.documentsDeleted?.total,
  ['getDocumentsDeletedSearchParams']: (state) => state.documentsDeleted?.searchParams,
  ['isDocumentPropertiesLoadingForDocument']: (state) => (documentId: string) =>
    state.documentIdsForWhichDocumentPropertiesIsLoading.includes(documentId),
  ['getDocumentPropertiesSearchParams']: (state) => state.documentPropertiesSearchParams,
  ['documentsSearchUrl']: (state) => state.documentsSearchUrl,
  ['documentsSearchUrlOld']: (state) => state.documentsSearchUrlOld,
  ['lastLoadDocumentsDate']: (state) => state.lastLoadDocumentsDate,
  ['getSearchModes']: (state) => state.documentsSearchPayload.searchModes,
  ['getDocumentRoleRights']: (state) => state.documentRoleRights,
  ['getDocumentRoleRightsIsLoading']: (state) => state.documentRoleRights?.isLoading,
  ['getDocumentRoleRightsTotal']: (state) => state.documentRoleRights?.total,
  ['isUpdateInProgress']: (state) => state.updateInProgress,
  ['getDocumentsNeedUpdate']: (state) => state.documentsNeedUpdate,
  ['isDocumentsNeedUpdate']: (state) => state.isDocumentsNeedUpdate,
  ['isAllQuestionClosed']: (state) => ActionLogsHelper.IsAllQuestionClosed(state.document),
  ['isAllTaskClosed']: (state) => ActionLogsHelper.IsAllTaskClosed(state.document),
  ['isAllCircularClosed']: (state) => ActionLogsHelper.IsAllCircularClosed(state.document),
  ['hasOpenQuestion']: (state) => ActionLogsHelper.HasOpenQuestion(state.document),
  ['hasOpenTask']: (state) => ActionLogsHelper.HasOpenTask(state.document),
  ['hasOpenCircular']: (state) => ActionLogsHelper.HasOpenCircular(state.document),
};
