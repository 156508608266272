import { Document } from '@/shared/model/document';
import { ActionLog } from '@/shared/model/actionLog';
import DateUtils from './DateUtils';

export default class ActionLogsHelper {
  public static OpenQuestionDate(actionLogs?: ActionLog[]) {
    return (
      DateUtils.formatDate(
        actionLogs?.find(
          (x: ActionLog) =>
            x.threadType == 'question' && !x.closed && x.dueTimeStamp != null && x.dueTimeStamp.length > 0
        )?.dueTimeStamp || ''
      ) || ''
    );
  }
  public static getOpenQuestionDate(doc?: Document) {
    if (doc?.threadQuestion?.closed == false) return DateUtils.formatDate(doc?.threadQuestion?.dueTimeStamp ?? '');
    return '';
  }
  public static HasOpenQuestion(doc?: Document): boolean {
    if (doc?.threadQuestion?.actionLogId && doc?.threadQuestion.closed != true) return true;
    return false;
  }
  public static IsAllQuestionClosed(doc?: Document): boolean {
    if (doc?.threadQuestion?.actionLogId && doc?.threadQuestion.closed == true) return true;
    return false;
  }
  public static HasOpenTask(doc?: Document): boolean {
    if (doc?.threadTask?.actionLogId && doc?.threadTask.closed != true) return true;
    return false;
  }
  public static IsAllTaskClosed(doc?: Document): boolean {
    if (doc?.threadTask?.actionLogId && doc?.threadTask.closed == true) return true;
    return false;
  }
  public static HasOpenCircular(doc?: Document): boolean {
    if (doc?.threadCircular?.actionLogId && doc?.threadCircular.closed != true) return true;
    return false;
  }
  public static IsAllCircularClosed(doc?: Document): boolean {
    if (doc?.threadCircular?.actionLogId && doc?.threadCircular.closed == true) return true;
    return false;
  }
  public static OpenQuestionId(actionLogs?: ActionLog[]) {
    if (actionLogs === undefined) return undefined;
    const union: string[] = ActionLogsHelper.openId(actionLogs, 'question');
    if (union.length) return union[0];
  }
  public static getOpenQuestionId(doc?: Document) {
    if (doc?.threadQuestion?.closed == false) return doc?.threadQuestion?.id;
    return '';
  }
  public static ClosedQuestionDate(actionLogs?: ActionLog[]) {
    return DateUtils.formatDate(actionLogs?.find((x) => x.threadType == 'question' && x.closed)?.dueTimeStamp) || '';
  }
  public static getClosedQuestionDate(doc?: Document) {
    if (doc?.threadQuestion?.closed == true) return DateUtils.formatDate(doc?.threadQuestion?.dueTimeStamp) || '';
    return '';
  }
  public static ClosedQuestionId(actionLogs?: ActionLog[]) {
    return actionLogs?.find((x) => x.threadType == 'question' && x.closed)?.actionLogId || '';
  }
  public static getClosedQuestionId(doc?: Document) {
    if (doc?.threadQuestion?.closed == true) return doc?.threadQuestion?.actionLogId || '';
    return '';
  }
  public static OpenCircularDate(actionLogs?: ActionLog[]) {
    return (
      DateUtils.formatDate(
        actionLogs?.find(
          (x: ActionLog) =>
            x.threadType == 'circular' && !x.closed && x.dueTimeStamp != null && x.dueTimeStamp.length > 0
        )?.dueTimeStamp
      ) || ''
    );
  }
  public static getOpenCircularDate(doc?: Document) {
    if (doc?.threadCircular?.closed == false) return DateUtils.formatDate(doc?.threadCircular?.dueTimeStamp);
    return '';
  }
  public static OpenCircularId(actionLogs?: ActionLog[]) {
    if (actionLogs === undefined) return undefined;
    const union: string[] = ActionLogsHelper.openId(actionLogs, 'circular');
    if (union.length) return union[0];
  }
  public static getOpenCircularId(doc?: Document) {
    if (doc?.threadCircular?.closed == false) doc.threadCircular.actionLogId;
    return '';
  }
  public static ClosedCircularDate(actionLogs?: ActionLog[]) {
    return DateUtils.formatDate(actionLogs?.find((x) => x.threadType == 'circular' && x.closed)?.dueTimeStamp) || '';
  }
  public static getClosedCircularDate(doc?: Document) {
    if (doc?.threadCircular?.closed == true) return DateUtils.formatDate(doc?.threadCircular?.dueTimeStamp);
    return '';
  }
  public static ClosedCircularId(actionLogs?: ActionLog[]) {
    return actionLogs?.find((x) => x.threadType == 'circular' && x.closed)?.actionLogId || '';
  }
  public static getClosedCircularId(doc?: Document) {
    if (doc?.threadCircular?.closed == true) return doc?.threadCircular?.actionLogId;
    return '';
  }
  public static OpenTaskDate(actionLogs?: ActionLog[]) {
    return (
      DateUtils.formatDate(
        actionLogs?.find(
          (x: ActionLog) => x.threadType == 'task' && !x.closed && x.dueTimeStamp != null && x.dueTimeStamp.length > 0
        )?.dueTimeStamp
      ) || ''
    );
  }
  public static getOpenTaskDate(doc?: Document) {
    return DateUtils.formatDate(doc?.threadTask?.dueTimeStamp) || '';
  }
  public static OpenTaskId(actionLogs?: ActionLog[]) {
    if (actionLogs === undefined) return undefined;
    const union: string[] = ActionLogsHelper.openId(actionLogs, 'task');
    if (union.length) return union[0];
  }
  public static getOpenTaskId(doc?: Document) {
    if (doc?.threadTask?.closed == false) return doc?.threadTask?.actionLogId;
    return '';
  }
  private static openId(actionLogs: ActionLog[], threadType: 'task' | 'question' | 'circular') {
    const closed: string[] =
      actionLogs
        ?.filter((x) => x.threadType == threadType && x.type == 'close_thread' && x.closed == true)
        .map((x) => x.actionLogId) ?? [];
    const opened: string[] =
      actionLogs
        ?.filter((x) => x.threadType == threadType && x.type == 'start_thread' && x.closed == false)
        .map((x) => x.actionLogId) ?? [];
    const union: string[] = opened.filter((x: string) => !closed.includes(x));
    return union;
  }

  public static ClosedTaskDate(actionLogs?: ActionLog[]) {
    return DateUtils.formatDate(actionLogs?.find((x) => x.threadType == 'task' && x.closed)?.dueTimeStamp) || '';
  }
  public static getClosedTaskDate(doc?: Document) {
    if (doc?.threadTask?.closed == true) return DateUtils.formatDate(doc?.threadTask?.dueTimeStamp) || '';
  }
  public static ClosedTaskId(actionLogs?: ActionLog[]) {
    return actionLogs?.find((x) => x.threadType == 'task' && x.closed)?.actionLogId || '';
  }
  public static getClosedTaskId(doc?: Document) {
    if (doc?.threadTask?.closed == true) return doc?.threadTask?.actionLogId;
    return '';
  }
}
