import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { TemplateMarkup } from '@/shared/model/templateMarkup';
import { templateMarkup } from '@/shared/store/modules/templateMarkup';
import autoGobdCheckTerm, { AutoGobdCheckTerm } from '@/shared/model/autoGobdCheckTerm';
import { Supplier } from '@/shared/model/supplier';
import MenuDocumentAccountTable from '@/views/menu/rightMainMenu/menu-document-preview/menu-document-account/menu-document-account-table/menu-document-account-table.vue';

const logger = new Logger('menu-document-template');
const documentModule = namespace('document');
const templateMarkupModule = namespace('templateMarkup');

const allocationTemplateModule = namespace('allocationTemplate');
const allocationModule = namespace('allocation');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const autoGobdCheckTermModule = namespace('autoGobdCheckTerm');

@Component({ name: 'menu-document-template', components: { MenuDocumentAccountTable } })
export default class MenuDocumentTemplateView extends Vue {
  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocumentIds!: any;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: any;

  @templateMarkupModule.Getter('getIsTemplateMode')
  private getterIsTemplateMode!: any;
  @templateMarkupModule.Action('changeIsTemplateMode')
  private actionChangeIsTemplateMode!: any;

  @templateMarkupModule.Getter('getTemplateMarkups')
  private getterGetTemplateMarkups!: any;
  @templateMarkupModule.Action('getTemplateMarkups')
  private actionGetTemplateMarkups!: any;

  @templateMarkupModule.Action('updateTemplateMarkup')
  private actionUpdateTemplateMarkup!: any;
  @templateMarkupModule.Action('changeTemplateMarkup')
  private actionChangeTemplateMarkup!: any;
  @templateMarkupModule.Action('changeTemplateMarkupSelected')
  private actionChangeTemplateMarkupSelected!: any;
  @templateMarkupModule.Action('deleteTemplateMarkup')
  private actionDeleteTemplateMarkup!: any;
  @templateMarkupModule.Action('deleteTemplateMarkupByDocumentId')
  private actionDeleteTemplateMarkupByDocumentId!: any;

  @templateMarkupModule.Getter('getTemplateMarkupsScale')
  private getterGetTemplateMarkupsScale!: any;
  @templateMarkupModule.Action('setTemplateMarkupsScale')
  private actionSetTemplateMarkupsScale!: any;

  @allocationModule.Action('getMainAllocationsByDocumentId')
  private actionGetMainAllocationsByDocumentId?: any;

  get getTemplateMarkups() {
    // TODO - We need try to find another solution for this WORKAROUND
    // WORKAROUND - When we open document with template, then without -
    // we get the same templates in state for document without templates
    const result = this.getterGetTemplateMarkups.items
      .filter((item: TemplateMarkup) => item.userFileId == this.documentId)
      .sort((a: TemplateMarkup, b: TemplateMarkup) => {
        return parseInt(a.name) - parseInt(b.name);
      });
    return result;
  }

  @Prop({ default: '' })
  private documentNumber!: string;

  @Ref() readonly myTable!: any;

  private template: any = {};

  get isChangeSingleDocument() {
    return this.getterSelectedDocumentIds.length === 0;
  }

  get isTemplateMode() {
    return this.getterIsTemplateMode === true;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  private max25chars = (v: string): any => v.length <= 25 || 'Input too long!';

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('mark_name'), value: 'name' },
      { text: this.$t('mark_x'), value: 'x' },
      { text: this.$t('mark_y'), value: 'y' },
      { text: this.$t('mark_width'), value: 'width' },
      { text: this.$t('mark_height'), value: 'height' },
      { text: this.$t('mark_isMarker'), value: 'isMarker' },
      { text: this.$t('mark_markerText'), value: 'markerText' },
      { text: this.$t('mark_fieldName'), value: 'fieldName.value' },
    ];
    return headers;
  }

  get Scale() {
    return this.getterGetTemplateMarkupsScale;
  }

  get items() {
    const items = [
      { id: '', value: '' },
      { id: 'iban', value: this.$t('document_preview_marker_fields.iban') }, //informationen - documents
      { id: 'homepage', value: this.$t('document_preview_marker_fields.homepage') }, //informationen - documents
      { id: 'tax_number', value: this.$t('document_preview_marker_fields.tax_number') }, //informationen - documents
      { id: 'amount', value: this.$t('document_preview_marker_fields.amount') }, //gobd - DocumentGobdDatums
      { id: 'document_date', value: this.$t('document_preview_marker_fields.document_date') }, //gobd - DocumentGobdDatums
      { id: 'voucher_number', value: this.$t('document_preview_marker_fields.voucher_number') },
      { id: 'indicator', value: this.$t('document_preview_marker_fields.indicator') },
      { id: 'serial_number', value: this.$t('document_preview_marker_fields.serial_number') },
      { id: 'customer_number', value: this.$t('document_preview_marker_fields.customer_number') },
    ];
    return items;
  }

  get filteredItems() {
    if (this.getTemplateMarkups.length != 0) {
      return this.items.filter(
        (h) =>
          this.getTemplateMarkups.find((o: any) => o.fieldName === h.id) === undefined ||
          h.id == '' ||
          h.id == this.editFieldName
      );
    }
    return this.items;
  }

  updateInDatabase() {
    this.actionDeleteTemplateMarkupByDocumentId(this.documentId).then(() => {
      const l = this.getTemplateMarkups.length;
      for (let i = 0; i < l; i++) {
        const currentValue = <TemplateMarkup>this.getTemplateMarkups[i];
        currentValue.id = '';
        currentValue.documentId = this.getterGetDocument?.id;
        currentValue.x = Math.round(currentValue.x * this.Scale);
        currentValue.y = Math.round(currentValue.y * this.Scale);
        currentValue.width = Math.round(currentValue.width * this.Scale);
        currentValue.height = Math.round(currentValue.height * this.Scale);
        this.actionUpdateTemplateMarkup(currentValue);
      }
    });
  }

  private saveDocTemplate() {
    this.updateInDatabase();
    this.actionChangeIsTemplateMode(false);
    this.$emit('click:return');
  }

  private returnBack() {
    this.actionChangeIsTemplateMode(false);
    this.$emit('click:return');
  }

  isNeedShowNumberWarning: boolean = true;
  onChangeUpdateExistingDocuments(value: any) {
    if (!value && this.isNeedShowNumberWarning) {
      this.$message.open(
        `${this.$t('is_need_show_number_warning_title')}`,
        `${this.$t('is_need_show_number_warning_text')}`,
        {
          cancelColor: 'error',
          okColor: 'grey',
          okText: this.$i18n.t('ok'),
        }
      );
      this.isNeedShowNumberWarning = false;
    }
  }
  mounted() {
    this.editedItem.updateExistingDocuments = true;
    this.isNeedShowNumberWarning = true;
    //this.actionChangeIsTemplateMode(true);
    this.actionGetAllocationTemplates();
  }

  private isTemplateModeActivated: boolean = false;

  public async onTemplateModeActivatedChange(newVal: any, oldVal: any) {
    if (newVal == true) this.actionChangeIsTemplateMode(true);
    else this.actionChangeIsTemplateMode(false);
  }

  private editMarkerText = '';
  saveMarkerText(val: any) {
    const temp: TemplateMarkup = val;
    temp.markerText = this.editMarkerText;
    this.actionChangeTemplateMarkup(temp);
  }

  private editFieldName = '';
  saveFieldName(val: any) {
    const temp: TemplateMarkup = val;
    temp.fieldName = this.editFieldName;
    this.actionChangeTemplateMarkup(temp);
    (this.$refs[`myDialogFieldName${temp.id}`] as any).save();
  }

  myRef(name: string, id: string) {
    return `myDialog${name}${id}`;
  }

  FieldNameFromid(id: any) {
    let value: any = '';
    this.items.forEach((element) => {
      if (element.id == id) value = element.value;
    });
    return value;
  }

  private editIsMarker = false;
  saveIsMarker(val: any) {
    const temp: TemplateMarkup = val;
    temp.isMarker = this.editIsMarker;
    this.actionChangeTemplateMarkup(temp);
    (this.$refs[`myDialogIsMarker${temp.id}`] as any).save();
  }

  cancel() {
    console.log('cancel');
  }

  close() {
    console.log('close');
  }

  row_classes(item: any) {
    if (item.isSelected) {
      return 'deep-purple lighten-4';
    }
  }

  handleRowClick(val: any) {
    const temp: TemplateMarkup = val;
    temp.isSelected = true;
    this.actionChangeTemplateMarkupSelected(temp);
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  openeditFieldName(fieldName: any) {
    this.editFieldName = fieldName;
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  openeditMarkerText(markerText: any) {
    this.editMarkerText = markerText;
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  openeditIsMarker(isMarker: any) {
    this.editIsMarker = isMarker;
    document.getElementById('menu')?.style.setProperty('display', 'none');
  }

  //#region AutoGobD
  @allocationTemplateModule.Getter('getAllocationTemplates')
  private allocationTemplates?: any;
  @allocationTemplateModule.Action('getAllocationTemplates')
  private actionGetAllocationTemplates?: any;

  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private actionGetOrganisationDocumentExtraStatusSettings!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  @autoGobdCheckTermModule.Action('getAutoGobdCheckTerms')
  private actionGetAutoGobdCheckTerms?: any;
  @autoGobdCheckTermModule.Action('updateAutoGobdCheckTerm')
  private actionUpdateAutoGobdCheckTerm?: any;

  private editedItem: AutoGobdCheckTerm = autoGobdCheckTerm.parse({ priority: 1, typeSearch: 1 });

  get getPrioritats() {
    return [
      { id: 1, name: '1' },
      { id: 2, name: '2' },
    ];
  }

  get getConditions() {
    return [
      { id: 1, name: 'Alle zutreffen' },
      { id: 2, name: 'Eins zutrifft' },
    ];
  }

  private saveDocGobD() {
    this.actionUpdateAutoGobdCheckTerm(this.editedItem).then((result: any) => {
      this.actionGetAutoGobdCheckTerms();
      if (this.editedItem.supplierAccountName) this.actionGetMainAllocationsByDocumentId(this.documentId);
    });
    this.actionChangeIsTemplateMode(false);
    this.$emit('click:return');
  }

  //#endregion

  //#region Lieferanten Dialog logic for GobD

  get supplier() {
    return this.editedItem.supplierAccountName;
  }

  private showDocumentAccountDialog(field: string) {
    this.dialogDocumentAccount.show = true;
    this.dialogDocumentAccount.insertIntoField = field;
  }

  dialogDocumentAccount = {
    show: false,
    insertIntoField: '',
    model: {},
    OnClose: () => {
      this.dialogDocumentAccount.show = false;
    },
    OnSelect: (value: Supplier) => {
      switch (this.dialogDocumentAccount.insertIntoField) {
        case 'account': {
          this.editedItem.supplierAccount = value.id;
          this.editedItem.supplierAccountName = value.konto;
          break;
        }
        case 'contra_account': {
          break;
        }
      }
      this.dialogDocumentAccount.show = false;
    },
  };
  //#endregion
}
