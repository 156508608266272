import { GetterTree } from 'vuex';
import { OrganisationDocumentListSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<OrganisationDocumentListSettingState, RootState> = {
  ['getOrganisationDocumentListSettings']: (state) => state.organisationDocumentListSettings,
  ['getOrganisationDocumentListSettingsIsLoading']: (state) => state.organisationDocumentListSettings?.isLoading,
  ['getOrganisationDocumentListSettingsTotal']: (state) => state.organisationDocumentListSettings?.total,
  ['getOrganisationDocumentListSettingsSearchParams']: (state) => state.organisationDocumentListSettings?.searchParams,
};
