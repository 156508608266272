import { ActionTree } from 'vuex';
import { AllocationBrandState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationBrand } from '@/shared/backend/allocationBrand';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationBrand, { Value, AllocationBrand } from '@/shared/model/allocationBrand';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.allocationBrands');
export const actions: ActionTree<AllocationBrandState, RootState> = {
  getAllocationBrands({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationBrandsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationBrandsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationBrand
      .getAllocationBrands(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationBrands', response.data);
        commit('setAllocationBrandsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationBrandsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllAllocationBrands({ commit, getters, rootGetters }, payload?: { searchParams?: any }) {
    //if start get action don't run second
    if (getters.getAllocationBrandsIsLoading.isLoading) return;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    commit('setAllAllocationBrandsIsImporting', true);
    const searchParams: SearchParams = payload?.searchParams ?? { ...getters.getAllocationBrandsSearchParams };
    searchParams.dataOption.itemsPerPage = 500;
    return defaultBackendAllocationBrand
      .getAllocationBrands(searchParams, organizationId)
      .then(async (response: AxiosResponse<Value>) => {
        let allocationBrands = response.data.value.map((x) => allocationBrand.parse(x));

        while (response.data['@odata.nextLink']) {
          response = await defaultBackendAllocationBrand.getByUrl(response.data['@odata.nextLink']);

          allocationBrands = allocationBrands.concat(response.data.value.map((x) => allocationBrand.parse(x)));
        }
        commit('setAllAllocationBrands', allocationBrands);
        return allocationBrands;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllAllocationBrandsIsImporting', false);
      });
  },
  getAllocationBrand({ commit, dispatch }, id: string) {
    return defaultBackendAllocationBrand
      .getAllocationBrand(id)
      .then((response: AxiosResponse<AllocationBrand>) => {
        return allocationBrand.parse(response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationBrand({ commit, dispatch }, file: AllocationBrand) {
    return defaultBackendAllocationBrand
      .updateAllocationBrand(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationBrand;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationBrand_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadAllocationBrand(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    commit('setIsImporting', true);
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationBrand
      .uploadAllocationBrand(fileInfo)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationBrand;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationBrand_uploaded`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        //show first error from responce if exits translate
        let error = [{ code: 'error', description: i18n.tc('error') }];
        if (Array.isArray(e.response.data.errors?.$values)) error = e.response.data.errors?.$values;
        if (Array.isArray(e.response.data.errors)) error = e.response.data.errors;
        if (i18n.te('errors.' + error[0].code)) error[0].description = i18n.tc('errors.' + error[0].code);

        module_utils.error(error[0].description, commit, e, logger);
        throw e.response.data;
      })
      .finally(() => {
        commit('setIsImporting', false);
      });
  },
  deleteAllocationBrand({ commit, dispatch }, id: string) {
    return defaultBackendAllocationBrand
      .deleteAllocationBrand(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationBrand;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
