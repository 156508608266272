import { GetterTree } from 'vuex';
import { OrganizationState } from './types';
import { RootState } from '../../types';
import { OrganisationUser } from '@/shared/model/organisationUser';
import { SearchParamsEmpty } from '@/shared/model/searchParams';

export const getters: GetterTree<OrganizationState, RootState> = {
  ['isFolderOpenTypeOpenTheWholeStructure']: (state) => state.currentOrganization?.folderOpenType == 0,
  ['getOrganization']: (state) => state.currentOrganization,
  ['gobdBlueIsProofed']: (state) => state.currentOrganization?.gobdBlueIsProofed,
  ['getOrganizations']: (state) => state.organizations,
  ['getMainOrganizations']: (state) => state.organizationsAll,
  ['getOrganizationsForMain']: (state) => (mainOrgId: string) => {
    if (state.organizationsAllForMain[mainOrgId] === undefined)
      Object.assign(state.organizationsAllForMain, {
        [mainOrgId]: {
          items: [],
          total: 0,
          isLoading: false,
          searchParams: { ...SearchParamsEmpty },
        },
      });
    return state.organizationsAllForMain[mainOrgId];
  },
  ['getOrganizationUsersAdmPage']: (state) => (orgId: string) => {
    if (state.organizationUsersAdmPage[orgId] === undefined)
      Object.assign(state.organizationUsersAdmPage, {
        [orgId]: {
          items: [],
          total: 0,
          isLoading: false,
          searchParams: { ...SearchParamsEmpty },
        },
      });
    return state.organizationUsersAdmPage[orgId];
  },
  ['getOrganizationsIsLoading']: (state) => state.organizations?.isLoading,
  ['getOrganizationsTotal']: (state) => state.organizations?.total,
  ['getOrganizationsSearchParams']: (state) => state.organizations?.searchParams,
  ['getOrganizationsAllSearchParams']: (state) => state.organizationsAll?.searchParams,
  ['getOrganizationFoldersAll']: (state) => state.organizationFoldersAll,
  ['resetInProgress']: (state) => state.resetInProgress,
  ['getOrganisationUsers']: (state) => state.organisationUsers,
  ['getOrganisationUsersAll']: (state) => state.organisationUsersAll,
  ['getInProgressUpdateOrganisationUser']: (state) => state.inProgressUpdateOrganisationUser,
  ['isOrganisationUsersOwnerForCurrentOrganisation']: (state) => (ou: OrganisationUser) =>
    state.currentOrganization?.ownerId == ou.userId,
  ['getInProgressUpdateToMain']: (state) => state.inProgressUpdateToMain,
};
