export interface OrganisationAllocationProperty {
  id: string; // ulong
  fieldId: string; // string
  organisationId: string; // string
  sortOrder: number; // int
  enabled: boolean; // bool?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  maxLength: number;
  //AllocationField.Name
  name: string; // string
}

export interface Value {
  value: OrganisationAllocationProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<OrganisationAllocationProperty>): any {
  return {
    Id: data?.id || undefined,
    FieldId: data?.fieldId || '',
    OrganisationId: data?.organisationId || '',
    SortOrder: data?.sortOrder || 0,
    Enabled: data?.enabled || false,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    MaxLength: data?.maxLength || 0,
  };
}

function parse(data?: Partial<OrganisationAllocationProperty>): OrganisationAllocationProperty {
  return {
    id: data?.id || '',
    fieldId: data?.fieldId || '',
    organisationId: data?.organisationId || '',
    sortOrder: data?.sortOrder || 0,
    enabled: data?.enabled || false,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    maxLength: data?.maxLength || 0,
    name: data?.name || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
