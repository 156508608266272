import { MutationTree } from 'vuex';
import { DocumentStateState } from './types';
import Vue from 'vue';
import documentState, { DocumentState, Value } from '@/shared/model/documentState';

export const mutations: MutationTree<DocumentStateState> = {
  setDocumentStates(state, payload: Value) {
    state.documentStates.items = payload.value.map((x) => documentState.parse(x));
    state.documentStates.total = payload['@odata.count'] || 0;
    state.documentStates.isLoading = false;
  },
  setDocumentStatesTotal(state, payload: number) {
    state.documentStates.total = payload;
  },
  setDocumentStatesIsLoading(state, payload: boolean) {
    state.documentStates.isLoading = payload;
  },
  setInProgressUpdateDocumentState(state, payload: boolean) {
    state.inProgressUpdateDocumentState = payload;
  },
};
