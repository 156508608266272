import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationDocumentSetting, { OrganisationDocumentSetting, Value } from '../model/organisationDocumentSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationDocumentSetting');
export interface BackendOrganisationDocumentSetting {
  getOrganisationDocumentSetting(id: string): AxiosPromise<OrganisationDocumentSetting>;
  getOrganisationDocumentSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteOrganisationDocumentSetting(id: string): AxiosPromise;
  updateOrganisationDocumentSetting(OrganisationDocumentSetting: OrganisationDocumentSetting): AxiosPromise<any>;
}

export const defaultBackendOrganisationDocumentSetting: BackendOrganisationDocumentSetting = {
  getOrganisationDocumentSetting(id: string): AxiosPromise<OrganisationDocumentSetting> {
    const url = `${URLS.organisationDocumentSettingOdata}/${id}`;
    return instance.get<OrganisationDocumentSetting>(url);
  },
  getOrganisationDocumentSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationDocumentSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getOrganisationDocumentSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteOrganisationDocumentSetting(id: string): AxiosPromise {
    logger.debug('deleteOrganisationDocumentSetting');
    return instance.delete(`${URLS.organisationDocumentSetting}/${id}`);
  },
  updateOrganisationDocumentSetting(OrganisationDocumentSetting: OrganisationDocumentSetting): AxiosPromise<any> {
    logger.debug('updateOrganisationDocumentSetting');
    return instance.put<OrganisationDocumentSetting>(
      `${URLS.organisationDocumentSetting}/update`,
      organisationDocumentSetting.toAPI(OrganisationDocumentSetting)
    );
  },
};
