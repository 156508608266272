import { MutationTree } from 'vuex';
import { OfficeState } from './types';
import Vue from 'vue';
import office, { Office, Value } from '@/shared/model/office';

export const mutations: MutationTree<OfficeState> = {
  setOffices(state, payload: Value) {
    state.offices.items = payload.value.map((x) => office.parse(x));
    state.offices.total = payload['@odata.count'] || 0;
    state.offices.isLoading = false;
  },
  setOfficesTotal(state, payload: number) {
    state.offices.total = payload;
  },
  setOfficesIsLoading(state, payload: boolean) {
    state.offices.isLoading = payload;
  },
};
