import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import supplier, { Supplier } from '@/shared/model/supplier';

const name = 'account-edit-dialog';
const logger = new Logger(name);

const supplierModule = namespace('supplier');

@Component({ name: name })
export default class AccountEditDialog extends Vue {
  private getSuppliers?: any;
  @supplierModule.Action('getSuppliers')
  private actionGetSuppliers?: any;
  @supplierModule.Action('deleteSupplier')
  private actionDeleteSupplier?: any;

  @Ref('account-edit-form')
  private refAccountEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => supplier.parse({}) })
  private value!: Supplier;
  @Watch('value')
  onUpdateValue(newV: Supplier, oldV: Supplier) {
    this.account = Object.assign(this.account, newV);
    this.resetValidation(); // (ED-1291) reset validation errors when open empty dialog after create/update address (when `Address` model had values )
  }
  private account: Supplier = supplier.parse({});

  private isFormValid = true;

  get accountNumberRules() {
    return {
      required: (v: any) => !!v.toString().trim() || this.$i18n.t('messages.account_number_required'), // i18n.tc(`name_required_message`) is not working here, just shows `name_required_message` key
      only_numbers: (v: any) => /^\d+$/.test(v) || this.$t('messages.only_numbers'), //only digits allowev
    };
  }

  get descriptionRules() {
    return [(v: string) => !!v.trim() || this.$i18n.t('messages.description_required')];
  }

  resetValidation() {
    if (this.refAccountEditForm) this.refAccountEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refAccountEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.account);
    }
  }

  onClickDelete() {
    this.$confirm
      .open(`${this.$t('delete_supplier')}`, `${this.$t('delete_confirmation')}`, {
        cancelText: this.$t('cancel'),
        okText: this.$t('ok'),
      })
      .then(async (response: any) => {
        if (response) {
          this.actionDeleteSupplier(this.account.id).then(() => {
            this.actionGetSuppliers();
            this.onClickClose();
          });
        } else {
        }
      });
  }
}
