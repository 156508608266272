import { MutationTree } from 'vuex';
import { OrganisationDocumentSettingState } from './types';
import Vue from 'vue';
import organisationDocumentSetting, {
  OrganisationDocumentSetting,
  Value,
} from '@/shared/model/organisationDocumentSetting';

export const mutations: MutationTree<OrganisationDocumentSettingState> = {
  setOrganisationDocumentSetting(state, payload: Value) {
    state.current = Object.assign({}, organisationDocumentSetting.parse(payload.value[0]));
  },
  setOrganisationDocumentSettings(state, payload: Value) {
    state.organisationDocumentSettings.items = payload.value.map((x) => organisationDocumentSetting.parse(x));
    state.organisationDocumentSettings.total = payload['@odata.count'] || 0;
    state.organisationDocumentSettings.isLoading = false;
  },
  setOrganisationDocumentSettingsTotal(state, payload: number) {
    state.organisationDocumentSettings.total = payload;
  },
  setOrganisationDocumentSettingsIsLoading(state, payload: boolean) {
    state.organisationDocumentSettings.isLoading = payload;
  },
  setIsUpdating(state, payload: boolean) {
    state.isUpdating = payload;
  },
};
