import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationsProperty, { AllocationsProperty, Value } from '../model/allocationsProperty';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationsProperty');
export interface BackendAllocationsProperty {
  getAllocationsProperty(id: string): AxiosPromise<AllocationsProperty>;
  getAllocationsPropertys: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationsProperty(id: string): AxiosPromise;
  updateAllocationsProperty(AllocationsProperty: AllocationsProperty): AxiosPromise<any>;
}

export const defaultBackendAllocationsProperty: BackendAllocationsProperty = {
  getAllocationsProperty(id: string): AxiosPromise<AllocationsProperty> {
    const url = `${URLS.allocationsPropertyOdata}/${id}`;
    return instance.get<AllocationsProperty>(url);
  },
  getAllocationsPropertys(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationsPropertyOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationsPropertys${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationsProperty(id: string): AxiosPromise {
    logger.debug('deleteAllocationsProperty');
    return instance.delete(`${URLS.allocationsProperty}/${id}`);
  },
  updateAllocationsProperty(AllocationsProperty: AllocationsProperty): AxiosPromise<any> {
    logger.debug('updateAllocationsProperty');
    return instance.put<AllocationsProperty>(
      `${URLS.allocationsProperty}/update`,
      allocationsProperty.toAPI(AllocationsProperty)
    );
  },
};
