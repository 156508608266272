import { MutationTree } from 'vuex';
import { SuppliersImportSettingState } from './types';
import Vue from 'vue';
import suppliersImportSetting, { SuppliersImportSetting, Value } from '@/shared/model/suppliersImportSetting';

export const mutations: MutationTree<SuppliersImportSettingState> = {
  setSuppliersImportSettings(state, payload: Value) {
    state.suppliersImportSettings.items = payload.value.map((x) => suppliersImportSetting.parse(x));
    state.suppliersImportSettings.total = payload['@odata.count'] || 0;
    state.suppliersImportSettings.isLoading = false;
  },
  setSuppliersImportSettingsTotal(state, payload: number) {
    state.suppliersImportSettings.total = payload;
  },
  setSuppliersImportSettingsIsLoading(state, payload: boolean) {
    state.suppliersImportSettings.isLoading = payload;
  },
  setSuppliersImportSetting(state, payload: Value) {
    state.suppliersImportSetting = Object.assign({}, payload.value[0]);
  },
  setInProgressUpdateSuppliersImportSetting(state, payload: boolean) {
    state.inProgressUpdateSuppliersImportSetting = payload;
  },
};
