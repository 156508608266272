import autoGobdCheckTerm, { AutoGobdCheckTerm } from '@/shared/model/autoGobdCheckTerm';
import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DocumentAutoGobdEdit from './document-auto-gobd-edit/document-auto-gobd-edit.vue';

const autoGobdCheckTermModule = namespace('autoGobdCheckTerm');

@Component({ components: { DocumentAutoGobdEdit } })
export default class DocumentAutoGobdDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: '' })
  private model!: any;

  @autoGobdCheckTermModule.Getter('getAutoGobdCheckTerms')
  private getAutoGobdCheckTerms?: any;
  @autoGobdCheckTermModule.Action('getAutoGobdCheckTerms')
  private actionGetAutoGobdCheckTerms?: any;
  @autoGobdCheckTermModule.Action('updateAutoGobdCheckTerm')
  private actionUpdateAutoGobdCheckTerm?: any;
  @autoGobdCheckTermModule.Action('deleteAutoGobdCheckTerm')
  private actionDeleteAutoGobdCheckTerm?: any;
  @autoGobdCheckTermModule.Action('runAutoGobdCheckTerm')
  private runAutoGobdCheckTerm?: any;
  @autoGobdCheckTermModule.Getter('isRunAutoGobdCheckTerm')
  private isRunAutoGobdCheckTerm!: (id: string) => boolean;

  get autoGobdCheckTerms() {
    return this.getAutoGobdCheckTerms;
  }

  private isSaveBtnLoading = false;

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('search_term1'), value: 'searchTerm1' },
      { text: this.$t('search_term2'), value: 'searchTerm2' },
      { text: this.$t('search_term3'), value: 'searchTerm3' },
      { text: this.$t('search_term4'), value: 'searchTerm4' },
      { text: this.$t('search_term5'), value: 'searchTerm5' },
      { text: this.$t('account_assignment_template'), value: 'allocationTemplateName' },
      { text: this.$t('status'), value: 'documentStatusName' },
      { text: this.$t('supplier'), value: 'supplierAccountName' },
      { text: this.$t('prio'), value: 'priority' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  created() {
    this.actionGetAutoGobdCheckTerms();
  }

  clickClose() {
    this.$emit('click:close');
  }

  onClickDelete(item: AutoGobdCheckTerm) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: item.id,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteAutoGobdCheckTerm(item.id)
            .then(() => {
              this.actionGetAutoGobdCheckTerms();
            })
            .catch((err: any) => {});
        }
      });
  }

  onClickAdd() {
    this.itemEditDialog.model = { ...autoGobdCheckTerm.parse({ priority: 1 }) };
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: AutoGobdCheckTerm) {
    this.itemEditDialog.model = { ...autoGobdCheckTerm.parse(item) };
    this.itemEditDialog.show = true;
  }
  async onClickPlay(item: AutoGobdCheckTerm) {
    await this.runAutoGobdCheckTerm({ autoGobdCheckTermId: item.id, organisationId: item.organisationId });
  }

  itemEditDialog = {
    show: false,
    model: { ...autoGobdCheckTerm.parse({}) },
  };

  async itemEditDialogOnClose(account: AutoGobdCheckTerm) {
    this.itemEditDialog.show = false;
  }

  async itemEditDialogOnUpdate(account: AutoGobdCheckTerm) {
    this.isSaveBtnLoading = true;
    this.actionUpdateAutoGobdCheckTerm(account)
      .then((result: any) => {
        this.getAutoGobdCheckTerms();
      })
      .catch((err: any) => {})
      .finally(() => {
        this.itemEditDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetAutoGobdCheckTerms();
      });
  }
  @Watch('autoGobdCheckTerms.searchParams.filter')
  onFilterChange(val: string, valN: string) {
    if (val != valN) this.actionGetAutoGobdCheckTerms();
  }
}
