import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import allocationTemplate, { AllocationTemplate } from '@/shared/model/allocationTemplate';
import AccountTemplateEditComponent from './account-template-edit-component/account-template-edit-component.vue';
import allocationTemplateEx, { AllocationTemplateEx } from '@/shared/model/allocationTemplateEx';
import MenuDocumentAccountTable from '../menu-document-account-table/menu-document-account-table.vue';
import MenuDocumentAccountAddresses from '../menu-document-account-addresses/menu-document-account-addresses.vue';
import { buildOnEnter } from '@/shared/utils/formUtils';

const name = 'account-edit-dialog';
const logger = new Logger(name);
const allocationTemplateModule = namespace('allocationTemplate');

@Component({
  name: name,
  components: { AccountTemplateEditComponent, MenuDocumentAccountTable, MenuDocumentAccountAddresses },
})
export default class AccountEditTemplateDialog extends Vue {
  @Ref('account-edit-form')
  private refAccountEditForm!: any;

  @allocationTemplateModule.Action('deleteAllocationTemplate')
  private actionDeleteAllocationTemplate?: any;

  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => allocationTemplate.parse({}) })
  private value!: AllocationTemplate;
  @Watch('value')
  onUpdateValue(newV: AllocationTemplate, oldV: AllocationTemplate) {
    this.account = Object.assign(this.account, newV);
    this.resetValidation(); // (ED-1291) reset validation errors when open empty dialog after create/update address (when `Address` model had values )
    this.resetForm();
  }
  private account: AllocationTemplate = allocationTemplate.parse({});

  private isFormValid = true;

  showNameError = '';
  get nameRules() {
    return [
      (v: any) => {
        if (v.toString().trim().length == 0) {
          this.showNameError = this.$i18n.t('messages.field_required').toString();
          return false;
        } else {
          this.showNameError = '';
          return true;
        }
      },
    ];
  }

  get descriptionRules() {
    return [(v: string) => !!v.trim() || this.$i18n.t('messages.description_required')];
  }

  @Prop({ default: () => [] })
  private properties!: AllocationTemplateEx[];
  @Watch('properties')
  onUpdateProperties(newV: AllocationTemplateEx, oldV: AllocationTemplateEx) {
    this.accountTemplateEdit.models = Object.assign(this.accountTemplateEdit.models, newV);
  }
  private accountTemplateEdit: { models: AllocationTemplateEx[] } = { models: [] };

  mounted() {}

  addSplit() {
    const ctrl: AllocationTemplateEx = allocationTemplateEx.parse({});
    let maxIndex = 0;
    if (this.accountTemplateEdit.models.length == 0) {
      maxIndex = 0;
    } else {
      maxIndex = Math.max(...this.accountTemplateEdit.models.map((o) => o.index)) + 1;
    }
    ctrl.index = maxIndex;
    ctrl.account_group = '1';
    this.accountTemplateEdit.models.push(ctrl);
  }

  updateItem(item: any) {
    const object = this.accountTemplateEdit.models.find((x) => x.name === item.name) ?? [0];
    Object.assign(object, item);
  }

  deleteItem(index: number) {
    const object = this.accountTemplateEdit.models.findIndex((x) => x.index === index) ?? [0];
    this.accountTemplateEdit.models.splice(object, 1);
  }

  resetForm() {
    this.accountTemplateEdit.models = [];
    this.addSplit();
  }

  resetValidation() {
    if (this.refAccountEditForm) this.refAccountEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async validate() {
    await this.refAccountEditForm.validate();
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refAccountEditForm.validate();

    if (isFormValidResult) {
      this.account.properties = this.accountTemplateEdit.models;
      this.$emit('click:update', this.account);
    }
  }

  async onClickDelete(account: AllocationTemplate) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: account.name,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteAllocationTemplate(account.allocationTemplateId)
            .then(() => {
              this.$emit('click:delete');
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }

  //#region Document Rights Dialog logic
  private showDocumentAccountDialog(fieldName: string, item: AllocationTemplateEx) {
    this.dialogDocumentAccount.show = true;
    this.dialogDocumentAccount.item = item;
    this.dialogDocumentAccount.insertIntoField = fieldName;
    switch (this.dialogDocumentAccount.insertIntoField) {
      case 'account': {
        this.dialogDocumentAccount.supplier = item.account;
        break;
      }
      case 'contra_account': {
        this.dialogDocumentAccount.supplier = item.contra_account;
        break;
      }
    }
  }

  dialogDocumentAccount = {
    show: false,
    insertIntoField: '',
    supplier: '',
    item: allocationTemplateEx.parse({}),
    model: {},
    OnClose: () => {
      this.dialogDocumentAccount.show = false;
    },
    OnSelect: (value: any) => {
      const object = this.accountTemplateEdit.models.find((x) => x.index === this.dialogDocumentAccount.item.index);
      if (this.dialogDocumentAccount.insertIntoField == 'account') object!['account'] = value.konto;
      if (this.dialogDocumentAccount.insertIntoField == 'contra_account') object!['contra_account'] = value.konto;
      this.dialogDocumentAccount.show = false;
    },
  };
  //#endregion

  //#region Document Rights Dialog logic
  private showDocumentAddressesDialog() {
    this.dialogDocumentAddresses.show = true;
  }

  dialogDocumentAddresses = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAddresses.show = false;
    },
    OnSelect: (value: any) => {
      //this.model['serial_number'] = value.seriennummer;
      this.dialogDocumentAddresses.show = false;
    },
  };
  //#endregion

  get dialogWidth() {
    if (this.accountTemplateEdit.models.length == 1) return 760;
    if (this.accountTemplateEdit.models.length == 2) return 1160;
    if (this.accountTemplateEdit.models.length >= 3) return 1400;
  }

  private focusNextInputOnEnter(el: HTMLInputElement) {
    const lastElement = (this.$refs.saveBtn as Vue).$el as HTMLElement;
    buildOnEnter(lastElement)(el);
  }
}
