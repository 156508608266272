import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import folderRule, { FolderRule, Value } from '../model/folderRule';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.FolderRule');
export interface BackendFolderRule {
  getFolderRule(id: string): AxiosPromise<FolderRule>;
  getFolderRules: (searchParams: SearchParams, organizationId: string, folderId: string) => AxiosPromise<Value>;
  deleteFolderRule(id: string): AxiosPromise;
  updateFolderRule(FolderRule: FolderRule): AxiosPromise<any>;
}

export const defaultBackendFolderRule: BackendFolderRule = {
  getFolderRule(id: string): AxiosPromise<FolderRule> {
    const url = `${URLS.folderRuleOdata}/${id}`;
    return instance.get<FolderRule>(url);
  },
  getFolderRules(searchParams: SearchParams, organizationId: string, folderId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false).eq(CONST.FolderId, folderId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.folderRuleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=ActionMoveToFolder']
    );
    logger.log(`getFolderRules${url}`);
    return instance.get<Value>(url);
  },

  deleteFolderRule(id: string): AxiosPromise {
    logger.debug('deleteFolderRule');
    return instance.delete(`${URLS.folderRule}/${id}`);
  },
  updateFolderRule(FolderRule: FolderRule): AxiosPromise<any> {
    logger.debug('updateFolderRule');
    return instance.put<FolderRule>(`${URLS.folderRule}/update`, folderRule.toAPI(FolderRule));
  },
};
