import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import comment, { Comment, Value } from '../model/comment';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Comment');
export interface BackendComment {
  getComment(id: string): AxiosPromise<Comment>;
  getComments: (searchParams: SearchParams, organisationId: string, documentId: string) => AxiosPromise<Value>;
  deleteComment(id: string): AxiosPromise;
  updateComment(Comment: Comment): AxiosPromise<any>;
}

export const defaultBackendComment: BackendComment = {
  getComment(id: string): AxiosPromise<Comment> {
    const url = `${URLS.commentOdata}/${id}`;
    return instance.get<Comment>(url);
  },
  getComments(searchParams: SearchParams, organisationId: string, documentId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    odfb.eq(CONST.DocumentId, documentId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.commentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getComments${url}`);
    return instance.get<Value>(url);
  },

  deleteComment(id: string): AxiosPromise {
    logger.debug('deleteComment');
    return instance.delete(`${URLS.comment}/${id}`);
  },
  updateComment(Comment: Comment): AxiosPromise<any> {
    logger.debug('updateComment');
    return instance.put<Comment>(`${URLS.comment}/update`, comment.toAPI(Comment));
  },
};
