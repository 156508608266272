import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const documentModule = namespace('document');
const fileModule = namespace('file');

@Component
export default class DocumentsUnMergeDialog extends Vue {
  @documentModule.Action('unmerge')
  private actionUnmerge!: any;

  @documentModule.Action('getDocumentFoldersCounts')
  private actionGetDocumentFoldersCounts!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;
  @fileModule.Action('getFilesForDocumentId')
  private actionGetFilesForDocumentId!: any;

  @fileModule.Getter('getFiles')
  private getterFile!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: [] })
  private model!: any;

  documentIds: Array<string> = [];

  get documents() {
    return this.model;
  }

  clickClose() {
    this.$emit('click:close');
  }

  get isDocumentSelected() {
    return this.documentIds.length == 0;
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  get mergedFiles() {
    return this.getterFile.items.filter(
      (x: any) => x.mergedToFileId != x.fileId && x.mergedToFileId != '00000000-0000-0000-0000-000000000000'
    );
  }

  unmerge() {
    this.actionUnmerge({
      documentIds: this.documentIds,
      sourceId: this.documentId,
    }).then(() => {
      this.actionGetDocuments().then(() => {
        this.actionGetDocumentFoldersCounts().then(() => {
          this.actionGetFilesForDocumentId(this.documentId).then(() => {
            if (this.mergedFiles.length == 0) {
              this.$router.push('/dashboard/organization');
            }
            this.$emit('click:unmerge');
          });
        });
      });
    });
  }
}
