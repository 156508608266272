import { MutationTree } from 'vuex';
import { UserBillingInfoState } from './types';
import Vue from 'vue';
import userBillingInfo, { UserBillingInfo, Value } from '@/shared/model/userBillingInfo';

export const mutations: MutationTree<UserBillingInfoState> = {
  setUserBillingInfos(state, payload: UserBillingInfo) {
    state.userBillingInfo = payload;
  },
  setIsloading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setInProgressUpdateUserBillingInfo(state, payload: boolean) {
    state.inProgressUpdateUserBillingInfo = payload;
  },
};
