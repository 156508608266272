import Vue from 'vue';
import { Component, Prop, PropSync, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import DateUtils from '@/shared/utils/DateUtils';
import DateTimePicker from '@/components/date-time-picker/date-time-picker.vue';
import moment from 'moment';

const logger = new Logger('document-list-item');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');
const documentModule = namespace('document');

@Component({
  components: { DateTimePicker },
})
export default class DocumentsDatesDialog extends Vue {
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;
  @documentModule.Mutation('addGetDocumentsDateFilter')
  private addGetDocumentsDateFilter!: any;
  @documentModule.Action('getDocuments')
  private getDocuments!: any;
  @Ref('from-date')
  private refFromDate!: any;
  @Ref('until-date')
  private refUntilDate!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @PropSync('value', { default: [] })
  private model!: any;

  @Ref('menu-document-reminder-edit-form')
  private refEditForm!: any;

  private docItemStatusValues = [
    'Hochgeladen',
    'Kontiert',
    'Datum GoBD',
    'Rechnungsdatum',
    // keep values to see them at once on load, otherwise can be empty values for a second while load the values (especially on the login)
    'Genehmigt',
    'Endkontrolle',
    'Zahlung offen',
    'Prüfen',
    'DMS gebucht',
    'per Lastschrift',
    'Extra4',
    'Extra5',
    'Extra6',
  ];

  selectedType?: { id: string; value: string } = { id: '', value: '' };
  fromISO = new Date().toISOString().substring(0, 10);
  untilISO = new Date().toISOString().substring(0, 10);
  from? = '';
  until? = '';
  menuFrom = false;
  menuUntil = false;

  get dateTypes() {
    const statusValues: { id: string; value: string }[] = [];

    this.docItemStatusValues.slice(0, 4).forEach((x) => {
      statusValues.push({ id: x, value: x });
    });
    this.getOrganisationDocumentExtraStatusSettings.items.forEach((element: any) => {
      statusValues.push({ id: element.statusValue, value: element.name });
    });

    return statusValues;
  }

  mounted() {
    this.selectedType = this.dateTypes[0];
    this.from = this.dateISOToLocal(this.fromISO);
    this.until = this.dateISOToLocal(this.untilISO);
  }

  clickClose() {
    this.$emit('click:close');
  }

  async filterByDate() {
    const isFormValidResult = await this.refEditForm.validate();
    this.addGetDocumentsDateFilter({
      type: this.selectedType,
      fromISO: this.fromISO,
      untilISO: this.untilISO,
      fromLocal: this.from,
      untilLocal: this.until,
    });
    this.getDocuments();
    this.$emit('click:close');
  }
  onBlurFrom(newVal: any, oldVal: any) {
    const valid = this.refFromDate.validate(false);
    if (valid) this.fromISO = this.dateLocalToISO(this.from);
  }
  onBlurUntil(newVal: any, oldVal: any) {
    const valid = this.refUntilDate.validate(false);
    if (valid) this.untilISO = this.dateLocalToISO(this.until);
  }
  onInputFrom(newVal: any) {
    this.refFromDate.reset();
    this.menuFrom = false;
    this.from = this.dateISOToLocal(newVal);
  }

  onInputFromText(newVal: any) {
    this.menuFrom = false;
    if (newVal instanceof Date) {
      this.onInputFrom(newVal);
    }
  }

  onInputUntil(newVal: any) {
    this.refUntilDate.reset();
    this.menuUntil = false;
    this.until = this.dateISOToLocal(newVal);
  }

  onInputUntilText(newVal: any) {
    this.menuUntil = false;
    if (newVal instanceof Date) {
      this.onInputUntil(newVal);
    }
  }

  dateLocalToISO(str: any) {
    //return DateUtils.localToIsoDate(str, this.$i18n.locale);
    const formats = ['MM/DD/YYYY HH:mm A', 'DD.MM.YYYY HH:mm'];
    return moment(str, formats).toISOString();
  }
  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale);
  }

  private isFormValid = true;
  get dateRules() {
    //en-GB 31/01/2018 10:00:00
    //en-US 1/31/2018 10:00:00 AM
    const formats = ['MM/DD/YYYY HH:mm A', 'DD.MM.YYYY HH:mm'];
    return [(v: string) => moment(v, formats, true).isValid() || this.$t('dateFormat')];
  }
}
