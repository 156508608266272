import { MutationTree } from 'vuex';
import { AllocationTaxGroupsImportSettingState } from './types';
import Vue from 'vue';
import allocationTaxGroupsImportSetting, {
  AllocationTaxGroupsImportSetting,
  Value,
} from '@/shared/model/allocationTaxGroupsImportSetting';

export const mutations: MutationTree<AllocationTaxGroupsImportSettingState> = {
  setAllocationTaxGroupsImportSettings(state, payload: Value) {
    state.allocationTaxGroupsImportSettings.items = payload.value.map((x) => allocationTaxGroupsImportSetting.parse(x));
    state.allocationTaxGroupsImportSettings.total = payload['@odata.count'] || 0;
    state.allocationTaxGroupsImportSettings.isLoading = false;
  },
  setAllocationTaxGroupsImportSettingsTotal(state, payload: number) {
    state.allocationTaxGroupsImportSettings.total = payload;
  },
  setAllocationTaxGroupsImportSettingsIsLoading(state, payload: boolean) {
    state.allocationTaxGroupsImportSettings.isLoading = payload;
  },
  setAllocationTaxGroupsImportSetting(state, payload: Value) {
    state.allocationTaxGroupsImportSetting = allocationTaxGroupsImportSetting.parse(payload.value[0]);
  },
  setInProgressUpdateAllocationTaxGroupsImportSetting(state, payload: boolean) {
    state.inProgressUpdateAllocationTaxGroupsImportSetting = payload;
  },
};
