import { MutationTree } from 'vuex';
import { OrganisationAddressState } from './types';
import Vue from 'vue';
import organisationAddress, { OrganisationAddress, Value } from '@/shared/model/organisationAddress';

export const mutations: MutationTree<OrganisationAddressState> = {
  setOrganisationAddresss(state, payload: Value) {
    state.organisationAddresses.items = payload.value.map((x) => organisationAddress.parse(x));
    state.organisationAddresses.total = payload['@odata.count'] || 0;
    state.organisationAddresses.isLoading = false;
  },
  setOrganisationAddresssTotal(state, payload: number) {
    state.organisationAddresses.total = payload;
  },
  setOrganisationAddresssIsLoading(state, payload: boolean) {
    state.organisationAddresses.isLoading = payload;
  },
  setCurrent(state, payload: OrganisationAddress) {
    state.current = payload;
  },
};
