import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { UserFile } from '@/shared/model/userFile';
import ActivitiesSettingsView from '@/views/settings/company/activities/activities.vue';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('menu-document-logs');

const userFileModule = namespace('userFile');
const documentModule = namespace('document');
const authModule = namespace('auth');

@Component({ name: 'menu-document-logs', components: { 'activities-settings-view': ActivitiesSettingsView } })
export default class MenuDocumentLogsView extends Vue {
  //#region Data for `Log create` message (redo later)
  @userFileModule.Getter('getUserFile')
  private getFile!: UserFile;
  @documentModule.Getter('getDocument')
  private getDocument!: any;
  @authModule.Getter('getAccount')
  private getAccount!: any;

  get fileName() {
    return this.getFile?.documentName || 'NO FILE NAME';
  }

  get createFileDate() {
    return DateUtils.isoDateToScreenDateWithLocale(this.getFile?.created, this.$i18n.locale, false, false) || 'NO DATE';
  }

  get userEmail() {
    return this.getAccount?.profile?.name || 'user';
  }

  get folder() {
    return this.getDocument?.folderPath?.trim() || 'Eingang';
  }
  //#endregion
  private logs = ''; // TODO: (ED-211) setup getting logs from backend

  private returnBack() {
    this.$emit('click:return');
  }
}
