import { ActionTree } from 'vuex';
import { DocumentPagesTextState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentPagesText } from '@/shared/backend/documentPagesText';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentPagesText, { Value, DocumentPagesText } from '@/shared/model/documentPagesText';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentPagesTexts');
export const actions: ActionTree<DocumentPagesTextState, RootState> = {
  getDocumentPagesTexts({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDocumentPagesTextsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentPagesTextsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocumentPagesText
      .getDocumentPagesTexts(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentPagesTexts', response.data);
        commit('setDocumentPagesTextsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentPagesTextsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentPagesText({ commit, dispatch }, id: string) {
    return defaultBackendDocumentPagesText
      .getDocumentPagesText(id)
      .then((response: AxiosResponse<DocumentPagesText>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentPagesText({ commit, dispatch }, file: DocumentPagesText) {
    return defaultBackendDocumentPagesText
      .updateDocumentPagesText(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentPagesText;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentPagesText_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentPagesText({ commit, dispatch }, id: string) {
    return defaultBackendDocumentPagesText
      .deleteDocumentPagesText(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentPagesText;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
