import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import driveTypesImportSetting, { DriveTypesImportSetting, Value } from '../model/driveTypesImportSetting';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.DriveTypesImportSetting');
export interface BackendDriveTypesImportSetting {
  getDriveTypesImportSetting(id: string): AxiosPromise<DriveTypesImportSetting>;
  getDriveTypesImportSettings: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteDriveTypesImportSetting(id: string): AxiosPromise;
  updateDriveTypesImportSetting(DriveTypesImportSetting: DriveTypesImportSetting): AxiosPromise<any>;
}

export const defaultBackendDriveTypesImportSetting: BackendDriveTypesImportSetting = {
  getDriveTypesImportSetting(id: string): AxiosPromise<DriveTypesImportSetting> {
    const url = `${URLS.driveTypesImportSettingOdata}/${id}`;
    return instance.get<DriveTypesImportSetting>(url);
  },
  getDriveTypesImportSettings(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.driveTypesImportSettingOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDriveTypesImportSettings${url}`);
    return instance.get<Value>(url);
  },

  deleteDriveTypesImportSetting(id: string): AxiosPromise {
    logger.debug('deleteDriveTypesImportSetting');
    return instance.delete(`${URLS.driveTypesImportSetting}/${id}`);
  },
  updateDriveTypesImportSetting(DriveTypesImportSetting: DriveTypesImportSetting): AxiosPromise<any> {
    logger.debug('updateDriveTypesImportSetting');
    return instance.put<DriveTypesImportSetting>(
      `${URLS.driveTypesImportSetting}/update`,
      driveTypesImportSetting.toAPI(DriveTypesImportSetting)
    );
  },
};
