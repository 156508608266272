import moment from 'moment';
import NumberUtils from './NumberUtils';

export default class DateUtils {
  static toLocalDateIfDate(modelValue: string, locale?: string) {
    if (modelValue && DateUtils.isDateEx(modelValue)) return DateUtils.formatDotDate(modelValue, locale);
    return modelValue;
  }
  public static handleIsoTimezone(dateTime: string) {
    if (!dateTime.includes('Z')) return dateTime;

    const x = new Date();
    const currentTimeZoneOffsetInHours = x.getTimezoneOffset() / 60; // -3 means we 3hours (180 minutes) AHEAD of UTC time, i.e. UTC+3 so `-` we should increase the time  (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset)
    const dateMod = new Date(dateTime);
    dateMod.setHours(dateMod.getHours() - currentTimeZoneOffsetInHours);
    return dateMod.toISOString();
  }

  public static formatDateToDotDate(date: Date) {
    const result = date.toLocaleDateString('de', { year: 'numeric', month: '2-digit', day: '2-digit' });
    return result;
  }

  // 'YYYY-MM-DD' (2021-08-24T08:04:05.562046Z) -> Date (24.08.2021, 08:04)
  public static dotDateTimeFromIsoDateTime = (dateTime: string, dateTimeSepartor: string = ','): string => {
    dateTime = DateUtils.handleIsoTimezone(dateTime);
    const [date, time] = dateTime.split('T');
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');

    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    // let result = new Date(+year, +month - 1, +day, +hour, +minute); // return `Tue Aug 24 2021 08:04:05 GMT+0300 (Moscow Standard Time)`
    const result = `${day}.${month}.${year}${dateTimeSepartor} ${hour}:${minute}`;
    return result;
  };
  public static splitter = '.';
  public static formatDate(date: any) {
    if (!date) return undefined;

    const [year, month, day] = date.split('T')[0].split('-');
    return [day, month, year].join(this.splitter);
  }
  public static parseDate(date: any, locale = 'de') {
    if (!date) return undefined;
    const deSplitter = '.';
    const enSplitter = '/';
    let [day, month, year] = date.split(deSplitter);
    if (locale != 'de') [month, day, year] = date.split(enSplitter);
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  // DD.MM.YYYY (24.08.2021) -> 'YYYY-MM-DD' (2021-08-24)
  public static dotDateToIsoDate = (date?: string) => {
    if (!date) return undefined;
    if (date.includes('-')) return date; // if `ISO` date(time) came then just return it without changes
    const [day, month, year] = date.split('.');

    const result = `${year}-${month}-${day}`;
    return result;
  };

  // 'YYYY-MM-DD' (2021-08-24) ->  DD.MM.YYYY (24.08.2021)
  public static isoDateToDotDate = (date: string) => {
    if (date.includes('.')) return date; // if `dot` date(time) came then just return it without changes
    const [year, month, day] = date.split('-');

    const result = `${day}.${month}.${year}`;
    return result;
  };

  public static isoDateToScreenDateWithLocale = (
    isoDate: string,
    locale: string,
    onlyDate: boolean = false,
    useText: boolean = false
  ) => {
    const deDateSuffics = useText ? ' um ' : ' ';
    const deTimeSuffics = useText ? ' Uhr' : '';
    const enDateSuffics = useText ? ' at ' : ' ';
    const enTimeSuffics = useText ? ' Hours' : '';

    const date = new Date(isoDate);
    if (!(date instanceof Date && !isNaN(date.valueOf()))) return '';
    const enDate = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const enTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const deDate = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const deTime = date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    if (locale.toLowerCase() === 'de' && date) {
      return onlyDate ? deDate : deDate + deDateSuffics + deTime + deTimeSuffics;
    }
    return onlyDate ? enDate : enDate + enDateSuffics + enTime + enTimeSuffics;
  };

  // (DDMMYY | DDMMYYYY | DD.MM.YY | DD.MM.YYYY ) => 'de' ? DD.MM.YYYY  : MM/DD/YYYY
  public static formatDotDate = (date: string, locale: string = 'de') => {
    if (date.includes('.') && date.length <= 10) {
      let [day, month, year] = date.split('.');
      year = year.length === 2 ? `20${year}` : year;
      month = month.length === 2 ? month : `0${month}`;
      day = day.length === 2 ? day : `0${day}`;
      date = locale == 'de' ? `${day}.${month}.${year}` : `${month}/${day}/${year}`;
    }

    if (!date.includes('.')) {
      if (date.length === 8 || date.length === 6) {
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.length === 8 ? date.slice(4) : `20${date.slice(4)}`;
        date = locale == 'de' ? `${day}.${month}.${year}` : `${day}/${month}/${year}`;
      }
    }
    return date;
  };
  public static getFormatRepresent(locale: string): string {
    if (locale.toLowerCase() === 'de') return 'DD.MM.JJJJ';
    if (locale.toLowerCase() === 'en') return 'MM/DD/YYYY';
    return 'DD.MM.JJJJ';
  }
  public static isDate(date: string, locale: string) {
    if (date === null) return false;
    if (!date) return false;
    if (date.length !== 10) return false;
    if (locale.toLowerCase() === 'iso') return /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/.test(date);
    if (locale.toLowerCase() === 'de') return /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(date);
    if (locale.toLowerCase() === 'en') return /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(date);
    else return false;
  }

  // FROM: https://stackoverflow.com/a/58210461/2771704 (2019-10)
  // TODO: (LOW) (ED-510) find more optimal way to detect date , this fields won't be necessary when migrate our `date-field` component
  public static isDateEx(date: any) {
    // convert `DD.MM.YYYY` date to ISO (YYYY-MM-DD) to pass validation (for `.`(dots) the `Date.parse` method is using `MM.DD.YYYY` format, so when you pass `21.12.2021` it will consider it as INVALID date since there is no 21st month)
    if (date.includes('.') && date.length === 10) {
      date = DateUtils.dotDateToIsoDate(date);
    }
    if (NumberUtils.isNumeric(date) && date.length === 6) {
      if (moment(date, 'MMDDYY').isValid()) date = moment(date, 'MMDDYY').format('DD.MM.YYYY');
    }
    if (NumberUtils.isNumeric(date) && date.length === 8) {
      if (moment(date, 'MMDDYYYY').isValid()) date = moment(date, 'MMDDYYYY').format('DD.MM.YYYY');
    }
    if (isNaN(date) && !isNaN(Date.parse(date))) return true;
    else return false;
  }

  public static localToIsoDate(date: string, locale: string): string {
    if (date === null) throw new Error();
    if (date.length < 10) throw new Error();
    if (locale.toLowerCase() === 'de') {
      const m = date.match(/^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)(\d{2})$/);
      return m ? `${m[3]}${m[4]}-${m[2]}-${m[1]}` : '';
    }
    if (locale.toLowerCase() === 'en') {
      const m = date.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)(\d{2})/);
      return m ? `${m[3]}${m[4]}-${m[1]}-${m[2]}` : '';
    } else throw new Error();
  }

  public static formatDateFromUtcNowToDisplayWithTimeZone(date: string, locale: string, showWithTime: boolean = true) {
    const isoDate = new Date(date);
    isoDate.setMinutes(isoDate.getMinutes() - isoDate.getTimezoneOffset());
    if (!(isoDate instanceof Date && !isNaN(isoDate.valueOf()))) return '';
    const enDate = isoDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const enTime = isoDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const deDate = isoDate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const deTime = isoDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    if (locale.toLowerCase() === 'de' && isoDate) {
      return showWithTime ? deDate + ' ' + deTime : deDate;
    }
    return showWithTime ? enDate + ' ' + enTime : enDate;
  }

  public static formatDateMonth(textDate: string) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(textDate);
    const name = month[date.getMonth()];
    return name + ' ' + date.getFullYear();
  }
  //localInputToDot("111224",'de')=> "11.12.2024"
  //localInputToDot("111224",'en')=> "11.12.2024"
  //localInputToDot("11.12.2024",'en')=> "11.12.2024"
  //localInputToDot("12/11/2024",'en')=> "11.12.2024"
  // ( MM/DD/YYYY | DD.MM.YYYY | DDMMYYYY de | MMDDYYYY en )=> "DD.MM.YYYY"
  public static localInputToDotDe(date: string, locale: string = 'de') {
    if (date === null) throw new Error('error date structure');
    if ((date.length === 8 || date.length === 6) && date.match(/^[\d]+$/)) {
      const firstPart = date.slice(0, 2);
      const secondPart = date.slice(2, 4);
      const year = date.length === 8 ? date.slice(4) : `20${date.slice(4)}`;
      date = locale == 'de' ? `${firstPart}.${secondPart}.${year}` : `${secondPart}.${firstPart}.${year}`;
    } else {
      if (date.length != 10) throw new Error('error date structure');
      if (locale.toLowerCase() === 'de') {
        const m = date.match(/^(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)([0-9][0-9])$/);
        if (m) return `${m[1]}.${m[2]}.${m[3]}${m[4]}`;
      }
      if (locale.toLowerCase() === 'en') {
        const m = date.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)(\d{2})/);
        if (m) return `${m[2]}.${m[1]}.${m[3]}${m[4]}`;
      }
      throw new Error('error date structure');
    }
    return date;
  }

  public static isDateDe(date: string) {
    return date.match(/^(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)([0-9][0-9])$/);
  }
}
