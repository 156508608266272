import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import regexSplitter, { RegexSplitter, Value } from '../model/regexSplitter';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.RegexSplitter');
export interface BackendRegexSplitter {
  getRegexSplitter(id: string): AxiosPromise<RegexSplitter>;
  getRegexSplitters: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteRegexSplitter(id: string): AxiosPromise;
  updateRegexSplitter(RegexSplitter: RegexSplitter): AxiosPromise<any>;
}

export const defaultBackendRegexSplitter: BackendRegexSplitter = {
  getRegexSplitter(id: string): AxiosPromise<RegexSplitter> {
    const url = `${URLS.regexSplitterOdata}/${id}`;
    return instance.get<RegexSplitter>(url);
  },
  getRegexSplitters(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.regexSplitterOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=regexSplitterParts($expand=regexSplitterPatternParts)']
    );
    logger.log(`getRegexSplitters${url}`);
    return instance.get<Value>(url);
  },

  deleteRegexSplitter(id: string): AxiosPromise {
    logger.debug('deleteRegexSplitter');
    return instance.delete(`${URLS.regexSplitter}/${id}`);
  },
  updateRegexSplitter(RegexSplitter: RegexSplitter): AxiosPromise<any> {
    logger.debug('updateRegexSplitter');
    return instance.put<RegexSplitter>(`${URLS.regexSplitter}/update`, regexSplitter.toAPI(RegexSplitter));
  },
};
