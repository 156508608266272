import { ActionTree } from 'vuex';
import { TradeChannelImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendTradeChannelImportSetting } from '@/shared/backend/tradeChannelImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tradeChannelImportSetting, { Value, TradeChannelImportSetting } from '@/shared/model/tradeChannelImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.tradeChannelImportSettings');
export const actions: ActionTree<TradeChannelImportSettingState, RootState> = {
  getTradeChannelImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setTradeChannelImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getTradeChannelImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendTradeChannelImportSetting
      .getTradeChannelImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setTradeChannelImportSettings', response.data);
        commit('setTradeChannelImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTradeChannelImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTradeChannelImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setTradeChannelImportSettingsIsLoading', true);
    const searchParams = getters.getTradeChannelImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendTradeChannelImportSetting
      .getTradeChannelImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setTradeChannelImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setTradeChannelImportSettingsIsLoading', false);
      });
  },
  getTradeChannelImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendTradeChannelImportSetting
      .getTradeChannelImportSetting(id)
      .then((response: AxiosResponse<TradeChannelImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTradeChannelImportSetting({ commit, dispatch, rootGetters }, file: TradeChannelImportSetting) {
    commit('setInProgressUpdateTradeChannelImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendTradeChannelImportSetting
      .updateTradeChannelImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TradeChannelImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.tradeChannelImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateTradeChannelImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getTradeChannelImportSetting.clearBeforeImport = payload.value;
    dispatch('updateTradeChannelImportSetting', getters.getTradeChannelImportSetting)
      .then((result: any) => {
        getters.getTradeChannelImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getTradeChannelImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteTradeChannelImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendTradeChannelImportSetting
      .deleteTradeChannelImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TradeChannelImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
