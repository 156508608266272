import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationsExportAllocation, { AllocationsExportAllocation, Value } from '../model/allocationsExportAllocation';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.AllocationsExportAllocation');
export interface BackendAllocationsExportAllocation {
  getAllocationsExportAllocation(id: string): AxiosPromise<AllocationsExportAllocation>;
  getAllocationsExportAllocations: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  getAllocationsExportAllocationByIdExportId: (id: string) => AxiosPromise<AllocationsExportAllocation[]>;
  deleteAllocationsExportAllocation(id: string): AxiosPromise;
  updateAllocationsExportAllocation(AllocationsExportAllocation: AllocationsExportAllocation): AxiosPromise<any>;
}

export const defaultBackendAllocationsExportAllocation: BackendAllocationsExportAllocation = {
  getAllocationsExportAllocation(id: string): AxiosPromise<AllocationsExportAllocation> {
    const url = `${URLS.allocationsExportAllocationOdata}/${id}`;
    return instance.get<AllocationsExportAllocation>(url);
  },
  getAllocationsExportAllocations(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationsExportAllocationOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAllocationsExportAllocations${url}`);
    return instance.get<Value>(url);
  },

  getAllocationsExportAllocationByIdExportId(id: string): AxiosPromise<AllocationsExportAllocation[]> {
    return instance.get<AllocationsExportAllocation[]>(
      `${URLS.allocationsExportAllocation}/GetAllocationsExportAllocationQueryAllByExportId/${id}`
    );
  },

  deleteAllocationsExportAllocation(id: string): AxiosPromise {
    logger.debug('deleteAllocationsExportAllocation');
    return instance.delete(`${URLS.allocationsExportAllocation}/${id}`);
  },
  updateAllocationsExportAllocation(AllocationsExportAllocation: AllocationsExportAllocation): AxiosPromise<any> {
    logger.debug('updateAllocationsExportAllocation');
    return instance.put<AllocationsExportAllocation>(
      `${URLS.allocationsExportAllocation}/update`,
      allocationsExportAllocation.toAPI(AllocationsExportAllocation)
    );
  },
};
