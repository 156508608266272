import { stat } from 'fs';
import { Allocation } from '@/shared/model/allocation';
import { MutationTree } from 'vuex';
import { DocumentState } from './types';
import doc, { Value, Document } from '@/shared/model/document';
import { Address } from '@/shared/model/address';
import { DocumentProperty } from '@/shared/model/documentProperty';
import { Comment } from '@/shared/model/comment';
import { GobdData } from '@/shared/model/gobdData';
import { OrganisationDocumentProperty } from '@/shared/model/organisationDocumentProperty';
import { FilterBntValues, FilterBtnData } from '@/shared/model/smallPayloadModels/filterBtnData';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST, documentChildEntities, odataStrings } from '@/shared/utils/Constants';
import ODataFilterBuilder from 'odata-filter-builder';
import { DefaultBackendHelper } from '@/shared/utils/backendHelper';
import { URLS } from '@/shared/backend';
import { ChipData } from '@/shared/model/ChipData';
import { ActionLog } from '@/shared/model/actionLog';
import DateUtils from '@/shared/utils/DateUtils';
import { DocumentStatusValues } from '@/shared/model/documentStatus';
import documentRoleRight, { Value as DocumentRoleRightValue } from '@/shared/model/documentRoleRight';
import { DocumentReminder } from '@/shared/model/documentReminder';

export const mutations: MutationTree<DocumentState> = {
  setSelectionMode(state, payload: boolean) {
    state.selectionMode = payload;
  },
  setSelectedDocumentIds(state, payload: string[]) {
    state.selectedDocumentIds = payload;
  },
  setHasActiveSearch(state, payload: boolean) {
    state.hasActiveSearch = payload;
  },
  setIsMetadataProcessing(state, payload: boolean) {
    state.isMetadataProcessing = payload;
  },
  setDocumentSearchData(state, payload: any) {
    state.documentSearchData = payload;
  },

  setDocument(state, payload: Document) {
    state.document = payload;
  },
  setDocumentTemp(state, payload: Document) {
    state.documentTemp = payload;
  },
  setDocumentIsLoading(state, payload: boolean) {
    state.documentIsLoading = payload;
  },
  setDocumentText(state, payload?: any) {
    if (state.document && payload.id == state.document.id) {
      state.document.text = payload.text;
    }
  },

  setDocumentInDocumentsState(state, payload: Document) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.id);
    if (index >= 0 && payload?.id) {
      //store image preview if loaded early
      if (!payload.imagePreview) payload.imagePreview = state.documents.items[index].imagePreview;
      Object.assign(state.documents.items[index], payload);
    }
  },

  // (ED-704) set document `documentPropertys` to avoid extra request to backend
  setDocumentPropertys(state, payload?: DocumentProperty[]) {
    //set after create
    state.document!.documentPropertys = payload;
  },
  setDocumentsProperties(
    state,
    payload: { items: DocumentProperty[]; anabledFavoritePropertysIds: OrganisationDocumentProperty[] }
  ) {
    for (const key in payload.items) {
      const element = payload.items[key];
      const orgPropertyIndex = payload.anabledFavoritePropertysIds.findIndex((x) => x.fieldId === element.fieldId);
      if (orgPropertyIndex == -1) continue;
      const index = state.documents.items.findIndex((x: Document) => x.id === element?.documentId);
      if (index >= 0) {
        //update document properties without remove existing properties
        payload.items.forEach((element) => {
          const indexdp =
            state.documents.items[index].documentPropertys?.findIndex((x) => x.fieldId == element?.fieldId) ?? -1;
          if (indexdp >= 0) state.documents.items[index].documentPropertys![indexdp].value = element.value;
          else state.documents.items[index].documentPropertys?.push(element);
        });

        (state.documents.items[index] as any)['enabledFavoriteValue' + (orgPropertyIndex + 1)] = element.value;
      }
    }
  },
  setDocumentsGobd(state, payload: GobdData) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.documentId);
    if (index >= 0 && payload?.id) {
      state.documents.items[index].documentGobdDatum = payload;
    }
  },
  setDocumentsComments(state, payload: { id: string; documentDetailComments: Comment[]; total?: number }) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.id);
    if (index >= 0 && payload?.id) {
      state.documents.items[index].documentDetailComments = payload.documentDetailComments;
      state.documents.items[index].documentHasNotes = (payload.total ?? payload.documentDetailComments.length) > 0;
    }
  },
  setDocumentsActionLogs(state, payload: { id: string; actionLogs: ActionLog[] }) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.id);
    const indexA = payload.actionLogs.findIndex((x) => x.documentId === payload?.id);
    if (index >= 0 && payload?.id && indexA >= 0) {
      state.documents.items[index].actionLogs = payload.actionLogs;
      switch (payload.actionLogs[indexA].threadType) {
        case 'circular':
          Object.assign(state.documents.items[index].threadCircular, payload.actionLogs[indexA]);
          break;
        case 'task':
          Object.assign(state.documents.items[index].threadTask, payload.actionLogs[indexA]);
          break;
        case 'quersion':
          Object.assign(state.documents.items[index].threadQuestion, payload.actionLogs[indexA]);
          break;
        default:
          break;
      }
    }
    if (state.document?.id == payload?.id && payload?.id && indexA >= 0) {
      state.document.actionLogs = payload.actionLogs;
      switch (payload.actionLogs[indexA].threadType) {
        case 'circular':
          Object.assign(state.document.threadCircular, payload.actionLogs[indexA]);
          break;
        case 'task':
          Object.assign(state.document.threadTask, payload.actionLogs[indexA]);
          break;
        case 'quersion':
          Object.assign(state.document.threadQuestion, payload.actionLogs[indexA]);
          break;
        default:
          break;
      }
    }
  },
  setDocumentsAllocation(state, doc: Document) {
    const index = state.documents.items.findIndex((x: Document) => x.id === doc.id);
    if (index >= 0 && doc.id) {
      state.documents.items[index].haveAllocations = doc.haveAllocations;
    }
  },
  pushToDocumentIdsForWhichDocumentPropertiesIsLoading(state, documentPropertyId: string) {
    state.documentIdsForWhichDocumentPropertiesIsLoading.push(documentPropertyId);
  },
  popFromDocumentIdsForWhichDocumentPropertiesIsLoading(state, documentPropertyId: string) {
    const index = state.documentIdsForWhichDocumentPropertiesIsLoading.indexOf(documentPropertyId);
    state.documentIdsForWhichDocumentPropertiesIsLoading.splice(index, 1);
  },
  // (ED-995) set document `documentDetailComments` (`Notes`) to see highlight for `Notes` button immediately in `document-preview`
  setDocumentNotes(state, payload: Comment[]) {
    state.document!.documentDetailComments = payload;
  },
  // (ED-773) set document `Gobd` to avoid extra request to backend
  setDocumentGobd(state, payload: GobdData) {
    //set after create
    state.document!.documentGobdDatum = payload;
  },

  setDocumentAddress(state, payload?: Address) {
    if (state.document) {
      state.document.address = payload;
      state.document.addressId = payload?.id || '';
      state.document.adresseKundennummer = payload?.konto || '';
      state.document.adresseSeriennummer = payload?.seriennummer || '';
      state.document.adresseKennzeichen = payload?.kennzeichen || '';
    }
  },
  setDocumentsAddress(state, payload: { address: Address; documentIds: Array<string> }) {
    const docIds = payload.documentIds;
    if (state.documents && docIds && docIds.length > 0) {
      docIds.forEach((docId) => {
        const index = state.documents.items.findIndex((x: Document) => x.id === docId);
        if (index >= 0 && docId) {
          state.documents.items[index].address = payload.address;
          state.documents.items[index].addressId = payload.address?.id;
          state.documents.items[index].adresseKundennummer = payload.address?.konto;
          state.documents.items[index].adresseSeriennummer = payload.address?.seriennummer;
          state.documents.items[index].adresseKennzeichen = payload.address?.kennzeichen;
        }
      });
    }
  },

  setDocumentsAllocations(state, payload: { documentId: string; allocations: Allocation[] }) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.documentId);
    if (index >= 0 && payload?.documentId) {
      state.documents.items[index].allocations = payload.allocations;
      state.documents.items[index].haveAllocations = payload.allocations.length > 0;
    }
  },

  updateDocumentRemindersOnCard(state, payload: { documentId: string; documentReminders: any }) {
    const index = state.documents.items.findIndex((x: Document) => x.id === payload?.documentId);
    if (index >= 0 && payload?.documentId) {
      let maxdate = new Date(-8640000000000000);
      if (payload.documentReminders != null && payload.documentReminders != undefined) {
        payload.documentReminders.value.forEach((element: DocumentReminder) => {
          const current = new Date(element.startAt);
          current.setDate(current.getDate() + element.days * element.times);
          if (current > maxdate) maxdate = current;
        });
        if (maxdate > new Date(-8640000000000000))
          state.documents.items[index].hasActiveReminders = maxdate >= new Date();
        else state.documents.items[index].hasActiveReminders = false;
      } else {
        state.documents.items[index].hasActiveReminders = false;
      }
    }
  },

  resetDocument(state, payload?: Document) {
    state.document = Object.assign({}, payload);
  },
  setDocuments(state, payload: Value) {
    state.documents.items = payload.value.map((x) => doc.parse(x));
    state.documents.total = payload['@odata.count'] || 0;
    state.documents.isLoading = false;
  },
  setDocumentsTemp(state, payload: Value) {
    state.documents = {
      items: [],
      isLoading: true,
      total: 0,
      searchParams: {
        dataOption: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['DocumentNumber'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        },
        filter: '',
        orClauseFieldsIds: [],
      },
    };
    state.documentsTemp.items = payload.value.map((x) => doc.parse(x));
    state.documentsTemp.total = payload['@odata.count'] || 0;
    state.documentsTemp.isLoading = false;
  },
  setImagePreviewInDoocumentsByDocumentId(state, payload: { previewFileId: string; imagePreview: string }) {
    const index = state.documents.items.findIndex((x: Document) => x.previewFileId === payload.previewFileId);
    if (index >= 0) {
      state.documents.items[index].imagePreview = payload.imagePreview;
    }
  },
  setTempToReal(state, payload: any) {
    state.documents = { ...state.documentsTemp };
  },
  resetLastLoadDate(state, payload: any) {
    state.lastLoadDocumentsDate = new Date(0);
  },
  updateLastLoadDate(state, payload: any) {
    state.lastLoadDocumentsDate = new Date();
  },
  setDocumentsTotal(state, payload: number) {
    state.documents.total = payload;
  },
  setDocumentsIsLoading(state, payload: boolean) {
    if (payload == true) {
      state.documents.items = [];
      state.documents.total = 0;
      state.documents.isLoading = true;
    }
    state.documents.isLoading = payload;
  },
  setDocumentsAllCount(state, payload: number) {
    state.documentsAllCount = payload;
  },
  setDocumentsDeleted(state, payload: Value) {
    state.documentsDeleted.items = payload.value.map((x) => doc.parse(x));
    state.documentsDeleted.total = payload['@odata.count'] || 0;
    state.documentsDeleted.isLoading = false;
  },
  setDocumentsDeletedTotal(state, payload: number) {
    state.documentsDeleted.total = payload;
  },
  setDocumentsDeletedIsLoading(state, payload: boolean) {
    state.documentsDeleted.isLoading = payload;
  },

  setSearchModes(state, payload: string) {
    state.documentsSearchPayload.searchModes = payload;
  },
  setDocumentSearchGobdStatus(state, payload: any) {
    state.documentSearchGobdStatus = payload;
  },
  resetDocumentsSearchPayload(state, payload: boolean) {
    state.documentsSearchPayload = {
      searchParams: {
        dataOption: {
          page: 1,
          itemsPerPage: 50, // (ED-236) Discussed `50` items with german customer (his wish)
          sortBy: ['DocumentNumber'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        },
        filter: '',
        orClauseFieldsIds: [],
      },
      showDeleted: false,
      isOnlyCurrentFolderDocs: false,
      folderId: undefined,
      searchModes: '',
      searchData: { searchWord: '', field: '', searchType: 'AND' },
      chipData: undefined,
      chipDataArray: [],
      filterData: [],
    };
  },
  addGetDocumentsDateFilter(
    state,
    payload: {
      type: { id: string; value: string };
      fromISO: string;
      untilISO: string;
      fromLocal: string;
      untilLocal: string;
    }
  ) {
    switch (payload.type.id) {
      case 'Datum GoBD':
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `Datum GoBD: ${payload.fromLocal}-${payload.untilLocal}`,
          field: 'DocumentGobdDatum/CreatedAt',
          searchWord: '',
          from: `${payload.fromISO}`, //`${payload.fromISO}T00:00:00.000Z`,
          until: payload.untilISO ? `${payload.untilISO}` : '', //payload.untilISO ? `${payload.untilISO}T23:59:59.999Z` : '',
        });
        break;
      case 'Hochgeladen':
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `Hochgeladen: ${payload.fromLocal}-${payload.untilLocal}`,
          field: 'CreatedAt',
          searchWord: '',
          from: `${payload.fromISO}`, //`${payload.fromISO}T00:00:00.000Z`,
          until: payload.untilISO ? `${payload.untilISO}` : '', //payload.untilISO ? `${payload.untilISO}T23:59:59.999Z` : '',
        });
        break;
      case 'Kontiert':
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `Kontiert: ${payload.fromLocal}-${payload.untilLocal}`,
          field: 'Allocations/CreatedAt',
          searchWord: '',
          from: `${payload.fromISO}`, //`${payload.fromISO}T00:00:00.000Z`,
          until: payload.untilISO ? `${payload.untilISO}` : '', //payload.untilISO ? `${payload.untilISO}T23:59:59.999Z` : '',
        });
        break;
      case 'Rechnungsdatum':
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `Rechnungsdatum: ${payload.fromLocal}-${payload.untilLocal}`,
          field: 'DocumentGobdDatum/InvoiceDate',
          searchWord: '',
          from: `${payload.fromISO}`, //`${payload.fromISO}T00:00:00.000Z`,
          until: payload.untilISO ? `${payload.untilISO}` : '', //payload.untilISO ? `${payload.untilISO}T23:59:59.999Z` : '',
        });
        break;
      case DocumentStatusValues.Payed:
      case DocumentStatusValues.Accepted:
      case DocumentStatusValues.FinalCheck:
      case DocumentStatusValues.Extra1:
      case DocumentStatusValues.Extra2:
      case DocumentStatusValues.Extra3:
      case DocumentStatusValues.Extra4:
      case DocumentStatusValues.Extra5:
      case DocumentStatusValues.Extra6:
      case DocumentStatusValues.Extra7:
      case DocumentStatusValues.Extra8:
      case DocumentStatusValues.Extra9:
      case DocumentStatusValues.Extra10:
      case DocumentStatusValues.Extra11:
      case DocumentStatusValues.Extra12:
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `${payload.type.value}: ${payload.fromLocal}-${payload.untilLocal}`,
          field: payload.type.id,
          searchWord: '',
          from: `${payload.fromISO}`, //`${payload.fromISO}T00:00:00.000Z`,
          until: payload.untilISO ? `${payload.untilISO}` : '', //payload.untilISO ? `${payload.untilISO}T23:59:59.999Z` : '',
        });
        break;
      default: {
        state.documentsSearchPayload.chipDataArray.push({
          chipText: `${payload.type.value}: ${payload.fromLocal}-${payload.untilLocal}`,
          field: payload.type.id,
          searchWord: '',
        });
      }
    }
  },
  makeGetDocumentsSearchUrl(
    state,
    payload: {
      searchParams: SearchParams;
      showDeleted: boolean;
      folderId?: string;
      searchData?: { searchWord: string; field: string; searchType: string };
      chipData?: string;
      chipDataArray: ChipData[];
      filterData?: Array<FilterBtnData>;
    }
  ) {
    const { searchParams, showDeleted, folderId, searchData, chipData, chipDataArray, filterData } = payload;
    const mainOdfb = ODataFilterBuilder.and();
    const odfb = ODataFilterBuilder(searchData?.searchType === 'AND' ? 'and' : 'or');
    mainOdfb.and(ODataFilterBuilder.and().eq(CONST.IsDeleted, showDeleted, false));
    //ATTENTION are we realy need to show merged files in delete????
    if (showDeleted) mainOdfb.and(ODataFilterBuilder.and().eq(CONST.IsMerged, false, false));

    if (searchData?.searchWord) {
      // addDocumentNumberFilter(searchData.searchWord, searchData.field, searchData.searchType, odfb);
      // addDocumentChildEntitiesFilter(searchData.searchWord, searchData.field, searchData.searchType, odfb);
      // addDocumentPagesTextFilter(searchData.searchWord, searchData.field, searchData.searchType, odfb);
      // addStringAdressFilter(searchData.searchWord, searchData.field, searchData.searchType, odfb);
    }
    if (chipDataArray) addChipDataFilter(chipDataArray, searchData?.searchType ?? 'or', odfb);

    if (filterData && filterData?.length > 0) {
      filterData.forEach((el) => {
        if (!el.docStateStatus) return; // (ED-385) ignore `Kontiert` filtering button values for now

        addKontiertFilter(el, odfb);
        addVorlagenFilter(el, odfb);
        addMessageFilter(el, odfb);
        addAdressFilter(el.docStateStatus, el.value, odfb);
        addGodbFilter(el, odfb);
        addFinalCheckFilter(el, odfb);
        addReminderFilter(el, odfb);
        addOcrFilter(el, odfb);
      });
    }

    const folderIdArr = Array.isArray(folderId) ? folderId : folderId?.split(',');
    const byFolder = new ODataFilterBuilder().in(CONST.FolderId, folderIdArr || [], false);
    if (folderId) odfb.and(byFolder);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      mainOdfb.and(odfb),

      [
        //`$expand=DocumentPropertys($select=id,fieldid,documentid,value),DataOfMergedDocuments($select=id,documentid,olddocumentid,documentnumber),DocumentStates($select=id,name,updatedAt;$top=50),ActionLogs,Allocations($count=true;$top=0),DocumentDetailComments($count=true;$top=0),DocumentGobdDatum($select=id,InvoiceAddressVerified,PerformanceDateVerified,TaxNumberVerified,Amount,InvoiceDate,InvoiceNumber,SupplierAccount,AmountVerified,SaveType,SerialNumber),DocumentReminders`,
        //slow request speed DocumentStates,DataOfMergedDocuments,DocumentPropertys,DocumentDetailComments($count=true),
        `$expand=ThreadQuestion,ThreadTask,ThreadCircular,Allocations($count=true)`,
        //
        `$select=*`,
      ]
    );
    state.documentsSearchUrlOld = state.documentsSearchUrl;
    state.documentsSearchUrl = url;
  },
  setDocumentRoleRights(state, payload: DocumentRoleRightValue) {
    state.documentRoleRights.items = payload.value.map((x) => documentRoleRight.parse(x));
    state.documentRoleRights.total = payload['@odata.count'] || 0;
    state.documentRoleRights.isLoading = false;
  },
  setDocumentRoleRightsTotal(state, payload: number) {
    state.documentRoleRights.total = payload;
  },
  setDocumentRoleRightsIsLoading(state, payload: boolean) {
    state.documentRoleRights.isLoading = payload;
  },
  setUpdateInProgress(state, payload: boolean) {
    state.updateInProgress = payload;
  },
  addDocumentsNeedUpdate(state, payload: { docId: string; date: Date }) {
    state.documentsNeedUpdate.push(payload);
    state.isDocumentsNeedUpdate = true;
  },
  removeDocumentsNeedUpdate(state, docId: string) {
    const index = state.documentsNeedUpdate.findIndex((x: { docId: string }) => x.docId === docId);
    if (index === -1) return;
    const time = new Date(state.documentsNeedUpdate[index]?.date);
    const now = Date.now();
    if (now - time.getMilliseconds() > 0) state.documentsNeedUpdate.splice(index, 1);
    state.isDocumentsNeedUpdate = state.documentsNeedUpdate.length > 0;
  },
  clearDocumentsNeedUpdate(state, payload: any) {
    const now = Date.now();
    state.documentsNeedUpdate = state.documentsNeedUpdate.filter(
      (x: { date: any }) => !(now - new Date(x.date).getMilliseconds() > 0)
    );
    state.isDocumentsNeedUpdate = state.documentsNeedUpdate.length > 0;
  },
};
function addChipDataFilter(chipDataValues: ChipData[], searchType: string, odfb: ODataFilterBuilder) {
  chipDataValues.forEach((chip: ChipData) => {
    const { searchWord, field } = chip;

    if (searchWord && field) {
      addDocumentChildEntitiesFilter(searchWord, field, searchType, odfb);
      addDocumentNumberFilter(searchWord, field, searchType, odfb);
      addDocumentPagesTextFilter(searchWord, field, searchType, odfb);
      addStringAdressFilter(searchWord, field, searchType, odfb);
      addDocumentDetailCommentsMessageFilter(searchWord, field, searchType, odfb);
      addAddressLastNameFilter(searchWord, field, searchType, odfb);
      addAddressFirstNameFilter(searchWord, field, searchType, odfb);
      addInvoiceNumberFilter(searchWord, field, searchType, odfb);
      addSupplierAccountFilter(searchWord, field, searchType, odfb);
      addDocumentPropertyFilter(searchWord, field, searchType, odfb);
      addAllocationPropertiesPostingTextFilter(searchWord, field, searchType, odfb);
    }
    addGodbDateFromUntile(chip, searchType, odfb);
    addGodbInvoiceDateFromUntile(chip, searchType, odfb);
    addCreatedAt(chip, searchType, odfb);
    addAccountizedCreatedAt(chip, searchType, odfb); //Kontiert-allocations.CreatedAt
    addDocumentStatesCreatedAt(chip, searchType, odfb); //Kontiert-allocations.CreatedAt
    addMergedDocumentFilter(chip, searchType, odfb);
  });
}

function addMergedDocumentFilter(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (chip.field == 'DocumentNumber')
    odfb.or(ODataFilterBuilder.and().and(`DataOfMergedDocuments/any(a: a/DocumentNumber eq ${chip.searchWord})`));
}

function addDocumentChildEntitiesFilter(
  searchWord: string,
  field: string,
  searchType: string,
  odfb: ODataFilterBuilder
) {
  if (searchWord && documentChildEntities.includes(field)) {
    makeStringFilter(`tolower(${field})`, searchWord, searchType, odfb);
  }
}

function addGodbDateFromUntile(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (chip.field == 'DocumentGobdDatum/CreatedAt') {
    makeDateFilter(chip.field, chip.from, chip.until, searchType, odfb);
  }
}
function addGodbInvoiceDateFromUntile(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (chip.field == 'DocumentGobdDatum/InvoiceDate') {
    makeDateFilter(chip.field, chip.from, chip.until, searchType, odfb);
  }
}
function addCreatedAt(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (chip.field == 'CreatedAt') {
    makeDateFilter(chip.field, chip.from, chip.until, searchType, odfb);
  }
}
function addAccountizedCreatedAt(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (chip.field == 'Allocations/CreatedAt') {
    const innerOdfb = ODataFilterBuilder().or(
      `Allocations/any(a:((a/CreatedAt gt ${chip.from}) and (a/CreatedAt lt ${chip.until})))`
    );
    if (innerOdfb.toString().length > 0) searchType.toLowerCase() == 'or' ? odfb.or(innerOdfb) : odfb.and(innerOdfb);
  }
}
function addDocumentStatesCreatedAt(chip: ChipData, searchType: string, odfb: ODataFilterBuilder) {
  if (
    chip.field == DocumentStatusValues.Payed ||
    chip.field == DocumentStatusValues.Accepted ||
    chip.field == DocumentStatusValues.FinalCheck ||
    chip.field == DocumentStatusValues.Extra1 ||
    chip.field == DocumentStatusValues.Extra2 ||
    chip.field == DocumentStatusValues.Extra3 ||
    chip.field == DocumentStatusValues.Extra4 ||
    chip.field == DocumentStatusValues.Extra5 ||
    chip.field == DocumentStatusValues.Extra6 ||
    chip.field == DocumentStatusValues.Extra7 ||
    chip.field == DocumentStatusValues.Extra8 ||
    chip.field == DocumentStatusValues.Extra9 ||
    chip.field == DocumentStatusValues.Extra10 ||
    chip.field == DocumentStatusValues.Extra11 ||
    chip.field == DocumentStatusValues.Extra12
  ) {
    const innerOdfb = ODataFilterBuilder().or(
      `documentStates/any(a:((a/CreatedAt gt ${chip.from}) and (a/CreatedAt lt ${chip.until}) and contains(tolower(a/Name),'${chip.field}')))`
    );
    if (innerOdfb.toString().length > 0) searchType.toLowerCase() == 'or' ? odfb.or(innerOdfb) : odfb.and(innerOdfb);
  }
}
function addDocumentNumberFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == CONST.DocumentNumber) {
    makeFilter(CONST.DocumentNumber, +searchWord, searchType, odfb);
  }
}

// search samples: N00672159552 125840 wo2477a
function addDocumentPagesTextFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == 'Text') {
    //const field = `DocumentPagesText/PagesText`;
    //const field = `tolower(DocumentPagesText/PagesText)`;
    const field = `DocumentPagesText/PagesText`;
    const val = searchWord.trim().toLowerCase().replace(/\'/g, "''");
    if (searchType.toLowerCase() == 'or') odfb.or(ODataFilterBuilder.or().contains(field, val));
    else odfb.and(ODataFilterBuilder.and().contains(field, val));
  }
}
function addDocumentDetailCommentsMessageFilter(
  searchWord: string,
  field: string,
  searchType: string,
  odfb: ODataFilterBuilder
) {
  if (field == 'Notes' || field == 'Note' || field == 'Notiz') {
    const val = searchWord.trim().toLowerCase().replace(/\'/g, "''");
    if (searchType.toLowerCase() == 'or')
      odfb.or(`DocumentDetailComments/any(a:contains(tolower(a/Message),'${val}'))`);
    else odfb.and(`DocumentDetailComments/any(a:contains(tolower(a/Message),'${val}'))`);
  }
}
function addAddressLastNameFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == 'AddressLastName') {
    makeStringFilter('Address/Nachname', searchWord, searchType, odfb);
  }
}
function addAddressFirstNameFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == 'AddressFirstName') {
    makeStringFilter('Address/Vorname', searchWord, searchType, odfb);
  }
}
function addInvoiceNumberFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == 'InvoiceNumber') {
    makeStringFilter('DocumentGobdDatum/InvoiceNumber', searchWord, searchType, odfb);
  }
}
function addSupplierAccountFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field == 'SupplierAccount') {
    makeStringFilter('DocumentGobdDatum/SupplierAccount', searchWord, searchType, odfb);
  }
}
function addDocumentPropertyFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field.startsWith('documentPropertys/')) {
    const val = searchWord.trim().toLowerCase().replace(/\'/g, "''");
    if (searchType.toLowerCase() == 'or') odfb.or(`DocumentPropertys/any(a:contains(tolower(a/Value),'${val}'))`);
    else
      odfb.and(
        `DocumentPropertys/any(a:contains(tolower(a/Value),'${val}') and a/OrganisationDocumentProperty/Name eq '${field.replace(
          'documentPropertys/',
          ''
        )}')`
      );
  }
}
function addAllocationPropertiesPostingTextFilter(
  searchWord: string,
  field: string,
  searchType: string,
  odfb: ODataFilterBuilder
) {
  if (field.startsWith('AllocationPropertiesPostingText')) {
    const val = searchWord.trim().toLowerCase().replace(/\'/g, "''");
    const postingTextFieldId = '53204fe8-b606-aab2-3c55-ecc900000000';
    if (searchType.toLowerCase() == 'or')
      odfb.or(
        `Allocations/any(x:x/AllocationsProperties/any(a:contains(tolower(a/Value), '${val}') and a/Key eq '${postingTextFieldId}'))`
      );
    else
      odfb.and(
        `Allocations/any(x:x/AllocationsProperties/any(a:contains(tolower(a/Value), '${val}') and a/Key eq '${postingTextFieldId}'))`
      );
  }
}
function addKontiertFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  if (el.docStateStatus.toLowerCase() === 'kontiert') {
    if (el.value === FilterBntValues.yes) {
      thisOdfb.and(ODataFilterBuilder.and().and('Allocations/any()')); // `ne` = NOT EQUAL
    }
    if (el.value === FilterBntValues.no) {
      thisOdfb.and(ODataFilterBuilder.and().not('Allocations/any()'));
    }
  }
}
function makeStringFilter(
  fieldId: string,
  searchWord: string,
  searchType: string | 'or' | 'and',
  odfb: ODataFilterBuilder
) {
  const field = `tolower(cast(${fieldId}, 'Edm.String'))`;
  const val = searchWord.trim().toLowerCase().replace(/\'/g, "''");
  if (searchType.toLowerCase() == 'or') odfb.or(`indexof(${field}, '${val}') gt -1`);
  else odfb.and(`indexof(${field}, '${val}') gt -1`);
}
function makeDateFilter(
  fieldId: string,
  from: string | undefined,
  untile: string | undefined,
  searchType: string | 'or' | 'and',
  odfb: ODataFilterBuilder
) {
  let innerOdfb = ODataFilterBuilder.and();
  if (from) innerOdfb = innerOdfb.gt(fieldId, `${from}`, false);
  if (untile) innerOdfb = innerOdfb.lt(fieldId, untile, false);
  if (innerOdfb.toString().length > 0) searchType.toLowerCase() == 'or' ? odfb.or(innerOdfb) : odfb.and(innerOdfb);
}
function makeFilter(
  fieldId: string,
  searchWord: string | number,
  searchType: string | 'or' | 'and',
  odfb: ODataFilterBuilder
) {
  const field = (x: any) => `${fieldId}`;
  const val = searchWord;
  if (searchType.toLowerCase() == 'or') odfb.or(ODataFilterBuilder.or().eq(field, val, false));
  else odfb.and(ODataFilterBuilder.and().eq(field, val, false));
}
function addVorlagenFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  // `vorlagen` is `Template`
  if (el.docStateStatus.toLowerCase() === 'templates') {
    if (el.value === FilterBntValues.yes) {
      thisOdfb.and(ODataFilterBuilder.and().and('templateMarkups/any()'));
    }
    if (el.value === FilterBntValues.no) {
      thisOdfb.and(ODataFilterBuilder.and().not('templateMarkups/any()'));
    }
  }
}

function addMessageFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  if (el.docStateStatus.toLowerCase() === 'qa') {
    thisOdfb.and(ODataFilterBuilder.and().and('actionLogs/any(a: a/closed eq false)'));
  }
  if (el.docStateStatus.toLowerCase() === 'my-qa') {
    thisOdfb.and(ODataFilterBuilder.and().and(`actionLogs/any(a: a/closed eq false and a/userId eq ${el.value})`));
  }
}

function addStringAdressFilter(searchWord: string, field: string, searchType: string, odfb: ODataFilterBuilder) {
  if (field.toLowerCase() == CONST.AddressId.toLowerCase()) {
    makeStringFilter(CONST.AddressId, searchWord, searchType, odfb);
  }
}
function addAdressFilter(field: string, value: string, thisOdfb: ODataFilterBuilder) {
  if (field.toLowerCase() === 'adresse') {
    if (value === FilterBntValues.yes) {
      thisOdfb.and(ODataFilterBuilder.and().ne(`addressId`, null)); // `ne` = NOT EQUAL
    }
    if (value === FilterBntValues.no) {
      thisOdfb.and(ODataFilterBuilder.and().eq(`addressId`, null));
    }
  }
}

//GOBD
function addGodbFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  if (el.docStateStatus.toLowerCase() === 'gobd') {
    if (el.value === FilterBntValues.yes) {
      // all => without filter
    }
    if (el.value === FilterBntValues.no) {
      //!yellow !blue !green
      thisOdfb.and(
        ODataFilterBuilder.and()
          .eq(`isDocumentStateHasGobdYellow`, false)
          .eq(`isDocumentStateHasGobdGreen`, false)
          .eq(`isDocumentStateHasGobdBlue`, false)
      );
    }
    if (el.value === FilterBntValues.incomplete) {
      //yellow
      thisOdfb.eq(`isDocumentStateHasGobdYellow`, true);
    }
    if (el.value === FilterBntValues.manual) {
      //green
      thisOdfb.eq('isDocumentStateHasGobdGreen', true);
    }
    if (el.value === FilterBntValues.auto) {
      //blue
      thisOdfb.eq(`isDocumentStateHasGobdBlue`, true);
    }
  }
}

function addFinalCheckFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  switch (el.docStateStatus.toLowerCase()) {
    case 'payed':
    case 'accepted':
    case 'final_check':
    case 'extra1':
    case 'extra2':
    case 'extra3':
    case 'extra4':
    case 'extra5':
    case 'extra6':
    case 'extra7':
    case 'extra8':
    case 'extra9':
    case 'extra10':
    case 'extra11':
    case 'extra12': {
      if (el.value === FilterBntValues.yes) {
        thisOdfb.and(
          ODataFilterBuilder.and().and(
            `documentStates/any(a:contains(tolower(a/Name), '${el.docStateStatus.toLowerCase()}'))`
          )
        );
      }
      if (el.value === FilterBntValues.no) {
        thisOdfb.and(
          ODataFilterBuilder.and().not(
            `documentStates/any(a:contains(tolower(a/Name), '${el.docStateStatus.toLowerCase()}'))`
          )
        );
      }
    }
  }
}

function addReminderFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  const todayDateIso = new Date().toISOString().slice(0, 10);
  if (el.docStateStatus.toLowerCase() === 'reminder') {
    if (el.value === FilterBntValues.yes) {
      thisOdfb.and(ODataFilterBuilder.and().eq(`hasActiveReminders`, true));
    }
    if (el.value === FilterBntValues.no) {
      thisOdfb.and(ODataFilterBuilder.and().eq(`hasActiveReminders`, false));
    }
  }
}

function addOcrFilter(el: FilterBtnData, thisOdfb: ODataFilterBuilder) {
  const todayDateIso = new Date().toISOString().slice(0, 10);
  if (el.docStateStatus.toLowerCase() === 'ocr') {
    if (el.value === FilterBntValues.yes) {
      thisOdfb.and(ODataFilterBuilder.and().ne(`ocrdetectionDate`, null));
    }
    if (el.value === FilterBntValues.no) {
      thisOdfb.and(ODataFilterBuilder.and().eq(`ocrdetectionDate`, null));
    }
  }
}
