import { GetterTree } from 'vuex';
import { CostCentreImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CostCentreImportSettingState, RootState> = {
  ['getCostCentreImportSettings']: (state) => state.costCentreImportSettings,
  ['getCostCentreImportSettingsIsLoading']: (state) => state.costCentreImportSettings?.isLoading,
  ['getCostCentreImportSettingsTotal']: (state) => state.costCentreImportSettings?.total,
  ['getCostCentreImportSettingsSearchParams']: (state) => state.costCentreImportSettings?.searchParams,
  ['getCostCentreImportSetting']: (state) => state.costCentreImportSetting,
  ['getInProgressUpdateCostCentreImportSetting']: (state) => state.inProgressUpdateCostCentreImportSetting,
};
