import doc, { Document } from '@/shared/model/document';
import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import RouterUtils from '@/shared/utils/RouterUtils';
import document from '@/shared/model/document';

const logger = new Logger('documents-delete-dialog');
const documentModule = namespace('document');

@Component
export default class DocumentsDeleteDialog extends Vue {
  @documentModule.Action('updateDocuments')
  private actionUpdateDocuments!: any;
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: false })
  private deleteAll!: boolean;

  @PropSync('value', { default: [] })
  private model!: any;

  private emptyDocument: Document = doc.parse({});

  get isTrashRoute() {
    return this.$route?.path === '/trash';
  }

  formatPayloadForMultipleDocuments() {
    const documentIdsWithData = {
      documentIds: this.deleteAll ? [] : this.model,
      commonAddressId: null,
      isUpdateAllIbans: false,
      isUpdateAllHomepages: false,
      isUpdateAllTaxNumbers: false,
      deleteAll: this.deleteAll,
    };
    this.emptyDocument.realDeleteAfter = this.AddDaysToCurrentDate();

    this.emptyDocument.size = '0';
    this.emptyDocument.storageId = RouterUtils.emptyGuid;
    this.emptyDocument.folderId = RouterUtils.emptyGuid;
    this.emptyDocument.id = RouterUtils.emptyGuid;
    this.emptyDocument.organisationId = RouterUtils.emptyGuid;
    this.emptyDocument.previewFileId = RouterUtils.emptyGuid;
    const payload = { documentIdsWithData: documentIdsWithData, document: this.emptyDocument };

    return payload;
  }

  private AddDaysToCurrentDate(days: number = 2) {
    return Math.floor(new Date().setDate(new Date().getDate() + days) / 1000);
  }

  clickClose() {
    this.$emit('click:close');
  }

  deleteDocuments() {
    const payload = this.formatPayloadForMultipleDocuments();
    this.actionUpdateDocuments(payload)
      .then((result: any) => {
        this.clickClose();
        if (this.isTrashRoute) {
          // emit event only for `document-list` not single document preview
          this.$emit('update:documents');
        } else {
          this.actionGetDocuments();
          this.$router.push('/trash');
        }
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
}
