import { MutationTree } from 'vuex';
import { AllocationsPropertyState } from './types';
import Vue from 'vue';
import allocationsProperty, { AllocationsProperty, Value } from '@/shared/model/allocationsProperty';

export const mutations: MutationTree<AllocationsPropertyState> = {
  setAllocationsPropertys(state, payload: Value) {
    state.allocationsPropertys.items = payload.value.map((x) => allocationsProperty.parse(x));
    state.allocationsPropertys.total = payload['@odata.count'] || 0;
    state.allocationsPropertys.isLoading = false;
  },
  setAllocationsPropertysTotal(state, payload: number) {
    state.allocationsPropertys.total = payload;
  },
  setAllocationsPropertysIsLoading(state, payload: boolean) {
    state.allocationsPropertys.isLoading = payload;
  },
};
