//Thread and Request types for `menu-document-qa` and `document-qa-dialog`
export enum ThreadType {
  question = 'question',
  task = 'task',
  circular = 'circular',
}
export enum RequestType {
  start = 'start_thread',
  comment = 'comment_thread',
  close = 'close_thread',
}
