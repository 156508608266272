import { MutationTree } from 'vuex';
import { DriveTypesImportSettingState } from './types';
import Vue from 'vue';
import driveTypesImportSetting, { DriveTypesImportSetting, Value } from '@/shared/model/driveTypesImportSetting';

export const mutations: MutationTree<DriveTypesImportSettingState> = {
  setDriveTypesImportSettings(state, payload: Value) {
    state.driveTypesImportSettings.items = payload.value.map((x) => driveTypesImportSetting.parse(x));
    state.driveTypesImportSettings.total = payload['@odata.count'] || 0;
    state.driveTypesImportSettings.isLoading = false;
  },
  setDriveTypesImportSettingsTotal(state, payload: number) {
    state.driveTypesImportSettings.total = payload;
  },
  setDriveTypesImportSettingsIsLoading(state, payload: boolean) {
    state.driveTypesImportSettings.isLoading = payload;
  },
  setDriveTypesImportSetting(state, payload: Value) {
    state.driveTypesImportSetting = Object.assign({}, driveTypesImportSetting.parse(payload.value[0]));
  },
  setInProgressUpdateDriveTypesImportSetting(state, payload: boolean) {
    state.inProgressUpdateDriveTypesImportSetting = payload;
  },
};
