import { AllocationOfficeState } from './types';

export const state = (): AllocationOfficeState => initialState();

export const initialState = (): AllocationOfficeState => ({
  allocationOffices: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['sortOrder'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['OfficeNumber', 'Name'],
      orClauseFieldsIdsIgnoreCase: ['OfficeNumber', 'Name'],
      orClauseFieldsIdsConvertToString: [],
    },
  },
  isImporting: false,
  editOfficesPermittedDialog_AllAllocationOffices: [],
  editOfficesPermittedDialog_AllAllocationOfficesIsImporting: false,
  editOfficesPermittedDialog_AllocationOfficesGroupByAllowedBrands: [],
});
