import { GetterTree } from 'vuex';
import { AddressState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AddressState, RootState> = {
  ['getAddresses']: (state) => state.addresses,
  ['getAddressesIsLoading']: (state) => state.addresses?.isLoading,
  ['getAddressesTotal']: (state) => state.addresses?.total,
  ['getAddressesSearchParams']: (state) => state.addresses?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
  ['inProgressGetAddress']: (state) => state.inProgressGetAddress,
  ['getImportingFile']: (state) => state.importingFile,
};
