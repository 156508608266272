import { ActionTree } from 'vuex';
import { AllocationsExportState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationsExport } from '@/shared/backend/allocationsExport';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationsExport, { Value, AllocationsExport } from '@/shared/model/allocationsExport';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationsExports');
export const actions: ActionTree<AllocationsExportState, RootState> = {
  getAllocationsExports({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationsExportsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationsExportsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationsExport
      .getAllocationsExports(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationsExports', response.data);
        commit('setAllocationsExportsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsExportsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationsExport({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsExport
      .getAllocationsExport(id)
      .then((response: AxiosResponse<AllocationsExport>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationsExport({ commit, dispatch }, file: AllocationsExport) {
    return defaultBackendAllocationsExport
      .updateAllocationsExport(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExport;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationsExport_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationsExport({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsExport
      .deleteAllocationsExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  createAllocationsExport(
    { commit, dispatch },
    payload: { timezoneOffet: number; branchCode: string; exportType: number }
  ) {
    commit('setAllocationsExportsIsLoading', true);
    return defaultBackendAllocationsExport
      .createAllocationsExport(payload.timezoneOffet, payload.branchCode, payload.exportType)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllocationsExportsIsLoading', false);
      });
  },

  markAsDownloadedAllocationExport({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsExport
      .markAsDownloadedAllocationExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
