import { MutationTree } from 'vuex';
import { CommentState } from './types';
import Vue from 'vue';
import comment, { Comment, Value } from '@/shared/model/comment';

export const mutations: MutationTree<CommentState> = {
  setComments(state, payload: Value) {
    state.comments.items = payload.value.map((x) => comment.parse(x));
    state.comments.total = payload['@odata.count'] || 0;
    state.comments.isLoading = false;
  },
  setCommentsTotal(state, payload: number) {
    state.comments.total = payload;
  },
  setCommentsIsLoading(state, payload: boolean) {
    state.comments.isLoading = payload;
  },
};
