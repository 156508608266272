import { Allocation } from './allocation';
import { AllocationsExport } from './allocationsExport';

export interface AllocationsExportAllocation {
  id: string; // System.Guid
  allocationsExportId: string; // System.Guid
  allocationsExport: AllocationsExport | undefined;
  allocationId: string; // System.Guid
  allocation: Allocation | undefined;
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  name: string;
}

export interface Value {
  value: AllocationsExportAllocation[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AllocationsExportAllocation>): any {
  return {
    Id: data?.id || undefined,
    AllocationsExportId: data?.allocationsExportId || undefined,
    AllocationId: data?.allocationId || undefined,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
  };
}

function parse(data?: Partial<AllocationsExportAllocation>): AllocationsExportAllocation {
  return {
    id: data?.id || '',
    allocationsExportId: data?.allocationsExportId || '',
    allocationsExport: data?.allocationsExport,
    allocationId: data?.allocationId || '',
    allocation: data?.allocation,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    name: data?.name || '--',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
