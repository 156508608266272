import { parse } from 'date-fns';
import { ActionTree } from 'vuex';
import { AllocationOfficeState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationOffice } from '@/shared/backend/allocationOffice';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationOffice, {
  Value,
  AllocationOffice,
  AllocationOfficeGroupByAllowedBrands,
} from '@/shared/model/allocationOffice';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.allocationOffices');
export const actions: ActionTree<AllocationOfficeState, RootState> = {
  getAllocationOffices({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationOfficesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationOfficesSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationOffice
      .getAllocationOffices(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationOffices', response.data);
        commit('setAllocationOfficesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationOfficesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e.response.data;
      });
  },
  getAllAllocationOffices({ commit, getters, rootGetters }, payload?: { searchParams?: any }) {
    //if start get action don't run second
    if (getters.getAllocationOfficesIsLoading.isLoading) return;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    commit('setAllAllocationOfficesIsImporting', true);
    const searchParams: SearchParams = payload?.searchParams ?? { ...getters.getAllocationOfficesSearchParams };
    searchParams.dataOption.itemsPerPage = 500;
    return defaultBackendAllocationOffice
      .getAllocationOffices(searchParams, organizationId)
      .then(async (response: AxiosResponse<Value>) => {
        let allocationOffices = response.data.value.map((x) => allocationOffice.parse(x));

        while (response.data['@odata.nextLink']) {
          response = await defaultBackendAllocationOffice.getByUrl(response.data['@odata.nextLink']);

          allocationOffices = allocationOffices.concat(response.data.value.map((x) => allocationOffice.parse(x)));
        }
        commit('setAllAllocationOffices', allocationOffices);
        return allocationOffices;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAllAllocationOfficesIsImporting', false);
      });
  },
  getAllocationOffice({ commit, dispatch }, id: string) {
    return defaultBackendAllocationOffice
      .getAllocationOffice(id)
      .then((response: AxiosResponse<AllocationOffice>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationOfficeGroupByAllowedBrands({ commit, dispatch }, id?: string) {
    return defaultBackendAllocationOffice
      .getAllocationOfficeGroupByAllowedBrands()
      .then((response: AxiosResponse<AllocationOfficeGroupByAllowedBrands[]>) => {
        commit('setAllocationOfficeGroupByAllowedBrands', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationOfficeGroupByAllowedBrands({ commit }, payload: AllocationOfficeGroupByAllowedBrands[]) {
    return defaultBackendAllocationOffice
      .updateAllocationOfficeGroupByAllowedBrands(payload)
      .then((response: AxiosResponse<Response>) => {
        module_utils.ok(i18n.tc(`success.allocation_office_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationOffice({ commit, dispatch }, file: AllocationOffice) {
    return defaultBackendAllocationOffice
      .updateAllocationOffice(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationOffice;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationOffice_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadAllocationOffice(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    commit('setIsImporting', true);
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationOffice
      .uploadAllocationOffice(fileInfo)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.allocation_office_uploaded`), commit);
        return response.data;
      })
      .catch((e: any) => {
        //show first error from responce if exits translate
        let error = [{ code: 'error', description: i18n.tc('error') }];
        if (Array.isArray(e.response.data.errors?.$values)) error = e.response.data.errors?.$values;
        if (Array.isArray(e.response.data.errors)) error = e.response.data.errors;
        if (i18n.te('errors.' + error[0].code)) error[0].description = i18n.tc('errors.' + error[0].code);

        module_utils.error(error[0].description, commit, e, logger);
        throw e.response.data;
      })
      .finally(() => {
        commit('setIsImporting', false);
      });
  },
  deleteAllocationOffice({ commit, dispatch }, id: string) {
    return defaultBackendAllocationOffice
      .deleteAllocationOffice(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationOffice;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
