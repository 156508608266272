import { ActionTree } from 'vuex';
import { DocumentFilesExportState } from './types';
import { RootState } from '../../types';
import { defaultBackendDocumentFilesExport } from '@/shared/backend/documentFilesExport';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import documentFilesExport, { Value, DocumentFilesExport } from '@/shared/model/documentFilesExport';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.documentFilesExports');
export const actions: ActionTree<DocumentFilesExportState, RootState> = {
  getDocumentFilesExports({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDocumentFilesExportsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDocumentFilesExportsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDocumentFilesExport
      .getDocumentFilesExports(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentFilesExports', response.data);
        commit('setDocumentFilesExportsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDocumentFilesExportsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDocumentFilesExport({ commit, dispatch }, id: string) {
    return defaultBackendDocumentFilesExport
      .getDocumentFilesExport(id)
      .then((response: AxiosResponse<DocumentFilesExport>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDocumentFilesExport({ commit, dispatch }, file: DocumentFilesExport) {
    return defaultBackendDocumentFilesExport
      .updateDocumentFilesExport(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFilesExport;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.documentFilesExport_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDocumentFilesExport({ commit, dispatch }, id: string) {
    return defaultBackendDocumentFilesExport
      .deleteDocumentFilesExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFilesExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  createPdfExport({ commit, dispatch }, payload: { value: string; timezoneOffet: number }) {
    commit('setDocumentFilesExportsIsLoading', true);
    return defaultBackendDocumentFilesExport
      .createPdfExport(payload.value, payload.timezoneOffet)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFilesExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDocumentFilesExportsIsLoading', false);
      });
  },

  markAsDownloadedPdfExport({ commit, dispatch }, id: string) {
    return defaultBackendDocumentFilesExport
      .markAsDownloadedPdfExport(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DocumentFilesExport;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
