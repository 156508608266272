export default class NumberUtils {
  public static isNumber(maybeNumber: any) {
    return !isNaN(Number.parseFloat(maybeNumber));
  }
  public static isNumberPositiveFloatOrInteger(a: any) {
    return NumberUtils.isPositiveFloat(a) || NumberUtils.isPositiveInt(a);
  }

  public static isPositiveInt(a: any) {
    return a - a === 0 && a >= 0 && a.toString().indexOf('.') === -1 && a.toString().indexOf(',') === -1;
  }

  public static isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public static isPositiveFloat(a: any) {
    // JS numbers can contain only `.`(dot) if try to convert string `5,62` to Number we will get `NaN`
    if (a.toString().indexOf(',') !== -1) {
      a = a.toString().replace(',', '.');
    }

    return a - a === 0 && a >= 0 && a.toString().indexOf('.') !== -1;
  }

  public static twoAfterDot(x: number) {
    // 123123.2123000021 -> 123123.21
    return ~~(x * 100) / 100;
  }

  // 1231.23 -> 1231,23
  // 12312.3 -> 12312,3
  public static oneDotToComa(num: string) {
    const dotSplit = num.split('.');
    if (num.indexOf(',') === -1 && dotSplit.length === 2 && dotSplit[1].length < 3) {
      return num.replace('.', ',');
    }
    return num;
  }
  // 1231,23 -> 1231.23
  // 12312,3 -> 12312.3
  public static oneComaToDot(num: string) {
    const dotSplit = num.split(',');
    if (num.indexOf('.') === -1 && dotSplit.length === 2 && dotSplit[1].length < 3) {
      return num.replace(',', '.');
    }
    return num;
  }
  public static toLocal(num: string, locale: string): string {
    if (Number.isNaN(num)) num = '0';
    switch (locale) {
      case 'en':
        return this.oneComaToDot(num);
      case 'de':
        return this.oneDotToComa(num);
    }
    return num;
  }
  // 123123.2123000021 -> 123123.21
  // 123123 -> 123123.00
  public static addZeroes(num: string, locale: string, afterdot = 2): string {
    if (num === 'null') num = '0';
    return (+num).toLocaleString(locale, { useGrouping: false, minimumFractionDigits: afterdot });
  }
  //123,123.123 -> 123123.123
  //123123.123 -> 123123.123
  //123.123,123 -> 123123.123
  //123123,123 -> 123123.123
  public static toApi(num: string, locale: string): any {
    if (Number.isNaN(num)) num = '0';

    switch (locale) {
      case 'en':
        return num.replace(',', '');
      case 'de':
        return num.replace('.', '').replace(',', '.');
    }
    return +num;
  }
}
