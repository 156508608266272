import supplier from '@/shared/model/supplier';
import { SupplierState } from './types';

export const state = (): SupplierState => initialState();

export const initialState = (): SupplierState => ({
  suppliers: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['kontoInteger'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'Konto',
        'KontoInteger',
        'Bezeichnung',
        'Kostenstelle',
        'Absatzkanal',
        'Kostentraeger',
        'Antriebsart',
      ],
      orClauseFieldsIdsIgnoreCase: ['Bezeichnung', 'Kostenstelle', 'Absatzkanal', 'Kostentraeger', 'Antriebsart'],
      orClauseFieldsIdsGrateThan: ['KontoInteger'],
    },
  },
  isImporting: false,
  accountSupplier: supplier.parse({}),
  contraAccountSupplier: supplier.parse({}),
});
