export interface ActionLogUser {
  id: string; // ulong
  userId: string; // string
  email: string; // string
  username: string; // string
  firstName: string; // string
  lastName: string; // string
  kurzform: string; // string
  employeeNumber: string; // string
  read: string; // bolean
}

export interface Value {
  value: ActionLogUser[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ActionLogUser>): any {
  const x: any = {
    //for correct parce guid field value from string in json format
  };
  if (data?.email) x.Email = data?.email || '';
  if (data?.firstName !== undefined) x.FirstName = data?.firstName || '';
  if (data?.lastName !== undefined) x.LastName = data?.lastName || '';
  if (data?.kurzform) x.Kurzform = data?.kurzform || '';
  if (data?.employeeNumber) x.EmployeeNumber = data?.employeeNumber || 0;
  if (data?.userId) {
    x['UserId@odata.type'] = '#Guid';
    x.UserId = data?.userId;
  }
  if (data?.id) {
    x['Id@odata.type'] = '#Guid';
    x.Id = data?.id;
  }
  return x;
}

function parse(data?: Partial<ActionLogUser>): ActionLogUser {
  return {
    id: data?.id || '',
    userId: data?.userId || data?.id || '',
    email: data?.email || '',
    username: data?.username || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    kurzform: data?.kurzform || '',
    employeeNumber: data?.employeeNumber || '',
    read: data?.read || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
