export interface DatevSetting {
  id: string; // guid
  organisationId: string; // guid
  active: boolean; // bool
  consultantNumber: string; // string
  clientNumber: string; // string
  marketingYear: string; // System.DateTime?
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  exportSerialNumber: boolean; // bool
  withDriveType: boolean; // bool
  generalLedgerPositions: number; // number
  accountType: AccountTypes; // number
  exportDocumentPermalink: boolean;
  serialNumberLastChars: number; // sbyte
  costCentre1Type: string;
}

export interface Value {
  value: DatevSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}
export enum AccountTypes {
  Haben = 1,
  Soll = 2,
}

export enum Kostenstelle1Types {
  All = 'full',
  CostCenterOnly = 'only',
}

// init api object
function toAPI(data: Partial<DatevSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    Active: data?.active || false,
    ConsultantNumber: data?.consultantNumber || '',
    ClientNumber: data?.clientNumber || '',
    MarketingYear: data?.marketingYear || undefined,
    CreatedAt: data?.createdAt || undefined,
    UpdatedAt: data?.updatedAt || undefined,
    ExportSerialNumber: data?.exportSerialNumber || false,
    WithDriveType: data?.withDriveType || false,
    GeneralLedgerPositions: data?.generalLedgerPositions || 0,
    AccountType: data?.accountType || 0,
    ExportDocumentPermalink: data?.exportDocumentPermalink || false,
    SerialNumberLastChars: data?.serialNumberLastChars || 0,
    CostCentre1Type: data?.costCentre1Type || '',
  };
}

function parse(data?: Partial<DatevSetting>): DatevSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    active: data?.active || false,
    consultantNumber: data?.consultantNumber || '',
    clientNumber: data?.clientNumber || '',
    marketingYear: data?.marketingYear ? data?.marketingYear.slice(0, 10) : '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    exportSerialNumber: data?.exportSerialNumber || false,
    withDriveType: data?.withDriveType || false,
    generalLedgerPositions: data?.generalLedgerPositions || 0,
    accountType: data?.accountType || AccountTypes.Haben,
    exportDocumentPermalink: data?.exportDocumentPermalink || false,
    serialNumberLastChars: data?.serialNumberLastChars || 0,
    costCentre1Type: data?.costCentre1Type || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
