import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import organisationAddress, { OrganisationAddress, Value } from '../model/organisationAddress';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.OrganisationAddress');
export interface BackendOrganisationAddress {
  getOrganisationAddress(id: string): AxiosPromise<OrganisationAddress>;
  getOrganisationAddresses: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteOrganisationAddress(id: string): AxiosPromise;
  updateOrganisationAddress(OrganisationAddress: OrganisationAddress): AxiosPromise<any>;
}

export const defaultBackendOrganisationAddress: BackendOrganisationAddress = {
  getOrganisationAddress(id: string): AxiosPromise<OrganisationAddress> {
    const url = `${URLS.organisationAddressOdata}/${id}`;
    return instance.get<OrganisationAddress>(url);
  },
  getOrganisationAddresses(searchParams: SearchParams): AxiosPromise<Value> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.organisationAddressOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getOrganisationAddresses${url}`);
    return instance.get<Value>(url);
  },

  deleteOrganisationAddress(id: string): AxiosPromise {
    logger.debug('deleteOrganisationAddress');
    return instance.delete(`${URLS.organisationAddress}/${id}`);
  },
  updateOrganisationAddress(OrganisationAddress: OrganisationAddress): AxiosPromise<any> {
    logger.debug('updateOrganisationAddress');
    return instance.put<OrganisationAddress>(
      `${URLS.organisationAddress}/update`,
      organisationAddress.toAPI(OrganisationAddress)
    );
  },
};
