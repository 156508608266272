import folder, { Folder } from './folder';

export enum ConditionTypes {
  'has_status' = 'has_status',
  'new_document' = 'new_document',
  'has_allocation' = 'has_allocation',
  'has_gobd' = 'has_gobd',
  'amount_greater_than' = 'amount_greater_than',
  'amount_lower_than' = 'amount_lower_than',
  'amount_between' = 'amount_between',
  'days_in_folder' = 'days_in_folder',
}

export interface FolderRule {
  id: string; // System.Guid
  folderId: string; // System.Guid
  conditionType: ConditionTypes; // string
  conditionStatus: string; // string
  actionType: ActionTypes; // string
  actionMoveToFolderId: string; // System.Guid
  actionMoveToFolderPattern: string; // string
  actionMoveToFolderPatternType:
    | 'document_upload'
    | 'latest_status'
    | 'latest_allocation'
    | 'invoice_date_of_latest_allocation'; // string
  actionEmailSubject: string; // string
  actionEmailMessage: string; // string
  sortOrder: number; // int
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  conditionAmountMin: number; // double
  conditionAmountMax: number; // double
  conditionAmountType: 'day' | 'month' | 'year'; // double
  conditionDays: number; // int
  lastExecution: string; // System.DateTime?
  organisationId: string; // System.Guid

  //update model
  actionRoles: string[];
  actionUsers: string[];
  actionMoveToFolder: Folder;
  copyToOrganisationId: string;
  actionOptions: {
    copyType?: 'copy_and_delete' | 'copy_and_keep';
    status?: { value: string; active?: true | false }[];
  };
}
export enum ActionTypes {
  attach_address = 'attach_address',
  move_to_folder = 'move_to_folder',
  send_email = 'send_email',
  copy_to_organization = 'copy_to_organization',
  delete = 'delete',
  change_status = 'change_status',
}
export interface Value {
  value: FolderRule[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<FolderRule>): any {
  return {
    Id: data?.id || undefined,
    FolderId: data?.folderId || undefined,
    ConditionType: data?.conditionType || '',
    ConditionStatus: data?.conditionStatus || '',
    ActionType: data?.actionType,
    ActionMoveToFolderId: data?.actionMoveToFolderId || undefined,
    ActionMoveToFolderPattern: data?.actionMoveToFolderPattern || '',
    ActionMoveToFolderPatternType: data?.actionMoveToFolderPatternType || '',
    ActionEmailSubject: data?.actionEmailSubject || '',
    ActionEmailMessage: data?.actionEmailMessage || '',
    SortOrder: data?.sortOrder || 0,
    CreatedAt: data?.createdAt || Date.now,
    UpdatedAt: data?.updatedAt || Date.now,
    ConditionAmountMin: data?.conditionAmountMin || 0,
    ConditionAmountMax: data?.conditionAmountMax || 0,
    ConditionAmountType: data?.conditionAmountType || 'day',
    ConditionDays: data?.conditionDays || 0,
    LastExecution: data?.lastExecution || Date.now,
    OrganisationId: data?.organisationId || undefined,

    ActionRoles: data?.actionRoles || [],
    ActionUsers: data?.actionUsers || [],
    CopyToOrganisationId: data?.copyToOrganisationId || undefined,
    ActionOptions: data?.actionOptions || '',
  };
}

function parse(data?: Partial<FolderRule>): FolderRule {
  return {
    id: data?.id || '',
    folderId: data?.folderId || '',
    conditionType: data?.conditionType || ConditionTypes.has_status,
    conditionStatus: data?.conditionStatus || '',
    actionType: data?.actionType || ActionTypes.attach_address,
    actionMoveToFolderId: data?.actionMoveToFolderId || '',
    actionMoveToFolderPattern: data?.actionMoveToFolderPattern || '',
    actionMoveToFolderPatternType: data?.actionMoveToFolderPatternType || 'document_upload',
    actionEmailSubject: data?.actionEmailSubject || '',
    actionEmailMessage: data?.actionEmailMessage || '',
    sortOrder: data?.sortOrder || 0,
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    conditionAmountMin: data?.conditionAmountMin || 0,
    conditionAmountMax: data?.conditionAmountMax || 0,
    conditionAmountType: data?.conditionAmountType || 'day',
    conditionDays: data?.conditionDays || 0,
    lastExecution: data?.lastExecution || '',
    organisationId: data?.organisationId || '',
    actionRoles: data?.actionRoles || [],
    actionUsers: data?.actionUsers || [],
    actionMoveToFolder: folder.parse(data?.actionMoveToFolder || {}),
    copyToOrganisationId: data?.copyToOrganisationId || '',
    actionOptions: data?.actionOptions || { copyType: 'copy_and_delete' },
  };
}

export default {
  parse,
  toAPI: toAPI,
};
