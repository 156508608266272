import { GetterTree } from 'vuex';
import { TradeChannelImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TradeChannelImportSettingState, RootState> = {
  ['getTradeChannelImportSettings']: (state) => state.tradeChannelImportSettings,
  ['getTradeChannelImportSettingsIsLoading']: (state) => state.tradeChannelImportSettings?.isLoading,
  ['getTradeChannelImportSettingsTotal']: (state) => state.tradeChannelImportSettings?.total,
  ['getTradeChannelImportSettingsSearchParams']: (state) => state.tradeChannelImportSettings?.searchParams,
  ['getTradeChannelImportSetting']: (state) => state.tradeChannelImportSetting,
  ['getInProgressUpdateTradeChannelImportSetting']: (state) => state.inProgressUpdateTradeChannelImportSetting,
};
