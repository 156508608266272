import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '../i18n';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    current: 'en',
    t: (key, ...params) => i18n.t(key, params).toString(),
  },
  icons: {
    iconfont: 'mdi', // 'md' || 'fa' || 'mdi'|| 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#b62240',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        // colors from https://neu.easy-docs.de/
        // https://www.dictionary.com/e/gray-vs-grey/
        'dark-grey--bg': '#4a4a4a',
        'dove-grey': '#6d6d6d',
        'batton-grey': '#6d6d6d',
        'icon-delete': '#F44336', // color of the delete icon in table rows
        'light-grey--bg': '#cccccc',
        'button-border-gray': '#d4d4d4',
        'right-drawer--bg': '#d3dbc5',
        'right-drawer-orange--bg': '#f0a35d',
        'have-workflow-rules': '#3adb76',
        'search-results': '#bee07e', //the highlight color of search results
        'bg-text-field': '#fefefe', //the background color of text fields (search)
        'document-list-item--document-id--bg': '#cccccc',
        'qa-section--is-all-items-closed': '#bee07e',
        'qa-section--has-items': '#eee8af',
        'qa-section--is-date-expired': '#ecb7c2',
        'qa-section--has-date': '#c2e2f1',
        'qa-section--thread-circular--readed': '#40db57',
        'reminder-section--has-active-reminder': '#bee07e',
        'accounting-section--has-accounting': '#bee07e',
        'gobd-section--is-yellow-green': '#bee07e',
        'gobd-section--is-blue': '#74b9d9',
        'gobd-section--is-yellow': '#eee8af',
        'impersonated-header': '#eade7f',
        'bg-checkbox': '#767676',
      },
      dark: {
        'document-list-item--document-id--bg': '#6a5252',
        'qa-section--is-all-items-closed': '#bee07e',
        'qa-section--has-items': '#eee8af',
        'qa-section--is-date-expired': '#ecb7c2',
        'qa-section--has-date': '#c2e2f1',
        'button-border-gray': '#d4d4d4',
        'icon-delete': '#F44336', // color of the delete icon in table rows
        'search-results': '#bee07e', // the highlight color of search results
        'bg-text-field': '#546E7A', //the background color of text fields (search)
      },
    },
  },
});
