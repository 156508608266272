import { ActionTree } from 'vuex';
import { UserFileState } from './types';
import { RootState } from '../../types';
import { defaultBackendUserFile } from '@/shared/backend/userFile';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import uf, { Value, UserFile } from '@/shared/model/userFile';
import { AxiosResponse } from 'axios';
import CacheUtils from '@/shared/utils/cacheUtils';
import userFile from '@/shared/model/userFile';

const logger = new Logger('actions.userFile');
export const actions: ActionTree<UserFileState, RootState> = {
  getUserFile({ commit, dispatch }, id: string) {
    commit('setUserFileIsLoading', id);
    return defaultBackendUserFile
      .getUserFile(id)
      .then((response: AxiosResponse<UserFile>) => {
        commit('setUserFile', uf.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUserFileIsLoading', id);
      });
  },
  async getUserFilePreview({ commit, dispatch }, id: string) {
    commit('setUserFileIsLoading', id);

    try {
      const cacheFile = await CacheUtils.getPreviewFileFromCache(id);
      if (cacheFile) {
        //logger.debug(`Frontend CACHE Preview load: userFile with ${id} was loaded from Frontend CACHE`);
        commit('setUserFileIsLoading', id);
        commit(
          'document/setImagePreviewInDoocumentsByDocumentId',
          { previewFileId: id, imagePreview: cacheFile.document },
          { root: true }
        );
        return cacheFile;
      }
    } catch (e) {
      logger.error(`Frontend CACHE Preview load: Failed to load userFile with id ${id}`);
    }

    return defaultBackendUserFile
      .getUserFile(id)
      .then(async (response: AxiosResponse<UserFile>) => {
        if (response.data.document) {
          // (ED-1211) don't put documents without preview in cache
          await CacheUtils.putPreviewInCache(id, response);
        }
        logger.debug(`BACKEND Preview load: userFile with ${id} was loaded from BACKEND`);
        commit(
          'document/setImagePreviewInDoocumentsByDocumentId',
          { previewFileId: id, imagePreview: userFile.parse(response.data).document },

          {
            root: true,
          }
        );
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUserFileIsLoading', id);
      });
  },
  getUserFiles({ commit, dispatch }, searchParams?: any) {
    commit('setUserFilesIsLoading', true);
    return defaultBackendUserFile
      .getUserFiles(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setUserFiles', response.data);
        commit('setUserFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setUserFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  downloadUserFile({ commit, dispatch }, id: string) {
    commit('setUserFileIsLoading', id);
    return defaultBackendUserFile
      .downloadUserFile(id)
      .then((response: AxiosResponse<Blob>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setUserFileIsLoading', id);
      });
  },

  updateUserFile({ commit, dispatch }, file: UserFile) {
    return defaultBackendUserFile
      .updateUserFile(file)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteUserFile({ commit, dispatch }, id: string) {
    return defaultBackendUserFile
      .deleteUserFile(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
