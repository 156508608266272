import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';

import MenuDocumentAccountTable from './menu-document-account-table/menu-document-account-table.vue';
import MenuDocumentAccountTemplateTable from './menu-document-account-template-table/menu-document-account-template-table.vue';
import MenuDocumentAccountAddresses from './menu-document-account-addresses/menu-document-account-addresses.vue';
import allocation, { Allocation, AllocationPropertyEx } from '@/shared/model/allocation';
import allocationsproperty, { AllocationsProperty } from '@/shared/model/allocationsProperty';
import { AllocationBrand } from '@/shared/model/allocationBrand';
import file from '@/shared/model/file';
import { Supplier } from '@/shared/model/supplier';
import { buildOnEnter } from '@/shared/utils/formUtils';
import costCentre, { CostCentre } from '@/shared/model/costCentre';
import costObjective, { CostObjective } from '@/shared/model/costObjective';
import tradeChannel, { TradeChannel } from '@/shared/model/tradeChannel';
import allocationTemplate, { AllocationTemplate } from '@/shared/model/allocationTemplate';
import allocationTemplateEx, { AllocationTemplateEx } from '@/shared/model/allocationTemplateEx';
import MenuDocumentAccountTemplateEdit from '././menu-document-account-template-edit/menu-document-account-template-edit.vue';
import NumberField from '@/components/number-field/number-field.vue';
import DateField from '@/components/date-field/date-field.vue';
import DateUtils from '@/shared/utils/DateUtils';
import NumberUtils from '@/shared/utils/NumberUtils';
import i18n from '@/i18n';
import documentProperty, { DocumentProperty } from '@/shared/model/documentProperty';
import { AllocationTaxSetting } from '@/shared/model/allocationTaxSetting';
import { Document } from '@/shared/model/document';
import rangeParser from 'parse-numeric-range';
import { OrganisationAllocationProperty } from '@/shared/model/organisationAllocationProperty';
import gobdData, { GobdData } from '@/shared/model/gobdData';
import GuidUtils from '@/shared/utils/GuidUtils';
import moment from 'moment';
import { AllocationSetting, AllocationSettingExportTypes } from '@/shared/model/allocationSetting';
import { AccountTypes, DatevSetting } from '@/shared/model/datevSetting';
import { Organisation } from '@/shared/model/organisation';
import { AllocationOffice } from '@/shared/model/allocationOffice';
import { isBlank } from '@/shared/utils/generalUtils';
import driveType, { DriveType } from '@/shared/model/driveType';

const logger = new Logger('menu-document-account');
const allocationModule = namespace('allocation');
const allocationBrandModule = namespace('allocationBrand');
const allocationOfficeModule = namespace('allocationOffice');
const allocationTaxSettingModule = namespace('allocationTaxSetting');
const costCentreModule = namespace('costCentre');
const costObjectiveModule = namespace('costObjective');
const supplierModule = namespace('supplier');
const tradeChannelModule = namespace('tradeChannel');
const allocationTemplateModule = namespace('allocationTemplate');
const commentModule = namespace('comment');
const documentPropertyModule = namespace('documentProperty');
const documentModule = namespace('document');
const organisationAllocationPropertyModule = namespace('organisationAllocationProperty');
const gobdModule = namespace('gobdData');
const allocationSettingModule = namespace('allocationSetting');
const datevSettingModule = namespace('datevSetting');
const organizationModule = namespace('organization');
const driveTypeModule = namespace('driveType');

@Component({
  name: 'menu-document-account',
  components: {
    MenuDocumentAccountTable,
    MenuDocumentAccountTemplateTable,
    MenuDocumentAccountAddresses,
    MenuDocumentAccountTemplateEdit,
    NumberField,
    DateField,
  },
})
export default class MenuDocumentAccountView extends Vue {
  @Ref('menu-document-account-form')
  private refForm!: any;

  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationPropertiesActiveItems')
  private organisationAllocationPropertiesActiveItems!: OrganisationAllocationProperty[];
  @organisationAllocationPropertyModule.Getter('isActiveOrganisationAllocationProperty')
  private isActiveOrganisationAllocationProperty!: (key: string) => {};

  @Ref('tax_key')
  private refTaxKey!: any;
  @Ref('account_group')
  private refAccountGroup!: any;
  @Ref('branch')
  private refBranch!: any;
  @Ref('brand')
  private refBrand!: any;
  @Ref('cost_center')
  private refCostCenter!: any;
  @Ref('cost_unit')
  private refCostUnit!: any;
  @Ref('sales_channel')
  private refSalesChannel!: any;

  @Prop({ default: '' })
  private documentNumber!: string;

  @Prop({ default: '' })
  private accountId!: string;

  @allocationModule.Action('updateAllocation')
  private actionUpdateAllocation?: any;
  @allocationModule.Action('getAllByDocumentId')
  private actionGetAllByDocumentId?: any;
  @allocationModule.Getter('getAllocationPropertyEx')
  private getAllocationPropertyEx?: any;
  @allocationModule.Action('deleteAllocation')
  private actionDeleteAllocation?: any;
  @allocationModule.Action('getListByDocumentId')
  private actionGetListByDocumentId?: any;
  @allocationModule.Getter('getAllocationList')
  private getAllocationList?: Allocation[];
  @allocationModule.Action('getMainAllocationsByDocumentId')
  private actionGetMainAllocationsByDocumentId?: any;

  @allocationModule.Getter('getAllocationCurrencies')
  private getAllocationCurrencies?: any[];

  @allocationBrandModule.Getter('getAllAllocationBrands')
  private allAllocationBrands!: AllocationBrand[];
  get allocationBrands(): AllocationBrand[] {
    if (this.allocationsProperties.branch) {
      const currenOffices = this.allocationOffices.filter((x) => x.officeNumber == this.allocationsProperties.branch);
      if (currenOffices.length > 0) {
        const allowedBrands = currenOffices[0].data.allowedBrands;
        return this.allAllocationBrands.filter((x) => allowedBrands.includes(x.brandNumber)) ?? [];
      }
    }
    return this.allAllocationBrands;
  }
  @allocationBrandModule.Action('getAllocationBrands')
  private actionGetAllocationBrands?: any;

  @allocationOfficeModule.Getter('getAllAllocationOffices')
  private allAllocationOffices!: AllocationOffice[];
  get allocationOffices(): AllocationOffice[] {
    if (this.allocationsProperties.brand)
      return (
        this.allAllocationOffices.filter((x) => x.data.allowedBrands.includes(this.allocationsProperties.brand)) ?? []
      );
    return this.allAllocationOffices;
  }
  @allocationOfficeModule.Action('getAllocationOffices')
  private actionGetAllocationOffices?: any;

  @allocationTaxSettingModule.Getter('getAllocationTaxSettingsAllForCombo')
  private getAllocationTaxSettings?: any;
  @allocationTaxSettingModule.Action('getAllocationTaxSettingsAllForCombo')
  private actionGetAllocationTaxSettings?: any;
  @allocationTaxSettingModule.Action('getAllocationTaxSettingByGroupDate')
  private getAllocationTaxSettingByGroupDate: any;

  @costCentreModule.Getter('getCostCentres')
  private getCostCentres?: any;
  @costCentreModule.Action('getCostCentres')
  private actionGetCostCentres?: any;

  @tradeChannelModule.Getter('getTradeChannels')
  private getTradeChannels?: any;
  @tradeChannelModule.Action('getTradeChannels')
  private actionGetTradeChannels?: any;

  @costObjectiveModule.Getter('getCostObjectives')
  private getCostObjectives?: any;
  @costObjectiveModule.Action('getCostObjectives')
  private actionGetCostObjectives?: any;

  @driveTypeModule.Getter('getDriveTypes')
  private getDriveTypes?: any;
  @driveTypeModule.Action('getDriveTypes')
  private actionGetDriveTypes!: any;

  @supplierModule.Getter('getAccountSupplier')
  private getAccountSupplier?: Supplier;
  @supplierModule.Action('getAccountSupplierByName')
  private actionGetAccountSupplierByName?: any;

  @supplierModule.Getter('getContraAccountSupplier')
  private getContraAccountSupplier?: Supplier;
  @supplierModule.Action('getContraAccountSupplierByName')
  private actionGetContraAccountSupplierByName?: any;

  @allocationTemplateModule.Action('getAllocationTemplatesById')
  private actionGetAllocationTemplatesById?: any;
  @allocationTemplateModule.Action('getAllocationTemplates')
  private actionGetAllocationTemplates?: any;
  @allocationTemplateModule.Action('updateAllocationTemplate')
  private actionUpdateAllocationTemplate?: any;

  @commentModule.Action('getComments')
  private actionGetComments!: any;
  @commentModule.Getter('getComments')
  private getComments!: any;

  @documentPropertyModule.Action('getDocumentPropertyQueryFirstEnabled')
  private actionGetDocumentPropertyQueryFirstEnabled!: any;
  @documentPropertyModule.Action('updateDocumentPropertys')
  private actionUpdateDocumentPropertys!: any;

  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;

  @organisationAllocationPropertyModule.Action('getOrganisationAllocationProperties')
  private actionGetOrganisationAllocationProperties!: any;
  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationProperties')
  private getOrganisationAllocationProperties!: any;

  @gobdModule.Action('getGobdData')
  private actiongetGobdData!: any;
  @gobdModule.Getter('getGobdData')
  private getGobdData!: any;
  @gobdModule.Action('detectGobdData')
  private actionDetectGobdData!: any;

  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @datevSettingModule.Getter('getDatevSetting')
  private datevSetting!: DatevSetting;

  @organizationModule.Action('getOrganization')
  private actionGetOrganization!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;

  private account: Allocation = allocation.parse({}); // TODO: (ED-195) setup getting Account from backend if saved

  private documentPropertyFirstEnabled: DocumentProperty = documentProperty.parse({});
  private gobdData: GobdData = gobdData.parse({});

  private currentIndex = 0;
  private allocationsPropertiesList: AllocationTemplateEx[] = [];
  private allocationsProperties: AllocationTemplateEx = allocationTemplateEx.parse({});
  private allocationsPropertiesDict: Record<string, Partial<AllocationPropertyEx>> = {
    '5fc8be6f-64b1-c407-9f49-6e7100000000': { name: 'Antriebsart', key: 'drive_type', value: '' },
    '53204fe8-b606-aab2-3c55-ecd500000000': { name: 'Marke', key: 'brand', value: '' }, //brand
    '53204fe8-b606-aab2-3c55-ecd300000000': { name: 'Filiale', key: 'branch', value: '' }, //branch
    '53204fe8-b606-aab2-3c55-ecd100000000': { name: 'Persornalnummer', key: 'staff_number', value: '' },
    '53204fe8-b606-aab2-3c55-eccf00000000': { name: 'Buchungsart', key: 'booking_type', value: '' },
    '53204fe8-b606-aab2-3c55-eccd00000000': { name: 'Belegstext 2', key: 'document_text_2', value: '' },
    '53204fe8-b606-aab2-3c55-eccb00000000': { name: 'Belegstext 1', key: 'document_text_1', value: '' },
    '53204fe8-b606-aab2-3c55-ecd700000000': { name: 'Kostenstelle', key: 'cost_center', value: '' }, //cost_center
    '53204fe8-b606-aab2-3c55-ecc900000000': { name: 'Buchungstext', key: 'posting_text', value: '' }, //posting_text
    '53204fe8-b606-aab2-3c55-ecc500000000': { name: 'Betrag', key: 'amount', value: '' }, //amount
    '53204fe8-b606-aab2-3c55-ecc300000000': { name: 'Gegenkontenkreis', key: 'contra_account_group', value: '' }, //contra_account_group
    '53204fe8-b606-aab2-3c55-ecc100000000': { name: 'Kontenkreis', key: 'account_group', value: '1' }, //account_group
    '53204fe8-b606-aab2-3c55-ecbf00000000': { name: 'Gegenkonto', key: 'contra_account', value: '' }, //contra_account
    '53204fe8-b606-aab2-3c55-ecbd00000000': { name: 'Konto', key: 'account', value: '' }, //account
    '53204fe8-b606-aab2-3c55-ecbb00000000': { name: 'Belegkreis', key: 'sparkle', value: '' },
    '53204fe8-b606-aab2-3c55-ecc700000000': { name: 'Rechnungsnummer', key: 'invoice_number', value: '' }, //invoice_number
    '53204fe8-b606-aab2-3c55-ecd900000000': { name: 'Kostenträger', key: 'cost_unit', value: '' }, //cost_unit
    '53204fe8-b606-aab2-3c55-ecdb00000000': { name: 'Herkunft', key: 'origin', value: '' },
    '53204fe8-b606-aab2-3c55-ecdd00000000': { name: 'Absatzkanal', key: 'sales_channel', value: '' }, //sales_channel
    '59a7cbe9-80dc-8475-077b-23c600000000': { name: 'Fremdwährung', key: 'foreign_currency', value: '' }, //foreign_currency
    '59a3f424-5746-d9f7-687e-6b8300000000': { name: 'Währung', key: 'exchange_rate', value: '' },
    '537b2cb2-09bb-e01a-1529-3c9e00000000': { name: 'Skontosatz', key: 'discount_rate', value: '' },
    '537b2cb2-09bb-e01a-1529-3c9c00000000': { name: 'Tage skonto', key: 'days_discount', value: '' }, //days_discount
    '537b2cb2-09bb-e01a-1529-3c9a00000000': { name: 'Tage netto', key: 'days_clean', value: '' }, //days_clean
    '5368eb93-09bb-e067-0911-69e200000000': { name: 'Skonto in &euro;', key: 'discount_in', value: '' }, //discount_in
    '53204fe8-b606-aab2-3c55-ecef00000000': { name: 'Lieferdatum', key: 'delivery_date', value: '' }, //delivery_date
    '53204fe8-b606-aab2-3c55-eced00000000': { name: 'Ausgleichsdatum', key: 'compensation_date', value: '' }, //compensation_date
    '53204fe8-b606-aab2-3c55-eceb00000000': { name: 'Seriennummer', key: 'serial_number', value: '' }, //serial_number
    '53204fe8-b606-aab2-3c55-ece900000000': { name: 'USt Code', key: 'vat_code', value: '' }, //vat_code
    '53204fe8-b606-aab2-3c55-ece700000000': { name: 'Steuer-Schlüssel', key: 'tax_key', value: '' }, //tax_key
    '53204fe8-b606-aab2-3c55-ece500000000': { name: 'Kontierungsdatum', key: 'denate_date', value: '' }, //denate_date
    '53204fe8-b606-aab2-3c55-ece300000000': { name: 'Projekt', key: 'project', value: '' },
    '53204fe8-b606-aab2-3c55-ece100000000': {
      name: 'Händler eigener Kostenträger',
      key: 'dealer_own_cost_unit',
      value: '',
    },
    '53204fe8-b606-aab2-3c55-ecdf00000000': { name: 'Erw. Vertriebsweg', key: 'exp_distribution_channel', value: '' },
    '53204fe8-b606-aab2-3c55-ecb900000000': { name: 'Rechnungsdatum', key: 'invoice_date', value: '' }, //invoice_date
    'a98a9b59-8786-405d-bcfa-80bf4aea97c8': { name: 'Steuerbetrag', key: 'tax_key_amount', value: '0,00' }, //tax_key_amount
  };

  accountGroups = [
    { name: 'Haben', value: '1' },
    { name: 'Soll', value: '2' },
  ];

  isFormValid = true;

  get documentId() {
    return this.$route.params['documentId'];
  }

  // kostenstelle/Cost Centre
  get getCostCentresItemsMapped(): CostCentre[] {
    if (this.kostenstelleAllowedNumbers.length > 0) {
      var allowed =
        this.getCostCentres.items.filter((x: CostCentre) => this.kostenstelleAllowedNumbers.includes(x.value)) ?? [];
      //console.error(this.getCostCentres.items, allowed);
      return allowed.map((x: CostCentre) => {
        return { name: x.name, value: x.value.toString() };
      });
    }
    return this.getCostCentres.items.map((x: CostCentre) => {
      return { name: x.name, value: x.value.toString() };
    });
  }

  get costCentrePlaceholder() {
    return this.getCostCentresItemsMapped.length == 0
      ? this.kostenstelleAllowedNumbers.length > 0
        ? this.kostenstelleAllowedNumbers[0].toString()
        : ''
      : '';
  }

  //kostentraeger/cost_unit/cost_object
  get getCostObjectivesItemsMapped(): CostCentre[] {
    if (this.kostentraegerAllowedNumbers.length > 0) {
      var allowed =
        this.getCostObjectives.items.filter((x: CostObjective) => this.kostentraegerAllowedNumbers.includes(x.value)) ??
        [];
      //console.error(this.getCostObjectives.items, allowed);
      return allowed.map((x: CostObjective) => {
        return { name: x.name, value: x.value.toString() };
      });
    }
    return this.getCostObjectives.items.map((x: CostObjective) => {
      return { name: x.name, value: x.value.toString() };
    });
  }

  get costObjectivePlaceholder() {
    return this.getCostObjectivesItemsMapped.length == 0
      ? this.kostentraegerAllowedNumbers.length > 0
        ? this.kostentraegerAllowedNumbers[0].toString()
        : ''
      : '';
  }

  // absatzkanal/sales_channel
  get getTradeChannelsItemsMapped(): TradeChannel[] {
    if (this.absatzkanalAllowedNumbers.length > 0) {
      var allowed =
        this.getTradeChannels.items.filter((x: TradeChannel) => this.absatzkanalAllowedNumbers.includes(x.value)) ?? [];
      //console.error(this.getTradeChannels.items, allowed);
      return allowed.map((x: TradeChannel) => {
        return { name: x.name, value: x.value.toString() };
      });
    }
    return this.getTradeChannels.items.map((x: TradeChannel) => {
      return { name: x.name, value: x.value.toString() };
    });
  }

  get salesChannelPlaceholder() {
    return this.getTradeChannelsItemsMapped.length == 0
      ? this.absatzkanalAllowedNumbers.length > 0
        ? this.absatzkanalAllowedNumbers[0].toString()
        : ''
      : '';
  }

  // antriebsart/drive_type
  get getDriveTypesItemsMapped(): DriveType[] {
    if (this.antriebsartAllowedNumbers.length > 0) {
      var allowed =
        this.getDriveTypes.items.filter((x: DriveType) => this.antriebsartAllowedNumbers.includes(x.value)) ?? [];
      //console.error(this.getDriveTypes.items, allowed);
      return allowed.map((x: DriveType) => {
        return { name: x.name, value: x.value.toString() };
      });
    }
    return this.getDriveTypes.items.map((x: DriveType) => {
      return { name: x.name, value: x.value.toString() };
    });
  }

  get driveTypePlaceholder() {
    return this.getDriveTypesItemsMapped.length == 0
      ? this.antriebsartAllowedNumbers.length > 0
        ? this.antriebsartAllowedNumbers[0].toString()
        : ''
      : '';
  }

  get allocationExported() {
    const currentAllocation: Allocation =
      this.getAllocationList?.find((x: Allocation) => x.id === this.accountId) ?? allocation.parse({});
    return currentAllocation.exported;
  }

  private brandName(id: string) {
    const item = this.allAllocationBrands.find((x: any) => x.id == id);
    return item != undefined ? item.name : '';
  }

  private branchName(id: string) {
    const item = this.allocationOffices.find((x: any) => x.id == id);
    return item != undefined ? item.name : '';
  }

  private kostenstelleName(id: string) {
    const item = this.getCostCentres.items.find((x: any) => x.id == id);
    return item != undefined ? item.name : '';
  }

  private kostunitName(id: string) {
    const item = this.getCostObjectives.items.find((x: any) => x.id == id);
    return item != undefined ? item.name : '';
  }

  private saleschanelName(id: string) {
    const item = this.getTradeChannels.items.find((x: any) => x.id == id);
    return item != undefined ? item.name : '';
  }

  private taxName(id: string) {
    const item = this.getAllocationTaxSettings.items.find((x: any) => x.id == id);
    return item != undefined ? item.percent : '';
  }

  get isEditMode() {
    return this.accountId != null && this.accountId != '';
  }

  private savePressed: boolean = false;
  get isSavePressed() {
    return this.savePressed;
  }
  @Watch('allocationsProperties.invoice_date')
  onInvoceDateUpdated(nVal: string, oVal: string) {
    this.getTaxVariants();
  }
  async created() {
    const promiseAll = [
      this.actiongetGobdData(this.documentId).then((result: any) => {
        Object.assign(this.gobdData, result);
      }),
      this.actionGetOrganisationAllocationProperties(),
      this.actionGetAllocationBrands(),
      this.actionGetAllocationOffices(),
      this.actionGetCostCentres(),
      this.actionGetCostObjectives(),
      this.actionGetTradeChannels(),
      this.actionGetDriveTypes(),
      this.actionGetDocumentPropertyQueryFirstEnabled(this.documentId).then((result: any) => {
        Object.assign(this.documentPropertyFirstEnabled, result);
      }),
      this.actionGetOrganization(this.organization.id),
    ];

    await Promise.all(promiseAll);

    if (this.accountId != null && this.accountId != '') {
      this.actionGetListByDocumentId({ id: this.documentId, allocationId: this.accountId }).then(() => {
        this.loadValues();
      });
    } else {
      this.allocationsProperties.tax_key_amount = '0,00';
      this.allocationsProperties.account_group = '1';
      this.allocationsProperties.exchange_rate = 'EUR';

      this.allocationsProperties.amount = NumberUtils.addZeroes(
        '' + this.gobdData.amount,
        this.$root.$i18n.locale.toLocaleLowerCase()
      );

      if (this.gobdData.invoiceDate) {
        this.allocationsProperties.invoice_date = DateUtils.formatDateToDotDate(new Date(this.gobdData.invoiceDate));
      }
      this.getTaxVariants();

      if (this.gobdData.serialNumber) {
        this.allocationsProperties.serial_number = this.gobdData.serialNumber;
      }

      if (this.gobdData.bookingText) {
        this.allocationsProperties.posting_text = this.gobdData.bookingText;
      }

      this.allocationsProperties.invoice_number = this.gobdData.invoiceNumber;

      //console.error(this.gobdData);

      this.detectGobdData(); // Always detect

      this.initialState = allocationTemplateEx.parse(this.allocationsProperties);
      this.actionGetAccountSupplierByName(this.allocationsProperties.account);
      this.actionGetContraAccountSupplierByName(this.allocationsProperties.contra_account);
    }

    if (this.allocationSetting.exportType == AllocationSettingExportTypes.custom) {
      this.allocationsProperties.contra_account = this.getterGetDocument.documentGobdDatum?.supplierAccount ?? '';
      this.forceValidateContraAccountValues();
    }

    if (this.allocationSetting.exportType == AllocationSettingExportTypes.datev) {
      if (this.datevSetting.accountType == AccountTypes.Haben) {
        this.allocationsProperties.contra_account = this.getterGetDocument.documentGobdDatum?.supplierAccount ?? '';
        this.forceValidateContraAccountValues();
      } else if (this.datevSetting.accountType == AccountTypes.Soll) {
        this.allocationsProperties.account = this.getterGetDocument.documentGobdDatum?.supplierAccount ?? '';
        this.forceValidateAccountValues();
      }
    }

    if (this.allocationsProperties?.id == '') {
      if (this.allocationBrands.length > 0) this.allocationsProperties.brand = this.allocationBrands[0].brandNumber;
      if (this.allocationOffices.length > 0) this.allocationsProperties.branch = this.allocationOffices[0].officeNumber;
      if (this.getDriveTypesItemsMapped.length > 0)
        this.allocationsProperties.drive_type = this.getDriveTypesItemsMapped[0].value.toString(); // set in combo first from all drive_types
    }
  }
  getTaxVariants() {
    let taxDate: any = DateUtils.dotDateToIsoDate(DateUtils.formatDateToDotDate(new Date()));
    const taxDateVisible = this.organisationAllocationPropertiesActiveItems.find(
      (x) => x.fieldId == '53204fe8-b606-aab2-3c55-ecb900000000'
    );
    if (DateUtils.isDateEx(this.allocationsProperties.invoice_date) && taxDateVisible)
      taxDate = DateUtils.dotDateToIsoDate(this.allocationsProperties.invoice_date);
    this.getAllocationTaxSettingByGroupDate(taxDate);
  }
  private initialState = allocationTemplateEx.parse({});

  async mounted() {}
  onBlurTaxKey($event: any) {
    //need to handle moment
    // when field tax_key is filled and without losing focus, click on button save
    // for store input value
    this.allocationsProperties.tax_key = $event.target?.value;
  }
  //#region tax calculations
  @Watch('allocationsProperties.tax_key')
  onUpdateTax(newV: any, oldV: any) {
    this.CalcTax();
  }

  @Watch('allocationsProperties.amount')
  onUpdateAmount(newV: any, oldV: any) {
    if (this.allocationsPropertiesList[this.currentAllocation]) {
      this.fromEditedItemToList();
    }

    this.CalcTax();
    this.calcAmountRest();
  }

  get getTaxAmount() {
    return this.formatCurrencyWithLocale(this.allocationsProperties.tax_key_amount);
  }

  private formatCurrencyWithLocale(value: string) {
    if (value == '0,00') value = '0';
    if (value.includes('%')) return value;
    const result = Number(value).toLocaleString(this.$i18n.locale.toLowerCase(), {
      minimumFractionDigits: 2,
    });
    return result;
  }

  private CalcTax() {
    const tax: AllocationTaxSetting = this.getAllocationTaxSettings.items.find(
      (item: any) => item.code == this.allocationsProperties.tax_key
    );

    if (tax) {
      const tax_percent = tax.percent / 100;
      var amount = this.allocationsProperties.amount.replace(',', '.');
      if (amount.includes('%')) {
        var full = this.allocationsPropertiesList[0].amount.replace(',', '.');
        amount = ((Number(full) * Number(amount.replace('%', ''))) / 100).toString();
      }
      const tax_key_amount = (Number(amount) / (1 + tax_percent)) * tax_percent;
      this.allocationsProperties.tax_key_amount = String(Math.round((tax_key_amount + Number.EPSILON) * 100) / 100);
    }
  }

  private CalcBrutto() {
    const tax: AllocationTaxSetting = this.getAllocationTaxSettings.items.find(
      (item: any) => item.id == this.allocationsProperties.tax_key
    );

    if (tax) {
      const tax_percent = tax.percent / 100;
      var amount = this.allocationsProperties.amount.replace(',', '.');
      if (amount.includes('%')) {
        var full = this.allocationsPropertiesList[0].amount.replace(',', '.');
        amount = ((Number(full) * Number(amount.replace('%', ''))) / 100).toString();
      }
      const amountBrutto = Number(amount) + Number(amount) * tax_percent;
      this.allocationsProperties.amount = String(Math.round((amountBrutto + Number.EPSILON) * 100) / 100);
    }
  }

  //#endregion

  private coalesce = (...args: any[]) =>
    args.find((_) => ![null, undefined].includes(_) && _.toString().length > 0) ?? '';

  private kostenstelleAllowedNumbers: Number[] = [];
  private kostentraegerAllowedNumbers: Number[] = [];
  private absatzkanalAllowedNumbers: Number[] = [];
  private antriebsartAllowedNumbers: Number[] = [];

  private async GetSuppliersExtraInfo() {
    const promiseAll = [
      this.actionGetAccountSupplierByName(this.allocationsProperties.account),
      this.actionGetContraAccountSupplierByName(this.allocationsProperties.contra_account),
    ];
    await Promise.all(promiseAll).then(() => {
      // kostenstelle/Cost Centre
      const kostenstelleSingle = this.coalesce(
        this.getAccountSupplier!.kostenstelle,
        this.getContraAccountSupplier!.kostenstelle
      );
      this.kostenstelleAllowedNumbers = rangeParser(kostenstelleSingle.toString());

      const kostenstelle: CostCentre =
        this.getCostCentres.items.find(
          (x: CostCentre) => x.value.toString() === this.kostenstelleAllowedNumbers[0].toString()
        ) ?? costCentre.parse({});
      //console.error(`omposite numbers are: ${this.kostenstelleAllowedNumbers.join(', ')}`);

      // kostentraeger/cost_unit/cost_object
      const kostentraegerSingle = this.coalesce(
        this.getAccountSupplier!.kostentraeger,
        this.getContraAccountSupplier!.kostentraeger
      );
      this.kostentraegerAllowedNumbers = rangeParser(kostentraegerSingle);

      const kostentraeger: CostObjective =
        this.getCostObjectives.items.find(
          (x: CostObjective) => x.value.toString() === this.kostentraegerAllowedNumbers[0].toString()
        ) ?? costObjective.parse({});

      // absatzkanal/sales_channel
      const absatzkanalSingle = this.coalesce(
        this.getAccountSupplier!.absatzkanal,
        this.getContraAccountSupplier!.absatzkanal
      );
      this.absatzkanalAllowedNumbers = rangeParser(absatzkanalSingle);

      const absatzkanal: TradeChannel =
        this.getTradeChannels.items.find(
          (x: TradeChannel) => x.value.toString() === this.absatzkanalAllowedNumbers[0].toString()
        ) ?? tradeChannel.parse({});

      // antriebsart/drive_type
      const antriebsartSingle = this.coalesce(
        this.getAccountSupplier!.antriebsart,
        this.getContraAccountSupplier!.antriebsart
      );
      this.antriebsartAllowedNumbers = rangeParser(antriebsartSingle);

      const antriebsart: DriveType =
        this.getDriveTypes.items.find(
          (x: DriveType) => x.value.toString() === this.antriebsartAllowedNumbers[0].toString()
        ) ?? driveType.parse({});

      if (kostenstelle) {
        //Cost Centre - cost_center
        if (this.allocationsProperties.cost_center == '')
          this.allocationsProperties.cost_center = kostenstelle.value == 0 ? '' : kostenstelle.value.toString();
      }

      if (kostentraeger) {
        // Cost Objective - cost_unit
        if (this.allocationsProperties.cost_unit == '')
          this.allocationsProperties.cost_unit = kostentraeger.value == 0 ? '' : kostentraeger.value.toString();
      }

      if (absatzkanal) {
        //Trade Channel - sales_channel
        if (this.allocationsProperties.sales_channel == '')
          this.allocationsProperties.sales_channel = absatzkanal.value == 0 ? '' : absatzkanal.value.toString();
      }

      if (antriebsart) {
        //Trade Channel - sales_channel
        if (this.allocationsProperties.drive_type == '')
          this.allocationsProperties.drive_type = antriebsart.value == 0 ? '' : antriebsart.value.toString();
      }
    });
  }

  get IsKostenstelleDisabled() {
    //cost_center
    return (
      this.getAccountSupplier!.kostenstelle.trim().length == 0 &&
      this.getContraAccountSupplier!.kostenstelle.trim().length == 0
    );
  }

  get IsAbsatzkanalDisabled() {
    //sales_channel
    return (
      this.getAccountSupplier!.absatzkanal.trim().length == 0 &&
      this.getContraAccountSupplier!.absatzkanal.trim().length == 0
    );
  }

  get IsKostentraegerDisabled() {
    //cost_unit/cost_object ????
    return (
      this.getAccountSupplier!.kostentraeger.trim().length == 0 &&
      this.getContraAccountSupplier!.kostentraeger.trim().length == 0
    );
  }

  get IsAntriebsartDisabled() {
    //drive_type

    return (
      this.getAccountSupplier!.antriebsart.trim().length == 0 &&
      this.getContraAccountSupplier!.antriebsart.trim().length == 0
    );
  }

  private HasOnlyOneCostCenterAndCostUnitAndTradeChanel() {
    let result = false;
    if (this.getAccountSupplier?.konto.length == 0 || this.getContraAccountSupplier?.konto.length == 0) return true;

    if (
      //  all account kostenstelle-cost_center, kostentraeger-cost_unit, absatzkanal-sales_channel, antriebsart-drive_type == 0 and one of contra account !=0
      this.getAccountSupplier!.kostenstelle.length == 0 &&
      this.getAccountSupplier!.kostentraeger.length == 0 &&
      this.getAccountSupplier!.absatzkanal.length == 0 &&
      this.getAccountSupplier!.antriebsart.length == 0 &&
      (this.getContraAccountSupplier!.kostenstelle.length != 0 ||
        this.getContraAccountSupplier!.kostentraeger.length != 0 ||
        this.getContraAccountSupplier!.absatzkanal.length != 0 ||
        this.getContraAccountSupplier!.antriebsart.length != 0)
    )
      result = true;

    if (
      // one of account !=0 and contra account kostenstelle, kostentraeger, absatzkanal, Antriebsart == 0
      (this.getAccountSupplier!.kostenstelle.length != 0 ||
        this.getAccountSupplier!.kostentraeger.length != 0 ||
        this.getAccountSupplier!.absatzkanal.length != 0 ||
        this.getAccountSupplier!.antriebsart.length != 0) &&
      this.getContraAccountSupplier!.kostenstelle.length == 0 &&
      this.getContraAccountSupplier!.kostentraeger.length == 0 &&
      this.getContraAccountSupplier!.absatzkanal.length == 0 &&
      this.getContraAccountSupplier!.antriebsart.length == 0
    )
      result = true;

    if (
      // account == 0 and contra account == 0
      this.getAccountSupplier!.kostenstelle.length == 0 &&
      this.getAccountSupplier!.kostentraeger.length == 0 &&
      this.getAccountSupplier!.absatzkanal.length == 0 &&
      this.getAccountSupplier!.antriebsart.length == 0 &&
      this.getContraAccountSupplier!.kostenstelle.length == 0 &&
      this.getContraAccountSupplier!.kostentraeger.length == 0 &&
      this.getContraAccountSupplier!.absatzkanal.length == 0 &&
      this.getContraAccountSupplier!.antriebsart.length == 0
    )
      result = true;

    return result;
  }

  private async getAccount(event: any) {
    if (event.target.value != '') this.accountChanged = true;
    await this.actionGetAccountSupplierByName(event.target.value).then(() => {
      this.GetSuppliersExtraInfo().then(() => {
        if (this.refForm) {
          this.refForm.validate();
        }
      });
    });
  }

  private async getContraAccount(event: any) {
    if (event.target.value != '') this.contraAccountChanged = true;
    await this.actionGetContraAccountSupplierByName(event.target.value).then(() => {
      this.GetSuppliersExtraInfo().then(() => {
        if (this.refForm) {
          this.refForm.validate();
        }
      });
    });
  }

  //#region rules
  get serialNumberRules() {
    const isRequired =
      this.getAccountSupplier?.serialNumberRequired || this.getContraAccountSupplier?.serialNumberRequired;
    return {
      //`${v}`.toString().trim().length fixes --> "TypeError: Cannot read properties of null (reading 'toString')"
      required: (v: any) =>
        (`${v}`.toString().trim().length != 0 && isRequired && this.isSavePressed) ||
        !isRequired ||
        !this.isSavePressed ||
        this.$i18n.t('messages.field_required').toString(),
    };
  }

  async forceValidateContraAccountValues() {
    this.$nextTick(() => {
      const contraAccount = this.$refs.contra_account as any;
      this.$nextTick(() => {
        setTimeout(() => {
          if (contraAccount?.length) {
            contraAccount[0]?.$refs?.input?.focus();
            contraAccount[0]?.$refs?.input?.blur();
            this.setFirstField();
          }
        });
      });
    });
  }

  async forceValidateAccountValues() {
    const account = this.$refs.account as any;
    this.$nextTick(() => {
      setTimeout(() => {
        account[0]?.$refs?.input?.focus();
        account[0]?.$refs?.input?.blur();
        this.setFirstField();
      });
    });
  }

  async setFirstField() {
    const id = this.organisationAllocationPropertiesActiveItems[0].fieldId;
    const element = document.getElementById(id);
    if (element) {
      const input = element?.getElementsByTagName('input')[0];
      input.focus();
      input.setSelectionRange(0, 0);
    }
  }

  private accountChanged: boolean = false;
  private contraAccountChanged: boolean = false;

  get accountRules() {
    return {
      required: (v: any) =>
        !(this.accountChanged && v.toString().trim().length == 0) || this.$t('messages.field_required'),
      not_found: (v: any) =>
        !(this.accountChanged && this.getAccountSupplier!.konto == '') || this.$t('messages.account_not_found'),
      one_can_have: (v: any) =>
        !(this.accountChanged && !this.HasOnlyOneCostCenterAndCostUnitAndTradeChanel()) ||
        this.$t('messages.only_one_can_have'),
      same_account: (v: any) =>
        !(
          this.allocationsProperties.account != '' &&
          this.allocationsProperties.contra_account != '' &&
          this.allocationsProperties.account == this.allocationsProperties.contra_account
        ) || this.$t('messages.the_same_account'),
    };
  }

  get contraAccountRules() {
    return {
      required: (v: any) =>
        !(this.contraAccountChanged && v.toString().trim().length == 0) || this.$t('messages.field_required'),
      not_found: (v: any) =>
        !(this.contraAccountChanged && this.getContraAccountSupplier!.konto == '') ||
        this.$t('messages.account_not_found'),
      one_can_have: (v: any) =>
        !(this.contraAccountChanged && !this.HasOnlyOneCostCenterAndCostUnitAndTradeChanel()) ||
        this.$t('messages.only_one_can_have'),
      same_account: (v: any) =>
        !(
          this.allocationsProperties.account != '' &&
          this.allocationsProperties.contra_account != '' &&
          this.allocationsProperties.account == this.allocationsProperties.contra_account
        ) || this.$t('messages.the_same_account'),
    };
  }

  showInvoiceDateError = '';
  get invoiceDateRules() {
    return [
      (v: any) => {
        if (this.isSavePressed && !DateUtils.isDateEx(v)) {
          this.showInvoiceDateError = this.$i18n.t('messages.field_required').toString();
          return false;
        } else {
          this.showInvoiceDateError = '';
          return true;
        }
      },
    ];
  }

  MaxLengthRule(id: string, field: any) {
    const orgField = this.getOrganisationAllocationProperties.items.find(
      (x: OrganisationAllocationProperty) => x.fieldId == id
    );
    if (!orgField) return true;

    const maxLength = orgField!.maxLength;

    if (maxLength == 0) return true;
    return (
      (field || '').length <= maxLength || this.$i18n.t('messages.max_length', { characters: maxLength }).toString()
    );
  }

  get invoiceNumberRules() {
    return {
      required: (v: any) =>
        (this.isSavePressed && `${v}`.trim().length != 0) ||
        !this.isSavePressed ||
        this.$i18n.t('messages.field_required').toString(),
    };
  }

  showAmountError = '';
  get amountRules() {
    return [
      (v: any) => {
        if (this.isSavePressed && `${v}`.trim().length == 0) {
          this.showAmountError = this.$i18n.t('messages.field_required').toString();
          return false;
        } else if (!NumberUtils.isNumber(v)) {
          //isNumberPositiveFloatOrInteger
          this.showAmountError = this.$i18n.t('messages.right_amount').toString();
          return false;
        } else {
          this.showAmountError = '';
          return true;
        }
      },
    ];
  }

  get driveTypeRules() {
    //Antriebsart
    return {
      required: (v: any) =>
        (this.isSavePressed && `${v}`.trim().length != 0 && !this.IsAntriebsartDisabled) ||
        !this.isSavePressed ||
        this.IsAntriebsartDisabled ||
        this.$t('messages.field_required'),
      only_x_allowed: (v: any) =>
        (this.isSavePressed && this.antriebsartAllowedNumbers.includes(Number(v)) && !this.IsAntriebsartDisabled) ||
        !this.isSavePressed ||
        this.IsAntriebsartDisabled ||
        this.$t('messages.only_x_allowed', { 0: this.antriebsartAllowedNumbers.join(', ') }),
    };
  }

  get costCenterlRules() {
    //Kostenstelle
    return {
      required: (v: any) =>
        (this.isSavePressed && `${v}`.trim().length != 0 && !this.IsKostenstelleDisabled) ||
        !this.isSavePressed ||
        this.IsKostenstelleDisabled ||
        this.$t('messages.field_required'),
      only_x_allowed: (v: any) =>
        (this.isSavePressed && this.kostenstelleAllowedNumbers.includes(Number(v)) && !this.IsKostenstelleDisabled) ||
        !this.isSavePressed ||
        this.IsKostenstelleDisabled ||
        this.$t('messages.only_x_allowed', { 0: this.kostenstelleAllowedNumbers.join(', ') }),
    };
  }

  get costUnitlRules() {
    //Kostentraeger
    return {
      required: (v: any) =>
        (this.isSavePressed && `${v}`.trim().length != 0 && !this.IsKostentraegerDisabled) ||
        !this.isSavePressed ||
        this.IsKostentraegerDisabled ||
        this.$t('messages.field_required'),
      only_x_allowed: (v: any) =>
        (this.isSavePressed && this.kostentraegerAllowedNumbers.includes(Number(v)) && !this.IsKostentraegerDisabled) ||
        !this.isSavePressed ||
        this.IsKostentraegerDisabled ||
        this.$t('messages.only_x_allowed', { 0: this.kostentraegerAllowedNumbers.join(', ') }),
    };
  }

  get salesChannelRules() {
    //Absatzkanal
    return {
      required: (v: any) =>
        (this.isSavePressed && `${v}`.trim().length != 0 && !this.IsAbsatzkanalDisabled) ||
        !this.isSavePressed ||
        this.IsAbsatzkanalDisabled ||
        this.$t('messages.field_required'),
      only_x_allowed: (v: any) =>
        (this.isSavePressed && this.absatzkanalAllowedNumbers.includes(Number(v)) && !this.IsAbsatzkanalDisabled) ||
        !this.isSavePressed ||
        this.IsAbsatzkanalDisabled ||
        this.$t('messages.only_x_allowed', { 0: this.absatzkanalAllowedNumbers.join(', ') }),
    };
  }

  //#endregion

  private async loadValues() {
    this.getAllocationList!.forEach((element: Allocation) => {
      const editedItem: AllocationTemplateEx = allocationTemplateEx.parse({});
      element.properties?.forEach((item: AllocationPropertyEx) => {
        const key = this.allocationsPropertiesDict[item.allocationFieldID].key;
        if (key != undefined) {
          (editedItem as any)[key!] = item.value;
        }
      });
      editedItem.id = element.id;
      this.allocationsPropertiesList.push(editedItem);
    });
    this.currentAllocation = 0;

    this.fromListToEditedItem().then(() => {
      this.GetSuppliersExtraInfo();
      this.actionGetAccountSupplierByName(this.allocationsProperties.account);
      this.actionGetContraAccountSupplierByName(this.allocationsProperties.contra_account);
      this.initialState = allocationTemplateEx.parse(this.allocationsProperties);
    });

    return;
  }

  private async openNotices() {
    await this.actionGetComments({ documentId: this.documentId });
    const info_box = document.getElementById('info_box');
    const currentDisplayMode = getComputedStyle(info_box!).display;
    if (currentDisplayMode == 'none') {
      info_box!.style.display = 'block';
    } else {
      info_box!.style.display = 'none';
    }
  }

  private openTemplateDialog() {
    this.showDocumentAccountTemplateDialog();
  }

  private grossBtnClick() {
    this.CalcBrutto();
  }

  private accountClick() {}

  get hasAccountId() {
    return this.accountId != null && this.accountId != '';
  }

  private async saveAccount() {
    this.savePressed = true;
    const isFormValidResult = await this.refForm.validate();
    if (!isFormValidResult) return;

    if (this.allocationsPropertiesList.length == 0) {
      this.initAllocationsPropertiesListIfEmpty();
    }
    this.fromEditedItemToList();

    const promiseAll: any[] = [];

    let mainAllocationId = '';
    if (this.accountId == '') mainAllocationId = GuidUtils.newGuid();
    else mainAllocationId = this.accountId;

    // console.error('SAVE:', this.allocationsPropertiesList);

    this.allocationsPropertiesList.forEach((account: AllocationTemplateEx, index: number) => {
      this.account = allocation.parse({});
      if (index == 0) {
        this.account.allocationId = mainAllocationId;
      } else {
        if (this.getAllocationList![index] != undefined)
          this.account.allocationId = this.getAllocationList![index].allocationId;
        this.account.mainAllocationId = mainAllocationId;
      }
      this.account.documentId = this.documentId;
      this.account.id = account.id;
      this.account.properties = [];
      this.account.createdAt = new Date().toISOString();
      this.account.updatedAt = new Date().toISOString();

      const props = allocationTemplateEx.toObject(account);
      for (const key in props) {
        const item = props[key];
        const ap: AllocationPropertyEx = {
          allocationFieldID: '',
          key: '',
          name: '',
          type: '',
          value: '',
          sortOrder: 0,
          enabled: true,
        };
        ap.key = key;
        ap.value = item.value!;
        if (
          this.isActiveOrganisationAllocationProperty('53204fe8-b606-aab2-3c55-ecb900000000') &&
          key == '53204fe8-b606-aab2-3c55-ecb900000000' &&
          !DateUtils.isDateDe(item.value)
        ) {
          ap.value = DateUtils.localInputToDotDe(item.value, this.$i18n.locale);
        }
        if (key == '53204fe8-b606-aab2-3c55-ecc500000000' && ap.value.includes('%')) {
          let full = this.allocationsPropertiesList[0].amount.replace(',', '.');
          ap.value = ((Number(full) * Number(ap.value.replace('%', ''))) / 100).toString();
        }
        this.account.properties.push(ap);
        if (key == '53204fe8-b606-aab2-3c55-ecbd00000000') {
          this.account.accountName = item.value!;
        }
        if (key == '53204fe8-b606-aab2-3c55-ecbf00000000') {
          this.account.contraAccountName = item.value!;
        }
      }
      //promiseAll.push(this.actionUpdateAllocation(this.account));
      this.actionUpdateAllocation(this.account);
    });

    //update first favorite property
    const updatedDocumentProperties: any = [];
    updatedDocumentProperties.push(this.documentPropertyFirstEnabled);

    //await Promise.all(promiseAll).then(() => {
    if (this.documentPropertyFirstEnabled.name != '' && !isBlank(this.documentPropertyFirstEnabled.value)) {
      this.actionUpdateDocumentPropertys(updatedDocumentProperties);
    }
    await this.actionGetMainAllocationsByDocumentId(this.documentId).then(() => {
      console.error('SAVDE: ');
      this.$emit('click:return');
    });
    //});
  }

  /// ----------

  private accountDisabled: boolean = false;
  private contraAccountDisabled: boolean = false;

  private async splitAccount() {
    //validate
    this.savePressed = true;
    const isFormValidResult = await this.refForm.validate();
    if (!isFormValidResult) return;

    this.initAllocationsPropertiesListIfEmpty();

    //add to list new record
    if (!this.hasAccountId && !this.isLoadedFromTeplate) {
      this.fromEditedItemToList();

      const allocationsProperties: AllocationTemplateEx = allocationTemplateEx.parse({});
      this.allocationsPropertiesList.push(allocationsProperties);

      //set default values
      this.accountDisabled = false;
      this.contraAccountDisabled = false;

      if (this.allocationsProperties.account == this.organization.splitBookingAccount) {
        this.allocationsProperties.account = '';
        this.allocationsProperties.contra_account = this.organization.splitBookingAccount;
        this.contraAccountDisabled = true;
      } else if (this.allocationsProperties.contra_account == this.organization.splitBookingAccount) {
        this.allocationsProperties.account = this.organization.splitBookingAccount;
        this.allocationsProperties.contra_account = '';
        this.accountDisabled = true;
      }

      this.allocationsProperties.amount = i18n.locale === 'de' ? '0,00' : '0.00';
      this.currentAllocation = this.currentAllocation + 1;
    } else {
      //show next record

      this.fromEditedItemToList();
      this.currentAllocation = this.currentAllocation + 1;
      this.fromListToEditedItem();
    }

    this.calcAmountRest();
  }

  private async ZuruekAccount() {
    //const isFormValidResult = await this.refForm.validate();
    //if (!isFormValidResult) return;
    //this.fromEditedItemToList();

    this.currentAllocation = this.currentAllocation - 1;
    if (this.currentAllocation < 0) this.currentAllocation = 0;
    this.fromListToEditedItem();
  }

  private isLoadedFromTeplate: boolean = false;
  private async weiter() {
    const isFormValidResult = await this.refForm.validate();
    if (!isFormValidResult) return;

    this.fromEditedItemToList();

    //add to list new record
    if (!this.hasAccountId && !this.isLoadedFromTeplate) {
      const allocationsProperties: AllocationTemplateEx = allocationTemplateEx.parse(this.allocationsProperties);

      if (this.contraAccountDisabled) {
        allocationsProperties.account = '';
        allocationsProperties.contra_account = this.organization.splitBookingAccount;
      } else if (this.accountDisabled) {
        allocationsProperties.account = this.organization.splitBookingAccount;
        allocationsProperties.contra_account = '';
      }
      allocationsProperties.amount = '';

      this.allocationsPropertiesList.push(allocationsProperties);
    }

    this.currentAllocation = this.currentAllocation + 1;

    this.fromListToEditedItem();

    this.calcAmountRest();
  }

  private async fertig() {
    // validate
    const isFormValidResult = await this.refForm.validate();
    if (!isFormValidResult) return;

    // ccheck record exist
    if (this.allocationsPropertiesList[this.currentAllocation]) {
      this.fromEditedItemToList();
    } else {
      //fill from current edited item
      this.fromEditedItemToList();
    }

    //save
    this.saveAccount();
  }

  private async firstAllocation() {
    this.accountDisabled = false;
    this.contraAccountDisabled = false;

    this.fromEditedItemToList();
    this.currentAllocation = 0;
    this.fromListToEditedItem();
  }

  private returnBack() {
    if (!allocationTemplateEx.compare(this.initialState, this.allocationsProperties)) {
      this.$confirm
        .open(`${i18n.tc(`dialogs.confirmation.title`)}`, `${this.$t('dialogs.confirmation.message')}`, {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        })
        .then(async (response: any) => {
          if (response) {
            this.saveAccount();
          } else {
            this.$emit('click:return');
          }
        });
    } else {
      this.$emit('click:return');
    }
  }

  beforeDestroy() {
    const info_box = document.getElementById('info_box');
    if (info_box?.style?.display) info_box.style.display = 'none';
  }

  private deleteAccount() {
    this.actionDeleteAllocation(this.accountId).then(() => {
      this.$emit('click:return');
    });
  }

  private deleteCurrentSplit() {
    this.allocationsPropertiesList.splice(this.currentAllocation, 1);
    this.currentAllocation = this.currentAllocation - 1;
    this.fromListToEditedItem();
  }

  isBtnMenuShown = false;
  onBtnMenuInputChange(isShow: boolean) {
    this.isBtnMenuShown = isShow;
    if (isShow) {
      this.scrollRightMenuToBottom();
    }
  }

  scrollRightMenuToBottom() {
    const elmnt = document.querySelector('.document-account__menu');
    elmnt?.scrollIntoView(false);
  }

  //#region Document Rights Dialog logic
  private showDocumentAccountDialog(field: string) {
    this.dialogDocumentAccount.show = true;
    this.dialogDocumentAccount.insertIntoField = field;
    switch (this.dialogDocumentAccount.insertIntoField) {
      case 'account': {
        this.dialogDocumentAccount.supplier = this.allocationsProperties.account;
        break;
      }
      case 'contra_account': {
        this.dialogDocumentAccount.supplier = this.allocationsProperties.contra_account;
        break;
      }
    }
  }

  dialogDocumentAccount = {
    show: false,
    insertIntoField: '',
    supplier: '',
    model: {},
    OnClose: () => {
      this.dialogDocumentAccount.show = false;
    },
    OnSelect: (value: any) => {
      switch (this.dialogDocumentAccount.insertIntoField) {
        case 'account': {
          this.allocationsProperties.account = value.konto;
          this.forceValidateAccountValues();
          break;
        }
        case 'contra_account': {
          this.allocationsProperties.contra_account = value.konto;
          this.forceValidateContraAccountValues();
          break;
        }
      }

      this.dialogDocumentAccount.show = false;
      this.GetSuppliersExtraInfo().then(() => {
        if (this.refForm) this.refForm.validate();
      });
    },
  };
  //#endregion

  //#region Document Template Dialog logic
  private showDocumentAccountTemplateDialog() {
    this.dialogDocumentAccountTemplate.show = true;
  }

  dialogDocumentAccountTemplate = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAccountTemplate.show = false;
    },
    OnSelect: (account: AllocationTemplate) => {
      this.dialogDocumentAccountTemplate.show = false;
      this.loadTemplates(account.allocationTemplateId);
    },
  };

  private async loadTemplates(id: string) {
    if (this.hasAccountId) this.accountId = this.allocationsPropertiesList[0].id;
    this.allocationsPropertiesList = [];
    const editedTemplate = await this.actionGetAllocationTemplatesById(id);
    this.isLoadedFromTeplate = true;
    let count = 0;
    editedTemplate.forEach((element: AllocationTemplateEx) => {
      const item: AllocationTemplateEx = allocationTemplateEx.parse({});
      if (count == 0) {
        item.id = this.accountId;
        item.sparkle = element.sparkle;
        item.booking_type = element.booking_type;
        item.document_text_2 = element.document_text_1;
        item.document_text_2 = element.document_text_2;
        item.staff_number = element.staff_number;
        item.origin = element.origin;
        item.exp_distribution_channel = element.exp_distribution_channel;
        item.dealer_own_cost_unit = element.dealer_own_cost_unit;
        item.project = element.project;
        item.discount_rate = element.discount_rate;
        item.exchange_rate = element.exchange_rate;
        item.drive_type = element.drive_type;
        item.account = element.account;
        item.contra_account = element.contra_account;
        item.account_group = element.account_group;
        item.amount = this.allocationsProperties.amount; // preserve amount
        item.invoice_date = this.allocationsProperties.invoice_date; // preserve invoice_date
        item.invoice_number = this.allocationsProperties.invoice_number; // preserve invoice_number
        item.branch = element.branch;
        item.brand = element.brand;
        item.tax_key = element.tax_key;
        item.tax_key_amount = element.tax_key_amount;
        item.posting_text =
          this.allocationsProperties.posting_text.length > 0
            ? this.allocationsProperties.posting_text
            : element.posting_text; // preserve posting_text
        item.cost_center = element.cost_center;
        item.cost_unit = element.cost_unit;
        item.serial_number =
          this.allocationsProperties.serial_number?.length > 0
            ? this.allocationsProperties.serial_number
            : element.serial_number; // preserve serial_number
        item.sales_channel = element.sales_channel;
        item.vat_code = element.vat_code;
        item.contra_account_group = element.contra_account_group;
        item.discount_in = element.discount_in;
        item.days_clean = element.days_clean;
        item.days_discount = element.days_discount;
        item.foreign_currency = element.foreign_currency;
        item.denate_date = element.denate_date;
        item.compensation_date = element.compensation_date;
        item.delivery_date = element.delivery_date;
      } else {
        item.sparkle = element.sparkle;
        item.booking_type = element.booking_type;
        item.document_text_2 = element.document_text_1;
        item.document_text_2 = element.document_text_2;
        item.staff_number = element.staff_number;
        item.origin = element.origin;
        item.exp_distribution_channel = element.exp_distribution_channel;
        item.dealer_own_cost_unit = element.dealer_own_cost_unit;
        item.project = element.project;
        item.discount_rate = element.discount_rate;
        item.exchange_rate = element.exchange_rate;
        item.drive_type = element.drive_type;
        item.account = element.account;
        item.contra_account = element.contra_account;
        item.account_group = element.account_group;
        item.amount = element.amount;
        item.invoice_date = element.invoice_date;
        item.invoice_number = element.invoice_number;
        item.branch = element.branch;
        item.brand = element.brand;
        item.tax_key = element.tax_key;
        item.tax_key_amount = element.tax_key_amount;
        item.posting_text = element.posting_text;
        item.cost_center = element.cost_center;
        item.cost_unit = element.cost_unit;
        item.serial_number = element.serial_number;
        item.sales_channel = element.sales_channel;
        item.vat_code = element.vat_code;
        item.contra_account_group = element.contra_account_group;
        item.discount_in = element.discount_in;
        item.days_clean = element.days_clean;
        item.days_discount = element.days_discount;
        item.foreign_currency = element.foreign_currency;
        item.denate_date = element.denate_date;
        item.compensation_date = element.compensation_date;
        item.delivery_date = element.delivery_date;
      }
      this.allocationsPropertiesList.push(item);
      count++;
    });
    this.fromListToEditedItem();
    this.forceValidateAccountValues();
    this.forceValidateContraAccountValues();
  }

  private async fromListToEditedItem() {
    Object.assign(this.allocationsProperties, this.allocationsPropertiesList[this.currentAllocation]);

    // console.error(
    //   'assign this.allocationsPropertiesList[' + this.currentAllocation + '] to this.allocationsProperties'
    // );
    // console.error(this.allocationsProperties);

    // if (this.$root.$i18n.locale.toLocaleLowerCase() == 'en')
    //   this.allocationsProperties.invoice_date = this.allocationsProperties.invoice_date.split('.').join('/');
    // else this.allocationsProperties.invoice_date = this.allocationsProperties.invoice_date.split('/').join('.');

    if (this.allocationsProperties.amount.includes('%') == false) {
      this.allocationsProperties.amount = NumberUtils.addZeroes(
        '' + this.allocationsProperties.amount.replace(',', '.'),
        this.$root.$i18n.locale.toLocaleLowerCase()
      );
    }
  }
  private fromEditedItemToList() {
    //console.error(
    //  'assign this.allocationsProperties to this.allocationsPropertiesList[' + this.currentAllocation + ']'
    //);
    Object.assign(this.allocationsPropertiesList[this.currentAllocation], this.allocationsProperties);
    //console.error(this.allocationsPropertiesList[this.currentAllocation]);
  }

  get isPercentInField() {
    return this.allocationsProperties.amount.includes('%');
  }

  get ConvertProzentToAmount() {
    let full = 0;
    let real = 0;
    if (this.allocationsPropertiesList.length > 0) {
      full = Number(this.allocationsPropertiesList[0].amount.replace(',', '.'));
      real = full * (Number(this.allocationsProperties.amount.replace('%', '')) / 100);
    }
    return real.toFixed(2);
  }

  get isSplitBuchungReady() {
    return (
      (this.allocationsProperties.contra_account.length > 0 &&
        this.allocationsProperties.contra_account == this.organization.splitBookingAccount) ||
      (this.allocationsProperties.account.length > 0 &&
        this.allocationsProperties.account == this.organization.splitBookingAccount) ||
      this.currentAllocation > 1
    );
  }

  get splittingAmountRest() {
    return i18n.locale === 'de'
      ? this.amountRest.toFixed(2).toString().replace('.', ',')
      : this.amountRest.toFixed(2).toString().replace(',', '.');
  }

  private amountRest = 0;
  private amountRestReal = 0;

  calcAmountRest() {
    let full = 0;
    let ost = 0;
    if (this.allocationsPropertiesList.length > 0) {
      full = Number(this.allocationsPropertiesList[0].amount.replace(',', '.'));
      for (let i = 1; i <= this.currentAllocation; i++) {
        if (this.allocationsPropertiesList[i].amount.includes('%')) {
          let real = full * (Number(this.allocationsPropertiesList[i].amount.replace(',', '.').replace('%', '')) / 100);
          ost += (Number(real) * 10) / 10;
        } else ost += (Number(this.allocationsPropertiesList[i].amount.replace(',', '.')) * 10) / 10;
      }
    }
    this.amountRest = Math.round((full - ost) * 100) / 100;
  }

  private currentAllocation = 0;
  //#endregion

  //#region Document Rights Dialog logic
  private showDocumentAddressesDialog() {
    this.dialogDocumentAddresses.show = true;
  }

  dialogDocumentAddresses = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentAddresses.show = false;
    },
    OnSelect: (value: any) => {
      this.allocationsProperties.serial_number = value.seriennummer;
      this.dialogDocumentAddresses.show = false;
    },
  };
  //#endregion

  //#region process keys Enter, Control
  onFocus(event: any, refname: string) {
    this.openItemsInAutocomplete(refname);
  }
  keyUp(event: any, refname: string) {
    if (event.key == 'Control') this.openItemsInAutocomplete(refname);
    if (event.key == 'Enter') {
      this.closeItemsInAutocomplete(refname);
      this.focusNextInputOnEnter(event.target);
    }
  }
  private openItemsInAutocomplete(refname: string) {
    if (this.$refs[refname] as any) {
      (this.$refs[refname] as any)[0].activateMenu();
    }
  }

  private closeItemsInAutocomplete(refname: string) {
    if (this.$refs[refname] as any) {
      const menu = (this.$refs[refname] as any)[0].$refs as any;
      if (menu?.menu?.value) {
        menu.menu.isActive = false;
      }
    }
  }

  private focusNextInputOnEnter(el: HTMLInputElement) {
    const lastElement = (this.$refs.saveBtn as Vue).$el as HTMLElement;
    buildOnEnter(lastElement)(el);
  }
  //#endregion

  //#region open "Create template dialog" directly
  private isSaveBtnLoading = false;

  accountEditTemplateDialog = {
    show: false,
    model: { ...allocationTemplate.parse({}) },
    properties: {} as AllocationTemplateEx[],
  };

  async accountEditDialogOnClose(account: AllocationTemplate) {
    this.accountEditTemplateDialog.show = false;
    this.showDocumentAccountTemplateDialog();
  }

  async accountEditDialogOnUpdate(account: AllocationTemplate) {
    this.isSaveBtnLoading = true;
    this.actionUpdateAllocationTemplate(account)
      .then((result: any) => {})
      .catch((err: any) => {})
      .finally(() => {
        this.accountEditTemplateDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetAllocationTemplates().then(() => {
          this.showDocumentAccountTemplateDialog();
        });
      });
  }

  // click save buttons
  private initAllocationsPropertiesListIfEmpty() {
    if (this.allocationsPropertiesList.length == 0) {
      const allocationsProperties: AllocationTemplateEx = allocationTemplateEx.parse({});
      this.allocationsPropertiesList.push(allocationsProperties);
      this.fromEditedItemToList();
    }
  }

  private saveAccountTemplate() {}

  formatMoney(value: any) {
    if (value) {
      return i18n.locale === 'de'
        ? Intl.NumberFormat('de-DE').format(value.replace(',', '.'))
        : Intl.NumberFormat('eng-US').format(value.replace(',', '.'));
    }
  }

  private saveOnlyCurrentBooking() {
    this.initAllocationsPropertiesListIfEmpty();
    const account: AllocationTemplate = allocationTemplate.parse({});

    this.fromEditedItemToList();
    const allocationsPropertiesList: AllocationTemplateEx[] = [this.allocationsPropertiesList[0]];
    allocationsPropertiesList[0].amount = '';
    //allocationsPropertiesList[0].drive_type = '';
    allocationsPropertiesList[0].index = 0;
    this.accountEditTemplateDialog.properties = { ...(allocationsPropertiesList as any[]) };

    // if (this.accountEditTemplateDialog.properties[0].branch.length > 0)
    //   this.accountEditTemplateDialog.properties[0].branch = this.getAllocationOffices.items.find(
    //     (x: any) => x.id == this.accountEditTemplateDialog.properties[0].branch
    //   )!.officeNumber;

    // if (this.accountEditTemplateDialog.properties[0].brand.length > 0)
    //   this.accountEditTemplateDialog.properties[0].brand = this.getAllocationBrands.items.find(
    //     (x: any) => x.id == this.accountEditTemplateDialog.properties[0].brand
    //   )!.brandNumber;

    //this.accountEditTemplateDialog.properties[0].cost_unit = this.getCostObjectives.items.find(
    //  (x: any) => x.id == this.getCostCentres.properties[0].cost_unit
    //);

    //this.accountEditTemplateDialog.properties[0].cost_center = this.getCostCentres.items.find(
    //  (x: any) => x.id == this.accountEditTemplateDialog.properties[0].cost_center
    //);

    this.accountEditTemplateDialog.model = { ...account };
    this.accountEditTemplateDialog.show = true;
  }

  private saveMainAndSplitPosting() {
    this.initAllocationsPropertiesListIfEmpty();
    const account: AllocationTemplate = allocationTemplate.parse({});

    this.fromEditedItemToList();

    var copyList = JSON.parse(JSON.stringify(this.allocationsPropertiesList));

    for (var i = 0; i < copyList.length; i++) {
      if (i == 0) copyList[i].amount = '';
      copyList[i].index = i;
    }

    this.accountEditTemplateDialog.properties = { ...(copyList as any) };
    this.accountEditTemplateDialog.model = { ...account };
    this.accountEditTemplateDialog.show = true;
  }
  //#endregion

  get accountDescription() {
    return this.getAccountSupplier?.bezeichnung;
  }

  get accountErrorColor() {
    if (this.accountDescription == undefined) return true;
    if (!this.accountDescription.length) return true;
    return false;
  }

  get contraAccountDescription() {
    return this.getContraAccountSupplier?.bezeichnung;
  }

  get contraAccountErrorColor() {
    if (this.contraAccountDescription == undefined) return true;
    if (!this.contraAccountDescription.length) return true;
    return false;
  }

  //#region Notes btn classes logic
  get notesBtnClassObject() {
    return {
      'yellow-bg': this.documentHasNotes,
    };
  }

  get documentHasNotes() {
    return (
      !!this.getterGetDocument?.documentDetailComments && this.getterGetDocument?.documentDetailComments.length > 0
    ); // if no Notes for document then `documentGobdDatum` will be NULL, i.e. return false
  }
  //#endregion

  detectGobdData() {
    if (this.hasAccountId) {
      return;
    }

    this.actionDetectGobdData(this.documentId).then((result: GobdData) => {
      //console.error(result.amount, ' -->', this.allocationsProperties.amount);
      //console.error(result.invoiceDate, ' --> ', this.allocationsProperties.invoice_date);
      //console.error(result.invoiceNumber, ' --> ', this.allocationsProperties.invoice_number);
      //console.error(result.serialNumber, ' --> ', this.allocationsProperties.serial_number);
      if (result.amount) {
        if (
          this.allocationsProperties.amount.length == 0 ||
          this.allocationsProperties.amount == '0,00' ||
          this.allocationsProperties.amount == '0.00'
        )
          this.allocationsProperties.amount = NumberUtils.addZeroes(
            '' + result.amount.toString().replace(',', '.'),
            this.$root.$i18n.locale.toLocaleLowerCase()
          );
      }

      if (this.allocationsProperties.invoice_date.length == 0)
        this.allocationsProperties.invoice_date = DateUtils.formatDateToDotDate(new Date(result.invoiceDate));

      if (this.allocationsProperties.invoice_number.length == 0)
        this.allocationsProperties.invoice_number = result.invoiceNumber;

      if (this.allocationsProperties.serial_number.length == 0)
        this.allocationsProperties.serial_number = result.serialNumber;
    });
  }
}
