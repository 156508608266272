import { ActionTree } from 'vuex';
import { RoleLoginTimeState } from './types';
import { RootState } from '../../types';
import { defaultBackendRoleLoginTime } from '@/shared/backend/roleLoginTime';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import roleLoginTime, { Value, RoleLoginTime } from '@/shared/model/roleLoginTime';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.roleLoginTimes');
export const actions: ActionTree<RoleLoginTimeState, RootState> = {
  getRoleLoginTimes({ commit, dispatch, getters }, payload?: { searchParams?: any }) {
    commit('setRoleLoginTimesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getRoleLoginTimesSearchParams;

    return defaultBackendRoleLoginTime
      .getRoleLoginTimes(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setRoleLoginTimes', response.data);
        commit('setRoleLoginTimesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRoleLoginTimesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRoleLoginTime({ commit, dispatch }, id: string) {
    return defaultBackendRoleLoginTime
      .getRoleLoginTime(id)
      .then((response: AxiosResponse<RoleLoginTime>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRoleLoginTime({ commit, dispatch }, file: RoleLoginTime) {
    return defaultBackendRoleLoginTime
      .updateRoleLoginTime(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoleLoginTime;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.role_login_time_update`), commit);
          commit('updateRoleLoginTime', response.data);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteRoleLoginTime({ commit, dispatch }, id: string) {
    return defaultBackendRoleLoginTime
      .deleteRoleLoginTime(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoleLoginTime;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
