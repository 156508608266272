import { Value as ActionLogUserValue } from '@/shared/model/actionLogUser';
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import actionLog, { ActionLog, Value } from '../model/actionLog';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.ActionLog');
export interface BackendActionLog {
  getActionLog(id: string): AxiosPromise<ActionLog>;
  getActionLogs: (searchParams: SearchParams, organizationId: string, documentIdFilter: string) => AxiosPromise<Value>;
  getActionLogsQa: (searchParams: SearchParams, organizationId: any) => AxiosPromise<Value>;
  deleteActionLog(id: string): AxiosPromise;
  getActionLogUsers(id: string, searchParams: SearchParams): AxiosPromise<ActionLogUserValue>;
  markAsReadNotification(documentId: string): AxiosPromise<any>;
  closeThread(id: string): AxiosPromise<any>;
  updateActionLog(ActionLog: ActionLog): AxiosPromise<any>;
}

export const defaultBackendActionLog: BackendActionLog = {
  getActionLog(id: string): AxiosPromise<ActionLog> {
    const url = `${URLS.actionLogOdata}/${id}`;
    return instance.get<ActionLog>(url);
  },
  getActionLogs(searchParams: SearchParams, organizationId: string, documentIdFilter: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false);
    if (documentIdFilter) odfb.eq(CONST.DocumentId, documentIdFilter, false);
    // else
    //   odfb.and(
    //     ODataFilterBuilder('or')
    //       .eq(CONST.DocumentId, null, false)
    //       .eq('type', 'start_thread')
    //       .eq('type', 'close_thread')
    //       .eq('type', 'comment_thread')
    //   );

    const url = DefaultBackendHelper.makeUrl(
      `${URLS.actionLogOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getActionLogs${url}`);
    return instance.get<Value>(url);
  },
  getActionLogUsers(actionLogId: string, searchParams: SearchParams): AxiosPromise<ActionLogUserValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.actionLogOdata}(${actionLogId})/ActionLogFullThreadUsers`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getActionLogs${url}`);
    return instance.get<ActionLogUserValue>(url);
  },
  // (ED-892) wrote separate method (`getActionLogsQa`) because `andClauseFilters`(with `documentId` did not work) + `orClauseFieldsIds` did not work correctly in `DefaultBackendHelper` (tried to add parameter `searchParams.andClauseFilters` for `url` variable in `getActionLogs` and get many console errors)
  getActionLogsQa: function (searchParams: SearchParams, searchData: any): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, searchData.organizationId, false);
    if (searchData.documentId) odfb.eq(CONST.DocumentId, searchData.documentId, false);

    if (searchData.type) odfb.eq('type', `'${searchData.type}'`, false);
    if (searchData.threadType) {
      odfb.eq('threadType', `'${searchData.threadType}'`, false); // (ED-892) without quotes get error :`The query specified in the URI is not valid. Could not find a property named 'comment_thread' on type 'Backend.Features.ActionLog.ActionLogDto'`
    }
    if (searchData.closed != 'all' && searchData.closed !== undefined) {
      odfb.eq('closed', searchData.closed != 'open');
    }
    if (searchData.threadId && searchData.id) {
      odfb.and(ODataFilterBuilder.or().eq('id', searchData.id, false).eq('threadId', searchData.id, false));
    } else if (searchData.threadId) {
      odfb.and(
        ODataFilterBuilder.or().eq('actionLogId', searchData.threadId, false).eq('threadId', searchData.threadId, false)
      );
    }
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.actionLogOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getActionLogsQa${url}`);
    return instance.get<Value>(url);
  },

  deleteActionLog(id: string): AxiosPromise {
    logger.debug('deleteActionLog');
    return instance.delete(`${URLS.actionLog}/${id}`);
  },
  updateActionLog(ActionLog: ActionLog): AxiosPromise<any> {
    logger.debug('updateActionLog: ', ActionLog);
    // (ED-892) if use `actionLog.toAPI` then `ActionLogAddUpdateCommand command` on backend controller is always NULL, since frontend and backend `ActionLog` models do not match (frontend model has too many fields, ie `actionLogInfos`,  `actionLogMessage`, `senderName` which backend `ActionLog` model does not have )
    return instance.put<ActionLog>(`${URLS.actionLog}/update`, ActionLog);
  },
  markAsReadNotification(documentId: string): AxiosPromise<any> {
    logger.debug('markAsReadNotification: ', documentId);
    return instance.put<ActionLog>(`${URLS.actionLog}/MarkAsReadNotification`, {
      documentId: documentId,
    });
  },
  closeThread(id: string): AxiosPromise<any> {
    logger.debug('markAsReadNotification: ', id);
    return instance.put<ActionLog>(`${URLS.actionLog}/CloseThread`, {
      ThreadId: id,
    });
  },
};
