import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import FilteringButtons from '@/components/filtering-buttons/filtering-buttons.vue';
import { namespace } from 'vuex-class';

const logger = new Logger('menu-address');
const addressModule = namespace('address');

@Component({ name: 'menu-address', components: { FilteringButtons } })
export default class MenuAddressView extends Vue {}
