import { MutationTree } from 'vuex';
import { AllocationFramePropertyState } from './types';
import Vue from 'vue';
import allocationFrameProperty, { AllocationFrameProperty, Value } from '@/shared/model/allocationFrameProperty';

export const mutations: MutationTree<AllocationFramePropertyState> = {
  setAllocationFramePropertys(state, payload: Value) {
    state.allocationFramePropertys.items = payload.value.map((x) => allocationFrameProperty.parse(x));
    state.allocationFramePropertys.total = payload['@odata.count'] || 0;
    state.allocationFramePropertys.isLoading = false;
  },
  setAllocationFramePropertysTotal(state, payload: number) {
    state.allocationFramePropertys.total = payload;
  },
  setAllocationFramePropertysIsLoading(state, payload: boolean) {
    state.allocationFramePropertys.isLoading = payload;
  },
};
