export interface ActionLogRole {
  id: string; // System.Guid
  actionLogId: string; // System.Guid
  roleId: string; // System.Guid
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
}

export interface Value {
  value: ActionLogRole[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ActionLogRole>): any {
  return {
    Id: data?.id || undefined,
    ActionLogId: data?.actionLogId || undefined,
    RoleId: data?.roleId || undefined,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
  };
}

function parse(data?: Partial<ActionLogRole>): ActionLogRole {
  return {
    id: data?.id || '',
    actionLogId: data?.actionLogId || '',
    roleId: data?.roleId || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
