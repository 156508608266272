import { MutationTree } from 'vuex';
import { AllocationTemplateState } from './types';
import Vue from 'vue';
import allocationTemplate, { AllocationTemplate, Value } from '@/shared/model/allocationTemplate';

export const mutations: MutationTree<AllocationTemplateState> = {
  setAllocationTemplates(state, payload: Value) {
    state.allocationTemplates.items = payload.value.map((x) => allocationTemplate.parse(x));
    state.allocationTemplates.total = payload['@odata.count'] || 0;
    state.allocationTemplates.isLoading = false;
  },
  setAllocationTemplatesTotal(state, payload: number) {
    state.allocationTemplates.total = payload;
  },
  setAllocationTemplatesIsLoading(state, payload: boolean) {
    state.allocationTemplates.isLoading = payload;
  },
};
