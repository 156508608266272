import { ActionTree } from 'vuex';
import { SuppliersImportSettingState } from './types';
import { RootState } from '../../types';
import { defaultBackendSuppliersImportSetting } from '@/shared/backend/suppliersImportSetting';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import suppliersImportSetting, { Value, SuppliersImportSetting } from '@/shared/model/suppliersImportSetting';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.suppliersImportSettings');
export const actions: ActionTree<SuppliersImportSettingState, RootState> = {
  getSuppliersImportSettings({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setSuppliersImportSettingsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getSuppliersImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendSuppliersImportSetting
      .getSuppliersImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setSuppliersImportSettings', response.data);
        commit('setSuppliersImportSettingsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setSuppliersImportSettingsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSuppliersImportSetting({ commit, dispatch, getters, rootGetters }) {
    commit('setSuppliersImportSettingsIsLoading', true);
    const searchParams = getters.getSuppliersImportSettingsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendSuppliersImportSetting
      .getSuppliersImportSettings(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setSuppliersImportSetting', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setSuppliersImportSettingsIsLoading', false);
      });
  },
  getSuppliersImportSettingById({ commit, dispatch }, id: string) {
    return defaultBackendSuppliersImportSetting
      .getSuppliersImportSetting(id)
      .then((response: AxiosResponse<SuppliersImportSetting>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateSuppliersImportSetting({ commit, dispatch, rootGetters }, file: SuppliersImportSetting) {
    commit('setInProgressUpdateSuppliersImportSetting', true);
    file.organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendSuppliersImportSetting
      .updateSuppliersImportSetting(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: SuppliersImportSetting;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.suppliersImportSetting_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateSuppliersImportSetting', false);
      });
  },
  updateChangeClearBeforeImport({ commit, dispatch, rootGetters, getters }, payload: { value: boolean; old: boolean }) {
    getters.getSuppliersImportSetting.clearBeforeImport = payload.value;
    dispatch('updateSuppliersImportSetting', getters.getSuppliersImportSetting)
      .then((result: any) => {
        getters.getSuppliersImportSetting.id = result.id;
      })
      .catch(() => {
        getters.getSuppliersImportSetting.clearBeforeImport = payload.old;
      });
  },
  deleteSuppliersImportSetting({ commit, dispatch }, id: string) {
    return defaultBackendSuppliersImportSetting
      .deleteSuppliersImportSetting(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: SuppliersImportSetting;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
