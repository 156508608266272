import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import MenuDocumentReminderEdit from './menu-document-reminder-edit/menu-document-reminder-edit.vue';
import { debounceAsync } from '@/shared/utils/generalUtils';
import GuidUtils from '@/shared/utils/GuidUtils';
import documentReminder, { DocumentReminder } from '@/shared/model/documentReminder';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('menu-document-reminders');

const documentReminderModule = namespace('documentReminder');

@Component({
  name: 'menu-document-reminders',
  components: { MenuDocumentReminderEdit },
})
export default class MenuDocumentReminders extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => documentReminder.parse({}) })
  private value!: any;

  @documentReminderModule.Getter('getDocumentReminders')
  private getDocumentReminders?: any;
  @documentReminderModule.Action('getDocumentReminders')
  private actionGetDocumentReminders?: any;
  @documentReminderModule.Action('deleteDocumentReminder')
  private actionDeleteDocumentReminder?: any;
  @documentReminderModule.Action('updateDocumentReminder')
  private actionUpdateDocumentReminder?: any;

  // debounce
  private getDocumentRemindersDebounced = debounceAsync(this.GetDocumentReminders);
  private async GetDocumentReminders() {
    await this.actionGetDocumentReminders({ documentId: this.documentId });
  }
  // debounce

  @Watch('getDocumentReminders.searchParams', { deep: true })
  onUpdateSearchParams(nwal: any, owal: any) {
    this.getDocumentRemindersDebounced({ searchParams: this.getDocumentReminders.searchParams });
  }

  private itemsPerPageOptions: number[] = [10, 25, 50, 100];
  private isSaveBtnLoading = false;

  get documentReminders() {
    return this.getDocumentReminders;
  }

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('active'), value: 'subject' },
      { text: this.$t('name'), value: 'name' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  async mounted() {
    const promiseAll = [this.getDocumentRemindersDebounced()];
    await Promise.all(promiseAll);
  }

  onClickDelete(item: DocumentReminder) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text', {
          0: item.id,
        })}`,
        {
          cancelText: `${this.$t('cancel')}`,
          okText: this.$t('delete'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteDocumentReminder(item.id)
            .then(() => {
              this.getDocumentRemindersDebounced();
            })
            .catch((err: any) => {});
        }
      });
  }

  get documentId() {
    return this.$route.params['documentId'];
  }

  onClickCopy(item: DocumentReminder): void {}

  onClickAdd() {
    this.itemEditDialog.model = {
      ...documentReminder.parse({
        startAt: new Date().toLocaleDateString('en-US') + ' ' + new Date().getHours() + ':' + new Date().getMinutes(),
        documentId: this.documentId,
        times: 3,
        days: 1,
      }),
    };
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: DocumentReminder) {
    //const clone = structuredClone(item);
    const clone = JSON.parse(JSON.stringify(item));
    this.itemEditDialog.model = { ...documentReminder.parse(clone) };
    this.itemEditDialog.show = true;
  }

  itemEditDialog = {
    show: false,
    model: { ...documentReminder.parse({}) },
  };

  async itemEditDialogOnClose(item: DocumentReminder) {
    this.itemEditDialog.show = false;
  }

  async itemEditDialogOnUpdate(item: DocumentReminder) {
    this.isSaveBtnLoading = true;
    this.actionUpdateDocumentReminder(item)
      .then((result: any) => {
        this.getDocumentRemindersDebounced();
      })
      .catch((err: any) => {})
      .finally(() => {
        this.itemEditDialog.show = false;
        this.isSaveBtnLoading = false;
      });
  }

  formatDate(date: string) {
    return DateUtils.formatDateFromUtcNowToDisplayWithTimeZone(date, this.$i18n.locale.toLowerCase());
  }
}
