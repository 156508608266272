import { AddressState } from './types';

export const state = (): AddressState => initialState();

export const initialState = (): AddressState => ({
  addresses: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 0, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['konto'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'Konto',
        'Nachname',
        'Vorname',
        'Kennzeichen',
        'Seriennummer',
        'Anrede',
        'Strasse',
        'Plz',
        'Ort',
        'Tel1',
        'Tel2',
        'Mail1',
        'Mail2',
        'Zbv1',
        'Zbv2',
      ],
      orClauseFieldsIdsConvertToString: ['Konto'],
      orClauseFieldsIdsIgnoreCase: [
        'Konto',
        'Nachname',
        'Vorname',
        'Kennzeichen',
        'Seriennummer',
        'Anrede',
        'Strasse',
        'Plz',
        'Ort',
        'Tel1',
        'Tel2',
        'Mail1',
        'Mail2',
        'Zbv1',
        'Zbv2',
      ],
    },
  },
  isImporting: false,
  inProgressGetAddress: false,
  importingFile: undefined,
});
