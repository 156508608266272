export default class GeneralUtils {
  public static isDevEnvironment(): boolean {
    return process.env.NODE_ENV == 'development';
  }
  public static isProdEnvironment(): boolean {
    return process.env.NODE_ENV == 'production';
  }

  public static generateUniqueId() {
    return Date.now().toString(16) + Math.random().toString(16).slice(2);
  }

  public static showFutureFeature = false; // return `FALSE` to hide future functionality until implementation
}

const DEFAULT_DEBOUNCE_TIME = 300;

export function debounce<T extends Function>(callback: T, wait = DEFAULT_DEBOUNCE_TIME) {
  let timer = 0;
  return (...args: any) => {
    clearTimeout(timer);
    timer = window.setTimeout(() => callback(...args), wait);
  };
}

export function debounceAsync<T extends Function>(callback: T, wait = DEFAULT_DEBOUNCE_TIME) {
  return debounce(async () => await callback(), wait);
}

/**
 * Will return:
 * False for: for all strings with chars
 * True for: false, null, undefined, 0, 0.0, "", " ".
 *
 * @param str
 * @returns {boolean}
 */
export function isBlank(str: any) {
  return !!!str || /^\s*$/.test(str);
}
