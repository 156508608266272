import { MutationTree } from 'vuex';
import { AutoGobdCheckTermState } from './types';
import Vue from 'vue';
import autoGobdCheckTerm, { AutoGobdCheckTerm, Value } from '@/shared/model/autoGobdCheckTerm';

export const mutations: MutationTree<AutoGobdCheckTermState> = {
  setAutoGobdCheckTerms(state, payload: Value) {
    state.autoGobdCheckTerms.items = payload.value.map((x) => autoGobdCheckTerm.parse(x));
    state.autoGobdCheckTerms.total = payload['@odata.count'] || 0;
    state.autoGobdCheckTerms.isLoading = false;
  },
  setAutoGobdCheckTermsTotal(state, payload: number) {
    state.autoGobdCheckTerms.total = payload;
  },
  setAutoGobdCheckTermsIsLoading(state, payload: boolean) {
    state.autoGobdCheckTerms.isLoading = payload;
  },
  pushRunAutoGobdCheckTerm(state, id: string) {
    state.isRunAutoGobdCheckTerms.push(id);
  },
  popRunAutoGobdCheckTerm(state, id: string) {
    const index = state.isRunAutoGobdCheckTerms.indexOf(id);
    state.isRunAutoGobdCheckTerms.splice(index, 1);
  },
};
