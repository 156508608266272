import { ActionTree } from 'vuex';
import { DriveTypeState } from './types';
import { RootState } from '../../types';
import { defaultBackendDriveType } from '@/shared/backend/driveType';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import driveType, { Value, DriveType } from '@/shared/model/driveType';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.driveTypes');
export const actions: ActionTree<DriveTypeState, RootState> = {
  getDriveTypes({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setDriveTypesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getDriveTypesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendDriveType
      .getDriveTypes(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDriveTypes', response.data);
        commit('setDriveTypesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDriveTypesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDriveType({ commit, dispatch }, id: string) {
    return defaultBackendDriveType
      .getDriveType(id)
      .then((response: AxiosResponse<DriveType>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDriveType({ commit, dispatch }, file: DriveType) {
    return defaultBackendDriveType
      .updateDriveType(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DriveType;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.driveType_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  uploadDriveType(
    { commit, dispatch, rootGetters },
    fileInfo: {
      fileForImport: any;
      organisationId: string;
      onUploadProgress: ((progressEvent: any) => void) | undefined;
    }
  ) {
    commit('setIsImporting', true);
    fileInfo.organisationId = fileInfo.organisationId ?? rootGetters[CONST.getOrganisation].id;
    return defaultBackendDriveType
      .uploadDriveType(fileInfo)
      .then((response: any) => {
        module_utils.ok(i18n.tc(`success.drive_type_uploaded`), commit);
        return response.data;
      })
      .catch((e: any) => {
        //show first error from responce if exits translate
        let error = [{ code: 'error', description: i18n.tc('error') }];
        if (Array.isArray(e.response.data.errors?.$values)) error = e.response.data.errors?.$values;
        if (Array.isArray(e.response.data.errors)) error = e.response.data.errors;
        if (i18n.te('errors.' + error[0].code)) error[0].description = i18n.tc('errors.' + error[0].code);

        module_utils.error(error[0].description, commit, e, logger);
        throw e.response.data;
      })
      .finally(() => {
        commit('setIsImporting', false);
      });
  },
  deleteDriveType({ commit, dispatch }, id: string) {
    return defaultBackendDriveType
      .deleteDriveType(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DriveType;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
