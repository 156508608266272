import { ActionTree } from 'vuex';
import { AllocationFramePropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationFrameProperty } from '@/shared/backend/allocationFrameProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationFrameProperty, { Value, AllocationFrameProperty } from '@/shared/model/allocationFrameProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationFramePropertys');
export const actions: ActionTree<AllocationFramePropertyState, RootState> = {
  getAllocationFramePropertys({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationFramePropertysIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationFramePropertysSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationFrameProperty
      .getAllocationFramePropertys(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationFramePropertys', response.data);
        commit('setAllocationFramePropertysIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationFramePropertysIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationFrameProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationFrameProperty
      .getAllocationFrameProperty(id)
      .then((response: AxiosResponse<AllocationFrameProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAllocationFrameProperty({ commit, dispatch }, file: AllocationFrameProperty) {
    return defaultBackendAllocationFrameProperty
      .updateAllocationFrameProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationFrameProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationFrameProperty_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationFrameProperty({ commit, dispatch }, id: string) {
    return defaultBackendAllocationFrameProperty
      .deleteAllocationFrameProperty(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationFrameProperty;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
