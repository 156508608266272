import { ActionTree } from 'vuex';
import { EmployeeState } from './types';
import { RootState } from '../../types';
import { defaultBackendEmployee } from '@/shared/backend/employee';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import ds, { Value, Employee } from '@/shared/model/employee';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.employees');
export const actions: ActionTree<EmployeeState, RootState> = {
  getEmployees(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; parentEmployeesId?: string }
  ) {
    commit('setEmployeesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getEmployeesSearchParams;
    const organizationId = rootGetters['organization/getOrganization'].id;
    return defaultBackendEmployee
      .getEmployees(searchParams, organizationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setEmployees', response.data);
        commit('setEmployeesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setEmployeesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getEmployee({ commit, dispatch }, id: string) {
    return defaultBackendEmployee
      .getEmployee(id)
      .then((response: AxiosResponse<Employee>) => {
        commit('setEmployee', ds.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateEmployee({ commit, dispatch }, file: Employee) {
    return defaultBackendEmployee
      .updateEmployee(file)
      .then((response) => {
        commit('updateEmployee', response.data.result);
        module_utils.ok(i18n.tc(`success.employee_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteEmployee({ commit, dispatch }, id: string) {
    return defaultBackendEmployee
      .deleteEmployee(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
