import tradeChannelImportSetting from '@/shared/model/tradeChannelImportSetting';
import { TradeChannelImportSettingState } from './types';

export const state = (): TradeChannelImportSettingState => initialState();

export const initialState = (): TradeChannelImportSettingState => ({
  tradeChannelImportSettings: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['CreatedAt'],
    },
  },
  tradeChannelImportSetting: tradeChannelImportSetting.parse({}),
  inProgressUpdateTradeChannelImportSetting: false,
});
