import { MutationTree } from 'vuex';
import { RegexSplitterPartState } from './types';
import Vue from 'vue';
import regexSplitterPart, { RegexSplitterPart, Value } from '@/shared/model/regexSplitterPart';

export const mutations: MutationTree<RegexSplitterPartState> = {
  setRegexSplitterParts(state, payload: Value) {
    state.regexSplitterParts.items = payload.value.map((x) => regexSplitterPart.parse(x));
    state.regexSplitterParts.total = payload['@odata.count'] || 0;
    state.regexSplitterParts.isLoading = false;
  },
  setRegexSplitterPartsTotal(state, payload: number) {
    state.regexSplitterParts.total = payload;
  },
  setRegexSplitterPartsIsLoading(state, payload: boolean) {
    state.regexSplitterParts.isLoading = payload;
  },
};
