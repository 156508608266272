import documentRemindersReceiverUser, { DocumentRemindersReceiverUser } from './documentRemindersReceiverUser';

export interface DocumentReminder {
  id: string; // System.Guid
  documentId: string; // System.Guid
  organisationId: string; // System.Guid
  days: number; // short
  times: number; // short
  subject: string; // string
  message: string; // string
  startAt: string; // System.DateTime
  stopAt: string;
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  documentRemindersReceiverUsers: DocumentRemindersReceiverUser[];
}

export interface Value {
  value: DocumentReminder[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentReminder>): any {
  return {
    Id: data?.id || undefined,
    DocumentId: data?.documentId || undefined,
    OrganisationId: data?.organisationId || undefined,
    Days: data?.days || 0,
    Times: data?.times || 0,
    Subject: data?.subject || '',
    Message: data?.message || '',
    StartAt: data?.startAt || '',
    //CreatedAt: data?.createdAt || '',
    //UpdatedAt: data?.updatedAt || '',
    DocumentRemindersReceiverUsers: data?.documentRemindersReceiverUsers?.map((item) =>
      documentRemindersReceiverUser.toAPI(item)
    ),
  };
}

function parse(data?: Partial<DocumentReminder>): DocumentReminder {
  return {
    id: data?.id || '',
    documentId: data?.documentId || '',
    organisationId: data?.organisationId || '',
    days: data?.days || 0,
    times: data?.times || 0,
    subject: data?.subject || '',
    message: data?.message || '',
    startAt: data?.startAt || '',
    stopAt: data?.stopAt || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    documentRemindersReceiverUsers: data?.documentRemindersReceiverUsers || [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
