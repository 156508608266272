import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import allocationTemplate, { AllocationTemplate, Value } from '../model/allocationTemplate';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
import { AllocationTemplateEx } from '../model/allocationTemplateEx';

const logger = new Logger('backend.AllocationTemplate');
export interface BackendAllocationTemplate {
  getAllocationTemplate(id: string): AxiosPromise<AllocationTemplate>;
  getAllocationTemplatesById(id: string): AxiosPromise<AllocationTemplateEx[]>;
  getAllocationTemplates: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteAllocationTemplate(id: string): AxiosPromise;
  updateAllocationTemplate(AllocationTemplate: AllocationTemplate): AxiosPromise<any>;
}

export const defaultBackendAllocationTemplate: BackendAllocationTemplate = {
  getAllocationTemplate(id: string): AxiosPromise<AllocationTemplate> {
    const url = `${URLS.allocationTemplateOdata}/${id}`;
    return instance.get<AllocationTemplate>(url);
  },
  getAllocationTemplatesById(id: string): AxiosPromise<AllocationTemplateEx[]> {
    const url = `${URLS.allocationTemplate}/GetById/${id}`;
    return instance.get<AllocationTemplateEx[]>(url);
  },
  getAllocationTemplates(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.allocationTemplateOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getAllocationTemplates${url}`);
    return instance.get<Value>(url);
  },

  deleteAllocationTemplate(id: string): AxiosPromise {
    logger.debug('deleteAllocationTemplate');
    return instance.delete(`${URLS.allocationTemplate}/${id}`);
  },
  updateAllocationTemplate(AllocationTemplate: AllocationTemplate): AxiosPromise<any> {
    logger.debug('updateAllocationTemplate');
    return instance.put<AllocationTemplate>(
      `${URLS.allocationTemplate}/update`,
      allocationTemplate.toAPI(AllocationTemplate)
    );
  },
};
