import { FileUploadState } from './types';

export const state = (): FileUploadState => initialState();

export const initialState = (): FileUploadState => ({
  fileUpload: {
    inProgress: false,
    total: 0,
    uploaded: 0,
    errors: 0,
  },
});
