import { ActionTree } from 'vuex';
import { OrganisationAllocationExportPropertyState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisationAllocationExportProperty } from '@/shared/backend/organisationAllocationExportProperty';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organisationAllocationExportProperty, {
  Value,
  OrganisationAllocationExportProperty,
} from '@/shared/model/organisationAllocationExportProperty';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.organisationAllocationExportProperties');
export const actions: ActionTree<OrganisationAllocationExportPropertyState, RootState> = {
  getOrganisationAllocationExportProperties(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any }
  ) {
    commit('setOrganisationAllocationExportPropertiesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganisationAllocationExportPropertiesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendOrganisationAllocationExportProperty
      .getOrganisationAllocationExportProperties(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganisationAllocationExportProperties', response.data);
        commit('setOrganisationAllocationExportPropertiesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setOrganisationAllocationExportPropertiesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganisationAllocationExportProperty({ commit, dispatch }, id: string) {
    return defaultBackendOrganisationAllocationExportProperty
      .getOrganisationAllocationExportProperty(id)
      .then((response: AxiosResponse<OrganisationAllocationExportProperty>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganisationAllocationExportProperty({ commit, dispatch }, file: OrganisationAllocationExportProperty) {
    commit('setIsUpdating', true);
    return defaultBackendOrganisationAllocationExportProperty
      .updateOrganisationAllocationExportProperty(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAllocationExportProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_allocation_export_property_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
  moveOrganisationAllocationExportProperty(
    { commit, dispatch },
    payload: { element: OrganisationAllocationExportProperty; newIndex: number; oldIndex: number }
  ) {
    commit('setIsUpdating', true);
    return defaultBackendOrganisationAllocationExportProperty
      .moveOrganisationAllocationExportProperty(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: OrganisationAllocationExportProperty;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.organisation_allocation_export_property_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setIsUpdating', false);
      });
  },
};
