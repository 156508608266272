import DateUtils from '../utils/DateUtils';
import updatedDiff from '@/shared/utils/deep-object-utils';
import user, { User } from './user';

export interface GobdData {
  id: string;
  amount: string; // `Double` type on the backend // (ED-782): changed frontend type to `String` to handle number DE locale with `,` (comma) (JS number can have ONLY `.`(dot) )
  amountVerified: boolean;
  documentId: string;
  invoiceAddressVerified: boolean;
  invoiceDate: string;
  invoiceNumber: string;
  performanceDateVerified: boolean;
  savedDate: string;
  //SavedUser
  supplierAccount: string;
  taxNumberVerified: boolean;
  userid: string;
  user: User;
  createdAt: string;
  updatedAt: string;
  organisationId: string; // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, so we also use `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
  serialNumber: string;
  saveType: string;
  bookingText: string;
}

export interface Value {
  value: GobdData[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GobdData>): any {
  return {
    Id: data?.id || undefined,
    Amount: data?.amount || 0, // since backend type is `Double` we should send NUMBER on the backend
    AmountVerified: data?.amountVerified || undefined,
    DocumentId: data?.documentId || undefined,
    InvoiceAddressVerified: data?.invoiceAddressVerified || undefined,
    InvoiceDate: DateUtils.dotDateToIsoDate(data?.invoiceDate) || undefined,
    InvoiceNumber: data?.invoiceNumber || '',
    PerformanceDateVerified: data?.performanceDateVerified || undefined,
    SavedDate: data?.savedDate || '',
    SupplierAccount: data?.supplierAccount || '',
    TaxNumberVerified: data?.taxNumberVerified || undefined,
    UserId: data?.userid || undefined,
    Created_At: data?.createdAt || undefined,
    Updated_At: data?.updatedAt || undefined,
    OrganisationId: data?.organisationId || undefined,
    SerialNumber: data?.serialNumber || '',
    BookingText: data?.bookingText || '',
  };
}

function parse(data?: Partial<GobdData>): GobdData {
  return {
    id: data?.id || '',
    amount: data?.amount || '0',
    amountVerified: data?.amountVerified || false,
    documentId: data?.documentId || '',
    invoiceAddressVerified: data?.invoiceAddressVerified || false,
    invoiceDate: data?.invoiceDate || '',
    invoiceNumber: data?.invoiceNumber || '',
    performanceDateVerified: data?.performanceDateVerified || false,
    savedDate: data?.savedDate || '',
    supplierAccount: data?.supplierAccount || '',
    taxNumberVerified: data?.taxNumberVerified || false,
    userid: data?.userid || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    organisationId: data?.organisationId || '',
    serialNumber: data?.serialNumber || '',
    saveType: data?.saveType || '',
    user: data?.user || user.parse({}),
    bookingText: data?.bookingText || '',
  };
}

function compare(obj1: GobdData, obj2: GobdData) {
  const diff = updatedDiff(parse(obj1), parse(obj2));
  return Object.keys(diff).length == 0;
}

export default {
  parse,
  toAPI: toAPI,
  compare,
};
