import { MutationTree } from 'vuex';
import { RoleState } from './types';
import Vue from 'vue';
import roles, { Role, Value } from '@/shared/model/role';
import roleRight, { Value as RoleRightsValue } from '@/shared/model/roleRight';

export const mutations: MutationTree<RoleState> = {
  setRoles(state, payload: Value) {
    state.roles.items = payload.value.map((x) => roles.parse(x));
    state.roles.total = payload['@odata.count'] || 0;
    state.roles.isLoading = false;
  },
  setRolesTotal(state, payload: number) {
    state.roles.total = payload;
  },
  setRolesIsLoading(state, payload: boolean) {
    state.roles.isLoading = payload;
  },
  setRoleRights(state, payload: RoleRightsValue) {
    state.roleRights.items = payload.value.map((x) => roleRight.parse(x));
    state.roleRights.total = payload['@odata.count'] || 0;
    state.roleRights.isLoading = false;
  },
  setRoleRightsTotal(state, payload: number) {
    state.roleRights.total = payload;
  },
  setRoleRightsIsLoading(state, payload: boolean) {
    state.roleRights.isLoading = payload;
  },
  setInProgressUpdateRoleRights(state, payload: boolean) {
    state.inProgressUpdateRoleRights = payload;
  },
  setInProgressUpdateRole(state, payload: boolean) {
    state.inProgressUpdateRole = payload;
  },
};
