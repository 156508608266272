import { render, staticRenderFns } from "./menu-document-account-addresses.html?vue&type=template&id=1799a826&scoped=true&external"
import script from "./menu-document-account-addresses.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-account-addresses.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-account-addresses.scss?vue&type=style&index=0&id=1799a826&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1799a826",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-account-addresses.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-account%2Fmenu-document-account-addresses%2Fmenu-document-account-addresses.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDataFooter,VDataTable,VDialog,VDivider,VIcon,VRow,VSelect,VSpacer,VTextField})
