import { render, staticRenderFns } from "./action-send-email.html?vue&type=template&id=55656f04&scoped=true&external"
import script from "./action-send-email.ts?vue&type=script&lang=ts&external"
export * from "./action-send-email.ts?vue&type=script&lang=ts&external"
import style0 from "./action-send-email.scss?vue&type=style&index=0&id=55656f04&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55656f04",
  null
  
)

/* custom blocks */
import block0 from "./action-send-email.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Ffolder%2Fworkflow-rules%2Fedit-folder-rule%2Faction-send-email%2Faction-send-email.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCol,VDataTable,VLabel,VRow,VTextField,VTextarea,VTooltip})
