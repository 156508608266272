import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import FilteringButtons from '@/components/filtering-buttons/filtering-buttons.vue';
import ProcessEditDialog from './process-edit-dialog/process-edit-dialog.vue';
import { namespace } from 'vuex-class';

const logger = new Logger('menu-process');
const authModule = namespace('auth');

@Component({ name: 'menu-process', components: { FilteringButtons, ProcessEditDialog } })
export default class MenuProcessView extends Vue {
  @authModule.Getter('EditProcessListsAllowed')
  private EditProcessListsAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  // TODO: (ED-276) add `i18n` for EN locale + get `v-select` data from the backend
  selectedOperation = 'Alle Betriebe';
  operations = ['Alle Betriebe'];

  selectedEditor = 'Alle Bearbeiter';
  editors = ['Alle Bearbeiter'];

  selectedTrafficLight = 'alle anzeigen';
  trafficLights = ['alle anzeigen', 'rot', 'gelb', 'grün'];

  selectedAd = 'dynamisch anzeigen';
  ads = ['dynamisch anzeigen', 'alle anzeigen'];

  selectedTask = 'alle anzeigen';
  tasks = ['alle anzeigen'];

  selectedStatus = 'alle anzeigen';
  statuses = ['alle anzeigen', 'rot', 'gelb', 'grün'];

  selectedCheck = 'alle Dokumente';
  checks = ['alle Dokumente', 'neustes Dokument', 'ältestes Dokument'];

  //#region Process add/edit dialog
  isSaveBtnLoading = false;

  processEditDialog = {
    show: false,
    model: {}, // replace with `...process.parse({})`
  };
  async processEditDialogOnClose(process: any) {
    this.processEditDialog.show = false;
  }

  async processEditDialogOnUpdate(process: any) {}

  onClickProcessAdd() {
    this.processEditDialog.show = true;
  }
  //#endregion

  private createNewProcess() {
    this.onClickProcessAdd();
    logger.debug('createNewProcess');
  }
}
