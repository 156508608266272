import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import export_, { Export, Value } from '../model/export';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.Export');
export interface BackendExport {
  getExport(id: string): AxiosPromise<Export>;
  getExports: (searchParams: SearchParams, organizationId: string, folderId: string) => AxiosPromise<Value>;
  deleteExport(id: string): AxiosPromise;
  updateExport(Export: Export): AxiosPromise<any>;
}

export const defaultBackendExport: BackendExport = {
  getExport(id: string): AxiosPromise<Export> {
    const url = `${URLS.exportOdata}/${id}`;
    return instance.get<Export>(url);
  },
  getExports(searchParams: SearchParams, organizationId: string, folderId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false).eq(CONST.FolderId, folderId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.exportOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getExports${url}`);
    return instance.get<Value>(url);
  },

  deleteExport(id: string): AxiosPromise {
    logger.debug('deleteExport');
    return instance.delete(`${URLS.export}/${id}`);
  },
  updateExport(Export: Export): AxiosPromise<any> {
    logger.debug('updateExport');
    return instance.put<Export>(`${URLS.export}/update`, export_.toAPI(Export));
  },
};
