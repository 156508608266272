export interface OrganisationDocumentExtraStatusSetting {
  id: string; // System.Guid
  organisationId: string; // System.Guid
  name: string; // string
  statusValue: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  allowExport: boolean; // bool
  condition: string;
  conditionArray: string[];
}

export interface Value {
  value: OrganisationDocumentExtraStatusSetting[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
  '@odata.nextLink'?: string;
}

// init api object
function toAPI(data: Partial<OrganisationDocumentExtraStatusSetting>): any {
  return {
    Id: data?.id || undefined,
    OrganisationId: data?.organisationId || undefined,
    Name: data?.name || '',
    StatusValue: data?.statusValue || '',
    CreatedAt: data?.createdAt || null,
    UpdatedAt: data?.updatedAt || new Date().toISOString(), // fix `"The JSON value could not be converted to System.Nullable`1[System.DateTime]. Path: $.UpdatedAt | LineNumber: 0 | BytePositionInLine: 199."` error + fix for `CreatedAt`
    AllowExport: data?.allowExport || false,
    Condition: data?.condition || '',
  };
}

function parse(data?: Partial<OrganisationDocumentExtraStatusSetting>): OrganisationDocumentExtraStatusSetting {
  return {
    id: data?.id || '',
    organisationId: data?.organisationId || '',
    name: data?.name || '',
    statusValue: data?.statusValue || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    allowExport: data?.allowExport || false,
    condition: data?.condition || '',
    conditionArray: data?.condition?.split(',') || [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
