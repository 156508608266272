import { GetterTree } from 'vuex';
import { AllocationOfficeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationOfficeState, RootState> = {
  ['getAllocationOffices']: (state) => state.allocationOffices,
  ['getAllocationOfficesIsLoading']: (state) => state.allocationOffices?.isLoading,
  ['getAllocationOfficesTotal']: (state) => state.allocationOffices?.total,
  ['getAllocationOfficesSearchParams']: (state) => state.allocationOffices?.searchParams,
  ['getIsImporting']: (state) => state.isImporting,
  ['getAllocationOfficeGroupByAllowedBrands']: (state) =>
    state.editOfficesPermittedDialog_AllocationOfficesGroupByAllowedBrands,
  ['getAllAllocationOfficesIsImporting']: (state) => state.editOfficesPermittedDialog_AllAllocationOfficesIsImporting,
  ['getAllAllocationOffices']: (state) => state.editOfficesPermittedDialog_AllAllocationOffices,
};
