import { ActionTree } from 'vuex';
import { FileState } from './types';
import { RootState } from '../../types';
import { defaultBackendFile } from '@/shared/backend/file';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import file, { Value, File } from '@/shared/model/file';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.files');
export const actions: ActionTree<FileState, RootState> = {
  getFiles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setFilesIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getFilesSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendFile
      .getFiles(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setFiles', response.data);
        commit('setFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getFile({ commit, dispatch }, id: string) {
    return defaultBackendFile
      .getFile(id)
      .then((response: AxiosResponse<File>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getFilesForDocumentId({ commit, dispatch, getters }, documentId: string) {
    commit('setFilesIsLoading', true);
    return defaultBackendFile
      .getFilesForDocumentId(documentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setFiles', response.data);
        commit('setFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getFilesForUnmergeForDocumentId({ commit, dispatch, getters }, documentId: string) {
    return defaultBackendFile
      .getFilesForUnmergeForDocumentId(documentId)
      .then((response: AxiosResponse<Value>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateFile({ commit, dispatch }, file: File) {
    return defaultBackendFile
      .updateFile(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: File;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.file_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteFile({ commit, dispatch }, id: string) {
    return defaultBackendFile
      .deleteFile(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: File;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
