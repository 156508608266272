import { GetterTree } from 'vuex';
import { CommentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CommentState, RootState> = {
  ['getComments']: (state) => state.comments,
  ['getCommentsIsLoading']: (state) => state.comments?.isLoading,
  ['getCommentsTotal']: (state) => state.comments?.total,
  ['getCommentsSearchParams']: (state) => state.comments?.searchParams,
};
