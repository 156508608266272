import { AllocationTaxGroupState } from './types';

export const state = (): AllocationTaxGroupState => initialState();

export const initialState = (): AllocationTaxGroupState => ({
  allocationTaxGroups: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['orderNumber'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
