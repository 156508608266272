import { MutationTree } from 'vuex';
import { TemplateMarkupState } from './types';
import Vue from 'vue';
import templateMarkup, { TemplateMarkup, Value } from '@/shared/model/templateMarkup';

export const mutations: MutationTree<TemplateMarkupState> = {
  setTemplateMarkups(state, payload: Value) {
    state.templateMarkups.items = payload.value.map((x) => templateMarkup.parse(x));
    state.templateMarkups.total = payload['@odata.count'] || 0;
    state.templateMarkups.isLoading = false;
  },
  setTemplateMarkupsTotal(state, payload: number) {
    state.templateMarkups.total = payload;
  },
  setTemplateMarkupsIsLoading(state, payload: boolean) {
    state.templateMarkups.isLoading = payload;
  },
  setIsTemplateMode(state, payload: boolean) {
    state.isTemplateMode = payload;
  },
  addTemplateMarkup(state, payload: TemplateMarkup) {
    state.templateMarkups.items.push(payload);
    state.templateMarkups.total += 1;
  },
  changeTemplateMarkup(state, payload: TemplateMarkup) {
    const index = state.templateMarkups.items.findIndex((item) => item.name === payload.name);

    state.templateMarkups.items[index].fieldName = payload.fieldName;
    state.templateMarkups.items[index].markerText = payload.markerText;
    state.templateMarkups.items[index].isMarker = payload.isMarker;
    //this is for vue reaction
    const zz = templateMarkup.parse({});
    state.templateMarkups.items.push(zz);
    state.templateMarkups.items.splice(-1, 1);
  },
  changeTemplateMarkupSelected(state, payload: TemplateMarkup) {
    const index = state.templateMarkups.items.findIndex((item) => item.name === payload.name);
    state.templateMarkups.items.forEach((element) => {
      element.isSelected = false;
    });

    state.templateMarkups.items[index].isSelected = true;
    //this is for vue reaction
    const zz = templateMarkup.parse({});
    state.templateMarkups.items.push(zz);
    state.templateMarkups.items.splice(-1, 1);
  },
  cleaTemplateMarkup(state) {
    state.templateMarkups.items = [];
    state.templateMarkups.total = 0;
  },
  setTemplateMarkupsScale(state, payload: number) {
    state.scale = payload;
  },
  setLastRecognizedTextInBox(state, payload: string) {
    state.lastRecognizedTextInBox = payload;
  },
};
