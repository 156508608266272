import { MutationTree } from 'vuex';
import { DocumentEditSessionState } from './types';
import Vue from 'vue';
import documentEditSession, { DocumentEditSession, Value } from '@/shared/model/documentEditSession';

export const mutations: MutationTree<DocumentEditSessionState> = {
  setDocumentEditSessions(state, payload: Value) {
    state.documentEditSessions.items = payload.value.map((x) => documentEditSession.parse(x));
    state.documentEditSessions.total = payload['@odata.count'] || 0;
    state.documentEditSessions.isLoading = false;
  },
  setDocumentEditSessionsTotal(state, payload: number) {
    state.documentEditSessions.total = payload;
  },
  setDocumentEditSessionsIsLoading(state, payload: boolean) {
    state.documentEditSessions.isLoading = payload;
  },
};
