export interface DocumentEditSession {
  id: string; // System.Guid
  documentId: string; // System.Guid
  userId: string; // System.Guid
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  isCurrentUser: boolean;
  fullUserName: string;
}

export interface Value {
  value: DocumentEditSession[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentEditSession>): any {
  return {
    Id: data?.id || undefined,
    DocumentId: data?.documentId || undefined,
    UserId: data?.userId || undefined,
    CreatedAt: data?.createdAt || '',
    UpdatedAt: data?.updatedAt || '',
  };
}

function parse(data?: Partial<DocumentEditSession>): DocumentEditSession {
  return {
    id: data?.id || '',
    documentId: data?.documentId || '',
    userId: data?.userId || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    isCurrentUser: data?.isCurrentUser || false,
    fullUserName: data?.fullUserName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
