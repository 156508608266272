import { ActionTree } from 'vuex';
import { TemplateMarkupState } from './types';
import { RootState } from '../../types';
import { defaultBackendTemplateMarkup } from '@/shared/backend/templateMarkup';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import templateMarkup, { Value, TemplateMarkup } from '@/shared/model/templateMarkup';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.templateMarkups');
export const actions: ActionTree<TemplateMarkupState, RootState> = {
  getTemplateMarkups(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; documentId?: string }
  ) {
    commit('setTemplateMarkupsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getTemplateMarkupsSearchParams;
    const organizationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendTemplateMarkup
      .getTemplateMarkups(searchParams, organizationId, payload?.documentId || '')
      .then((response: AxiosResponse<Value>) => {
        commit('setTemplateMarkups', response.data);
        commit('setTemplateMarkupsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTemplateMarkupsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTemplateMarkup({ commit, dispatch }, id: string) {
    return defaultBackendTemplateMarkup
      .getTemplateMarkup(id)
      .then((response: AxiosResponse<TemplateMarkup>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTemplateMarkup({ commit, dispatch, rootGetters }, file: TemplateMarkup) {
    const organizationId = rootGetters[CONST.getOrganisation].id;
    file.organisationId = organizationId;
    return defaultBackendTemplateMarkup
      .updateTemplateMarkup(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TemplateMarkup;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.templateMarkup_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  addTemplateMarkup({ commit, dispatch }, payload: TemplateMarkup) {
    commit('addTemplateMarkup', payload);
  },
  changeTemplateMarkup({ commit, dispatch }, payload: TemplateMarkup) {
    commit('changeTemplateMarkup', payload);
  },
  changeTemplateMarkupSelected({ commit, dispatch }, payload: TemplateMarkup) {
    commit('changeTemplateMarkupSelected', payload);
  },
  clearTemplateMarkup({ commit, dispatch }) {
    commit('cleaTemplateMarkup');
  },
  deleteTemplateMarkup({ commit, dispatch }, id: string) {
    return defaultBackendTemplateMarkup
      .deleteTemplateMarkup(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TemplateMarkup;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTemplateMarkupByDocumentId({ commit, dispatch }, documentId: string) {
    return defaultBackendTemplateMarkup
      .deleteTemplateMarkupByDocumentId(documentId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TemplateMarkup;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  changeIsTemplateMode({ commit, dispatch }, value: boolean) {
    commit('setIsTemplateMode', value);
  },
  setTemplateMarkupsScale({ commit, dispatch }, value: number) {
    commit('setTemplateMarkupsScale', value);
  },

  getLastRecognizedTextInBox(
    { commit, dispatch, getters, rootGetters },
    payload?: { id: string; x: number; y: number; width: number; height: number }
  ) {
    return defaultBackendTemplateMarkup
      .getLastRecognizedTextInBox(payload!.id, payload!.x, payload!.y, payload!.width, payload!.height)
      .then((response: AxiosResponse<any>) => {
        commit('setLastRecognizedTextInBox', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setLastRecognizedTextInBox', '');
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
