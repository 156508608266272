import { ActionTree } from 'vuex';
import { AllocationsExportAllocationState } from './types';
import { RootState } from '../../types';
import { defaultBackendAllocationsExportAllocation } from '@/shared/backend/allocationsExportAllocation';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import allocationsExportAllocation, {
  Value,
  AllocationsExportAllocation,
} from '@/shared/model/allocationsExportAllocation';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('actions.allocationsExportAllocations');
export const actions: ActionTree<AllocationsExportAllocationState, RootState> = {
  getAllocationsExportAllocations({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setAllocationsExportAllocationsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getAllocationsExportAllocationsSearchParams;
    const organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendAllocationsExportAllocation
      .getAllocationsExportAllocations(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllocationsExportAllocations', response.data);
        commit('setAllocationsExportAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsExportAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllocationsExportAllocation({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsExportAllocation
      .getAllocationsExportAllocation(id)
      .then((response: AxiosResponse<AllocationsExportAllocation>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getAllocationsExportAllocationByIdExportId({ commit, dispatch, getters, rootGetters }, id: string) {
    commit('setAllocationsExportAllocationsIsLoading', true);
    return defaultBackendAllocationsExportAllocation
      .getAllocationsExportAllocationByIdExportId(id)
      .then((response: AxiosResponse<AllocationsExportAllocation[]>) => {
        commit('setAllocationsExportAllocationsData', response.data);
        commit('setAllocationsExportAllocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAllocationsExportAllocationsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateAllocationsExportAllocation({ commit, dispatch }, file: AllocationsExportAllocation) {
    return defaultBackendAllocationsExportAllocation
      .updateAllocationsExportAllocation(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExportAllocation;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.allocationsExportAllocation_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAllocationsExportAllocation({ commit, dispatch }, id: string) {
    return defaultBackendAllocationsExportAllocation
      .deleteAllocationsExportAllocation(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AllocationsExportAllocation;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
