import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import actionLogRole, { ActionLogRole, Value } from '../model/actionLogRole';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ActionLogRole');
export interface BackendActionLogRole {
  getActionLogRole(id: string): AxiosPromise<ActionLogRole>;
  getActionLogRoles: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteActionLogRole(id: string): AxiosPromise;
  updateActionLogRole(ActionLogRole: ActionLogRole): AxiosPromise<any>;
}

export const defaultBackendActionLogRole: BackendActionLogRole = {
  getActionLogRole(id: string): AxiosPromise<ActionLogRole> {
    const url = `${URLS.actionLogRoleOdata}/${id}`;
    return instance.get<ActionLogRole>(url);
  },
  getActionLogRoles(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.actionLogRoleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getActionLogRoles${url}`);
    return instance.get<Value>(url);
  },

  deleteActionLogRole(id: string): AxiosPromise {
    logger.debug('deleteActionLogRole');
    return instance.delete(`${URLS.actionLogRole}/${id}`);
  },
  updateActionLogRole(ActionLogRole: ActionLogRole): AxiosPromise<any> {
    logger.debug('updateActionLogRole');
    return instance.put<ActionLogRole>(`${URLS.actionLogRole}/update`, actionLogRole.toAPI(ActionLogRole));
  },
};
