import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gobdData, { GobdData, Value } from '../model/gobdData';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/Constants';

const logger = new Logger('backend.GobdData');
export interface BackendGobdData {
  getGobdData(id: string): AxiosPromise<GobdData>;
  detectGobdData(id: string): AxiosPromise<GobdData>;
  getGobdDatas: (searchParams: SearchParams, organizationId: string, folderId: string) => AxiosPromise<Value>;
  deleteGobdData(id: string): AxiosPromise;
  updateGobdData(GobdData: GobdData): AxiosPromise<any>;
}

export const defaultBackendGobdData: BackendGobdData = {
  getGobdData(id: string): AxiosPromise<GobdData> {
    const url = `${URLS.gobdDataOdata}/${id}`;
    return instance.get<GobdData>(url);
  },

  detectGobdData(id: string): AxiosPromise<GobdData> {
    const url = `${URLS.gobdData}/Detect/${id}`;
    return instance.get<GobdData>(url);
  },

  getGobdDatas(searchParams: SearchParams, organizationId: string, folderId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organizationId, false).eq(CONST.FolderId, folderId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.gobdDataOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getGobdDatas${url}`);
    return instance.get<Value>(url);
  },

  deleteGobdData(id: string): AxiosPromise {
    logger.debug('deleteGobdData');
    return instance.delete(`${URLS.gobdData}/${id}`);
  },
  updateGobdData(GobdData: GobdData): AxiosPromise<any> {
    logger.debug('updateGobdData');
    return instance.put<GobdData>(`${URLS.gobdData}/update`, gobdData.toAPI(GobdData));
  },
};
