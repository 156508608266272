import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
// TODO: (ED-271) use next model https://github.com/dev4yougmbh/easy-docs-services/blob/dev/Services/Backend/Backend.DataAccessPostgreSQL/DataAccess/DataObjects/ProcessList.cs

const name = 'process-edit-dialog';
const logger = new Logger(name);

@Component({ name: name })
export default class ProcessEditDialog extends Vue {
  @Ref('process-edit-form')
  private refProcessEditForm!: any;

  //TODO fully implement canProcess: https://dev4you.atlassian.net/browse/ED-717?focusedCommentId=11045
  @Prop({ default: false })
  public canProcess!: boolean;
  //TODO fully implement canUpdate: https://dev4you.atlassian.net/browse/ED-720?focusedCommentId=11044
  @Prop({ default: false })
  public canUpdate!: boolean;
  @Prop({ default: false })
  public canDelete!: boolean;
  @Prop({ default: false })
  public isLoading!: boolean;
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => {} })
  private value!: any; // TODO: (ED-284) Replace with ProcessList model from backend
  @Watch('value')
  onUpdateValue(newV: any, oldV: any) {
    this.process = Object.assign(this.process, newV);
  }
  private process: any = {}; // TODO: use `process.parse()` + also for `Prop `value``

  private selectedOperation = '';
  private operations = ['Alle Betriebe'];

  private addresses: any[] = [];
  private address: any = {};
  private employees: any[] = ['mitarbeiter'];

  private isFormValid = true;
  get processRules() {
    return [
      (v: any) => (v && !!v.toString().trim()) || this.$i18n.t('messages.process_required'), // i18n.tc(`name_required_message`) is not working here, just shows `name_required_message` key
    ];
  }

  get operationRules() {
    return [(v: string) => (!!v && !!v.trim()) || this.$i18n.t('messages.operation_required')];
  }

  get employeeRules() {
    return [(v: string) => (!!v && !!v.trim()) || this.$i18n.t('messages.employee_required')];
  }

  // name of method is taken from `neu.easy-docs.de`
  addProcessSeller() {
    logger.debug('addProcessSeller');
  }
  // name of method is taken from `neu.easy-docs.de`
  addAllProcessesTodos() {
    logger.debug('addAllProcessesTodos');
  }
  // name of method is taken from `neu.easy-docs.de`
  removeAllProcessesTodos() {
    logger.debug('removeAllProcessesTodos');
  }
  // name of method is taken from `neu.easy-docs.de`
  addProcessTodo() {
    logger.debug('addProcessTodo');
  }

  resetValidation() {
    this.refProcessEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  onClickDelete() {
    this.resetValidation();
    this.$emit('click:delete');
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refProcessEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.process);
    }
  }
}
