import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { Document } from '@/shared/model/document';
import { Folder } from '@/shared/model/folder';
import { OdataItems } from '@/shared/model/OdataItems';
import TreeUtils from '@/shared/utils/treeUtils';

const logger = new Logger('menu-document-assign-folder');
const folderModule = namespace('folder');
const documentModule = namespace('document');
const organizationModule = namespace('organization');

@Component({ name: 'menu-document-assign-folder', components: {} })
export default class MenuDocumentAssignFolderView extends Vue {
  @folderModule.Action('getMenuFolders')
  private getFolders!: () => {};
  @folderModule.Getter('getMenuFoldersTreeViewWrite')
  private folders!: Folder[];

  @documentModule.Action('getDocument')
  private actionGetDocument!: any;
  @documentModule.Action('getDocuments')
  private getDocuments!: any;
  @documentModule.Mutation('resetLastLoadDate')
  private resetLastLoadDate!: any;
  @documentModule.Getter('getDocument')
  private getterGetDocument!: Document;

  @documentModule.Action('updateDocumentFolder')
  private actionUpdateDocumentFolder!: any;
  @documentModule.Action('updateDocumentsFolder')
  private actionUpdateDocumentsFolder!: any;
  @documentModule.Getter('getDocuments')
  private getterGetDocuments!: OdataItems<Document>;

  @documentModule.Getter('getSelectedDocumentIds')
  private getterSelectedDocumentIds!: any;

  @organizationModule.Getter('isFolderOpenTypeOpenTheWholeStructure')
  private isFolderOpenTypeOpenTheWholeStructure!: boolean;

  private openTreeFolders: string[] = [];

  get documentId() {
    return this.$route.params['documentId'];
  }

  get isAssignSingleDocument() {
    return this.getterSelectedDocumentIds.length === 0;
  }

  async mounted() {
    const promiseAll = [this.getFolders(), this.loadDocument()];
    await Promise.all(promiseAll);
  }
  search: any = '';
  @Watch('search')
  onSearchChanged(nVal: string, oVal: string): void {
    if (nVal) {
      (this.$refs.tree as any).updateAll(true);
    } else {
      (this.$refs.tree as any).updateAll(false);
    }
  }
  get filter() {
    return (item: any, search: string, textKey: string) => {
      const index = (item[textKey] as string).toLowerCase().indexOf(`${search}`.toLowerCase());
      // console.log(`item[${textKey}]=${item[textKey] as string} and indexOf(${search})=${index}`);
      return index > -1;
    };
  }
  private loadDocument() {
    // if for some reason `document` getter is empty send request
    if (!this.getterGetDocument?.id && this.isAssignSingleDocument) {
      this.actionGetDocument(this.documentId).catch((err: any) => {
        logger.error(err);
      });
    }
  }
  openLeaf(folder: any, open: boolean): void {
    if (!open && this.isFolderOpenTypeOpenTheWholeStructure)
      TreeUtils.openFoldersAndChildrens(folder, folder.children, this.openTreeFolders);
  }
  private returnBack() {
    this.$emit('click:return');
  }

  private assignToFolder(folder: Folder) {
    logger.debug('assignToFolder');

    if (this.isAssignSingleDocument) {
      this.updateOneDocument(folder, this.getterGetDocument);
    } else {
      this.updateMultipleDocuments(folder);
    }
    this.returnBack();
  }

  private async updateOneDocument(folder: Folder, document: Document) {
    // if try to assign for the document the CURRENT folder, then ignore such a request

    if (folder.id === document.folderId) return;
    await this.actionUpdateDocumentsFolder({ documentsIds: [document.id], toFolder: folder });
  }

  private async updateMultipleDocuments(folder: Folder) {
    await this.actionUpdateDocumentsFolder({ documentsIds: this.getterSelectedDocumentIds, toFolder: folder });
    this.resetLastLoadDate();
    this.getDocuments();
  }

  private selectDocumentById(id: string) {
    const result = this.getterGetDocuments.items.find((x: Document) => x.id === id);
    return result;
  }

  private toggleOpenForNode(open: boolean, treeNodeId: string) {
    if (open) {
      this.openTreeFolders = this.openTreeFolders.filter((x) => x !== treeNodeId);
    } else this.openTreeFolders.push(treeNodeId);
  }
}
