import { OrganisationDocumentProperty } from './organisationDocumentProperty';

export interface DocumentProperty {
  id: string; // ulong
  fieldId: string; // string
  organisationId: string; // string
  documentId: string; // string
  value: string; // string
  name: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  organisationDocumentProperty?: OrganisationDocumentProperty;
}

export interface Value {
  value: DocumentProperty[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DocumentProperty>): any {
  const result: any = {
    Id: data?.id || undefined,
    FieldId: data?.fieldId || '',
    DocumentId: data?.documentId || '',
    Value: data?.value || '',
  };

  if (data?.createdAt) result.CreatedAt = data?.createdAt || '';
  if (data?.updatedAt) result.UpdatedAt = data?.updatedAt || '';
  if (data?.organisationId) result.OrganisationId = data?.organisationId || '';

  return result;
}

function parse(data?: Partial<DocumentProperty>): DocumentProperty {
  return {
    id: data?.id || '',
    fieldId: data?.fieldId || '',
    organisationId: data?.organisationId || '',
    documentId: data?.documentId || '',
    value: data?.value || '',
    name: data?.value || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
