import { GetterTree } from 'vuex';
import { DatevSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DatevSettingState, RootState> = {
  ['getDatevSettings']: (state) => state.datevSettings,
  ['getDatevSetting']: (state) => state.current,
  ['getDatevSettingsIsLoading']: (state) => state.datevSettings?.isLoading,
  ['getDatevSettingsTotal']: (state) => state.datevSettings?.total,
  ['getDatevSettingsSearchParams']: (state) => state.datevSettings?.searchParams,
  ['getIsUpdating']: (state) => state.isUpdating,
};
