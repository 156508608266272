import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from 'oidc-client';
import { OdataItems } from '@/shared/model/OdataItems';
import folder, { Folder, Value } from '@/shared/model/folder';
import { Logger } from 'fsts';
import { Organisation } from '@/shared/model/organisation';
import { Document } from '@/shared/model/document';
import LeftMenuHelpDialog from './left-menu-help-dialog/left-menu-help-dialog.vue';
import SimulationView from './simulation/simulation.vue';
import ChangeLocaleRadioGroup from '@/views/app/dev-components-not-for-users/change-locale-radio-group/change-locale-radio-group.vue';
import GeneralUtils from '@/shared/utils/generalUtils';
import TreeUtils from '@/shared/utils/treeUtils';

const logger = new Logger('left_menu');
const authModule = namespace('auth');
const documentModule = namespace('document');
const folderModule = namespace('folder');
const organizationModule = namespace('organization');

@Component({ name: 'left-menu', components: { LeftMenuHelpDialog, ChangeLocaleRadioGroup, SimulationView } })
export default class LeftMenuView extends Vue {
  @Ref('leftMenuTree')
  private refLeftMenuTree!: any;

  @documentModule.Mutation('resetDocumentsSearchPayload')
  private resetDocumentsSearchPayload!: () => {};
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: any;
  @authModule.Getter('ViewAddressesAllowed')
  private ViewAddressesAllowed!: boolean;
  @authModule.Getter('ViewAllProcessListsAllowed')
  private ViewAllProcessListsAllowed!: boolean;
  @authModule.Getter('ViewProcessListsAllowed')
  private ViewProcessListsAllowed!: boolean;
  @authModule.Getter('EditProcessListsAllowed')
  private EditProcessListsAllowed!: boolean;
  @authModule.Getter('DeleteDocumentsFromTrashAllowed')
  private DeleteDocumentsFromTrashAllowed!: boolean;
  @authModule.Getter('ExportDocumentAllocationsAllowed')
  private exportDocumentAllocationsAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  @authModule.Getter('isSiteOwner')
  private isSiteOwner!: boolean;
  @authModule.Getter('isSeller')
  private isSeller!: boolean;
  @organizationModule.Getter('getOrganization')
  private organization!: Organisation;
  @organizationModule.Mutation('setOrganization')
  private mutationSetOrganization!: any;
  @organizationModule.Getter('getOrganizations')
  private organizations!: OdataItems<Organisation>;
  @organizationModule.Getter('isFolderOpenTypeOpenTheWholeStructure')
  private isFolderOpenTypeOpenTheWholeStructure!: boolean;

  @organizationModule.Action('getOrganizationsAll')
  private getOrganizations!: any;

  @Prop({ default: true, type: Boolean })
  private drawerLeft!: boolean;

  @documentModule.Getter('getDocuments')
  private documents!: OdataItems<Document>;
  @documentModule.Getter('getDocumentsAllCount')
  private documentsAllCount!: number;
  @documentModule.Getter('getDocumentsDeleted')
  private documentsDeleted!: OdataItems<Document>;

  @documentModule.Action('getDocumentsDeleted')
  private actionGetDocumentsDeleted!: any;
  @authModule.Getter('isLoggedIn')
  public isLoggedIn!: boolean;

  @authModule.Action('logout')
  private actionLogout!: any;

  @authModule.Getter('getAccount')
  private account!: User;

  @folderModule.Action('getMenuFolders')
  private getMenuFolders!: any;
  openLeaf(folder: any, open: boolean): void {
    if (!open && this.isFolderOpenTypeOpenTheWholeStructure)
      TreeUtils.openFoldersAndChildrens(folder, folder.children, this.open);
  }
  get mobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }
  get isDevEnvironment() {
    return GeneralUtils.isDevEnvironment();
  }
  @authModule.Getter('getAccountFullName')
  accountFullName!: any;
  maxHeight = 32;
  maxWidth = 32;
  leftMenuItems: {
    src: string;
    translateId: string;
    to?: string;
    secondLayer?: {
      src?: string;
      translateId: string;
      to?: string;
      show?: boolean;
      count?: number;
    }[];
    foldersLayer?: boolean;
    count?: number;
    show: boolean;
  }[] = [];

  get currentOrganisationProcessListsActive() {
    const current = this.organizations.items.filter((prop: Organisation) => prop.id == this.organization.id);
    if (current[0]) return current[0].processListsActive;
    else return false;
  }

  get initLeftMenuItems() {
    return [
      {
        icon: 'mdi-content-copy',
        iconColor: 'white',
        translateId: 'all_documents',
        count: this.documentsAllCount,
        to: `/dashboard/organization/${this.organization.id}`,
        show: true,
      },
      { src: 'symbol_eingang.png', translateId: 'xx', foldersLayer: true, show: true },
      {
        icon: 'mdi-card-account-details',
        iconColor: 'white',
        translateId: 'addresses',
        to: '/addresses',
        show: this.ViewAddressesAllowed || this.isOwner,
      },
      {
        src: 'symbol_archiv.png',
        translateId: 'export',
        to: '/export',
        //show: GeneralUtils.showFutureFeature && this.exportDocumentAllocationsAllowed,
        show: this.exportDocumentAllocationsAllowed ?? false,
      },
      {
        icon: 'mdi-format-list-bulleted-square',
        iconColor: 'white',
        translateId: 'processes',
        to: '/processes',
        show:
          (this.isSeller &&
            this.currentOrganisationProcessListsActive &&
            (this.ViewAllProcessListsAllowed || this.ViewProcessListsAllowed || this.EditProcessListsAllowed)) ||
          (this.isOwner && this.currentOrganisationProcessListsActive),
      },
      {
        icon: 'mdi-delete',
        iconColor: 'white',
        translateId: 'trash',
        count: this.documentsDeleted.total,
        to: '/trash',
        show: this.DeleteDocumentsFromTrashAllowed,
      },
    ];
  }
  async onLogout() {
    await this.$backendHub.OrgLeave(this.account.profile.current_organization_id);
    await this.actionLogout();
    this.$router.push('/home').catch(() => {});
  }
  async onSettings() {
    this.$router.push('/settings/my-data').catch(() => {});
    this.$emit('change:header-text', { translateId: 'settings', to: '/settings' });
  }
  async onSaSettings() {
    this.$router.push('/superAdmin/admin').catch(() => {});
  }
  private navigateTo(menuItem: any) {
    this.activeLeftMenuClass = 'custom-left-menu-active-class';
    // this.active = [];// Why use `transparent` color?  Because if reset `this.active ` array `Treeview` highlight will disappear, but request from `v-treeview` will be sent to the backend which will redirect to default `All documents` and `this.$router.push` will not be reached (i.e. ignored)
    this.treeviewActiveColor = 'transparent';

    const routerPath = menuItem.to === '/export' ? `${menuItem.to}/allocation` : menuItem.to; // (ED-564): avoid left-menu `export` to loose focus when switch among Tabs
    this.resetDocumentsSearchPayload(); // (ED-1232) reset `documentsSearchPayload` Address related data when navigate to `Addresses` via `left-menu`
    if (menuItem.to == '/trash' || (menuItem.to as string).startsWith('/dashboard/organization')) {
      this.documentsSearchPayload.showDeleted = menuItem.to == '/trash';
      // this.actionGetDocuments();
    }
    this.$router
      .push({
        path: routerPath,
      })
      .catch(() => {});
    this.$emit('change:header-text', { translateId: menuItem?.translateId, to: menuItem.to });
  }

  private toggleOpenForNode(open: boolean, treeNodeId: string) {
    this.getMenuFolders();
    open = !open; // recreate `update:open` behavior for Treeview (https://github.com/vuetifyjs/vuetify/blob/8bb752b210d25fbebcea12cd073d2ce4986f5e12/packages/vuetify/src/components/VTreeview/VTreeviewNode.ts#L180)

    this.refLeftMenuTree[0].updateOpen(treeNodeId, open); // `REF` returns arrya since used within the `v-for` directive (https://forum.vuejs.org/t/this-refs-theid-returns-an-array/31995/9)
  }

  showCountInBadge(count: number) {
    // ED-555 (if return `0` as a NUMBER the JavaScript(JS) considers it as a FALSE value (nothing) and shows nothing for `All Documents` and `Trash` folders, so as workaround we return `0` as a STRING value to show ZERO value)
    return count === 0 ? '0' : count;
  }

  active: any = [];
  avatar = null;

  private open: any = [];
  private treeviewActiveColor = 'warning'; // (ED-564) we need this hack with `activeColor` + `activeClass` to avoid simultaneous highlight of node in `v-treeview` and element from `v-list-item` (see `initLeftMenuItems` array = Trash, All Documents, Export, etc)
  private activeLeftMenuClass = 'custom-left-menu-active-class';

  private selected: any;
  breweries: any[] = [];
  @folderModule.Getter('getMenuFoldersTreeViewWithoutHidden')
  private folders!: {
    folder: Folder;
    id: string;
    name: string;
    children: any[];
  }[];

  //#region (ED-372) Logic to keep `left-menu` folder highlighted on repeated clicks (before if click on active folder the `active` state is OFF and `all documents` are loaded) and change between only this folder (click on badge) & this+ child folders
  /**
   * Handle click on the `left-menu` folder badge (show number of documents in folder) which should show documents ONLY in current folder without documents in child folders
   * @param {boolean} active - the state of the folder in `left-menu` (FALSE on 1st click since folder is not `active` before click, when folder highlighted then `active` is TRUE)
   * @param {string} id of the clicked `left-menu` folder
   * @param {any} $event - `click` event
   */
  handleFolderBadgeClick(item: any, $event: any) {
    if ($event.target == $event.currentTarget) {
      this.onUpdateActiveFolder(item, true, $event);
      $event.stopPropagation();
    }
  }

  /**
   * Handle click on the `left-menu` folder name (label) which should show documents in current folder AND documents in child folders
   * @param {boolean} active - the state of the folder in `left-menu` (FALSE on 1st click  since folder is not `active` before click, when folder highlighted then `active` is TRUE)
   * @param {string} id of the clicked `left-menu` folder
   * @param {any} $event - `click` event
   */
  private handleFolderClick(item: any, $event: any) {
    this.onUpdateActiveFolder(item, false, $event);
    $event.stopPropagation();
  }
  private handleImageClick(item: any, $event: any) {
    this.onUpdateActiveFolder(item, false, $event);
    $event.stopPropagation();
  }

  @folderModule.Getter('getMenuFoldersTreeViewFlat')
  private menuFoldersTreeViewFlat!: Folder[];

  private getFoldersIdsWithFullPasthStartfrom(fullPath: string) {
    return this.menuFoldersTreeViewFlat
      .filter((el) => {
        if (el.fullPath.startsWith(fullPath + ' / ') || el.fullPath == fullPath) {
          return el.id; // create object with `id`(id of child): `parentFolderId` structure
        }
      }, {})
      .map((x) => x.id);
  }
  //#endregion

  @documentModule.Action('getDocumentsWithChildren')
  private actionGetDocumentsWithChildren!: () => {};
  @documentModule.Action('getDocuments')
  private actionGetDocuments!: () => {};
  onUpdateActiveFolder(item: any, isOnlyCurrentFolderDocs: boolean, $event: any) {
    this.getMenuFolders();
    const fullPath: string = item?.fullPath;
    const activeFolderId: string = item.id;
    const activeFolderdsIds = isOnlyCurrentFolderDocs
      ? activeFolderId
      : this.getFoldersIdsWithFullPasthStartfrom(fullPath);
    this.activeLeftMenuClass = '';
    this.treeviewActiveColor = 'warning';
    this.resetDocumentsSearchPayload();
    this.documentsSearchPayload.folderId =
      isOnlyCurrentFolderDocs || activeFolderdsIds.length == 0 ? activeFolderId : activeFolderdsIds;
    this.documentsSearchPayload.isOnlyCurrentFolderDocs = isOnlyCurrentFolderDocs;
    const returnToUrl = `/dashboard/organization/${this.organization.id}/${activeFolderId}`;
    if (this.documentsSearchPayload.isOnlyCurrentFolderDocs == true) {
      this.actionGetDocuments();
    }
    this.$emit('change:header-text', { translateId: fullPath, to: `${returnToUrl}` });

    this.$router
      .push({
        path: returnToUrl,
        query: { onlyCurrentFolderDocs: isOnlyCurrentFolderDocs + '' },
      })
      .catch(() => {});
    this.active = [item.id];
  }

  @authModule.Action('login')
  private actionLogin!: any;
  async onSelectOrganization(item: Organisation) {
    if (item) {
      await this.$backendHub.OrgLeave(this.account.profile.current_organization_id);
      await this.actionLogin({ organizationId: item.id });
    } else {
      // restore selection current company after:
      // 1 - typing in combo 2 - deleting entered text
      this.$nextTick(() => {
        this.organization.id = this.account.profile.current_organization_id;
      });
    }
  }

  async mounted() {
    logger.log('mounted');
    if (this.isLoggedIn) {
      this.initResizeDrawer();
    }
  }

  //#region showHelpDialog logic
  private showHelpDialog() {
    this.dialogHelp.show = true;
  }

  dialogHelp = {
    show: false,
    OnClose: () => {
      this.dialogHelp.show = false;
    },
  };

  //#endregion

  //#region : Drawer(left-menu) resize logic
  @Ref('leftDrawer')
  private refLeftDrawer!: any;

  private defaultDrawerWidth = 315;
  private maxDrawerWidth = this.windowWidth * 0.4; // allow `max-width` for Drawer up to 40% of the window

  private drawerData = {
    shown: false,
    width: this.defaultDrawerWidth,
    borderSize: 3,
  };

  @Watch('$vuetify.breakpoint.width', { deep: true })
  onWindowWidthChange(newVal: any, oldVal: any) {
    this.setMaxDrawerWidth(newVal);
  }

  private setMaxDrawerWidth(newVal: number) {
    this.maxDrawerWidth = newVal * 0.4;

    // no matter how to assign value (even via `this.drawerData.width` avoiding `el.style.width` ) the `this.drawerData.width` always contains `PX` part
    const currentDrawerWidth = +this.drawerData.width.toString().replace('px', '');
    // dynamically reduce `this.drawerData.width` if resize window from big to small (i.e. if use `Windows + arrow` buttons to make window take HALF the screen)
    if (currentDrawerWidth > this.maxDrawerWidth) {
      this.drawerData.width = this.maxDrawerWidth;
    }
  }

  get windowWidth() {
    return this.$vuetify.breakpoint.width;
  }

  setBorderWidth() {
    const i = this.refLeftDrawer.$el.querySelector('.v-navigation-drawer__border');
    i.style.width = this.drawerData.borderSize + 'px';
    i.style.cursor = 'ew-resize';
    // i.style.backgroundColor = 'transparent';
  }

  setEvents() {
    const minSize = this.drawerData.width - this.drawerData.borderSize; // min size should be a little bit smaller than `this.navigation.width` to allow resize
    const el = this.refLeftDrawer.$el;
    const drawerBorder = el.querySelector('.v-navigation-drawer__border');
    const vm = this;
    const direction = el.classList.contains('v-navigation-drawer--right') ? 'right' : 'left';

    function resize(e: any) {
      document.body.style.cursor = 'ew-resize';
      const f = direction === 'right' ? document.body.scrollWidth - e.clientX : e.clientX;
      if (e.clientX > minSize && e.clientX < vm.maxDrawerWidth) {
        el.style.width = f + 'px';
      }
    }

    drawerBorder.addEventListener(
      'mousedown',
      (e: any) => {
        if (e.offsetX < minSize) {
          el.style.transition = 'initial';
          document.addEventListener('mousemove', resize, false);
        }
      },
      false
    );

    document.addEventListener(
      'mouseup',
      () => {
        el.style.transition = '';
        this.drawerData.width = el.style.width;
        document.body.style.cursor = '';
        document.removeEventListener('mousemove', resize, false);
      },
      false
    );
  }

  private initResizeDrawer() {
    // TODO: (ED-165) make detecting `mobile` mode (window width) more dynamic to enable/disable `resize events` initialization
    if (this.mobile) return; // don't init resize events on mobile devices

    this.setBorderWidth();
    this.setEvents();
  }

  //#endregion
  @Watch('organizations.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    this.getOrganizations();
  }
  organizationId: string = '';
  get filtredOrganisations() {
    return this.organizations?.items.map((x) => ({
      id: x.id,
      nameEmail: `${x.name} (${x.owner?.email})`,
      name: `${x.name}`,
      email: `${x.owner?.email}`,
    }));
  }
}
