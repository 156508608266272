import { MutationTree } from 'vuex';
import axios from 'axios';
import { AuthState } from './types';
import { initialState } from './state';
import { AUTHORITIES } from './index';

import credentials, { Credetials } from '../../../model/credentials';
import LSService from '../../../utils/LocalStorageService';

const lsService = LSService.getService();

export const mutations: MutationTree<AuthState> = {
  setAccount(state: AuthState, payload: any) {
    if (payload?.profile)
      payload.profile.sub =
        payload.profile.impersonate == 'true' ? payload.profile.impersonate_id : payload.profile.sub;
    state.account = payload;
    state.isLoggedIn = state.account?.expires_at > 0;
  },
  setToken(state: AuthState, payload: any) {
    state.token = payload.access_token;
    lsService.setToken(payload);
    state.isLoggedIn = state.account?.expires_at > 0;
  },
  clearTokens(state: AuthState, payload: any) {
    lsService.clearToken();
    state.token = '';
  },
  reset(state) {
    const s = initialState() as any;
    Object.keys(s).forEach((key: string) => {
      (state as any)[key] = s[key];
    });
  },
  setIsLeftDrawerShown(state, payload: boolean) {
    state.isLeftDrawerShown = payload;
  },
  setHeaderNavData(state, payload: { translateId: string; to: string }) {
    state.headerNavData = payload;
  },
};
