import { DocumentPropertyState } from './types';

export const state = (): DocumentPropertyState => initialState();

export const initialState = (): DocumentPropertyState => ({
  documentPropertys: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['ActionEmailMessage'],
    },
  },
});
