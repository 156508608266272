import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AllocationSetting } from '@/shared/model/allocationSetting';
import { namespace } from 'vuex-class';
import allocationOffice, { AllocationOffice } from '@/shared/model/allocationOffice';

const allocationSettingModule = namespace('allocationSetting');
const allocationOfficeModule = namespace('allocationOffice');

@Component
export default class AllocationExportDialog extends Vue {
  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @allocationSettingModule.Action('getAllocationSettingCurrent')
  private getAllocationSetting!: any;

  @allocationOfficeModule.Getter('getAllocationOffices')
  private getAllocationOffices?: any;
  @allocationOfficeModule.Action('getAllocationOffices')
  private actionGetAllocationOffices?: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  private branch: any = '';

  get offices() {
    const results: AllocationOffice[] = [];
    this.getAllocationOffices.items.forEach((element: AllocationOffice) => {
      results.push(element);
    });

    results.push(
      allocationOffice.parse({
        id: '',
        organisationId: '',
        officeNumber: '',
        name: 'Alle',
        createdAt: '',
        updatedAt: '',
      })
    );

    return results;
  }

  get isExportEachOffice() {
    return this.allocationSetting.exportEachOffice;
  }

  async mounted() {
    await this.getAllocationSetting().then(() => {
      this.actionGetAllocationOffices();
    });
  }

  private agree() {
    this.$emit('click:export', this.branch);
  }
  private cancel() {
    this.$emit('click:close');
  }
}
