import { MutationTree } from 'vuex';
import { AllocationsExportState } from './types';
import Vue from 'vue';
import allocationsExport, { AllocationsExport, Value } from '@/shared/model/allocationsExport';

export const mutations: MutationTree<AllocationsExportState> = {
  setAllocationsExports(state, payload: Value) {
    state.allocationsExports.items = payload.value.map((x) => allocationsExport.parse(x));
    state.allocationsExports.total = payload['@odata.count'] || 0;
    state.allocationsExports.isLoading = false;
  },
  setAllocationsExportsTotal(state, payload: number) {
    state.allocationsExports.total = payload;
  },
  setAllocationsExportsIsLoading(state, payload: boolean) {
    state.allocationsExports.isLoading = payload;
  },
};
