import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import regexSplitterPart, { RegexSplitterPart, Value } from '../model/regexSplitterPart';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.RegexSplitterPart');
export interface BackendRegexSplitterPart {
  getRegexSplitterPart(id: string): AxiosPromise<RegexSplitterPart>;
  getRegexSplitterParts: (searchParams: SearchParams, organisationId: string) => AxiosPromise<Value>;
  deleteRegexSplitterPart(id: string): AxiosPromise;
  updateRegexSplitterPart(RegexSplitterPart: RegexSplitterPart): AxiosPromise<any>;
}

export const defaultBackendRegexSplitterPart: BackendRegexSplitterPart = {
  getRegexSplitterPart(id: string): AxiosPromise<RegexSplitterPart> {
    const url = `${URLS.regexSplitterPartOdata}/${id}`;
    return instance.get<RegexSplitterPart>(url);
  },
  getRegexSplitterParts(searchParams: SearchParams, organisationId: string): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.OrganisationId, organisationId, false);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.regexSplitterPartOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getRegexSplitterParts${url}`);
    return instance.get<Value>(url);
  },

  deleteRegexSplitterPart(id: string): AxiosPromise {
    logger.debug('deleteRegexSplitterPart');
    return instance.delete(`${URLS.regexSplitterPart}/${id}`);
  },
  updateRegexSplitterPart(RegexSplitterPart: RegexSplitterPart): AxiosPromise<any> {
    logger.debug('updateRegexSplitterPart');
    return instance.put<RegexSplitterPart>(
      `${URLS.regexSplitterPart}/update`,
      regexSplitterPart.toAPI(RegexSplitterPart)
    );
  },
};
