import { GetterTree } from 'vuex';
import { FolderState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<FolderState, RootState> = {
  ['getFolder']: (state) => state.folder,
  ['getFolders']: (state) => state.folders,
  ['getFoldersIsLoading']: (state) => state.folders?.isLoading,
  ['getFoldersTotal']: (state) => state.folders?.total,
  ['getFoldersSearchParams']: (state) => state.folders?.searchParams,

  ['getFoldersAll']: (state) => state.foldersAll,
  ['getFoldersAllIsLoading']: (state) => state.foldersAll?.isLoading,
  ['getFoldersAllTotal']: (state) => state.foldersAll?.total,
  ['getFoldersAllSearchParams']: (state) => state.foldersAll?.searchParams,
  ['getFoldersAllTreeViewFlat']: (state) => state.foldersAll.treeViewFlat,
  ['getFoldersAllTreeView']: (state) => state.foldersAll.treeView,
  ['getFoldersAllTreeViewWithoutHidden']: (state) => state.foldersAll.treeViewWithoutHidden,
  ['getFoldersAllTreeViewWrite']: (state) => state.foldersAll.treeViewWrite,

  ['getUploadDialogFolders']: (state) => state.uploadDialogFolders,

  ['getMenuFolders']: (state) => state.menuFolders,
  ['menuFoldersIsLoading']: (state) => state.menuFolders.isLoading,
  ['getMenuFoldersTreeViewFlat']: (state) => state.menuFolders.treeViewFlat,
  ['getMenuFoldersTreeView']: (state) => state.menuFolders.treeView,
  ['getMenuFoldersTreeViewWithoutHidden']: (state) => state.menuFolders.treeViewWithoutHidden,
  ['getMenuFoldersTreeViewWithoutHiddenFlat']: (state) => state.menuFolders.treeViewWithoutHiddenFlat,
  ['getMenuFoldersTreeViewUploadFlat']: (state) => state.menuFolders.treeViewUploadFlat,
  ['getMenuFoldersTreeViewWrite']: (state) => state.menuFolders.treeViewWrite,
  ['getMenuFoldersTreeViewWriteFlat']: (state) => state.menuFolders.treeViewWriteFlat,

  ['getInProgressUpdateFoldersRoleRights']: (state) => state.inProgressUpdateFoldersRoleRights > 0,
  ['getInProgressUpdateFolder']: (state) => state.inProgressUpdateFolder,
  ['getColor']: (state) => (id: string) => state.menuFolders?.items.find((item) => item.id === id)?.color,
};
