import { MutationTree } from 'vuex';
import { OrganisationDocumentExtraStatusSettingState } from './types';
import Vue from 'vue';
import organisationDocumentExtraStatusSetting, {
  OrganisationDocumentExtraStatusSetting,
  Value,
} from '@/shared/model/organisationDocumentExtraStatusSetting';

export const mutations: MutationTree<OrganisationDocumentExtraStatusSettingState> = {
  setOrganisationDocumentExtraStatusSettings(state, payload: Value) {
    const x = payload.value.map((x) => organisationDocumentExtraStatusSetting.parse(x));
    const orderEtalon = [
      'payed',
      'accepted',
      'final_check',
      'extra1',
      'extra2',
      'extra3',
      'extra4',
      'extra5',
      'extra6',
      'extra7',
      'extra8',
      'extra9',
      'extra10',
      'extra11',
      'extra12',
    ];
    state.organisationDocumentExtraStatusSettings.items = [];
    orderEtalon.forEach((element, i) => {
      const elements = x.filter((y) => y.statusValue == element);
      if (elements.length > 0) state.organisationDocumentExtraStatusSettings.items.push(elements[0]);
      else
        state.organisationDocumentExtraStatusSettings.items.push(
          organisationDocumentExtraStatusSetting.parse({
            statusValue: element,
            name: element.charAt(0).toUpperCase() + element.slice(1),
          })
        );
    });
    state.organisationDocumentExtraStatusSettings.total =
      (payload['@odata.count'] || 0) < orderEtalon.length ? orderEtalon.length : payload['@odata.count'] || 0;
    state.organisationDocumentExtraStatusSettings.isLoading = false;
  },
  setOrganisationDocumentExtraStatusSettingsTotal(state, payload: number) {
    state.organisationDocumentExtraStatusSettings.total = payload;
  },
  setOrganisationDocumentExtraStatusSettingsIsLoading(state, payload: boolean) {
    state.organisationDocumentExtraStatusSettings.isLoading = payload;
  },
};
