import { AuthState } from './types';

export const state = (): AuthState => initialState();

export const initialState = (): AuthState => ({
  isLeftDrawerShown: true,
  account: undefined,
  token: undefined,
  isLoggedIn: false,
  headerNavData: { translateId: 'all_documents', to: '' },
});
