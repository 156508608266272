import { Component, Vue, Prop } from 'vue-property-decorator';
import { Logger } from 'fsts';
import OcrTextDialog from './ocr-text-dialog/ocr-text-dialog.vue';
import doc, { Document } from '@/shared/model/document';
import { OCR_RADIO_OPTIONS as OcrOptions } from './ocr-text-dialog/ocrDialogRadioOptions';
import { namespace } from 'vuex-class';

const logger = new Logger('menu-maintenance-btn');
const documentModule = namespace('document');

@Component({ name: 'menu-maintenance-btn', components: { OcrTextDialog } })
export default class MenuMaintenanceBtnView extends Vue {
  @Prop({ default: () => doc.parse({}) })
  private document!: Document;

  @documentModule.Action('rescanDocument')
  private actionRescanDocuments!: any;

  private renewPreviewImage() {
    logger.debug('renewPreviewImage');
    this.actionRescanDocuments({ id: this.document.id, textOrPreview: 2 });
  }

  private changeOcrText() {
    logger.debug('changeOcrText');
    this.openOcrDialog();
  }

  //#region OCR dialog logic
  dialogOcrText = {
    lastDialogAction: '',
    show: false,
    model: doc.parse({}),
    OnClose: (lastDialogAction: OcrOptions) => {
      this.dialogOcrText.lastDialogAction = lastDialogAction;
      this.dialogOcrText.show = false;
    },
  };

  private async openOcrDialog() {
    this.dialogOcrText.model = this.document;
    this.dialogOcrText.show = true;
  }

  //#endregion
}
