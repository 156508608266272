import { MutationTree } from 'vuex';
import { RoleRightState } from './types';
import Vue from 'vue';
import roleRight, { RoleRight, Value } from '@/shared/model/roleRight';

export const mutations: MutationTree<RoleRightState> = {
  setRoleRights(state, payload: Value) {
    state.roleRights.items = payload.value.map((x) => roleRight.parse(x));
    state.roleRights.total = payload['@odata.count'] || 0;
    state.roleRights.isLoading = false;
  },
  setRoleRightsTotal(state, payload: number) {
    state.roleRights.total = payload;
  },
  setRoleRightsIsLoading(state, payload: boolean) {
    state.roleRights.isLoading = payload;
  },
  setInProgressUpdateRoleRights(state, payload: boolean) {
    state.inProgressUpdateRoleRights = payload;
  },
  setInProgressUpdateRole(state, payload: boolean) {
    state.inProgressUpdateRole = payload;
  },
};
