export interface OrganisationUser {
  id: string; // ulong
  userId: string; // string
  organisationId: string; // string
  email: string; // string
  firstName: string; // string
  lastName: string; // string
  createdAt: string; // System.DateTime?
  updatedAt: string; // System.DateTime?
  shortName: string; // string
  isSeller: boolean; // bool
  employeeNumber: string; // string
  roleId: string; // Guid
  roleName: string; // Guid
  deleted: boolean;
  disabled: boolean;
  userType: number;
}

export interface Value {
  value: OrganisationUser[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<OrganisationUser>): any {
  const x: any = {
    //for correct parce guid field value from string in json format
  };
  if (data?.email) x.Email = data?.email || '';
  if (data?.firstName !== undefined) x.FirstName = data?.firstName || '';
  if (data?.lastName !== undefined) x.LastName = data?.lastName || '';
  if (data?.shortName !== undefined) x.ShortName = data?.shortName || '';
  if (data?.isSeller != undefined) x.IsSeller = data?.isSeller || false;

  if (data?.employeeNumber !== 'undefined') x.EmployeeNumber = data?.employeeNumber;
  if (data?.userId) {
    x['UserId@odata.type'] = '#Guid';
    x.UserId = data?.userId;
  }
  if (data?.roleId) {
    x['RoleId@odata.type'] = '#Guid';
    x.RoleId = data?.roleId;
  }
  if (data?.id) {
    x['Id@odata.type'] = '#Guid';
    x.Id = data?.id;
  }
  if (data?.organisationId) {
    x['OrganisationId@odata.type'] = '#Guid';
    x.OrganisationId = data?.organisationId;
  }
  if (data?.createdAt) x.CreatedAt = data?.createdAt;
  if (data?.updatedAt) x.UpdatedAt = data?.updatedAt;

  return x;
}
function parse(data?: Partial<OrganisationUser>): OrganisationUser {
  return {
    id: data?.id || '',
    userId: data?.userId || '',
    organisationId: data?.organisationId || '',
    email: data?.email || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    shortName: data?.shortName || '',
    createdAt: data?.createdAt || '',
    updatedAt: data?.updatedAt || '',
    isSeller: data?.isSeller || false,
    employeeNumber: data?.employeeNumber || '',
    roleId: data?.roleId || '',
    roleName: data?.roleName || '',
    deleted: data?.deleted || false,
    disabled: data?.disabled || false,
    userType: data?.userType || 0,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
