import { SearchParams } from '@/shared/model/searchParams';
import { OrganisationUser, Value as OrganisationUserValue } from '@/shared/model/organisationUser';
import { UserBillingInfo } from '@/shared/model/userBillingInfo';
import { ActionTree } from 'vuex';
import { OrganizationState } from './types';
import { RootState } from '../../types';
import { defaultBackendOrganisation as defaultBackendOrganization } from '@/shared/backend/organization';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import organization, { Value, Organisation } from '@/shared/model/organisation';
import folder, { Value as FolderValue, Folder } from '@/shared/model/folder';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { CONST } from '@/shared/utils/Constants';
const logger = new Logger('actions.organization');

export const actions: ActionTree<OrganizationState, RootState> = {
  getOrganization({ commit, dispatch }, id: string) {
    return defaultBackendOrganization
      .getOrganization(id)
      .then((response: AxiosResponse<Organisation>) => {
        commit('setOrganization', organization.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOrganizations({ commit, dispatch, getters }, payload?: any) {
    commit('setOrganizationsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getOrganizationsSearchParams;

    searchParams.dataOption.sortBy.forEach((item: string, i: number) => {
      searchParams.dataOption.sortBy[i] = searchParams.dataOption.sortBy[i].replace('.', '/');
    });

    return defaultBackendOrganization
      .getOrganizations(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganizations', response.data);
        commit('setOrganizationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        //module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganizationsIsLoading', false);
      });
  },
  getOrganizationsAll({ commit, dispatch, getters }, payload?: any) {
    commit('setOrganizationsIsLoading', true);
    const searchParams = payload ?? getters.getOrganizationsSearchParams;

    searchParams.dataOption.sortBy.forEach((item: string, i: number) => {
      searchParams.dataOption.sortBy[i] = searchParams.dataOption.sortBy[i].replace('.', '/');
    });

    return defaultBackendOrganization
      .getOrganizations(searchParams)
      .then(async (response: AxiosResponse<Value>) => {
        const organizations = response.data;
        while (response.data['@odata.nextLink']) {
          response = await defaultBackendOrganization.getFoldersByUrl(response.data['@odata.nextLink']);
          organizations.value = organizations.value.concat(response.data.value);
          organizations['@odata.count'] = organizations.value.length;
        }
        commit('setOrganizations', organizations);
        commit('setOrganizationsIsLoading', false);
        return organizations;
      })
      .catch((e: any) => {
        //module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganizationsIsLoading', false);
      });
  },
  updateToMain({ commit, dispatch }, payload: { organisationId: string; userBillingInfo: UserBillingInfo }) {
    commit('setInProgressUpdateToMain', true);
    return defaultBackendOrganization
      .updateToMain(payload.organisationId, payload.userBillingInfo)
      .then((response) => {
        commit('document/setDocumentsAllCount', 0, { root: true });
        module_utils.ok(i18n.tc(`success.organization_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateToMain', false);
      });
  },
  updateOrganization({ commit, dispatch }, file: Organisation) {
    return defaultBackendOrganization
      .updateOrganization(file)
      .then((response) => {
        module_utils.ok(i18n.t('success.organization_update'), commit, logger);
        commit('setOrganization', file);
        // return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  resetOrganization({ commit, dispatch }) {
    commit('setResetInProgress', true);
    return defaultBackendOrganization
      .resetOrganization()
      .then((response) => {
        module_utils.ok(i18n.t('success.organization_update'), commit, logger);
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setResetInProgress', false);
      });
  },
  deleteOrganization({ commit, dispatch }, id: string) {
    return defaultBackendOrganization
      .deleteOrganization(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAndCleanAllTables({ commit, dispatch }, id: string) {
    return defaultBackendOrganization
      .deleteAndCleanAllTables(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateOrganizationSettings({ commit, dispatch }, payload: { organizationId: string; name: string }) {
    return defaultBackendOrganization
      .updateOrganizationSettings(payload.organizationId, payload.name)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  export({ commit, dispatch }, payload: { organizationId: string }) {
    return defaultBackendOrganization
      .export(payload.organizationId)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  treatGobdEnable({ commit, dispatch }, payload: { organizationId: string }) {
    return defaultBackendOrganization
      .treatGobdEnable(payload.organizationId)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  addDemoDays({ commit, dispatch }, payload: { organizationId: string; days: number }) {
    return defaultBackendOrganization
      .addDemoDays(payload.organizationId, payload.days)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateOrganizationForCustomerSettings({ commit, dispatch }, payload: { organizationId: string; name: string }) {
    return defaultBackendOrganization
      .updateOrganizationForCustomerSettings(payload.organizationId, payload.name)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAddressIdentificationType(
    { commit, dispatch },
    payload: { organisationId: string; addressIdentificationType: string; id: string }
  ) {
    return dispatch('updateOrganization', payload);
  },

  updateUserSettings({ commit, dispatch }, payload: { userId: string; name: string }) {
    return defaultBackendOrganization
      .updateUserSettings(payload.userId, payload.name)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getOrganizationFolders(
    { commit, dispatch, getters, rootGetters },
    payload?: { organizationId: string; searchParams?: any; parentFolderId?: string; roleId?: string }
  ) {
    const searchParams = payload?.searchParams ?? {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    };
    const organizationId = payload?.organizationId || '';
    return defaultBackendOrganization
      .getOrganizationFolders(organizationId, searchParams, payload?.parentFolderId)
      .then((response: AxiosResponse<FolderValue>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  getOrganisationUsersAdmPage({ commit, dispatch, getters, rootGetters }, organizationId: string) {
    const searchParams = getters.getOrganizationUsersAdmPage(organizationId).searchParams;
    commit('setMainOrganizationsIsLoading', true);
    return defaultBackendOrganization
      .getOrganisationUsers(organizationId, searchParams)
      .then((response: AxiosResponse<OrganisationUserValue>) => {
        commit('setOrganizationUsersAdmPage', { orgId: organizationId, val: response.data });
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setMainOrganizationsIsLoading', false);
      });
  },
  getOrganisationsForMain({ commit, dispatch, getters }, mainOrgId: any) {
    commit('setMainOrganizationsIsLoading', true);
    const searchParams = getters.getOrganizationsForMain(mainOrgId).searchParams;
    return defaultBackendOrganization
      .getOrganisationsForOwner(searchParams, mainOrgId)
      .then((response: AxiosResponse<Value>) => {
        commit('setOrganizationsAllForMain', { mainId: mainOrgId, val: response.data });
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      })
      .finally(() => {
        commit('setMainOrganizationsIsLoading', false);
      });
  },
  getMainOrganisations({ commit, dispatch, getters }, payload?: any) {
    commit('setMainOrganizationsIsLoading', true);
    const searchParams = getters.getMainOrganizations.searchParams;

    searchParams.dataOption.sortBy.forEach((item: string, i: number) => {
      searchParams.dataOption.sortBy[i] = searchParams.dataOption.sortBy[i].replace('.', '/');
    });

    return defaultBackendOrganization
      .getOrganisationsForOwner(searchParams, undefined)
      .then((response: AxiosResponse<Value>) => {
        commit('setMainOrganizations', response.data);
        commit('setMainOrganizationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setMainOrganizationsIsLoading', false);
        throw e;
      });
  },

  getOrganizationFoldersAll(
    { commit, dispatch },
    payload?: { organizationId: string; searchParams?: any; parentFolderId?: string; roleId?: string }
  ) {
    const searchParams = payload?.searchParams ?? {
      dataOption: {
        page: 1,
        itemsPerPage: 150,
        sortBy: [],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    };
    const organizationId = payload?.organizationId || '';
    commit('setOrganizationFoldersAllIsLoading', true);

    return defaultBackendOrganization
      .getOrganizationFolders(organizationId, searchParams, payload?.parentFolderId)
      .then(async (response: AxiosResponse<FolderValue>) => {
        const folders = response.data;
        while (response.data['@odata.nextLink']) {
          response = await defaultBackendOrganization.getOrganizationFoldersByUrl(response.data['@odata.nextLink']);
          folders.value = folders.value.concat(response.data.value);
        }
        commit('setOrganizationFoldersAll', folders);
        commit('setOrganizationFoldersAllIsLoading', false);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganizationFoldersAllIsLoading', false);
      });
  },

  getOrganisationUsers(
    { commit, dispatch, getters, rootGetters },
    payload?: { organizationId: string; searchParams?: any }
  ) {
    const searchParams = payload?.searchParams ?? getters.getOrganisationUsers.searchParams;
    const organizationId = payload?.organizationId || rootGetters['auth/getCurrentOrganizationId'];
    commit('setOrganisationUsersIsLoading', true);
    return defaultBackendOrganization
      .getOrganisationUsers(organizationId, searchParams)
      .then((response: AxiosResponse<OrganisationUserValue>) => {
        commit('setOrganisationUsers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganisationUsersIsLoading', false);
      });
  },

  getOrganisationUsersAll({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    const searchParams = payload?.searchParams ?? getters.getOrganisationUsersAll.searchParams;
    commit('setOrganisationUsersAllIsLoading', true);
    return defaultBackendOrganization
      .getOrganisationUsersAll(searchParams)
      .then((response: AxiosResponse<OrganisationUserValue>) => {
        commit('setOrganisationUsersAll', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setOrganisationUsersAllIsLoading', false);
      });
  },

  updateFolderOpenType({ commit, dispatch, rootGetters }, payload: { organisationId: string; folderOpenType: number }) {
    commit('setInProgressUpdateCurrentOrganization', true);
    return defaultBackendOrganization
      .updateFolderOpenType(payload)
      .then((response) => {
        module_utils.ok(i18n.t('success.organization_update'), commit, logger);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateCurrentOrganization', false);
      });
  },
  updateOrganisationUser({ commit, dispatch, rootGetters }, payload: OrganisationUser) {
    payload.organisationId = payload.organisationId || rootGetters[CONST.getOrganisation].id;
    commit('setInProgressUpdateOrganisationUser', true);
    return defaultBackendOrganization
      .updateOrganisationUser(payload)
      .then((response) => {
        module_utils.ok(i18n.t('success.organization_user_update'), commit, logger);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateOrganisationUser', false);
      });
  },
  deleteOrganisationUser({ commit, dispatch, rootGetters }, id: string) {
    commit('setInProgressUpdateOrganisationUser', true);
    return defaultBackendOrganization
      .deleteOrganisationUser(id)
      .then((response) => {
        module_utils.ok(i18n.t('success.organization_user_update'), commit, logger);
        return response.data;
      })
      .catch((e) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInProgressUpdateOrganisationUser', false);
      });
  },
};
