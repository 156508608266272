import { MutationTree } from 'vuex';
import { TradeChannelState } from './types';
import Vue from 'vue';
import tradeChannel, { TradeChannel, Value } from '@/shared/model/tradeChannel';

export const mutations: MutationTree<TradeChannelState> = {
  setTradeChannels(state, payload: Value) {
    state.tradeChannels.items = payload.value.map((x) => tradeChannel.parse(x));
    state.tradeChannels.total = payload['@odata.count'] || 0;
    state.tradeChannels.isLoading = false;
  },
  setTradeChannelsTotal(state, payload: number) {
    state.tradeChannels.total = payload;
  },
  setTradeChannelsIsLoading(state, payload: boolean) {
    state.tradeChannels.isLoading = payload;
  },
  setIsImporting(state, payload: boolean) {
    state.isImporting = payload;
  },
};
