import { GetterTree } from 'vuex';
import { FileState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<FileState, RootState> = {
  ['getFiles']: (state) => state.files,
  ['getFilesIsLoading']: (state) => state.files?.isLoading,
  ['getFilesTotal']: (state) => state.files?.total,
  ['getFilesSearchParams']: (state) => state.files?.searchParams,
};
