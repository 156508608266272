import { GetterTree } from 'vuex';
import { FolderRuleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<FolderRuleState, RootState> = {
  ['getFolderRules']: (state) => state.folderRules,
  ['getFolderRulesIsLoading']: (state) => state.folderRules?.isLoading,
  ['getFolderRulesTotal']: (state) => state.folderRules?.total,
  ['getFolderRulesSearchParams']: (state) => state.folderRules?.searchParams,
};
