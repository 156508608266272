import { MutationTree } from 'vuex';
import { EmployeeState } from './types';
import Vue from 'vue';
import employees, { Employee, Value } from '@/shared/model/employee';

export const mutations: MutationTree<EmployeeState> = {
  setEmployees(state, payload: Value) {
    state.employees.items = payload.value.map((x) => employees.parse(x));
    state.employees.total = payload['@odata.count'] || 0;
    state.employees.isLoading = false;
  },
  setEmployeesTotal(state, payload: number) {
    state.employees.total = payload;
  },
  setEmployeesIsLoading(state, payload: boolean) {
    state.employees.isLoading = payload;
  },
};
