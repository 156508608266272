import organisationAddress from '@/shared/model/organisationAddress';
import { OrganisationAddressState } from './types';

export const state = (): OrganisationAddressState => initialState();

export const initialState = (): OrganisationAddressState => ({
  organisationAddresses: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
  current: organisationAddress.parse({}),
});
