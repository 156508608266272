import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { Logger } from 'fsts';
import folder, { Folder } from '@/shared/model/folder';
import { Address } from '@/shared/model/address';
import DuplicateDocumentDialog from './duplicate-document-dialog/duplicate-document-dialog.vue';
import { blobToSHA512 } from 'file-to-sha512';
import store from '@/shared/store';
import { FileUpload } from '@/shared/model/fileUpload';
import EditFolderDialog from '@/components/dialogs/edit-folder/edit-folder.vue';
import { AxiosPromise } from 'axios';
const folderModule = namespace('folder');
const organizationModule = namespace('organization');
const authModule = namespace('auth');
const documentModule = namespace('document');
const addressModule = namespace('address');
const logger = new Logger('file-upload-dialog');
const fileUploadModule = namespace('fileUpload');

@Component({
  name: 'file-upload-dialog',
  components: { vueDropzone: vue2Dropzone, DuplicateDocumentDialog, EditFolderDialog },
})
export default class FileUploadDialog extends Vue {
  @folderModule.Action('getMenuFolders')
  private getFoldersAll!: () => AxiosPromise<Folder[]>;
  @folderModule.Getter('getMenuFoldersTreeViewUploadFlat')
  private folders!: Folder[];
  @folderModule.Action('updateFolder')
  private updateFolder!: any;

  @folderModule.Getter('getMenuFolders')
  private getterGetMenuRootFolders!: any; // just array NOT `OdataItems`

  @organizationModule.Getter('getOrganization')
  private organization!: any;
  @authModule.Getter('getAccount')
  private account!: any;

  @documentModule.Action('getDocuments')
  private getDocuments!: any;
  @documentModule.Action('checkDocumentDuplicates')
  private actionCheckDocumentDuplicates!: any;
  @documentModule.Action('getDocumentsAllOrDeletedCount')
  private getDocumentsAllOrDeletedCount!: any;
  @documentModule.Getter('getDocuments')
  private getterGetDocuments!: any;
  @documentModule.Getter('getDocument')
  private document!: any;

  @fileUploadModule.Action('updateFileUpload')
  private updateFileUpload!: any;
  @fileUploadModule.Getter('getFileUpload')
  private getFileUpload!: FileUpload;

  @Ref('myVueDropzone')
  private refDropzone!: any;
  @Ref('addFile')
  private refAddFile!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Watch('dialog')
  async onDialog(newV: boolean) {
    if (newV) {
      this.setDefaultFolder();
      const promiseAll = [this.getFoldersAll()];
      await Promise.all(promiseAll);
    }
  }
  @Prop({ default: () => {} })
  private value!: any;
  private isUploading = 0;
  private currentFolderId = '';
  private lastUploadedDocsStorageIds: any = [];

  get dropzoneOptions() {
    return {
      url: `/api/UserFile/post`,
      autoProcessQueue: false,
      // supported extensions ".pdf, .jpg, .bmp, .png, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .tiff, .odt, .odt"
      acceptedFiles:
        'image/svg+xml, image/png, image/tiff, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, text/plain, video/mp4',
      // set up file limits
      maxFilesize: 100, // defult 256 MB
      headers: {
        Authorization: `Bearer ${this.account?.access_token}`,
      },
      paramName: function (n: any) {
        return 'File'; // (ED-175) Dropzone by default was sending `file[]` key with binary data, so it was sending the same file TWICE(2 times), so that is why `HttpContext.Request.ContentLength` was double the actual file size in bytes and we can upload ony 14.3 MB, while default Kestrel `MaxRequestBodySize` is 28.6 MB
      },
      dictDefaultMessage: 'Upload Files Here xD',
      includeStyling: false,
      // previewsContainer: false,
      createImageThumbnails: true,
      addRemoveLinks: true,
      thumbnailWidth: 175,
      thumbnailHeight: 175,
      uploadMultiple: false,
      parallelUploads: 5, // (ED-499) try 5 parallel uploads after fixing some backend issues (discussed with @Chaslau) // (ED-328) fix missing files when upload many files by using sequential upload // same behavior as in old `https://neu.easy-docs.de/` (they upload file sequentially 1 by 1)
      //dictDuplicateFile: 'Duplicate Files Cannot Be Uploaded',
      //preventDuplicates: true,
    };
  }
  private numOfDrpozoneFiles = 0;
  get allDropzoneFiles(): number {
    return this.numOfDrpozoneFiles;
  }
  get allowedUploadFolders(): Folder[] {
    return this.folders;
  }

  private dupplicateFileNames: string = '';

  //https://github.com/rowanwins/vue-dropzone/issues/242
  //https://github.com/rowanwins/vue-dropzone/blob/c88b8b08922cca4743a7fde03a0213e8c7a34f0f/docs/src/pages/Events.vue
  private duplicateFile(file: any) {
    this.dupplicateFileNames =
      this.dupplicateFileNames.length == 0 ? file.name : this.dupplicateFileNames + ', ' + file.name;
    const filePreview = document.getElementById(file.name);
    if (filePreview != null) {
      let removeLink = null;
      for (let i = 0; i < filePreview.childNodes.length; i++) {
        const el = filePreview.childNodes[i];
        if ((el as HTMLElement).className == 'dz-remove') {
          removeLink = el as HTMLElement;
          break;
        }
      }

      store.commit(
        'setSnackbarError',
        {
          message: this.dupplicateFileNames + ' bereits in diesem Upload-Fenster vorhanden (rot markiert)!',
          duration: 6000,
        },
        { root: true }
      );

      removeLink!.innerText = 'Remove';
    }
  }

  //#region : Folder autocomplete logic
  private folder: Folder = folder.parse({});

  setDefaultFolder() {
    let folderId = this.$route.params['folderId'];
    if (!folderId) folderId = this.document.folderId;
    const currentFolder = folderId ? this.folders.find((x: Folder) => x.id === folderId) : undefined;
    const eingangFolder = this.folders.find((folder) => folder.name.startsWith('Eingang'));
    if (currentFolder) this.selectFolder(currentFolder.id);
    else if (eingangFolder) this.selectFolder(eingangFolder.id);

    this.setCurrentFolderId(this.folder);
  }

  setCurrentFolderId(item: Folder) {
    this.currentFolderId = item.id; // set folderId from URL when open dialog, set `Eingang` folderId if open from `All documents` OR `Trash`
  }
  //#endregion

  //#region : Address autocomplete logic
  // TODO: (ED-380) (Low) extract same logic from here and from `menu-document-information` in the separate component
  @addressModule.Action('getAddresses')
  private actionGetAddresses?: any;

  private addresses: Address[] = [];
  private addressValue: string = ''; // will store `AddressId` from `getItemId`

  private isLoading = false;
  private hideNoData = true;

  getItemId(item: Address) {
    return `${item.id}`;
  }

  private itemTextVariable = ''; // (ED-380) variable to show tooltip for long `Address`
  getItemText(item: Address) {
    const result = `${item.konto} ${item.vorname} ${item.nachname} ${item.kennzeichen} ${item.seriennummer}`;
    this.itemTextVariable = result;
    return result;
  }

  async searchAddresses(searchWord: any) {
    if (!searchWord || searchWord.length < 2) return;
    this.isLoading = true;

    await this.actionGetAddresses({ searchTerm: searchWord })
      .then((result: any) => {
        this.addresses = result.value;
        if (this.addresses.length == 0) {
          this.hideNoData = false; // show `NO addresses` menu
        } else {
          this.hideNoData = true;
        }
      })
      .catch((err: any) => {
        logger.error(err);
        this.hideNoData = false;
      })
      .finally(() => (this.isLoading = false));
  }

  clearAddresses() {
    this.addresses = [];
  }

  //#endregion

  mounted() {}

  private numOfUploadedFiles = 0;
  public get uploadedFiles(): number {
    return this.numOfUploadedFiles;
  }

  //#region (ED-790) Logic to check duplicates on frontend before(without) uploading the document
  // hash ~ checksum (please see https://stackoverflow.com/questions/460576/hash-code-and-checksum-whats-the-difference)
  private dialogFilesHashes: any[] = [];

  //one file added was calls in same moment when vdropzone-files-added event so ...
  // private async oneFileAdded(file: any) {
  //   //TODO this is fixed in version 3.7
  //   //https://github.com/rowanwins/vue-dropzone/blob/a552bb06e7d75722833f275d6ee432214191641f/src/components/vue-dropzone.vue
  //   //TODO add  duplicate-check  @vdropzone-duplicate-file="duplicateFile" to fronend for dropzone

  //   let hasDuplicate: boolean = false;

  //   if (this.refDropzone.dropzone.files.length) {
  //     var _i, _len;
  //     for (_i = 0, _len = this.refDropzone.dropzone.files.length; _i < _len - 1; _i++) {
  //       if (
  //         this.refDropzone.dropzone.files[_i].name === file.name &&
  //         this.refDropzone.dropzone.files[_i].size === file.size &&
  //         this.refDropzone.dropzone.files[_i].lastModified.toString() === file.lastModified.toString()
  //       ) {
  //         this.dupplicateFileNames = !this.dupplicateFileNames
  //           ? file.name
  //           : this.dupplicateFileNames + ', ' + file.name;
  //         hasDuplicate = true;
  //         this.refDropzone.dropzone.removeFile(file);
  //       }
  //     }
  //   }
  //   if (hasDuplicate) {
  //     store.commit(
  //       'setSnackbarError',
  //       {
  //         message: this.dupplicateFileNames + this.$t('frontend_duplicates_title'),
  //         duration: 6000,
  //       },
  //       { root: true }
  //     );
  //   }
  //   //END TODO this is fixed in version 3.7
  // }

  private async fileAdded(lastDropedFiles: any) {
    this.dupplicateFileNames = '';
    logger.log('File Dropped => ', lastDropedFiles);
    const counter = 0;
    this.numOfDrpozoneFiles = this.refDropzone.dropzone.files.length;
    this.showHideAddFileIcon();

    await this.checkDublicateInUploads(this.refDropzone.dropzone.files);
  }

  private duplicates: any = [];
  private duplicatesDialogModel: any[] = [];

  private async checkDublicateInUploads(lastDropedFiles: any) {
    this.dialogFilesHashes = [];
    const array: any[] = [...lastDropedFiles];
    for (const index in array) {
      const el: any = array[index];
      const element = el.previewElement as HTMLElement;
      if (element != undefined) element.setAttribute('id', el.name);

      await blobToSHA512(el).then((hash) => {
        array[index].hash = hash; // add `hash` property to `FileAPI` in dialog to find duplicates by `checkSum` (see https://developer.mozilla.org/en-US/docs/Web/API/File)
        this.dialogFilesHashes.push(hash);
      });
    }

    this.actionCheckDocumentDuplicates(this.dialogFilesHashes)
      .then((result: any) => {
        this.highlightDuplicatesInDialog(result.result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get dialogHasDuplicatesOnBackend() {
    return this.duplicatesDialogModel.length > 0;
  }

  get dialogDuplicatesCount() {
    return this.duplicatesDialogModel.length / 2;
  }

  private highlightDuplicatesInDialog(backendDuplicates: any[]) {
    backendDuplicates = backendDuplicates;

    this.duplicates = [];

    {
      //clear counters
      this.refDropzone.dropzone.files.forEach((element: any, index: number) => {
        this.refDropzone.dropzone.files[index].isDuplicate = false;
      });
      this.duplicatesDialogModel = [];
      //reset section dublicates (if dumblicat removed)
      const dropzoneFiles = this.refDropzone.$el.querySelectorAll('.dz-details');
      if (dropzoneFiles && dropzoneFiles.length > 0) {
        dropzoneFiles.forEach((htmlEl: any, index: number) => {
          htmlEl.className = htmlEl.className.replace(' orange darken-1', '');
          htmlEl.className = htmlEl.className.replace(' red lighten-1', '');
        });
      }
    }
    const { indexOfBackendDuplicateInDialog, indexOfDialogOnlyDuplicates } = this.prepareDataForDialogHighlight(
      backendDuplicates //this.refDropzone.dropzone.files.map((x: any) => x.hash)
    );
    if (this.noDuplicatesInDialog(indexOfBackendDuplicateInDialog, indexOfDialogOnlyDuplicates)) return;

    const dropzoneFiles = this.refDropzone.$el.querySelectorAll('.dz-details');
    if (dropzoneFiles && dropzoneFiles.length > 0) {
      dropzoneFiles.forEach((htmlEl: any, index: number) => {
        if (indexOfBackendDuplicateInDialog.includes(index) && !indexOfDialogOnlyDuplicates.includes(index)) {
          htmlEl.className += ' orange darken-1';
          htmlEl.style.opacity = '1';
        }

        if (indexOfDialogOnlyDuplicates.includes(index)) {
          //disable DialogOnlyDuplicates coloring
          htmlEl.className += ' red lighten-1';
        }
      });
    }
  }

  private noDuplicatesInDialog(indexOfBackendDuplicateInDialog: any, indexOfDialogOnlyDuplicates: any) {
    return indexOfBackendDuplicateInDialog.length === 0 && indexOfDialogOnlyDuplicates.length == 0;
  }

  private prepareDataForDialogHighlight(backendDuplicates: any) {
    const dialogDuplicates = this.checkDialogOnlyDuplicates(this.dialogFilesHashes);

    const dialogFiles = this.refDropzone.dropzone.files;

    const indexOfBackendDuplicateInDialog: any = [];
    const indexOfDialogOnlyDuplicates: any = []; // duplicates ONLY in dialog, but NOT in the backend
    backendDuplicates.forEach((file: any) => {
      dialogFiles.filter((x: any, index: number) => {
        if (x.isDuplicate) return;
        if (file.deleted) return;

        if (x.hash == file.checkSum) {
          indexOfBackendDuplicateInDialog.push(index);
          x.isDuplicate = true; // add `isDuplicate` property to `FileAPI` object to prevent duplicate upload by default
          x.isDuplicateOnBackend = true;

          this.duplicatesDialogModel.push({ id: x.upload.uuid, name: x.name });
          this.duplicatesDialogModel.push(file);
        }

        if (dialogDuplicates.length > 0 && dialogDuplicates.includes(x.hash)) {
          indexOfDialogOnlyDuplicates.push(index);
          //allow DialogOnlyDuplicates to be uploaded
          x.isDuplicate = true; // add `isDuplicate` property to `FileAPI` object to prevent duplicate upload by default
        }
      });
    });

    if (dialogDuplicates.length > 0) {
      dialogFiles.filter((x: any, index: number) => {
        // if (x.isDuplicate) return;

        if (dialogDuplicates.length > 0 && dialogDuplicates.includes(x.hash)) {
          indexOfDialogOnlyDuplicates.push(index);
          x.isDuplicate = true; // add `isDuplicate` property to `FileAPI` object to prevent duplicate upload by default
        }
      });
    }

    return { indexOfBackendDuplicateInDialog, indexOfDialogOnlyDuplicates };
  }

  checkIfDuplicatesExist(arr: any) {
    return new Set(arr).size !== arr.length;
  }
  findDialogOnlyDuplicates(arr: any) {
    const duplicates = arr.filter((item: any, index: number) => arr.indexOf(item) !== index);
    const uniqueDuplicates = [...new Set(duplicates)]; //

    const result = uniqueDuplicates;
    return result;
  }

  private checkDialogOnlyDuplicates(dialogFilesHashes: string[]) {
    const isDuplicatesInUploadDialog = this.checkIfDuplicatesExist(dialogFilesHashes);
    let onlyDialogDuplicates: any[] = [];
    if (isDuplicatesInUploadDialog) {
      onlyDialogDuplicates = this.findDialogOnlyDuplicates(dialogFilesHashes);
    }
    return onlyDialogDuplicates;
  }
  //#endregion

  private showHideAddFileIcon() {
    const dropzone = this.refDropzone.dropzone;
    if (dropzone.files.length > 0) {
      dropzone.element.appendChild(this.refAddFile);
    } else if (dropzone.element.contains(this.refAddFile)) {
      dropzone.element.removeChild(this.refAddFile); // or ` //this.$refs.more`
    }
  }

  // a middle layer function where you can change the XHR request properties
  private sendingFile(file: any, xhr: any, formData: any) {
    console.log('sendingFile :>> ', file);
    console.log('xhr :>> ', xhr);
    console.log('formData :>> ', formData);
    console.log('if you want to change the upload time or add data to the formData you can do it here.');
    // formData = null;

    formData.append('FolderId', this.folder?.id);
    formData.append('Name', file.name);
    formData.append('Path', this.folder?.fullPath);
    formData.append('OrganisationId', this.organization.id); // `OrganisationId` (with `S`) is used in old `https://neu.easy-docs.de/` tables, BUT the `OrganizationId` is used in the our backend `DocumentTextExtractedEvent` class and later this `OrganizationId` field mapped to `DocumentDm` `OrganisationId` (with `S`) field
    formData.append('OwnerId', this.account.profile.sub);
    if (this.addressValue) {
      formData.append('AddressId', this.addressValue);

      const address = this.getAutoCompleteAddress();
      formData.append('AdresseKundennummer', address?.konto);
      formData.append('AdresseSeriennummer', address?.seriennummer);
      formData.append('AdresseKennzeichen', address?.kennzeichen);
    }
  }

  getAutoCompleteAddress() {
    return this.addresses.find((x) => x.id === this.addressValue);
  }

  // function where we get the upload progress
  uploadProgress(file: any, progress: any, bytesSent: any) {
    console.log('File Upload Progress', progress);
    console.log('bytesSent :>> ', bytesSent);
    // this.tempAttachments.map(attachment => {
    //   if (attachment.title === file.name) {
    //     attachment.progress = `${Math.floor(progress)}`;
    //   }
    // });
  }

  private totalProgress = 0;
  uploadProgressTotal(totalProgress: any, totalBytes: any, totalBytesSent: any) {
    let allProgress = 0;
    let allFilesBytes = 0;
    let allSentBytes = 0;
    for (let a = 0; a < this.refDropzone.dropzone.files.length; a++) {
      allFilesBytes = allFilesBytes + this.refDropzone.dropzone.files[a].size;
      allSentBytes = allSentBytes + this.refDropzone.dropzone.files[a].upload.bytesSent;
      allProgress = (allSentBytes / allFilesBytes) * 100;
    }

    this.totalProgress = allProgress;
  }

  private getDropzoneFilesInfo() {
    console.log('Dropzone.getAcceptedFiles().length', this.refDropzone.getAcceptedFiles().length);
    console.log('Dropzone.getActiveFiles().length', this.refDropzone.getActiveFiles().length);
    console.log('Dropzone.getAddedFiles().length', this.refDropzone.getAddedFiles().length);
    console.log('Dropzone.getFilesWithStatus().length', this.refDropzone.getFilesWithStatus().length);
    console.log('Dropzone.getQueuedFiles().length', this.refDropzone.getQueuedFiles().length);
    console.log('Dropzone.getRejectedFiles().length', this.refDropzone.getRejectedFiles().length);
    console.log('Dropzone.getUploadingFiles().length', this.refDropzone.getUploadingFiles().length);
  }

  // called on successful upload of a file
  success(file: any, response: any) {
    console.log('File uploaded successfully');
    this.lastUploadedDocsStorageIds.push(response.id); // response with ID of document from `UserFiles` table (`StorageDb` database)
    this.numOfUploadedFiles += 1;
    this.newSuccessfullyUploadedFiles += 1;
    this.removeFromHashesArrayAndDialogModel(file);

    this.updateFileUpload({
      inProgress: true,
      total: this.numOfDrpozoneFiles,
      uploaded: this.numOfUploadedFiles,
      errors: this.errors,
    });
  }

  private errors: number = 0;
  onError(file: any) {
    this.errors++;
    this.updateFileUpload({
      inProgress: true,
      total: this.numOfDrpozoneFiles,
      uploaded: this.numOfUploadedFiles,
      errors: this.errors,
    });
  }

  retryUploadErrors() {
    const dialogFiles = this.refDropzone.dropzone.files.slice(0);
    dialogFiles.forEach((file: any) => {
      if (file.status === 'error') {
        const newFile = file;
        newFile.status = undefined; //fileNew.status = 'queued';
        newFile.accepted = undefined;
        newFile.previewElement.classList.remove('dz-error');
        newFile.previewElement.classList.add('dz-success');
        this.refDropzone.dropzone.removeFile(file);
        this.refDropzone.dropzone.addFile(newFile);
        //$scope.errors.push(file.name);
        //myDropZone.context.dropzone.uploadFile(file);
      }
    });
    this.errors = 0;
  }

  resetNewFileUpload() {
    this.newSuccessfullyUploadedFiles = 0;
  }

  completeUpload(response: any) {
    this.removeNotSelectedDuplicates(); //remove duplicates before checking for upload progress finished
    if (this.isAllUploadsComplete()) {
      this.finalDelayedCountBadgeUpdate();

      this.getDocuments();
    }
  }

  // called on click on `remove file` button in thumbnail
  removeFile(file: any, error: any, xhr: any) {
    this.numOfDrpozoneFiles -= 1;
    if (file.status === 'success') {
      this.numOfUploadedFiles -= 1;
      this.updateFileUpload({
        inProgress: true,
        total: this.numOfDrpozoneFiles,
        uploaded: this.numOfUploadedFiles,
        errors: this.errors,
      });
      this.removeFromHashesArrayAndDialogModel(file);
    }
    this.checkDublicateInUploads(this.refDropzone.dropzone.files);
    this.showHideAddFileIcon();
  }

  //#region (ED-1097) Remove single file additional logic
  /**
   * (ED-1097) When in dialog click on `Remove file` (delete single files) sync `file-upload` dialog content with `dialogFilesHashes` and `duplicatesDialogModel` arrays
   * @param {any} file - FileApi object with some custom `duplicate` and `hash` fields
   */
  removeFromHashesArrayAndDialogModel(file: any) {
    this.removeFromArrayByValue(file.hash, this.dialogFilesHashes);

    if (!file.isDuplicate) return;

    if (file.isDuplicateOnBackend) {
      this.removeFromDuplicateDialogModel(file);
    }
  }

  removeFromDuplicateDialogModel(file: any) {
    const index = this.duplicatesDialogModel.findIndex((x: any) => x.name === file.name && !x.$id);
    if (index >= 0) {
      this.duplicatesDialogModel.splice(index, 2);
    }
  }
  //#endregion

  private newSuccessfullyUploadedFiles = 0;
  get hasNewUploads() {
    return this.newSuccessfullyUploadedFiles > 0;
  }
  // `this.refDropzone.getQueuedFiles().length` is NOT reactive so we cannot use GETTER here
  private isAllUploadsComplete() {
    const result = this.isDuplicatesUploaded
      ? this.isAllSelectedDuplicatesUploaded()
      : this.refDropzone.getQueuedFiles().length === 0;

    console.log('this.isAllSelectedDuplicatesUploaded() :>> ', this.isAllSelectedDuplicatesUploaded());

    return this.refDropzone.getUploadingFiles().length === 0 && result;
  }

  /**
   * This method takes into account if documents uploaded from `All documents`/`Trash` left-menu items
   */
  getCorrectTotalDocuments() {
    if (this.isUploadFromAllDocuments()) {
      return this.getCurrentFolder?.count ?? 0; // return 0 for `child` (NOT root) left-menu folders
    } else {
      return this.getterGetDocuments.total;
    }
  }

  /**
   * We need  2-5 extra seconds for Vuex to update and read State for the `left-menu` folder Badges properly, without this 5 seconds delay the count will be `-1` from actual file uploads (ie like increase count on 4 for 5 files, or 9 for 10 files)
   */
  async finalDelayedCountBadgeUpdate() {
    this.getFoldersAll();
    this.getDocumentsAllOrDeletedCount({
      searchParams: { dataOption: { itemsPerPage: 0, page: 1 } },
    }); // Update left-menu count badge for `All documents`
    if (this.isUploadFromAllDocuments()) {
      this.setCurrentUploadDialogFolderCount(this.newSuccessfullyUploadedFiles);
    }
    this.resetNewFileUpload();
  }

  private currentFolderFullPath = '';
  setFolderForUploadDialog(item: Folder) {
    this.currentFolderId = item.id;
    this.currentFolderFullPath = item.fullPath;
  }

  isUploadFromAllDocuments() {
    return !this.$route.params['folderId'];
  }

  get getCurrentFolder() {
    // TODO: (ED-318) maybe to be more precise about count (maybe failed docs) replace `left-menu` Vuex State operations with actual request to backend like ` // const payload = { showDeleted: false, folderId: item.id };  this.actionGetDocuments(payload);`
    const folders = this.getterGetMenuRootFolders;
    const currentFolder = folders.items.find((x: any) => x.id === this.currentFolderId); // when upload at once from `All documents`, then find `Eingang` folder when upload from `All documents` to return count from left menu (if use `getDocuments` action (for `Eingang`) it will affect state and `All documents` will contain data only from `Eingang` folder)
    return currentFolder;
  }

  setCurrentUploadDialogFolderCount(newUploadedDocs: number) {
    const uploadDialogCurrentFolder = this.getCurrentFolder;
    if (uploadDialogCurrentFolder) {
      uploadDialogCurrentFolder.count += newUploadedDocs;
    }
  }

  //#endregion

  private onClose() {
    this.dupplicateFileNames = '';
    this.$emit('click:close', this.value);
    if (this.hasNewUploads && this.isAllUploadsComplete()) {
      this.resetNewFileUpload();
      this.getDocuments();
    }
  }

  private onClear() {
    this.$emit('click:clear', this.value);

    this.removeFiles();
    this.showHideAddFileIcon();
    this.dupplicateFileNames = '';
  }

  private removeOnlyDuplicateFiles() {
    const dialogFiles = this.refDropzone.dropzone.files;
    const duplicateFiles = dialogFiles.filter((file: any) => file.isDuplicate);

    if (duplicateFiles.length > 0) {
      duplicateFiles.forEach((file: any) => {
        this.refDropzone.removeFile(file);
        this.removeFromArrayByValue(file.hash, this.dialogFilesHashes);
      });
    }

    this.duplicates = []; // clear `duplicates` to remove `!` icon
    this.duplicatesDialogModel = []; // clear `duplicatesDialogModel` to avoid deleted(cleared) files results in `duplicate-document-dialog`

    if (this.isDuplicatesDisalogCalledFromUpload) {
      this.onUpload();
    }
  }

  private removeFiles() {
    const dialogFiles = this.refDropzone.dropzone.files;
    const duplicateFiles = dialogFiles.filter((file: any) => file.isDuplicate);

    //if (duplicateFiles.length > 0) {
    duplicateFiles.forEach((file: any) => {
      this.refDropzone.removeFile(file);
      this.removeFromArrayByValue(file.hash, this.dialogFilesHashes);
    });
    //} else {
    this.refDropzone.removeAllFiles();
    this.dialogFilesHashes = [];
    this.numOfDrpozoneFiles = 0;
    this.numOfUploadedFiles = 0;
    this.updateFileUpload({
      inProgress: false,
      total: this.numOfDrpozoneFiles,
      uploaded: this.numOfUploadedFiles,
      errors: 0,
    });
    //}

    this.duplicates = []; // clear `duplicates` to remove `!` icon
    this.duplicatesDialogModel = []; // clear `duplicatesDialogModel` to avoid deleted(cleared) files results in `duplicate-document-dialog`
  }

  private removeFromArrayByValue(value: any, array: any) {
    const index = array.indexOf(value); // get index if value found otherwise -1

    if (index > -1) {
      // remove if found
      array.splice(index, 1);
    }
  }

  private cancelAllUploads: boolean = false;

  //https://github.com/dropzone/dropzone/issues/1142
  private onCancel() {
    this.cancelAllUploads = !this.cancelAllUploads;
    if (this.cancelAllUploads == false) {
      this.onUpload();
    }
  }

  private isDuplicatesDisalogCalledFromUpload: boolean = false;

  private async onUpload() {
    this.totalProgress = 0; // totalProgress
    this.cancelAllUploads = false;
    if (this.dialogHasDuplicatesOnBackend) {
      this.isDuplicatesDisalogCalledFromUpload = true;
      this.openDocumentDuplicatesDialog();
      return;
    } else {
      this.isDuplicatesDisalogCalledFromUpload = false;
    }

    this.$emit('click:upload', this.value);
    console.log('this.refDropzone.getQueuedFiles().length :>> ', this.refDropzone.getQueuedFiles().length);
    if (this.refDropzone.getQueuedFiles().length) {
      // this.refDropzone.processQueue(); // Old approach

      // Retrieve selected files
      const queuedFiles = this.refDropzone.getQueuedFiles();
      // (ED-328) try to fix missing files when upload many files by using 3 seconds delay between file upload (works for small files)
      // TODO: (ED-328) test this hack for bigger files with text extraction (PDF and images)
      for (let i = 0; i < queuedFiles.length; i++) {
        if (queuedFiles[i].isDuplicate) continue; // (ED-790) prevent uploading duplicates via `Upload` button
        setTimeout(() => {
          if (this.cancelAllUploads != true) {
            this.isUploading += 1;
            this.refDropzone.dropzone.processFile(queuedFiles[i]);
            this.isUploading -= 1;
            this.completeUpload(undefined);
          }
        }, i * 1000);
      }
    }
  }

  //#region (ED-1121) Logic to upload selected duplicates from `duplicate-document-dialog` dialog
  private uploadedDuplicatesCount = 0;
  private notSelectedDialogDuplicateIds: any = [];

  get isDuplicatesUploaded() {
    return this.uploadedDuplicatesCount > 0;
  }

  // (ED-1121) Need this method to detect when all selected duplicates uploaded, to clear NOT selected after completion
  private isAllSelectedDuplicatesUploaded() {
    return (
      this.refDropzone.getAcceptedFiles().length - this.uploadedDuplicatesCount ==
      this.refDropzone.getQueuedFiles().length
    );
  }

  private removeNotSelectedDuplicates() {
    if (!this.isDuplicatesUploaded) return;

    const notSelectedDuplicatesIds = this.notSelectedDialogDuplicateIds;

    const removeFiles = this.refDropzone.dropzone.files.filter(
      (file: any) => file.isDuplicate & notSelectedDuplicatesIds.includes(file.upload.uuid)
    );

    removeFiles.forEach((file: any) => {
      this.refDropzone.removeFile(file);
      this.removeFromArrayByValue(file.hash, this.dialogFilesHashes);
    });
  }

  uploadSelectedDuplicates(selectedDuplicateIds: any) {
    logger.log('selectedDuplicateIds from Dialog :>> ', selectedDuplicateIds);
    this.uploadedDuplicatesCount = selectedDuplicateIds.length;
    this.notSelectedDialogDuplicateIds = this.duplicatesDialogModel
      .filter((x: any) => !x.$id && !selectedDuplicateIds.includes(x.id))
      .map((x: any) => x.id);
    logger.log('notSelectedDialogDuplicateIds :>> ', this.notSelectedDialogDuplicateIds);
    if (!this.isDuplicatesDisalogCalledFromUpload) {
      if (this.refDropzone.getQueuedFiles().length && selectedDuplicateIds.length) {
        const duplicateFilesForUpload = this.refDropzone.dropzone.files.filter(
          (file: any) => file.isDuplicate & selectedDuplicateIds.includes(file.upload.uuid)
        );

        for (let i = 0; i < duplicateFilesForUpload.length; i++) {
          setTimeout(() => {
            if (this.cancelAllUploads != true) {
              this.isUploading += 1;
              this.refDropzone.dropzone.processFile(duplicateFilesForUpload[i]);
              this.isUploading -= 1;
              this.completeUpload(undefined);
            }
          }, i * 1000);
        }
      }
    } else {
      if (this.refDropzone.getQueuedFiles().length) {
        const queuedFiles = this.refDropzone.getQueuedFiles();
        for (let i = 0; i < queuedFiles.length; i++) {
          // skeep not selected dublicates
          if (queuedFiles[i].isDuplicate && !selectedDuplicateIds.includes(queuedFiles[i].upload.uuid)) continue;
          setTimeout(() => {
            if (this.cancelAllUploads != true) {
              this.isUploading += 1;
              this.refDropzone.dropzone.processFile(queuedFiles[i]);
              this.isUploading -= 1;
              this.completeUpload(undefined);
            }
          }, i * 1000);
        }
      }
    }
  }
  //#endregion

  //#region Duplicate Document dialog logic
  openDocumentDuplicatesDialog() {
    this.dialogDocumentDuplicates.show = true;
    this.dialogDocumentDuplicates.model = this.duplicatesDialogModel;
  }

  dialogDocumentDuplicates = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentDuplicates.show = false;
    },
    DeleteDuplicates: () => {
      this.removeOnlyDuplicateFiles();
    },
  };
  //#endregion
  //#region create folder dialog logic
  selectFolder(id: string) {
    const newFolders = this.folders.filter((x) => x.id == id);
    this.folder = newFolders?.length > 1 ? newFolders[0] : folder.parse({ id: id });
  }
  openDialogCreateFolder() {
    this.dialogCreateFolder.model = folder.parse({});
    this.dialogCreateFolder.show = true;
  }
  dialogCreateFolder = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogCreateFolder.show = false;
    },
    OnCreate: (item: any) => {
      this.updateFolder(item)
        .then((result: any) => {
          this.getFoldersAll()
            .then((x: any) => {
              this.selectFolder(result.id);
            })
            .finally(() => {
              this.dialogCreateFolder.show = false;
            });
        })
        .catch((err: any) => {
          this.dialogCreateFolder.show = false;
        });
    },
  };

  //#endregion
}
