import { MutationTree } from 'vuex';
import { RegexSplitterState } from './types';
import Vue from 'vue';
import regexSplitter, { RegexSplitter, Value } from '@/shared/model/regexSplitter';

export const mutations: MutationTree<RegexSplitterState> = {
  setRegexSplitters(state, payload: Value) {
    state.regexSplitters.items = payload.value.map((x) => regexSplitter.parse(x));
    state.regexSplitters.total = payload['@odata.count'] || 0;
    state.regexSplitters.isLoading = false;
  },
  setRegexSplittersTotal(state, payload: number) {
    state.regexSplitters.total = payload;
  },
  setRegexSplittersIsLoading(state, payload: boolean) {
    state.regexSplitters.isLoading = payload;
  },
};
