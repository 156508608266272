import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import doc, { Document, Value } from '../model/document';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST, documentChildEntities, odataStrings } from '../utils/Constants';
import { FilterBntValues, FilterBtnData } from '../model/smallPayloadModels/filterBtnData';
import documentProperty, { DocumentProperty, Value as DocumentPropertyValue } from '../model/documentProperty';
import { DocumentRoleRight, Value as DocumentRoleRightValue } from '../model/documentRoleRight';

const logger = new Logger('backend.document');
export interface BackendDocument {
  documentsMoveToFolder(documentsIds: string[], id: string): AxiosPromise<any>;
  getDocument(id: string): AxiosPromise<Document>; // (ED-298) OData expand works only with collections, so return Document as collection with 1 element
  documentExists(id: string): AxiosPromise<boolean>;
  getDocuments: (url: string) => AxiosPromise<Value>;
  getDocumentsWithChildren: (searchParams: SearchParams, folderId: string, showDeleted: boolean) => AxiosPromise<Value>;
  getFacetSearchResult(
    word: string,
    showDeleted: boolean,
    searchType: string,
    searchModes: string[],
    searchFolderIds?: string,
    chipData?: string
  ): AxiosPromise<any>;
  getDocumentProperties(documentId: string, searchParams: SearchParams): AxiosPromise<DocumentPropertyValue>;
  deleteDocument(id: string): AxiosPromise<any>;
  deleteDocuments(documentIds: string[]): AxiosPromise<any>;
  updateDocument(document: Document): AxiosPromise<any>;
  checkDocumentDuplicates(documentChecksums: any): AxiosPromise<any>;
  updateDocuments(documentIdsWithData: any, document: Document): AxiosPromise<any>;
  updateDocumentInformationMultiple(
    documentIdsWithData: any,
    document: Document,
    documentPropertys: DocumentProperty[]
  ): AxiosPromise<any>;
  updateDocumentDeletedStatus: (
    ids: string[],
    organizationId: string,
    isRestore?: boolean,
    deleteAfter?: string
  ) => AxiosPromise<Value>;
  rescanDocument(id: string, textOrPreview: number): AxiosPromise;
  removeDeleteAfter(id: string): AxiosPromise;
  rescanDocumentMultiple(organisationId: string, textOrPreview: number, documentsIds: string[]): AxiosPromise;
  createMetadata(id: string, timezoneOffet: number): AxiosPromise;
  merge(sourceIds: string[], destinationId: string, asNewDocument: boolean): AxiosPromise;
  split(
    pages: string[],
    deleteSource: boolean,
    onlyCreateDublicate: boolean,
    documentNumber: string,
    fileId: string,
    folderId: string
  ): AxiosPromise;
  unmerge(documentIds: string[], sourceId: string): AxiosPromise;
  getDocumentRoleRights(documentId: string, searchParams: SearchParams): AxiosPromise<DocumentRoleRightValue>;
}

// TODO (ED-254): change `OrganizationId` (with `Z`) to `OrganisationId` (with `S`) here and `DocumentStatusDm` backend class,  `OrganisationId` is used in old `https://neu.easy-docs.de/` tables, so we also use should `OrganisationId` (with `S`) on our backend for easier migration from `https://neu.easy-docs.de/` database
export const defaultBackendDocument: BackendDocument = {
  getDocument(id: string): AxiosPromise<Document> {
    const url = `${URLS.documentOdata}/GetDocument(id=${id})?$expand=ThreadQuestion,ThreadTask,ThreadCircular,Allocations($count=true;$top=0),DocumentPropertys,TemplateMarkups($count=true;$top=0),DocumentFiles($expand=File),ActionLogs($orderby=createdAt desc),DocumentStates,DocumentGobdDatum($expand=User),DocumentDetailComments,DocumentPagesText($select=id),Address,AlternativeFiles`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    return instance.get<Document>(url);
  },
  documentExists(id: string): AxiosPromise<boolean> {
    const url = `${URLS.document}/Exist/${id}`;
    return instance.get<boolean>(url);
  },
  getFacetSearchResult(
    word: string,
    showDeleted: boolean,
    searchType: string,
    searchModes: string[],
    searchFolderIds?: string,
    chipData?: string
  ): AxiosPromise<any> {
    let url = `${URLS.document}/getSearchResult/${encodeURIComponent(
      word
    )}?showDeleted=${showDeleted}&searchType=${searchType}&searchModes=${searchModes}`; // did not use `DefaultBackendHelper.makeUrl` to avoid default `$count=true` in result URL, which return ERROR 400
    if (chipData && chipData?.length > 0) {
      url += `&chipData=${chipData}`;
    }
    if (searchFolderIds && searchFolderIds?.length > 0) {
      url += `&searchFolderIds=${searchFolderIds}`;
    }
    return instance.get<Value>(url);
  },
  getDocuments(url: string): AxiosPromise<Value> {
    url = `${url}`;
    logger.log(`getDocuments${url}`);

    return instance.get<Value>(url);
  },

  getDocumentsWithChildren(searchParams: SearchParams, folderId: string, showDeleted: boolean): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.IsDeleted, showDeleted, false);
    if (showDeleted) odfb.eq(CONST.IsMerged, false, false);

    const folderIdArr = Array.isArray(folderId) ? folderId : folderId?.split(',');

    const byFolder = new ODataFilterBuilder().in(CONST.FolderId, folderIdArr || [], false);
    if (folderId) odfb.and(byFolder);
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentsDetailedViewOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
      // [
      //   //`&$expand=ActionLogs($orderby=createdAt desc),DocumentStates,DocumentGobdDatum,DocumentDetailComments,DocumentPagesText($select=id),TemplateMarkups,Address`,
      // ] // (ED-1128) show all information (Address, DocumentStates, etc) when query `DocumentsWithChildren`
    );
    const fixedBatchOdataQueryFromUrl = url.replace('/odata/DocumentsDetailedView?', '').replace(' ', '%20'); // fix ODFB URL to handle all Odata query parameters correctly
    return instance.post<Value>(`${URLS.documentsDetailedViewOdata}/$query`, fixedBatchOdataQueryFromUrl, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },
  getDocumentProperties(documentId: string, searchParams: SearchParams): AxiosPromise<DocumentPropertyValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentOdata}(${documentId})/DocumentPropertys`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined
    );
    logger.log(`getDocumentProperties${url}`);
    return instance.get<DocumentPropertyValue>(url);
  },
  deleteDocument(id: string): AxiosPromise {
    logger.debug('deleteDocument');
    return instance.delete(`${URLS.document}/${id}`);
  },
  updateDocument(document: Document): AxiosPromise<any> {
    return instance.put<Document>(`${URLS.document}/update`, doc.toAPI(document));
  },
  updateDocuments(documentIdsWithData: any, document: Document): AxiosPromise<any> {
    const payload = { ...doc.toAPI(document), ...documentIdsWithData };
    logger.log('updateDocuments payload :>> ', payload);
    return instance.put<Document>(`${URLS.document}/UpdateMultiple`, payload);
  },
  updateDocumentInformationMultiple(
    documentIdsWithData: any,
    document: Document,
    documentPropertys: DocumentProperty[]
  ): AxiosPromise<any> {
    const payload = {
      ...doc.toAPI(document),
      ...documentIdsWithData,
      DocumentPropertys: documentPropertys.map((x) => documentProperty.toAPI(x)),
    };
    logger.log('updateDocuments payload :>> ', payload);
    return instance.put<Document>(`${URLS.document}/UpdateDocumentInformationMultiple`, payload);
  },
  updateDocumentDeletedStatus(
    ids: string[],
    organisationId: string,
    isRestore?: boolean,
    deleteAfter?: string
  ): AxiosPromise<any> {
    logger.debug('updateDeletedStatus (+ ids): ', ids);
    if (isRestore) {
      return instance.put(`${URLS.document}/restore`, { ids, organisationId });
    }
    return instance.put(`${URLS.document}/moveToBin`, { ids, organisationId, deleteAfter });
  },
  documentsMoveToFolder(DocumentsIds: string[], ToFolderId: string, FromFolderId?: string): AxiosPromise<any> {
    logger.debug(`documentsMoveToFolder (+ ids): `, DocumentsIds);
    return instance.put(`${URLS.document}/DocumentsMoveToFolder`, { DocumentsIds, ToFolderId });
  },
  rescanDocument(id: string, textOrPreview: number): AxiosPromise {
    logger.debug('rescanDocument');
    return instance.put(`${URLS.document}/RescanDocument/${id}/${textOrPreview}`);
  },

  removeDeleteAfter(id: string): AxiosPromise<any> {
    logger.debug('RemoveDeleteAfter');
    return instance.put(`${URLS.document}/RemoveDeleteAfter/${id}`);
  },

  rescanDocumentMultiple(organisationId: string, textOrPreview: number, documentsIds: string[]): AxiosPromise {
    logger.debug('rescanDocumentMultiple');
    return instance.put(`${URLS.document}/RescanDocumentMultiple`, { organisationId, textOrPreview, documentsIds });
  },
  checkDocumentDuplicates(documentChecksums: any): AxiosPromise<any> {
    // GET doesn't support to send body for Axios. For `GET` request got the next error ```{type: "https://tools.ietf.org/html/rfc7231#section-6.5.1",…} errors: {"": ["A non-empty request body is required."]} ``` + read (2017)https://stackoverflow.com/a/46404151/2771704
    return instance.put<Value>(`${URLS.document}/CheckDuplicates`, { DocumentChecksums: documentChecksums });
  },
  deleteDocuments(documentIds: string[]): AxiosPromise<any> {
    logger.debug('deleteDocuments');
    return instance.delete(`${URLS.document}/deleteMultiple`, { data: { documentIds } });
  },
  createMetadata(id: string, timezoneOffet: number): AxiosPromise {
    logger.debug('createMetadata');
    return instance.put(`${URLS.document}/CreateMetadata/${id}/${timezoneOffet}`);
  },
  merge(sourceIds: string[], destinationId: string, asNewDocument: boolean): AxiosPromise<any> {
    logger.debug('merge');
    return instance.put(`${URLS.document}/Merge/${destinationId}/${asNewDocument}`, sourceIds);
  },
  split(
    pages: string[],
    deleteSource: boolean,
    onlyCreateDublicate: boolean,
    documentNumber: string,
    fileId: string,
    folderId: string
  ): AxiosPromise<any> {
    logger.debug('split');
    return instance.put(
      `${URLS.document}/Split/${deleteSource}/${onlyCreateDublicate}/${documentNumber}/${fileId}/${folderId}`,
      pages
    );
  },
  unmerge(documentIds: string[], sourceId: string): AxiosPromise<any> {
    logger.debug('unmerge');
    return instance.put(`${URLS.document}/Unmerge/${sourceId}`, documentIds);
  },
  getDocumentRoleRights(documentId: string, searchParams: SearchParams): AxiosPromise<DocumentRoleRightValue> {
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.documentOdata}(${documentId})/RoleRights`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      undefined,
      undefined,
      searchParams.orClauseFieldsIdsConvertToString,
      searchParams.orClauseFieldsIdsIgnoreCase
    );
    logger.log(`getDocumentRoleRights${url}`);
    return instance.get<DocumentRoleRightValue>(url);
  },
};

//#region (ED-1063) Logic connected to modifying  `searchData` and `chipData` to avoid `Text` in OData URL
// we should ignore `Text` field in OData URL (because field ignored on the backend in `document-list` and will get the error if use `text` in OData URL  )
function modifySearchAndChipDataForTextFullTextSearch(
  searchData: { searchWord: string; field: string; searchType: string } | undefined,
  textSearchWord: string,
  chipData: string | undefined
) {
  if (searchData?.field == 'Text') {
    textSearchWord = searchData.searchWord;
    searchData = undefined;
  }

  if (chipData && chipData.includes('Text:')) {
    if (isMultipleChipValues(chipData) && singleTextChip(chipData)) {
      ({ chipData, textSearchWord } = modifyChipData(chipData, textSearchWord));
    } else if (multipleTextChips(chipData)) {
      for (let index = 0; index <= getTextChipsCount(chipData); index++) {
        const x = modifyChipData(chipData, textSearchWord);
        textSearchWord += ' ' + x.textSearchWord;
        chipData = x.chipData;
      }
      textSearchWord = textSearchWord.trim();
    } else {
      textSearchWord = (textSearchWord + chipData.replace('Text:', '')).trim(); // (ED-1063) Fix to search 2 `Text` chips in a row, otherwise 1st text `chipData` value override the 2nd value from `searchData.searchWord` and don't update the result (the same result for the 2nd `Text` chip as for the 1st)
      chipData = '';
    }
  }
  return { searchData, textSearchWord, chipData };
}

// modify chipData to avoid `Text` in OData URL (delete `Text:` from `Chipdata` and put values in `textSearchWord`)
function modifyChipData(chipData: string, textSearchWord: string) {
  if (isTextChipFirst(chipData)) {
    if (isMultipleChipValues(chipData)) {
      textSearchWord = chipData.substring(chipData.indexOf(':') + 1, chipData.indexOf(',')).trim();
      chipData = chipData.slice(chipData.indexOf(',') + 1);
    } else {
      textSearchWord = chipData.substring(chipData.indexOf(':') + 1).trim();
      chipData = '';
    }
  }
  if (isTextChipInTheMiddle(chipData)) {
    let replaceExpresstion = '';
    [replaceExpresstion, textSearchWord] = isTextChipInTheMiddle(chipData)!;
    chipData = chipData.replace(replaceExpresstion, '');
  }
  if (isTextChipLast(chipData)) {
    let replaceExpresstion = '';
    [replaceExpresstion, textSearchWord] = isTextChipLast(chipData)!;
    chipData = chipData.replace(replaceExpresstion, '');
  }
  return { chipData, textSearchWord };
}

function isTextChipInTheMiddle(chipData: string) {
  return chipData.match('Text: (.*),');
}

function isTextChipLast(chipData: string) {
  return chipData.match(',Text: ([^,]*)$'); // `[^,]*` means any char except comma(`,`), so the Text Chip is last
}

function isTextChipFirst(chipData: string) {
  return chipData.indexOf('Text:') == 0;
}

function isMultipleChipValues(chipData: string) {
  return chipData.includes(',');
}

function singleTextChip(chipData: string) {
  return getTextChipsCount(chipData) === 1;
}
function multipleTextChips(chipData: string) {
  return getTextChipsCount(chipData) > 1;
}

function getTextChipsCount(chipData: string) {
  return chipData.split('Text:').length - 1;
}
//#endregion
