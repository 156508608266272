import { render, staticRenderFns } from "./menu-document-account-table.html?vue&type=template&id=446088c2&scoped=true&external"
import script from "./menu-document-account-table.ts?vue&type=script&lang=ts&external"
export * from "./menu-document-account-table.ts?vue&type=script&lang=ts&external"
import style0 from "./menu-document-account-table.scss?vue&type=style&index=0&id=446088c2&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446088c2",
  null
  
)

/* custom blocks */
import block0 from "./menu-document-account-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fmenu%2FrightMainMenu%2Fmenu-document-preview%2Fmenu-document-account%2Fmenu-document-account-table%2Fmenu-document-account-table.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDataTable,VDialog,VIcon,VListItem,VListItemTitle,VTextField})
