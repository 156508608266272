import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AllocationSetting } from '@/shared/model/allocationSetting';
import { namespace } from 'vuex-class';
import DateUtils from '@/shared/utils/DateUtils';

@Component
export default class DocumentDeleteDialog extends Vue {
  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  private optionalDate: any = '';
  private menuFrom: any = false;
  private from: any = '';
  onInputFrom(newVal: any) {
    this.menuFrom = false;
    this.from = this.dateISOToLocal(newVal);
  }

  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale, true);
  }

  async mounted() {}

  private deleteDocument() {
    this.$emit('click:delete', this.optionalDate);
  }
  private cancel() {
    this.$emit('click:close');
  }
}
