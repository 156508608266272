import { MutationTree } from 'vuex';
import { ProcessListsExportState } from './types';
import Vue from 'vue';
import processListsExport, { ProcessListsExport, Value } from '@/shared/model/processListsExport';

export const mutations: MutationTree<ProcessListsExportState> = {
  setProcessListsExports(state, payload: Value) {
    state.processListsExports.items = payload.value.map((x) => processListsExport.parse(x));
    state.processListsExports.total = payload['@odata.count'] || 0;
    state.processListsExports.isLoading = false;
  },
  setProcessListsExportsTotal(state, payload: number) {
    state.processListsExports.total = payload;
  },
  setProcessListsExportsIsLoading(state, payload: boolean) {
    state.processListsExports.isLoading = payload;
  },
};
