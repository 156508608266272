import { GetterTree } from 'vuex';
import { ExportState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ExportState, RootState> = {
  ['getExports']: (state) => state.exports,
  ['getExportsIsLoading']: (state) => state.exports?.isLoading,
  ['getExportsTotal']: (state) => state.exports?.total,
  ['getExportsSearchParams']: (state) => state.exports?.searchParams,
};
