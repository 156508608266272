// From https://github.com/mattphillips/deep-object-diff
export const isDate = (d: any) => d instanceof Date;
export const isEmpty = (o: any) => Object.keys(o).length === 0;
export const isObject = (o: any) => o != null && typeof o === 'object';
export const properObject = (o: any) => (isObject(o) && !o.hasOwnProperty ? { ...o } : o);

// Returns only the values that have been changed in the updated object
const updatedDiff = (lhs: any, rhs: any) => {
  if (lhs === rhs) return {};

  // null check one or another
  if (!lhs && !rhs) return {};
  if (!lhs && rhs) return rhs;
  if (lhs && !rhs) return lhs;

  if (!isObject(lhs) || !isObject(rhs)) return rhs;

  const l = properObject(lhs);
  const r = properObject(rhs);

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {};
    return r;
  }

  return Object.keys(r).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (l.hasOwnProperty(key)) {
      const difference: any = updatedDiff(l[key], r[key]);

      if (isObject(difference) && isEmpty(difference) && !isDate(difference)) return acc;

      return { ...acc, [key]: difference };
    }

    return acc;
  }, {});
};

export default updatedDiff;
