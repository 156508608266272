import { GetterTree } from 'vuex';
import { AddressesImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AddressesImportSettingState, RootState> = {
  ['getAddressesImportSettings']: (state) => state.addressesImportSettings,
  ['getAddressesImportSettingsIsLoading']: (state) => state.addressesImportSettings?.isLoading,
  ['getAddressesImportSettingsTotal']: (state) => state.addressesImportSettings?.total,
  ['getAddressesImportSettingsSearchParams']: (state) => state.addressesImportSettings?.searchParams,
  ['getAddressesImportSetting']: (state) => state.addressesImportSetting,
  ['getInProgressUpdateAddressesImportSetting']: (state) => state.inProgressUpdateAddressesImportSetting,
};
