import { MutationTree } from 'vuex';
import { GobdDataState } from './types';
import Vue from 'vue';
import gobdData, { GobdData, Value } from '@/shared/model/gobdData';

export const mutations: MutationTree<GobdDataState> = {
  setGobdDatas(state, payload: Value) {
    state.gobdDatas.items = payload.value.map((x) => gobdData.parse(x));
    state.gobdDatas.total = payload['@odata.count'] || 0;
    state.gobdDatas.isLoading = false;
  },
  setGobdDatasTotal(state, payload: number) {
    state.gobdDatas.total = payload;
  },
  setGobdDatasIsLoading(state, payload: boolean) {
    state.gobdDatas.isLoading = payload;
  },
};
