import { GetterTree } from 'vuex';
import { AllocationOfficesImportSettingState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AllocationOfficesImportSettingState, RootState> = {
  ['getAllocationOfficesImportSettings']: (state) => state.allocationOfficesImportSettings,
  ['getAllocationOfficesImportSettingsIsLoading']: (state) => state.allocationOfficesImportSettings?.isLoading,
  ['getAllocationOfficesImportSettingsTotal']: (state) => state.allocationOfficesImportSettings?.total,
  ['getAllocationOfficesImportSettingsSearchParams']: (state) => state.allocationOfficesImportSettings?.searchParams,
  ['getAllocationOfficesImportSetting']: (state) => state.allocationOfficesImportSetting,
  ['getInProgressUpdateAllocationOfficesImportSetting']: (state) =>
    state.inProgressUpdateAllocationOfficesImportSetting,
};
